import { reactive } from 'vue'
import actions from '@/store/screen/actions'
import state, { getBreakpoint } from '@/store/screen/state'
import getters from '@/store/screen/getters'

export const screenModule = {
    state: reactive(state),
    actions,
    getters,
}

// Update breakpoint on window resize
window.addEventListener('resize', () => {
    screenModule.state.breakpoint = getBreakpoint()
})
