<!-- eslint-disable max-len -->
<template>
    <div id="study__container" class="study__container">
        <UpgradeSidePanel
            v-if="showUpgradeSidePanel && user"
            key="head"
            :has-active-referral-link="validReferral"
            :referral-link-errors="[ referralError ]"
            @close="showUpgradeSidePanel = false"
        />
        <div v-dark class="study__line" />
        <div
            v-dark
            class="study"
            :class="{ 
                [`study--${timeOfDay}`]: true,
            }"
        >
            <WelcomeSeriesModal v-if="showWelcomeSeriesModal && !isSSORoute" @close="showWelcomeSeriesModal = false" />
            <QuizSettingsSidePanel v-if="showQuizSettingsSidePanel" @close="showQuizSettingsSidePanel = false" />
            <QuizModeSetupModal
                v-if="quizMode"
                :mode="quizMode"
                @start="startQuiz"
                @close="quizMode = null"
            />
            <UnfinishedQuizModal
                v-if="showUnfinishedQuizModal"
                :unfinished-mode="unfinishedMode"
                :clicked-mode="clickedMode"
                @close="showUnfinishedQuizModal = false"
                @startNew="beginQuizMode"
                @resume="resumeMode"
            />
            <div class="study__header">
                <div
                    v-dark
                    class="study__header-left"
                    :class="{ [`study__header-left--${timeOfDay}`]: true }"
                >
                    <Blob class="study__blob" />
                    <div 
                        v-dark 
                        class="study__title"
                        :class="{
                            'study__title--ten-day-cta': !isLoading && showTenDayCta,
                        }"
                    >
                        <h1>
                            <template v-if="timeOfDay === 'early'">
                                Rise 'n Shine
                            </template>
                            <template v-else-if="timeOfDay === 'morning'">
                                Good Morning
                            </template>
                            <template v-else-if="timeOfDay === 'afternoon'">
                                Good Afternoon
                            </template>
                            <template v-else-if="timeOfDay === 'evening'">
                                Good Evening
                            </template>
                            <template v-else>
                                Hey, Night Owl
                            </template>
                        </h1>
                        <div class="study__subhead">
                            <div 
                                v-if="!isLoading && isTomorrowExamDay" 
                                class="study__greeting"
                                v-html="tomorrowExamDayGreeting" 
                            />
                            <div 
                                v-else-if="!isLoading && showTenDayCta" 
                                class="study__share-cta"
                            >
                                <div v-dark class="study__share-cta-message">
                                    You have a 10 day streak! Share the love.
                                    <PocketButton
                                        class="study__share-cta-btn"
                                        type="tertiary"
                                        :is-dark-mode="isDarkMode"
                                        @click="goToSharePage"
                                    >
                                        Give 20% Off
                                    </PocketButton>
                                </div>
                            </div>
                            <div
                                v-else-if="!isLoading && quizBasedGreeting && !isTomorrowExamDay"
                                class="study__greeting"
                                v-html="quizBasedGreeting" 
                            />
                            <div
                                v-else-if="!isLoading && !quizBasedGreeting && !isTomorrowExamDay"
                                class="study__greeting"
                                v-html="genericSubhead" 
                            />
                        </div>
                    </div>
                    <CalendarContainer />
                </div>
                <div v-dark="isDarkMode || isMidnight" class="study__header-right">
                    <QotDContainer :key="showWelcomeSeriesModal" />
                </div>
            </div>
            <div class="study__body">
                <PocketButton
                    v-if="!isLoading && breakpoint === 'black-bear' && !hasPremiumAccess"
                    :is-dark-mode="isDarkMode"
                    class="study__mobile-cta"
                    :is-loading="isLoading"
                    @click="showUpgradeSidePanel = true"
                >
                    Subscribe for all {{ numOfQuizModes }} quiz modes
                </PocketButton>
                <div class="study__body-top">
                    <h2 v-dark class="study__body-title">
                        Quiz Modes
                    </h2>
                    <PocketButton
                        :is-dark-mode="isDarkMode"
                        type="tertiary-small"
                        @click="showQuizSettingsSidePanel = true"
                    >
                        Quiz Settings
                    </PocketButton>
                </div>
                <div class="study__modes">
                    <Mode
                        class="study__mode study__mode-qotd"
                        name="Question of the Day"
                        @click="clickQuizMode('qotd')"
                    />
                    <Mode
                        class="study__mode"
                        name="Quick 10"
                        @click="clickQuizMode('quick10')"
                    />
                    <Mode
                        class="study__mode"
                        name="Timed Quiz"
                        @click="clickQuizMode('timed')"
                    />
                    <Mode
                        v-if="showLevelUpMode"
                        class="study__mode"
                        name="Level Up"
                        :is-premium="true"
                        @click="clickQuizMode('levelUp')"
                    />
                    <Mode
                        class="study__mode"
                        name="Weakest Subject"
                        :is-premium="true"
                        @click="clickQuizMode('weakest')"
                    />
                    <Mode
                        class="study__mode"
                        name="Missed Questions"
                        :is-premium="true"
                        @click="clickQuizMode('missed')"
                    />
                    <Mode
                        class="study__mode"
                        name="Build Your Own"
                        :is-premium="true"
                        @click="clickQuizMode('custom')"
                    />
                    <Mode
                        v-if="areMockExamsAvailable"
                        class="study__mode"
                        name="Mock Exam"
                        :is-premium="true"
                        @click="clickQuizMode('mockExam')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-facing-decorator'
import WelcomeSeriesModal from '@/components/WelcomeSeriesModal.vue'
import { userModule } from '@/store/user/module'
import CalendarContainer from '@/components/Study/CalendarContainer.vue'
import QuizSettingsSidePanel from '@/components/Study/QuizSettingsSidePanel.vue'
import QotDContainer from '@/components/Study/QotDContainer.vue'
import { timeOfDay } from '@/utils'
import Blob from '@/components/Study/Blob.vue'
import UIKit from '@pocketprep/ui-kit'
import Mode from '@/components/Study/Mode.vue'
import QuizModeSetupModal from '@/components/Study/QuizModeSetupModal.vue'
import UnfinishedQuizModal from '@/components/Study/UnfinishedQuizModal.vue'
import UpgradeSidePanel from '@/components/Settings/UpgradeSidePanel.vue'
import { questionModule } from '@/store/question/module'
import { quizModule } from '@/store/quiz/module'
import type { TQuizMode } from '@/store/quiz/state'
import type { TCreateActiveQuizParams } from '@/store/quiz/actions'
import { mockExamModule } from '@/store/mockExam/module'
import { analyticsModule } from '@/store/analytics/module'
import { screenModule } from '@/store/screen/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { bundleModule } from '@/store/bundle/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { subscriptionModule } from '@/store/subscription/module'
import { stripeModule } from '@/store/stripe/module'
import { referralModule } from '@/store/referral/module'
import type { Study as StudyTypes } from '@pocketprep/types'
import { formatDate } from '@/utils'
import { logger } from '@/elk'
import { keywordsModule } from '@/store/keywords/module'

@Component({
    components: {
        WelcomeSeriesModal,
        CalendarContainer,
        Blob,
        PocketButton: UIKit.Button,
        SidePanel: UIKit.SidePanel,
        Mode,
        QuizSettingsSidePanel,
        QotDContainer,
        QuizModeSetupModal,
        UnfinishedQuizModal,
        UpgradeSidePanel,
        PocketLink: UIKit.Link,
    },
})
export default class Study extends Vue {
    showWelcomeSeriesModal = !userModule.state.user?.webConfig?.hasSeenWelcomeModal
        && !userModule.state.user?.webConfig?.hasSeenOnboarding
    showUnfinishedQuizModal = false
    unfinishedMode: TQuizMode | null = null
    clickedMode: TQuizMode | null = null
    timeOfDay = timeOfDay()
    showQuizSettingsSidePanel = false
    quizMode: null | 'weakest' | 'missed' | 'timed' = null
    isLoading = true
    showUpgradeSidePanel = false
    showLevelUpMode = false

    get isSSORoute () {
        return typeof this.$route.query.ssoOrgName === 'string'
    }

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get userExamMetadata () {
        return userExamMetadataModule.getters.getCurrentUserExamMetadata()
    }

    get isMidnight () {
        return timeOfDay() === 'midnight'
    }

    get currentBundle () {
        return bundleModule.getters.getCurrentBundle()
    }

    get currentExam () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get currentExamQuestionSerials () {
        const questions = questionModule.getters.getSerialQuestionInfoLib({ questionFilter: 'all' })

        return Object.keys(questions)
    }

    get numOfKeywordsInCurrentExam () {
        return keywordsModule.getters.getKeywordDefinitions().size
    }

    get numOfQuizModes () {
        if (this.showLevelUpMode) {
            return this.areMockExamsAvailable ? 8 : 7
        }
        return this.areMockExamsAvailable ? 7 : 6
    }

    get areMockExamsAvailable () {
        return mockExamModule.getters.getCurrentMockExams().length > 0
    }

    get isTomorrowExamDay () {
        if (this.userExamMetadata?.scheduledExamDate) {
            const examDay = new Date(this.userExamMetadata.scheduledExamDate.iso).getTime()
            const tomorrow = new Date()
            tomorrow.setHours(24,0,0,0)
            return tomorrow.getTime() === examDay
        }
        return false
    }

    get tomorrowExamDayGreeting () {
        if (this.isTomorrowExamDay) {
            const tomorrowExamSubheads = [
                'Bon chance! (French for kick butt on your exam tomorrow!) <img src="img/study/france.png" alt="">',
                'Tomorrow\'s the day. Keep calm and exam on! ',
                'Big day tomorrow. Wishing you tons of luck! <img src="img/study/lucky.png" alt="">',
                'Hey <img src="img/study/wave.png" alt="">. We\'ll be rooting for you tomorrow. ',
            ]

            const randomIndex = Math.round(Math.random() * (tomorrowExamSubheads.length - 1))
            return tomorrowExamSubheads[randomIndex]
        }
        return false
    }

    get quizBasedGreeting () {
        if (quizModule.getters.getQuizzes().length) {
            const quizBasedSubheads = [
                { quizNumber: 10, subHead: '10 quizzes completed! Amazing!' },
                { quizNumber: 20, 
                    // eslint-disable-next-line max-len
                    subHead: 'You’ve slayed 20 quizzes so far. Keep it up! <img src="img/study/axe.png" alt=""><img src="img/study/vampire.png" alt="">',
                },
                { quizNumber: 30, 
                    subHead: '30 Quizzes down! However many to go <img src="img/study/wink.png" alt="">.' },
                { quizNumber: 40, subHead: '40 Quizzes done - that\'s a lot of thinking. ' },
                { quizNumber: 50, subHead: '50 Quizzes finished. Most excellent.' },
                { quizNumber: 60, subHead: '60 Quizzes down - we\'re impressed!' },
                { quizNumber: 75, 
                    subHead: '75 Quizzes completed. Baller status achieved. <img src="img/study/mic.png" alt="">' },
                { quizNumber: 100, 
                    subHead: 'You defeated 100 quizzes! All hail the conquerer. <img src="img/study/crown.png" alt="">',
                },
            ]
            // eslint-disable-next-line max-len
            const quizNumberIndex = quizBasedSubheads.findIndex(q => q.quizNumber === quizModule.getters.getQuizzes().length)
            if (quizNumberIndex > -1) {
                return quizBasedSubheads[quizNumberIndex].subHead
            }
            return false
        }
        return false
    }

    get genericSubhead () {
        const genericSubheads = [
            'Greetings and happy studying.',
            'It\'s always a good time to study.',
            'It\'s an excellent day to study.',
            'It\'s a fine time to study.',
            'WOOT. Time for some studying.',
            'Nice to see you. Enjoy your studies.',
            'Hiya. Happy studying. <img src="img/study/wave.png" alt="">',
            'Remember to hydrate while you study. <img src="img/study/droplet.png" alt="">',
            'When in doubt, study trout! (Wait...that\'s not right.) <img src="img/study/fish.png" alt="">',
            'Kicking butt and taking names - keep up the good work.',
            'Hot tip: answering 100 questions a week improves your chances of passing!',
        ]

        const timeSpecificSubheads = {
            'early': [
                'Hey - you\'re awake. Welcome to study central.',
                'Early bird gets the worm, er...coffee?',
                'You\'re up early. Happy studying. <img src="img/study/smile.png" alt="">',
                'Time for a stretch \'n study.',
                'Top \'o the mornin\' to ye! <img src="img/study/tophat.png" alt="">',
                'It\'s early. Sup. <img src="img/study/smile.png" alt="">',
                'Big morning study energy comin\' at ya!',
                'Behold! The morning! Go forth and study!',
                'Starting the day strong. #success',
                'We\'re happy you\'re here!',
            ],
            'morning': [
                'It\'s a great morning to study. <img src="img/study/sun.png" alt="">',
                'Your journey to awesomeness begins.',
                'Looks like you\'re getting a studious start to your day.',
                'Get that caffeine ready - it\'s study time. <img src="img/study/coffee.png" alt="">',
                'Welcome to your latest study sesh!',
                'Study now, play later. Your evening will thank you.',
                'Fact: the earth is round. Also fact: you\'ve got this.',
                'It\'s early, but not too early. Neato.',
                'Got that studying feeling?',
                'Slugs have four noses. You\'re welcome.',
            ],
            'afternoon': [
                'Time for tea or a quick study sesh? <img src="img/study/tea.png" alt="">',
                'No slump, just studying.',
                'Study on, Wayne. Study on, Garth.',
                'Fuel your study fire. <img src="img/study/taco.png" alt="">',
                'Way to keep your <img src="img/study/brain.png" alt="brain"> firing on all cylinders.',
                'We\'re mightly pleased you\'re here!',
                'Humans share 70% of our DNA with slugs.',
                'Turn down for <span style="text-decoration: line-through;">what</span> studying.',
            ],
            'evening': [
                'The word ‘evening\' comes from an Old English word æfnung. Study on.',
                'Hey! It\'s kinda dark.',
                'Way to wrap up your day with a quick round of studying.',
                'Way to go on the evening study sesh. <img src="img/study/moon.png" alt="">',
                'EOD = BOS (beginning of studying)',
                'How\'s about dinner with a side of study?',
                'The sun is 10,000 degrees F. So is this study sesh. <img src="img/study/fire.png" alt="">',
                'Always hydrate before you study. <img src="img/study/droplet.png" alt="">',
                'Dance like no one\'s watching, study like your professor lives next door.',
                'Sunsets and studying pair together like PB&J.',
            ],
            'midnight': [
                'Farfegnugen. Said no one ever about studying. Whatever, it\'s late.',
                // eslint-disable-next-line max-len
                'There\'s midnight oil, and then there\'s espresso. Cheers. <img src="img/study/coffee.png" alt="">',
                'Maximum effort. Well done. <img src="img/study/highfive.png" alt="">',
                'Ermergersh, what time is it?',
                'Hi <img src="img/study/wave.png" alt="">. We see you! (In a non-creepy way)',
                'Time. This is no time to talk about time.',
                'Wee small hours of the morning: <img src="img/study/wave.png" alt="">]',
                'Non Sequitur is a great word.',
                'Thou studiest most excellently.',
            ],
        }

        // if exam has keywords, add another message to the list
        if (this.numOfKeywordsInCurrentExam > 0) {
            genericSubheads.push(
                'Learning vocabulary can be tricky. Click highlighted words in explanations to see definitions.'
            )
        }

        const subheads = [ ...genericSubheads, ...(timeSpecificSubheads[this.timeOfDay] || []) ]
        const randomIndex = Math.round(Math.random() * (subheads.length - 1))
        return subheads[randomIndex]
    }

    get user () {
        return userModule.state.user
    }

    get validReferral () {
        return referralModule.getters.getValidReferral()
    }

    get referralError () {
        return referralModule.getters.getReferralError()
    }

    get hasPremiumAccess () {
        return subscriptionModule.getters.getSubscriptionForExamId(this.currentExam?.objectId)
    }

    get quizMessageConfig () {
        return userExamMetadataModule.getters.getMessageReferralConfig()?.quizResults
    }

    get tenDayStreakConfig () {
        return userExamMetadataModule.getters.getMessageReferralConfig()?.tenDayStreak
    }

    get streakCount () {
        return quizModule.getters.getStreakCount({ filterQuizMode: false })
    }

    get isVisibleStartingToday () {
        if (this.tenDayStreakConfig?.visibleStartingAt?.iso) {
            const today = formatDate(new Date(), 'month', 0)
            const startingAt = new Date(this.tenDayStreakConfig.visibleStartingAt.iso)
            const formattedVisibleDate = formatDate(startingAt, 'month', 0)
            if (today === formattedVisibleDate) {
                return true
            }
        }
        return false
    }

    get wasVisibleYesterday () {
        const date = new Date()
        date.setDate(date.getDate() - 1)
        const yesterday = formatDate(date, 'month', 0)
        if (this.tenDayStreakConfig?.visibleStartingAt?.iso) {
            const startingAt = new Date(this.tenDayStreakConfig.visibleStartingAt.iso)
            const formattedDate = formatDate(startingAt, 'month', 0)
            if (yesterday === formattedDate) {
                return true
            }
        }
        return false
    }

    get showTenDayCta () {
        // Do not show if tomorrow is the user's exam date
        if (this.isTomorrowExamDay) {
            return false
        }

        // Only show to subscribed premium users
        if (typeof this.hasPremiumAccess !== 'object') {
            return false
        }

        // Show if a user has a streak of ten or more days
        if (this.streakCount < 10) {
            return false
        }

        // Premium user with a 10 day streak but no config
        if (!this.tenDayStreakConfig) {
            return true
        }

        // Show once calendar month
        if (!this.isVisibleStartingToday) {
            return false
        }
        
        // Do not show if the CTA was shown yesterday
        if (this.wasVisibleYesterday) {
            return false
        }

        // Has a config and it should be shown today
        return true
    }

    async mounted () {
        this.isLoading = true

        await Promise.all([
            mockExamModule.actions.fetchMockExams(),
            questionModule.actions.fetchSerialQuestionInfoLib(),
            userExamMetadataModule.actions.fetchUserExamMetadata(),
            quizModule.actions.fetchAnsweredQuestions(),
            userModule.actions.fetchUserData(),
            referralModule.actions.validateReferral(),
            bundleModule.actions.fetchBundles(),
            examMetadataModule.actions.fetchExamMetadata(),
        ])
        await this.updateShowLevelUpMode()

        const studyContainerEl = document.getElementById('study__container')
        if (studyContainerEl && this.breakpoint !== 'black-bear') {
            studyContainerEl.scrollIntoView()
        }
       
        const sub = subscriptionModule.getters.getSubscriptionForExamId(this.currentExam?.objectId)

        let selectedPlan: 'Free' | 'Monthly' | 'Quarterly' | 'Yearly' = 'Free'
        if (sub && typeof sub === 'object') {
            const stripeSub = stripeModule.getters.getStripeSubscriptions().find(ss => ss.id === sub.guid)
            if (stripeSub && stripeSub?.items.data[0].plan) {
                const plan = stripeSub?.items.data[0].plan

                selectedPlan = plan.interval_count === 1 && plan.interval === 'month'
                    ? 'Monthly'
                    : plan.interval_count === 3
                        ? 'Quarterly'
                        : 'Yearly'
                
            }
        }

        if (this.showWelcomeSeriesModal && this.currentExam?.examGuid && this.currentBundle?.objectId && selectedPlan) {
            await userModule.actions.onboardingCompleted({
                firstExamGuid: this.currentExam?.examGuid,
                firstBundleId: this.currentBundle?.objectId,
                signedUpOn: 'Web',
                onboardingResult: selectedPlan,
            })
        }

        // update ten date config if ten day cta was shown yesterday
        if (this.wasVisibleYesterday) {
            const nextMonth = new Date ()
            nextMonth.setMonth(nextMonth.getMonth() + 1)
            await this.updateTenDayConfig(nextMonth)
        }

        if (this.showTenDayCta && !this.tenDayStreakConfig) {
            // update config if showTenDayCta and user does not have a config
            const today = new Date()
            await this.updateTenDayConfig(today)
        }

        // fetch keywords for the exam
        await keywordsModule.actions.fetchKeywordDefinitions(this.currentExamQuestionSerials)

        this.isLoading = false
    }

    async clickQuizMode (mode: TQuizMode) {
        if (mode === 'mockExam') {
            await analyticsModule.actions.trackEvent('Mock_Exam_Clicked')
        }

        const activeQuiz = quizModule.getters.getActiveQuiz()
        const activeMockExam = mockExamModule.getters.getActiveMockExamQuiz()
        const quizOrExamToResume = activeMockExam || activeQuiz
        const currentExamMatchesActiveQuiz = quizOrExamToResume?.examGuid === this.currentExam?.examGuid
        // make sure there is a quizOrExamToResume && currentExam examGuids match in case a user switches
        // exams with an activeQuiz for the previous exam clickExamCard in ExamMenu should be clearing 
        // out activeQuiz in localStorage but this is double checking just to be safe.
        if (currentExamMatchesActiveQuiz && quizOrExamToResume) {
            this.clickedMode = mode
            this.unfinishedMode = quizOrExamToResume.mode
            this.showUnfinishedQuizModal = true
            return
        }

        this.beginQuizMode(mode)
    }

    beginQuizMode (mode: TQuizMode) {
        this.showUnfinishedQuizModal = false
        if (mode === 'qotd') {
            this.startQuiz({ mode: 'qotd' })
        } else if (mode === 'quick10') {
            this.startQuiz({ mode: 'quick10', questionCount: 10 })
        } else if (mode === 'timed') {
            this.quizMode = 'timed'
        } else if (mode === 'levelUp') {
            userModule.actions.incrementLevelUpOpenedCount()
            this.$router.push({ name: 'level-up-intro' })
        } else if (mode === 'weakest') {
            this.quizMode = 'weakest'
        } else if (mode === 'missed') {
            this.quizMode = 'missed'
        } else if (mode === 'custom') {
            this.$router.push({ name: 'byoq' })
        } else if (mode === 'mockExam') {
            this.$router.push({ name: 'mock-exam-intro' })
        }
    }

    resumeMode (mode: TQuizMode) {
        if (mode === 'mockExam') {
            this.$router.push({ name: 'mock-exam' })
        } else {
            this.$router.push({ name: 'quiz' })
        }
    }

    async startQuiz (quizInfo: TCreateActiveQuizParams | { mode: 'qotd' }) {
        if (this.isLoading) {
            return
        }

        this.isLoading = true
        if (quizInfo.mode === 'qotd') {
            this.$router.push({ name: 'qotd' })
            return
        } else {
            await quizModule.actions.createActiveQuiz(quizInfo)
        }
        this.isLoading = false

        this.$router.push({ name: 'quiz' })
    }

    async updateTenDayConfig (date: Date) {
        if (!this.userExamMetadata) {
            return
        }

        await userExamMetadataModule.actions.upsertUserExamMetadata({
            examGuid: this.userExamMetadata.examGuid,
            messageConfig: {
                ...(this.userExamMetadata.messageConfig || {}),
                referral: {
                    ...(this.userExamMetadata.messageConfig?.referral as StudyTypes.Class.MessageConfigOptions || {}),
                    tenDayStreak: {
                        visibleStartingAt: date,
                    },
                },
            },
        })
    }

    goToSharePage () {
        logger.info('10 day streak notification click', {
            examName: this.currentExam?.nativeAppName,
            bundleName: this.currentBundle?.name,
            trackingCampaign: 'ReferralProgramV3',
        }, 'ReferralProgramV3')

        this.$router.push({
            name: 'share',
            query: {
                utm_content: '10_day_streak',
            },
        })
    }

    @Watch('currentExam')
    async updateShowLevelUpMode () {
        if (this.currentExam?.examGuid && this.currentBundle?.objectId) {
            this.showLevelUpMode = await quizModule.actions.isLevelUpEnabled({ 
                examGuid: this.currentExam.examGuid, 
                bundleId: this.currentBundle.objectId,
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.study {
    font-size: 15px;
    max-width: 1024px;
    margin: 0 auto;
    width: 100%;
    padding: 0 34px;
    box-sizing: border-box;

    @include breakpoint(black-bear) {
        padding: 0;
        display: block;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        height: 421px;
        width: 100%;

        @include breakpoint(brown-bear) {
            height: 330px;
        }

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &::after {
        @include breakpoint(black-bear) {
            content: '';
            position: fixed;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            background: $gray-background;
            z-index: -2;
        }
    }

    &--dark::after {
        background: $charcoal;
    }

    &--early {
        &::before {
            background-color: rgba($sunset-papaya, 0.08);
        }

        &--dark {
            &::before {
                background-color: $moonlit-ocean;
            }
        }
    }

    &--morning {
        &::before {
            background-color: rgba($manilla, 0.9);
        }

        &--dark {
            &::before {
                background-color: $moonlit-ocean;
            }
        }
    }

    &--afternoon {
        &::before {
            background-color: rgba($cream-puff, 0.9);
        }

        &--dark {
            &::before {
                background-color: $brand-black;
            }
        }
    }

    &--evening {
        &::before {
            background-color: #eef5ff;
        }

        &--dark {
            &::before {
                background-color: $evening;
            }
        }
    }

    &--midnight {
        &::before {
            background-color: $midnight-blue;
        }

        &--dark {
            &::before {
                background-color: $evening;
            }
        }
    }

    &__line {
        content: '';
        position: absolute;
        top: 186px;
        right: 0;
        width: calc(50% - 22px);
        height: 1px;
        background: rgba($brand-black, 0.16);
        z-index: 1;

        @include breakpoint(brown-bear) {
            width: calc(50% - 350px);
            top: 101px;
        }

        @include breakpoint(black-bear) {
            display: none;
        }

        &--dark {
            background: $white;
            opacity: 0.41;
        }
    }

    &__container {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            height: 1px;
            background: rgba($brand-black, 0.16);
            top: 186px;
            right: 0;
            width: 25%;

            @include breakpoint(brown-bear) {
                display: none;
            }
        }
    }

    &__header {
        height: 421px;
        display: flex;
        position: relative;
        z-index: 1;

        @include breakpoint(brown-bear) {
            display: block;
            max-width: 700px;
            margin: 0 auto;
            height: 330px;
        }

        @include breakpoint(black-bear) {
            min-height: 335px;
            height: auto;
            max-width: none;
        }
    }

    &__header-right {
        padding-left: 62px;
        padding-top: 80px;
        position: relative;

        @include breakpoint(brown-bear) {
            display: none;
        }
    }

    &__header-left {
        width: 500px;

        @include breakpoint(brown-bear) {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        @include breakpoint(black-bear) {
            width: 100%;
            display: block;
            border-bottom: 2px solid $white;
            padding-bottom: 14px;
            border-radius: 0 0 6px 6px;
        }

        &--early {
            @include breakpoint(black-bear) {
                background-color: rgba($cream-puff, 0.41);
            }

            &--dark {
                background-color: $moonlit-ocean;
            }
        }

        &--morning {
            @include breakpoint(black-bear) {
                background-color: $cream-puff;
            }

            &--dark {
                background-color: $moonlit-ocean;
            }
        }

        &--afternoon {
            @include breakpoint(black-bear) {
                background-color: rgba($banana-bread, 0.5);
            }

            &--dark {
                background-color: $brand-black;
            }
        }

        &--evening {
            @include breakpoint(black-bear) {
                background-color: $barely-blue;
            }

            &--dark {
                background-color: $evening;
            }
        }

        &--midnight {
            color: $white;

            @include breakpoint(black-bear) {
                background-color: $midnight-blue;
            }

            &--dark {
                background-color: $evening;
            }
        }

        &--dark {
            @include breakpoint(black-bear) {
                border-color: $charcoal;
            }
        }
    }

    &__body {
        max-width: 1012px;
        width: 100%;
        margin: 0 auto;
        padding: 30px 0 0;
        box-sizing: border-box;

        @include breakpoint(brown-bear) {
            margin-top: 0;
            max-width: 700px;
            padding: 28px 0 0;
        }

        @include breakpoint(black-bear) {
            padding: 15px 18px 0;
        }
    }

    &__mobile-cta {
        margin-bottom: 20px;
        width: 100%;
    }

    &__body-top {
        display: flex;
        justify-content: space-between;
        max-width: 500px;
        width: 100%;
        margin-bottom: 16px;
        box-sizing: border-box;

        @include breakpoint(brown-bear) {
            max-width: none;
        }

        @include breakpoint(black-bear) {
            padding: 0 8px;
            margin: 0 0 14px;
        }

        button {
            padding: 0;
        }
    }

    &__body-title {
        font-size: 15px;
        line-height: 18px;
        font-weight: 600;
        margin: 0;

        &--dark {
            color: $white;
        }
    }

    &__modes {
        margin: -6.5px;
        max-width: 513px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 60px;

        @include breakpoint(brown-bear) {
            max-width: none;
            margin: -8px;
            width: calc(100% + 16px);
        }

        @include breakpoint(black-bear) {
            margin: -5px 0;
            width: 100%;
            display: block;
            padding-bottom: 50px;
        }
    }

    &__mode {
        margin: 6.5px;

        @include breakpoint(brown-bear) {
            margin: 8px;
        }

        @include breakpoint(black-bear) {
            margin: 0 0 10px;
        }
    }

    &__mode-qotd {
        display: none;

        @include breakpoint(brown-bear) {
            display: flex;
        }
    }

    &__blob {
        position: absolute;
        left: 50%;
        margin-left: -122px;
        width: 244px;
        height: 46px;
    }

    &__title {
        max-width: 1012px;
        width: 100%;
        padding: 80px 0 0;
        margin: 0 auto 29px;
        box-sizing: border-box;

        @include breakpoint(brown-bear) {
            max-width: 230px;
            margin: 0;
        }

        @include breakpoint(black-bear) {
            padding: 66px 30px 0;
            margin-bottom: 30px;
            max-width: none;

            &--ten-day-cta {
                margin-bottom: 60px;
            }
        }

        &--dark {
            color: $white;
        }

        h1 {
            margin: 0;
            font-size: 26px;
            font-weight: 600;
            line-height: 34px;
            margin-bottom: 5px;
        }
    }

    &__subhead {
        position: relative;
        height: 16px;

        @include breakpoint(black-bear) {
            height: auto;
        }
    }

    &__greeting {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2px;

        :deep(img) {
            height: 16px;
            margin-left: 3px;
            display: inline;
            margin-bottom: -4px;
        }
    }

    &__share-cta {
        display: flex;
        width: 780px;
        margin-bottom: 29px;

        @include breakpoint(black-bear) {
            max-width: 343px;
            height: 65px;
            margin-bottom: 30px;
        }
    }

    &__share-cta-message {
        color: $pickled-bluewood;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        text-align: left;
        display: flex;
        align-items: center;

        &--dark {
            color: $white;
        }

        @include breakpoint(brown-bear) {
            display: block;
            width: 225px;
        }

        @include breakpoint(black-bear) {
            display: block;
            width: 100%;
            line-height: 22px;
            padding: 0;
        }
    }

    &__share-cta-btn {
        margin-left: 4px;

        @include breakpoint(brown-bear) {
            margin-left: 0px;
        }
    }
}
</style>