<template>
    <div
        v-if="initialLevelProgress"
        v-dark
        tabindex="-1"
        class="level-up-result"
    >
        <LevelBadge
            ref="level-up-result__badge"
            class="level-up-result__badge"
            :is-dark-mode="isDarkMode"
            size="large"
            :status="badgeStatus"
            :level-num="initialLevelProgress.level"
            :percent-start="(
                initialLevelProgress.score / initialLevelProgress.goal
            ) * 100"
            :percent-stop="(
                (initialLevelProgress.score + (scoreIncrease)) / initialLevelProgress.goal
            ) * 100"
            :animate="true"
        />
        <template v-if="progressMessage">
            <div
                class="level-up-result__title"
                v-html="progressMessage.title"
            />
            <div
                v-dark
                class="level-up-result__text"
                v-html="progressMessage.text"
            />
        </template>
        <PocketButton :is-dark-mode="isDarkMode" @click="emitContinue">
            Continue
        </PocketButton>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import LevelBadge from '@/components/LevelBadge.vue'
import type { ComponentPublicInstance } from 'vue'
import type { Study } from '@pocketprep/types'

@Component({
    components: {
        PocketButton: UIKit.Button,
        LevelBadge,
    },
})
export default class LevelUpResult extends Vue {
    @Prop() initialLevelProgress!: Study.Cloud.TLevelUpProgress[number] | null
    @Prop() scoreIncrease!: number

    badgeStatus = 'active'
    badgeScale = 1

    get startingLevel () {
        return this.initialLevelProgress?.level || 1
    }

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get finalScore () {
        return (
            ((this.initialLevelProgress?.score || 0) + (this.scoreIncrease))
            / (this.initialLevelProgress?.goal || Infinity)
        ) * 100
    }

    get didLevelUp () {
        if (!this.initialLevelProgress) {
            return false
        }
        return Boolean((
            this.initialLevelProgress.score + (this.scoreIncrease)
        ) >= this.initialLevelProgress.goal)
    }

    get progressMessage () {
        let messages: { title: string; text: string }[] = []
        if (this.didLevelUp) {
            messages = [{
                'title': 'Woohoo!',
                'text': `Level ${this.startingLevel} completed.`,
            },
            {
                'title': 'Shazam!',
                'text': `You completed Level ${this.startingLevel}.`,
            },
            {
                'title': 'Level cleared!',
                'text': 'Nicely done.',
            },
            {
                'title': 'Level tamed!',
                'text': '<img src="img/levelup/fire.png" alt=""> <img src="img/levelup/dragon.png" alt="">',
            },
            {
                'title': 'Way to go!',
                'text': `Level ${this.startingLevel} completed.`,
            },
            {
                'title': 'High-five!',
                'text': `Level ${this.startingLevel} complete!`,
            },
            {
                'title': 'Level completed.',
                'text': 'A+ work. <img src="img/levelup/smiley.png" alt="">',
            },
            {
                'title': 'Congrats!',
                'text': 'You\'re onto the next level.',
            },
            {
                'title': 'Level complete!',
                'text': 'Pass go & collect 200 metaphorical dollars.',
            },
            {
                'title': 'GGWP.',
                'text': 'You\'re onto the next level!',
            },
            {
                'title': 'Brilliant work.',
                'text': `Level ${this.startingLevel} cleared! Keep it up.`,
            },
            {
                'title': 'You did it!',
                'text': `Level ${this.startingLevel} complete. Well done.`,
            }]
        } else if (this.finalScore < 25) {
            messages = [{
                title: 'Great start!',
                text: `You're on your way to completing Level ${this.startingLevel}.`,
            }, {
                title: 'It begins!',
                text: 'Progress started.',
            }, {
                title: 'Away we go.',
                text: `Great start on Level ${this.startingLevel}.`,
            }]
        } else if (this.finalScore < 50) {
            messages = [{
                title: 'Keep going.',
                text: 'You\'ve made a solid start. Keep it up.',
            }, {
                title: 'On your way.',
                text: 'Great progress! Keep going.',
            }, {
                title: 'Good work.',
                text: 'You\'re on the right track.',
            }]
        } else if (this.finalScore < 75) {
            messages = [{
                title: 'So close!',
                text: 'You\'re more than halfway there.',
            }, {
                title: 'Just about there.',
                text: 'Keep going - you\'re making great progress.',
            }, {
                title: 'Nicely done.',
                text: `Level ${this.startingLevel} is coming along nicely.`,
            }]
        } else {
            messages = [{
                title: 'Almost there!',
                text: `Just a few more correct questions 'til you beat Level ${this.startingLevel}.`,
            }, {
                title: 'Success is around the corner.',
                text: `You're super close to completing Level ${this.startingLevel}.`,
            }, {
                title: 'You\'re learning fast!',
                text: `Level ${this.startingLevel} is almost complete.`,
            }]
        }
        return messages[Math.floor(Math.random() * messages.length)]
    }

    mounted () {
        setTimeout(() => {
            const titleEl = document.querySelector('.level-up-result') as HTMLElement | null
            titleEl?.focus()
        }, 0)

        if (this.didLevelUp) {
            setTimeout(() => {
                this.animateLevelUp()
            }, 1500)
        }
    }

    animateLevelUp () {
        const badgeRef = this.$refs['level-up-result__badge'] as ComponentPublicInstance | undefined
        const badgeEl = badgeRef?.$el as HTMLElement | undefined
        if (badgeEl) {
            const scaleChangeRate = this.badgeStatus === 'active'
                ? 0.05
                : 0.05  // Can change this to make the scale up rate different than the scale down
            const scaleChange = this.badgeStatus === 'active' ? -1 * scaleChangeRate : scaleChangeRate
            this.badgeScale = this.badgeScale + scaleChange
            badgeEl.style.transform = `scale(${this.badgeScale})`
            if (this.badgeScale <= 0.1) {
                this.badgeStatus = 'complete'
            }
            if (
                (this.badgeStatus === 'active' && this.badgeScale > 0.1)
                || (this.badgeStatus === 'complete' && this.badgeScale < 1)) {
                window.requestAnimationFrame(this.animateLevelUp)
            }
        }
    }

    @Emit('continue')
    emitContinue () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.level-up-result {
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 96px;
    background-color: $barely-purple;
    height: 100%;
    box-sizing: border-box;
    border-radius: 6px;

    &--dark {
        color: $white;
        background-color: $charcoal;
    }

    &__badge {
        margin-bottom: 40px;
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 12px;
    }

    &__text {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 32px;
        color: $ash;
        width: 280px;
        text-align: center;

        &--dark {
            color: $fog;
        }

        @include breakpoint(black-bear) {
            max-width: 275px;
        }

        :deep(img) {
            height: 17px;
            margin-bottom: -2px;
        }
    }
}
</style>
