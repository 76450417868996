<template>
    <div
        class="keyword-definition"
        :class="{'keyword-definition--mobile': isMobileScreenSize}"
        :style="{
            ...definitionCoordinates,
            clipPath: definitionClipPath,
        }"
        role="dialog"
        tabindex="-1"
        ref="mainContainer"
        v-dark
    >
        <div
            class="keyword-definition__container"
            tabindex="-1"
            ref="definitionContainer"
        >
            <div
                class="keyword-definition__tooltip-arrow"
                :class="{
                    [`keyword-definition__tooltip-arrow--${definitionPosition.x}-${definitionPosition.y}`]
                        : arrowMode === 'static'
                }"
                :style="{left: dynamicArrowPosition}"
                v-dark
            />
            <div
                class="keyword-definition__title"
                tabindex="-1"
                v-dark
            >
                <div
                    class="keyword-definition__title-text"
                    :aria-label="`${keyword}. definition.`"
                    role="none"
                >
                    {{ keyword }}
                    <div
                        class="keyword-definition__title-underline"
                        aria-hidden="true"
                        v-dark
                    />
                </div>
            </div>
            <div
                class="keyword-definition__text"
                :class="{'keyword-definition__text--mobile': isMobileScreenSize}"
                tabindex="-1"
                v-dark
            >
                {{ definition }}
            </div>
            <div
                class="keyword-definition__feedback"
                :class="{'keyword-definition__feedback--mobile': isMobileScreenSize}"
                tabindex="0"
                v-dark
            >
                <div
                    v-if="keywordVote === 'helpful'"
                    class="keyword-definition__feedback-helpful"
                    :class="{'keyword-definition__feedback-helpful--mobile': isMobileScreenSize}"
                >
                    <div
                        class="keyword-definition__feedback-helpful-title"
                        :class="{'keyword-definition__feedback-helpful-title--mobile': isMobileScreenSize}"
                        v-dark
                    >
                        Helpful
                    </div>
                    <div
                        class="keyword-definition__feedback-helpful-text"
                        :class="{'keyword-definition__feedback-helpful-text--mobile': isMobileScreenSize}"
                        v-dark
                    >
                        Thanks for the feedback!
                    </div>
                </div>
                <div
                    v-else-if="keywordVote === 'unhelpful'"
                    class="keyword-definition__feedback-helpful"
                    :class="{'keyword-definition__feedback-unhelpful--mobile': isMobileScreenSize}"
                >
                    <div
                        class="keyword-definition__feedback-unhelpful-title"
                        :class="{'keyword-definition__feedback-unhelpful-title--mobile': isMobileScreenSize}"
                        v-dark
                    >
                        Unhelpful</div>
                    <div
                        class="keyword-definition__feedback-unhelpful-text"
                        :class="{'keyword-definition__feedback-unhelpful-text--mobile': isMobileScreenSize}"
                        v-dark
                    >
                        We appreciate the feedback
                    </div>
                </div>
                <div
                    v-else
                    class="keyword-definition__feedback-text"
                    :class="{'keyword-definition__feedback-text--mobile': isMobileScreenSize}"
                    v-dark
                >
                    Share Feedback
                </div>
                <div
                    class="keyword-definition__feedback-reactions"
                    :class="{'keyword-definition__feedback-reactions--mobile': isMobileScreenSize}"
                >
                    <div
                        class="keyword-definition__feedback-thumbs-up"
                        :class="{
                            'keyword-definition__feedback-thumbs-up--active': keywordVote === 'helpful',
                            'keyword-definition__feedback-thumbs-up--mobile': isMobileScreenSize
                        }"
                        tabindex="0"
                        role="button"
                        :aria-label="`helpful. ${keywordVote === 'helpful' ? 'selected' : 'unselected'}`"
                        @mouseenter="enableReactionTooltip('helpful')"
                        @mouseleave="showTooltip = null"
                        @click="!isLoading && submitVote('helpful')"
                        v-dark
                    >
                        <Tooltip
                            v-if="showTooltip === 'helpful'"
                            class="keyword-definition__reaction-tooltip-helpful"
                            :class="{'keyword-definition__reaction-tooltip-helpful--mobile': isMobileScreenSize}"
                            :styles="{
                                'backgroundColor': isDarkMode ? brandColors.mariner : brandColors.fog,
                                'color': isDarkMode ? brandColors.white : brandColors.brandBlack,
                            }"
                        >
                            Helpful
                        </Tooltip>
                        👍
                    </div>
                    <div
                        class="keyword-definition__feedback-thumbs-down"
                        :class="{
                            'keyword-definition__feedback-thumbs-down--active': keywordVote === 'unhelpful',
                            'keyword-definition__feedback-thumbs-down--mobile': isMobileScreenSize
                        }"
                        tabindex="0"
                        role="button"
                        :aria-label="`unhelpful. ${keywordVote === 'unhelpful' ? 'selected' : 'unselected'}`"
                        @mouseenter="enableReactionTooltip('unhelpful')"
                        @mouseleave="showTooltip = null"
                        @click="!isLoading && submitVote('unhelpful')"
                        v-dark
                    >
                        <Tooltip
                            v-if="showTooltip === 'unhelpful'"
                            class="keyword-definition__reaction-tooltip-unhelpful"
                            :class="{'keyword-definition__reaction-tooltip-unhelpful--mobile': isMobileScreenSize}"
                            :styles="{
                                'backgroundColor': isDarkMode ? brandColors.mariner : brandColors.fog,
                                'color': isDarkMode ? brandColors.white : brandColors.brandBlack,
                            }"
                        >
                            Unhelpful
                        </Tooltip>
                        👎
                    </div>
                </div>
            </div>
            <div
                class="keyword-definition__close"
                :class="{'keyword-definition__close--mobile': isMobileScreenSize}"
                @click="emitClose"
                tabindex="0"
                role="button"
                aria-label="close definition"
                v-dark
            >
                <Icon type="incorrect"></Icon>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { analyticsModule } from '@/store/analytics/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { keywordsModule } from '@/store/keywords/module'
import { quizModule } from '@/store/quiz/module'
import { screenModule } from '@/store/screen/module'
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'
import { Vue, Component, Prop, Emit, Watch } from 'vue-facing-decorator'

interface IDefinitionPosition {
    x: 'left' | 'center' | 'right'
    y: 'above' | 'below'
}

@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
        Tooltip: UIKit.Tooltip,
    },
})
export default class KeywordDefinition extends Vue {
    @Prop() keyword!: string
    @Prop() definition!: string
    @Prop() serial!: string
    @Prop() keywordLocation!: string
    @Prop() keywordDOMElement!: HTMLElement
    @Prop() questionContainer!: HTMLElement
    @Prop() keywordClickLocation!: { x: number; y: number }
    @Prop() adjustBaseTopCoordinate?: number
    @Prop() isMobile?: boolean

    isLoading = false
    brandColors = BrandColors
    timeOpened = new Date().getTime()
    definitionCoordinates = { top: '0', left: '0' }
    definitionPosition: IDefinitionPosition = { x: 'left', y: 'below' }
    arrowMode: 'static' | 'dynamic' = 'static'
    dynamicArrowPosition = ''
    keywordVotes: { keyword: string; vote: 'helpful' | 'unhelpful'}[] | null = null
    showTooltip: 'helpful' | 'unhelpful' | null = null
    definitionClipPath = ''
    focusListener: Parameters<typeof addEventListener>[1] | null = null

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get keywordVote () {
        return this.keywordVotes?.find(kw => kw.keyword === this.keyword)?.vote
    }

    get activeQuiz () {
        return quizModule.getters.getActiveQuiz()
    }

    get mode () {
        return this.activeQuiz?.mode || null
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get isMobileScreenSize () {
        return this.isMobile || this.breakpoint === 'black-bear'
    }

    get scrollableSections () {
        const explanationSection = document.getElementsByClassName('uikit-question__right-side')
        const questionSection = document.getElementsByClassName('uikit-question__main')
        const questionContainer = document.getElementsByClassName('quiz__question')
        const scrollableSections = [
            ...explanationSection,
            ...questionSection,
            ...questionContainer,
            this.questionContainer,
        ]

        return scrollableSections
    }

    getDefinitionCoordinates (event?: Event) {
        const keywordRect = this.keywordDOMElement.getBoundingClientRect()
        const frameRect = this.questionContainer.getBoundingClientRect()

        const keyword = {
            top: keywordRect.top,
            left: keywordRect.left,
            bottom: keywordRect.bottom,
            right: keywordRect.right,
            width: keywordRect.width,
            height: keywordRect.height,
        }

        if (!keywordRect || !frameRect) {
            return
        }

        this.$nextTick(() => {
            if (this.adjustBaseTopCoordinate) {
                keyword.top = keyword.top - this.adjustBaseTopCoordinate
                this.keywordClickLocation.y = this.keywordClickLocation.y - this.adjustBaseTopCoordinate
            }
            // if keyword takes up more than one line we set starting coordinates closer to the click
            if (keyword.height > 40 && this.keywordClickLocation.y) {

                const keywordMidpoint = keyword.top + (keyword.height / 2)

                if (this.keywordClickLocation.y < keywordMidpoint) {
                    keyword.width = 30 // guesstimate of a width of the word
                    keyword.top = keyword.top - (keyword.height / 2)
                    keyword.left = keyword.right - keyword.width
                    keyword.right = keyword.right + keyword.width
                    keyword.bottom = keyword.bottom - (keyword.height / 2)
                } else {
                    keyword.width = 10 // short width secures good arrow positioning
                }
            }
            const definitionEl = document.getElementsByClassName('keyword-definition')[0]
            const definitionHeight = definitionEl?.clientHeight || 0
            const definitionWidth = definitionEl?.clientWidth || 0
            const coordinates = {
                top: `${this.definitionPosition.y === 'below'
                    ? (keyword.top + keyword.height + 12)
                    : keyword.height > 40 && this.keywordClickLocation.x
                        ? (keyword.top + (keyword.height / 2)) - definitionHeight - 12 :
                        keyword.top - definitionHeight - 12}px`,
                left: `${keyword.left - 11}px`,
            }

            this.dynamicArrowPosition = ''
            this.definitionPosition.x = 'left'
    
            const isOverflowingRight = (definitionWidth - 11 + keyword.left + 8) > frameRect.right
            const isOverflowingBottom = (definitionHeight + keyword.bottom + 8) > frameRect.bottom
            const isOverflowingBottomOnMobile = (definitionHeight + keyword.bottom + 26) > frameRect.bottom
            const isOverflowingTop = (keyword.top - (definitionHeight + 12)) < frameRect.top

            const frameEdgeDistance = ((frameRect.width - definitionWidth) / 2)
    
            if (this.isMobile || frameEdgeDistance < 60) {
                // there are different behaviors within the black-bear breakpoint so we don't check for it here
                // styles for qotd on big screens and mobile + small tablet screen sizes are the same
                coordinates.left = `${frameRect.left + frameEdgeDistance}px`
                this.dynamicArrowPosition = `${
                    keyword.left - frameRect.left - frameEdgeDistance + (keyword.width / 2.5)
                }px`
    
                if (isOverflowingBottomOnMobile && !event) {
                    this.$nextTick(() => {
                        this.questionContainer.scrollBy({
                            top: (definitionHeight + 26) - (frameRect.bottom - keyword.bottom),
                            behavior: 'smooth',
                        })
                    })
                }
            } else {
                if (isOverflowingRight) {
                    if (this.breakpoint === 'brown-bear') {
                        // if overflowing right on tablet we align right immediately
                        const newCoordinatesX = keyword.left - definitionWidth + keyword.width + 12
                        coordinates.left = `${newCoordinatesX}px`
                        this.definitionPosition.x = 'right'
                    } else {
                        // if overflowing right on Web - try to center it against the keyword first
                        let newCoordinatesX = keyword.left - (definitionWidth / 2 - (keyword.width / 2))
                        this.definitionPosition.x = 'center'
        
                        // if still overflowing after being centered, shift to the left until at least 8px margin
                        if ((newCoordinatesX + definitionWidth + 8) > frameRect.right) {
                            newCoordinatesX = keyword.left - definitionWidth + keyword.width + 12
                            this.definitionPosition.x = 'right'
                        }

                        coordinates.left = `${newCoordinatesX}px`
                    }
                }
    
                if (isOverflowingBottom && !isOverflowingTop && !event) {
                    // when it's above multi-line keyword we reverse top coordinate adjustment
                    if (keyword.height > 40 && this.keywordClickLocation.x) {
                        keyword.top = keyword.top + (keyword.height / 2)
                    }

                    coordinates.top = `${keyword.top - definitionHeight - 12}px`
                    this.definitionPosition.y = 'above'
                }
            }
            
            this.definitionCoordinates = coordinates

        })
    }

    trackModalClosed () {
        const examMetadata = examMetadataModule.getters.getCurrentExamMetadata()
        analyticsModule.actions.amplitudeTrack('Definition Modal Closed', {
            examGuid: examMetadata?.examGuid,
            secondsInDefinition: Math.round((new Date().getTime() - this.timeOpened) / 1000),
            definitionKeyword: this.keyword,
            serial: this.serial,
            location: this.keywordLocation,
            quizMode: this.mode || undefined,
        })
    }

    trackKeywordVote (params: { keyword: string; vote: 'helpful' | 'unhelpful'}) {
        const keywords = JSON.parse(localStorage.getItem('keywordVotes') || '[]') as { 
            keyword: string
            vote: 'helpful' | 'unhelpful'
        }[]
        const existingVoteIndex = keywords.findIndex(ekw => ekw.keyword === params.keyword)
        if (existingVoteIndex !== -1) {
            if (keywords[existingVoteIndex].vote === params.vote) {
                keywords.splice(existingVoteIndex, 1)
            } else {
                keywords[existingVoteIndex].vote = params.vote
            }
        } else {
            keywords.push(params)
        }
        localStorage.setItem('keywordVotes', JSON.stringify(keywords))
    }

    async submitVote (vote: 'helpful' | 'unhelpful') {
        this.isLoading = true
        const keywords = JSON.parse(localStorage.getItem('keywordVotes')
        || '[]') as {
            keyword: string
            vote: 'helpful' | 'unhelpful'
        }[]

        const existingVote = keywords.find(ekw => ekw.keyword === this.keyword)
        let helpfulVoteChange = 0
        let unhelpfulVoteChange = 0

        if (vote === 'helpful') {
            if (existingVote?.vote === 'helpful') {
                helpfulVoteChange = -1
            } else if (existingVote?.vote === 'unhelpful') {
                helpfulVoteChange = 1
                unhelpfulVoteChange = -1
            } else {
                helpfulVoteChange = 1
            }
        }

        if (vote === 'unhelpful') {
            if (existingVote?.vote === 'unhelpful') {
                unhelpfulVoteChange = -1
            } else if (existingVote?.vote === 'helpful') {
                unhelpfulVoteChange = 1
                helpfulVoteChange = -1
            } else {
                unhelpfulVoteChange = 1
            }
        }

        const examMetadata = examMetadataModule.getters.getCurrentExamMetadata()
        analyticsModule.actions.amplitudeTrack('Definition Feedback Submitted', {
            examGuid: examMetadata?.examGuid,
            isFeedbackPositive: vote === 'helpful',
            definitionKeyword: this.keyword,
            serial: this.serial,
            location: this.keywordLocation,
            quizMode: this.mode || undefined,
        })
        await keywordsModule.actions.submitKeywordDefinitionVote({
            keyword: this.keyword,
            serial: this.serial,
            vote,
            helpfulVoteChange,
            unhelpfulVoteChange,
        })
        this.trackKeywordVote({ keyword: this.keyword, vote })
        this.updateKeywordVotes()
        this.isLoading = false
    }

    updateKeywordVotes () {
        const keywords = JSON.parse(localStorage.getItem('keywordVotes')
        || '[]') as { keyword: string; vote: 'helpful' | 'unhelpful'}[]
        this.keywordVotes = keywords
    }

    enableReactionTooltip (reaction: 'helpful' | 'unhelpful') {
        if (this.breakpoint === 'black-bear') {
            return
        }

        this.showTooltip = reaction
    }

    clickListener (e: MouseEvent) {
        const targetEl = e.target as HTMLElement
        if (!Array.from(targetEl.classList).some(el =>
            el.includes('keyword-definition') || el.includes('keyword-highlight'))) {
            this.emitClose()
        }
    }

    mounted () {
        this.updateKeywordVotes()
        this.keywordDOMElement.classList.add('keyword-highlight--active')
        this.getDefinitionCoordinates()
        this.scrollableSections.forEach(e => e.addEventListener('scroll', this.getDefinitionCoordinates))
        document.addEventListener('mouseup', this.clickListener)
        window.addEventListener('resize', this.getDefinitionCoordinates)

        const definitionContainer = this.$refs['definitionContainer'] as HTMLElement
        definitionContainer.focus()

        // Trap the user's focus within the defition modal - don't allow focusing elements behind
        this.focusListener = event => {
            const target = (event as FocusEvent).target as HTMLElement  // The element receiving focus
            const parentContainer = this.$refs['mainContainer'] as HTMLElement
            const isFocusOutside = target && parentContainer && !parentContainer.contains(target)

            if (isFocusOutside) {
                const focusableDefinitionChildren = Array.from<HTMLElement>(parentContainer.querySelectorAll(
                    'div'
                ))
                const firstFocusableDefinitionChild = focusableDefinitionChildren.find(
                    el => !!el.getBoundingClientRect().width
                )
                const reversedDefinitionChildren = [ ...focusableDefinitionChildren ].reverse()
                const lastFocusableDefinitionChild = reversedDefinitionChildren.find(
                    el => !!el.getBoundingClientRect().width
                )
                if (firstFocusableDefinitionChild) {
                    const relatedTarget = (event as FocusEvent).relatedTarget   // The element last focused
                    if (relatedTarget === firstFocusableDefinitionChild && lastFocusableDefinitionChild) {
                        // If focus moves from first element -> outside defition modal, focus the last element instead
                        lastFocusableDefinitionChild.focus()
                    } else {
                        // If focus goes outside the defition modal in any other way, focus the first element
                        firstFocusableDefinitionChild.focus()
                    }
                } else {
                    // If the defition modal doesn't have any focusable children, focus the container instead
                    if (parentContainer.tabIndex === -1) {
                        parentContainer.tabIndex = 0
                    }
                    parentContainer.focus()
                }
            }
        }

        document.addEventListener('focusin', this.focusListener)
    }

    beforeUnmount () {
        this.keywordDOMElement.classList.remove('keyword-highlight--active')
        this.getDefinitionCoordinates()
        this.scrollableSections.forEach(e => e.removeEventListener('scroll', this.getDefinitionCoordinates))
        document.removeEventListener('mouseup', this.clickListener)
        window.removeEventListener('resize', this.getDefinitionCoordinates)
    }

    unmounted () {
        this.keywordDOMElement.focus()
    }

    @Emit('close')
    emitClose () {
        this.trackModalClosed()
        return true
    }

    @Watch('keywordDOMElement')
    updateDefinitionCoordinates (){
        this.getDefinitionCoordinates()
    }

    @Watch('breakpoint')
    closeDefinitionOnBreakpointChange (){
        this.emitClose()
    }
}
</script>

<style lang="scss" scoped>
.keyword-definition {
    position: absolute;
    max-width: 340px;
    background-color: rgba($mariner, 0.97);
    padding: 12px 12px 14px 12px;
    border-radius: 6px;
    z-index: 3;
    box-sizing: border-box;

    &--mobile {
        max-width: 356px;
    }

    &__title {
        color: $barely-background;

        &--dark {
            color: $brand-black;
        }
    }

    &__container {
        position: relative;
        outline: none;
    }

    &__tooltip-arrow {
        position: absolute;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid rgba($mariner, 0.97);

        &--dark {
            border-bottom: 6px solid $fog;
        }

        &--left-below {
            top: -17px;
        }

        &--center-below {
            top: -17px;
            left: 50%;
        }

        &--right-below {
            top: -17px;
            right: 0;
        }

        &--left-above {
            bottom: -19px;
            transform: rotate(180deg);
        }

        &--center-above {
            bottom: -19px;
            transform: rotate(180deg);
            left: 50%;
        }

        &--right-above {
            bottom: -19px;
            transform: rotate(180deg);
            right: 0;
        }
    }

    &__title-text {
        display: inline-block;
        max-width: 300px;
        font-weight: 600;
        font-size: 17px;
        line-height: 25px;
        letter-spacing: 0.3px;
    }

    &__title-underline {
        height: 1.5px;
        background-color: $beach-sand;
        border-radius: 1px;

        &--dark {
            background-color: $butterscotch;
        }
    }

    &__close {
        position: absolute;
        right: -4px;
        top: -4px;
        color: $banana-bread;
        cursor: pointer;

        &--mobile {
            svg {
                width: 30px;
                height: 30px;
            }
        }

        &:hover {
            color: $butterscotch;
        }

        &--dark {
            color: $brand-blue;

            &:hover {
                color: $brand-black;
            }
        }
    }

    &__text {
        margin-top: 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: $gray-background;

        &--mobile {
            font-size: 15px;
            line-height: 22px;
        }

        &--dark {
            color: $brand-black;
        }
    }

    &__feedback {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 42px;
        border-top: 1px solid $slate;
        box-sizing: border-box;
        padding-bottom: 3px;
        margin-top: 24px;
        
        &--mobile {
            height: 66px;
            padding-bottom: 6px;
        }

        &--dark {
            border-color: rgba($pewter, 0.5);
        }
    }

    &__feedback-text {
        display: flex;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: rgba($white, 0.7);
        padding-bottom: 6px;

        &--mobile {
            font-size: 13px;
            padding-bottom: 11px;
            line-height: 18px;
        }

        &--dark {
            color: $ash;
        }
    }

    &__feedback-helpful, &__feedback-unhelpful {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 500;
        height: 34px;
        color: rgba($white, 0.7);
        margin-bottom: -4px;

        &--mobile {
            margin-bottom: 0;
            line-height: 18px;
        }

        &--dark {
            color: $ash;
        }
    }

    &__feedback-helpful-title, &__feedback-unhelpful-title {
        font-weight: 600;
        line-height: 18px;
        color: $white;
        font-size: 13px;

        &--mobile {
            font-size: 14px;
        }

        &--dark {
            color: $brand-black;
        }
    }

    &__feedback-helpful-text, &__feedback-unhelpful-text {
        font-size: 11px;
        color: rgba($white, 0.7);

        &--mobile {
            font-size: 12px!important;
        }

        &--dark {
            color: $ash;
        }
    }

    &__feedback-reactions {
        display: flex;
        justify-content: space-between;
        width: 70px;

        &--mobile {
            width: 95px;
        }
    }

    &__feedback-thumbs-up, &__feedback-thumbs-down {
        width: 30px;
        height: 28px;
        background-color: $pickled-bluewood;
        border-radius: 6px;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;

        &--mobile {
            width: 40px;
            height: 40px;
            font-size: 20px;
        }

        &:hover {
            background-color: $slate;
            border: 1px solid rgba($banana-bread, 0.4);

            &--mobile {
                background-color: $pickled-bluewood;
                border: none;
            }

            @media (hover: none) {
                background-color: $pickled-bluewood;
                border: none;
            }
        }

        &--active {
            background-color: rgba($banana-bread, 0.2)!important;
            border: 1px solid $banana-bread!important;

            &--dark {
                background-color: $white!important;
                border: 1px solid $brand-blue!important;
            }
        }

        &--dark {
            background-color: transparent;
            border: 0.5px solid rgba($pewter, 0.85);

            &:hover {
                background-color: transparent;
                border: 1px solid rgba($brand-blue, 0.4);

                @media (hover: none) {
                    background-color: transparent;
                    border: 0.5px solid rgba($pewter, 0.85);
                }
            }   
        }
    }

    &__reaction-tooltip-helpful {
        position: absolute;
        top: -34px;
        right: -44px;

        &--mobile {
            right: -40px;
        }
    }

    &__reaction-tooltip-unhelpful {
        position: absolute;
        top: -34px;
        right: -58px;

        &--mobile {
            right: -54px;
        }
    }

    &__feedback-thumbs-down {
        padding-top: 4px;
        box-sizing: border-box;
    }

    &--dark {
        background-color: $gray-background;
    }
}
</style>