<template>
    <div v-if="!isLoading" class="share">
        <SettingsHead class="share__head">
            <div v-dark class="share__referral-percent">
                <span v-dark class="share__referral-percent-span">Give 20% Off</span>
            </div>
        </SettingsHead>
        <div v-dark class="share__description">
            Help your study buddies by giving them 20% off any new Pocket Prep 
            subscription when they sign up using your unique referral link.
        </div>
        <div v-dark class="share__copy-share">
            Copy and paste your link anywhere
        </div>
        <div class="share__url-share">
            <PocketInput
                v-model="referralLinkURL"
                class="share__referral-link-url"
                :is-dark-mode="isDarkMode"
            />
            <PocketButton
                class="share__copy-link-btn"
                :is-dark-mode="isDarkMode"
                :is-loading="isLoading"
                @click="copyReferralLink"
            >
                Copy Link
            </PocketButton>
        </div>
        <div 
            v-if="userReferralInfo" 
            v-dark 
            class="share__friends-joined"
        >
            <Icon
                v-dark
                class="share__present"
                type="present"
            />
            <div v-dark class="share__number">
                Friends joined: {{ userReferralInfo.redeemedUsersCount }}
            </div>
        </div>
        <div
            class="share__brain"
        >            
            <img
                v-if="!isDarkMode"
                class="share__brain-img"
                src="@/assets/settings/brain-present.png"
                alt=""
            >
            <img
                v-if="isDarkMode"
                class="share__brain-img"
                src="@/assets/settings/brain-present-dm.png"
                alt=""
            >
        </div>
        <div v-dark class="share__referral-faq">
            How does the referral program work?
        </div>
        <div v-dark class="share__faq-answer">
            Simply share your unique referral link with classmates, friends, family members, 
            your pet (wait, what?!) and they'll get 20% off their first subscription.
        </div>
        <div v-dark class="share__referral-faq">
            Will my referral link work for any Pocket Prep exam?
        </div>
        <div v-dark class="share__faq-answer">
            Yes! Friends can use the link to receive 20% off a subscription to any of Pocket Prep's
            <PocketLink
                :href="`https://www.pocketprep.com/exam-search?${buildUTMParamString({
                    utm_campaign: 'app_settings',
                    utm_content: 'referrals_exam_search',
                })}`"
                target="_blank"
                class="settings__foot-link"
                :is-dark-mode="isDarkMode"
                @mousedown.prevent
            >
                120+ exams
            </PocketLink>
        </div>
        <div v-dark class="share__referral-faq">
            Can I send my referral link to multiple people?
        </div>
        <div v-dark class="share__faq-answer">
            There’s no limit on the number of times your referral link can be used, so keep on sharin'!
        </div>
        <div class="share__terms-conditions">
            <PocketLink
                :href="`https://www.pocketprep.com/referral-program?${buildUTMParamString({
                    utm_campaign: 'app_settings',
                    utm_content: 'referrals_terms',
                })}`"
                target="_blank"
                :is-dark-mode="isDarkMode"
                @mousedown.prevent
            >
                Referral Program Terms & Conditions
            </PocketLink>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import SettingsHead from '@/components/Settings/SettingsHead.vue'
import { examMetadataModule } from '@/store/examMetadata/module'
import { bundleModule } from '@/store/bundle/module'
import { userModule } from '@/store/user/module'
import { referralModule } from '@/store/referral/module'
import { toastModule } from '@/store/toast/module'
import { logger } from '@/elk'
import { isSupportWindow } from '@/utils'
import { buildUTMParamString } from '@/store/utils'
import { analyticsModule } from '@/store/analytics/module'

@Component({
    components: {
        SettingsHead,
        PocketButton: UIKit.Button,
        PocketLink: UIKit.Link,
        PocketInput: UIKit.Input,
        Icon: UIKit.Icon,
    },
})
export default class Share extends Vue {
    isLoading = true
    userReferralCode = ''
    referralLinkUTMContent = 'settings'
    version = import.meta.env.VUE_APP_VERSION

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get referralLinkURL () {
        return `${window.location.origin}/register?referral=${this.userReferralCode}&${buildUTMParamString({
            utm_campaign: 'app_referral',
            utm_content: this.referralLinkUTMContent,
        })}`
    }

    get userReferralInfo () {
        return userModule.getters.getReferralInfo()
    }

    get currentExam () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }
    
    get currentBundle () {
        return bundleModule.getters.getCurrentBundle()
    }

    async mounted () {
        await referralModule.actions.validateReferral()

        await Promise.all([
            bundleModule.actions.fetchBundles(),
            userModule.actions.fetchUserData(),
            examMetadataModule.actions.fetchExamMetadata(),
        ])

        if (!this.userReferralInfo){
            this.$router.push({ name: 'account' })
        } else {
            this.userReferralCode = this.userReferralInfo.code
        }

        // Consume utm_content query param (e.g. quiz_results or 10_day_streak), if present
        const utmContentRouteValue = this.$route.query?.utm_content
        if (utmContentRouteValue && typeof utmContentRouteValue === 'string') {
            this.referralLinkUTMContent = utmContentRouteValue
            await this.$router.replace({
                query: {
                    ...this.$route.query,
                    utm_content: undefined,
                },
            })
        }

        this.isLoading = false
    }

    buildUTMParamString = buildUTMParamString

    showIntercom () {
        if (import.meta.env.VUE_APP_INTERCOM_APP_ID && !isSupportWindow()) {
            window.Intercom('show')
        }
    }

    copyReferralLink () {
        if (this.referralLinkURL) {
            navigator.clipboard.writeText(this.referralLinkURL)
            toastModule.actions.displayToast({
                title: 'Referral link copied!',
            })

            analyticsModule.actions.amplitudeTrack('Referral Shared', {
                bundleId: this.currentBundle?.objectId,
                client: 'Web Study',
                clientAppId: 'study.pocketprep.com',
                clientVersion: this.version,
                examGuid: this.currentExam?.examGuid,
                organizationId: userModule.getters.getCurrentOrganizationId() || undefined,
                userDomain: userModule.getters.getUserDomain() || undefined,
                referralSource: 'Settings Tab',
                referralAction: 'Copy Link',
            })

            logger.info('Referral page copy button click', {
                examName: this.currentExam?.nativeAppName,
                bundleName: this.currentBundle?.name,
                trackingCampaign: 'ReferralProgramV3',
            }, 'ReferralProgramV3')
        }
    }
}
</script>

<style lang="scss" scoped>
.share {
    font-size: 16px;

    &__head {
        @include breakpoint(black-bear) {
            margin-bottom: 44px;
        }
    }

    &__referral-percent {
        position: absolute;
        top: 8px;
        left: 81px;
        
        @include breakpoint(black-bear) {
            top: 8px;
            left: 80px;
        }
    }

    &__referral-percent-span {
        color: $brand-black;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        border-radius: 3px;
        border: 1px solid $banana-bread;
        background: $buttermilk;
        padding: 0 6px 0 6px;

        &--dark {
            color: $banana-bread;
            background: rgba(255, 237, 203, 0.20);
        }
    }

    &__description {
        color: $brand-black;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        width: 520px;
        height: 44px;

        &--dark {
            color: $fog;
        }

        @include breakpoint(black-bear) {
            width: 287px;
            height: 88px;
        }
    }

    &__copy-share {
        color: $brand-black;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;  
        margin-top: 40px;
        margin-bottom: 12px;

        &--dark {
            color: $white;
        }

        @include breakpoint(black-bear) {
            margin-bottom: 16px;
        }
    }

    &__url-share {
        display: flex;
    }

    &__referral-link-url {
        :deep(.uikit-input__input) {
            width: 300px;
            height: 39px;
            border-right: none;

            @include breakpoint(black-bear) {
                width: 240px;
            }
        }
    }

    &__copy-link-btn {
        margin-left: -8px;
        height: 39px;
        padding: 0;
        width: 100px;
    }

    &__friends-joined {
        display: flex;
        margin-top: 50px;
        margin-bottom: 24px;

        @include breakpoint(grizzly-bear) {
            padding-bottom: 0;
            margin-bottom: 20px;
        }
    }

    &__present {
        width: 24px;
        height: 24px;
        margin-right: 5px;
    }

    &__number {
        color: $brand-black;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;

        &--dark {
            color: $white;
        }
    }

    &__brain {
        margin-bottom: 40px;

        @include breakpoint(grizzly-bear) {
            margin-top: 20px;
            margin-bottom: 24px;
        }
    }

    &__brain-img {
        height: 174px;
        width: 174px;
    }

    &__referral-faq {
        color: $brand-black;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 8px;

        &--dark {
            color: $white;
        }

        @include breakpoint(black-bear) {
            width: 287px;
        }
    }

    &__faq-answer {
        color: $brand-black;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        width: 520px;
        margin-bottom: 24px;

        &--dark {
            color: $fog;
        }

        @include breakpoint(black-bear) {
            width: 312px;
        }
    }

    &__terms-conditions {
        margin-top: 40px;
    }
}
</style>