<template>
    <div v-dark class="mobile-menu">
        <Icon
            ref="closeIcon"
            type="close"
            class="mobile-menu__close"
            title="Close"
            tabindex="0"
            role="button"
            aria-label="Close Menu"
            @click="emitClose"
            @keydown.enter="emitClose"
            @mousedown.prevent
        />
        <ExamMenu
            class="mobile-menu__exam-menu"
            :exam-metadata="examMetadata"
            :current-exam-metadata="currentExamMetadata"
            @examChanged="emitClose"
        />
        <nav class="mobile-menu__nav">
            <RouterLink :to="{ name: 'study' }">
                <span 
                    v-dark 
                    class="mobile-menu__nav-links" 
                    @mousedown.prevent
                >
                    Study
                </span>
            </RouterLink>
            <RouterLink :to="{ name: 'stats' }">
                <span 
                    v-dark 
                    class="mobile-menu__nav-links" 
                    @mousedown.prevent
                >
                    Stats
                </span>
            </RouterLink>
            <RouterLink :to="{ name: 'review' }">
                <span
                    v-dark 
                    class="mobile-menu__nav-links" 
                    @mousedown.prevent
                >
                    Review
                </span>
            </RouterLink>
        </nav>
        <div class="mobile-menu__utility">
            <div
                v-dark
                class="mobile-menu__utility-link mobile-menu__dark-mode"
                tabindex="0"
                role="switch"
                :aria-checked="isDarkMode"
                @click="clickDarkMode"
                @keydown.enter="clickDarkMode"
                @mousedown.prevent
            >
                <div v-dark class="mobile-menu__dark-mode-inner">
                    Dark Mode <span v-dark class="mobile-menu__dark-mode-icon"><Icon type="moon" /></span>
                </div>
            </div>
            <div
                v-if="hasInstructorDashboardAccess"
                v-dark
                class="mobile-menu__utility-link"
                role="link"
                tabindex="0"
                @click="clickInstructorDashboard"
                @keydown.enter="clickInstructorDashboard"
                @mousedown.prevent
            >
                Instructor Dashboard <Icon class="mobile-menu__launch-icon" type="launch" />
            </div>
            <RouterLink
                class="mobile-menu__utility-link"
                :to="{ name: 'account' }"
            >
                <span 
                    v-dark 
                    class="mobile-menu__nav-links" 
                    @mousedown.prevent
                >
                    Settings
                </span>
            </RouterLink>
            <div
                v-dark
                class="mobile-menu__utility-link"
                role="button"
                tabindex="0"
                @click="showIntercom"
                @keydown.enter="showIntercom"
                @mousedown.prevent
            >
                Help
            </div>
        </div>
        <PocketButton
            v-if="!hasActiveSubscription && user"
            class="mobile-menu__upgrade mobile-menu__button"
            type="primary-yellow"
            @click="emitOpenUpgradeSidePanel"
        >
            Upgrade to Premium
        </PocketButton>
        <PocketButton
            class="mobile-menu__sign-out mobile-menu__button"
            role="link"
            :disabled="isLoading"
            @click="clickSignOut"
        >
            Sign Out
        </PocketButton>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import type { Study } from '@pocketprep/types'
import { userModule } from '@/store/user/module'
import { instructorModule } from '@/store/instructor/module'
import { subscriptionModule } from '@/store/subscription/module'
import ExamMenu from '@/components/Head/ExamMenu.vue'
import type { ComponentPublicInstance } from 'vue'
import { isSupportWindow } from '@/utils'

@Component({
    components: {
        Icon: UIKit.Icon,
        PocketButton: UIKit.Button,
        ExamMenu,
    },
})
export default class MobileMenu extends Vue {
    @Prop() currentExamMetadata!: Study.Class.ExamMetadataJSON
    @Prop() examMetadata!: Study.Class.ExamMetadataJSON[]

    isLoading = false

    get user () {
        return userModule.state.user
    }

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get hasActiveSubscription () {
        return !!subscriptionModule.getters.getSubscriptionForExamId()
    }

    get hasInstructorDashboardAccess () {
        return instructorModule.getters.getCurrentUserOrgInstructors().length > 0
    }

    get currentRoute () {
        return this.$route.path
    }

    async mounted () {

        // See if user has valid Instructor Dashboard account
        await instructorModule.actions.fetchCurrentUserOrgInstructors()

        document.body.classList.add('mobile-menu-open')
        document.body.style.overflow = 'hidden'

        const closeIconRef = this.$refs['closeIcon'] as ComponentPublicInstance | undefined
        const closeIconEl = closeIconRef?.$el as HTMLElement | undefined
        closeIconEl?.focus()
    }

    beforeUnmount () {
        document.body.classList.remove('mobile-menu-open')
        document.querySelector('body')?.style.removeProperty('overflow')
    }

    @Watch('currentRoute')
    routeChanged () {
        this.emitClose()
    }

    async clickSignOut () {
        this.isLoading = true
        await userModule.actions.signOut()
        this.$router.push({ name: 'sign-in' })
        this.isLoading = false
    }

    clickDarkMode () {
        userModule.actions.toggleIsDarkMode()
    }

    showIntercom () {
        if (import.meta.env.VUE_APP_INTERCOM_APP_ID && !isSupportWindow()) {
            window.Intercom('show')
            this.emitClose()
        }
    }

    clickInstructorDashboard () {
        // On mobile side, we want to open Instructor Dashboard in a NEW tab
        const teachURL = import.meta.env.VUE_APP_TEACH_URL
        if (teachURL) {
            window.open(teachURL, '_blank')
        }
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('openUpgradeSidePanel')
    emitOpenUpgradeSidePanel () {
        return true
    }
}
</script>
<style lang="scss" scoped>
.mobile-menu {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    min-height: 100%;
    width: 100%;
    background: $tangaroa;
    z-index: 999;
    max-height: 100%;
    overflow: auto;

    &--dark {
        background: $charcoal;
    }

    &__close {
        color: $banana-bread;
        width: 30px;
        height: 30px;
        padding: 12px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        user-select: none;
        box-sizing: content-box;
    }

    &__exam-menu {
        margin-bottom: 64px;
        position: relative;
        z-index: 2;
    }

    &__nav {
        width: 270px;
        margin: 0 auto 52px;

        a {
            font-size: 32px;
            line-height: 34px;
            font-weight: 600;
            margin-bottom: 24px;
            display: block;
            text-decoration: none;
            color: #ffedcb;
        }
    }

    &__nav-links {
        &--dark {
            color: $white;
        }
    }

    &__utility {
        width: 270px;
        margin: 0 auto 52px;
    }

    &__utility-link {
        color: #ffedcb;
        font-size: 20px;
        text-decoration: none;
        line-height: 46px;
        height: 46px;
        cursor: pointer;
        display: block;

        &--dark {
            color: $white;
        }
    }

    &__dark-mode-inner {
        height: 35px;
        position: relative;
        padding: 5.5px 2px 0 8px;
        line-height: 35px;
        z-index: 1;
        left: -8px;
        width: 146px;

        &--dark::before {
            content: '';
            position: absolute;
            background: $white;
            opacity: 0.17;
            left: 0;
            top: 2.25px;
            width: 100%;
            height: 100%;
            border-radius: 6px;
        }
    }

    &__dark-mode-icon {
        display: inline-block;
        margin-left: 11px;
        background: rgba($white, 0.2);
        border-radius: 4px;
        width: 23px;
        height: 23px;
        vertical-align: middle;
        margin-top: -6px;

        &--dark {
            background: transparent;
        }

        svg {
            color: $white;
            margin-left: 4px;
            margin-top: 3px;
            display: block;
        }
    }

    &__launch-icon {
        margin-left: 4px;
        margin-bottom: -1px;
    }

    &__button {
        display: block;
        width: 258px;
        margin: 0 auto 16px;
    }

    &__sign-out {
        border: 1px solid $cream-puff !important;
        background: transparent !important;
        color: #ffedcb !important;
        margin-bottom: 40px;
        height: 44px;
    }
}
</style>