<template>
    <div id="what-to-expect" class="what-to-expect">
        <div v-dark class="what-to-expect__content">
            <div class="what-to-expect__title">
                <img
                    class="what-to-expect__title-pencil"
                    src="@/assets/mock-exams/pencil.png"
                    alt=""
                >
                What to Expect
            </div>
            <div class="what-to-expect__interface">
                <div class="what-to-expect__section-title">
                    Pocket Prep Interface
                </div>
                <div v-dark class="what-to-expect__section-text">
                    This mock exam will use the Pocket Prep interface and is not
                    representative of how the official exam interface will appear.
                </div>
                <img
                    v-dark
                    class="what-to-expect__interface-image"
                    :src="getImagePath('interface.jpg')"
                    alt=""
                >
            </div>
            <div class="what-to-expect__timer">
                <div class="what-to-expect__section-title">
                    Timer
                </div>
                <div v-dark class="what-to-expect__section-text">
                    The timer cannot be paused.
                </div>
                <img
                    v-dark
                    class="what-to-expect__timer-image"
                    :src="getImagePath('timer.jpg')"
                    alt=""
                >
            </div>
            <div class="what-to-expect__all-questions">
                <div class="what-to-expect__section-title">
                    View All Questions
                </div>
                <div v-dark class="what-to-expect__section-text">
                    You will be able to view your flagged, answered, and unanswered questions
                    via an “All Questions” view while taking the exam.
                </div>
                <img
                    v-dark
                    class="what-to-expect__all-questions-image"
                    :src="getImagePath('questions.jpg')"
                    alt=""
                >
            </div>
        </div>
        <div class="what-to-expect__deep-breath">
            OK! Take a deep breath. Make sure you're in a quiet environment.
            Clear your space of notes and clutter.
            Do your best to imitate the real testing environment.
            Stand up and do a biiiig stretch, this is a long one.
        </div>
        <img
            class="what-to-expect__you-got-this"
            src="@/assets/mock-exams/you-got-this.png"
            alt=""
        >
    </div>
</template>

<script lang="ts">
import { analyticsModule } from '@/store/analytics/module'
import { screenModule } from '@/store/screen/module'
import { userModule } from '@/store/user/module'
import { Component, Vue } from 'vue-facing-decorator'

@Component
export default class WhatToExpect extends Vue {
    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    async mounted () {

        const mockExamIntroEl = document.getElementById('mock-exam-intro')
        // Scroll into view the top of the mock exam page 
        if (mockExamIntroEl) {
            mockExamIntroEl.scrollIntoView()
        }

        await analyticsModule.actions.trackEvent('Mock_Exam_Expectations_Viewed')
    }

    getImagePath (fileName: string) {
        let folderName: 'desktop-light' | 'desktop-dark' | 'mobile-light' | 'mobile-dark'

        if (this.breakpoint !== 'black-bear' && !this.isDarkMode) {
            folderName = 'desktop-light'
        } else if (this.breakpoint !== 'black-bear' && this.isDarkMode) {
            folderName = 'desktop-dark'
        } else if (this.breakpoint === 'black-bear' && !this.isDarkMode) {
            folderName = 'mobile-light'
        } else { // this.breakpoint === 'black-bear' && this.isDarkMode
            folderName = 'mobile-dark'
        }

        return new URL(`../../assets/mock-exams/${folderName}/${fileName}`, import.meta.url).href
    }
}
</script>

<style lang="scss" scoped>
.what-to-expect {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &__content {
        position: relative;
        background-color: $white;
        border: 1px solid $fog;
        border-radius: 5px;
        width: 620px;
        height: 1054px;
        box-sizing: border-box;
        padding: 43px 80px 45px;
        margin-bottom: 45px;

        &--dark {
            background-color: $brand-black;
            border-color: $brand-black;
        }

        &::after {
            content: '';
            border-radius: 5px;
            background: url('@/assets/mock-exams/halftone-black.png') repeat;
            background-size: 85px;
            position: absolute;
            width: 620px;
            height: 1052px;
            top: 10px;
            left: 6px;
            z-index: -1;
        }

        @include breakpoint(black-bear) {
            width: 100%;
            height: 937px;
            max-width: 375px;
            padding: 23px 34px 38px 33px;
            margin-top: 61px;
            margin-bottom: 32px;

            &::after {
                display: none;
            }
        }
    }

    &__title {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        margin-bottom: 22px;

        @include breakpoint(black-bear) {
            position: absolute;
            top: -46px;
            font-size: 20px;
            line-height: 24px;
        }
    }

    &__title-pencil {
        height: 25px;
        margin-right: 10px;

        @include breakpoint(black-bear) {
            height: 27px;
        }
    }

    &__section-title {
        font-weight: 600;
        font-size: 16px;
        letter-spacing: -0.1px;
        line-height: 24px;
        margin-bottom: 4px;

        @include breakpoint(black-bear) {
            line-height: 19px;
        }
    }

    &__section-text {
        font-size: 16px;
        line-height: 22px;
        color: $ash;
        margin-bottom: 24px;

        &--dark {
            color: $fog;
        }
    }

    &__web-url {
        font-weight: 600;
    }

    &__interface-image,
    &__timer-image,
    &__all-questions-image {
        display: block;
        width: 460px;
        margin-bottom: 36px;

        @include breakpoint(black-bear) {
            width: 324px;
            margin-bottom: 48px;
        }
    }

    &__timer-image {
        border-radius: 4px;
    }

    &__deep-breath {
        width: 460px;
        font-size: 18px;
        letter-spacing: -0.11px;
        line-height: 28px;
        margin-bottom: 65px;

        @include breakpoint(black-bear) {
            width: 308px;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
        }
    }

    &__you-got-this {
        width: 149px;
        height: 184px;
    }
}
</style>
