import { userModule } from '@/store/user/module'
import { bundleModule } from '@/store/bundle/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { quizModule } from '@/store/quiz/module'
import { stripeModule } from '@/store/stripe/module'
import { subscriptionModule } from '@/store/subscription/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { isSupportWindow } from '@/utils'
import type { RouteLocationNormalizedLoaded } from 'vue-router'

const intercomSettings = () => {
    const currentUser = userModule.state.user
    const selectedBundle = bundleModule.getters.getCurrentBundle()
    const selectedExam = examMetadataModule.getters.getCurrentExamMetadata()

    if (
        import.meta.env.VUE_APP_INTERCOM_APP_ID
        && currentUser
        && selectedBundle
        && !isSupportWindow()
        && selectedExam
    ) {
        const scheduledExamDate = userExamMetadataModule.getters.getCurrentUserExamMetadata()?.scheduledExamDate?.iso

        const quizResultsCount = quizModule.getters.getQuizzes().length

        const intercomSettingsObj: Partial<IIntercomSettings> = {
            'app_id': import.meta.env.VUE_APP_INTERCOM_APP_ID,
            'email': currentUser.email,
            'user_id': currentUser.objectId,
            'hide_default_launcher': true,
            'App Name': selectedBundle.name,
            'Exam Date_at': scheduledExamDate ? new Date(scheduledExamDate) : null,
            'Exam Name': selectedExam.nativeAppName,
            'Exam Version': selectedExam.version,
            'Exams Taken': quizResultsCount,
            Name: userModule.getters.userFullName(),
        }

        const activeSub = subscriptionModule.getters.getSubscriptionForExamId()

        if (activeSub && typeof activeSub !== 'boolean') {
            // Currently "Subscription Created_at" is the start of the current renewal cycle
            // but at some point we may want to store the date that the user actually first subscribed
            intercomSettingsObj['Subscription Created_at'] = new Date(activeSub.createdAt)
            intercomSettingsObj['Subscription Expires_at'] = new Date(activeSub.expiresAt.iso)

            const receipt = activeSub.receipt
            if ('source' in receipt) {
                intercomSettingsObj['Subscription Source'] = receipt.source
                // We should only update "Subscription Plan" from web if it's a Stripe Subscription
                if (receipt.source === 'Stripe') {
                    const stripeSub = stripeModule.getters.getStripeSubscriptions()
                        .find(s => s.id === activeSub.guid)
                    intercomSettingsObj['Subscription Plan'] = 
                    stripeSub?.items.data[0]?.plan.nickname || null
                }
            }
            
            intercomSettingsObj['Trial'] = !!activeSub.isTrial
        } else {
            intercomSettingsObj['Subscription Created_at'] = null
            intercomSettingsObj['Subscription Expires_at'] = null
            intercomSettingsObj['Subscription Source'] = null
            intercomSettingsObj['Subscription Plan'] = null
            intercomSettingsObj['Trial'] = null
        }

        return intercomSettingsObj
    } else {
        return {
            'app_id': import.meta.env.VUE_APP_INTERCOM_APP_ID,
            'hide_default_launcher': true,
        }
    }
}

const apmCustomContext = () => {
    const currentUser = userModule.state.user
    const bundle = bundleModule.getters.getCurrentBundle()
    const examMetadata = examMetadataModule.getters.getCurrentExamMetadata()
    const subscription = subscriptionModule.getters.getSubscriptionForExamId()
    const hasSubscription = !!subscription

    return {
        custom: {
            isPremium: hasSubscription,
            examName: examMetadata?.nativeAppName,
            bundleName: bundle?.name, 
        },
        user: {
            id: currentUser?.objectId,
            username: currentUser?.email,
            email: currentUser?.email,
        },
    }
}

const getScreenName = (route: RouteLocationNormalizedLoaded) => {
    const screenKeyNameMap: Record<string, string> = {
        'register__onboarding' : 'Onboarding Testimonial',
        'register__account_details': 'Onboarding Create Account',
        'sign-in__password': 'Onboarding Password Login',
        'sign-in': 'Onboarding Magic Email',
        'email-auth': 'Onboarding Magic Email Code',
        'register__industry': 'Onboarding Select Bundle',
        'register__exam': 'Onboarding Select Exam',
        'register__account': 'Onboarding Confirm Exam',
        'register__plan': 'Onboarding Purchase',
        'register__payment': 'Onboarding Purchase Payment',
        'study': 'Study Tab',
        'stats': 'Stats Tab',
        'review': 'Review Tab',
        'account': 'Settings Tab',
        'exams': 'Settings Tab',
        'purchases': 'Settings Tab',
        'share': 'Settings Tab',
        'question-review': 'Question Review',
        'mock-exam-intro': 'Mock Exam',
        'level-up-intro': 'Level Up Quiz',
        'byoq': 'Custom Quiz',
    }

    let screenKey = String(route.name) || ''
    if (route.name === 'register' && typeof route.query.step === 'string') {
        screenKey += `__${route.query.step}`
    }
    if (route.name === 'sign-in' && typeof route.query.type === 'string') {
        screenKey += `__${route.query.type}`
    }

    const screenName = screenKeyNameMap[screenKey]
    return screenName
}

export default {
    intercomSettings,
    apmCustomContext,
    getScreenName,
}
