import type { Study } from '@pocketprep/types'
import { Parse } from '@/store/parseUtils'
import { initLoadable } from '@/store/utils'
import type { Loadable } from '@/store/utils'
import * as Sentry from '@sentry/browser'
import { analyticsModule } from '@/store/analytics/module'

export interface IUserState {
    user: Study.Class.UserJSON | null
    settings: {
        subjectInsightsSort: 'weakest' | 'strongest' | 'alphabetical'
        isDarkMode: boolean
    }
    userData: Loadable<null>
    dismissables: Record<string, unknown>
    referralInfo: TReferralInfo | null
    updatingWebConfig: boolean
}

export type TReferralInfo = {
    redeemedUsersCount: number
    code: string
}

export const getInitialUserState = (): IUserState => {
    const parseUserFromLocalStorage = Parse.User.current<Study.Class.User>()
    const localSettingsRaw = localStorage.getItem('settings')
    const localSettings = localSettingsRaw
        ? JSON.parse(localSettingsRaw) as IUserState['settings']
        : undefined
    if (localSettings?.isDarkMode) {
        document.querySelector('body')?.classList.add('dark')
    }

    const referralInfoString = localStorage.getItem('referralInfo')
    const localStorageReferralInfo: TReferralInfo = referralInfoString && JSON.parse(referralInfoString)

    // update Sentry user context if signed in
    const user = parseUserFromLocalStorage?.toJSON() || null
    if (user) {
        Sentry.setUser({ 
            id: user.objectId,
            email: user.email,
        })
        analyticsModule.actions.setSprigUser({
            id: user.objectId,
            email: user.email,
        })
        analyticsModule.actions.setAmplitudeUser({
            id: user.objectId,
            email: user.email,
            createdAt: user.createdAt,
        })
    }

    return {
        user,
        settings: localSettings || {
            subjectInsightsSort: 'weakest',
            isDarkMode: false,
        },
        dismissables: {},
        userData: initLoadable(null),
        referralInfo: localStorageReferralInfo || null,
        updatingWebConfig: false,
    }
}

const moduleState = getInitialUserState()

export default moduleState
