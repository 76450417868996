<template>
    <PocketButton
        :is-dark-mode="isDarkMode"
        class="skip-to-content"
        @click="skipToContent"
    >
        Skip to Content
    </PocketButton>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component } from 'vue-facing-decorator'

@Component({
    components: {
        PocketButton: UIKit.Button,
    },
})
export default class SkipToContent extends Vue {
    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    skipToContent () {
        const headEl = (document.querySelector('.head')
            || document.querySelector('.uikit-quiz-container__header')) as HTMLElement | null

        if (headEl) {
            const bodyEl = headEl.nextElementSibling as HTMLElement
            bodyEl?.focus()
        }
    }
}
</script>

<style lang="scss" scoped>
.skip-to-content {
    position: absolute;
    left: -1000px;
    top: 10px;
    z-index: 10000;

    &:focus {
        left: 15px;
    }

    @include breakpoint(black-bear) {
        display: none;
    }
}
</style>