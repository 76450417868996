export type TToastState = {
    toast: {
        title: string
        subtext?: string
    } | null
}

export const getInitialToastState = (): TToastState => {
    return {
        toast: null,
    }
}

const moduleState = getInitialToastState()

export default moduleState
