import { Parse } from '@/store/parseUtils'
import type { Study } from '@pocketprep/types'
import { bundleModule } from '@/store/bundle/module'
import { fetchLoadable } from '@/store/utils'

const fetchBundles = async () => {
    const bundlesLoadable = bundleModule.state.bundles

    await fetchLoadable(bundlesLoadable, async () => {
        const bundles = await new Parse.Query<Study.Class.Bundle>('Bundle')
            .equalTo('isDisabled', false)
            .findAll()
        return bundles.map(b => b.toJSON())
    })
}

export default {
    fetchBundles,
}