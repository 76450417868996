<template>
    <div
        v-dark
        class="review-tab"
        :class="{ 'review-tab--active': isActive }"
        tabindex="0"
        :aria-label="`${count} ${label} questions`"
        @keydown.enter="emitKeydown($event)"
        @click="emitClick"
        @mousedown.prevent
    >
        <slot name="content">
            <div class="review-tab__label" aria-hidden="true">
                <slot name="label">
                    {{ label }}
                </slot>
            </div>
            <div class="review-tab__count" aria-hidden="true">
                <slot name="count">
                    {{ count }}
                </slot>
            </div>
        </slot>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'

@Component
export default class ReviewTab extends Vue {
    @Prop({ default: false }) isActive!: boolean
    @Prop({ default: 'All' }) label!: string
    @Prop({ default: 0 }) count!: number

    @Emit('keydown')
    emitKeydown (keyEvent: KeyboardEvent) {
        return keyEvent
    }

    @Emit('click')
    emitClick () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.review-tab {
    width: 84px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-bottom: 4px solid $white;
    color: $slate-01;
    margin-right: 2px;
    cursor: pointer;
    user-select: none;
    flex-direction: column;
    outline: none;
    font-weight: 600;

    &:hover {
        color: $brand-blue;
    }

    &:focus::before {
        content: '';
        position: absolute;
        left: 0;
        top: -7px;
        width: 83px;
        height: 56px;
        border: 1px solid $brand-blue;
        border-radius: 6px;
        box-sizing: border-box;
    }

    &--dark {
        border-bottom-color: $brand-black;
        color: $pewter;

        &:hover {
            color: $banana-bread;
        }

        &:focus::before {
            border-color: $banana-bread;
        }
    }

    &--active {
        border-bottom-color: $brand-black;
        color: $brand-black !important;

        &--dark {
            border-bottom-color: $white;
            color: $white !important;
        }
    }

    &__label {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.39px;
        margin-bottom: 2px;
    }

    &__count {
        font-size: 25px;
        line-height: 30px;
        letter-spacing: -0.5px;
        padding-bottom: 5px;
    }
}
</style>
