<script setup lang="ts">
import { screenModule } from '@/store/screen/module'
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { computed, onMounted, ref } from 'vue'
import ReadinessModalA from '@/components/Stats/ReadinessModalA.vue'
import ReadinessModalB from '@/components/Stats/ReadinessModalB.vue'
import ReadinessExamDateModal from '@/components/Stats/ReadinessExamDateModal.vue'
import ReadinessSurvey from '@/components/Stats/ReadinessSurvey.vue'
import { analyticsModule } from '@/store/analytics/module'
import { toastModule } from '@/store/toast/module'
import { quizModule } from '@/store/quiz/module'

type TModals = 'examDate' | 'progress-bar' | 'on-off-track' | 'survey'

const {
    InfoMessage,
    Link,
    Tag,
} = UIKit

const props = defineProps<{
    readinessScore: number
    percentQuestionBankComplete: number
    daysUntilExam: number | null
}>()

const activeReadinessModal = ref<TModals| null>(null)

const isUpdatingExamDate = ref(false)

const readinessExperimentValue = ref<'progress-bar' | 'on-off-track' | null>(null)

const isDarkMode = computed(() => {
    return userModule.state.settings.isDarkMode
})

const breakpoint = computed(() => {
    return screenModule.getters.getBreakpoint()
})

const isMobile = computed(() => {
    return breakpoint.value === 'black-bear'
})

const tagGap = computed(() => {
    return isMobile.value ? '6px' : '10px'
})

const isEligibleForReadinessSurvey = computed(() => {
    const surveyTimestamps = sawReadinessSurveyTimestamps.value

    // if never seen survey, always try to show survey
    if (!surveyTimestamps.length) {
        return true
    }

    // if seen survey two times, don't show survey
    if (surveyTimestamps.length >= 2) {
        return false
    }

    // if seen survey within last 24 hours, don't show survey
    const lastSawReadinessSurveyTimestamp = sawReadinessSurveyTimestamps
        .value[sawReadinessSurveyTimestamps.value.length - 1]
    if (lastSawReadinessSurveyTimestamp) {
        const lastSawSurveyTimestamp = new Date(lastSawReadinessSurveyTimestamp).getTime()
        const nowTimestamp = Date.now()
        const numOfMsIn24Hours = 24 * 60 * 60 * 1000
    
        if ((nowTimestamp - lastSawSurveyTimestamp) < numOfMsIn24Hours) {
            return false
        }
    }

    // (if we're here, then we've seen the survey exactly once more than 24 hours ago)
    // if seen readiness less than 5 times total, don't show survey
    const timesSawReadiness = sawExamReadinessCount.value
    if (timesSawReadiness < 4) {
        return false
    }

    return true
})

const orderedQuizzes = computed(() => {
    return [ ...quizModule.state.quizzes ]
        .filter(q => (q.mode !== -1 && q.mode !== 5))
        .sort((a, b) => new Date(a.startedAt.iso).getTime() - new Date(b.startedAt.iso).getTime())
})

const lastQuizDate = computed(() => {
    return orderedQuizzes.value.length
        ? new Date(orderedQuizzes.value[orderedQuizzes.value.length - 1].startedAt.iso)
        : null
})

const isActiveLast10Days = computed(() => {
    const tenDaysAgoMillis = new Date().getTime() - (10 * 24 * 60 * 60 * 1000)
    return !!lastQuizDate.value && (lastQuizDate.value.getTime() > tenDaysAgoMillis)
})

const sawExamReadinessCount = computed(() => {
    return userModule.state.user?.webConfig?.sawExamReadinessCount || 0
})

const sawReadinessSurveyTimestamps = computed(() => {
    return userModule.state.user?.webConfig?.sawReadinessSurveyTimestamps || []
})

const submitReadinessFeedback = (params: {
    isHelpful: string | undefined
    context: string
}) => {
    analyticsModule.actions.amplitudeTrack('Readiness Score Survey Answered', {
        isHelpful: params.isHelpful,
        context: params.context,
    })
    
    toastModule.actions.displayToast({
        title: 'Thank you for your feedback!',
    })

    activeReadinessModal.value = null
}

const openReadinessModal = () => {
    if (props.daysUntilExam === null) {
        activeReadinessModal.value = 'examDate'
    } else {
        activeReadinessModal.value = readinessExperimentValue.value
    }
    analyticsModule.actions.amplitudeTrack('Readiness Calculate Button Clicked')
}

const closeReadinessModal = () => {
    activeReadinessModal.value = null
    isUpdatingExamDate.value = false

    userModule.actions.updateWebConfig({
        sawExamReadinessCount: sawExamReadinessCount.value + 1,
    })
}

onMounted(async () => {
    const readinessVariant = await analyticsModule.actions.amplitudeFetchVariant('readiness-experiment')
    const value = readinessVariant?.value as 'progress-bar' | 'on-off-track'
    readinessExperimentValue.value = value || null

    await userModule.actions.fetchUserData()

    analyticsModule.actions.amplitudeTrack('Readiness Banner Viewed', {
        readinessScore: props.readinessScore,
        percentQuestionBankComplete: props.percentQuestionBankComplete,
        daysUntilExam: props.daysUntilExam || undefined,
        isActiveLast10Days: isActiveLast10Days.value,
    })
})

</script>

<template>
    <ReadinessExamDateModal
        v-if="activeReadinessModal === 'examDate'"
        @close="activeReadinessModal = null"
        @next="() => { isUpdatingExamDate = true; openReadinessModal() }"
    />
    <ReadinessModalA
        v-else-if="activeReadinessModal === 'progress-bar'"
        :is-updating-exam-date="isUpdatingExamDate"
        :is-eligible-for-survey="isEligibleForReadinessSurvey"
        :readinessScore="readinessScore"
        :percentQuestionBankComplete="percentQuestionBankComplete"
        :isActiveLast10Days="isActiveLast10Days"
        :daysUntilExam="daysUntilExam || 0"
        @close="closeReadinessModal"
        @previous="activeReadinessModal = 'examDate'"
        @next="activeReadinessModal = 'survey'"
    />
    <ReadinessModalB
        v-else-if="activeReadinessModal === 'on-off-track'"
        :is-updating-exam-date="isUpdatingExamDate"
        :is-eligible-for-survey="isEligibleForReadinessSurvey"
        :readinessScore="readinessScore"
        :percentQuestionBankComplete="percentQuestionBankComplete"
        :isActiveLast10Days="isActiveLast10Days"
        :daysUntilExam="daysUntilExam || 0"
        @close="closeReadinessModal"
        @previous="activeReadinessModal = 'examDate'"
        @next="activeReadinessModal = 'survey'"
    />
    <ReadinessSurvey
        v-else-if="activeReadinessModal === 'survey'"
        @close="closeReadinessModal"
        @previous="activeReadinessModal = readinessExperimentValue"
        @submit="submitReadinessFeedback"
    />
    <div
        class="readiness-banner"
        :class="{
            'readiness-banner--tablet': breakpoint === 'brown-bear',
            'readiness-banner--mobile': isMobile,
        }"
    >
        <InfoMessage :isDarkMode="isDarkMode">
            <div
                class="readiness-banner__content-container"
                :class="{'readiness-banner__content-container--mobile': isMobile}"
            >
                <div>
                    <Tag
                        :isBetaTag="true"
                        :isDarkMode="isDarkMode"
                        :size="isMobile && 'small'"
                        :style="{marginRight: tagGap}"
                    >
                        <template #tagMessage>
                            Beta
                        </template>
                    </Tag>
                    <span>Calculate your exam readiness based on study activity, performance data, and exam date.</span>
                </div>
                <Link
                    class="readiness-banner__calculate-button"
                    :isDarkMode="isDarkMode"
                    @click="openReadinessModal"
                >
                    Calculate
                </Link>
            </div>
        </InfoMessage>
    </div>
</template>

<style lang="scss" scoped>
.readiness-banner {
    margin: 40px auto -5px;
    width: 978px;

    :deep(.uikit-tag) {
        margin-left: 0;
    }

    &--tablet {
        width: 724px;
        margin: 36px auto -13px;
    }

    &--mobile {
        width: calc(100% - 36px);
        margin: 30px auto -14px;
    }

    &__content-container {
        display: flex;
        justify-content: space-between;

        &--mobile {
            flex-direction: column;
            gap: 8px;
        }
    }

    &__calculate-button {
        line-height: 20px;
        margin-right: 8px;
    }
}
</style>
