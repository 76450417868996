<!-- eslint-disable -->
<template>
    <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Facebook icon</title>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-232.000000, 0.000000)" fill="currentColor">
                <g transform="translate(191.000000, 0.000000)">
                    <path d="M55,9.9475983e-14 C62.7319865,9.9475983e-14 69,6.2680135 69,14 C69,21.7319865 62.7319865,28 55,28 C47.2680135,28 41,21.7319865 41,14 C41,6.2680135 47.2680135,9.9475983e-14 55,9.9475983e-14 Z M58.1675934,6.63636364 L56.0964697,6.63636364 C53.6080866,6.63636364 52.7327902,7.86037158 52.7327902,9.9176287 L52.7327902,9.9176287 L52.7327902,11.4317034 L51.1818182,11.4317034 L51.1818182,13.9536217 L52.7327902,13.9536217 L52.7327902,21.2727273 L55.8361567,21.2727273 L55.8361567,13.9536217 L57.9072805,13.9536217 L58.1818182,11.4317034 L55.8361567,11.4317034 L55.8404242,10.1693586 C55.8404242,9.51162757 55.9044355,9.15920566 56.8731411,9.15920566 L56.8731411,9.15920566 L58.1675934,9.15920566 L58.1675934,6.63636364 Z"></path>
                </g>
            </g>
        </g>
    </svg>
</template>
<!-- eslint-enable -->

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class Facebook extends Vue {

}
</script>