import { initLoadable } from '@/store/utils'
import type { Loadable } from '@/store/utils'

export type TProgressState = {
    progressByExamGuid: Loadable<{ [examGuid: string]: { answered: number; total: number } }>
}

export const getInitialProgressState = (): TProgressState => {
    return {
        progressByExamGuid: initLoadable({}),
    }
}

const moduleState = getInitialProgressState()

export default moduleState
