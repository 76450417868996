<template>
    <div
        :key="exam.version"
        v-dark
        class="exam-version-card"
        :class="{ 
            'exam-version-card--current': isSelectedVersion && (reviewingVersions || addingExam),
            'exam-version-card--another-version': !isSelectedVersion && (reviewingVersions || addingExam)
        }"
        tabindex="0"
        @click="emitClickExamVersion(exam)"
        @keydown.enter="emitClickExamVersion(exam)"
        @mousedown.prevent
    >
        <div class="exam-version-card__header">
            <div 
                v-dark 
                class="exam-version-card__name"
                :class="{ 
                    'exam-version-card__name--reviewing-versions': !addingExam,
                }"
            >
                {{ exam.releaseInfo && exam.releaseInfo.name }}
            </div>
            <PocketLink
                v-dark
                v-if="!hasMultipleVersions && !reviewingVersions && !addingExam"
                href="https://help.pocketprep.com/en/articles/3697276-where-do-your-practice-questions-come-from"
                target="_blank"
                class="exam-version-card__learn-more-link"
                aria-label="Learn more. Open help center article in browser view."
                @mousedown.prevent
            >
                Learn More
                <Icon type="externalLink" />
            </PocketLink>
            <PocketLink
                v-dark
                v-if="hasMultipleVersions && !reviewingVersions && !addingExam"
                @click="emitClickReviewExamVersions"
                class="exam-version-card__link"
                @mousedown.prevent
            >
                Review Versions
            </PocketLink>
        </div>
        <div v-dark class="exam-version-card__info">
            <span
                v-if="!reviewingVersions || addingExam"  
                v-dark
                class="exam-version-card__info-current"
            >
                Version: {{ exam.version }}
            </span>
            <span
                v-if="reviewingVersions && isActiveExam"  
                v-dark
                class="exam-version-card__info-current"
            >
                {{ exam.version }}: Current Version
            </span>
            <span
                v-if="reviewingVersions && isLatestVersion && !isActiveExam"  
                v-dark
                class="exam-version-card__info-current"
            >
                {{ exam.version }}: New Version
            </span>
            <span
                v-if="reviewingVersions && !isLatestVersion && !isActiveExam"  
                v-dark
                class="exam-version-card__info-current"
            >
                {{ exam.version }}
            </span>
        </div>
        <div 
            v-if="!reviewingVersions && !addingExam" 
            v-dark 
            class="exam-version-card__description"
        >
            {{ exam.releaseInfo && exam.releaseInfo.description }}
        </div>
        <div 
            v-if="addingExam || reviewingVersions" 
            v-dark 
            class="exam-version-card__description"
        >
            {{ exam.description }}
        </div>
    </div>
</template>

<script lang="ts">
import type { Study } from '@pocketprep/types'
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        PocketLink: UIKit.Link,
        Icon: UIKit.Icon,
    },
})

@Component
export default class ExamVersionCard extends Vue {
    @Prop() exam!: Study.Class.ExamMetadataJSON
    @Prop() isSelectedVersion!: boolean
    @Prop() isLatestVersion!: boolean
    @Prop({ default: null }) isActiveExam!: boolean
    @Prop({ default: false }) reviewingVersions!: boolean
    @Prop({ default: false }) addingExam!: boolean
    @Prop({ default: false }) hasMultipleVersions!: boolean

    @Emit('clickExamVersion')
    emitClickExamVersion (exam: Study.Class.ExamMetadataJSON) {
        return exam
    }

    @Emit('clickReviewExamVersions')
    emitClickReviewExamVersions () {
        return true
    }    
}
</script>

<style lang="scss" scoped>
.exam-version-card {
    box-shadow: 0 1px 6px 0 rgba($gray-divider, 0.6);
    background: $white;
    border-radius: 5px;
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
    padding: 14px 15px;
    box-sizing: border-box;
    margin-bottom: 16px;
    user-select: none;
    position: relative;
    outline: none;

    &::before {
        content: '';
        display: none;
        left: -2px;
        top: -2px;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        position: absolute;
    }

    &--another-version {
        cursor: pointer;
        &:hover::before {
            display: block;
            border: 2px solid $slate-01;
        }

        &:focus::before {
            display: block;
            left: -1px;
            top: -1px;
            border: 1px solid $brand-blue;
        }
    }

    &--dark {
        background-color: $moonlit-ocean;
        box-shadow: 0 1px 6px 0 rgba($charcoal, 0.3);
    }

    &--current {
        &::before {
            display: block;
            left: -2px !important;
            top: -2px !important;
            border: 2px solid $brand-blue !important;
        }

        &--dark::before {
            border-color: $banana-bread !important;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    &__name {
        font-size: 17px;
        line-height: 20px;
        font-weight: 600;

        &--reviewing-versions {
            width: 235px;
        }
    }

    &__link {
        display: flex;
    }

    &__learn-more-link {
        display: flex;
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        text-decoration: none;
        margin-bottom: 5px;
        padding: 2px 0;

        svg {
            vertical-align: top;
            margin-left: 5px;
            width: 9px;
            height: 10px;
        }
    }

    &__info {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.3px;
        margin-bottom: 13px;
        color: $pickled-bluewood;

        &--dark {
            color: $fog;
        }

        span {
            font-weight: 600;
        }
    }

    &__info-dot {
        margin: 0 3px;
        color: $slate;
    }

    &__info-current {
        font-weight: 500 !important;

        &--latest {
            color: $spectral-green;

            &--dark {
                color: $jungle-green;
            }
        }
    }

    &__description {
        font-size: 15px;
        line-height: 19px;
        color: $slate-01;
        white-space: pre-wrap;

        &--dark {
            color: $white;
        }
    }
}
</style>