<template>
    <Portal to="sidepanel">
        <SidePanel
            :key="`addPaymentMethodSidePanel-${String($.vnode.key)}`"
            class="add-payment-method"
            name="Add Payment Method"
            width="medium"
            :disable-transition="disableTransition"
            :is-dark-mode="isDarkMode"
            :class="{ 'add-payment-method--full-height': showFullHeight }"
            @close="emitClose"
        >
            <div class="add-payment-method__title">
                Add Credit/Debit Card
            </div>
            <PaymentFields
                :errors="errors"
                :error-fields="errorFields"
                :plan-amount="0"
                class="add-payment-method__fields"
                @submit="addPaymentMethod"
                @update="updatePaymentParams"
            />
            <template #action>
                <PocketButton
                    :disabled="!paymentFieldsCompleted"
                    :is-dark-mode="isDarkMode"
                    :is-loading="isLoading"
                    @click="addPaymentMethod"
                >
                    Save Payment
                </PocketButton>
            </template>
        </SidePanel>
    </Portal>
</template>

<script lang="ts">
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import PaymentFields from '@/components/PaymentFields.vue'
import { stripeModule } from '@/store/stripe/module'
import { userModule } from '@/store/user/module'
import { toastModule } from '@/store/toast/module'
import { stripeEnabledAppearance, stripeDisabledAppearance } from '@/utils'
import { type StripeElement } from '@stripe/stripe-js'

@Component({
    components: {
        SidePanel: UIKit.SidePanel,
        PocketButton: UIKit.Button,
        PocketInput: UIKit.Input,
        PocketSelect: UIKit.Select,
        PaymentFields,
    },
})
export default class AddPaymentMethodSidePanel extends Vue {
    @Prop({ default: false }) disableTransition!: boolean
    @Prop({ default: false }) showFullHeight!: boolean

    isLoading = false
    errors: string[] = []
    errorFields: string[] = []
    addressEl: StripeElement | null | void = null
    cardElCompleted = false
    addressElCompleted = false
    paymentFieldsCompleted = false
    stripe: stripe.Stripe | null = null

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    updatePaymentParams (params: {
        addressEl: StripeElement | null | void
        cardElCompleted: boolean
        addressElCompleted: boolean
    }) {
        this.addressEl = params.addressEl
        this.cardElCompleted = params.cardElCompleted
        this.addressElCompleted = params.addressElCompleted

        if (this.cardElCompleted && this.addressElCompleted) {
            this.paymentFieldsCompleted = true
        }
    }

    async addPaymentMethod () {
        this.isLoading = true
        this.errors = []
        if (import.meta.env.VUE_APP_STRIPE_PUBLISHABLE_KEY) {
            const { stripe, elements } = await stripeModule.actions.loadStripeAndElements({
                planAmount: 0,
                creatingPaymentField: false,
            })
            const stripeClientSecret = await stripeModule.actions.createSetupIntent()

            if (stripe && elements && stripeClientSecret) {
                const disabledAppearance = stripeDisabledAppearance(this.isDarkMode)
                const enabledAppearance = stripeEnabledAppearance(this.isDarkMode)

                await elements.submit()
                const paymentStripeEl = elements.getElement('payment')
                paymentStripeEl?.update({ readOnly: true })
                stripeModule.getters.getElements()?.update({ appearance: disabledAppearance })

                const result = await stripe.confirmSetup({
                    clientSecret: stripeClientSecret,
                    elements,
                    confirmParams: {
                        return_url: `${window.location.href}`,
                    },
                    redirect: 'if_required',
                })

                if (result.error) {
                    paymentStripeEl?.update({ readOnly: false })
                    stripeModule.getters.getElements()?.update({ appearance: enabledAppearance })
                    this.errors.push('Unable to process credit card information. Please try again or contact support.')
                } else {
                    await stripeModule.actions.loadNewPaymentMethods()
                    toastModule.actions.displayToast({
                        title: 'Card added successfully',
                    })
                    this.emitClose()
                }
            }
        }
        this.isLoading = false
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('error')
    emitError () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.add-payment-method {
    &--full-height {
        top: 0;
        height: 100%;
    }

    &__license-code {
        margin-bottom: 29px;
        padding: 0;
    }

    &__fields {
        margin-left: -12px;
        margin-right: -12px;
    }

    &__title {
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 17px;
    }
}
</style>