import { examMetadataModule } from '@/store/examMetadata/module'
import { runCloudFunction } from '@/store/parseUtils'
import { fetchLoadable, resetLoadable } from '@/store/utils'
import { keywordsModule } from '@/store/keywords/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'

type FetchKeywordDefinitions = (params: {
    serials: string[]
    examGuid: string
    examMetadataId?: string
}) => Promise<{
    keyword: string
    definition: string
    serial: string
}[]>
const fetchKeywordDefinitions = async (serials: string[], forceFetch?: boolean, examGuid?: string) => {
    await Promise.all([
        examMetadataModule.actions.fetchExamMetadata(),
        userExamMetadataModule.actions.fetchUserExamMetadata(),
    ])

    const currentExamMetadata = examMetadataModule.getters.getCurrentExamMetadata()
    const currentExamGuid = currentExamMetadata?.examGuid || examGuid
    const disabledKeywordExamGuids = import.meta.env.VUE_APP_DISABLED_KEYWORD_EXAMGUIDS
    if (!currentExamGuid || disabledKeywordExamGuids?.includes(currentExamGuid)) {
        return
    }

    // if keywords are already fetched for serials, we'll just return them, but otherwise we need
    // to clear out store and fetch again
    const currentKeywords = keywordsModule.getters.getKeywordDefinitions()
    const missingKeywords = serials.filter(k => !currentKeywords.has(k))

    if (missingKeywords.length) {
        resetLoadable(keywordsModule.state.keywordDefinitions)
    }

    return fetchLoadable(keywordsModule.state.keywordDefinitions, async () => {
        const keywordDefinitionsArr = await runCloudFunction<FetchKeywordDefinitions>('fetchKeywordDefinitions', {
            examGuid: currentExamGuid,
            serials,
            examMetadataId: currentExamMetadata?.objectId,
        })

        return keywordDefinitionsArr.reduce((acc, kd) => {
            if (!acc.has(kd.serial)) {
                acc.set(kd.serial, new Map())
            }

            acc.get(kd.serial)?.set(kd.keyword, kd.definition)
            return acc
        }, new Map<string, Map<string, string>>())
    }, forceFetch)
}

type SubmitKeywordDefinitionVote = (params: {
    keyword: string
    examGuid: string
    serial: string
    vote: 'helpful' | 'unhelpful'
    helpfulVoteChange: number
    unhelpfulVoteChange: number
}) => Promise<void>
const submitKeywordDefinitionVote = 
    async (params: {
        keyword: string
        vote: 'helpful' | 'unhelpful'
        serial: string
        helpfulVoteChange: number
        unhelpfulVoteChange: number
    }) => {
        const currentExamMetadata = examMetadataModule.getters.getCurrentExamMetadata()
        if (!currentExamMetadata) {
            return
        }

        return runCloudFunction<SubmitKeywordDefinitionVote>('submitKeywordDefinitionVote', {
            examGuid: currentExamMetadata.examGuid,
            serial: params.serial,
            keyword: params.keyword,
            vote: params.vote,
            helpfulVoteChange: params.helpfulVoteChange,
            unhelpfulVoteChange: params.unhelpfulVoteChange,
        })
    }

export default {
    fetchKeywordDefinitions,
    submitKeywordDefinitionVote,
}
