<template>
    <div class="account-details">
        <LeftBlob class="account-details__left-blob" />
        <RightBlob class="account-details__right-blob" />
        <div class="account-details__info">
            To start, we need some basic info for your account and study personalization.
        </div>
        <PocketInput
            v-model="firstName"
            class="account-details__firstName account-details__input"
            label="First Name"
            aria-required="true"
            :error="errorFields.includes('firstName')"
            @keydown.enter="submitAccountDetails"
        />
        <PocketInput
            v-model="lastName"
            class="account-details__lastName account-details__input"
            label="Last Name"
            aria-required="true"
            :error="errorFields.includes('lastName')"
            @keydown.enter="submitAccountDetails"
        />
        <div class="account-details__email-container">
            <PocketInput
                v-model="email"
                class="account-details__email account-details__input"
                label="Email"
                field-type="email"
                aria-required="true"
                :error="errorFields.includes('email')"
                @keydown.enter="submitAccountDetails"
            />
            <div class="account-details__email-tip">
                <span class="account-details__email-tip-title">
                    <img src="@/assets/onboarding/lock.png" alt="">
                    Your data is safe with us
                </span>
                <div class="account-details__email-tip-description">
                    We use email to create your account and to sync your data across devices.
                </div>
            </div>
        </div>
        <PocketInput
            v-if="createPassword"
            ref="passwordInput"
            v-model="password"
            :error="errorFields.includes('password')"
            class="account-details__input"
            aria-required="true"
            label="Create Password (6+ characters)"
            field-type="password"
            @keydown.enter="submitAccountDetails"
        />
        <Errors
            v-if="errors.length"
            class="account-details__errors"
            :errors="errors"
        />
        <div class="account-details__create-password">
            <template v-if="!createPassword">
                <PocketButton
                    type="tertiary-small account-details__create-password-link"
                    @click="toggleCreatePassword"
                    @keydown.enter="toggleCreatePassword"
                    @mousedown.prevent
                >
                    Create a password
                </PocketButton>
                instead of using a link to sign in
            </template>
            <template v-else>
                <PocketButton
                    type="tertiary-small account-details__create-password-link"
                    @click="toggleCreatePassword"
                    @keydown.enter="toggleCreatePassword"
                    @mousedown.prevent
                >
                    Sign in with a link
                </PocketButton>
                instead of creating a password
            </template>
        </div>
        <div class="account-details__legal">
            <Checkbox
                v-model="agreed"
                class="account-details__legal-checkbox"
                :disabled="agreeDisabled"
                aria-label="I agree to the privacy policy and terms of service, required"
            />
            <div class="account-details__legal-text">
                I agree to the
                <a
                    target="_blank"
                    :href="`https://www.pocketprep.com/privacy-policy?${buildUTMParamString({
                        utm_campaign: 'app_settings',
                        utm_content: 'account_details_privacy_policy'
                    })}`"
                    class="account-details__legal-text-link"
                >privacy policy</a>
                and
                <a
                    target="_blank"
                    :href="`https://www.pocketprep.com/terms-of-service?${buildUTMParamString({
                        utm_campaign: 'app_settings',
                        utm_content: 'account_details_terms'
                    })}`"
                    class="account-details__legal-text-link"
                >terms of service</a>
            </div>
        </div>
        <PocketButton
            class="account-details__submit"
            :class="{ 'account-details__submit--referral': validReferral && createPassword }"
            :disabled="!firstName.trim() || !lastName.trim() || !email || !agreed || (createPassword && !password)"
            :is-loading="isLoading"
            @click="submitAccountDetails"
            @keydown.enter="submitAccountDetails"
        >
            {{ exam.isFree ? 'Start Studying' : 'Choose Your Plan' }} <Icon type="arrow" />
        </PocketButton>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import type { Study } from '@pocketprep/types'
import { isValidEmail } from '@/utils'
import LeftBlob from '@/assets/onboarding/LeftBlob.vue'
import RightBlob from '@/assets/onboarding/RightBlob.vue'
import { buildUTMParamString } from '@/store/utils'

@Component({
    components: {
        PocketInput: UIKit.Input,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
        Checkbox: UIKit.Checkbox,
        Errors: UIKit.Errors,
        LeftBlob,
        RightBlob,
    },
})
export default class AccountDetails extends Vue {
    @Prop() savedPassword!: string
    @Prop() exam!: Study.Class.ExamMetadataJSON
    @Prop() examVersions!: Study.Class.ExamMetadataJSON[]
    @Prop({ default: false }) validReferral!: boolean

    errors: string[] = []
    errorFields: string[] = []
    firstName = ''
    lastName = ''
    email = ''
    agreed = false
    password = ''
    createPassword = false
    agreeDisabled = false
    isValidEmail = isValidEmail
    isLoading = false

    mounted () {
        const registeredUser = userModule.state.user
        this.firstName = registeredUser?.firstName || ''
        this.lastName = registeredUser?.lastName || ''
        this.email = registeredUser?.email || ''
        this.agreed = !!registeredUser
        this.agreeDisabled = !!registeredUser
        this.password = this.savedPassword
        this.createPassword = !!this.password
    }

    buildUTMParamString = buildUTMParamString

    toggleCreatePassword () {
        this.createPassword = !this.createPassword
        
        if (!this.createPassword) {
            this.password = ''
        } else {
            setTimeout(() => {
                const passwordInputRef = this.$refs.passwordInput as typeof UIKit.Input | undefined
                const passwordInputEl = (passwordInputRef
                    && '$el' in passwordInputRef
                    && passwordInputRef.$el) as HTMLElement
                const inputEl = passwordInputEl?.querySelector('input')
                inputEl?.focus()
            }, 1)
        }
    }

    async submitAccountDetails () {
        this.errors = []
        this.errorFields = []
        this.isLoading = true

        try {
            const currentUser = userModule.state.user
            if (!this.firstName || !this.lastName) {
                if (!this.firstName) {
                    this.errorFields.push('firstName')
                }
                if (!this.lastName) {
                    this.errorFields.push('lastName')
                }
                this.errors.push('Please enter a first and last name.')
            }

            if (!this.agreed) {
                this.errors.push('You must agree to the terms of service and privacy policy to continue.')
            }

            if (!this.isValidEmail(this.email)) {
                this.errorFields.push('email')
                this.errors.push('Invalid email address.')
            }

            if (this.createPassword && this.password.length < 6) {
                this.errorFields.push('password')
                this.errors.push('Password must be at least 6 characters.')
            }

            if (!this.errors.length) {
                // check if there are multiple exam versions and, if so, mark as seen so we don't
                // show user the upgrade modal
                const upgradesSeen = []

                if (this.examVersions.length > 1) {
                    upgradesSeen.push(
                        ...this.examVersions
                            .slice(0, this.examVersions.length - 1)
                            .map(e => e.version.split('.')[0])
                    )
                }

                if (currentUser) {
                    await userModule.actions.updateUser({
                        email: this.email,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        password: this.password,
                        exam: this.exam,
                        upgradesSeen,
                    })
                } else {
                    await userModule.actions.createUser({
                        email: this.email,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        password: this.password,
                        exam: this.exam,
                        upgradesSeen,
                    })
                }

                this.emitSubmit({
                    password: this.password,
                })
            }
        } catch (err) {
            const error = (err as { error?: string }).error || (err as { message?: string }).message
            if (!err) {
                this.errors.push('Unable to create or update account.')
            } else if (error) {
                this.errors.push(error)
            } else if (err instanceof Error || typeof err === 'string') {
                this.errors.push(err.toString())
            }
            
        } finally {
            this.isLoading = false
        }
    }

    @Emit('submit')
    emitSubmit (submitInfo: { password: string }) {
        return submitInfo
    }
}
</script>
<style lang="scss" scoped>
.account-details {
    width: 320px;
    margin: 9px auto 0;

    &__left-blob {
        position: fixed;
        bottom: 0;
        left: 0;
        border-radius: 0 0 0 12px;

        @include breakpoint (black-bear) {
            display: none;
        }
    }

    &__right-blob {
        position: absolute;
        top: 0;
        right: 0;

        @include breakpoint (black-bear) {
            display: none;
        }
    }

    &__info {
        color: $slate-03;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        margin-bottom: 36px;
    }

    &__email-container {
        position: relative;
        color: $slate-03;
    }

    &__email-tip {
        position: absolute;
        width: 199px;
        right: -249px;
        top: 5px;
        line-height: 18px;
        font-size: 13px;

        @include breakpoint (brown-bear) {
            width: 171px;
            right: -189px;
            top: 10px;
        }

        @include breakpoint (black-bear) {
            position: relative;
            width: 262px;
            top: auto;
            right: auto;
            margin-bottom: 30px;
            margin-left: 3px;
        }

        &::before {
            content: '';
            position: absolute;
            left: -68px;
            top: -9px;
            background: url(@/assets/onboarding/tip-arrow.svg) no-repeat center center;
            width: 65px;
            height: 14px;
            transform: rotate(-10deg);

            @include breakpoint (black-bear) {
                display: none;
            }
        }
    }

    &__email-tip-title {
        margin-bottom: 13px;
        font-weight: 600;
        display: block;

        @include breakpoint (brown-bear) {
            margin-bottom: 8px;
        }

        img {
            width: 16px;
            display: inline-block;
            margin-right: 5px;
            vertical-align: top;
            margin-top: -2px;
        }
    }

    &__create-password {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 32px;
        margin-left: 11px;

        @include breakpoint (black-bear) {
            margin-bottom: 24px;
        }
    }

    &__create-password-link {
        font-weight: 500;
        font-size: 13px;
    }

    &__legal {
        font-size: 13px;
        line-height: 18px;
        margin-left: 4px;
        display: flex;
        align-items: center;
    }

    &__legal-checkbox {
        margin-right: 9px;
        vertical-align: middle;
        margin-bottom: 2px;
    }

    &__legal-text-link {
        color: $brand-blue;
        text-decoration: none;
    }

    &__input,
    &__errors {
        margin-bottom: 17px;
    }

    &__submit {
        margin: 22px auto 6px;
        display: block;

        &--referral {
            margin-bottom: 16px;
        }

        svg {
            margin-left: 10px;
        }
    }
}
</style>