<template>
    <Portal to="modal">
        <ModalContainer
            key="addExamRequiredModal"
            :is-dark-mode="isDarkMode"
            @close="emitClose"
        >
            <template #modal>
                <Modal
                    class="add-exam-modal"
                    :show-close-button="true"
                    @close="emitClose"
                >
                    <div class="add-exam-modal__title">
                        Select Exam to Continue
                    </div>
                    <div class="add-exam-modal__message">
                        Before you can continue studying, please select the exam you wish to study.
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import { Vue, Component, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
    },
})
export default class AddExamRequired extends Vue {
    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    @Emit('close')
    emitClose () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.add-exam-modal {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    top: 80px;
    width: 392px;
    padding: 35px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakpoint(black-bear) {
        width: 356px;
        height: auto;
        max-height: 638px;
        top: 10px;
    }

    &__title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        width: 309px;
        margin: 0 auto 12px;
    }

    &__message {
        font-size: 15px;
        line-height: 22px;
        color: $slate-03;
        width: 309px;
    }
}
</style>