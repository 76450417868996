<template>
    <div>
        <KeywordDefinitionHint
            :isDarkMode="isDarkMode"
            v-if="[0, 4, 7].includes(seenQuizWithKeywordsCount)
                && !isLoading
                && !hasClickedKeyword
                && isFirstQuestionWithKeywordInQuiz
                && !currentAnswer?.checked
                && !showAnswersAtEnd
                && !isMobileOrTabletScreen"
        />
        <SkipToContent />
        <QuitQuizModal
            v-if="showQuitQuizModal && currentExam"
            :quiz-mode="mode"
            :num-answers="orderedCompleteAnswers.length"
            :quiz-length="quizLength"
            @close="showQuitQuizModal = false"
            @submit="submitQuiz(true)"
            @quit="quitQuiz"
        />
        <SubmitQuizModal
            v-if="showSubmitQuizModal && currentExam"
            :num-answers="orderedCompleteAnswers.length"
            :quiz-length="quizLength"
            @close="showSubmitQuizModal = false"
            @continue="showSubmitQuizModal = false"
            @submit="submitQuiz(true)"
        />
        <KeywordDefinition
            v-if="showKeywordDefinition && visibleKeywordDefinition && questionContainer"
            :keywordDOMElement="keywordDOMElement"
            :keywordClickLocation="keywordClickLocation"
            :keyword="visibleKeywordDefinition.keyword"
            :definition="visibleKeywordDefinition.definition"
            :questionContainer="questionContainer"
            :serial="currentQuestion.serial"
            :keywordLocation="visibleKeywordDefinition.location"
            @close="closeDefinition"
        />
        <AIFeedbackInfoModal
            v-if="showAIFeedbackInfoModal"
            @close="showAIFeedbackInfoModal = false"
        />
        <QuizContainer
            ref="quizContainer"
            class="quiz"
            :class="{ 'quiz--progress-bar': showProgressBar }"
            :is-dark-mode="isDarkMode"
            :custom-header-styles="showProgressBar && breakpoint === 'black-bear' && { height: '26px' }"
            :custom-main-styles="showProgressBar && breakpoint === 'black-bear' && { height: 'calc(100% - 80px)' }"
        >
            <template #header>
                <div class="quiz__exam-info">
                    <ExamMenuCard
                        v-if="currentExam && currentBundle"
                        :exam="currentExam"
                        :bundle-id="currentBundle.objectId"
                        theme="silver"
                        :is-clickable="false"
                        class="quiz__exam-info-card"
                    />
                </div>
                <div
                    v-if="showProgressBar && !showLevelUpResult && !showWeekly100ProgressMessage"
                    class="quiz__progress"
                >
                    <QuizProgress
                        :num-questions="quizLength"
                        :selected-index="questionIndex + 1"
                        :answered-index-list="answeredIndexList"
                        @barClicked="progressBarClicked"
                    />
                </div>
                <div
                    v-if="showTimer"
                    class="quiz__timer"
                >
                    <Icon class="quiz__timer-icon" type="hourglass" />
                    {{ timeDisplay }}
                </div>
                <PocketButton
                    v-if="!showLevelUpResult && !showWeekly100ProgressMessage"
                    class="quiz__quit-btn"
                    type="secondary-yellow-dark"
                    :is-dark-mode="isDarkMode"
                    @click="quitQuizClicked"
                >
                    Quit Quiz
                </PocketButton>
            </template>
            <template #question>
                <QuizError 
                    v-if="errorMessage"
                    :errorMessage="errorMessage"
                    :errorDescription="errorDescription"
                    @close="quitQuiz"
                />
                <LevelUpResult
                    v-else-if="!isLoading && showLevelUpResult"
                    :initialLevelProgress="initialLevelProgress"
                    :scoreIncrease="levelScoreIncrease"
                    @continue="goToQuizResults"
                />
                <div
                    v-else-if="!isLoading && showWeekly100ProgressMessage"
                    v-dark="isDarkMode"
                    class="quiz__weekly-100-container"
                >
                    <IconBadge
                        v-if="earnedWeekly100Badge"
                        class="quiz__weekly-100-badge-image"
                        v-dark="isDarkMode"
                        :show-blob="!isDarkMode"
                    />
                    <img
                        v-else
                        class="quiz__weekly-100-hiking-image"
                        src="@/assets/quiz/hiking.gif"
                    />
                    <div class="quiz__weekly-100-title">
                        {{ earnedWeekly100Badge ? 'You earned a Weekly 100 badge' : 'You\'re doing great!' }}
                    </div>
                    <div class="quiz__weekly-100-message-text">
                        {{
                            earnedWeekly100Badge
                                ? 'Great job answering 100 questions this week!'
                                : weekly100ProgressMessage
                        }}
                    </div>
                    <PocketButton
                        class="quiz__weekly-100-continue-btn"
                        :is-dark-mode="isDarkMode"
                        @click="goToQuizResults"
                    >
                        Continue
                    </PocketButton>
                </div>
                <Icon
                    v-else-if="isLoading || !currentExam"
                    v-dark
                    class="quiz__loading"
                    type="loading2"
                    :is-dark-mode="isDarkMode"
                />
                <Question
                    v-else-if="mode && currentQuestion && currentExam"
                    :key="`${currentQuestion.objectId}-${allowKeyboardShortcuts}`"
                    class="quiz__question"
                    :ref="`questionContainer_${currentQuestion.objectId}`"
                    :container-el="quizContainerEl"
                    :question="currentQuestion"
                    :question-number="questionIndex + 1"
                    :quiz-length="(mode === 'timed' && !secondsRemaining) ? questionIndex + 1 : quizLength"
                    :quiz-mode="mode"
                    :image-url-prefix="`${s3Url || ''}/${currentQuestion.compositeKey.toUpperCase()}/`"
                    :hide-answer="showAnswersAtEnd"
                    :show-check-answer="!showAnswersAtEnd && showCheckAnswerButton"
                    :previous-choices="previousChoices"
                    :previous-matrix-choices="previousChoices"
                    :initial-show-answers="
                        !showAnswersAtEnd 
                            && !!currentAnswer 
                            && (!showCheckAnswerButton || (showCheckAnswerButton && currentAnswer.checked))
                            && !(currentQuestion.type === 'Multiple Correct Response' && !currentAnswer.checked)
                            && !(currentQuestion.type === 'Matrix Checkbox' && !currentAnswer.checked)
                            && !(currentQuestion.type === 'Matrix Radio Button' && !currentAnswer.checked)
                    "
                    :allow-keyboard-shortcuts="allowKeyboardShortcuts"
                    :is-dark-mode="isDarkMode"
                    :answer-seed="currentQuestionSeed"
                    :hide-references="currentExam.hideReferences"
                    :keyword-definitions="keywordDefinitions"
                    @close="quitQuizClicked"
                    @previousQuestion="clickPrevious"
                    @nextQuestion="clickNext"
                    @checkAnswer="checkAnswer"
                    @selectedChoices="questionAnswered"
                    @submitQuiz="submitQuiz()"
                    @keywordClick="keywordClicked"
                    @update:showExplanation="explanationViewToggled"
                >
                    <template #explanationTopExperiment>
                        <KeywordDefinitionBanner
                            v-if="[0, 4, 7].includes(seenQuizWithKeywordsCount)
                                && !hasClickedKeyword
                                && ((isMobileOrTabletScreen && isFirstQuestionWithKeywordInQuiz)
                                    || isSecondQuestionWithKeywordInQuiz)
                                && currentAnswer"
                            :isMobile="breakpoint === 'black-bear'"
                            :isDarkMode="isDarkMode"
                        >
                        </KeywordDefinitionBanner>
                    </template>
                    <template
                        #tag
                        v-if="showLearningTag"
                    >
                        <div v-dark class="quiz__learning-tag">
                            Learning
                        </div>
                    </template>
                    <template #motivationalMoment="{
                        isCorrect,
                        choiceKey,
                        showAnswers,
                        answerKeys,
                    }">
                        <div
                            v-if="showMotivationalMoment
                                && motivationalMomentText
                                && showAnswers
                                && (!choiceKey || answerKeys.includes(choiceKey))
                                && isCorrect
                            "
                            class="quiz__motivational-moment"
                        >
                            <div v-dark class="quiz__motivational-moment-text">{{ motivationalMomentText }}</div>
                            <img
                                v-if="isDarkMode"
                                class="quiz__motivational-moment-image"
                                src="@/assets/quiz/motivational-dark.png"
                            />
                            <img
                                v-else
                                class="quiz__motivational-moment-image"
                                src="@/assets/quiz/motivational.png"
                            />
                        </div>
                    </template>
                    <template #actionExperiment>
                        <BonusQuestionTextarea
                            v-if="showExplanationTextArea"
                            class="quiz__desktop-bonus-question-explanation-textarea"
                            v-model="questionExplanation"
                            label="Why is this the correct answer?"
                            height="134px"
                            :show-icon-and-tooltip="breakpoint !== 'black-bear'"
                            :disabled="currentAnswer?.checked || !currentAnswer?.selectedChoices"
                            :is-dark-mode="isDarkMode"
                        />
                        <CheckboxOption
                            v-if="showExplanationTextArea && showAIFeedbackExperiment"
                            class="quiz__ai-feedback-checkbox"
                            v-model="giveAIFeedbackChecked"
                            label="Give me feedback on my response"
                            :is-dark-mode="isDarkMode"
                            :disabled="currentAnswer?.checked || !questionExplanation"
                        >
                            <template #labelContent>
                                Give me <PocketLink
                                    type="tertiary-small"
                                    :isDarkMode="isDarkMode"
                                    @click.stop="openAIFeedbackInfoModal('Give me feedback on my response')"
                                    @keydown.enter.stop
                                >feedback</PocketLink> on my response
                            </template>
                        </CheckboxOption>
                    </template>
                    <template #explanationBottomExperiment>
                        <template v-if="showExplanationTextArea && showAIFeedbackExperiment && questionExplanation">
                            <AIFeedback
                                v-if="currentQuestionAIFeedback?.success !== false"
                                :aiFeedback="currentQuestionAIFeedback?.feedback || ''"
                                :isLoading="isAIFeedbackLoading"
                                :isHelpfulVote="currentQuestionAIFeedback?.vote || null"
                                @generatedByAIClicked="openAIFeedbackInfoModal('Feedback generated by AI')"
                                @giveFeedbackClicked="giveFeedbackClicked"
                                @submitVote="submitAIFeedbackHelpfulVote"
                            />
                            <div v-else>
                                <div class="quiz__ai-feedback-error">
                                    <img
                                        v-if="isDarkMode"
                                        class="quiz__brain-error"
                                        src="@/assets/brain/brain-error-dm.png"
                                        alt="Brain error"
                                    >
                                    <img
                                        v-else
                                        class="quiz__brain-error"
                                        src="@/assets/brain/brain-error.png"
                                        alt="Brain error"
                                    >
                                    <div class="quiz__ai-feedback-error-message" v-dark>
                                        <template v-if="questionExplanation.split(' ').length <= 3">
                                            We couldn't generate feedback because your response was too short.
                                            Aim for at least 4 words when writing your responses.
                                        </template>
                                        <template v-else>
                                            We couldn't generate feedback for your response
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template
                        #action 
                        v-if="showSubmitQuizButton || (showAnswersAtEnd && isNextArrowDisabled)"
                    >
                        <PocketButton
                            :is-dark-mode="isDarkMode"
                            :disabled="orderedCompleteAnswers.length === 0"
                            @click="submitQuiz()"
                        >
                            Submit Quiz
                        </PocketButton>
                    </template>
                </Question>
            </template>
            <template v-if="!showLevelUpResult && !showWeekly100ProgressMessage" #footer>
                <div class="quiz__controls">
                    <Icon
                        class="quiz__previous-arrow"
                        :class="{
                            'quiz__previous-arrow--disabled': isPreviousArrowDisabled,
                        }"
                        :tabindex="isPreviousArrowDisabled ? -1 : 0"
                        type="paginationArrow"
                        role="button"
                        aria-label="Previous question"
                        :aria-disabled="isPreviousArrowDisabled"
                        @click="clickPrevious"
                        @keydown.enter.stop.prevent="clickPrevious"
                        @mousedown.prevent
                    />
                    <FlagToggle
                        :key="`flagToggle_${allowKeyboardShortcuts}`"
                        class="quiz__flag-toggle"
                        :is-flagged="isFlagged"
                        :disabled="errorMessage"
                        :is-dark-mode="isDarkMode"
                        :enable-flag-keyboard-shortcut="allowKeyboardShortcuts"
                        :enable-flag-tooltip="breakpoint !== 'brown-bear' && breakpoint !== 'black-bear'"
                        @toggleFlag="toggleFlag"
                    />
                    <Icon
                        :tabindex="isNextArrowDisabled ? -1 : 0"
                        role="button"
                        aria-label="Next question"
                        :aria-disabled="isNextArrowDisabled"
                        class="quiz__next-arrow"
                        :class="{
                            'quiz__next-arrow--disabled': isNextArrowDisabled,
                        }"
                        type="paginationArrow"
                        @click="clickNext"
                        @keydown.enter.stop.prevent="clickNext"
                        @mousedown.prevent
                    />
                </div>
                <KeyboardShortcutsButton
                    class="quiz__keyboard-shortcuts-btn"
                    :tooltip-theme="
                        (breakpoint === 'polar-bear' || breakpoint === 'grizzly-bear' || breakpoint === 'brown-bear') 
                            && 'leftalign'
                    "
                    :is-dark-mode="isDarkMode"
                    @keydown.enter.stop
                >
                    <template
                        #keyboardShortcutsModal="{ 
                            showKeyboardShortcutsModal,
                            closeModalFunc
                        }"
                    >
                        <Portal to="modal">
                            <KeyboardShortcutsModal
                                v-if="showKeyboardShortcutsModal"
                                key="quizKeyboardShortcutsModal"
                                :allow-keyboard-shortcuts="allowKeyboardShortcuts"
                                :is-dark-mode="isDarkMode"
                                aria-live="polite"
                                @toggleKeyboardShortcuts="toggleKeyboardShortcuts"
                                @close="closeModalFunc"
                            />
                        </Portal>
                    </template>
                </KeyboardShortcutsButton>
            </template>
        </QuizContainer>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { examMetadataModule } from '@/store/examMetadata/module'
import { bundleModule } from '@/store/bundle/module'
import { quizModule } from '@/store/quiz/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import type { Study } from '@pocketprep/types'
import { userModule } from '@/store/user/module'
import { difference, localISODateString, getNumericQuizMode } from '@/utils'
import QuitQuizModal from '@/components/Quiz/QuitQuizModal.vue'
import SubmitQuizModal from '@/components/Quiz/SubmitQuizModal.vue'
import KeywordDefinition from '@/components/KeywordDefinitions/KeywordDefinition.vue'
import KeywordDefinitionBanner from '@/components/KeywordDefinitions/KeywordDefinitionBanner.vue'
import KeywordDefinitionHint from '@/components/KeywordDefinitions/KeywordDefinitionHint.vue'
import LevelUpResult from '@/components/Quiz/LevelUpResult.vue'
import IconBadge from '@/components/IconBadge.vue'
import { utils } from '@pocketprep/ui-kit'
import { screenModule } from '@/store/screen/module'
import SkipToContent from '@/components/SkipToContent.vue'
import { questionModule } from '@/store/question/module'
import { analyticsModule } from '@/store/analytics/module'
import { subscriptionModule } from '@/store/subscription/module'
import { keywordsModule } from '@/store/keywords/module'
import BonusQuestionTextarea from '@/components/BonusQuestionExperiment/BonusQuestionTextarea.vue'
import BonusQuestionExperiment from '@/components/BonusQuestionExperiment/BonusQuestionExperiment.vue'
import QuizError from '@/components/Assignments/QuizError.vue'
import AIFeedback from '@/components/Quiz/AIFeedback.vue'
import AIFeedbackInfoModal from '@/components/Quiz/AIFeedbackInfoModal.vue'
import { toastModule } from '@/store/toast/module'

@Component({
    components: {
        QuitQuizModal,
        SubmitQuizModal,
        FlagToggle: UIKit.FlagToggle,
        KeyboardShortcutsButton: UIKit.KeyboardShortcutsButton,
        Icon: UIKit.Icon,
        ExamMenuCard: UIKit.ExamMenuCard,
        PocketButton: UIKit.Button,
        PocketLink: UIKit.Link,
        QuizContainer: UIKit.QuizContainer,
        Question: UIKit.Question,
        QuizProgress: UIKit.QuizProgress,
        Modal: UIKit.Modal,
        ModalContainer: UIKit.ModalContainer,
        ToggleSwitch: UIKit.ToggleSwitch,
        SkipToContent,
        KeyboardShortcutsModal: UIKit.KeyboardShortcutsModal,
        KeywordDefinition,
        KeywordDefinitionBanner,
        KeywordDefinitionHint,
        LevelUpResult,
        IconBadge,
        BonusQuestionTextarea,
        BonusQuestionExperiment,
        QuizError,
        CheckboxOption: UIKit.CheckboxOption,
        AIFeedback,
        AIFeedbackInfoModal,
    },
    options: {
        beforeRouteEnter (this, to, from, next) {
            next(vm => {
                const fromName = from.name as string | undefined
                (vm as Quiz).fromName = fromName || null
            })
        },
    },
})
export default class Quiz extends Vue {
    isLoading = true
    questionIndex = 0
    timerInterval: ReturnType<typeof setInterval> | null = null
    showQuitQuizModal = false
    showSubmitQuizModal = false
    showLevelUpResult = false
    hideMotivationalMoment = false  // Can force motivational moment to disappear
    currentExam: null | Study.Class.ExamMetadataJSON = null
    currentBundle: null | Study.Class.BundleJSON = null
    fromName: string | null = null
    isFlagged = false
    hasCheckedAnswer = false
    recordedQuizId: string | null = null
    isLevelUp = false
    initialLevelProgress: Study.Cloud.TLevelUpProgress[number] | null = null
    levelScoreIncrease = 0
    subjectsWithLevels: {
        subjectName: string
        levels: number[]
    }[] = []
    showWeekly100ProgressMessage = false
    weekly100ProgressMessage = ''
    earnedWeekly100Badge = false
    keywordDOMElement: HTMLElement | null = null
    keywordClickLocation: { x: number; y: number} | null = null
    showKeywordDefinition = false
    visibleKeywordDefinition: {
        keyword: string
        definition: string
        location: string
        element: HTMLElement
    } | null = null
    hasClickedKeywordDefinitionDuringQuiz = false
    badgeExperimentVariantValue: 'badges-not-visible' | 'badges-visible' | null = null
    errorMessage = ''
    errorDescription = ''

    // Explanation Area experiment
    experimentVariantValue: undefined | string = undefined
    questionExplanation = ''
    localStorageExplanationsKey = 'levelUpQuestionExperiment_explanationAnswer'
    localStorageAIFeedbackKey = 'levelUpQuestionExperiment_aiFeedback'
    explanations: { [serial: string]: string } = {}
    aiFeedback: { [serial: string]: { success: boolean; feedback?: string; vote?: 'Helpful' | 'Unhelpful' } } = {}
    hasSeenHighlightedKeyword = false
    giveAIFeedbackChecked = false
    isAIFeedbackLoading = false
    showAIFeedbackInfoModal = false
    aiFeedbackExperimentVariantValue: 'ai-feedback-not-visible' | 'ai-feedback-visible' | null = null

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get questionContainer () {
        const questionContainerRef = this.$refs[`questionContainer_${
            this.currentQuestion.objectId}`] as typeof UIKit.Question | undefined
        return questionContainerRef && '$el' in questionContainerRef ? questionContainerRef.$el as HTMLElement : null
    }

    get quizContainerEl () {
        const quizContainerRef = this.$refs['quizContainer'] as typeof UIKit.QuizContainer | undefined
        return quizContainerRef && '$el' in quizContainerRef ? quizContainerRef.$el as HTMLElement : null
    }

    get mode () {
        return this.activeQuiz?.mode || null
    }

    get showProgressBar () {
        // Hide progress bar if quiz mode is 'timed'
        return this.mode !== 'timed'
    }

    get showTimer () {
        // Show timer if quiz mode is 'timed'
        return this.mode === 'timed'
    }

    get showExplanationTextArea () {
        return this.experimentVariantValue === '1'
    }

    get showAIFeedbackExperiment () {
        return this.aiFeedbackExperimentVariantValue === 'ai-feedback-visible'
    }

    get activeQuiz () {
        return quizModule.getters.getActiveQuiz()
    }

    get activeQuizQuestions () {
        return this.activeQuiz?.questions || []
    }

    get questionSerialSet () {
        return new Set(this.activeQuizQuestions.map(question => question.serial))
    }

    get answers () {
        return this.activeQuiz?.answers || []
    }

    get answerSerialSet () {
        return new Set(this.answers.map(answer => answer.questionSerial))
    }

    get durationSeconds () {
        return this.activeQuiz?.durationSeconds || 0
    }

    get secondsRemaining () {
        return ((this.activeQuiz?.timeLimit || 0) * 60 - (this.durationSeconds || 0))
    }

    get startedAt () {
        return this.activeQuiz?.startedAt
    }

    get currentQuestion () {
        return this.activeQuizQuestions[this.questionIndex] || null
    }

    get currentQuestionSeed () {
        return this.activeQuiz?.answerSeeds && this.activeQuiz.answerSeeds[this.questionIndex] || null
    }

    get quizLength () {
        return this.activeQuizQuestions.length
    }

    get timeDisplay () {
        if (!this.secondsRemaining) {
            return '0:00'
        }

        const numMinutes = Math.floor(this.secondsRemaining / 60)
        const numSeconds = this.secondsRemaining % 60
        return `${numMinutes}:${String(numSeconds).padStart(2, '0')}`
    }

    get s3Url () {
        return import.meta.env.VUE_APP_S3_URL
    }

    get flaggedQuestions () {
        return userExamMetadataModule.getters.getFlaggedQuestions()
    }

    get answeredIndexList () {
        return this.activeQuizQuestions.reduce<number[]>((acc, question, index) => {
            if (this.answerSerialSet.has(question.serial)) {
                acc.push(index + 1)
            }
            return acc
        }, [])
    }

    get currentAnswer () {
        return this.answers.find(ans => this.currentQuestion?.serial === ans.questionSerial) || null
    }

    get previousChoices () {
        return this.currentAnswer?.selectedChoices || []
    }

    get isPreviousArrowDisabled () {
        return this.questionIndex === 0 || (this.mode === 'timed' && this.secondsRemaining <= 0)
    }

    get isNextArrowDisabled () {
        return (this.questionIndex >= (this.quizLength - 1))
            || (this.mode === 'timed' && this.secondsRemaining <= 0)
    }

    get quizSettings () {
        return userModule.state.user?.quizSettings || null
    }

    get isMobileOrTabletScreen () {
        return this.breakpoint === 'black-bear' || this.breakpoint === 'brown-bear'
    }

    get seenQuizWithKeywordsCount () {
        return userModule.state.user?.webConfig?.seenQuizWithKeywordsCount || 0
    }

    get isFirstQuestionWithKeywordInQuiz () {
        const firstQuizQuestionWithKeywords = this.activeQuizQuestions.find(q =>
            keywordsModule.getters.getKeywordDefinitions().get(q.serial))?.serial

        return firstQuizQuestionWithKeywords
            ? firstQuizQuestionWithKeywords === this.currentQuestion?.serial
            : false
    }

    get hasClickedKeyword () {
        return userModule.state.user?.webConfig?.hasClickedKeyword
    }

    get isSecondQuestionWithKeywordInQuiz () {
        return this.activeQuizQuestions.filter(q =>
            keywordsModule.getters.getKeywordDefinitions().get(q.serial))[1]?.serial === this.currentQuestion?.serial
    }

    get allowKeyboardShortcuts () {
        return !!this.quizSettings?.enableKeyboardShortcuts
    }

    get showAnswersAtEnd () {
        if (this.isLevelUp) {
            return false
        } else if (this.activeQuiz && ('showAnswersAtEnd' in this.activeQuiz)) {
            return this.activeQuiz.showAnswersAtEnd
        } else {
            return !!this.quizSettings?.showAnswersAtEnd
        }
    }

    get showCheckAnswerButton () {
        if (this.activeQuiz && ('showCheckAnswerButton' in this.activeQuiz)) {
            return this.activeQuiz.showCheckAnswerButton
        } else if (this.isLevelUp && this.showExplanationTextArea) {
            // In a level up question with the explanation text area showing
            return true
        } else {
            return !!this.quizSettings?.showCheckAnswerButton
        }
    }

    get currentCorrectCount () {
        return this.answers.filter(answer => answer.isCorrect).length
    }

    get bestAnswers () {
        if (this.mode === 'levelUp') {
            return quizModule.getters.getLatestAnswers({
                questionFilter: 'all',
                sinceDate: this.lastLevelUpResetDate ? this.lastLevelUpResetDate : undefined,
                quizMode: 6,
            })
        } else {
            return quizModule.getters.getLatestAnswers({ questionFilter: 'all' })
        }
    }

    get currentBestAnswer () {
        return this.currentQuestion ? this.bestAnswers[this.currentQuestion.serial] : null
    }

    get correctStreakCount () {
        let streakCount = 0
        // Start with the most recent answer and move back, counting correct answers until there's an incorrect answer
        for (let i = this.answers.length - 1; i >= 0; i--) {
            if (this.answers[i].isCorrect) {
                streakCount++
            } else {
                break
            }
        }
        return streakCount
    }

    get showLearningTag () {
        return this.mode === 'levelUp'
            && this.currentBestAnswer
            && !this.currentBestAnswer.isCorrect
    }

    get showMotivationalMoment () {
        return !this.hideMotivationalMoment
            && this.mode === 'levelUp'
            && this.correctStreakCount === 5
            && this.answers[this.answers.length - 1].questionSerial === this.currentQuestion.serial
    }

    get motivationalMomentText () {
        const fiveInARowMessages = [
            '5 in a row. Nice!',
            'You\'re on the right track',
            '5 for 5!',
        ]
        return fiveInARowMessages[Math.floor(Math.random() * fiveInARowMessages.length)]
    }

    get lastLevelUpResetDate () {
        const sortedResetDates = userExamMetadataModule.getters.getCurrentUserExamMetadata()?.levelUpResetDates
            ?.sort((a, b) => new Date(b.iso).getTime() - new Date(a.iso).getTime())
        const mostRecentResetDate = sortedResetDates?.[0]
        return mostRecentResetDate ? new Date(mostRecentResetDate.iso) : null
    }

    get levelUpProgress () {
        return userExamMetadataModule.getters.getCurrentUserExamMetadata()?.levelUpProgress || null
    }

    get weeklyAnswerHistory () {
        return quizModule.getters.getWeeklyAnswerHistory()
    }

    get weeksWith100Answers () {
        return this.weeklyAnswerHistory.filter(weekHistory => weekHistory.answerCount >= 100)
    }

    get latestWeekWith100Answers () {
        return this.weeksWith100Answers.sort((a, b) => b.startOfWeekMs - a.startOfWeekMs)[0]
    }

    get numWeeksWith100Answers () {
        return this.weeksWith100Answers.length
    }

    get currentWeekAnswerCount () {
        return quizModule.getters.getCurrentWeekAnswerCount()
    }

    get hasActiveSubscription () {
        return !!subscriptionModule.getters.getSubscriptionForExamId()
    }

    get keywordDefinitions () {
        const questionSerial = this.currentQuestion.serial
        const questionKeywords = keywordsModule.getters.getKeywordDefinitions().get(questionSerial)

        if (
            !this.currentAnswer 
            || !questionKeywords
            || !this.currentAnswer.checked
        ) {
            return []
        }

        return Array.from(questionKeywords, ([ keyword, definition ]) => ({
            keyword,
            definition,
        }))
    }

    /********* Start Level Up Question Experiment Methods *********/
    get currentQuestionExplanationAnswer () {
        const currentQuestionExplanation = this.explanations[this.currentQuestion.serial]
        // If explanation exists, then they've already checked the answer :)
        this.hasCheckedAnswer = !!currentQuestionExplanation
        return currentQuestionExplanation || ''
    }

    get questionExplanationAnswerWordCount () {
        return this.questionExplanation.split(' ').length
    }

    get currentQuestionAIFeedback (): Quiz['aiFeedback'][string] | null {
        return this.aiFeedback[this.currentQuestion.serial] || null
    }

    // For amplitude
    get subscriptionSource () {
        const subscription = subscriptionModule.getters.getSubscriptionForExamId()
        return subscription
            && typeof subscription !== 'boolean'
            && subscription.receipt // Subscription.receipt is `.included in fetchUserData cloud function
            && 'source' in subscription.receipt
            && subscription.receipt.source
    }

    // We want to make sure the answers are in the same order that the questions were presented
    get orderedCompleteAnswers () {
        return this.activeQuizQuestions.map(question => {
            if (!this.isQuestionComplete(question.serial)) {
                return undefined
            }
            const answerForQuestion = this.answers.find(answer => answer.questionSerial === question.serial)
            return answerForQuestion || undefined
        }).filter(answer => !!answer) as Study.Cloud.IQuizAnswer[]
    }

    get showSubmitQuizButton () {
        return this.answerSerialSet.size >= this.questionSerialSet.size
            && (this.currentQuestion?.type === 'Multiple Correct Response' && !this.hasCheckedAnswer)
    }

    get prebuiltQuiz () {
        return this.activeQuiz?.prebuiltQuiz
    }

    async setExperimentVariantValue () {
        // Start learning tag experiment
        if (this.showLearningTag && this.mode === 'levelUp') {
            const variant = await analyticsModule.actions.amplitudeFetchVariant('level-up-explanation-experiment-2')
            // Could be '1', '0', or undefined
            this.experimentVariantValue = variant?.value

            if (this.experimentVariantValue === '1') {
                // Fetch explanations on reload
                const localStorageExplanations = localStorage.getItem(this.localStorageExplanationsKey)
                this.explanations = JSON.parse(localStorageExplanations || '{}')

                const aiFeedbackVariant = await analyticsModule.actions.amplitudeFetchVariant('ai-explanation-feedback')
                this.aiFeedbackExperimentVariantValue = aiFeedbackVariant?.value as (
                    'ai-feedback-not-visible' | 'ai-feedback-visible'
                )

                if (this.showAIFeedbackExperiment) {
                    // And any AI feedback for each explanation
                    const localStorageAIFeedback = localStorage.getItem(this.localStorageAIFeedbackKey)
                    this.aiFeedback = JSON.parse(localStorageAIFeedback || '{}')
                }

                this.questionExplanation = this.currentQuestionExplanationAnswer
                this.giveAIFeedbackChecked = !!this.currentQuestionAIFeedback
            }
        } else {
            // Reset experimentVariantValue
            this.experimentVariantValue = undefined
        }
    }
    /********* End Bonus Question Experiment Methods *********/

    async mounted () {
        if (typeof this.$route.query.assignment === 'string') {
            try {
                const completedQuiz = await quizModule.actions.createActiveQuiz({
                    prebuiltQuizId: this.$route.query.assignment,
                    mode: 'assignment',
                })

                if (completedQuiz) {
                    this.$router.push({
                        name: 'quiz-result',
                        params: { quizId: completedQuiz.objectId },
                    })
                    return
                }
            } catch (e: { errorDescription?: string; errorMessage?: string } | unknown) {
                if (e && typeof e === 'object' && 'errorMessage' in e) {
                    this.errorMessage = e.errorMessage as string || ''
                    this.errorDescription = 'errorDescription' in e && e.errorDescription as string || ''
                    await Promise.all([
                        userModule.actions.fetchUserData(),
                        bundleModule.actions.fetchBundles(),
                        examMetadataModule.actions.fetchExamMetadata(),
                    ])

                    this.currentExam = examMetadataModule.getters.getCurrentExamMetadata()
                    this.currentBundle = bundleModule.getters.getCurrentBundle() || null

                    analyticsModule.actions.amplitudeTrack('Assignment Error Triggered', {
                        errorMessage: this.errorMessage,
                        errorDescription: this.errorDescription,
                        assignmentId: this.$route.query.assignment,
                    })

                    return
                }

                throw e
            }


            this.$router.push({ name: 'quiz' })
        }

        if (!this.activeQuiz) {
            this.$router.push({ name: 'study' })
            return
        }
        // Quit quiz if user is loading a quiz with legacy question schema
        if (!this.activeQuiz.questions[0].choices) {
            this.quitQuiz()
            return
        }

        // Restore place in any previously saved quiz
        this.questionIndex = this.activeQuiz.currentQuestionIndex

        await Promise.all([
            userModule.actions.fetchUserData(),
            quizModule.actions.fetchAnsweredQuestions(),
            questionModule.actions.fetchSerialQuestionInfoLib(),
            bundleModule.actions.fetchBundles(),
            examMetadataModule.actions.fetchExamMetadata(),
        ])

        this.currentExam = examMetadataModule.getters.getCurrentExamMetadata()
        this.currentBundle = bundleModule.getters.getCurrentBundle() || null

        // Fetch badge experiment variant if user is premium
        if (this.hasActiveSubscription) {
            const badgeExperimentVariant = await analyticsModule.actions.amplitudeFetchVariant('badges-experiment')
            const badgeExperimentVariantValue = badgeExperimentVariant?.value as 'badges-not-visible' | 'badges-visible'
            this.badgeExperimentVariantValue = badgeExperimentVariantValue || null
        }
        
        // fetch keyword data
        const serials = this.activeQuiz?.questions.map(q => q.serial) || []
        await keywordsModule.actions.fetchKeywordDefinitions(serials)

        if (this.mode === 'levelUp' && this.currentExam) {
            this.subjectsWithLevels = await examMetadataModule.actions.fetchSubjectsWithLevels({
                examGuid: this.currentExam.examGuid,
                majorVersion: this.currentExam.version.split('.')[0],
            })
            this.isLevelUp = true
            const levelUpProgress = userExamMetadataModule.getters.getLevelUpProgress()
            const subjectLevelProgress = levelUpProgress.find(progress => {
                const subject = this.currentQuestion.subject as Study.Class.SubjectJSON
                return progress.subjectName === subject.name
            })
            this.initialLevelProgress = subjectLevelProgress ? { ...subjectLevelProgress } : null
        }

        this.isLoading = false

        const questionSerial = this.currentQuestion?.serial
        this.isFlagged = !!(questionSerial && this.flaggedQuestions.includes(questionSerial))

        this.timerInterval = setInterval(() => {
            const activeQuiz = this.activeQuiz
            if (activeQuiz) {
                const durationSeconds = activeQuiz.durationSeconds || 0
                const timeLimit = activeQuiz.timeLimit && activeQuiz.timeLimit * 60
                if (timeLimit === undefined || durationSeconds < timeLimit) {
                    activeQuiz.durationSeconds = durationSeconds + 1
                    quizModule.actions.updateActiveQuiz(activeQuiz)

                    if (
                        this.timerInterval
                        && typeof timeLimit === 'number'
                        && activeQuiz.durationSeconds >= timeLimit
                    ) {
                        clearInterval(this.timerInterval)
                    }
                }
            }
        }, 1000)

        // Start learning tag experiment
        await this.setExperimentVariantValue()
    }

    beforeUnmount () {
        if (this.timerInterval) {
            clearInterval(this.timerInterval)
        }
    }

    isQuestionComplete (questionSerial: string) {
        const question = this.activeQuizQuestions.find(q => q.serial === questionSerial)
        const answer = this.answers.find(a => a.questionSerial === questionSerial)
        if (!question || !answer) {
            return false
        }

        // Matrix questions are complete if each row has a selected choice
        if ([ 'Matrix Radio Button', 'Matrix Checkbox' ].includes(question.type)) {
            const answeredRowNums = answer.selectedChoices.map(choice =>
                Number(choice.split('_')[0].slice(1))    // a1_2 -> 1
            )
            const matrixRows = question.matrixChoiceLayout || []
            const isEveryRowAnswered = matrixRows.every((row, index) => {
                const rowNum = index + 1
                const hasSelectedChoiceForRow = answeredRowNums.includes(rowNum)
                return hasSelectedChoiceForRow
            })
            return isEveryRowAnswered
        }

        // Other question types are complete if they have 1 or more selected choice
        return answer.selectedChoices.length > 0
    }

    @Watch('currentQuestion')
    currentQuestionChanged () {
        this.isFlagged = !!this.currentQuestion && this.flaggedQuestions.includes(this.currentQuestion.serial)
    }

    keywordClicked (params?: { 
        keyword: string
        location: string
        clickLocation: { x: number; y: number}
        target: HTMLElement
    }) {
        this.showKeywordDefinition = false
        if (!params?.keyword) {
            return
        }

        const questionKeywords = keywordsModule.getters.getKeywordDefinitions().get(this.currentQuestion.serial)
        const definition = questionKeywords?.get(params.keyword.toLowerCase())

        if (!definition) {
            return
        }

        analyticsModule.actions.amplitudeTrack('Keyword Definition Viewed', {
            examGuid: this.currentExam?.examGuid,
            definitionKeyword: params.keyword,
            serial: this.currentQuestion.serial,
            location: params.location,
            quizMode: this.mode ? Number(getNumericQuizMode(this.mode)) : 10000,
            studyMode: 'Active Quiz',
        })

        this.visibleKeywordDefinition = {
            keyword: params.keyword, 
            definition,
            location: params.location,
            element: params.target,
        }
        this.keywordDOMElement = params.target
        this.keywordClickLocation = params.clickLocation
        this.hasClickedKeywordDefinitionDuringQuiz = true

        params.target.setAttribute('aria-expanded', 'true')

        this.$nextTick(() => {
            this.showKeywordDefinition = true
        })
    }

    closeDefinition () {
        this.showKeywordDefinition = false
        this.visibleKeywordDefinition?.element.setAttribute('aria-expanded', 'false')
    }

    openAIFeedbackInfoModal (source: 'Give me feedback on my response' | 'Feedback generated by AI') {
        this.showAIFeedbackInfoModal = true
        
        analyticsModule.actions.amplitudeTrack('AI-Generated Feedback Modal Opened', {
            source,
        })
    }

    submitAIFeedbackHelpfulVote (vote: 'Helpful' | 'Unhelpful') {
        if (
            this.aiFeedback[this.currentQuestion.serial]
            && this.aiFeedback[this.currentQuestion.serial].vote !== vote   // Only record changed votes
        ) {
            this.aiFeedback[this.currentQuestion.serial].vote = vote
            localStorage.setItem(this.localStorageAIFeedbackKey, JSON.stringify(this.aiFeedback))

            toastModule.actions.displayToast({
                title: 'Thank you for your feedback!',
            })
            analyticsModule.actions.amplitudeTrack('AI Content Feedback Provided', {
                aiContentType: 'explainYourAnswerFeedback',
                feedback: vote,
                aiContent: this.currentQuestionAIFeedback?.feedback,
            })
        } else if (
            this.aiFeedback[this.currentQuestion.serial]
            && this.aiFeedback[this.currentQuestion.serial].vote === vote   // Allow unsetting votes
        ) {
            this.aiFeedback[this.currentQuestion.serial].vote = undefined
            localStorage.setItem(this.localStorageAIFeedbackKey, JSON.stringify(this.aiFeedback))
        }
    }

    quitQuizClicked () {
        if (this.orderedCompleteAnswers.length) {
            this.showQuitQuizModal = true
        } else {
            this.quitQuiz()
        }
    }

    quitQuiz () {
        // Log in amplitude that the user quit the quiz
        if (this.mode) {
            // calculate how many answers in quiz were on "learning" questions
            const isLearningQuestionCount = this.mode === 'levelUp'
                ? this.answers.filter(a => 
                    this.bestAnswers[a.questionSerial] 
                    && !this.bestAnswers[a.questionSerial].isCorrect
                ).length
                : 0

            const quizQuitPayload: { [key: string]: string | number | boolean } = {
                explanationExperimentVisible: !!this.showExplanationTextArea,
                isLearningQuestion: !!this.showLearningTag,
                quizPlatform: 'Web',
                quizAnswerCount: this.orderedCompleteAnswers.length,
                isLearningQuestionCount,
            }

            if (this.subscriptionSource) {
                quizQuitPayload.subscriptionSource = this.subscriptionSource
            }

            if (getNumericQuizMode(this.mode) !== 'unknown') {
                quizQuitPayload.quizMode = Number(getNumericQuizMode(this.mode))
            }

            analyticsModule.actions.amplitudeTrack('Quiz Quit', quizQuitPayload)
        }

        // Clear out any entries for explanations
        localStorage.removeItem(this.localStorageExplanationsKey)
        localStorage.removeItem(this.localStorageAIFeedbackKey)

        quizModule.actions.updateActiveQuiz(null)
        if (!this.fromName || this.fromName === 'byoq' || this.fromName === 'quiz') {
            this.$router.push({
                name: 'study',
            })
        } else {
            this.$router.back()
        }
    }

    clickPrevious () {
        if (this.mode === 'timed' && this.secondsRemaining <= 0) {
            return
        }

        if (this.questionIndex > 0) {
            this.questionIndex--
        }

        this.$nextTick(() => {
            if (this.showExplanationTextArea) {
                // Check local storage and fill question explanation if needed
                this.questionExplanation = this.currentQuestionExplanationAnswer
                this.giveAIFeedbackChecked = !!this.currentQuestionAIFeedback
            }
        })
    }

    clickNext () {
        if (this.mode === 'timed' && this.secondsRemaining <= 0) {
            return
        }

        if (this.questionIndex < (this.quizLength - 1)) {
            this.questionIndex++

            // move focus back to top
            this.$nextTick(() => {
                const headEl = document.querySelector('.uikit-quiz-container__header') as HTMLElement
                const focusableEls = Array.from<HTMLElement>(headEl.querySelectorAll(
                    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
                ))
                focusableEls[focusableEls.length - 1]?.focus()
                focusableEls[focusableEls.length - 1]?.blur()
            })
        }

        this.$nextTick(() => {
            if (this.showExplanationTextArea) {
                // Check local storage and fill question explanation if needed
                this.questionExplanation = this.currentQuestionExplanationAnswer
                this.giveAIFeedbackChecked = !!this.currentQuestionAIFeedback
            }
        })
    }

    progressBarClicked (questionNumber: number) {
        if (this.mode === 'timed' && this.secondsRemaining <= 0) {
            return
        }

        this.questionIndex = questionNumber - 1
    }

    giveFeedbackClicked () {
        this.giveAIFeedbackChecked = true
        this.generateAIFeedback('explanation-button')
    }

    async checkAnswer (answer: Study.Cloud.IQuizAnswer) {
        this.questionAnswered(answer, true)
        this.hasCheckedAnswer = true

        if (this.showExplanationTextArea) {
            // Store the entry in local storage
            this.explanations[this.currentQuestion.serial] = this.questionExplanation
            localStorage.setItem(this.localStorageExplanationsKey, JSON.stringify(this.explanations))

            this.$nextTick(async () => {
                let aiFeedback: {
                    success: boolean
                    feedback: string
                } | null = null

                if (this.currentExam && this.giveAIFeedbackChecked) {
                    const showExplanationBtnEl = [
                        ...document.querySelectorAll('.uikit-btn'),
                    ].find(btn => btn.textContent?.includes('Show Explanation')) as HTMLElement | undefined
                    showExplanationBtnEl?.click()

                    aiFeedback = await this.generateAIFeedback('checkbox')
                }

                // Send event to amplitude
                analyticsModule.actions.amplitudeTrack('Answer Explanation Offered', {
                    isCorrect: this.answers[this.answers.length - 1].isCorrect,
                    explanationText: this.questionExplanation,
                    isExplained: !!this.questionExplanation,
                    serial: this.currentQuestion.serial,
                    examGuid: this.currentExam?.examGuid,
                    ...(aiFeedback?.feedback && { aiFeedback: aiFeedback.feedback }),
                })
            })
        }
    }

    async generateAIFeedback (source: 'checkbox' | 'explanation-button') {
        let aiFeedback: {
            success: boolean
            feedback: string
        } | null = null
        if (this.currentExam && this.giveAIFeedbackChecked) {
            const studentAnswer = this.currentQuestion.choices.filter(choice =>
                this.currentAnswer?.selectedChoices.includes(choice.id)
            ).map(choice => choice.text).join(', ')

            analyticsModule.actions.amplitudeTrack('AI Explanation Feedback Requested', {
                source,
            })

            if (this.questionExplanationAnswerWordCount > 3) {
                this.isAIFeedbackLoading = true
                aiFeedback = await quizModule.actions.generateExplainYourAnswerFeedback({
                    examMetadataId: this.currentExam.objectId,
                    serial: this.currentQuestion.serial,
                    userExplanation: this.questionExplanation,
                    userSelectedAnswer: studentAnswer,
                })
                if (aiFeedback) {
                    this.aiFeedback[this.currentQuestion.serial] = aiFeedback
                } else {
                    this.aiFeedback[this.currentQuestion.serial] = {
                        success: false,
                    }
                }
                this.isAIFeedbackLoading = false
            } else {
                this.aiFeedback[this.currentQuestion.serial] = {
                    success: false,
                }
            }
            localStorage.setItem(this.localStorageAIFeedbackKey, JSON.stringify(this.aiFeedback))
        }
        return aiFeedback
    }

    async questionAnswered (answer: Study.Cloud.IQuizAnswer, checked?: boolean) {
        if (!this.activeQuiz) {
            throw new Error('A quiz is active, but no activeQuiz was found')
        }

        const answers = this.answers
        const existingAnswer = answers.find(ans => ans.questionSerial === answer.questionSerial)

        // If question component emits selected choices that we already have or are empty, don't make any updates
        if ((
            existingAnswer
            && !checked
            && !difference(existingAnswer.selectedChoices, answer.selectedChoices).length
            && !difference(answer.selectedChoices, existingAnswer.selectedChoices).length
        ) || (
            !existingAnswer
            && !answer.selectedChoices.length
        )) {
            return
        }
        
        if (existingAnswer && !answer.selectedChoices.length) {
            // If the user has removed their selections, remove the answer from the list
            const answerIndex = answers.indexOf(existingAnswer)
            answers.splice(answerIndex, 1)
        } else if (existingAnswer) {
            // If we have new selected choices for an existing answer, update them on the answer object
            existingAnswer.selectedChoices = answer.selectedChoices
            existingAnswer.isCorrect = answer.isCorrect
            if (checked) {
                existingAnswer.checked = !!checked
            }
        } else {
            // If we have selected choices for a new answer, update the answers list
            answers.push({
                ...answer,
                checked: !!checked,
            })
        }

        quizModule.actions.updateActiveQuiz({
            ...this.activeQuiz,
            answers,
        })
        
        if (this.keywordDefinitions.length) {
            this.hasSeenHighlightedKeyword = true
        }
    }

    async toggleFlag () {
        this.isFlagged = !this.isFlagged
        const serial = this.currentQuestion?.serial

        try {
            await userExamMetadataModule.actions.toggleQuestionFlag(serial)
        } catch (e) {
            // noop
        } finally {
            this.isFlagged = !!(serial && this.flaggedQuestions.includes(serial))
        }
    }

    toggleKeyboardShortcuts (newVal: boolean) {
        userModule.actions.updateQuizSettings({
            enableKeyboardShortcuts: newVal,
        })
    }

    checkWeekly100Progress (submittedAnswerCount: number) {
        const weeklyAnswerCountAfterQuiz = this.currentWeekAnswerCount
        const weeklyAnswerCountBeforeQuiz = weeklyAnswerCountAfterQuiz - submittedAnswerCount
        const earnedWeekly100Badge = weeklyAnswerCountAfterQuiz >= 100 && weeklyAnswerCountBeforeQuiz < 100
        if (
            (weeklyAnswerCountAfterQuiz >= 20 && weeklyAnswerCountBeforeQuiz < 20)    // Passed 20 weekly answers
            || (weeklyAnswerCountAfterQuiz >= 60 && weeklyAnswerCountBeforeQuiz < 60)    // Passed 60 weekly answers
            || (weeklyAnswerCountAfterQuiz >= 90 && weeklyAnswerCountBeforeQuiz < 90)    // Passed 90 weekly answers
            || earnedWeekly100Badge    // Passed 100 weekly answers
        ) {
            // We still check if they earned the badge even if badges are not visible for user during experiment
            this.earnedWeekly100Badge = earnedWeekly100Badge
            
            if (this.badgeExperimentVariantValue !== 'badges-not-visible') {
                const remainingQuestions = 100 - weeklyAnswerCountAfterQuiz
                this.weekly100ProgressMessage = `Answer ${
                    remainingQuestions
                } more question${ remainingQuestions === 1 ? '' : 's'} to earn your Weekly 100 badge.`
                this.showWeekly100ProgressMessage = true
            }
        }

        if (earnedWeekly100Badge) {
            const badgeEarnedDate = this.latestWeekWith100Answers
                && new Date(this.latestWeekWith100Answers.startOfWeekMs)
            analyticsModule.actions.amplitudeTrack('Badge Earned', {
                badgeName: 'weekly100',
                badgeEarnedCount: this.numWeeksWith100Answers,
                ...(badgeEarnedDate && { badgeEarnedDate: badgeEarnedDate.toISOString() }),
                ...(this.currentExam?.examGuid && { examGuid: this.currentExam.examGuid }),
            })
        }
    }

    async submitQuiz (skipConfirm?: true | false) {
        this.showQuitQuizModal = false
        this.showSubmitQuizModal = false

        if (!this.currentExam) {
            throw new Error('Quiz.submitQuiz: No current exam found')
        }

        // If not all questions have been answered, show confirmation modal
        if (
            !skipConfirm
            && this.mode !== 'timed'
            && this.answers.length < this.quizLength
        ) {
            // Clear the entries upon exit
            this.showSubmitQuizModal = true
            return
        }

        // if user was exposed to a highlighted keyword, update webConfig item
        if (this.hasSeenHighlightedKeyword) {
            userModule.actions.updateWebConfig({
                seenQuizWithKeywordsCount: this.seenQuizWithKeywordsCount + 1,
            })
        }

        const studyModes = utils.studyModes
        const modeEntry = Object.entries(studyModes).find(entry => entry[1].shortName === this.mode)
        const modeCode = modeEntry ? Number(modeEntry[0]) : null
        if (modeCode === null) {
            throw new Error('Quiz.submitQuiz: Cannot submit a quiz without a mode')
        }

        if (this.isLevelUp) {
            this.isLoading = true
            await new Promise(res => setTimeout(res, 1500))
            this.levelScoreIncrease = this.currentCorrectCount
            this.showLevelUpResult = true
        }

        this.isLoading = true
        const subject = this.currentQuestion.subject as Study.Class.SubjectJSON
        // Capture the count here since orderedCompleteAnswers will be cleared when recordQuiz completes
        const submittedAnswerCount = this.orderedCompleteAnswers.length
        const recordQuizResponse = await quizModule.actions.recordQuiz({
            answers: this.orderedCompleteAnswers,
            durationSeconds: this.durationSeconds,
            examGuid: this.currentExam.examGuid,
            mode: modeCode,
            platform: 'Web',
            appBundleId: 'study.pocketprep.com',
            startedAt: this.startedAt || localISODateString(),
            examVersion: this.currentExam.version,
            prebuiltQuizId: this.prebuiltQuiz?.objectId,
            ...(this.isLevelUp && { levelSubjectName: subject.name }),
        })
        this.recordedQuizId = recordQuizResponse.quiz.objectId

        // Only show weekly 100 progress for premium users
        if (this.hasActiveSubscription) {
            this.checkWeekly100Progress(submittedAnswerCount)
        }
        if (!this.isLevelUp && !this.showWeekly100ProgressMessage) {
            this.goToQuizResults()
        }

        // Clear out any entries for explanations
        localStorage.removeItem(this.localStorageExplanationsKey)
        localStorage.removeItem(this.localStorageAIFeedbackKey)
        this.isLoading = false
    }

    async goToQuizResults () {
        // If Level Up, check whether the current subject is now complete
        if (this.isLevelUp) {
            const currentSubjectName = this.initialLevelProgress?.subjectName
            if (currentSubjectName) {
                const levels = this.subjectsWithLevels
                    .find(subjectWithLevels => subjectWithLevels.subjectName === currentSubjectName)?.levels
                const numLevels = levels?.length
                const subjectProgress = this.levelUpProgress
                    ?.find(progress => progress.subjectName === currentSubjectName)
                const progressLevel = subjectProgress?.level || 0
                const score = subjectProgress?.score || 0
                const goal = subjectProgress?.goal || Infinity
                const isComplete = numLevels && (
                    progressLevel > numLevels || (progressLevel === numLevels && score >= goal)
                )
                if (isComplete) {
                    const examName = this.currentExam?.nativeAppName
                    if (examName) {
                        analyticsModule.actions.updateSprigUser({
                            examName: examName,
                        })
                    }
                    // Triggers Sprig survey when subject is complete
                    await analyticsModule.actions.sprigTrack({
                        eventName: 'Level_Up_Subject_Complete',
                    })
                }
            }
        }
        this.$router.push({
            name: 'quiz-result',
            params: { quizId: this.recordedQuizId },
            query: {
                from: this.fromName || null,
            },
        })
    }

    @Watch('questionIndex')
    async questionIndexChanged (questionIndex: Quiz['questionIndex']) {
        if (this.activeQuiz) {
            // Hide motivational moment when question changes
            if (this.showMotivationalMoment) {
                this.hideMotivationalMoment = true
            }

            this.showKeywordDefinition = false

            // Start learning tag experiment
            await this.setExperimentVariantValue()

            quizModule.actions.updateActiveQuiz({
                ...this.activeQuiz,
                currentQuestionIndex: questionIndex,
            })
        }

        // stop showing keyword messaging for the user once they click their first keyword
        if (this.hasClickedKeywordDefinitionDuringQuiz) {
            userModule.actions.updateWebConfig({ hasClickedKeyword: true })
        }
    }

    async explanationViewToggled (showExplanation: boolean) {
        // stop showing keyword messaging for the user once they click their first keyword
        if (this.hasClickedKeywordDefinitionDuringQuiz) {
            userModule.actions.updateWebConfig({ hasClickedKeyword: true })
        }
        if (showExplanation) {
            this.$nextTick(() => {
                if (this.giveAIFeedbackChecked && !this.currentQuestionAIFeedback) {
                    const selector = [ 'black-bear', 'brown-bear' ].includes(this.breakpoint)
                        ? '.uikit-question-dropdown-explanation__dropdown-explanation .ai-feedback'
                        : '.uikit-question-explanation .ai-feedback'
                    const aiFeedbackEl = document.querySelector(selector)
                    this.$nextTick(() => {
                        if (this.breakpoint === 'black-bear') {
                            setTimeout(() => {
                                aiFeedbackEl?.scrollIntoView()
                            }, 500)
                        } else {
                            aiFeedbackEl?.scrollIntoView({
                                behavior: 'smooth',
                            })
                        }
                    })
                }
            })
            analyticsModule.actions.amplitudeTrack(
                'Explanation Opened',
                {
                    examGuid: this.currentExam?.examGuid,
                    serial: this.currentQuestion.serial,
                    quizMode: this.mode || undefined,
                    platform: 'Web',
                }
            )
            if (this.keywordDefinitions) {
                this.hasSeenHighlightedKeyword = true
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.keywords-message {
    position: absolute;
    top: 20%;
    left: 10%;
    z-index: 10000;
}
.quiz {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &__exam-info {
        position: absolute;
        left: -10px;
        width: 320px;

        @include breakpoint(brown-bear) {
            width: 285px;
        }

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__exam-info-card {
        max-width: 100%;
    }

    &__progress {
        position: absolute;
        left: 50%;
        max-width: 768px;
        width: calc(40% + 130px);
        transform: translateX(-50%);

        @include breakpoint(polar-bear) {
            width: calc(40% + 45px);
        }

        @include breakpoint(brown-bear) {
            max-width: 280px;
            width: calc(36% + 38px);
        }

        @include breakpoint(black-bear) {
            max-width: none;
            width: 90%;
        }
    }

    &__timer {
        display: flex;
        align-items: center;
        position: absolute;
        right: 121px;

        @include breakpoint(black-bear) {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__timer-icon {
        margin-right: 12px;
    }

    &__quit-btn {
        position: absolute !important;
        right: 0;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__weekly-100-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 96px;
    }

    &__weekly-100-hiking-image {
        width: 343px;
        height: 120px;
        margin-bottom: 40px;
    }

    &__weekly-100-badge-image {
        width: 100px;
        height: 100px;
        margin-bottom: 40px;
        color: $cheddar;

        &--dark {
            color: $banana-bread;
        }
    }

    &__weekly-100-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 8px;
    }

    &__weekly-100-message-text {
        width: 280px;
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 24px;
    }

    &__loading {
        position: absolute;
        left: 50%;
        top: 25%;
        transform: translateX(-50%) scale(1.5);
    }

    &__question {
        position: relative;
        height: 100%;
    }

    &__learning-tag {
        height: 17px;
        padding: 0px 3px;
        border-radius: 3px;
        border: 1px solid $orchid;
        background-color: rgba($orchid, 0.4);
        font-size: 12px;
        line-height: 16px;
        box-sizing: border-box;

        &--dark {
            color: $brand-black;
            background-color: $orchid;
        }
    }

    &__motivational-moment {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 114px;
        height: 143px;
        margin-bottom: -8px;

        &-text {
            height: 18px;
            color: $slate;
            font-size: 15px;
            font-weight: 600;
            line-height: 20px;
            width: 200px;
            z-index: 2;
            text-align: center;

            &--dark {
                color: $fog;
            }
        }

        &-image {
            width: 100px;
            height: 121px;
        }
    }

    &__desktop-bonus-question-explanation-textarea {
        width: 100%;
        margin-top: 20px;

        @include breakpoint(brown-bear) {
            margin-top: 0;
        }
    }

    &__ai-feedback-checkbox {
        box-sizing: border-box;
        width: 100%;
        max-width: calc(min(492px, 100%) - 28px);
        padding-left: 15px;
    }

    :deep(.uikit-checkbox-option__label) {
        font-size: 14px;
    }

    &__ai-feedback-link {
        font-size: 14px;
    }

    &__ai-feedback-error {
        position: relative;
        margin-top: 24px;
        margin-bottom: 253px;
        padding-top: 26px;
        padding-left: 67px;
        border-top: 1px solid $fog;

        @include breakpoint(black-bear) {
            padding-left: 0;
        }
    }

    &__brain-error {
        position: absolute;
        top: 16px;
        left: 0;
        width: 47px;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__ai-feedback-error-message {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $ash;
        margin-bottom: 32px;

        &--dark {
            color: $fog;
        }
    }

    &__controls {
        display: flex;
        align-items: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @include breakpoint(black-bear) {
            width: 304px;
        }
    }

    &__previous-arrow,
    &__next-arrow {
        &--disabled {
            opacity: 0.35;
        }

        &:not(&--disabled) {
            cursor: pointer;

            @media (hover: hover) {
                &:hover {
                    color: $banana-bread;
                }
            }
        }

        &:focus {
            outline: none;
            color: $banana-bread;
        }
    }

    &__previous-arrow {
        transform: rotate(180deg);
    }

    &__flag-toggle {
        margin: 0 80px;
    }

    &__keyboard-shortcuts-btn {
        position: absolute;
        right: 0;

        @include breakpoint(black-bear) {
            display: none;
        }
    }
}
</style>
