import { referralModule } from '@/store/referral/module'
import { isPromise } from '@/store/utils'

const getValidReferral = () => {
    const validReferral = referralModule.state.validReferral.value

    if (isPromise(validReferral)) {
        return null
    } else {
        return validReferral
    }
}

const getReferralError = () => {
    return referralModule.state.referralError
}

const getDiscountAmount = () => {
    const referral = referralModule.state.validReferral.value

    if (!referral) {
        return null
    } else if ('name' in referral) {
        return referral.percent_off
            ? `${referral.percent_off}%`
            : `$${((referral.amount_off || 0) / 100).toFixed(2)}`
    } else {
        return '20%'
    }
}

const calculateDiscountedPrice = (baseCost: number) => {
    const referral = referralModule.state.validReferral.value

    if (!referral) {
        return baseCost
    } else if ('name' in referral) {
        return baseCost - (referral.percent_off
            ? ((referral.percent_off / 100) * baseCost)
            : referral.amount_off || 0)
    } else {
        return 0.8 * baseCost
    }
}

export default {
    getValidReferral,
    getReferralError,
    calculateDiscountedPrice,
    getDiscountAmount,
}
