<template>
    <Portal to="modal">
        <ModalContainer key="ssoTransitionModal"
                        :is-dark-mode="isDarkMode"
                        @close="closeModal">
            <template #modal>
                <Modal
                    v-dark
                    class="sso-transition"
                    :is-dark-mode="isDarkMode"
                    :show-close-button="true"
                    @close="closeModal"
                >
                    <img
                        v-if="isDarkMode"
                        class="sso-transition__gif"
                        src="@/assets/loading/High-Five-animation-2DM.gif"
                    >
                    <img
                        v-else
                        class="sso-transition__gif"
                        src="@/assets/loading/High-Five-animation-2.gif"
                    >
                    <div class="sso-transition__title">
                        Let's do this!
                    </div>
                    <div class="sso-transition__subtitle">
                        <template v-if="hasPremiumAccess">
                            You have free Premium Pocket Prep from {{  orgName  }}.
                        </template>
                        <template v-else>
                            Prep for your certification exam with Pocket Prep.
                        </template>
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { subscriptionModule } from '@/store/subscription/module'
import { examMetadataModule } from '@/store/examMetadata/module'

@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
    },
})
export default class SSOTransitionModal extends Vue {
    @Prop() orgName!: string

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get currentExam () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get hasPremiumAccess () {
        return subscriptionModule.getters.getSubscriptionForExamId(this.currentExam?.objectId)
    }

    @Emit('close')
    closeModal () {
        return true
    }
}
</script>

<style lang="scss">
.sso-transition {
    max-width: 374px;
    padding: 0 21px 40px 21px;
    text-align: center;
    box-sizing: border-box;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    top: 120px;

    &__gif {
        width: 302px;
        margin: 0 0 36px 28px;
    }

    &__title {
        color: $brand-black;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.3px;
        font-weight: 600;
        margin-bottom: 8px;
    }

    &__subtitle {
        max-width: 278px;
        margin: 0 auto;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
    }
}
</style>