import { readinessModule } from '@/store/readiness/module'
import { isPromise } from '@/store/utils'

const getReadinessScores = () => {
    const readinessScores = readinessModule.state.readinessScores.value
    if (isPromise(readinessScores)) {
        return null
    } else {
        return readinessScores
    }
}

export default {
    getReadinessScores,
}
