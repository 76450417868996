import { reactive } from 'vue'
import actions from '@/store/globalQuestionMetric/actions'
import state from '@/store/globalQuestionMetric/state'
import getters from '@/store/globalQuestionMetric/getters'

export const globalQuestionMetricModule = {
    state: reactive(state),
    actions,
    getters,
}
