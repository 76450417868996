<!-- eslint-disable -->
<template>
    <svg width="139.063334px" height="141.012546px" viewBox="0 0 139.063334 141.012546" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title></title>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M139.063334,0.00101109597 L139.063334,141.001011 L136.4884,141.012546 C98.6843302,117.472196 71.1496899,58.1521387 57.5261768,0.000714655625 L139.063334,0.00101109597 Z" fill="#FFF2D9" opacity="0.152832031"></path>
            <path d="M139.005762,0.428298372 L139.005,79.3112984 L137.36584,79.0153696 C35.7932603,59.1107741 9.93097974,63.1790108 8.11906098e-13,0.42834319 L139.005762,0.428298372 Z" fill="url(#halftone-yellow)"></path>
        </g>
    </svg>
</template>
<!-- eslint-enable -->

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class RightBlob extends Vue {

}
</script>