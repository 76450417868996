<template>
    <div id="mock-exam-info" class="mock-exam-info">
        <div v-dark class="mock-exam-info__num-questions">
            {{ mockExam.questionSerials.length }} Questions
        </div>
        <div v-dark class="mock-exam-info__time-info">
            <div v-dark class="mock-exam-info__default-time">
                {{ allowedTime }}
            </div>
            <div v-dark class="mock-exam-info__ten-minute-break">
                This exam has a 10 minute break halfway through the questions. 
                You may resume your exam anytime during this period.
            </div>
            <slot name="extraTime" />
        </div>
        <div class="mock-exam-info__imitation-text">
            Our mock exam imitates both the time limit and question count of the {{ currentExamName }} exam.
        </div>
        <div
            v-dark
            class="mock-exam-info__description"
            v-html="mockExam.description"
        />
        <div v-dark class="mock-exam-info__warning">
            Warning: You will NOT see these exam questions on exam day.
        </div>
    </div>
</template>

<script lang="ts">
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import type { TMappedMockExam } from '@/components/MockExamIntro.vue'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { analyticsModule } from '@/store/analytics/module'

@Component({
    components: {
        Icon: UIKit.Icon,
    },
})
export default class MockExamInfo extends Vue {
    @Prop() mockExam!: TMappedMockExam
    @Prop() currentExamName!: string

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get allowedTime () {
        const hourSeconds = this.mockExam.durationSeconds || 0
        const hours = Math.floor(hourSeconds / 3600)
        const minuteSeconds = hourSeconds % 3600
        const minutes = Math.floor(minuteSeconds / 60)
        const paddedMinutes = String(minutes).padStart(2, '0')
        return `${hours}h ${paddedMinutes}m`
    }

    async mounted () {
        await analyticsModule.actions.trackEvent('Mock_Exam_Description_Viewed')

        const mockExamIntroEl = document.getElementById('mock-exam-intro')
        // Scroll into view the top of the mock exam page 
        if (mockExamIntroEl) {
            mockExamIntroEl.scrollIntoView()
        }
    }
}
</script>

<style lang="scss" scoped>
.mock-exam-info {
    width: 460px;

    @include breakpoint(black-bear) {
        width: 308px;
    }

    &__num-questions {
        position: relative;
        left: -20px;
        display: flex;
        align-items: center;
        background-color: $white;
        box-sizing: border-box;
        border: 1px solid $fog;
        border-radius: 5px;
        width: 500px;
        height: 50px;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        padding-left: 20px;
        margin-bottom: 14px;

        &::after {
            content: '';
            border-radius: 5px;
            background: url('@/assets/mock-exams/halftone-black.png') repeat;
            background-size: 85px;
            position: absolute;
            width: 498px;
            height: 47px;
            top: 6px;
            left: 6px;
            z-index: -1;
        }

        &--dark {
            background-color: $brand-black;
            color: $white;
            border-color: $ash;
        }

        @include breakpoint(black-bear) {
            width: 338px;
            left: -15px;
            padding-left: 15px;

            &::after {
                display: none;
            }
        }
    }

    &__time-info {
        position: relative;
        left: -20px;
        background-color: $white;
        box-sizing: border-box;
        border: 1px solid $fog;
        border-radius: 5px;
        width: 500px;
        height: 155px;
        margin-bottom: 31px;

        &::after {
            content: '';
            border-radius: 5px;
            background: url('@/assets/mock-exams/halftone-black.png') repeat;
            background-size: 85px;
            position: absolute;
            width: 498px;
            height: 153px;
            top: 7px;
            left: 6px;
            z-index: -1;
        }

        &--dark {
            background-color: $brand-black;
            color: $white;
            border-color: $ash;
        }

        @include breakpoint(black-bear) {
            width: 338px;
            height: 195px;
            left: -15px;

            &::after {
                display: none;
            }
        }
    }

    &__default-time {
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        height: 50px;
        box-sizing: border-box;
        border-bottom: 1px solid $fog;

        &--dark {
            border-color: $ash;
        }

        @include breakpoint(black-bear) {
            padding-left: 15px;
        }
    }

    &__ten-minute-break {
        display: flex;
        align-items: center;
        padding-top: 12px;
        padding-bottom: 14px;
        padding-left: 20px;
        padding-right: 17px;
        font-size: 15px;
        line-height: 20px;
        height: 58px;
        box-sizing: border-box;
        border-bottom: 1px solid $fog;

        &--dark {
            font-weight: 400;
            color: $white;
            border-color: $ash;
        }

        @include breakpoint(black-bear) {
            height: 85px;
            padding-left: 15px;
            padding-top: 11px;
            padding-bottom: 11px;
        }
    }

    &__imitation-text {
        font-weight: 600;
        font-size: 18px;
        letter-spacing: -0.11px;
        line-height: 28px;
        margin-bottom: 14px;
    }

    &__description {
        font-size: 18px;
        letter-spacing: -0.11px;
        line-height: 28px;
        margin-bottom: 8px;

        :deep(p) {
            margin: 0;
        }

        &--dark {
            color: $fog;
        }
    }

    &__warning {
        color: $slate;
        font-size: 18px;
        letter-spacing: -0.11px;
        line-height: 28px;
        margin-bottom: 64px;

        &--dark {
            color: $pewter;
        }
    }
}
</style>
