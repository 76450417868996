import { type Stripe as StripeNode } from 'stripe'
import { initLoadable } from '@/store/utils'
import type { Loadable } from '@/store/utils'
import { type Stripe, type StripeElements } from '@stripe/stripe-js'

export type TStripeState = {
    subscriptions: Loadable<StripeNode.Subscription[]>
    plans: Loadable<{ [bundleId: string]: StripeNode.Plan[] }>
    payment: Loadable<{
        paymentMethods: StripeNode.PaymentMethod[]
        defaultPaymentMethod: StripeNode.PaymentMethod | null
    }>
    stripe: Stripe | null
    elements: StripeElements | null
}

export const getInitialStripeState = (): TStripeState => {
    return {
        subscriptions: initLoadable([]),
        plans: initLoadable({}),
        payment: initLoadable({
            paymentMethods: [],
            defaultPaymentMethod: null,
        }),
        stripe: null,
        elements: null,
    }
}

const moduleState = getInitialStripeState()

export default moduleState
