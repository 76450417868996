import { initLoadable } from '@/store/utils'
import type { Loadable } from '@/store/utils'
import type { Teach } from '@pocketprep/types'

export interface IOrgInstructorModuleState {
    currentUserOrgInstructors: Loadable<Teach.Class.OrgInstructorJSON[]>
}

// Keeping the return value as obj in case we want to add more in the future
export const getInitialOrgInstructorState = (): IOrgInstructorModuleState => {
    return {
        currentUserOrgInstructors: initLoadable([]),
    }
}

const moduleState = getInitialOrgInstructorState()

export default moduleState
