<template>
    <div
        v-dark
        tabindex="-1"
        class="scoring-loading"
    >
        <img
            v-if="!isDarkMode"
            src="@/assets/mock-exams/scoring-loading.png"
            alt=""
            class="scoring-loading__image"
        >
        <img
            v-else-if="isDarkMode"
            src="@/assets/mock-exams/scoring-loading-dm.png"
            alt=""
            class="scoring-loading__image"
        >
        <div class="scoring-loading__title">
            You did it!
        </div>
        <div v-dark class="scoring-loading__text">
            Scoring your exam...
        </div>
        <Icon
            class="scoring-loading__loading"
            type="loading2"
            :is-dark-mode="isDarkMode"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'

@Component({
    components: {
        Icon: UIKit.Icon,
    },
})
export default class ScoringLoading extends Vue {
    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    mounted () {
        setTimeout(() => {
            const titleEl = document.querySelector('.scoring-loading') as HTMLElement | null
            titleEl?.focus()
        }, 0)
    }
}
</script>

<style lang="scss" scoped>
.scoring-loading {
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 53px;

    &--dark {
        color: $white;
    }

    &__image {
        height: 224px;
        margin-bottom: 21px;
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
    }

    &__text {
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 21px;
        color: $ash;

        &--dark {
            color: $fog;
        }
    }
}
</style>
