import { examMetadataModule } from '@/store/examMetadata/module'
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { isPromise } from '@/store/utils'
import { uniqBy } from '@/utils'
import type { Study } from '@pocketprep/types'

const getExamMetadataById = () => {
    const examMetadata = examMetadataModule.state.examMetadata.value
    if (isPromise(examMetadata)) {
        return {}
    } else {
        return examMetadata.reduce<{ [examId: string]: Study.Class.ExamMetadataJSON }>((acc, e) => {
            acc[e.objectId] = e
            return acc
        }, {})
    }
}

const getExamMetadata = () => {
    const examMetadata = examMetadataModule.state.examMetadata.value
    if (isPromise(examMetadata)) {
        return []
    } else {
        return examMetadata
    }
}

const getMostRecentExamMetadata = () => {
    const examMetadata = examMetadataModule.state.examMetadata.value
    if (isPromise(examMetadata)) {
        return []
    } else {
        return uniqBy(
            e => e.examGuid, 
            [ ...examMetadata ].sort((a, b) => -a.version.localeCompare(b.version, undefined, { numeric: true }))
        )
    }
}

const getMostRecentExamMetadataById = () => {
    return getMostRecentExamMetadata().reduce<{ [examId: string]: Study.Class.ExamMetadataJSON }>((acc, e) => {
        if (e) {
            acc[e.objectId] = e
        }
        return acc
    }, {})
}

const getMostRecentExamForExamGuid = (examGuid: string) => {
    const exams = Object.values(getExamMetadataById())
    const mostRecentExam = [ ...exams ]
        .filter(exam => exam.examGuid === examGuid)
        .sort((a, b) => -a.version.localeCompare(b.version, undefined, { numeric: true }))[0]
    return mostRecentExam
}

const getCurrentExamMetadata = () => {
    const user = userModule.state.user
    const examGuid = user?.currentExamGuid
    const userExamMetadata = examGuid && userExamMetadataModule.getters.getUserExamMetadataByGuid()[examGuid]
    const examMetadata = user 
        && userExamMetadata 
        && examMetadataModule.getters.getExamMetadata()
            .find(e => 
                e.examGuid === examGuid 
                && (
                    !userExamMetadata.examVersion
                    || e.version.startsWith(`${userExamMetadata.examVersion?.split('.')[0]}.`)
                )
            )

    return examMetadata || (examGuid && getMostRecentExamForExamGuid(examGuid)) || null
}

const getLevelsInSubject = (subjectName: string | undefined) => {
    const subjectsWithLevels = examMetadataModule.state.subjectsWithLevels.value
    if (isPromise(subjectsWithLevels)) {
        return []
    } else {
        const subjectLevels = 
            subjectsWithLevels.find(subjectWithLevels => subjectWithLevels.subjectName === subjectName)?.levels 
        return subjectLevels
    }
}

export default {
    getExamMetadataById,
    getMostRecentExamForExamGuid,
    getMostRecentExamMetadataById,
    getMostRecentExamMetadata,
    getCurrentExamMetadata,
    getExamMetadata,
    getLevelsInSubject,
}
