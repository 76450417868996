<template>
    <div class="account">
        <Portal v-if="showDeleteMobileErrorModal" to="modal">
            <ModalContainer
                key="deleteMobileErrorModal"
                :is-dark-mode="isDarkMode"
                @close="showDeleteMobileErrorModal = false"
            >
                <template #modal>
                    <Modal
                        class="account__modal"
                        :show-close-button="true"
                        :is-dark-mode="isDarkMode"
                        @close="showDeleteMobileErrorModal = false"
                    >
                        <div class="account__modal-title">
                            Unable to Delete Account
                        </div>
                        <div v-dark class="account__modal-message">
                            You must cancel all subscriptions before deleting your account. Please visit the Settings
                            tab on your mobile app.
                        </div>
                        <div class="account__modal-buttons">
                            <PocketButton
                                class="account__modal-button"
                                type="secondary"
                                :is-dark-mode="isDarkMode"
                                @click="openHelpCenter"
                            >
                                Help Center
                            </PocketButton>
                            <PocketButton
                                class="account__modal-button"
                                :is-dark-mode="isDarkMode"
                                @click="showDeleteMobileErrorModal = false"
                            >
                                Close
                            </PocketButton>
                        </div>
                    </Modal>
                </template>
            </ModalContainer>
        </Portal>
        <Portal v-else-if="showDeleteStripeErrorModal" to="modal">
            <ModalContainer
                key="deleteStripeErrorModal"
                :is-dark-mode="isDarkMode"
                @close="showDeleteStripeErrorModal = false"
            >
                <template #modal>
                    <Modal
                        class="account__modal"
                        :show-close-button="true"
                        :is-dark-mode="isDarkMode"
                        @close="showDeleteStripeErrorModal = false"
                    >
                        <div class="account__modal-title">
                            Unable to Delete Account
                        </div>
                        <div v-dark class="account__modal-message">
                            You must cancel all subscriptions before deleting your account. Please visit,
                            <PocketLink
                                @click="$router.push({ name: 'purchases' })"
                                @keydown.enter="$router.push({ name: 'purchases' })"
                            >
                                <!-- eslint-disable -->Purchases</PocketLink><!-- eslint-enable -->
                            and click "edit" to cancel any active subscriptions.
                        </div>
                        <div class="account__modal-buttons">
                            <PocketButton
                                class="account__modal-button"
                                type="secondary"
                                :is-dark-mode="isDarkMode"
                                @click="openHelpCenter"
                            >
                                Help Center
                            </PocketButton>
                            <PocketButton
                                class="account__modal-button"
                                :is-dark-mode="isDarkMode"
                                @click="showDeleteStripeErrorModal = false"
                            >
                                Close
                            </PocketButton>
                        </div>
                    </Modal>
                </template>
            </ModalContainer>
        </Portal>
        <Portal v-if="showDeleteConfirmModal" to="modal">
            <ModalContainer
                key="confirmDeleteAccountModal"
                :is-dark-mode="isDarkMode"
                @close="showDeleteConfirmModal = false"
            >
                <template #modal>
                    <Modal
                        class="account__modal"
                        :show-close-button="true"
                        :is-dark-mode="isDarkMode"
                        @close="showDeleteConfirmModal = false"
                    >
                        <div class="account__modal-title">
                            Delete Your Pocket Prep Account?
                        </div>
                        <div v-dark class="account__modal-message">
                            You will lose access to all of your data. This is irreversible.
                        </div>
                        <div class="account__modal-buttons">
                            <PocketButton
                                class="account__modal-button"
                                type="secondary"
                                :is-dark-mode="isDarkMode"
                                @click="showDeleteConfirmModal = false"
                            >
                                Never mind
                            </PocketButton>
                            <PocketButton
                                type="primary-red"
                                class="account__modal-button"
                                :is-loading="isLoading"
                                :is-dark-mode="isDarkMode"
                                @click="deleteAccount"
                            >
                                Yes, Delete
                            </PocketButton>
                        </div>
                    </Modal>
                </template>
            </ModalContainer>
        </Portal>
        <Portal v-if="showForgotPasswordModal" to="modal">
            <ModalContainer
                key="forgotPasswordModal"
                :is-dark-mode="isDarkMode"
                @close="showForgotPasswordModal = false"
            >
                <template #modal>
                    <Modal
                        class="account__modal"
                        :show-close-button="true"
                        :is-dark-mode="isDarkMode"
                        @close="showForgotPasswordModal = false"
                    >
                        <div class="account__modal-title">
                            Forgot Your Password?
                        </div>
                        <div v-dark class="account__modal-message">
                            We’ll send a reset password link to {{ originalEmail }}.
                        </div>
                        <div class="account__modal-buttons">
                            <PocketButton
                                class="account__modal-button"
                                :is-dark-mode="isDarkMode"
                                type="secondary"
                                @click="showForgotPasswordModal = false"
                            >
                                Cancel
                            </PocketButton>
                            <PocketButton
                                class="account__modal-button"
                                :is-loading="isSendingPasswordLink"
                                :is-dark-mode="isDarkMode"
                                @click="sendResetPasswordLink"
                            >
                                Send Reset Password Link
                            </PocketButton>
                        </div>
                    </Modal>
                </template>
            </ModalContainer>
        </Portal>
        <Portal v-if="showEmailSentModal" to="modal">
            <ModalContainer
                key="emailSentModal"
                :is-dark-mode="isDarkMode"
                @close="showEmailSentModal = false"
            >
                <template #modal>
                    <Modal
                        class="account__modal"
                        :show-close-button="true"
                        :is-dark-mode="isDarkMode"
                        @close="showEmailSentModal = false"
                    >
                        <div class="account__modal-title">
                            Email Sent
                        </div>
                        <div v-dark class="account__modal-message">
                            <Icon type="check" />
                        </div>
                        <div class="account__modal-buttons">
                            <PocketButton
                                class="account__modal-button"
                                type="secondary"
                                :is-dark-mode="isDarkMode"
                                @click="showIntercom"
                            >
                                Contact Support
                            </PocketButton>
                            <PocketButton
                                class="account__modal-button"
                                :is-loading="isSendingPasswordLink"
                                :is-dark-mode="isDarkMode"
                                @click="sendResetPasswordLink"
                            >
                                Resend Link
                            </PocketButton>
                        </div>
                    </Modal>
                </template>
            </ModalContainer>
        </Portal>
        <SettingsHead />
        <h2 v-dark class="account__label">
            Profile
        </h2>
        <PocketInput
            v-model="firstName"
            label="First Name"
            class="account__field"
            :error="errorFields.includes('firstName')"
            :is-dark-mode="isDarkMode"
            @keydown.enter="saveChanges"
        />
        <PocketInput
            v-model="lastName"
            label="Last Name"
            class="account__field"
            :error="errorFields.includes('lastName')"
            :is-dark-mode="isDarkMode"
            @keydown.enter="saveChanges"
        />
        <PocketInput
            v-model="email"
            field-type="email"
            label="Email"
            class="account__field"
            :error="errorFields.includes('email')"
            :is-dark-mode="isDarkMode"
            @keydown.enter="saveChanges"
        />
        <template v-if="showPasswordFields">
            <div class="account__password-label">
                Update Password
            </div>
            <PocketInput
                v-model="currentPassword"
                field-type="password"
                label="Current Password"
                class="account__field"
                :error="errorFields.includes('currentPassword')"
                :is-dark-mode="isDarkMode"
                @keydown.enter="saveChanges"
            />
            <PocketButton
                v-if="showPasswordFields && breakpoint === 'black-bear'"
                type="tertiary-small"
                class="account__small-breakpoint-forgot"
                :is-dark-mode="isDarkMode"
                @click="showForgotPasswordModal = true"
            >
                Forgot Password?
            </PocketButton>
            <PocketInput
                v-model="newPassword"
                field-type="password"
                label="New Password (6+ characters)"
                class="account__field"
                :error="errorFields.includes('newPassword')"
                :is-dark-mode="isDarkMode"
                @keydown.enter="saveChanges"
            />
        </template>
        <Errors
            v-if="errors.length"
            class="account__errors"
            :errors="errors"
            :is-dark-mode="isDarkMode"
        />
        <div class="account__buttons" :class="{ 'account__buttons--expanded': showPasswordFields }">
            <PocketButton
                v-if="!showPasswordFields"
                type="tertiary"
                :is-dark-mode="isDarkMode"
                @click="showPasswordFields = true"
            >
                Update Password
            </PocketButton>
            <PocketButton
                v-else-if="showPasswordFields && breakpoint !== 'black-bear'"
                type="tertiary-small"
                :is-dark-mode="isDarkMode"
                @click="showForgotPasswordModal = true"
            >
                Forgot Password?
            </PocketButton>
            <div 
                class="account__buttons-save" 
                :class="{ 
                    'account__buttons-save--expanded': showPasswordFields, 
                    'account__buttons-save--small-breakpoint': showPasswordFields && breakpoint === 'black-bear'
                }"
            >
                <PocketButton
                    v-if="showPasswordFields"
                    :is-dark-mode="isDarkMode"
                    type="tertiary"
                    @click="showPasswordFields = false"
                >
                    Cancel
                </PocketButton>
                <PocketButton
                    :is-dark-mode="isDarkMode"
                    :is-loading="isLoading"
                    :disabled="!hasUnsavedChanges || !firstName.trim() || !lastName.trim()"
                    @click="saveChanges"
                >
                    Save Changes
                </PocketButton>
            </div>
        </div>
        <PocketButton
            type="tertiary-red"
            class="account__delete"
            :class="{ 'account__delete--expanded': showPasswordFields }"
            :is-dark-mode="isDarkMode"
            @click="clickDeleteAccount"
        >
            Delete My Pocket Prep Account
        </PocketButton>
        <h2
            id="preferences"
            v-dark
            class="account__label"
        >
            Preferences
        </h2>
        <div v-dark class="account__preferences-label">
            Notifications
        </div>
        <div class="account__reminders">
            <div class="account__reminders-left">
                <ToggleSwitch
                    v-model="enableReminders"
                    :is-dark-mode="isDarkMode"
                    size="large"
                    aria-label="Email Reminders"
                />
            </div>
            <div class="account__reminders-right">
                <div class="account__reminders-title">
                    Email Reminders
                </div>
                <div v-dark class="account__reminders-description">
                    Nudges to study and encouragement in your inbox
                </div>
                <PocketSelect
                    v-if="enableReminders"
                    v-model="reminderFrequency"
                    :data="reminderOptions"
                    :is-dark-mode="isDarkMode"
                    class="account__field"
                    label="Frequency"
                />
            </div>
        </div>
        <div v-dark class="account__preferences-label account__sort-preferences-label">
            Sort Preferences
        </div>
        <div id="account__sort-label-id" class="account__sort-label">
            Sort <span>Subject Insights</span>
        </div>
        <Radio
            v-model="sortPreference"
            class="account__sort-radio"
            :is-dark-mode="isDarkMode"
            :data="sortOptions"
            labelled-by="account__sort-label-id"
        />
    </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-facing-decorator'
import SettingsHead from '@/components/Settings/SettingsHead.vue'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import { isSupportWindow, isValidEmail } from '@/utils'
import type { TUpdateUserPayload } from '@/store/user/actions'
import { toastModule } from '@/store/toast/module'
import { stripeModule } from '@/store/stripe/module'
import { subscriptionModule } from '@/store/subscription/module'
import { screenModule } from '@/store/screen/module'
import type { Study } from '@pocketprep/types'

type TSortOption = { value: 'weakest' | 'strongest' | 'alphabetical'; label: string }
type TReminderOption = { value: 'daily' | 'weekly'; label: 'Daily' | 'Weekly' }
@Component({
    components: {
        SettingsHead,
        PocketInput: UIKit.Input,
        PocketButton: UIKit.Button,
        PocketSelect: UIKit.Select,
        ToggleSwitch: UIKit.ToggleSwitch,
        Errors: UIKit.Errors,
        Icon: UIKit.Icon,
        Radio: UIKit.Radio,
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
        PocketLink: UIKit.Link,
    },
})
export default class Account extends Vue {
    firstName = ''
    lastName = ''
    email = ''
    originalFirstName = ''
    originalLastName = ''
    originalEmail = ''
    currentPassword = ''
    newPassword = ''
    isValidEmail = isValidEmail
    sortPreference: TSortOption = { value: 'weakest', label: 'Weakest to Strongest' }
    showPasswordFields = false
    isLoading = true
    isSendingPasswordLink = false
    enableReminders = false
    showDeleteConfirmModal = false
    showForgotPasswordModal = false
    showEmailSentModal = false
    showDeleteMobileErrorModal = false
    showDeleteStripeErrorModal = false
    sortOptions: TSortOption[] = [
        { value: 'weakest', label: 'Weakest to Strongest' }, 
        { value: 'strongest', label: 'Strongest to Weakest' }, 
        { value: 'alphabetical', label: 'Alphabetically' },
    ]
    reminderOptions: TReminderOption[] = [{ value: 'daily', label: 'Daily' }, { value: 'weekly', label: 'Weekly' }]
    reminderFrequency: TReminderOption = { value: 'daily', label: 'Daily' }
    errorFields: string[] = []
    errors: string[] = []

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }
    
    get hasUnsavedChanges () {
        return this.firstName.trim() !== this.originalFirstName 
            || this.lastName.trim() !== this.originalLastName 
            || this.email !== this.originalEmail
            || (this.showPasswordFields && (this.currentPassword || this.newPassword))
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    async mounted () {
        await Promise.all([
            userModule.actions.fetchUserData(),
            stripeModule.actions.fetchStripeSubscriptions(),
        ])
        this.firstName = userModule.state.user?.firstName || ''
        this.lastName = userModule.state.user?.lastName || ''
        this.email = userModule.state.user?.email || ''
        this.originalFirstName = this.firstName
        this.originalLastName = this.lastName
        this.originalEmail = this.email
        this.enableReminders = !!userModule.state.user?.studyRemindersFrequency
        this.reminderFrequency = userModule.state.user?.studyRemindersFrequency === 'weekly'
            ? { value: 'weekly', label: 'Weekly' }
            : this.reminderFrequency
        this.sortPreference = this.sortOptions
            .find(o => o.value === userModule.state.settings.subjectInsightsSort)
            || { value: 'weakest', label: 'Weakest to Strongest' }

        const settingsHeaderEl = document.getElementById('settings')
        // Scroll into view the top of the page on the setting header
        if (settingsHeaderEl && this.breakpoint !== 'black-bear') {
            settingsHeaderEl.scrollIntoView()
        }
        this.isLoading = false
    }

    openHelpCenter () {
        window.open('https://help.pocketprep.com/en/articles/3697654-how-do-i-cancel-my-subscription', '_blank')
    }

    async saveChanges () {
        this.errors = []
        this.errorFields = []

        if (!isValidEmail(this.email)) {
            this.errors.push('Invalid email address.')
            this.errorFields.push('email')
        }

        if (!this.firstName || !this.lastName) {
            if (!this.firstName || !this.firstName.trim()) {
                this.errorFields.push('firstName')
            }
            if (!this.lastName || !this.lastName.trim()) {
                this.errorFields.push('lastName')
            }
            this.errors.push('Enter a first and last name.')
        }

        if (this.showPasswordFields && (this.currentPassword || this.newPassword)) {
            if (!this.currentPassword) {
                this.errors.push('Enter your current password.')
                this.errorFields.push('currentPassword')
            }
            if (!this.newPassword) {
                this.errors.push('Invalid new password.')
                this.errorFields.push('newPassword')
            }
            if (this.newPassword.length < 6) {
                this.errors.push('Password must be at least 6 characters.')
                this.errorFields.push('newPassword')
            }

            if (!this.errors.length) {
                try {
                    await userModule.actions.signIn({ username: this.originalEmail, password: this.currentPassword })
                } catch (e) {
                    this.errors.push('Incorrect current password.')
                    this.errorFields.push('currentPassword')
                }
            }
        }

        if (!this.errors.length) {
            this.isLoading = true
            const updateUserPayload: TUpdateUserPayload = {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
            }
            if (this.currentPassword && this.newPassword && this.showPasswordFields) {
                updateUserPayload.password = this.newPassword
            }
            try {
                await userModule.actions.updateUser(updateUserPayload)
                this.originalFirstName = this.firstName
                this.originalLastName = this.lastName
                this.originalEmail = this.email

                if (this.newPassword) {
                    this.newPassword = ''
                    this.currentPassword = ''
                    this.showPasswordFields = false
                }
                toastModule.actions.displayToast({
                    title: 'Account information saved successfully',
                })
            } catch (err) {
                const error = (err as { error?: string }).error || (err as { message?: string }).message
                if (!err) {
                    /* istanbul ignore next */
                    this.errors.push('Unable to update account information.')
                } else if (error) {
                    this.errors.push(error)
                } else if (err instanceof Error || typeof err === 'string') {
                    this.errors.push(err.toString())
                }
            } finally {
                const  newUserData = await userModule.actions.fetchUserData()
                if (newUserData) {
                    this.email = newUserData?.user.email
                }
                this.isLoading = false
            }
        }
    }

    async sendResetPasswordLink () {
        this.isSendingPasswordLink = true
        await userModule.actions.resetPassword()
        this.showForgotPasswordModal = false
        this.showEmailSentModal = true
        this.isSendingPasswordLink = false
    }

    clickDeleteAccount () {
        // if any non-Stripe active subscriptions, show error modal
        const renewingSub = subscriptionModule.state.subscriptions.find(s => s.willAutoRenew)
        if (renewingSub) {
            if ((renewingSub.receipt as Study.Class.ReceiptJSON).source === 'Stripe') {
                this.showDeleteStripeErrorModal = true
            } else {
                this.showDeleteMobileErrorModal = true
            }
        } else {
            this.showDeleteConfirmModal = true
        }
    }

    async deleteAccount () {
        this.isLoading = true

        await userModule.actions.deleteUser()

        this.$router.push({ name: 'sign-in', query: { delete: 'success' } })
    }

    showIntercom () {
        /* istanbul ignore next */
        if (import.meta.env.VUE_APP_INTERCOM_APP_ID && !isSupportWindow()) {
            window.Intercom('show')
        }

        this.showEmailSentModal = false
    }

    @Watch('sortPreference')
    updateSortPreference () {
        userModule.actions.updateLocalSettings({
            subjectInsightsSort: this.sortPreference.value,
        })
    }

    @Watch('enableReminders')
    @Watch('reminderFrequency')
    async updateNotificationPreference () {
        const reminderFrequency = this.enableReminders ? this.reminderFrequency.value : 'never'
        await userModule.actions.updateReminderPreferences(reminderFrequency)
    }
}
</script>
<style lang="scss" scoped>
.account {
    &__modal {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 80px;
        width: 392px;
        padding: 35px 0 26px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include breakpoint(black-bear) {
            width: 356px;
            height: auto;
            max-height: 638px;
            top: 10px;
        }
    }

    &__modal-title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        width: 309px;
        margin: 0 auto 12px;
    }

    &__modal-message {
        font-size: 15px;
        line-height: 22px;
        color: $slate-03;
        margin-bottom: 29px;
        width: 309px;

        &--dark {
            color: $white;
        }

        a {
            color: $brand-blue;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        svg {
            width: 31px;
            height: 28px;
            color: $green;
            margin-top: 13px;
        }
    }

    &__modal-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__modal-button {
        margin: 0 6px;
    }

    &__label {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        margin: 0 0 19px;

        &--dark {
            color: rgba($white, 0.86);
        }
    }

    &__field {
        width: 381px;
        margin: 0 -12px 12px;

        @include breakpoint(black-bear) {
            width: calc(100% + 24px);
        }
    }

    &__small-breakpoint-forgot {
        margin-bottom: 24px;
    }

    &__buttons {
        display: flex;
        max-width: 381px;
        width: 100%;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 24px;

        &--expanded {
            margin-left: -2px;
        }

        @include breakpoint(black-bear) {
            max-width: none;
        }
    }

    &__buttons-save {
        width: 140px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 9px;

        @include breakpoint(black-bear) {
            margin-right: -15px;
        }

        &--expanded {
            width: 216px;
            justify-content: space-between;
        }

        &--small-breakpoint {
            margin-left: auto;
        }

        svg {
            width: 23px;
            height: 23px;
        }
    }

    &__errors {
        width: 381px;
        margin: 0 -12px;
        box-sizing: border-box;

        @include breakpoint(brown-bear) {
            width: calc(100% + 24px);
        }
    }

    &__delete {
        margin-bottom: 45px;

        &--expanded {
            margin-left: -3px;
        }
    }

    &__preferences-label {
        font-size: 14px;
        line-height: 17px;
        color: $slate-01;
        padding: 7px 11px;
        margin-left: -12px;
        margin-right: -12px;
        border-bottom: 1px solid $gray-divider;
        margin-bottom: 28px;

        &--dark {
            color: $pewter;
            border-bottom-color: rgba($pewter, 0.6);
        }
    }

    &__reminders {
        display: flex;

        @include breakpoint(black-bear) {
            display: block;
            position: relative;
        }
    }

    &__reminders-left {
        margin-right: 15px;

        @include breakpoint(black-bear) {
            position: absolute;
            right: -12px;
            top: 2px;
            margin-right: 0;
        }
    }

    &__reminders-title {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 4px;
    }

    &__reminders-description {
        font-size: 14px;
        line-height: 18px;
        color: $slate-03;
        margin-bottom: 23px;

        &--dark {
            color: $pewter;
        }

        @include breakpoint(black-bear) {
            max-width: 263px;
        }
    }

    &__sort-preferences-label {
        margin-top: 34px;
    }

    &__color-preferences-label {
        margin-top: 42px;
    }

    &__sort-label {
        font-size: 15px;
        margin-bottom: 10px;

        span {
            font-weight: 600;
        }
    }

    &__color-radio {
        :deep(ul) {
            display: flex;

            li:first-child {
                margin-right: 52px;
            }
        }
    }

    &__color-radio-label {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 3px;
    }

    &__color-radio-subtext {
        font-size: 14px;
        line-height: 22px;
        color: $slate-03;
        margin-bottom: 10px;
    }

    &__color-radio-image {
        width: 232px;
        height: 94px;
    }

    &__password-label {
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        margin-top: 29px;
        margin-bottom: 19px;
    }
}
</style>