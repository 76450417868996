<template>
    <div class="question-grid">
        <div v-dark class="question-grid__header">
            <div class="question-grid__header-content">
                <div
                    tabindex="-1"
                    class="question-grid__title"
                >
                    All Questions
                </div>
                <Icon
                    v-dark
                    class="question-grid__close-icon"
                    type="close"
                    role="button"
                    aria-label="Close All Questions"
                    tabindex="0"
                    @click="emitClose"
                />
                <div class="question-grid__tabs">
                    <ReviewTab
                        v-for="tab in tabs"
                        :key="tab"
                        class="question-grid__tab"
                        :is-active="activeTab === tab"
                        :label="tab"
                        :count="questionCounts[tab]"
                        role="tab"
                        :aria-selected="activeTab === tab ? 'true' : 'false'"
                        @keydown.enter="activeTab = tab"
                        @click="activeTab = tab"
                    />
                    <div v-dark class="question-grid__legend">
                        <span class="question-grid__legend-key-label">Key:</span>
                        <span v-dark class="question-grid__legend-unanswered-icon" />
                        <span class="question-grid__legend-unanswered-label">Unanswered</span>
                        <span v-dark class="question-grid__legend-answered-icon" />
                        <span class="question-grid__legend-answered-label">Answered</span>
                        <Icon
                            v-dark
                            class="question-grid__legend-flagged-icon"
                            type="flagFeedback"
                            :secondary-color="brandColors.bananaBread"
                        />
                        <span class="question-grid__legend-flagged-label">Flagged</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-dark class="question-grid__questions">
            <div v-dark class="question-grid__tablet-legend-container" />
            <TransitionGroup
                v-if="questionCounts[activeTab]"
                tag="div"
                class="question-grid__questions-content"
                name="question-grid__question"
            >
                <template v-for="(question, index) in questions">
                    <div
                        v-if="isQuestionVisible(question.serial)"
                        :key="question.serial"
                        v-dark
                        tabindex="0"
                        class="question-grid__question"
                        :class="{
                            'question-grid__question--flagged': flaggedSerialSet.has(question.serial),
                        }"
                        role="button"
                        :aria-label="`Question ${ index + 1 }, ${
                            answeredSerialSet.has(question.serial) ? 'answered' : 'unanswered'
                        }${
                            flaggedSerialSet.has(question.serial) ? ', flagged' : ''
                        }`"
                        @click="questionClicked(index)"
                        @keydown.enter="questionClicked(index)"
                        @keydown.space="questionClicked(index)"
                        @mousedown.prevent
                    >
                        <span>{{ index + 1 }}</span>
                        <div class="question-grid__question-icons">
                            <Icon
                                v-if="flaggedSerialSet.has(question.serial)"
                                v-dark
                                class="question-grid__question-flagged-icon"
                                type="flagFeedback"
                                :secondary-color="brandColors.bananaBread"
                            />
                            <span
                                v-if="answeredSerialSet.has(question.serial)"
                                v-dark
                                class="question-grid__question-answered-icon"
                            />
                            <span
                                v-else
                                v-dark
                                class="question-grid__question-unanswered-icon"
                            />
                        </div>
                    </div>
                </template>
            </TransitionGroup>
            <div
                v-else
                class="question-grid__blank"
            >
                <EmptyState
                    v-if="activeTab === 'All'"
                    title="Loading questions..."
                    :is-dark-mode="isDarkMode"
                />
                <EmptyState
                    v-else-if="activeTab === 'Flagged'"
                    state="flagged"
                    :title="breakpoint !== 'black-bear' ? 'You haven’t flagged any questions.' : '0 Flagged Questions'"
                    :message="breakpoint !== 'black-bear' ? 
                        'Click the flag icon at the bottom of any question and you can review them here.' : 
                        'Tap the flag icon at the bottom of any question and you can review them here.'
                    "
                    backgroundColor="white"
                    :is-dark-mode="isDarkMode"
                />
                <EmptyState
                    v-else-if="activeTab === 'Unanswered'"
                    :title="breakpoint !== 'black-bear' ? 
                        'You don’t have any unanswered questions.' : '0 unanswered questions'"
                    message="Impressive!"
                    :is-dark-mode="isDarkMode"
                />
                <EmptyState
                    v-else-if="activeTab === 'Answered'"
                    :title="breakpoint !== 'black-bear' ? 
                        'You don’t have any answered questions.' : '0 answered questions'"
                    message="Let’s get started!"
                    backgroundColor="white"
                    :is-dark-mode="isDarkMode"
                >
                    <template #imageRow>
                        <img
                            v-if="!isDarkMode"
                            src="@/assets/review/brain-pencil.png"
                            alt=""
                            class="question-grid__blank-image"
                        >
                        <img
                            v-if="isDarkMode"
                            src="@/assets/review/brain-pencil-dm.png"
                            alt=""
                            class="question-grid__blank-image"
                        >
                    </template>
                </EmptyState>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'
import type { Study } from '@pocketprep/types'
import ReviewTab from '@/components/ReviewTab.vue'
import NoResultsBlob from '@/assets/review/NoResultsBlob.vue'
import type { TActiveMockExamQuiz } from '@/store/mockExam/state'
import { screenModule } from '@/store/screen/module'

type TTabName = 'All' | 'Flagged' | 'Unanswered' | 'Answered'

@Component({
    components: {
        Icon: UIKit.Icon,
        ReviewTab,
        NoResultsBlob,
        EmptyState: UIKit.EmptyState,
    },
})
export default class QuestionGrid extends Vue {
    @Prop() questions!: TActiveMockExamQuiz['questions']
    @Prop() answers!: TActiveMockExamQuiz['answers']
    @Prop() flaggedQuestions!: Study.Class.UserExamMetadataJSON['flaggedQuestions']
    @Prop({ default: false }) isDarkMode!: boolean

    tabs: TTabName[] = [
        'All',
        'Flagged',
        'Unanswered',
        'Answered',
    ]
    activeTab: TTabName = 'All'
    brandColors = BrandColors

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get flaggedSerialSet () {
        return new Set(this.flaggedQuestions || [])
    }

    get answeredSerialSet () {
        return new Set(this.answers.map(answer => answer.questionSerial))
    }

    get questionCounts (): Record<TTabName, number> {
        return {
            All: this.questions.length,
            Flagged: this.flaggedSerialSet.size,
            Unanswered: this.questions.length - this.answeredSerialSet.size,
            Answered: this.answeredSerialSet.size,
        }
    }

    mounted () {
        setTimeout(() => {
            const titleEl = document.querySelector('.question-grid__title') as HTMLElement | null
            titleEl?.focus()
        }, 0)
    }

    isQuestionVisible (serial: string) {
        if (this.activeTab === 'Flagged' && this.flaggedSerialSet.has(serial)) {
            return true
        } else if (this.activeTab === 'Unanswered' && !this.answeredSerialSet.has(serial)) {
            return true
        } else if (this.activeTab === 'Answered' && this.answeredSerialSet.has(serial)) {
            return true
        } else if (this.activeTab === 'All') {
            return true
        } else {
            return false
        }
    }

    questionClicked (index: number) {
        this.emitQuestionClicked(index)
        this.emitClose()
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('questionClicked')
    emitQuestionClicked (questionIndex: number) {
        return questionIndex
    }
}
</script>

<style lang="scss" scoped>
.question-grid {
    position: absolute;
    top: 0;
    left: -65px;
    width: calc(100% + 130px);
    height: calc(100% + 65px);
    background-color: $white;
    color: $brand-black;
    border-radius: 6px 6px 0 0;
    z-index: 2;
    overflow: hidden;

    @include breakpoint(polar-bear) {
        left: -23px;
        width: calc(100% + 45px);
    }

    @include breakpoint (brown-bear) {
        left: -19px;
        width: calc(100% + 38px);
    }

    @include breakpoint (black-bear) {
        left: 0;
        width: 100%;
    }

    &__header {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 168px;
        box-sizing: border-box;
        padding-top: 43px;

        &--dark {
            background-color: $mariner;
            color: $white;
        }
    }

    &__header-content {
        width: 940px;
        box-sizing: border-box;

        @include breakpoint(brown-bear) {
            position: relative;
            width: 690px;
        }

        @include breakpoint(black-bear) {
            width: 100%;
            padding: 0 max(4%, 17px);
        }
    }

    &__title {
        outline: none;
        font-size: 26px;
        line-height: 34px;
        font-weight: 600;
    }

    &__close-icon {
        position: absolute;
        top: 38px;
        right: 61px;
        width: 40px;
        height: 40px;
        outline: none;
        color: $brand-blue;
        cursor: pointer;

        &:hover,
        &:focus {
            color: $brand-black;
        }

        &--dark {
            color: $banana-bread;

            &:hover,
            &:focus {
                color: $butterscotch;
            }
        }

        @include breakpoint(brown-bear) {
            top: -4px;
            right: 6px;
        }

        @include breakpoint(black-bear) {
            top: -24px;
            right: 6%;
        }
    }

    &__tabs {
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
        width: 100%;

        @include breakpoint(black-bear) {
            justify-content: center;
            left: 0;
        }
    }

    &__tab {
        width: 100px;

        &:focus::before {
            width: 100px;
        }

        &--dark:not(.review-tab--active) {
            border-bottom-color: $mariner;
        }

        @include breakpoint(black-bear) {
            width: 90px;

            &:focus::before {
                width: 90px;
            }
        }
    }

    &__legend {
        display: flex;
        align-items: center;
        color: $ash;
        margin-left: 189px;
        font-size: 13px;
        line-height: 14px;

        &--dark {
            color: $fog;
        }

        @include breakpoint(brown-bear) {
            position: absolute;
            bottom: -34px;
            right: 0;
            margin-left: 0;
            font-size: 14px;
            z-index: 3;
        }

        @include breakpoint(black-bear) {
            right: 50%;
            transform: translateX(50%);
        }
    }

    &__legend-key-label {
        margin-right: 11px;
    }

    &__legend-unanswered-icon,
    &__question-unanswered-icon {
        box-sizing: border-box;
        display: inline-block;
        border: 1.8px solid $steel;
        border-radius: 100%;
        width: 15px;
        height: 15px;

        &--dark {
            border-color: $fog;
        }
    }

    &__legend-unanswered-label {
        margin-left: 9px;
        margin-right: 16px;
    }

    &__legend-answered-icon,
    &__question-answered-icon {
        box-sizing: border-box;
        display: inline-block;
        background-color: $ash;
        border: 1.8px solid $brand-black;
        border-radius: 100%;
        width: 15px;
        height: 15px;

        &--dark {
            background-color: $gray-background;
            border-color: $fog;
        }
    }

    &__legend-answered-label {
        margin-left: 9px;
        margin-right: 16px;
    }

    &__legend-flagged-icon,
    &__question-flagged-icon {
        width: 22px;
        height: 22px;
        color: #ce8500;

        &--dark {
            color: $butterscotch;
        }
    }

    &__legend-flagged-label {
        margin-left: 6px;
    }

    &__questions {
        position: relative;
        height: calc(100% - 168px);
        padding-top: 34px;
        padding-bottom: 72px;
        box-sizing: border-box;
        overflow: auto;
        background-color: $gray-background;

        &--dark {
            background-color: $charcoal;
            color: $white;
        }

        @include breakpoint(brown-bear) {
            padding-top: 0;
        }
    }

    &__tablet-legend-container {
        display: none;
        position: sticky;
        top: 0;
        height: 48px;
        width: 100%;
        background-color: $gray-background;
        z-index: 2;
        border-width: 1px 0;
        border-color: $fog;
        border-style: solid;
        box-sizing: border-box;
        margin-bottom: 18px;

        &--dark {
            background-color: $charcoal;
            border-color: $slate;
            border-top: none;
        }

        @include breakpoint(brown-bear) {
            display: block;
        }
    }

    &__questions-content {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-content: center;
        column-gap: 20px;
        width: 940px;

        @include breakpoint(brown-bear) {
            grid-template-columns: repeat(5, 1fr);
            column-gap: 18px;
            width: 690px;
        }

        @include breakpoint(black-bear) {
            grid-template-columns: repeat(auto-fill, 104px);
            column-gap: 16px;
            width: 100%;
            padding: 0 16px 0 15px;
            box-sizing: border-box;
        }
    }

    &__question {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        outline: none;
        width: 140px;
        height: 52px;
        padding: 14px 16px;
        box-sizing: border-box;
        border: 1px solid rgba($pewter, 0.85);
        border-radius: 5px;
        box-shadow: 0 1px 4px 0 rgba($ash, 0.3);
        background-color: $white;
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.1px;
        line-height: 24px;
        transition: opacity 0.5s, transform 0.5s;
        cursor: pointer;

        &-enter,
        &-leave-to {
            opacity: 0;
        }

        &-leave-active {
            display: none;
        }

        &--flagged {
            border-color: $chicken-masala;
            background-color: $floral-white;
        }

        &:hover {
            border: 2px solid $slate;
            padding: 13px 15px;

            &--dark {
                border-color: $banana-bread;
            }
        }

        &:focus::before {
            content: '';
            position: absolute;
            box-sizing: border-box;
            top: -4px;
            left: -4px;
            width: 146px;
            height: 58px;
            border: 1px solid $brand-blue;
            border-radius: 8px;
        }

        &:hover:focus::before {
            top: -5px;
            left: -5px;
        }

        &--dark {
            background-color: $brand-black;
            border-color: $slate;
            box-shadow: 0 1px 4px 0 $jet;
        }

        &--dark#{&}--flagged {
            border-color: $banana-bread;
            // This is a hack to make background color match the designs
            background:
                linear-gradient(rgba($banana-bread, 0.12), rgba($banana-bread, 0.12)),
                linear-gradient($brand-black, $brand-black);
        }

        &--dark:hover {
            border-color: rgba($white, 0.6);
        }

        &--dark:focus::before {
            border-color: $banana-bread;
        }

        @include breakpoint(brown-bear) {
            width: auto;
        }
    }

    &__question-icons {
        display: flex;
        align-items: center;
    }

    &__question-flagged-icon {
        margin-right: 4px;
    }

    &__blank {
        text-align: center;
        padding-top: 66px;

        :deep(.uikit-empty-state) {
            margin: auto;
        }
    }

    &__blank-image {
        width: 130px;
        display: block;
        margin: 0 auto 16px auto;
    }
}
</style>
