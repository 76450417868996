import { reactive } from 'vue'
import actions from '@/store/keywords/actions'
import state from '@/store/keywords/state'
import getters from '@/store/keywords/getters'

export const keywordsModule = {
    state: reactive(state),
    getters,
    actions,
}
