<template>
    <div class="settings-head">
        <h1 class="settings-head__left">
            {{ $route.name }}
        </h1>
        <slot />
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class SettingsHead extends Vue {

}
</script>

<style lang="scss" scoped>
.settings-head {
    position: relative;

    &__left {
        font-size: 26px;
        line-height: 34px;
        font-weight: 600;
        text-transform: capitalize;
        margin: 0 0 32px;

        @include breakpoint(black-bear) {
            margin-bottom: 9px;
        }
    }
}
</style>