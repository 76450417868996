<template>
    <div class="license-field">
        <div class="license-field__field">
            <PocketInput
                v-model="licenseKey"
                label="License Code"
                placeholder="15 digit code"
                class="license-field__license"
                :error="errors.length"
                :is-dark-mode="isDarkMode"
                @focusout="validateLicense({ checkShort: true })"
            />
            <Icon
                v-if="isLoading"
                v-dark
                type="loading2"
                class="license-field__loading"
                :is-dark-mode="isDarkMode"
            />
        </div>
        <Errors
            v-if="errors.length"
            class="license-field__errors"
            :errors="errors"
            :is-dark-mode="isDarkMode"
        />
        <div
            v-if="licenseExams && licenseExams.length && licenseExams.length === 1"
            v-dark
            class="license-field__label"
        >
            Exam
        </div>
        <div
            v-else-if="licenseExams && licenseExams.length && licenseExams.length > 1"
            v-dark
            class="license-field__label"
        >
            {{ licenseExams.length }} Exams
        </div>
        <div
            v-for="(licenseExam, index) in licenseExams"
            :key="index"
            class="license-field__list"
        >
            <ExamCard
                :exam="licenseExam"
                :bundle="getLicenseExamBundle(licenseExam)"
                class="license-field__exam"
                :disable-interaction="true"
                :is-dark-mode="isDarkMode"
            />
        </div>
        <div v-if="licenseExams && licenseExams.length" class="license-field__not-correct">
            <PocketLink
                class="license-field__not-correct-link"
                tabindex="0"
                type="tertiary-small"
                :is-dark-mode="isDarkMode"
                @click="showIntercom"
            >
                Contact our support team
            </PocketLink>
            if this list of exams doesn't seem correct.
        </div>
    </div>
</template>

<script lang="ts">
import { bundleModule } from '@/store/bundle/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { licenseModule } from '@/store/license/module'
import { userModule } from '@/store/user/module'
import { isSupportWindow } from '@/utils'
import type { Study } from '@pocketprep/types'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop, Watch, Emit } from 'vue-facing-decorator'

@Component({
    components: {
        PocketLink: UIKit.Link,
        Errors: UIKit.Errors,
        PocketInput: UIKit.Input,
        Icon: UIKit.Icon,
        ExamCard: UIKit.ExamCard,
    },
})
export default class LicenseField extends Vue {
    @Prop({ default: '' }) modelValue!: string

    isLoading = false
    validatedLicense: null | Study.Class.LicenseJSON = null
    licenseKey: null | string = null
    validateDelayTimer: null | ReturnType<typeof setTimeout> = null
    errors: string[] = []

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get licenseExams () {
        const les: Study.Class.ExamMetadataJSON[] = []
        if (this.validatedLicense && this.validatedLicense.examGuid) {
            // Convert a license to a licenseExam
            const licenseExam = examMetadataModule.getters.getMostRecentExamForExamGuid(this.validatedLicense.examGuid)
            les.push(licenseExam)
            if (this.validatedLicense.linkedLicenses) {
                this.validatedLicense.linkedLicenses.forEach(linkedLicense => {
                    // convert a license to a licenseExam
                    const ll = linkedLicense as Study.Class.LicenseJSON
                    if (ll && ll.examGuid) {
                        const linkedLicenseExam = examMetadataModule.getters.getMostRecentExamForExamGuid(ll.examGuid)
                        les.push(linkedLicenseExam)
                    }
                })
            }
        }
        return les
    }

    getLicenseExamBundle (licenseExam: Study.Class.ExamMetadataJSON) {
        const le = licenseExam
        return le && bundleModule.getters.getBundles()
            .find(b => b.exams.find(e => e.objectId === le.objectId))
    }

    async mounted () {
        this.licenseKey = this.modelValue || (typeof this.$route.query.license === 'string'
            ? this.$route.query.license
            : '')

        await Promise.all([
            examMetadataModule.actions.fetchExamMetadata(),
        ])

        this.isLoading = false
    }

    @Watch('licenseKey')
    validateLicense (options: { checkShort: boolean }) {
        this.validatedLicense = null
        this.errors = []

        if (this.validateDelayTimer) {
            clearTimeout(this.validateDelayTimer)
        }
        if (this.licenseKey && this.licenseKey.match(/[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}/)) {
            this.isLoading = true
            this.validateDelayTimer = setTimeout(async () => {
                try {
                    if (this.licenseKey) {
                        this.validatedLicense = await licenseModule.actions.validateLicense(this.licenseKey)
                    }
                    // If license is valid, we should remove the license qs params from url (if present)
                    if (this.$route.query.license) {
                        this.$router.replace({
                            query: {
                                ...this.$route.query,
                                license: undefined,
                                firstName: undefined,
                                lastName: undefined,
                            },
                        })
                    }
                } catch (err) {
                    const error = (err as { error?: string } | undefined)?.error
                    if (!err) {
                        this.errors = [ 'That license code isn\'t valid. Try again?' ]
                    } else if (error) {
                        this.errors = [ error ]
                    } else if (err instanceof Error || typeof err === 'string') {
                        this.errors = [ err.toString() ]
                    }
                } finally {
                    this.isLoading = false
                }
            }, 200)
        } else if (
            (options.checkShort && this.licenseKey && this.licenseKey.length)
            || (!options.checkShort && this.licenseKey && this.licenseKey.length === 17)
        ) {
            this.errors = [ 'That license code isn’t valid. License codes are case sensitive and should be in the ' +
                'format: XXXXX-XXXXX-XXXXX' ]
        }
    }

    @Watch('validatedLicense')
    updatedValidLicense (license: Study.Class.LicenseJSON) {
        if (license) {
            this.emitUpdateModelValue(license.licenseKey)
        } else {
            this.emitUpdateModelValue(null)
        }
        
    }

    showIntercom () {
        /* istanbul ignore next */
        if (import.meta.env.VUE_APP_INTERCOM_APP_ID && !isSupportWindow()) {
            window.Intercom('show')
        }
    }

    @Emit('update:modelValue')
    emitUpdateModelValue (licenseKey: string | null) {
        return licenseKey
    }

}
</script>

<style lang="scss" scoped>
.license-field {
    &__field {
        position: relative;
    }

    &__license,
    &__exam,
    &__errors {
        margin-left: -12px;
        margin-right: -12px;
        width: calc(100% + 24px);
        box-sizing: border-box;
    }

    &__loading {
        position: absolute;
        bottom: 8px;
        width: 20px;
        height: 20px;
        right: -1px;
    }

    &__license {
        margin-bottom: 23px;
    }

    &__errors {
        margin-top: -12px;
        margin-bottom: 40px;
    }

    &__label {
        font-size: 13px;
        line-height: 14px;
        color: $slate-03;
        margin-bottom: 9px;

        &--dark {
            color: $fog;
        }
    }

    &__exam {
        margin-bottom: 14px;
    }

    &__not-correct {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 28px;
    }
}
</style>