import { initLoadable } from '@/store/utils'
import type { Loadable } from '@/store/utils'
import type { Study } from '@pocketprep/types'

type TBundleState = {
    bundles: Loadable<Study.Class.BundleJSON[]>
}

export const getInitialBundleState = (): TBundleState => {
    return {
        bundles: initLoadable([]),
    }
}

const moduleState = getInitialBundleState()

export default moduleState
