<template>
    <Portal to="modal">
        <ModalContainer key="forgotPasswordModal">
            <div class="forgot-password">
                <div class="forgot-password__head">
                    <div
                        class="forgot-password__back-btn"
                        tabindex="0"
                        role="button"
                        aria-label="Back"
                        @click="$router.push({ name: 'sign-in', query: { type: 'password' } })"
                        @keydown.enter="$router.push({ name: 'sign-in', query: { type: 'password' } })"
                        @mousedown.prevent
                    >
                        <Icon class="forgot-password__back-btn-icon" type="arrow" />
                    </div>
                    <div v-if="linkSent" class="forgot-password__sent">
                        <Icon type="check" />
                        <span role="alert">Link Sent</span>
                    </div>
                </div>
                <div class="forgot-password__body">
                    <div tabindex="-1" class="forgot-password__title">
                        Forgot your password?
                    </div>
                    <div class="forgot-password__subtitle">
                        Enter the email associated with your account and we'll send an email to reset your password.
                    </div>
                    <PocketInput
                        v-model="email"
                        label="Email"
                        class="forgot-password__input"
                        field-type="email"
                        @keydown.enter="submitForgotPassword"
                    />
                    <Errors
                        v-if="errors.length"
                        class="forgot-password__errors"
                        :errors="errors"
                    />
                    <PocketButton
                        :disabled="!email"
                        :is-loading="isLoading"
                        class="forgot-password__submit"
                        @click="submitForgotPassword"
                    >
                        {{ linkSent ? 'Resend' : 'Send' }} Link
                    </PocketButton>
                </div>
            </div>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import { isValidEmail } from '@/utils'

@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        Icon: UIKit.Icon,
        PocketInput: UIKit.Input,
        PocketButton: UIKit.Button,
        Errors: UIKit.Errors,
    },
})
export default class ForgotPassword extends Vue {
    email = ''
    linkSent = false
    errors: string[] = []
    isValidEmail = isValidEmail
    isLoading = false

    mounted () {
        setTimeout(() => {
            const titleEl = document.querySelector('.forgot-password__title') as HTMLElement | null
            titleEl?.focus()
        }, 500)
    }

    async submitForgotPassword () {
        this.errors = []
        this.linkSent = false
        this.isLoading = true
        
        try {
            if (!isValidEmail(this.email)) {
                throw new Error('Invalid email address.')
            }
            await userModule.actions.forgotPassword(this.email)
            this.linkSent = true
        } catch (err) {
            if (err instanceof Error) {
                this.errors.push(err.toString())
            }
        } finally {
            this.isLoading = false
        }
    }
}
</script>
<style lang="scss" scoped>
.forgot-password {
    background: $floral-white;
    min-height: 490px;
    width: 661px;

    @include breakpoint(black-bear) {
        width: 100%;
        min-height: 100%;
    }

    &__head {
        position: relative;
        display: flex;
        width: 100%;
        padding-top: 15px;
        color: $brand-blue;
        align-items: center;
    }

    &__back-btn {
        position: relative;
        left: 18px;
        height: 24px;
        transform: rotate(180deg);
        display: block;
        cursor: pointer;

        &:hover,
        &:focus {
            color: $brand-black;
        }
    }

    &__back-btn-icon {
        display: block;
        width: 18px;
        height: 24px;
        padding: 0 4px;
    }

    &__sent {
        font-size: 14px;
        line-height: 19px;
        color: $slate-03;
        position: absolute;
        text-align: center;
        width: 100%;
        left: 0;
        top: 18px;

        svg {
            width: 12px;
            height: 10px;
            margin-right: 5px;
        }
    }

    &__body {
        max-width: 533px;
        margin: 0 auto;
        text-align: center;
        padding: 34px 40px 0;
        box-sizing: border-box;
    }

    &__title {
        outline: none;
        font-size: 36px;
        line-height: 46px;
        font-weight: 700;
        margin-bottom: 14px;
    }

    &__subtitle {
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 30px;
        color: $slate-03;
    }

    &__input {
        text-align: left;
        max-width: 320px;
        width: 100%;
        margin: 0 auto;
    }

    &__errors {
        max-width: 320px;
        width: 100%;
        margin: 18px auto 0;
        text-align: left;
        box-sizing: border-box;
    }

    &__submit {
        margin-top: 24px;
    }
}
</style>