<template>
    <div v-dark class="subject-card">
        <div class="subject-card__left">
            <div v-if="name" class="subject-card__name">
                {{ name }}
            </div>
            <div v-dark class="subject-card__score">
                {{ correctCount || 0 }} of {{ totalQuestions || 0 }} Correct
            </div>
        </div>
        <div class="subject-card__right">
            <div 
                v-dark
                class="subject-card__percent-dot"
                :class="{ [`subject-card__percent-dot--${percentColor}`]: true }"
            />
            <div class="subject-card__percent">
                {{ percent || '--' }}%
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { getScoreRange } from '@/utils'
import { Vue, Component, Prop } from 'vue-facing-decorator'

@Component
export default class SubjectCard extends Vue {
    @Prop() name!: string
    @Prop() correctCount!: number
    @Prop() totalQuestions!: number

    get percent () {
        return Math.round((this.correctCount / this.totalQuestions) * 100)
    }

    get percentColor () {
        return isNaN(this.percent) 
            ? 'gray'
            : getScoreRange(this.percent) === 'low'
                ? 'red'
                : getScoreRange(this.percent) === 'medium'
                    ? 'yellow'
                    : 'green'
    }
}
</script>
<style lang="scss" scoped>
.subject-card {
    background: $white;
    box-shadow: 0 1px 6px 0 rgba($brand-black, 0.12);
    display: flex;
    padding: 10px 14px 11px;
    box-sizing: border-box;
    width: 335px;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    border-radius: 8px;

    @include breakpoint(black-bear) {
        max-width: 100%;
    }

    &--dark {
        background-color: rgba($brand-black, 0.6);
        box-shadow: 0 1px 6px 0 $charcoal;
    }

    &__name {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 3px;
        max-width: 250px;
    }

    &__score {
        font-size: 13px;
        line-height: 18px;
        color: $slate-01;

        &--dark {
            color: $pewter;
        }
    }

    &__right {
        display: flex;
        align-items: center;
    }

    &__percent-dot {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin-right: 11px;

        &--yellow {
            background: $cheddar;

            &--dark {
                background-color: $banana-bread;
            }
        }

        &--red {
            background: $tomato;

            &--dark {
                background-color: $brand-red;
            }
        }

        &--green {
            background: $field;

            &--dark {
                background: $jungle-green;
            }
        }

        &--gray {
            background: $slate-01;
        }
    }

    &__percent {
        font-size: 16px;
        letter-spacing: -0.2px;
        line-height: 22px;
        font-weight: 600;
    }
}
</style>