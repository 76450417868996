<template>
    <div>
        <ul class="user-menu-dropdown">
            <li
                v-dark
                role="img"
                :aria-label="fullName"
                class="user-menu-dropdown__item"
            >
                <div aria-hidden="true" class="user-menu-dropdown__top">
                    <div class="user-menu-dropdown__initial">
                        <span>{{ initial }}</span>
                    </div>
                    <div
                        v-if="fullName" 
                        v-dark
                        class="user-menu-dropdown__name"
                        :class="{ 'user-menu-dropdown__name--force-break': !stringHasSpaces(fullName) }"
                    >
                        {{ fullName }}
                    </div>
                </div>
            </li>
            <li v-dark class="user-menu-dropdown__item user-menu-dropdown__settings">
                <RouterLink
                    custom
                    :to="{ name: 'account' }"
                    v-slot="{ navigate }"
                    :key="String(isDarkMode)"
                >
                    <a
                        v-dark
                        tabindex="0"
                        ref="user-menu-item-1"
                        class="user-menu-dropdown__settings-link"
                        @click="emitClose() && navigate($event)"
                        @keydown.enter="emitClose() && navigate()"
                    >
                        Settings
                    </a>
                </RouterLink>
            </li>
            <li
                ref="user-menu-item-2"
                v-dark
                tabindex="0"
                class="user-menu-dropdown__item user-menu-dropdown__dark-mode"
                role="switch"
                :aria-checked="isDarkMode"
                @click="clickDarkMode"
                @keydown.enter="clickDarkMode"
                @mousedown.prevent
            >
                <div v-dark class="user-menu-dropdown__dark-mode-inner">
                    Dark Mode <span v-dark class="user-menu-dropdown__dark-mode-icon"><Icon type="moon" /></span>
                </div>
            </li>
            <li
                v-if="hasInstructorDashboardAccess"
                ref="user-menu-item-3"
                v-dark
                tabindex="0"
                class="user-menu-dropdown__item user-menu-dropdown__teach"
                role="link"
                @click="clickInstructorDashboard"
                @keydown.enter="clickInstructorDashboard"
                @mousedown.prevent
            >
                Go to Instructor Dashboard
            </li>
            <li
                ref="user-menu-item-4"
                v-dark
                tabindex="0"
                class="user-menu-dropdown__item user-menu-dropdown__sign-out"
                role="link"
                @click="clickSignOut"
                @keydown.enter="clickSignOut"
                @mousedown.prevent
            >
                Sign Out
            </li>
            <li
                v-if="!hasActiveSubscription && currentExamMetadata && breakpoint === 'brown-bear'"
                v-dark
                class="user-menu-dropdown__item user-menu-dropdown__upgrade"
            >
                <PocketButton
                    ref="user-menu-item-4"
                    :is-dark-mode="isDarkMode"
                    @click="emitClickShowUpgrade"
                >
                    Upgrade to Premium
                </PocketButton>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import { Vue, Component, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { subscriptionModule } from '@/store/subscription/module'
import { screenModule } from '@/store/screen/module'
import { instructorModule } from '@/store/instructor/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { setCookie } from '@/utils'
import { getSessionToken } from '@/store/parseUtils'
import type { ComponentPublicInstance } from 'vue'

@Component({
    components: {
        Icon: UIKit.Icon,
        PocketButton: UIKit.Button,
    },
})
export default class UserMenuDropdown extends Vue {
    focusRefIndex: number | null = null

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }
    
    get initial () {
        return userModule.getters.userInitial()
    }

    get fullName () {
        return userModule.getters.userFullName()
    }

    get user () {
        return userModule.state.user
    }

    get hasActiveSubscription () {
        return !!subscriptionModule.getters.getSubscriptionForExamId()
    }

    get hasInstructorDashboardAccess () {
        return instructorModule.getters.getCurrentUserOrgInstructors().length > 0
    }

    get nextRefIndex () {
        if (this.focusRefIndex === null || this.focusRefIndex === this.menuRefs.length - 1) {
            return 0
        }
        return this.focusRefIndex + 1
    }

    get previousRefIndex () {
        if (!this.focusRefIndex) {
            return this.menuRefs.length - 1
        }

        return this.focusRefIndex - 1
    }

    get menuRefs () {
        return Object.keys(this.$refs)
            .sort((a, b) => a.localeCompare(b))
            .map(key => {
                const refs = this.$refs as {
                    [key: string]: ComponentPublicInstance | (ComponentPublicInstance | Element)[]
                }
                const refKey = refs[key]
                return (refKey && '$el' in refKey)
                    ? (refs[key] as ComponentPublicInstance).$el
                    : refs[key]
            }) as HTMLElement[]
    }
    
    get currentExamMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    async mounted () {
        await Promise.all([
            userModule.actions.fetchUserData(),
            instructorModule.actions.fetchCurrentUserOrgInstructors(),
        ])

        // close user menu on `esc` press
        window.addEventListener('keydown', this.keydownListener, true)
    }

    beforeUnmount () {
        window.removeEventListener('keydown', this.keydownListener, true)
    }

    stringHasSpaces (val: string) {
        return val.includes(' ')
    }

    keydownListener (e: KeyboardEvent) {
        if (e.key === 'Escape') {
            e.stopPropagation()
            this.emitClose()
        }
        if (e.key === 'ArrowDown') {
            e.preventDefault()
            this.menuRefs[this.nextRefIndex]?.focus()
            this.focusRefIndex = this.nextRefIndex
        }
        if (e.key === 'ArrowUp') {
            e.preventDefault()
            this.menuRefs[this.previousRefIndex]?.focus()
            this.focusRefIndex = this.previousRefIndex
        }
    }

    clickDarkMode () {
        userModule.actions.toggleIsDarkMode()
    }

    clickSignOut () {
        this.emitClickDropdownOption('signOut')
    }

    clickInstructorDashboard () {
        // On web, we want to open Instructor Dashboard in the same tab
        const teachURL = import.meta.env.VUE_APP_TEACH_URL
        const sessionCookieName = import.meta.env.VUE_APP_SESSION_COOKIE_NAME
        const sessionToken = getSessionToken()
        if (teachURL && sessionToken && sessionCookieName) {
            setCookie({ name: sessionCookieName, value: sessionToken })
            window.location.href = teachURL
        }
    }

    @Emit('clickDropdownOption')
    emitClickDropdownOption (option: string) {
        return option
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('clickShowUpgrade')
    emitClickShowUpgrade () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.user-menu-dropdown {
    list-style: none;
    margin: 0;
    padding: 0;
    color: $brand-black;

    &__item {
        margin: 0;
        border-bottom: 1px solid $gray-divider;
        font-size: 14px;
        line-height: 17px;
        padding: 0 15px;
        min-height: 40px;
        display: flex;
        align-items: center;

        &--dark {
            border-bottom-color: rgba($fog, 0.25);
            color: $gray-background;
        }

        &:last-child {
            border-bottom: none;
            border-radius: 0 0 8px 8px;
        }
    }

    &__settings {
        padding: 0 !important;
    }

    &__settings-link {
        color: $brand-black;
        text-decoration: none;
        display: block;
        padding: 0 15px;
        height: 40px;
        line-height: 40px;
        width: 100%;

        &--dark {
            color: $gray-background;
        }
    }

    &__top {
        margin: 0 auto;
    }

    &__initial {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        color: $flat-brown;
        margin: 23px auto 12px;
        background: linear-gradient(216.65deg, $beach-sand 0%, $cream-puff 100%);
        line-height: 1px;
        font-size: 24px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }

    &__name {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 18px;
        color: #6d4705;

        &--force-break {
            word-break: break-all;
        }

        &--dark {
            color: $white;
        }
    }

    &__settings-link,
    &__dark-mode,
    &__teach,
    &__sign-out {
        cursor: pointer;
        outline: none;
        position: relative;

        &:hover {
            background-color: $gray-background;
        }

        &:focus::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            border: 1px solid $brand-blue;
            border-radius: 6px;
        }

        &--dark {
            &:hover {
                background-color: $brand-black;
            }

            &:focus::before {
                border-color: $banana-bread;
            }
        }
    }

    &__dark-mode-icon {
        display: inline-block;
        margin-left: 6px;
        background: rgba($brand-black, 0.12);
        border-radius: 4px;
        width: 23px;
        height: 23px;
        vertical-align: middle;

        &--dark {
            background: transparent;

            svg {
                color: $gray-background !important;
            }
        }

        svg {
            display: block;
            color: $brand-black;
            margin-left: 4px;
            margin-top: 3px;
        }
    }

    &__dark-mode-inner {
        height: 26px;
        position: relative;
        padding: 0 0 0 7px;
        line-height: 26px;
        z-index: 1;
        left: -7px;

        &--dark::before {
            content: '';
            position: absolute;
            background: $white;
            opacity: 0.17;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 6px;
        }
    }

    .user-menu-dropdown__upgrade {
        display: flex;
        justify-content: center;
        padding: 8px;
    }
}
</style>
