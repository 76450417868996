<script setup lang="ts">
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import UIKit from '@pocketprep/ui-kit'
import { computed, onMounted, ref } from 'vue'
import * as Sentry from '@sentry/browser'
import { bundleModule } from '@/store/bundle/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { subscriptionModule } from '@/store/subscription/module'
const {
    Calendar,
    ModalContainer,
    Modal,
    Button: PocketButton,
} = UIKit

const examDate = ref<Date | null>(null)
const hasExamDateLoaded = ref(false)
const isLoading = ref(true)

const emit = defineEmits<{
    close: []
    next: []
}>()

const userExamMetadata = computed(() => {
    return userExamMetadataModule.getters.getCurrentUserExamMetadata()
})

const hasExamDateChanged = computed(() => {
    const uem = userExamMetadata.value
    const existingExamDate = uem?.scheduledExamDate
    if (existingExamDate && examDate.value) {
        return new Date(existingExamDate.iso).getTime() !== examDate.value.getTime()
    } else if (!existingExamDate && !examDate.value) {
        return false
    } else {
        return true
    }
})

const bundle = computed(() => {
    return bundleModule.getters.getCurrentBundle()
})

const currentUserExamMetadata = computed(() => {
    return examMetadataModule.getters.getCurrentExamMetadata()
})

const isDarkMode = computed(() => {
    return userModule.state.settings.isDarkMode
})

const hasSubscription = computed(() => {
    return !!subscriptionModule.getters.getSubscriptionForExamId(userExamMetadata.value?.objectId)
})

const saveExamDate = async () => {
    if (!hasExamDateChanged.value) {
        emit('next')
        return
    }

    isLoading.value = true
    const date = examDate.value || undefined

    // if date is somehow not valid, capture date and send to Sentry
    if (date && isNaN(date.getTime())) {
        Sentry.captureException(new Error(`Invalid date: ${examDate.value}`))
    } else {
        if (userExamMetadata.value && bundle.value && currentUserExamMetadata.value) {
            await userExamMetadataModule.actions.setScheduledExamDate({
                scheduledExamDate: examDate.value || undefined,
                examNativeName: currentUserExamMetadata.value?.nativeAppName,
                uemCreatedAt: userExamMetadata.value?.createdAt,
                uemObjectId: userExamMetadata.value?.objectId,
                bundle: bundle.value,
                hasSubscription: hasSubscription.value,
            })
        }
    }

    isLoading.value = false
    emit('next')
}

onMounted (async () => {
    await userModule.actions.fetchUserData()
    const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    if (uem?.scheduledExamDate) {
        examDate.value = new Date(uem.scheduledExamDate.iso)
    }
    hasExamDateLoaded.value = true
    isLoading.value = false

    setTimeout(() => {
        const titleEl = document.querySelector('.exam-date__title') as HTMLElement | null
        titleEl?.focus()
    }, 0)
}
)

</script>

<template>
    <Portal to="modal">
        <ModalContainer
            key="readinessExamDateModal"
            class="readiness-exam-date"
            :is-dark-mode="isDarkMode"
            @close="emit('close')"
        >
            <template #modal>
                <Modal
                    :is-dark-mode="isDarkMode"
                    class="readiness-exam-date__modal"
                    :show-close-button="true"
                    @close="emit('close')"
                >
                    <div tabindex="-1" class="readiness-exam-date__title">
                        Exam Readiness
                    </div>
                    <div class="readiness-exam-date__subtitle" v-dark>
                        First things first, when do you plan on taking your exam?
                    </div>
                    <Calendar
                        v-if="hasExamDateLoaded"
                        v-model="examDate"
                        class="readiness-exam-date__calendar"
                        label="Scheduled Exam Date"
                        :is-dark-mode="isDarkMode"
                        @keydown.enter="!isLoading && examDate && saveExamDate()"
                    />
                    <div class="readiness-exam-date__buttons">
                        <PocketButton
                            :is-dark-mode="isDarkMode"
                            type="secondary"
                            @click="emit('close')"
                        >
                            Cancel
                        </PocketButton>
                        <PocketButton
                            :is-dark-mode="isDarkMode"
                            :is-loading="isLoading"
                            :disabled="!examDate"
                            @click="saveExamDate"
                        >
                            Next
                        </PocketButton>
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<style lang="scss" scoped>
.readiness-exam-date {
    color: $brand-black;
    z-index: 9998;

    &__modal {
        padding: 36px 52px 24px;
        width: 492px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 80px;
        max-width: 95%;
        box-sizing: border-box;

        @include breakpoint(black-bear) {
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__title {
        margin-bottom: 38px;
        font-size: 18px;
        letter-spacing: 0.3px;
        line-height: 25px;
        font-weight: 600;
        text-align: center;
        outline: none;
    }

    &__subtitle {
        color: $ash;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 32px;

        &--dark {
            color: $fog;
        }
    }

    &__calendar {
        margin-bottom: 71px;
    }

    &__buttons {
        display: flex;
        justify-content: center;
        gap: 8px;
    }
}
</style>
