<template>
    <div v-dark class="quiz-setting">
        <div 
            v-for="option in options" 
            :key="option.label"
            v-dark
            class="quiz-setting__option"
            :class="{ 'quiz-setting__option--selected': modelValue === option.value }"
            tabindex="0"
            :aria-labelledby="`quiz-setting__label-${option.label.replace(/[^\w!?]/g,'').toLowerCase()}`"
            :aria-checked="modelValue === option.value"
            role="checkbox"
            @click="emitUpdateModelValue(option.value)"
            @keydown.enter="emitUpdateModelValue(option.value)"
            @mousedown.prevent
        >
            <label
                :id="`quiz-setting__label-${option.label.replace(/[^\w!?]/g,'').toLowerCase()}`"
                class="quiz-setting__label"
            >
                {{ option.label }}
            </label>
            <Icon
                v-if="modelValue === option.value"
                v-dark
                type="check"
                class="quiz-setting__check"
            />
        </div>
    </div>
</template>

<script lang="ts">
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'

@Component({
    components: {
        Icon: UIKit.Icon,
    },
})
export default class QuizSetting extends Vue {
    @Prop() modelValue!: boolean
    @Prop() options!: { value: boolean; label: string }[]

    @Emit('update:modelValue')
    emitUpdateModelValue (modelVal: boolean) {
        return modelVal
    }
}
</script>
<style lang="scss" scoped>
.quiz-setting {
    background: $white;
    border: 1px solid $gray-divider;
    border-radius: 6px;
    width: 100%;

    &--dark {
        background: $brand-black;
        border-color: $brand-black;
    }

    &__option {
        font-size: 15px;
        line-height: 18px;
        color: $slate-01;
        padding: 14px 50px 14px 15px;
        border-bottom: 1px solid $gray-divider;
        cursor: pointer;
        user-select: none;
        position: relative;
        outline: none;

        &:focus::before {
            content: '';
            position: absolute;
            left: -1px;
            top: -1px;
            border: 1px solid $brand-blue;
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }

        &--dark {
            border-bottom-color: $ash;
            color: $pewter;

            &:focus::before {
                border-color: $banana-bread;
            }
        }

        &--selected {
            color: $brand-black;

            &--dark {
                color: $white;
            }
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    &__label {
        cursor: pointer;
    }

    &__check {
        color: $brand-blue;
        position: absolute;
        width: 16px;
        height: 13px;
        top: 19px;
        right: 20px;

        &--dark {
            color: $banana-bread;
        }
    }
}
</style>