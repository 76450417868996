import type { ExperimentClient } from '@amplitude/experiment-js-client'
import { sprig } from '@sprig-technologies/sprig-browser'


export interface IAnalyticsState {
    sprig: ReturnType<typeof sprig.configure> | null
    experiment: ExperimentClient | null
}

export const getInitialAnalyticsState = (): IAnalyticsState => {
    const Sprig = import.meta.env.VUE_APP_SPRIG_ENVIRONMENT_ID && sprig.configure({
        environmentId: import.meta.env.VUE_APP_SPRIG_ENVIRONMENT_ID,
    })

    return {
        sprig: Sprig || null,
        experiment: null,
    }
}

const moduleState = getInitialAnalyticsState()

export default moduleState
