<template>
    <div class="purchases">
        <SettingsHead />
        <UpgradeSidePanel
            v-if="showUpgradeSidePanel"
            key="purchases"
            :has-active-referral-link="validReferral"
            :referral-link-errors="[ referralError ]"
            @close="showUpgradeSidePanel = false"
        />
        <AddPaymentMethodSidePanel
            v-if="showAddPaymentMethodSidePanel"
            key="purchases"
            @close="showAddPaymentMethodSidePanel = false"
        />
        <EditSubscriptionSidePanel
            v-if="showEditSubscriptionSidePanel && subscriptionToEdit"
            :subscription="subscriptionToEdit"
            @close="showEditSubscriptionSidePanel = false"
        />
        <h2 v-dark class="purchases__label purchases__label--top">
            Premium Access
        </h2>
        <div
            v-if="!activeSubscriptions.length"
            v-dark
            class="purchases__no-subscriptions"
        >
            <img
                src="@/assets/settings/no-premium.png"
                alt=""
                class="purchases__no-subscriptions-image"
            >
            <div 
                v-dark 
                class="purchases__no-subscriptions-title"
                :class="{ 
                    'purchases__no-subscriptions-title--no-upgrade-to': 
                        (currentExamMetadata && bundle?.name === 'Finance') ||
                        (currentExamMetadata && currentExamMetadata.isFree)
                }"
            >
                You have no active Pocket Prep purchases.
            </div>
            <div
                v-if="
                    (currentExamMetadata && bundle?.name !== 'Finance') &&
                        (currentExamMetadata && !currentExamMetadata.isFree)
                " 
                v-dark 
                class="purchases__no-subscriptions-upgrade"

            >
                <PocketLink
                    :is-dark-mode="isDarkMode"
                    type="tertiary-small"
                    @click="openUpgradeSidePanel"
                >
                    Upgrade to Premium
                </PocketLink>
                to get the full Pocket Prep experience.
            </div>
            <img
                src="@/assets/settings/no-premium-mobile.png"
                alt=""
                class="purchases__no-subscriptions-image purchases__no-subscriptions-image--mobile"
            >
            <div 
                v-dark 
                class="purchases__no-subscriptions-help">
                Did you already pay for Premium and it’s not showing up here?
                <PocketLink
                    :is-dark-mode="isDarkMode"
                    class="purchases__no-subscriptions-help-link"
                    @click="showIntercom"
                >
                    Contact our support team.
                </PocketLink>
            </div>
        </div>
        <Table
            v-if="activeSubscriptions.length"
            class="purchases__subscriptions"
            theme="open"
            :show-header="false"
            :rows="activeSubscriptions"
            :columns="tableColumns"
            :is-dark-mode="isDarkMode"
            @sort="updateActiveSortSettings"
        >
            <template #tableCellValue="{ row, column }">
                <SubscriptionRow
                    :row="row"
                    :column="column"
                    @editSubscription="editSubscription"
                />
            </template>
        </Table>
        <div class="purchases__subscription-cards">
            <div
                v-for="sub in activeSubscriptions"
                :key="sub.id"
                v-dark
                class="purchases__subscription-card"
            >
                <div v-dark class="purchases__subscription-card-top">
                    <BundleIcon
                        :theme="isDarkMode ? 'silver' : 'color'"
                        :bundle-id="sub.bundleId"
                        class="purchases__subscription-card-icon"
                    />
                    <div class="purchases__subscription-card-top-right">
                        <div class="purchases__subscription-card-name">
                            {{ sub.name }}
                        </div>
                        <div v-dark class="purchases__subscription-card-name-subtext">
                            {{ sub.nameSubtext }}
                        </div>
                    </div>
                </div>
                <div v-dark class="purchases__subscription-card-info">
                    <div v-dark class="purchases__subscription-card-label">
                        Plan
                    </div>
                    <div class="purchases__subscription-card-title">
                        {{ sub.planName }}
                    </div>
                    <div v-dark class="purchases__subscription-card-subtext">
                        <template v-if="sub.planCost && !sub.expired">
                            {{ sub.planCost }} •
                        </template>
                        {{ sub.renewsExpired }}
                    </div>
                </div>
                <div
                    v-if="sub.paymentPlatform"
                    v-dark
                    class="purchases__subscription-card-info"
                    :class="{ 'purchases__subscription-card-info--no-edit': !sub.canEdit }"
                >
                    <div v-dark class="purchases__subscription-card-label">
                        Paid via
                    </div>
                    <div class="purchases__subscription-card-title">
                        {{ sub.paymentPlatform }}
                    </div>
                    <div v-dark class="purchases__subscription-card-subtext">
                        {{ sub.paymentSubtext }}
                    </div>
                </div>
                <PocketButton
                    v-if="sub.canEdit"
                    type="tertiary-small"
                    class="purchases__subscription-card-edit"
                    :is-dark-mode="isDarkMode"
                    @click="editSubscription(sub.id)"
                >
                    Edit Purchase
                </PocketButton>
            </div>
        </div>
        <template v-if="subscriptions.expired.length">
            <h2 class="purchases__label">
                Expired
            </h2>
            <Table
                class="purchases__subscriptions"
                theme="open"
                :show-header="false"
                :rows="expiredSubscriptions"
                :columns="tableColumns"
                :is-dark-mode="isDarkMode"
                @sort="updateExpiredSortSettings"
            >
                <template #tableCellValue="{ row, column }">
                    <SubscriptionRow
                        :row="row"
                        :column="column"
                        @editSubscription="editSubscription"
                    />
                </template>
            </Table>
        </template>
        <h2 v-dark class="purchases__label">
            Wallet
        </h2>
        <div v-if="!paymentMethods.length" class="purchases__no-cards">
            You have no saved credit/debit cards.
        </div>
        <div class="purchases__cards">
            <div
                v-for="pm in paymentMethods"
                :key="pm.id"
                v-dark
                class="purchases__card"
                :class="{ 'purchases__card--expired': pm.hasExpired }"
            >
                <div class="purchases__card-title">
                    Card ending in {{ pm.last4 }}
                </div>
                <div v-dark class="purchases__card-expiration">
                    {{ pm.hasExpired ? 'Expired' : 'Expires' }}
                    {{ pm.expiration }}
                </div>
                <PocketButton
                    type="tertiary-small"
                    class="purchases__card-delete"
                    :disabled="isLoading"
                    :is-dark-mode="isDarkMode"
                    @click="deleteCard(pm.id)"
                >
                    Delete
                </PocketButton>
            </div>
        </div>
        <PocketButton
            :is-dark-mode="isDarkMode"
            type="tertiary-small"
            @click="showAddPaymentMethodSidePanel = true"
        >
            + Add Card
        </PocketButton>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'
import UIKit, { type ITableSortSettings } from '@pocketprep/ui-kit'
import type { Study } from '@pocketprep/types'
import AddPaymentMethodSidePanel from '@/components/Settings/AddPaymentMethodSidePanel.vue'
import EditSubscriptionSidePanel from '@/components/Settings/EditSubscriptionSidePanel.vue'
import SettingsHead from '@/components/Settings/SettingsHead.vue'
import SubscriptionRow from '@/components/Settings/Purchases/SubscriptionRow.vue'
import UpgradeSidePanel from '@/components/Settings/UpgradeSidePanel.vue'
import { isSupportWindow, type TRow } from '@/utils'
import { subscriptionModule } from '@/store/subscription/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { bundleModule } from '@/store/bundle/module'
import { stripeModule } from '@/store/stripe/module'
import { userModule } from '@/store/user/module'
import { referralModule } from '@/store/referral/module'
import { toastModule } from '@/store/toast/module'

@Component({
    components: {
        AddPaymentMethodSidePanel,
        EditSubscriptionSidePanel,
        SettingsHead,
        PocketButton: UIKit.Button,
        PocketLink: UIKit.Link,
        Table: UIKit.Table,
        SubscriptionRow,
        BundleIcon: UIKit.BundleIcon,
        UpgradeSidePanel,
    },
})
export default class Purchases extends Vue {
    showAddPaymentMethodSidePanel = false
    showEditSubscriptionSidePanel = false
    showUpgradeSidePanel = false
    subscriptionToEdit: null | ReturnType<Study.Class.SubscriptionBundle['toJSON']> = null
    activeSortSettings: ITableSortSettings | null = null
    expiredSortSettings: ITableSortSettings | null = null
    isLoading = true
    referralLinkErrors: string[] = []
    tableColumns = [{
        name: 'Purchase',
        propName: 'purchase',
    }, {
        name: 'Plan',
        propName: 'plan',
    }, {
        name: 'Paid via',
        propName: 'paid',
        isSortDisabled: true,
    }, {
        name: 'Action',
        propName: 'action',
        isLabelHidden: true,
        isSortDisabled: true,
    }]

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }


    get paymentMethods () {
        return stripeModule.getters.getStripePaymentMethods().map(pm => {
            return {
                id: pm.id,
                last4: pm.card?.last4,
                expiration: `${pm.card?.exp_month}/${String(pm.card?.exp_year).substr(2, 2)}`,
                hasExpired: new Date(pm.card?.exp_year || 0, (pm.card?.exp_month || 0) + 1, 0)
                    .getTime() < new Date().getTime(),
            }
        })
    }

    get subscriptions () {
        return subscriptionModule.state.subscriptions.reduce<{ active: TRow[]; expired: TRow[] }>((acc, s) => {
            const row = {
                id: s.objectId,
                source: '',
                bundleId: '',
                name: '',
                nameSubtext: '',
                planName: '',
                planCost: '',
                renewsExpired: '',
                paymentPlatform: '',
                paymentSubtext: '',
                canEdit: false,
                expired: false,
            }
            if ('bundle' in s) {
                row.bundleId = s.bundle.objectId
                const bundle = bundleModule.getters.getBundles().find(b => b.objectId === row.bundleId)
                row.name = `${bundle?.name} Pocket Prep`
                row.nameSubtext = `Premium access to ${bundle?.exams.length} exam${
                    (bundle?.exams.length || 0) > 1 ? 's' : ''}`
            } else {
                const exam = examMetadataModule.getters.getMostRecentExamForExamGuid(s.examGuid)
                if (!exam) {
                    return acc
                }
                row.bundleId = bundleModule.getters.getBundles()
                    .find(b => b.exams.find(e => e.objectId === exam.objectId))?.objectId || ''
                row.name = exam.nativeAppName
                row.nameSubtext = 'Premium access to 1 exam'
            }

            if (!row.bundleId) {
                return acc
            }

            const receipt = s.receipt as Study.Class.ReceiptJSON
            row.source = receipt.source
            const expires = new Date(s.expiresAt.iso)
            row.renewsExpired = (
                expires.getTime() < new Date().getTime()
                    ? 'Expired '
                    : (s.willAutoRenew && (receipt.source !== 'Stripe' || this.paymentMethods.length))
                        ? 'Renews '
                        : 'Expires '
            ) + expires.toLocaleDateString()
            const expired = expires.getTime() < new Date().getTime()
            row.expired = expired
            if (receipt.source === 'Grandfather') {
                row.planName = 'Legacy Premium'
                row.renewsExpired = 'No expiration'
            } else if (receipt.source === 'Teach' || receipt.source === 'Bulk Purchase') {
                row.paymentPlatform = 'License Code'
                row.planName = 'Premium Prep'
            } else if (receipt.source === 'Stripe') {
                const sub = stripeModule.getters.getStripeSubscriptions().find(ss => ss.id === s.guid)
                if (!sub) {
                    if (row.name && expired) {
                        acc.expired.push(row)
                    }
                    return acc
                }
                if (sub) {
                    const plan = sub.items.data[0]?.plan
                    if (!plan) {
                        throw new Error('Purchases - subscriptions: no plan')
                    }
                    row.planName = 'Premium Prep'
                    row.planCost = stripeModule.getters.getPlanLabel({ p: plan, hidePeriodName: true })
                    const paymentMethod = stripeModule.getters.getStripePaymentMethods().find(
                        pm => pm.id === sub.default_payment_method
                    )
                    row.paymentPlatform = paymentMethod
                        ? `Card ending in ${paymentMethod.card?.last4}`
                        : 'Credit Card'
                    row.canEdit = true
                }


            } else if (receipt.source === 'Google') {
                row.planName = 'Premium Prep'

                // Checking to see if the receipt plan price amount is in one of two places
                // receipt.data.priceAmountMicros || needing access it through receipt.data.productId.split(0)
                if (receipt.data && receipt.data.priceAmountMicros) {
                    const googlePlanAmount = Number(receipt.data.priceAmountMicros)
                    row.planCost = googlePlanAmount ? `$${googlePlanAmount / 1000000}` : ''
                }
                if (receipt.data && !receipt.data.priceAmountMicros && receipt.data.productId) {
                    const productIdParts = receipt.data.productId.split(0)
                    const plan = productIdParts[productIdParts.length - 1]
                    row.planName = 'Premium Prep'
                    row.planCost = plan && Number(plan.priceAmountMicros) ? `$${plan.priceAmountMicros / 1000000}` : ''
                }

                row.paymentPlatform = 'Google Play'
                row.paymentSubtext = expired
                    ? 'Renew in Play Store'
                    : 'Edit in Play Store'
                row.canEdit = expired
            } else if (receipt.source === 'Apple') {
                /*
                    Apple now provides receipts with the productId nested in receipt.data.productId. We should check
                    for that structure first and default to old structure if new one is not present.
                */
                row.planName = 'Premium Prep'
                row.paymentPlatform = 'Apple Subscription'
                row.paymentSubtext = expired
                    ? 'Renew in App Store'
                    : 'Edit in App Store'
                row.canEdit = expired
            } else {
                row.planName = 'Premium Prep'
            }

            if (expired) {
                acc.expired.push(row)
            } else {
                acc.active.push(row)
            }
            return acc
        }, { active: [], expired: [] })
    }

    get expiredSubscriptions () {
        const sort = this.expiredSortSettings
        return this.subscriptions.expired.sort((a, b) => this.rowSort(a, b, sort))
    }

    get activeSubscriptions () {
        const sort = this.activeSortSettings
        return this.subscriptions.active.sort((a, b) => this.rowSort(a, b, sort))
    }

    get validReferral () {
        return referralModule.getters.getValidReferral()
    }

    get referralError () {
        return referralModule.getters.getReferralError()
    }

    get currentExamMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get examMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get examMetadataById () {
        return examMetadataModule.getters.getExamMetadataById()
    }

    get bundle () {
        return bundleModule.getters.getBundles()
            .find(b => b.exams.find(e => {
                const bundleExam = this.examMetadataById[e.objectId]
                return this.currentExamMetadata && bundleExam?.examGuid === this.currentExamMetadata.examGuid
            }))
    }

    async mounted () {
        await referralModule.actions.validateReferral()

        await Promise.all([
            stripeModule.actions.fetchStripePlans(),
            stripeModule.actions.fetchPaymentMethods(),
            stripeModule.actions.fetchStripeSubscriptions(),
            bundleModule.actions.fetchBundles(),
            examMetadataModule.actions.fetchExamMetadata(),
        ])

        this.isLoading = false
    }

    rowSort (a: TRow, b: TRow, sort: ITableSortSettings | null) {
        if (sort?.column?.name === 'Purchase') {
            return sort.direction * a.name.localeCompare(b.name)
        }
        if (sort?.column?.name === 'Plan') {
            return sort.direction * a.planName.localeCompare(b.planName)
        }

        return 0
    }

    async deleteCard (pmId: string) {
        this.isLoading = true
        await stripeModule.actions.deleteStripePM(pmId)
        toastModule.actions.displayToast({
            title: 'Card deleted successfully',
        })
        this.isLoading = false
    }

    editSubscription (subscriptionId: string) {
        const subscription = subscriptionModule.state.subscriptions
            .find(s => s.objectId === subscriptionId && 'bundle' in s)

        if (subscription) {
            this.showEditSubscriptionSidePanel = true
            this.subscriptionToEdit = subscription as ReturnType<Study.Class.SubscriptionBundle['toJSON']>
        }
    }

    openUpgradeSidePanel () {
        this.showUpgradeSidePanel = true
    }

    updateActiveSortSettings (sortSettings: ITableSortSettings) {
        this.activeSortSettings = sortSettings
    }

    updateExpiredSortSettings (sortSettings: ITableSortSettings) {
        this.expiredSortSettings = sortSettings
    }

    showIntercom () {
        if (import.meta.env.VUE_APP_INTERCOM_APP_ID && !isSupportWindow()) {
            window.Intercom('show')
        }
    }
}
</script>

<style lang="scss" scoped>
.purchases {
    font-size: 16px;

    &__label {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        margin: 0 0 12px;

        &--dark {
            color: rgba($white, 0.86);
        }

        &--top {
            margin-top: 24px;
        }
    }

    &__no-cards {
        margin-bottom: 18px;
        font-size: 14px;
        line-height: 17px;
    }

    &__no-subscriptions {
        max-width: 788px;
        width: 100%;
        border-radius: 6px;
        padding: 34px 0 33px;
        background: $gray-background;
        text-align: center;
        margin-bottom: 87px;
        position: relative;
        overflow: hidden;

        &--dark {
            background-color: $charcoal;
        }

        @include breakpoint(grizzly-bear) {
            max-width: 730px;
        }

        @include breakpoint(brown-bear) {
            padding: 30px 0 20px;
        }

        @include breakpoint(black-bear) {
            padding: 32px 8px;
            box-sizing: border-box;
        }
    }

    &__no-subscriptions-image {
        position: absolute;
        left: 0;
        top: 0;
        height: 164px;

        @include breakpoint(grizzly-bear) {
            height: 181px;
        }

        @include breakpoint(black-bear) {
            display: none;
        }

        &--mobile {
            display: none;

            @include breakpoint(black-bear) {
                display: block;
                position: relative;
                margin: 0 auto 30px;
                left: auto;
                top: auto;
            }
        }
    }

    &__no-subscriptions-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        margin-bottom: 4px;
        position: relative;
        z-index: 1;

        &--dark {
            color: $white;
        }

        @include breakpoint(grizzly-bear) {
            max-width: 360px;
            margin: 0 auto 4px;
        }

        &--no-upgrade-to {
            margin-bottom: 18px;
        }
    }

    &__no-subscriptions-upgrade {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 18px;
        color: $slate-03;
        position: relative;
        z-index: 1;

        &--dark {
            color: $fog;
        }

        @include breakpoint(grizzly-bear) {
            max-width: 360px;
            margin: 0 auto 18px;
        }

        @include breakpoint(black-bear) {
            max-width: none;
            margin-bottom: 22px;
        }
    }

    &__no-subscriptions-help {
        font-size: 13px;
        line-height: 18px;
        color: $slate-01;
        max-width: 375px;
        margin: 0 auto;
        position: relative;
        z-index: 1;

        &--dark {
            color: $fog;
        }

        @include breakpoint(grizzly-bear) {
            max-width: 360px;
        }

        @include breakpoint(black-bear) {
            max-width: none;
        }

        a {
            font-size: 13px;
            line-height: 18px;
            margin: 0 auto;

            @include breakpoint(grizzly-bear) {
                display: inline;
                font-weight: 500;
            }
        }
    }

    &__subscriptions {
        margin-left: -12px;
        margin-right: -12px;
        max-width: 789px;
        margin-bottom: 48px;

        @include breakpoint(brown-bear) {
            max-width: 706px;
        }

        @include breakpoint(black-bear) {
            display: none;
        }

        :deep(.uikit-table__row) {
            padding-left: 0;
        }

        :deep(.uikit-table__column-label),
        :deep(.uikit-table__cell) {
            &:nth-child(1) {
                width: 289px;

                @include breakpoint(grizzly-bear) {
                    width: 269px;
                }

                @include breakpoint(brown-bear) {
                    width: 270px;
                }
            }

            &:nth-child(2) {
                width: 244px;

                @include breakpoint(grizzly-bear) {
                    width: 219px;
                }

                @include breakpoint(brown-bear) {
                    width: 213px;
                }
            }

            &:nth-child(3) {
                width: 176px;

                @include breakpoint(grizzly-bear) {
                    width: 159px;
                }

                @include breakpoint(brown-bear) {
                    width: 142px;
                }
            }

            &:nth-child(4) {
                width: 26px;
                margin-right: -12px;
            }
        }

        :deep(.uikit-table__column-label:first-child) {
            margin-right: 0;
        }
    }

    &__cards {
        margin-bottom: 20px;
        margin-left: -9px;
    }

    &__card {
        position: relative;
        width: 320px;
        height: 74px;
        border-radius: 6px;
        margin-bottom: 12px;
        padding: 16px 0 0 20px;
        box-sizing: border-box;
        border: 1px solid $fog;
        background: $white;

        &--dark {
            background: $moonlit-ocean;
            border-color: $moonlit-ocean;
            box-shadow: 0 1px 6px 0 rgba($charcoal, 0.3);
        }

        &--expired {
            border: 1px solid rgba($brand-red, 0.5);
            background: $red-hint;
            box-shadow: none;

            &--dark {
                background: rgba($brand-red, 0.3);
                border-color: rgba($rosa, 0.5);
            }
        }
    }

    &__card-title {
        font-size: 15px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    &__card-expiration {
        font-size: 13px;
        line-height: 18px;
        color: $slate-01;

        &--dark {
            color: $fog;
        }
    }

    &__card-delete {
        position: absolute;
        right: 24px;
        top: 17px;
    }

    &__subscription-cards {
        display: none;
        margin-left: -12px;
        margin-right: -12px;
        margin-bottom: 48px;

        @include breakpoint(black-bear) {
            display: block;
        }
    }

    &__subscription-card {
        width: 381px;
        max-width: 100%;
        border-radius: 6px;
        padding: 16px 0 0;
        box-shadow: 0 1px 5px 0 rgba($brand-black, 0.2);
        margin-bottom: 12px;
        box-sizing: border-box;
        background: $white;

        @include breakpoint(black-bear) {
            border: 1px solid $fog;
        }

        &--dark {
            background: $brand-black;
            box-shadow: 0 1px 6px 0 rgba($jet, 0.3);
            border-color: $brand-black;
        }
    }

    &__subscription-card-top {
        display: flex;
        align-content: center;
        margin-bottom: 12px;
        border-bottom: 1px solid $fog;
        padding: 0 12px;

        &--dark {
            border-bottom-color: $pickled-bluewood;
        }
    }

    &__subscription-card-icon {
        width: 45px;
        height: 45px;
        margin-right: 8px;
        margin-top: -7px;
        margin-left: -6px;
    }

    &__subscription-card-name {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
    }

    &__subscription-card-name-subtext {
        font-size: 14px;
        line-height: 18px;
        color: $slate-01;
        padding-bottom: 12px;

        &--dark {
            color: $pewter;
        }
    }

    &__subscription-card-info {
        margin-bottom: 12px;
        padding: 0 12px 12px;
        border-bottom: 1px solid $fog;

        &--dark {
            border-bottom-color: $pickled-bluewood;
        }

        &:last-child {
            border-bottom: 0;
        }

        &--no-edit {
            margin-bottom: 0;
        }
    }

    &__subscription-card-label {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.25px;
        margin-bottom: 5px;
        color: $slate-03;

        &--dark {
            color: $fog;
        }
    }

    &__subscription-card-title {
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 6px;
    }

    &__subscription-card-subtext {
        font-size: 14px;
        line-height: 18px;
        color: $slate-01;

        &--dark {
            color: $pewter;
        }
    }

    &__subscription-card-edit {
        padding: 0 12px 10px;
        display: block;
    }
}
</style>