import { initLoadable } from '@/store/utils'
import type { Loadable } from '@/store/utils'
import type { Study } from '@pocketprep/types'

export type TQuestionState = {
    serialQuestionInfoLib: Loadable<ReturnType<Study.Cloud.fetchSerialQuestionInfoLibV2>>
}

export const getInitialQuestionState = (): TQuestionState => {
    return {
        serialQuestionInfoLib: initLoadable({}),
    }
}

const moduleState = getInitialQuestionState()

export default moduleState
