<template>
    <Portal to="modal">
        <ModalContainer key="UtiDiscountPageModal">
            <template #modal>
                <Modal class="uti-discount-page__modal">
                    <div class="uti-discount-page__head">
                        <UtiHeader
                            :current-step="currentStep"
                            @back="goBack"
                        />
                    </div>
                    <div class="uti-discount-page">
                        <div
                            class="uti-discount-page__step"
                            aria-live="polite"
                        >
                            Step {{ currentStep }} of 2
                        </div>
                        <h1
                            class="uti-discount-page__title"
                            tabindex="-1"
                            role="heading"
                        >
                            <template v-if="currentStep === 1">
                                Account Details
                            </template>
                            <template v-else-if="currentStep === 2">
                                Payment Details
                            </template>
                        </h1>
                        <UtiAccountDetails
                            v-if="currentStep === 1"
                            :exam-guids="examGuids"
                            :org-id-name-lib="orgIdNameLib"
                            @submit="submitAccountDetails"
                        />
                        <UtiPaymentDetails
                            v-if="currentStep === 2"
                            :exam-guids="examGuids"
                            :stripe-price="stripePrice"
                            @submit="submitPaymentDetails"
                        />
                        <Errors
                            v-if="!isLoading && errorMessage"
                            class="uti-discount-page__error-message"
                            :errors="[ errorMessage ]"
                        />
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import { isSupportWindow, isValidEmail } from '@/utils'
import UtiHeader from '@/components/UTI/UtiHeader.vue'
import UtiAccountDetails from '@/components/UTI/UtiAccountDetails.vue'
import UtiPaymentDetails from '@/components/UTI/UtiPaymentDetails.vue'
import { subscriptionModule } from '@/store/subscription/module'


@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
        UtiHeader,
        UtiAccountDetails,
        UtiPaymentDetails,
        PocketInput: UIKit.Input,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
        PocketLink: UIKit.Link,
        PocketSelect: UIKit.Select,
        Checkbox: UIKit.Checkbox,
        Errors: UIKit.Errors,
    },
})
export default class UtiDiscountPage extends Vue {
    currentStep = 1
    firstName = ''
    lastName = ''
    email = ''
    password = ''
    createPassword = false
    showPasswordSignIn = false
    isLoading = false
    isLoadingPayment = false
    errorMessage = ''
    emailError = false
    passwordError = false
    showUserDeletedModal = false
    agreed = false
    agreeDisabled = false
    errorFields = []
    examGuids: string[] | null = null
    orgIdNameLib: { [orgId: string]: string } | null = null
    stripePrice: {
        id: string
        object: 'price'
        created: number  
        nickname: string | null
        unit_amount: number | null
        unit_amount_decimal: string | null
    } | null = null
    isValidEmail = isValidEmail

    async mounted () {
        const routeStep = this.$route.query.step
        if (routeStep === 'account') {
            this.currentStep = 1
        } else if (routeStep === 'payment') {
            this.currentStep = 2
        }

        // Fetch UTI-specific discount data
        const utiConfig = await subscriptionModule.actions.fetchUTIConfig()
        this.examGuids = utiConfig.examGuids
        this.orgIdNameLib = utiConfig.orgIdNameLib
        this.stripePrice = utiConfig.stripePrice

        const registeredUser = userModule.state.user
        this.firstName = registeredUser?.firstName || ''
        this.lastName = registeredUser?.lastName || ''
        this.email = registeredUser?.email || ''
        this.agreed = !!registeredUser
        this.agreeDisabled = !!registeredUser
        // this.password = this.savedPassword
        this.createPassword = !!this.password
    }

    showIntercom () {
        if (import.meta.env.VUE_APP_INTERCOM_APP_ID && !isSupportWindow()) {
            window.Intercom('show')
        }
    }

    async validateForm () {
        this.emailError = false
        this.passwordError = false
        this.errorMessage = ''

        await new Promise(res => setTimeout(res, 200))

        if (!this.isValidEmail(this.email)) {
            this.errorMessage = 'Please enter a valid email address.'
            this.emailError = true
            return false
        }
        if (this.showPasswordSignIn && this.password.length < 6) {
            this.errorMessage = 'Passwords must be at least 6 characters.'
            this.passwordError = true
            return false
        }

        return true
    }

    updateRouteStep (stepName: string) {
        if (this.$route.query.step !== stepName) {
            this.$router.replace({
                name: 'uti-discount',
                query: {
                    ...this.$route.query,
                    step: stepName,
                },
            })
        }

        this.focusTitle()
    }


    focusTitle () {
        setTimeout(() => {
            const titleEl = document.querySelector('.uti-discount-page__title') as HTMLElement
            titleEl?.focus()
        }, 1)
    }

    scrollTop () {
        const registerEl = document.querySelector('.uti-discount-page')
        registerEl?.scrollTo(0, 0)
    }

    submitAccountDetails () {
        this.currentStep = 2
        this.updateRouteStep('payment')

        this.scrollTop()
    }

    async submitPaymentDetails (successfulTransaction: boolean) {
        if (this.isLoadingPayment) {
            return
        }

        this.isLoadingPayment = true

        // make sure confirm and activate payment was successful
        if (successfulTransaction) {
            await this.$router.push({ name: 'study' })
        } else {
            // if it was not a successful payment
            this.isLoadingPayment = false
            throw new Error('Unable to process payment. Please try again.')
        }

        this.isLoadingPayment = false
    }

    goBack () {
        this.currentStep = 1
        this.updateRouteStep('account')
        this.focusTitle()
    }
}
</script>

<style lang="scss" scoped>
.uti-discount-page {
    width: 946px;
    background: rgba($gray-background, 0.4);
    min-height: 640px;
    height: calc(100% - 56px);
    padding-bottom: 30px;
    box-sizing: border-box;
    overflow: auto;
    border-radius: 0 0 12px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;

    @include breakpoint(brown-bear) {
        width: 740px;
        height: calc(100vh - 140px) !important;
    }

    @include breakpoint(black-bear) {
        max-height: none;
        height: calc(100% - 56px) !important;
        width: 100%;
        min-height: 0;
    }

    &__modal {
        position: absolute;
        top: 72px;
        left: 50%;
        transform: translate(-50%);
        overflow: hidden;
        height: calc(86vh);

        @include breakpoint(brown-bear) {
            top: 69px;
        }

        @include breakpoint(black-bear) {
            height: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transform: none;
            margin: 0;
            border-radius: 0;
            box-shadow: none;
            overflow: auto;
        }
    }

    &__head {
        position: relative;
        width: 100%;
        height: 56px;
        display: flex;
        padding: 0 18px;
        box-sizing: border-box;
        align-items: center;

        @include breakpoint(brown-bear) {
            padding: 0 16px;
        }
    }

    &__existing-account {
        display: none;

        @include breakpoint(brown-bear) {
            display: block;
            margin: 12px 0 5px;
            text-align: center;
        }
    }

    &__step {
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        color: $slate-03;
        padding-top: 23px;
        margin-bottom: 10px;

        &--referral {
            padding-top: 40px;
        }

        @include breakpoint (black-bear) {
            padding-top: 21px;
        }
    }

    &__title {
        font-size: 26px;
        outline: none;
        line-height: 36px;
        font-weight: 700;
        text-align: center;
        margin: 0;
        padding: 0 25px;

        @include breakpoint(black-bear) {
            max-width: 323px;
        }
    }
}
</style>
