<!-- eslint-disable -->
<template>
    <svg width="96" height="35" viewBox="0 0 96 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M67.0869 34.3362C49.2738 35.802 6.84403 34.844 3.38036 31.4237C-0.0832991 28.4917 -2.06236 21.1627 3.38054 10.902C8.82344 0.641293 30.5949 5.52543 58.799 1.12799C87.0031 -3.26944 98.8785 5.52727 95.4148 21.6505C93.2288 31.8262 84.9 32.8704 67.0869 34.3362Z" fill="white"/>
    </svg>
</template>
<!-- eslint-disable -->
 
<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class HighlightBlob extends Vue {

}
</script>