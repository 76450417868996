import { bundleModule } from '@/store/bundle/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { isPromise } from '@/store/utils'
import type { Study } from '@pocketprep/types'

const getBundles = () => {
    const bundles = bundleModule.state.bundles.value
    if (isPromise(bundles)) {
        return []
    } else {
        return bundles
    }
}

const getBundleUniqueExams = (bundleId: string) => {
    const examMetadata = examMetadataModule.getters.getExamMetadataById()
    const bundle = getBundles().find(b => b.objectId === bundleId)

    if (!bundle) {
        return []
    }

    const mappedExams = bundle.exams.map(e => examMetadata[e.objectId])
    const uniqueExams = mappedExams.reduce<{ [examGuid: string]: Study.Class.ExamMetadataJSON }>((acc, e) => {
        if (!e || e.examGuid in acc) {
            return acc
        }

        acc[e.examGuid] = examMetadataModule.getters.getMostRecentExamForExamGuid(e.examGuid)
        return acc
    }, {})

    return Object.values(uniqueExams)
}

const getCurrentBundle = () => {
    const examMetadata = examMetadataModule.getters.getExamMetadataById()
    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()
    if (!currentExam) {
        return null
    }
    return getBundles().find(b => b.exams.find(e => { 
        const exam = examMetadata[e.objectId]
        return exam?.examGuid === currentExam.examGuid
    }))
}

const getBundleBrandLib = () => {
    return {
        'VEcpw3DhnA': { // AMTA
            appClipBundleId: 'com.tesolutions.amta.Clip',
            appStoreId: '1040585600',
            appStoreURL: 'https://apple.co/3ddZkCn',
            color: '#1F3545',
            universalLinkName: 'amta',
            playStoreURL: 'https://bit.ly/3dYDh2X',
        },
        '4dTgUX97Dk': { // Nursing
            appClipBundleId: 'com.pocketprep.app.nursing.Clip',
            appStoreId: '1501741725',
            appStoreURL: 'https://apple.co/3lhnHBW',
            color: '#FF6363',
            universalLinkName: 'nursing',
            playStoreURL: 'https://bit.ly/3dYDh2X',
        },
        'gAlCkoUVLl': { // EMS
            appClipBundleId: 'com.pocketprep.app.ems.Clip',
            appStoreId: '1503584128',
            appStoreURL: 'https://apple.co/3d73Rq1',
            color: '#FF6363',
            universalLinkName: 'ems',
            playStoreURL: 'https://bit.ly/3dYDh2X',
        },
        'mO5EIsoZ9H': { // Automotive
            appClipBundleId: 'com.pocketprep.app.automotive.Clip',
            appStoreId: '1503583993',
            appStoreURL: 'https://apple.co/36JI3Qe',
            color: '#FF6363',
            universalLinkName: 'automotive',
            playStoreURL: 'https://bit.ly/3dYDh2X',
        },
        '1kS20KPPkH': { // IT & Cybersecurity
            appClipBundleId: 'com.pocketprep.app.itcybersecurity.Clip',
            appStoreId: '1501744813',
            appStoreURL: 'https://apple.co/33BLWF6',
            color: '#00b584',
            universalLinkName: 'itcybersecurity',
            playStoreURL: 'https://bit.ly/3dYDh2X',
        },
        'GVCNrq4ixh': { // Finance
            appClipBundleId: 'com.pocketprep.app.finance.Clip',
            appStoreId: '1503584362',
            appStoreURL: 'https://apple.co/34ChUAp',
            color: '#00b584',
            universalLinkName: 'finance',
            playStoreURL: 'https://bit.ly/3dYDh2X',
        },
        'k9T5L3kC0U': { // Professional
            appClipBundleId: 'com.pocketprep.app.professional.Clip',
            appStoreId: '1503584541',
            appStoreURL: 'https://apple.co/30GoSTH',
            color: '#0064ff',
            universalLinkName: 'professional',
            playStoreURL: 'https://bit.ly/3dYDh2X',
        },
        'PusoOu7qGQ': { // Fitness
            appClipBundleId: 'com.pocketprep.app.personaltraining.Clip',
            appStoreId: '1461475433',
            appStoreURL: 'https://apple.co/2F5Tne3',
            color: '#0064ff',
            universalLinkName: 'fitness',
            playStoreURL: 'https://bit.ly/3dYDh2X',
        },
        'tVMt7KCrkQ': { // Medical
            appClipBundleId: 'com.pocketprep.app.medical.Clip',
            appStoreId: '1476171010',
            appStoreURL: 'https://apple.co/30BsJRJ',
            color: '#0064ff',
            universalLinkName: 'medical',
            playStoreURL: 'https://bit.ly/3dYDh2X',
        },
        'DO5jBm0OJo': { // Essentials
            appClipBundleId: 'com.pocketprep.app.essentials.Clip',
            appStoreId: '1629129056',
            appStoreURL: 'https://apple.co/3PlKax0',
            color: '#0064ff',
            universalLinkName: 'essentials',
            playStoreURL: 'https://bit.ly/3IWisVl',
        },
        '9970muCFGV': { // Nursing School
            appClipBundleId: 'com.pocketprep.app.nursingschool.Clip',
            appStoreId: '1501744528',
            appStoreURL: 'https://apple.co/3d5UgQD',
            color: '#C238F3',
            universalLinkName: 'nursingschool',
            playStoreURL: 'https://bit.ly/3dYDh2X',
        },
        'uKLwvbsbPV': { // Behavioral Health
            appClipBundleId: 'com.pocketprep.app.behavioralhealth.Clip',
            appStoreId: '1503584120',
            appStoreURL: 'https://apple.co/3nnMJkL',
            color: '#C238F3',
            universalLinkName: 'behavioralhealth',
            playStoreURL: 'https://bit.ly/3dYDh2X',
        },
    } as {
        [bundleId: string]: {
            appClipBundleId: string
            appStoreId: string
            appStoreURL: string
            color: string
            universalLinkName: string
            playStoreURL: string
        }
    }
}

export default {
    getBundles,
    getBundleUniqueExams,
    getCurrentBundle,
    getBundleBrandLib,
}
