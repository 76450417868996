<template>
    <Portal to="modal">
        <ModalContainer
            key="levelUpResetModal"
            :show-close-button="true"
            :is-dark-mode="isDarkMode"
            @close="emitClose"
        >
            <template #modal>
                <Modal
                    :show-close-button="true"
                    class="level-up-reset-modal__modal"
                    :is-dark-mode="isDarkMode"
                    @close="emitClose"
                >
                    <div class="level-up-reset-modal">
                        <h1
                            ref="level-up-reset-modal__title"
                            class="level-up-reset-modal__title"
                            tabindex="-1"
                        >
                            Reset Progress?
                        </h1>
                        <div v-dark class="level-up-reset-modal__text">
                            You will start at Level 1 again for all of your subjects if you reset your progress.
                        </div>
                        <div class="level-up-reset-modal__actions">
                            <PocketButton
                                type="secondary"
                                class="level-up-reset-modal__cancel-btn"
                                :is-dark-mode="isDarkMode"
                                @click="emitClose"
                            >
                                Cancel
                            </PocketButton>
                            <PocketButton
                                class="level-up-reset-modal__reset-btn"
                                :is-dark-mode="isDarkMode"
                                @click="emitReset"
                            >
                                Reset Progress
                            </PocketButton>
                        </div>
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'

@Component({
    components: {
        PocketButton: UIKit.Button,
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
    },
})
export default class LevelUpResetModal extends Vue {
    @Prop({ default: false }) isResetLoading!: boolean

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    mounted () {
        this.$nextTick(() => {
            const titleEl = this.$refs['level-up-reset-modal__title'] as HTMLElement | undefined
            titleEl?.focus()
        })
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('reset')
    emitReset () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.level-up-reset-modal {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 41px 26px 38px;
    width: 384px;
    margin: 0 auto;

    @include breakpoint(black-bear) {
        max-width: 343px;
        padding: 35px 24px 26px 24px;
    }

    &__modal {
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);

        @include breakpoint(black-bear) {
            top: 132px;
            max-width: 343px;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin: 0 0 12px 0;
        outline: none;
    }

    &__text {
        color: $slate-03;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 29px;

        &--dark {
            color: $white;
        }
    }

    &__cancel-btn {
        margin-right: 8px;
    }
}

:deep(.uikit-modal__close) {
    width: 20px;
    height: 20px;
}
</style>
