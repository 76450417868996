<template>
    <Portal to="modal">
        <ModalContainer
            key="submitQuizModal"
            :show-close-button="true"
            :is-dark-mode="isDarkMode"
            @close="emitClose"
        >
            <div class="submit-quiz-modal">
                <h1
                    ref="submit-quiz-modal__title"
                    class="submit-quiz-modal__title"
                    tabindex="-1"
                >
                    Submit Quiz?
                </h1>
                <div v-dark class="submit-quiz-modal__text">
                    You've answered {{ numAnswers }} of {{ quizLength }} question{{
                        quizLength !== 1 ? 's' : ''
                    }}. If you submit now, you'll only be scored on the {{ numAnswers }} question{{
                        numAnswers !== 1 ? 's' : ''
                    }} you've answered.
                </div>
                <div class="submit-quiz-modal__actions">
                    <PocketButton
                        type="secondary"
                        class="submit-quiz-modal__continue-btn"
                        :is-dark-mode="isDarkMode"
                        @click="emitContinue"
                    >
                        Continue Quiz
                    </PocketButton>
                    <PocketButton
                        class="submit-quiz-modal__submit-btn"
                        :is-dark-mode="isDarkMode"
                        @click="emitSubmit"
                    >
                        Submit Quiz
                    </PocketButton>
                </div>
            </div>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'

@Component({
    components: {
        PocketButton: UIKit.Button,
        ModalContainer: UIKit.ModalContainer,
    },
})
export default class SubmitQuizModal extends Vue {
    @Prop({ default: 0 }) numAnswers!: number
    @Prop({ default: 1 }) quizLength!: number

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    mounted () {
        this.$nextTick(() => {
            const titleEl = this.$refs['submit-quiz-modal__title'] as HTMLElement | undefined
            titleEl?.focus()
        })
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('continue')
    emitContinue () {
        return true
    }

    @Emit('submit')
    emitSubmit () {
        return true
    }
}
</script>

<style lang="scss" scoped>
// @import '@pocketprep/ui-kit/styles/_colors';

.submit-quiz-modal {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 42px 26px 39px;
    width: 384px;

    &__title {
        outline: none;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin: 0 0 12px 0;
    }

    &__text {
        color: $slate-03;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 29px;

        &--dark {
            color: $white;
        }
    }

    &__continue-btn {
        margin-right: 4px;
    }
}

</style>