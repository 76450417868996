<template>
    <div v-dark class="blob">
        <!-- eslint-disable -->
        <svg width="236px" height="114px" viewBox="0 0 236 114" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title></title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(124.400915, 66.654255) scale(-1, 1) translate(-124.400915, -66.654255) translate(12.799836, 19.308510)">
                    <path d="M95.3286518,36.9422387 C125.921223,61.7207434 154.404285,76.9183665 174.974643,94.6910878 L0.00199523778,94.6914899 L1.31450406e-13,1.74860126e-15 C30.1469555,0.135548167 63.1503855,10.8793974 95.3286518,36.9422387 Z" fill="currentColor"></path>
                    <path d="M34.6744732,71.8811144 C139.840794,25.2501959 151.407432,8.00579871 208.110481,75.1848352 C213.783505,81.90596 218.800772,88.4094755 223.20216,94.6913091 L4.66320354,94.6870751 C10.6922835,85.9403869 20.4343995,78.1951864 34.6744732,71.8811144 Z" fill="url(#halftone-blue)"></path>
                </g>
            </g>
        </svg>
        <!-- eslint-enable -->
    </div>
</template>

<style lang="scss" scoped>
// @import '@pocketprep/ui-kit/styles/_colors';

.blob {
    color: $barely-blue;

    &--dark {
        color: rgba($brand-blue, 0.12);
    }

    svg {
        display: block;
    }
}
</style>