import { screenModule } from '@/store/screen/module'

export type Loadable<T> = {
    isLoading: boolean
    lastFetched: null | Date
    value: T | Promise<T>
}

export const initLoadable = <T>(value: T): Loadable<T> => ({
    isLoading: false,
    lastFetched: null,
    value,
})

export const resetLoadable = <T, L extends Loadable<T>>(loadable: L) => {
    loadable.lastFetched = null
}

export const fetchLoadable = async <T>(
    loadable: Loadable<T>,
    asyncFunc: () => Promise<T>,
    forceFetch?: boolean
): Promise<T> => {
    if (
        !forceFetch
        && (
            loadable.isLoading
            || loadable.lastFetched
        )
    ) {
        return loadable.value
    }

    const promise = asyncFunc()
    loadable.isLoading = true
    if (!forceFetch) {
        loadable.value = promise
    }

    const result = await promise
    // Only store the final result if isLoading === true
    // This lets us "cancel" in-flight requests by setting isLoading = false
    if (loadable?.isLoading) {
        loadable.isLoading = false
        loadable.lastFetched = new Date()
        loadable.value = result
    }
    return result
}

// TypeScript uses 'instanceof Promise' to narrow Promises, but not all PromiseLike types
// This is a helper function to also narrow PromiseLike types in our context
export const isPromise = (val: unknown): val is PromiseLike<unknown> => {
    return val instanceof Promise
}

// All links with UTM parameters should include the same utm_source and utm_medium values
export const buildBaseUTMParamObj = () => {
    const isMobile = screenModule.getters.getBreakpoint() === 'black-bear'
    const utm_source = isMobile ? 'mobile_web' : 'web'
    const utm_medium = 'study_app'

    return {
        utm_source,
        utm_medium,
    }
}

// Useful if adding UTM parameters to vue-router links
// Note: depending on how we decide to use UTM params in the future, we may not need this
export const buildUTMParamObj = ({ utm_campaign, utm_content, utm_term }: {
    utm_campaign: string
    utm_content: string
    utm_term?: string
}) => {
    const { utm_source, utm_medium } = buildBaseUTMParamObj()

    return {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        ...(utm_term && { utm_term }),
    }
}

// Useful if adding UTM parameters directly to url string literals
export const buildUTMParamString = ({ utm_campaign, utm_content, utm_term }: {
    utm_campaign: string
    utm_content: string
    utm_term?: string
}) => {
    const { utm_source, utm_medium } = buildBaseUTMParamObj()

    let paramString = `utm_source=${
        utm_source
    }&utm_medium=${
        utm_medium
    }&utm_campaign=${
        utm_campaign
    }&utm_content=${
        utm_content
    }`
    if (utm_term) {
        paramString += `&utm_term=${utm_term}`
    }

    return paramString
}
