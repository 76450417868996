import type { TActiveQuiz } from '@/store/quiz/state'
import { initLoadable } from '@/store/utils'
import type { Loadable } from '@/store/utils'
import type { Parse } from '@/store/parseUtils'
import type { Study } from '@pocketprep/types'

export type TActiveMockExamQuiz = TActiveQuiz & {
    mockExam: Parse.Pointer
    getsExtraTime: boolean
    numOfAllQuestionViews?: number
    secondsInAllQuestionsView?: number
}

type TMockExamState = {
    mockExams: Loadable<Study.Class.MockExamJSON[]>
    activeMockExamQuiz: TActiveMockExamQuiz | null
}

export const getInitialMockExamState = (): TMockExamState => {
    const activeMockExamQuizString = localStorage.getItem('activeMockExamQuiz')
    const activeMockExamQuiz: TActiveMockExamQuiz = activeMockExamQuizString && JSON.parse(activeMockExamQuizString)

    return {
        mockExams: initLoadable([]),
        activeMockExamQuiz: activeMockExamQuiz || null,
    }
}

const moduleState = getInitialMockExamState()

export default moduleState
