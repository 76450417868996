<template>
    <div v-if="showCompareView" class="compare">
        <div class="compare__section">
            <div class="compare__premium-features">
                <div class="compare__premium-header">
                    Premium Features
                </div>
                <div v-if="exam" class="compare__compare-info">
                    <img
                        src="@/assets/register-onboard/checkmark.svg"
                        class="compare__icon"
                        alt=""
                    >
                    <div>
                        {{ exam.itemCount - exam.archivedCount }} Questions and Explanations
                    </div>
                </div>
                <div class="compare__compare-info">
                    <img
                        src="@/assets/register-onboard/checkmark.svg"
                        class="compare__icon"
                        alt=""
                    >
                    <div>
                        Performance by Subject
                    </div>
                </div>
                <div class="compare__compare-info">
                    <img
                        src="@/assets/register-onboard/checkmark.svg"
                        class="compare__icon"
                        alt=""
                    >
                    <div>
                        Mobile & Web Access
                    </div>
                </div>
                <div 
                    v-if="bundle && bundle.name && uniqueExams.length" 
                    class="compare__compare-info"
                >
                    <img
                        src="@/assets/register-onboard/checkmark.svg"
                        class="compare__icon"
                        alt=""
                    >
                    <div>
                        Prep for {{ uniqueExams.length }} {{ bundle.name }} Exams
                    </div>
                </div>
                <div class="compare__compare-info">
                    <img
                        src="@/assets/register-onboard/checkmark.svg"
                        class="compare__icon"
                        alt=""
                    >
                    <div>
                        Pass Guarantee
                    </div>
                </div>
            </div>
            <div class="compare__app-info">
                <div class="compare__premium-header">
                    Premium Quiz Modes
                </div>
                <div v-if="exam.mockExams && exam.mockExams.length" class="compare__compare-info">
                    <img
                        src="@/assets/register-onboard/mock-exam.svg"
                        class="compare__icon"
                        alt=""
                    >
                    <div>
                        {{ exam.mockExams.length }} Mock Exam{{ exam.mockExams.length > 1 ? 's' : '' }}
                    </div>
                </div>
                <div class="compare__compare-info">
                    <img
                        src="@/assets/register-onboard/qotd.svg"
                        class="compare__icon"
                        alt=""
                    >
                    <div>
                        Question of the Day
                    </div>
                </div>
                <div class="compare__compare-info">
                    <img
                        src="@/assets/register-onboard/quick-ten.svg"
                        class="compare__icon"
                        alt=""
                    >
                    <div>
                        Quick 10 Quiz
                    </div>
                </div>
                <div class="compare__compare-info">
                    <img
                        src="@/assets/register-onboard/timed-quiz.svg"
                        class="compare__icon"
                        alt=""
                    >
                    <div>
                        Timed Quiz
                    </div>
                </div>
                <div class="compare__compare-info">
                    <img
                        src="@/assets/register-onboard/missed-questions.svg"
                        class="compare__icon"
                        alt=""
                    >
                    <div>
                        Missed Questions Quiz
                    </div>
                </div>
                <div class="compare__compare-info">
                    <img
                        src="@/assets/register-onboard/weakest-subject.svg"
                        class="compare__icon"
                        alt=""
                    >
                    <div>
                        Weakest Subject Quiz
                    </div>
                </div>
                <div class="compare__compare-info">
                    <img
                        src="@/assets/register-onboard/pencil.svg"
                        class="compare__icon"
                        alt=""
                    >
                    <div>
                        Build Your Own Quiz
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator'
import type { Study } from '@pocketprep/types'
import UIKit from '@pocketprep/ui-kit'
import { screenModule } from '@/store/screen/module'
import { examMetadataModule } from '@/store/examMetadata/module'

@Component({
    components: {
        Icon: UIKit.Icon,
    },
})
export default class Compare extends Vue {
    @Prop() exam!: Study.Class.ExamMetadataJSON
    @Prop({ default: false }) showCompareView!: boolean
    @Prop() bundle!: Study.Class.BundleJSON

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get examMetadataById () {
        return examMetadataModule.getters.getExamMetadataById()
    }

    get uniqueExams () {
        return this.bundle.exams.reduce<string[]>((acc, exam) => {
            const examNativeAppName = this.examMetadataById[exam.objectId].nativeAppName
            if (!acc.includes(examNativeAppName)) {
                acc.push(examNativeAppName)
            }
            return acc
        }, [])
    }

    async mounted () {
        await examMetadataModule.actions.fetchExamMetadata()
    }
}
</script>
<style lang="scss" scoped>
.compare {
     &__section {
        display: flex;
        justify-content: space-evenly;
        width: 946px;
        min-height: 331px;
        padding-bottom: 50px;
        background: $white;

        @include breakpoint(brown-bear) {
            display: flex;
            justify-content: center;
            width: auto;
            min-height: 342px;
            padding-bottom: 0;
            margin-bottom: 32px;
        }

        @include breakpoint(black-bear) {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: auto;
            min-height: 456px;
            justify-content: flex-start;
            padding-bottom: 20px;
        }
    }

    &__premium-features {
        width: 331px;
        height: 237px;
        margin-top: 31px;
        margin-left: 12px;

        @include breakpoint(brown-bear) {
            margin-left: 0;
            margin-right: 0;
            width: 316px;
        }

        @include breakpoint(black-bear) {
            display: flex;
            flex-direction: column;
            height: fit-content;
            margin-left: 0;
            margin-right: 0;
            width: 319px;
        }
    }

    &__compare-info {
        display: flex;        
        color: $brand-black;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        align-items: center;
        border-bottom: 1px solid $pewter;
        margin-bottom: 5px;
        padding-bottom: 2px;
        height: 35px;

        &:last-child {
            border: none;
            margin-bottom: 0
        }

        @include breakpoint(brown-bear) {
            width: 290px;
        }

        @include breakpoint(black-bear) {
            width: 312px;
        }
    }

    &__premium-header {
        color: $slate;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 7px;
    }

    &__app-info{
        width: 337px;
        height: 237px;
        margin-top: 31px;
        margin-left: 45px;

        @include breakpoint(brown-bear) {
            width: 293px;
            margin-right: 5px;
            height: fit-content;
            margin-left: 35px;
        }

        @include breakpoint(black-bear) {
            display: flex;
            flex-direction: column;
            height: fit-content;
            margin-left: 0;
            margin-right: 0;
            margin-top: 17px;
            width: 319px;
        }
    }

    &__info {
        display: flex;
        align-items: start;
        margin-bottom: 28px;
    }

    &__info-message {
        color: $brand-black;
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
        margin-top: 5px;
        margin-left: 15px;

        @include breakpoint(black-bear) {
            width: 277px;
        }
    }

    &__icon {
        margin-right: 5px;
        margin-left: 4px;
        @include breakpoint(black-bear) {
            margin-left: 0;
            margin-right: 8px;
        }
    }
}
</style>