import { runCloudFunction } from '@/store/parseUtils'
import { readinessModule } from '@/store/readiness/module'
import { fetchLoadable } from '@/store/utils'
import type { Study } from '@pocketprep/types'
import * as Sentry from '@sentry/browser'

export type ReadinessScoreResponse = {
    userId: string
    examGuid: string
    readinessScore: number
    actualResult: 'Passed' | 'Failed' | 'Unknown'
    readinessStats?: Study.Class.ReadinessStats
}
const fetchReadinessScore = async (userId: string, examGuid: string) => {
    return fetchLoadable(readinessModule.state.readinessScores, async () => {
        try {
            const readinessScores = await runCloudFunction(
                'fetchReadinessScore', 
                { 
                    includeStats: true,
                    users: [{
                        userId,
                        examGuid,
                    }],
                }
            ) as Promise<ReadinessScoreResponse[]>
            return readinessScores
        } catch (err) {
            Sentry.captureException(err)
            return null
        }
    })
}

export default {
    fetchReadinessScore,
}
