import type { Study } from '@pocketprep/types'
import { initLoadable } from '@/store/utils'
import type { Loadable } from '@/store/utils'

type TGlobalQuestionMetricState = {
    globalQuestionMetricsBySerial: Loadable<{
        [serial: string]: Study.Class.GlobalQuestionMetricJSON
    }>
}

export const getInitialGlobalQuestionMetricState = (): TGlobalQuestionMetricState => {
    return {
        globalQuestionMetricsBySerial: initLoadable({}),
    }
}

const moduleState = getInitialGlobalQuestionMetricState()

export default moduleState
