import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import { environment } from './utils'

export const SentryInit = (app: App) => {
    /* istanbul ignore next */
    if (import.meta.env.VUE_APP_SENTRY_DSN) {
        Sentry.init({
            app,
            dsn: import.meta.env.VUE_APP_SENTRY_DSN,
            release: `study@${import.meta.env.VUE_APP_VERSION}`,
            environment,
            integrations: [
                Sentry.replayIntegration({
                    networkDetailAllowUrls: [ new RegExp('.*') ],
                }),
            ],
            replaysSessionSampleRate: 0.001,
            replaysOnErrorSampleRate: 0.001,
            beforeSend: (event, hint) => {
                if (
                    hint?.originalException
                    && (
                        hint.originalException.toString().includes('Invalid session token')
                        || hint.originalException.toString().includes('Unable to connect to the Parse API')
                        || hint.originalException.toString().includes('window.webkit.messageHandlers')
                        || hint.originalException.toString().includes('ceCurrentVideo.currentTime')
                        || hint.originalException.toString().includes('instantSearchSDKJSBridgeClearHighlight')
                        || hint.originalException.toString().includes('NotReadableError: The I/O read operation failed')
                        // These network errors typically seem to be from non-pocketprep requests
                        || hint.originalException.toString().includes('Failed to fetch')
                        || hint.originalException.toString().includes('Request timeout after 10000 milliseconds')
                        || hint.originalException.toString().includes('Load failed')
                        || hint.originalException.toString().includes('NetworkError when attempting to fetch resource.')
                    )
                ) {
                    return null
                }
            
                return event
            },
        })
    }
}
