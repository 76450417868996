import type { Study } from '@pocketprep/types'
import { examMetadataModule } from '@/store/examMetadata/module'
import { runCloudFunction, Parse } from '@/store/parseUtils'
import { userModule } from '@/store/user/module'
import { questionModule } from '@/store/question/module'
import { fetchLoadable, resetLoadable } from '@/store/utils'
import { qotdModule } from '@/store/qotd/module'
import type { TQuizDraft } from '@/store/qotd/state'
import { quizModule } from '@/store/quiz/module'
import { analyticsModule } from '@/store/analytics/module'
import { localISODateString } from '@/utils'

const fetchQotDQuestion = async ({ examGuid, version }: { examGuid: string; version: string }) => {
    // Get today's QotD
    const today = new Date()
    const daysSinceEpoch = Math.floor((today.getTime() / 60000 - today.getTimezoneOffset()) / 60 / 24)
    return (await runCloudFunction<Study.Cloud.fetchQotDV5>('fetchQotD-v5', {
        daysSinceEpoch,
        examGuid,
        version,
        includeMatrixQuestions: true,
    }))[0]
}

const fetchCurrentQotDQuestion = async () => {
    await fetchLoadable(qotdModule.state.question, async () => {
        // Ensures we have the examMetadata and quizzes that we need
        await Promise.all([
            userModule.actions.fetchUserData(),
            questionModule.actions.fetchSerialQuestionInfoLib(),
        ])
    
        const currentExam = examMetadataModule.getters.getCurrentExamMetadata()
        if (!currentExam) {
            return
        }
        const examGuid = currentExam.examGuid
        const version = currentExam.version    

        return fetchQotDQuestion({ examGuid, version })
    })
}

const fetchQotDMetric = async ({ examGuid, serial }: { examGuid: string; serial: string }) => {
    // We can't use fetchGlobalQuestionMetrics because that requires a user
    const globalMetric = await new Parse.Query<Study.Class.GlobalQuestionMetric>('GlobalQuestionMetric')
        .equalTo('examGuid', examGuid)
        .containedIn('questionSerial', [ serial ])
        .find()

    return (globalMetric && globalMetric[0]?.toJSON()) || null
}

const fetchCurrentQotDMetric = async () => {
    await fetchLoadable(qotdModule.state.globalMetric, async () => {
        // Ensures we have the examMetadata and quizzes that we need
        await Promise.all([
            userModule.actions.fetchUserData(),
            questionModule.actions.fetchSerialQuestionInfoLib(),
            fetchCurrentQotDQuestion(),
        ])
        const qotdQuestion = qotdModule.getters.getQotDQuestion()
        if (!qotdQuestion) {
            return
        }
        const currentExam = examMetadataModule.getters.getCurrentExamMetadata()
        if (!currentExam) {
            throw new Error('No current exam found')
        }
        const examGuid = currentExam.examGuid
    
        return fetchQotDMetric({ examGuid, serial: qotdQuestion?.serial })
    })
}

const fetchSharedQotD = async ({ examGuid, version }: { examGuid: string; version: string }) => {
    const today = new Date()
    const daysSinceEpoch = Math.floor((today.getTime() / 60000 - today.getTimezoneOffset()) / 60 / 24)
    const sharedQotDInfo = await runCloudFunction<Study.Cloud.fetchSharedQotdV3>('fetchSharedQotD-v3', {
        daysSinceEpoch,
        examGuid,
        version,
        includeMatrixQuestions: true,
    })
    return sharedQotDInfo
}

const recordQotD = async (answer: Study.Cloud.IQuizAnswer) => {
    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()
    if (!currentExam) {
        throw new Error('No current exam found')
    }
    const examGuid = currentExam.examGuid

    const recordPayload: Study.Cloud.IRecordQuizPayload = {
        durationSeconds: 0,
        examGuid,
        mode: -1,
        platform: 'Web',
        appBundleId: 'study.pocketprep.com',
        startedAt: localISODateString(),
        answers: [ answer ],
    }
    const recordQuizResponse = await runCloudFunction<Study.Cloud.recordQuiz>('recordQotd', recordPayload)

    quizModule.state.quizzes.push(recordQuizResponse.quiz)

    resetLoadable(qotdModule.state.globalMetric)
    await Promise.all([
        quizModule.actions.fetchAnsweredQuestions(recordQuizResponse.quiz.objectId),
        fetchCurrentQotDMetric(),
    ])

    await analyticsModule.actions.googleAnalyticsTrack(answer.isCorrect ? 'Correct' : 'Incorrect', {
        'event_category': 'QOTD',
        'event_label': currentExam.nativeAppName,
    })
}

const saveSharedQotDDraft = (sharedQotDQuizDraft: TQuizDraft) => {
    qotdModule.state.sharedQotDQuizDraft = sharedQotDQuizDraft
    localStorage.setItem('sharedQotDQuizDraft', JSON.stringify(sharedQotDQuizDraft))
}

const clearSharedQotDDraft = () => {
    qotdModule.state.sharedQotDQuizDraft = null
    localStorage.removeItem('sharedQotDQuizDraft')
}

export default {
    fetchCurrentQotDQuestion,
    fetchCurrentQotDMetric,
    fetchQotDMetric,
    fetchQotDQuestion,
    fetchSharedQotD,
    recordQotD,
    saveSharedQotDDraft,
    clearSharedQotDDraft,
}
