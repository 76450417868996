import type { Study } from '@pocketprep/types'
import type Stripe from 'stripe'
import { userModule } from '@/store/user/module'
import { runCloudFunction } from '@/store/parseUtils'
import { resetLoadable } from '@/store/utils'
import { stripeModule } from '@/store/stripe/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { analyticsModule } from '@/store/analytics/module'
import { referralModule } from '@/store/referral/module'
import { toastModule } from '@/store/toast/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'

type TSubscribeUserPayload = { 
    stripeToken?: Stripe.Token
    paymentMethodId?: string
    name?: string
    city?: string
    plan: string
    state?: string
    country?: string
    referralId?: string
    impactClickId?: string
}

type TCreateIncompleteSubscription = (params: {
    plan: string
    referralId?: string
    impactClickId?: string
}) => Promise<{
    clientSecret: string
    subscriptionId: string
}>

type TCreateIncompleteDiscountPurchase = (params: {
    priceId: string
}) => Promise<{
    clientSecret: string
    invoiceId: string
}>

type TActivateSubscription = 
(params: { 
    subscriptionId: string
    plan: string
}) => Promise<Study.Class.SubscriptionBundle>

type TActivateDiscountPurchase = 
    (params: { invoiceId: string; organizationId: string }) => Promise<Study.Class.Subscription[]>

type TFetchUTIConfig = () => Promise<{ 
    examGuids: string[]
    orgIdNameLib: { [orgId: string]: string }
    stripePrice: {
        id: string
        object: 'price'
        created: number  
        nickname: string | null
        unit_amount: number | null
        unit_amount_decimal: string | null
    }
}>

const subscribeUser = async (params: TSubscribeUserPayload) => {
    const buySubscriptionParams: Parameters<Study.Cloud.buySubscription>[0] = params

    // If we have a valid referral, pass the referralId to buySubscription
    const referral = referralModule.getters.getValidReferral()
    if (referral) {
        buySubscriptionParams.referralId = 'id' in referral ? referral.id : referral.objectId
    }

    if (params.impactClickId) {
        buySubscriptionParams.impactClickId = params.impactClickId
    }

    await runCloudFunction<Study.Cloud.buySubscription>('buySubscription', buySubscriptionParams)

    // if no Stripe Customer ID on User, refresh Parse User
    if (!userModule.state.user?.stripeCustomerId) {
        await userModule.actions.refreshParseUser()
    }

    resetLoadable(userModule.state.userData)
    resetLoadable(stripeModule.state.payment)
    resetLoadable(stripeModule.state.subscriptions)

    referralModule.actions.wipeReferralContext()

    // 'trial' is used because we are confident that Tapfiliate will listen to future subscription webhooks
    // when we send it as a trial. Conversion is another option that we may explore later, but the documentation
    // is sparse and we aren't confident that Tapfiliate will continue listening for future subscription webhooks 
    // if we send it as a conversion.
    if (userModule.state.user?.stripeCustomerId && import.meta.env.VUE_APP_TAPFILIATE_KEY){
        window.tap('trial', userModule.state.user.stripeCustomerId)
    }

    await Promise.all([
        userModule.actions.fetchUserData(),
        userModule.actions.refreshParseUser(),
        stripeModule.actions.fetchPaymentMethods(),
    ])

    analyticsModule.actions.updateIntercom()

    toastModule.actions.displayToast({
        title: 'Premium subscription activated!',
    })
}

const createIncompleteSubscription = async (params: {
    plan: string
    referralId?: string
    impactClickId?: string
}) => {
    const stripeClientSecret = 
        await runCloudFunction<TCreateIncompleteSubscription>('createIncompleteSubscription', params)
    return stripeClientSecret
}

const activateSubscription = async (params: {
    subscriptionId: string
    plan: string
}) => {
    await runCloudFunction<TActivateSubscription>('activateSubscription', params)

    // if no Stripe Customer ID on User, refresh Parse User
    if (!userModule.state.user?.stripeCustomerId) {
        await userModule.actions.refreshParseUser()
    }

    resetLoadable(userModule.state.userData)
    resetLoadable(stripeModule.state.payment)
    resetLoadable(stripeModule.state.subscriptions)

    referralModule.actions.wipeReferralContext()

    // 'trial' is used because we are confident that Tapfiliate will listen to future subscription webhooks
    // when we send it as a trial. Conversion is another option that we may explore later, but the documentation
    // is sparse and we aren't confident that Tapfiliate will continue listening for future subscription webhooks 
    // if we send it as a conversion.
    if (userModule.state.user?.stripeCustomerId && import.meta.env.VUE_APP_TAPFILIATE_KEY){
        window.tap('trial', userModule.state.user.stripeCustomerId)
    }

    await Promise.all([
        userModule.actions.fetchUserData(),
        userModule.actions.refreshParseUser(),
        stripeModule.actions.fetchPaymentMethods(),
    ])

    analyticsModule.actions.updateIntercom()

    toastModule.actions.displayToast({
        title: 'Premium subscription activated!',
    })
}

const createIncompleteDiscountPurchase = async (params: {
    priceId: string
}) => {
    const stripeClientSecret = 
        await runCloudFunction<TCreateIncompleteDiscountPurchase>('createIncompleteDiscountPurchase', params)
    return stripeClientSecret
}

const activateDiscountPurchase = async (params: {
    invoiceId: string
    organizationId: string
}) => {
    await runCloudFunction<TActivateDiscountPurchase>('activateDiscountPurchase', params)

    resetLoadable(userModule.state.userData)
    resetLoadable(userExamMetadataModule.state.userExamMetadataByGuid)
    resetLoadable(stripeModule.state.payment)
    resetLoadable(stripeModule.state.subscriptions)

    await Promise.all([
        userModule.actions.fetchUserData(),
        userModule.actions.refreshParseUser(),
        stripeModule.actions.fetchPaymentMethods(),
    ])

    analyticsModule.actions.updateIntercom()

    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()

    toastModule.actions.displayToast({ 
        title: 'License codes activated.',
        subtext: `You are now studying the ${currentExam?.nativeAppName || 'selected'} exam. Switch
        exams via the upper left menu dropdown`,
    })
}

const fetchUTIConfig = async () => {
    const UTIConfig = await runCloudFunction<TFetchUTIConfig>('fetchUTIConfig')
    return UTIConfig
}

export default {
    subscribeUser,
    createIncompleteSubscription,
    activateSubscription,
    createIncompleteDiscountPurchase,
    activateDiscountPurchase,
    fetchUTIConfig,
}
