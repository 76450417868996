<template>
    <div class="foot">
        <div class="foot__nav">
            <Icon
                :tabindex="currentDot <= 0 ? -1 : 0"
                type="arrow"
                class="foot__previous"
                role="button"
                aria-label="Previous slide"
                :aria-disabled="currentDot <= 0 ? 'true' : 'false'"
                :class="{ 'foot__next--disabled': currentDot <= 0 }"
                @click="currentDot > 0 && emitChange(currentDot - 1)"
                @keydown.enter="currentDot > 0 && emitChange(currentDot - 1)"
                @mousedown.prevent
            />
            <div class="foot__dots">
                <div
                    v-for="dot in dots"
                    :key="dot"
                    tabindex="0"
                    class="foot__dot"
                    :class="{ 'foot__dot--active': dot === currentDot }"
                    role="button"
                    :aria-label="dot === currentDot
                        ? `Current slide ${dot + 1} of ${dots.length}`
                        : `Slide ${dot + 1} of ${dots.length}`"
                    @click="emitChange(dot)"
                    @keydown.enter="emitChange(dot)"
                    @mousedown.prevent
                />
            </div>
            <Icon
                :tabindex="currentDot >= dots.length-1 ? -1 : 0"
                type="arrow"
                class="foot__next"
                :class="{ 'foot__next--disabled': currentDot >= dots.length - 1 }"
                role="button"
                aria-label="Next slide"
                :aria-disabled="currentDot >= dots.length - 1 ? 'true' : 'false'"
                @click="currentDot < dots.length - 1 && emitChange(currentDot + 1)"
                @keydown.enter="currentDot < dots.length - 1 && emitChange(currentDot + 1)"
                @mousedown.prevent
            />
        </div>
        <div v-if="currentDot === dots.length - 1" class="foot__close">
            <PocketButton class="foot__close-button" @click="emitClose">
                Got It
            </PocketButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        Icon: UIKit.Icon,
        PocketButton: UIKit.Button,
    },
})
export default class Foot extends Vue {
    @Prop({ default: 0 }) currentDot!: number

    dots = [ 0, 1, 2, 3 ]

    @Emit('change')
    emitChange (slideNumber: number) {
        return slideNumber
    }

    @Emit('close')
    emitClose () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.foot {
    position: relative;

    &__nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 136px;
        height: 36px;
        margin: 0 auto 2px;

        @include breakpoint(black-bear) {
            width: 286px;
            height: 18px;
            padding-bottom: 32px;
        }
    }

    &__previous,
    &__next {
        width: 17px;
        height: 13px;
        padding: 4px;
        color: $brand-blue;
        cursor: pointer;

        @include breakpoint(black-bear) {
            width: 24px;
            height: 18px;
        }

        &--disabled {
            color: $sky-blue;
            cursor: default;
        }
    }

    &__previous {
        transform: rotate(180deg);
    }

    &__dots {
        width: 64px;
        display: flex;
        justify-content: space-around;

        @include breakpoint(black-bear) {
            width: 80px;
        }
    }

    &__dot {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background: $gray-divider;
        cursor: pointer;

        @include breakpoint(black-bear) {
            width: 10px;
            height: 10px;
            border-radius: 10px;
        }

        &--active {
            background: $slate-03;
        }
    }

    &__close {
        position: absolute;
        right: 35px;
        bottom: 0;

        @include breakpoint(black-bear) {
            width: 174px;
            bottom: 74px;
            left: 50%;
            right: auto;
            margin-left: -87px;
        }
    }

    &__close-button {
        @include breakpoint(black-bear) {
            width: 100%;
            height: 44px;
        }
    }
}
</style>