import { reactive } from 'vue'
import actions from '@/store/analytics/actions'
import state from '@/store/analytics/state'
import getters from '@/store/analytics/getters'

export const analyticsModule = {
    state: reactive(state),
    actions,
    getters,
}
