<template>
    <KeywordDefinition
        v-if="showKeywordDefinition && visibleKeywordDefinition && question"
        :keywordDOMElement="keywordDOMElement"
        :keywordClickLocation="keywordClickLocation"
        :keyword="visibleKeywordDefinition.keyword"
        :definition="visibleKeywordDefinition.definition"
        :questionContainer="questionContainer"
        :serial="question.serial"
        :keywordLocation="visibleKeywordDefinition.location"
        @close="showKeywordDefinition = false"
    />
    <div class="qotd__container">
        <UpgradeSidePanel
            v-if="showUpgradeSidePanel"
            key="qotd"
            :show-full-height="true"
            @close="showUpgradeSidePanel = false"
        />
        <BonusQuestionSurvey
            v-if="showSurvey"
            :variant="bonusQuestionVariantValue === 'short-answer-question'
                ? 'shortAnswerQuestion'
                : 'explanationAnswerQuestion'
            "
            :bonusQText="bonusQuestionUserText"
            @submit="submitSurvey"
            @close="closeSurvey"
        />
        <QuizContainer
            ref="quizContainer"
            class="qotd"
            :is-dark-mode="isDarkMode"
        >
            <template #header>
                <div class="qotd__exam-info">
                    <ExamMenuCard
                        v-if="currentExam && bundle"
                        :exam="currentExam"
                        :bundle-id="bundle.objectId"
                        theme="silver"
                        :is-clickable="false"
                        :is-dark-mode="isDarkMode"
                    />
                </div>
                <PocketButton
                    class="qotd__quit-btn"
                    type="secondary-yellow-dark"
                    :is-dark-mode="isDarkMode"
                    @click="quitQuiz"
                >
                    Close
                </PocketButton>
            </template>
            <template #question>
                <BonusQuestionExperiment
                    v-if="currentExam && showBonusQuestion"
                    :examGuid="currentExam.examGuid"
                    :question="bonusQuestion"
                    :is-dark-mode="isDarkMode"
                    @skip="skipBonusQuestion"
                    @userAnswer="updateUserShortAnswer"
                    @showSurvey="showSurvey = true"
                />
                <Question
                    v-else-if="question && currentExam && !showBonusQuestion && !isLoading"
                    :key="`${question.objectId}-${allowKeyboardShortcuts}-${JSON.stringify(answer)}`"
                    class="qotd__question"
                    ref="qotdContainer"
                    :class="{ 'qotd__question--correct': answer && isCorrect }"
                    :container-el="quizContainerEl"
                    :show-next-question="false"
                    :question="question"
                    :quiz-mode="'qotd'"
                    :is-dark-mode="isDarkMode"
                    :previous-choices="previousChoices"
                    :previous-matrix-choices="previousChoices"
                    :global-metrics="showGlobalMetrics && globalMetrics"
                    :show-check-answer="true"
                    :image-url-prefix="`${s3Url || ''}/${question.compositeKey.toUpperCase()}/`"
                    :initial-show-answers="!!answer"
                    :allow-keyboard-shortcuts="allowKeyboardShortcuts"
                    :show-close-button="true"
                    :show-paywall="showPaywall"
                    :hide-references="currentExam.hideReferences"
                    :keyword-definitions="keywordDefinitions"
                    @upgrade="showUpgradeSidePanel = true"
                    @keywordClick="keywordClicked"
                    @close="quitQuiz"
                    @checkAnswer="submitQotD"
                    @selectedChoices="selectChoices"
                    @update:showExplanation="explanationViewToggled"
                >
                    <template #promptExperiment>
                        <BonusQuestionBanner
                            v-if="showUserExplanationField"
                            class="qotd__bonus-question-banner"
                            variant="explanation-answer-question"
                            :is-dark-mode="isDarkMode"
                        />
                    </template>
                    <template #actionExperiment>
                        <BonusQuestionTextarea
                            v-if="showUserExplanationField"
                            class="qotd__desktop-bonus-question-explanation-textarea"
                            v-model="userExplanation"
                            label="Why is this the correct answer?"
                            height="134px"
                            :disabled="!selectedChoices.length || !!answer"
                            :is-dark-mode="isDarkMode"
                        />
                    </template>
                </Question>
            </template>
            <template #footer>
                <div class="qotd__footer">
                    <div class="qotd__footer-left">
                        <div
                            class="qotd__global-metrics-toggle"
                            :class="{ 
                                'qotd__global-metrics-toggle--active': showGlobalMetrics,
                                'qotd__global-metrics-toggle--disabled': !answer,
                                'qotd__global-metrics-toggle--enabled': !!answer
                            }"
                        >
                            <GlobalMetricsToggle 
                                :show-global-metrics="showGlobalMetrics" 
                                :disabled="!answer || showPaywall"
                                :is-dark-mode="isDarkMode"
                                :tooltip-theme="
                                    (
                                        breakpoint === 'polar-bear' 
                                        || breakpoint === 'grizzly-bear' 
                                        || breakpoint === 'brown-bear'
                                    )
                                        && 'rightalign'
                                "
                                @toggleGlobalMetrics="toggleGlobalMetrics"
                            />
                        </div>
                        <QotDShareLink
                            v-if="isTodaysQotD"
                            :bundle="bundle"
                            class="qotd__share--desktop"
                            :exam-metadata="currentExam"
                            :is-dark-mode="isDarkMode"
                        />
                    </div>
                    <div class="qotd__controls">
                        <FlagToggle
                            :key="`flagToggle_${allowKeyboardShortcuts}`"
                            class="qotd__flag-toggle"
                            :is-flagged="isFlagged"
                            :is-dark-mode="isDarkMode"
                            :enable-flag-tooltip="breakpoint !== 'brown-bear' && breakpoint !== 'black-bear'"
                            :enable-flag-keyboard-shortcut="allowKeyboardShortcuts"
                            @toggleFlag="toggleFlag"
                        />
                    </div>
                    <QotDShareLink
                        v-if="isTodaysQotD"
                        :bundle="bundle"
                        class="qotd__share--mobile"
                        :is-dark-mode="isDarkMode"
                        :exam-metadata="currentExam"
                    />
                    <KeyboardShortcutsButton
                        class="qotd__keyboard-shortcuts-btn"
                        :is-dark-mode="isDarkMode"
                        :tooltip-theme="(breakpoint === 'polar-bear' || breakpoint === 'grizzly-bear' 
                            || breakpoint === 'brown-bear') && 'leftalign'"
                    >
                        <template
                            #keyboardShortcutsModal="{ 
                                showKeyboardShortcutsModal, 
                                closeModalFunc
                            }"
                        >
                            <Portal to="modal">
                                <KeyboardShortcutsModal
                                    v-if="showKeyboardShortcutsModal"
                                    key="qotdKeyboardShortcutsModal"
                                    :allow-keyboard-shortcuts="allowKeyboardShortcuts"
                                    :is-dark-mode="isDarkMode"
                                    aria-live="polite"
                                    @toggleKeyboardShortcuts="toggleKeyboardShortcuts"
                                    @close="closeModalFunc"
                                />
                            </Portal>
                        </template>
                    </KeyboardShortcutsButton>
                </div>
            </template>
        </QuizContainer>
    </div>
</template>

<script lang="ts">
import { bundleModule } from '@/store/bundle/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { qotdModule } from '@/store/qotd/module'
import { userModule } from '@/store/user/module'
import type { Study } from '@pocketprep/types'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component } from 'vue-facing-decorator'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { quizModule } from '@/store/quiz/module'
import QotDShareLink from '@/components/Quiz/QotDShareLink.vue'
import { screenModule } from '@/store/screen/module'
import UpgradeSidePanel from '@/components/Settings/UpgradeSidePanel.vue'
import { subscriptionModule } from '@/store/subscription/module'
import KeywordDefinition from '@/components/KeywordDefinitions/KeywordDefinition.vue'
/********* Start Bonus Question Experiment imports *********/
import { analyticsModule } from '@/store/analytics/module'
import BonusQuestionExperiment from '@/components/BonusQuestionExperiment/BonusQuestionExperiment.vue'
import BonusQuestionBanner from '@/components/BonusQuestionExperiment/BonusQuestionBanner.vue'
import BonusQuestionSurvey from '@/components/BonusQuestionExperiment/BonusQuestionSurvey.vue'
import BonusQuestionTextarea from '@/components/BonusQuestionExperiment/BonusQuestionTextarea.vue'
import { toastModule } from '@/store/toast/module'
import { keywordsModule } from '@/store/keywords/module'
/********* End Bonus Question Experiment imports *********/

@Component({
    components: {
        FlagToggle: UIKit.FlagToggle,
        KeyboardShortcutsButton: UIKit.KeyboardShortcutsButton,
        Icon: UIKit.Icon,
        ExamMenuCard: UIKit.ExamMenuCard,
        PocketButton: UIKit.Button,
        GlobalMetricsToggle: UIKit.GlobalMetricsToggle,
        QuizContainer: UIKit.QuizContainer,
        Question: UIKit.Question,
        Tooltip: UIKit.Tooltip,
        QotDShareLink,
        KeyboardShortcutsModal: UIKit.KeyboardShortcutsModal,
        UpgradeSidePanel,
        KeywordDefinition,
        /********* Start Bonus Question Experiment components *********/
        BonusQuestionExperiment,
        BonusQuestionBanner,
        BonusQuestionSurvey,
        BonusQuestionTextarea,
        /********* End Bonus Question Experiment components *********/
    },
    options: {
        beforeRouteEnter (this, to, from, next) {
            next(vm => {
                const fromName = from.name as string | undefined
                (vm as QotD).fromName = fromName || null
            })
        },
    },
})
export default class QotD extends Vue {
    isLoading = true
    isSubmitLoading = false
    showLinkCopiedTooltip = false
    showGlobalMetrics = false
    globalMetrics: Study.Class.GlobalQuestionMetricJSON | null = null
    currentExam: Study.Class.ExamMetadataJSON | null = null
    fromName: string | null = null
    isFlagged = false
    showUpgradeSidePanel = false
    isCorrect = false
    isCommunityStatsDisabled = true
    /********* Start Bonus Question Experiment properties *********/
    disableExperiment = false
    showSurvey = false
    selectedChoices: Study.Cloud.TChoiceKey[] = []
    bonusQuestionVariantValue: 'short-answer-question' | 'explanation-answer-question' | null = null
    userShortAnswer = ''
    userExplanation = ''
    /********* End Bonus Question Experiment properties *********/
    keywordDOMElement: HTMLElement | null = null
    showKeywordDefinition = false
    visibleKeywordDefinition: {
        keyword: string
        definition: string
        location: string
    } | null = null
    keywordClickLocation: { x: number; y: number} | null = null

    get showPaywall () {
        return !this.hasActiveSubscription && !this.isTodaysQotD
    }

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get questionContainer () {
        const questionContainerRef = this.$refs['qotdContainer'] as typeof UIKit.Question
        return questionContainerRef && '$el' in questionContainerRef ? questionContainerRef.$el as HTMLElement : null
    }

    get quizContainerEl () {
        const quizContainerRef = this.$refs['quizContainer'] as typeof UIKit.QuizContainer | undefined
        return quizContainerRef && '$el' in quizContainerRef ? quizContainerRef.$el : null
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get question () {
        if (this.quizId) {
            const quiz = quizModule.getters.getQuizById(this.quizId)

            if (quiz) {
                const answer = quiz.answers[0]

                return quizModule.getters.getAnsweredQuestions().find(q => q.serial === answer?.questionSerial)
            }
        }

        return qotdModule.getters.getQotDQuestion()
    }

    get flaggedQuestions () {
        return userExamMetadataModule.getters.getFlaggedQuestions()
    }

    get quizSettings () {
        return userModule.state.user?.quizSettings || null
    }

    get allowKeyboardShortcuts () {
        return !!this.quizSettings?.enableKeyboardShortcuts
    }

    get hasActiveSubscription () {
        return subscriptionModule.getters.getSubscriptionForExamId()
    }

    get answer () {
        return this.quizId
            ? quizModule.getters.getQuizById(this.quizId)?.answers[0] || null
            : qotdModule.getters.getQotDQuiz()?.answers[0] || null
    }

    get previousChoices () {
        return this.answer?.selectedChoices || []
    }

    get quizId () {
        return this.$route.params['quizId'] as string | undefined
    }

    get examMetadataById () {
        return examMetadataModule.getters.getExamMetadataById()
    }

    get bundle () {
        return bundleModule.getters.getBundles()
            .find(b => b.exams.find(e => {
                const bundleExam = this.examMetadataById[e.objectId]
                return bundleExam?.examGuid === this.currentExam?.examGuid
            }))
    }

    get s3Url () {
        return import.meta.env.VUE_APP_S3_URL
    }

    get isTodaysQotD () {
        return qotdModule.getters.getQotDQuestion()?.serial === this.question?.serial
    }

    get keywordDefinitions () {
        const questionSerial = this.question?.serial
        const questionKeywords = questionSerial && keywordsModule.getters.getKeywordDefinitions().get(questionSerial)

        if (
            !this.answer
            || !questionKeywords
        ) {
            return []
        }

        return Array.from(questionKeywords, ([ keyword, definition ]) => ({
            keyword,
            definition,
        }))
    }

    /********* Start Bonus Question Experiment Getters *********/
    get bonusQuestion () {
        const examGuid = this.currentExam?.examGuid
        if (!examGuid) {
            return null
        }

        const examGuidQuestionLibString = import.meta.env.VUE_APP_BONUS_QUESTION_EXPERIMENT_EXAM_GUID_QUESTION_LIB
        const examGuidQuestionLib = JSON.parse(examGuidQuestionLibString || '{}') as {
            [examGuid: string]: {
                prompt: string
                answer: string
                explanation: string
                reference: string
            }
        }
        return examGuidQuestionLib[examGuid]
    }

    get isBonusQuestionExperimentActive () {
        // Check if current date is within experiment date range
        const currentDate = new Date()
        const dateRangeString = import.meta.env.VUE_APP_BONUS_QUESTION_EXPERIMENT_DATE_RANGE
        const dateRange = JSON.parse(dateRangeString || '[]') as [string, string]
        const [ startDate, endDate ] = dateRange.map(dateString => new Date(dateString))
        if (currentDate < startDate || currentDate > endDate) {
            return false
        }

        // Check if we have a current exam
        if (!this.currentExam?.examGuid) {
            return false
        }

        // Check if we have a bonus experiment question for the current exam
        if (!this.bonusQuestion) {
            return false
        }

        return true
    }

    get showBonusQuestion () {
        if (this.disableExperiment) {
            return false
        }
        if (!this.bonusQuestion) {
            return false
        }
        
        return this.bonusQuestionVariantValue === 'short-answer-question'
    }

    get showUserExplanationField () {
        if (this.disableExperiment) {
            return false
        }

        return this.bonusQuestionVariantValue === 'explanation-answer-question'
    }

    get isFlagDisabled () {
        return this.showBonusQuestion
    }

    get isShareDisabled () {
        return this.showBonusQuestion
    }

    get bonusQuestionUserText () {
        return this.bonusQuestionVariantValue === 'short-answer-question'
            ? this.userShortAnswer
            : this.userExplanation
    }
    /********* End Bonus Question Experiment Getters *********/

    async mounted () {
        await Promise.all([
            bundleModule.actions.fetchBundles(),
            examMetadataModule.actions.fetchExamMetadata(),
            quizModule.actions.fetchAnsweredQuestions(),
            qotdModule.actions.fetchCurrentQotDMetric(),
            qotdModule.actions.fetchCurrentQotDQuestion(),
            userModule.actions.fetchUserData(),
        ])

        if (this.quizId && !this.question) {
            quizModule.actions.fetchAnsweredQuestions(this.quizId)
        }

        this.currentExam = examMetadataModule.getters.getCurrentExamMetadata()

        if (this.question && this.currentExam) {
            this.globalMetrics = await qotdModule.actions.fetchQotDMetric({ 
                examGuid: this.currentExam.examGuid, 
                serial: this.question.serial, 
            })

            await keywordsModule.actions.fetchKeywordDefinitions([ this.question.serial ])
        }

        // Setup Bonus Question Experiment
        if (this.isBonusQuestionExperimentActive) {
            const variant = await analyticsModule.actions.amplitudeFetchVariant('bonus-question-experiment')
            const variantValue = variant?.value as 'short-answer-question' | 'explanation-answer-question'
            this.bonusQuestionVariantValue = variantValue || null
            this.initializeBonusQuestionExperiment()
        }

        const questionSerial = this.question?.serial
        this.isFlagged = !!(questionSerial && this.flaggedQuestions.includes(questionSerial))

        this.isLoading = false
    }

    keywordClicked (params?: { 
        keyword: string
        location: string
        clickLocation: { x: number; y: number}
        target: HTMLElement
    }) {
        this.showKeywordDefinition = false
        if (!params?.keyword) {
            return
        }

        const questionKeywords = this.question
        && keywordsModule.getters.getKeywordDefinitions().get(this.question.serial)
        const definition = questionKeywords?.get(params.keyword.toLowerCase())

        if (!definition) {
            return
        }

        analyticsModule.actions.amplitudeTrack('Keyword Definition Viewed', {
            examGuid: this.currentExam?.examGuid,
            definitionKeyword: params.keyword,
            serial: this.question?.serial,
            location: params.location,
            quizMode: -1,
            studyMode: this.$route.params.quizId ? 'Review' : 'Active Quiz',
        })

        this.visibleKeywordDefinition = {
            keyword: params.keyword, 
            definition,
            location: params.location,
        }
        this.keywordDOMElement = params.target
        this.keywordClickLocation = params.clickLocation

        this.$nextTick(() => {
            this.showKeywordDefinition = true
        })
    }

    toggleGlobalMetrics (newVal: boolean) {
        this.showGlobalMetrics = newVal
    }

    async toggleFlag () {
        this.isFlagged = !this.isFlagged
        const serial = this.question?.serial

        try {
            await userExamMetadataModule.actions.toggleQuestionFlag(serial)
        } catch (e) {
            // noop
        } finally {
            this.isFlagged = !!(serial && this.flaggedQuestions.includes(serial))
        }
    }

    toggleKeyboardShortcuts (newVal: boolean) {
        userModule.actions.updateQuizSettings({
            enableKeyboardShortcuts: newVal,
        })
    }

    async submitQotD (answer: Study.Cloud.IQuizAnswer) {
        // Prevent duplicate submission
        if (this.isSubmitLoading) {
            return
        }
        this.isSubmitLoading = true
        if (answer.isCorrect) {
            this.isCorrect = answer.isCorrect
        }
        // Store the bonus question experiment answer before submitting the quiz
        if (this.showUserExplanationField) {
            const examGuid = this.currentExam?.examGuid
            localStorage.setItem(`bonusQuestionExperiment_${examGuid}_explanationAnswer`, this.userExplanation)
            analyticsModule.actions.amplitudeTrack('Bonus Question Answered', {
                bonusQuestionText: this.userExplanation,
                bonusQuestionVariation: 'explanationAnswerQuestion',
            })
        }
        await qotdModule.actions.recordQotD(answer)
        this.isSubmitLoading = false

        // Show the bonus question experiment survey 3 seconds after submitting the quiz
        if (this.showUserExplanationField) {
            setTimeout(() => {
                this.showSurvey = true
            }, 3000)
        }
    }

    quitQuiz () {
        if (this.fromName && this.fromName !== 'shared-qotd') {
            this.$router.back()
        } else {
            this.$router.push({ name: 'study' })
        }
    }

    /********* Start Bonus Question Experiment Methods *********/
    selectChoices (answer: Study.Cloud.IQuizAnswer) {
        this.selectedChoices = answer.selectedChoices
    }

    initializeBonusQuestionExperiment () {
        const isBonusQuestionComplete = localStorage.getItem('bonusQuestionExperimentComplete')
        if (this.bonusQuestionVariantValue === 'explanation-answer-question') {
            const examGuid = this.currentExam?.examGuid
            const localStorageAnswer = localStorage.getItem(`bonusQuestionExperiment_${examGuid}_explanationAnswer`)
            if (localStorageAnswer) {
                this.userExplanation = localStorageAnswer
                this.disableExperiment = false  // Enable to show user their previous answer
            } else if (localStorageAnswer === null && this.answer) {
                this.disableExperiment = true   // Disable if qotd answered outside of experiment
            } else if (isBonusQuestionComplete) {
                this.disableExperiment = true   // Disable if already participated in experiment
            } else {
                this.disableExperiment = false  // Enable if qotd unanswered and experiment still incomplete
            }
        } else if (this.bonusQuestionVariantValue === 'short-answer-question') {
            const examGuid = this.currentExam?.examGuid
            const localStorageAnswer = localStorage.getItem(`bonusQuestionExperiment_${examGuid}_shortAnswer`)
            if (localStorageAnswer === null && this.answer) {
                this.disableExperiment = true   // Disable if qotd answered outside of experiment
            } else if (isBonusQuestionComplete) {
                this.disableExperiment = true   // Disable if already participated in experiment
            } else {
                this.disableExperiment = false  // Enable if qotd unanswered and experiment still incomplete
            }
        }
    }

    skipBonusQuestion () {
        localStorage.setItem('bonusQuestionExperimentComplete', 'true')
        this.disableExperiment = true
        this.isCommunityStatsDisabled = !this.answer
    }

    updateUserShortAnswer (userAnswer: string) {
        this.userShortAnswer = userAnswer
    }

    submitSurvey () {
        this.showSurvey = false
        toastModule.actions.displayToast({
            title: 'Thank you for your feedback!',
        })
        this.completeBonusQuestionExperiment()
    }

    closeSurvey () {
        this.showSurvey = false
        this.completeBonusQuestionExperiment()
    }

    completeBonusQuestionExperiment () {
        localStorage.setItem('bonusQuestionExperimentComplete', 'true')
        if (this.bonusQuestionVariantValue === 'short-answer-question') {
            this.disableExperiment = true
        }
        this.isCommunityStatsDisabled = !this.answer
    }
    /********* End Bonus Question Experiment Methods *********/

    explanationViewToggled (showExplanation: boolean) {
        if (showExplanation) {
            analyticsModule.actions.amplitudeTrack(
                'Explanation Opened',
                {
                    examGuid: this.currentExam?.examGuid,
                    serial: this.question?.serial,
                    quizMode: 'qotd',
                    platform: 'Web',
                }
            )
        }
    }
}
</script>

<style lang="scss" scoped>
.qotd {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    :deep(.uikit-quiz-container__main) {
        @include breakpoint(black-bear) {
            height: calc(100% - 55px);
        }
    }

    :deep(.uikit-quiz-container__question--mobile) {
        @include breakpoint(black-bear) {
            overflow: auto;
        }
    }

    :deep(.uikit-quiz-container__header--mobile) {
        @include breakpoint(black-bear) {
            height: 1px;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(black-bear) {
            justify-content: space-around;
            margin: 0 auto;
            width: 375px;
        }
    }

    &__footer-left {
        display: flex;
        align-items: center;
    }

    &__global-metrics-toggle {
        margin-right: 24px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;

        @include breakpoint(black-bear) {
            margin-right: 0;
        }

        &--active {
            background: $black-feather;
        }
    }

    &__share {
        &--mobile {
            display: none;

            @include breakpoint(black-bear) {
                display: block;
            }
        }

        &--desktop {
            display: block;

            @include breakpoint(black-bear) {
                display: none;
            }
        }
    }

    &__exam-info {
        position: absolute;
        left: -10px;
        width: 320px;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__quit-btn {
        position: absolute;
        right: 0;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__question {
        height: 100%;

        &--correct{
            :deep(.uikit-question-choices-container__choice-container--correct) {
                &::after {
                    @media (prefers-reduced-motion: reduce) {
                        animation: none;
                    }

                    content: '';
                    border: 2px solid $cadaverous;
                    @keyframes fadeIt {
                        0%  { box-shadow: 0px 0px 2px 2px rgba($cadaverous, 0.8); }
                        25%  { box-shadow: 0px 0px 4px 3px rgba($cadaverous, 0.6); }
                        50%  { box-shadow: 0px 0px 6px 5px rgba($cadaverous, 0.5); }
                        75%  { box-shadow: 0px 0px 8px 7px rgba($cadaverous, 0.4); }
                        90%  { box-shadow: 0px 0px 10px 8px rgba($cadaverous, 0.3); }
                        100%  { box-shadow: 0px 0px 11px 10px rgba($cadaverous, 0.2); }
                    }
                    animation: fadeIt .75s ease-in-out;
                }
            }
        }
    }

    &__controls {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @include breakpoint(black-bear) {
            position: relative;
            left: auto;
            transform: initial;
        }
    }

    &__flag-toggle {
        margin: 0 80px;

        @include breakpoint(black-bear) {
            margin: 0;
        }
    }

    &__keyboard-shortcuts-btn {
        position: absolute;
        right: 0;

        @include breakpoint(black-bear) {
            display: none;
        }
    }
}
</style>