import { qotdModule } from '@/store/qotd/module'
import { quizModule } from '@/store/quiz/module'
import { bundleModule } from '@/store/bundle/module'
import { buildUTMParamString, isPromise } from '@/store/utils'

const getQotDSharingInfo = ({ bundleId, examGuid, version, userId, examName }: {
    bundleId: string
    examGuid: string
    version: string
    userId: string
    examName: string
}) => {
    const bundleBrandLib = bundleModule.getters.getBundleBrandLib()
    const bundleBrandInfo = bundleBrandLib[bundleId]
    const name = bundleBrandInfo?.universalLinkName || 'web'
    
    const queryParamString = `?examGuid=${examGuid}&version=${version}&userId=${userId}&bundleId=${bundleId}`

    return {
        title: `Can you answer today's ${(examName)} question?`,
        hashtags: 'itsyourturnnow,pocketprep',
        url: `https://pocketprep.com/share/${name}/qotd${queryParamString}&${buildUTMParamString({
            utm_campaign: 'app_share',
            utm_content: 'qotd',
        })}`,
    }
}

const getQotDQuestion = () => {
    const question = qotdModule.state.question.value

    if (isPromise(question)) {
        return null
    } else {
        return question
    }
}

const getQotDMetric = () => {
    const metric = qotdModule.state.globalMetric.value

    if (isPromise(metric)) {
        return null
    } else {
        return metric
    }
}

// Get today's submitted QotD quiz, if there is one
const getQotDQuiz = () => {
    const question = getQotDQuestion()

    if (!question) {
        return null
    }

    return quizModule.state.quizzes
        .filter(quiz =>
            quiz.mode === -1
            && quiz.answers[0]?.questionSerial === question.serial
            && Math.abs(new Date(quiz.startedAt.iso).getTime() - Date.now()) <= 86400000
        )[0] || null
}

export default {
    getQotDQuestion,
    getQotDMetric,
    getQotDQuiz,
    getQotDSharingInfo,
}
