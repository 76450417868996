import type { Study } from '@pocketprep/types'
import { initLoadable } from '@/store/utils'
import type { Loadable } from '@/store/utils'
import { type Stripe } from 'stripe'

type TSubscriptionState = {
    subscriptions: Study.Class.SubscriptionJSON[]
    stripeSubscriptions: Loadable<Stripe.Subscription[] | null>
}

export const getInitialSubscriptionState = (): TSubscriptionState => {
    return {
        subscriptions: [],
        stripeSubscriptions: initLoadable(null),
    }
}

const moduleState = getInitialSubscriptionState()

export default moduleState
