<!-- eslint-disable -->
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="61" height="21" viewBox="0 0 61 21" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M59.7031 1.16381L52.1966 0.853133L45.2031 0.817271L43.0381 0.831082L39.6964 0.860158L35.7312 0.908585L32.7285 0.956994L30.2245 1.00641L26.8474 1.08841L24.6234 1.15382L22.5145 1.22581L20.7986 1.29277L19.4333 1.35226L18.3834 1.40228L16.303 1.51088L14.9298 1.59054L13.4007 1.69027L12.2215 1.77817L11.3381 1.85244L10.506 1.93088L9.91458 1.99278L9.35043 2.0576L8.98911 2.10256L8.63936 2.149L8.13597 2.22159L7.65742 2.29792L7.203 2.37825L6.91312 2.43414L6.49734 2.52167L6.23254 2.58259L5.97741 2.64565L5.73173 2.71093C5.69156 2.72199 5.65178 2.73316 5.61237 2.74442L5.38049 2.81315L5.15752 2.88429L4.94327 2.95789C4.90828 2.97037 4.87364 2.98295 4.83934 2.99564L4.63777 3.07309L4.44438 3.1532C4.41282 3.16677 4.38159 3.18046 4.35069 3.19427L4.16917 3.27849C4.13956 3.29277 4.11027 3.30716 4.0813 3.32167L3.91119 3.41018L3.74841 3.50164C3.72188 3.51713 3.69565 3.53275 3.66971 3.5485L3.51755 3.64452C3.49276 3.66078 3.46825 3.67717 3.44403 3.6937L3.30199 3.79445C3.27887 3.81151 3.25601 3.8287 3.23342 3.84603L3.10105 3.95167C2.88562 4.13051 2.6959 4.32332 2.5275 4.53163C0.590271 6.92792 0.432862 9.16955 1.50397 13.2043C2.45769 16.7969 3.95099 21.2154 5.75381 19.7977L5.80599 19.7547L5.96131 19.7021L6.078 19.6663L6.37566 19.5851L6.72861 19.5021L7.02436 19.4402L7.3464 19.3786L7.81623 19.2975L8.33168 19.2176L8.9975 19.1254L9.54887 19.0565L10.1343 18.9893L10.9137 18.9078L11.5755 18.8446L12.4503 18.768L13.7638 18.6655L15.1806 18.5686L16.9261 18.4646L18.8391 18.3663L20.4428 18.2944L21.5592 18.2491L23.6038 18.1749L25.764 18.1072L27.3778 18.0628L29.7362 18.0063L34.0481 17.9246L38.2989 17.8666L41.5697 17.8345L47.8934 17.8608C53.1432 17.9971 56.0418 18.0802 56.5892 18.1103C57.1366 18.1403 57.6244 18.2429 58.0526 18.418C58.8895 18.4191 59.268 18.358 59.6305 16.8414C60.2263 14.3486 60.4893 9.54686 60.5326 6.62924C60.6023 1.93655 61.9156 1.16676 59.7031 1.16381Z" fill="#FFF2D9"/>
    </svg>
</template>
<!-- eslint-enable -->

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class DiscountHighlight extends Vue {
}
</script>