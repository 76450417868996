<template>
    <Portal to="modal">
        <ModalContainer key="signInModal">
            <div class="sign-in">
                <Blob class="sign-in__blob" />
                <div class="sign-in__header">
                    <img
                        class="sign-in__logo"
                        src="@/assets/logos/logo.svg"
                        alt="Pocket Prep Logo"
                    >
                </div>
                <Errors
                    v-if="!isLoading && referralErrors"
                    :errors="[ referralErrors ]"
                    class="sign-in__referral-link-error"
                />
                <h1
                    tabindex="-1"
                    class="sign-in__title"
                    :class="{
                        'sign-in__title--password': showPasswordSignIn,
                        'sign-in__title--error': errorMessage,
                        'sign-in__title--referral': validReferral,
                        'sign-in__title--referral-error': referralErrors
                    }"
                >
                    Sign In
                </h1>
                <div 
                    v-if="validReferral"
                    class="sign-in__referral"
                >
                    <template v-if="'name' in validReferral && validReferral.name">
                        <p class="sign-in__referral-coupon">
                            {{ validReferral.name }}
                        </p>
                    </template>
                    <template v-else-if="'objectId' in validReferral">
                        <p class="sign-in__referral-percentage-off">
                            Someone sent you
                            <span class="sign-in__referral-percent-span">20% off<ReferralHighlight /></span>
                            your first
                        </p>
                        <div class="sign-in__referral-celebrate">
                            <p class="sign-in__referral-celebrate-paragraph">
                                subscription with Pocket Prep. Sweet!
                            </p>
                            <img 
                                class="sign-in__referral-celebrate-img" 
                                src="@/assets/welcomeSeries/celebrate-icon.png" 
                                alt=""
                            >
                        </div>
                    </template>
                </div>
                <div 
                    v-if="!showPasswordSignIn" 
                    class="sign-in__description"
                    :class="{
                        'sign-in__description--referral': validReferral
                    }"
                >
                    We’ll email you a link to sign in password-free
                </div>
                <PocketInput
                    v-model="email"
                    class="sign-in__email"
                    :class="{
                        'sign-in__email--password': showPasswordSignIn,
                    }"
                    field-type="email"
                    label="Email"
                    :error="emailError"
                    @keydown.enter="showPasswordSignIn ? signInWithPassword() : sendMagicEmail()"
                />
                <PocketInput
                    v-if="showPasswordSignIn"
                    ref="passwordInput"
                    v-model="password"
                    class="sign-in__password"
                    :class="{
                        'sign-in__password--error': errorMessage,
                    }"
                    field-type="password"
                    label="Password"
                    :error="passwordError"
                    @keydown.enter="showPasswordSignIn && signInWithPassword()"
                />
                <Errors
                    v-if="!isLoading && errorMessage"
                    class="sign-in__error-message"
                    :errors="[ errorMessage ]"
                />
                <div
                    v-if="showPasswordSignIn"
                    class="sign-in__password-options"
                    :class="{
                        'sign-in__password-options--error': errorMessage
                    }"
                >
                    <RouterLink
                        v-slot="{ href, navigate }"
                        custom
                        :to="{
                            name: 'sign-in',
                            query: {
                                ...$route.query,
                                type: undefined,
                            }
                        }"
                    >
                        <PocketLink
                            class="sign-in__use-magic-link-btn"
                            type="tertiary-small"
                            :disabled="isLoading"
                            :href="href"
                            @click="navigate"
                        >
                            Sign in with a link
                        </PocketLink>
                    </RouterLink>
                    <PocketButton
                        class="sign-in__password-submit"
                        :disabled="!email || !password"
                        :is-loading="isLoading"
                        @click="signInWithPassword"
                    >
                        <span class="sign-in__password-submit-text">Sign In</span>
                        <Icon class="sign-in__password-submit-arrow" type="arrow" />
                    </PocketButton>
                </div>
                <div
                    v-else
                    class="sign-in__magic-email-options"
                    :class="{
                        'sign-in__magic-email-options--error': errorMessage
                    }"
                >
                    <RouterLink
                        v-slot="{ href, navigate }"
                        custom
                        :to="{
                            name: 'sign-in',
                            query: {
                                ...$route.query,
                                type: 'password',
                            }
                        }"
                    >
                        <PocketLink
                            class="sign-in__use-password-btn"
                            type="tertiary-small"
                            :disabled="isLoading"
                            :href="href"
                            @click="navigate"
                        >
                            Sign in with password
                        </PocketLink>
                    </RouterLink>
                    <PocketButton
                        class="sign-in__magic-submit"
                        :disabled="!email"
                        :is-loading="isLoading"
                        @click="sendMagicEmail"
                    >
                        <span class="sign-in__magic-submit-text">Send Link</span>
                        <Icon class="sign-in__magic-submit-arrow" type="arrow" />
                    </PocketButton>
                </div>
                <div class="sign-in__footer">
                    <span aria-hidden="true">New?</span>
                    <RouterLink
                        v-slot="{ href, navigate }"
                        custom
                        :to="{
                            name: 'register',
                            query: {
                                ...$route.query
                            }
                        }"
                    >
                        <PocketLink
                            class="sign-in__create-account-link"
                            type="tertiary-small"
                            :href="href"
                            @click="navigate"
                        >
                            Create an account
                        </PocketLink>
                    </RouterLink>
                    <span class="sign-in__footer-middot" aria-hidden="true">•</span>
                    <RouterLink
                        v-if="showPasswordSignIn"
                        v-slot="{ href, navigate }"
                        custom
                        :to="{ name: 'forgot-password' }"
                    >
                        <PocketLink
                            type="tertiary-small"
                            class="sign-in__help-link"
                            :href="href"
                            @click="navigate"
                            @mousedown.prevent
                        >
                            Forgot password
                        </PocketLink>
                    </RouterLink>
                    <PocketLink
                        v-else
                        type="tertiary-small"
                        class="sign-in__help-link"
                        @click="showIntercom"
                        @keypress.enter="showIntercom"
                        @mousedown.prevent
                    >
                        I need help
                    </PocketLink>
                </div>
            </div>
        </ModalContainer>
    </Portal>
    <Portal v-if="showUserDeletedModal" to="modal">
        <ModalContainer key="signInDeletionScheduledModal" @close="showUserDeletedModal = false">
            <template #modal>
                <Modal
                    class="sign-in__modal"
                    :show-close-button="true"
                    @close="showUserDeletedModal = false"
                >
                    <div class="sign-in__modal-title">
                        Account Scheduled for Deletion
                    </div>
                    <div class="sign-in__modal-message">
                        You have been signed out. Please allow for 2 weeks for your account data to be erased 
                        from our systems. If you would like to cancel this deletion please sign in again.
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import { isSupportWindow, isValidEmail } from '@/utils'
import Blob from '@/assets/signIn/Blob.vue'
import { licenseModule } from '@/store/license/module'
import { referralModule } from '@/store/referral/module'
import type { Study } from '@pocketprep/types'
import ReferralHighlight from '@/assets/signIn/ReferralHighlight.vue'

@Component({
    options: {
        beforeRouteUpdate (this: SignIn, to, from, next) {
            this.password = ''
            this.errorMessage = ''
            this.emailError = false
            this.passwordError = false
    
            let shouldFocusTitle = true
            if (to.query && to.query.type === 'password') {
                this.showPasswordSignIn = true
                if (this.email) {
                    shouldFocusTitle = false
                    setTimeout(() => {
                        const passwordInputRef = this.$refs.passwordInput as typeof UIKit.Input | undefined
                        const passwordInputEl = (passwordInputRef
                            && '$el' in passwordInputRef
                            && passwordInputRef.$el) as HTMLElement
                        const inputEl = passwordInputEl?.querySelector('input')
                        inputEl?.focus()
                    }, 1)
                }
            } else {
                this.showPasswordSignIn = false
            }
    
            if (shouldFocusTitle) {
                setTimeout(() => {
                    const titleEl = document.querySelector('.sign-in__title') as HTMLElement | null
                    titleEl?.focus()
                }, 500)
            }
    
            next()
        },
    },
    components: {
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
        PocketInput: UIKit.Input,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
        PocketLink: UIKit.Link,
        Errors: UIKit.Errors,
        Blob,
        ReferralHighlight,
    },
})
export default class SignIn extends Vue {
    email = ''
    password = ''
    showPasswordSignIn = false
    isLoading = false
    errorMessage = ''
    emailError = false
    passwordError = false
    showUserDeletedModal = false

    isValidEmail = isValidEmail

    get referralErrors () {
        return referralModule.getters.getReferralError()
    }

    get validReferral () {
        return referralModule.getters.getValidReferral()
    }

    async mounted () {
        // if user is logged in but license is in query param, test if license is valid for signed in user
        const user = userModule.state.user
        if (typeof this.$route.query.license === 'string' && user) {
            let validLicense: Study.Class.LicenseJSON | undefined
            try {
                validLicense = await licenseModule.actions.validateLicense(this.$route.query.license)
            } catch (e) {
                // noop
            }

            if (validLicense?.userEmail === user.email) {
                this.$router.push({
                    name: 'exams',
                    query: {
                        license: this.$route.query.license,
                    },
                })
            } else {
                await userModule.actions.signOut()
            }
        }

        if (typeof this.$route.query.error === 'string') {
            this.errorMessage = this.$route.query.error
        }

        if (typeof this.$route.query.email === 'string') {
            this.email = this.$route.query.email
        }

        if (this.$route.query && this.$route.query.type === 'password') {
            this.showPasswordSignIn = true
        }

        if (this.$route.query && this.$route.query.delete === 'success') {
            this.showUserDeletedModal = true
            this.$router.push({
                name: 'sign-in',
                query: {
                    ...this.$route.query,
                    delete: undefined,
                },
            })
        }

        // check to see if there is a valid referral in the URL
        await referralModule.actions.validateReferral()

        setTimeout(() => {
            const titleEl = document.querySelector('.sign-in__title') as HTMLElement | null
            titleEl?.focus()
        }, 500)
    }

    showIntercom () {
        if (import.meta.env.VUE_APP_INTERCOM_APP_ID && !isSupportWindow()) {
            window.Intercom('show')
        }
    }

    async validateForm () {
        this.emailError = false
        this.passwordError = false
        this.errorMessage = ''

        await new Promise(res => setTimeout(res, 200))

        if (!this.isValidEmail(this.email)) {
            this.errorMessage = 'Please enter a valid email address.'
            this.emailError = true
            return false
        }
        if (this.showPasswordSignIn && this.password.length < 6) {
            this.errorMessage = 'Passwords must be at least 6 characters.'
            this.passwordError = true
            return false
        }

        return true
    }

    async signInWithPassword () {
        this.isLoading = true
        if (await this.validateForm()) {
            try {
                await userModule.actions.signIn({
                    username: this.email,
                    password: this.password,
                })
                if (this.$route.query.redirect && typeof this.$route.query.redirect === 'string') {
                    const [ path, queryParams ] = this.$route.query.redirect.split('?')
                    const query = Object.fromEntries(new URLSearchParams(queryParams))
                    this.$router.push({ path, query })
                } else {
                    if (typeof this.$route.query.license === 'string') {
                        const firstName = this.$route.query.firstName
                        const lastName = this.$route.query.lastName
                        this.$router.push({
                            name: 'exams',
                            query: {
                                license: this.$route.query.license,
                                ...(typeof firstName === 'string' && { firstName }),  // Conditionally adds firstName
                                ...(typeof lastName === 'string' && { lastName }),  // Conditionally adds lastName
                            },
                        })
                    } else {
                        this.$router.push({ 
                            name: 'study',
                        })
                    }
                }
            } catch (err) {
                const error = (err as { error?: string }).error || (err as { message?: string }).message
                if (!err) {
                    this.errorMessage = 'Unable to sign in.'
                } else if (error) {
                    this.errorMessage = error
                } else if (err instanceof Error || typeof err === 'string') {
                    this.errorMessage = err.toString()
                }
            }
        }

        this.isLoading = false
    }

    async sendMagicEmail () {
        this.isLoading = true

        if (await this.validateForm()) {
            try {
                const redirect = await userModule.actions.sendMagicEmail({ 
                    email: this.email,
                    route: this.$route,
                })
                this.$router.push({
                    name: 'email-auth',
                    query: {
                        email: this.email.toLowerCase(),
                        redirect,
                    },
                })
            } catch (err) {
                this.errorMessage = 'We weren\'t able to send the link.'
            }
        }

        this.isLoading = false
    }
}
</script>

<style lang="scss" scoped>
.sign-in {
    position: relative;
    box-sizing: border-box;
    background-color: $floral-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 17px 18px 18px 18px;
    width: 661px;
    min-height: 490px;

    @include breakpoint(black-bear) {
        width: 100%;
        min-height: 100%;
        padding: 28px 18px 47px 18px;
    }

    &__modal {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 80px;
        width: 392px;
        padding: 35px 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include breakpoint(black-bear) {
            width: 356px;
            height: auto;
            max-height: 638px;
            top: 10px;
        }
    }

    &__modal-title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        width: 309px;
        margin: 0 auto 12px;
    }

    &__modal-message {
        font-size: 15px;
        line-height: 22px;
        color: $slate-03;
        width: 309px;
    }

    &__blob {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 122px;
        height: 189px;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__header {
        width: 100%;
        height: 27px;
        margin-bottom: 58px;

        @include breakpoint(black-bear) {
            display: flex;
            justify-content: center;
            height: 34px;
            margin-bottom: 66px;
        }
    }

    &__referral-link-error {
        width: 500px;

        @include breakpoint(black-bear) {
            max-width: 300px;
        }
    }

    &__title {
        font-size: 36px;
        line-height: 46px;
        font-weight: 700;
        margin-bottom: 17px;
        margin-top: 0;
        outline: none;

        @include breakpoint(black-bear) {
            margin-bottom: 14px;
        }

        &--password {
            margin-bottom: 40px;
        }

        &--password#{&}--error {
            margin-bottom: 27px;
        }

        &--referral {
            margin-bottom: 14px;

            @include breakpoint(black-bear) {
                margin-bottom: 22px;
            }
        }

        &--referral-error {
            margin-top: 26px;

            @include breakpoint(black-bear) {
                margin-top: 24px;
            }
        }
    }

    &__referral {
        margin-bottom: 30px;

        @include breakpoint(black-bear) {
            margin-bottom: 44px;
        }
    }

    &__referral-percentage-off,
    &__referral-coupon {
        color: $ash;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 16px;
        line-height: 25px;
        padding-left: 20px;
        font-weight: 500;
    }

    &__referral-coupon {
        padding-left: 0;
    }

    &__referral-celebrate {
        flex-direction: row;
    }

    &__referral-celebrate-paragraph {
        color: $ash;
        float: left;
        margin-top: auto;
        font-size: 16px;
        line-height: 25px;
        padding-left: 8px;
        font-weight: 500;
        margin-right: 5px;
        margin-bottom: 0;
    }

    &__referral-celebrate-img {
        float: right;
        height: 18px;
    }

    &__referral-percent-span {
        color: $brand-black;
        z-index: 1;
        position: relative;
        font-weight: 600;

        svg {
            color: $buttermilk;
            position: absolute;
            left: -2px;
            top: -4px;
            width: calc(100% + 5px);
            z-index: -2;
        }
    }

    &__logo {
        width: 120px;
        height: 27px;

        @include breakpoint(black-bear) {
            width: 153px;
            height: 34px;
        }
    }

    &__description {
        width: 336px;
        min-height: 22px;
        text-align: center;
        color: $slate-03;
        line-height: 19px;
        margin-bottom: 41px;
        font-weight: 500;

        @include breakpoint(black-bear) {
            width: 275px;
            margin-bottom: 34px;
        }

        &--referral {
            text-align: center;
            margin-bottom: 30px;
            font-size: 16px;

            @include breakpoint(black-bear) {
                width: 275px;
                margin-right: 15px;
                text-align: center;
            }
        }
    }

    &__email {
        width: 340px;
        margin-bottom: 24px;

        &--password {
            margin-bottom: 21px;
        }

        @include breakpoint(black-bear) {
            width: 296px;
        }
    }

    &__password {
        width: 340px;
        margin-bottom: 24px;

        &--error {
            margin-bottom: 20px;
        }

        @include breakpoint(black-bear) {
            width: 296px;
        }
    }

    &__error-message {
        width: 292px;
        margin-bottom: 29px;

        @include breakpoint(black-bear) {
            width: 248px;
        }
    }

    &__password-options {
        margin-bottom: 91px;
        display: flex;
        align-items: center;

        &--error {
            margin-bottom: 73px;
        }

        @include breakpoint(black-bear) {
            margin-bottom: 92px;

            &--error {
                margin-bottom: 44px;
            }
        }
    }

    &__magic-email-options {
        margin-bottom: 128px;
        display: flex;
        align-items: center;

        &--error {
            margin-bottom: 73px;
        }

        @include breakpoint(black-bear) {
            padding-left: 10px;
            width: 296px;
            margin-bottom: 129px;

            &--error {
                margin-bottom: 64px;
            }
        }
    }

    &__use-password-btn,
    &__use-magic-link-btn {
        margin-right: 31px;

        @include breakpoint(black-bear) {
            margin-right: 24px;
        }
    }

    &__password-submit,
    &__magic-submit {
        display: inline-flex;
    }

    &__password-submit-text {
        width: 51px;
    }

    &__magic-submit-text {
        width: 64px;
    }

    &__password-submit-arrow {
        width: 12px;
        margin-top: 4px;
        margin-left: 14px;
    }

    &__magic-submit-arrow {
        width: 12px;
        margin-top: 4px;
        margin-left: 10px;
    }

    &__footer {
        position: absolute;
        height: 19px;
        font-size: 14px;
        color: $slate-03;
        bottom: 18px;

        @include breakpoint(black-bear) {
            bottom: 44px;
        }
    }

    &__footer-middot {
        color: $steel;
        margin: 0 16px;
    }
}
</style>
