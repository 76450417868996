<template>
    <div v-dark class="study-progress">
        <ResetProgressModal
            v-if="showResetProgressModal"
            :is-loading="isLoading"
            @resetProgress="resetProgress"
            @close="showResetProgressModal = false"
        />
        <div v-dark class="study-progress__head">
            <div v-dark class="study-progress__title">
                <Icon type="questions" />
                <h2>Study Progress</h2>
            </div>
            <PocketLink
                v-if="bestAnswers.length"
                :is-dark-mode="isDarkMode"
                @click="showResetProgressModal = true"
            >
                Reset Progress
            </PocketLink>
        </div>
        <div class="study-progress__progress-container">
            <div v-dark class="study-progress__progress-left">
                {{ bestAnswers.length }}<span>/</span>{{ totalQuestionCount }}
            </div>
            <div class="study-progress__progress-right">
                <div v-dark class="study-progress__progress-percent">
                    {{ Math.round(((bestAnswers.length / totalQuestionCount) || 0) * 100) }}<span>%</span>
                </div>
                <div v-dark class="study-progress__progress-bar">
                    <div
                        v-dark 
                        class="study-progress__progress-bar-fill"
                        :class="{ 'study-progress__progress-bar-fill--100': bestAnswers.length === totalQuestionCount }"
                        :style="{ width: `${Math.round((bestAnswers.length / totalQuestionCount) * 100)}%`}"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { examMetadataModule } from '@/store/examMetadata/module'
import { quizModule } from '@/store/quiz/module'
import { subscriptionModule } from '@/store/subscription/module'
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component } from 'vue-facing-decorator'
import ResetProgressModal from '@/components/Stats/ResetProgressModal.vue'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'

@Component({
    components: {
        Icon: UIKit.Icon,
        PocketLink: UIKit.Link,
        PocketButton: UIKit.Button,
        ResetProgressModal,
    },
})
export default class StudyProgress extends Vue {
    showResetProgressModal = false
    isLoading = false

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get hasSubscription () {
        return subscriptionModule.getters.getSubscriptionForExamId()
    }

    get bestAnswers () {
        return Object.values(
            quizModule.getters.getLatestAnswers({ questionFilter: this.hasSubscription ? 'all' : 'free' })
        )
    }

    get examMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get totalQuestionCount () {
        const hasSubscription = subscriptionModule.getters.getSubscriptionForExamId()

        if (hasSubscription) {
            return ((this.examMetadata?.itemCount || 0) - (this.examMetadata?.archivedCount || 0))
        }

        return this.examMetadata?.specialQuestions || 0
    }

    mounted () {
        examMetadataModule.actions.fetchExamMetadata()
        userModule.actions.fetchUserData()
    }

    async resetProgress () {
        if (!this.isLoading && this.examMetadata) {
            this.isLoading = true
            await userExamMetadataModule.actions.resetExamProgress(this.examMetadata)
            this.showResetProgressModal = false
            this.isLoading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.study-progress {
    background: $white;
    border-radius: 6px;
    padding: 3px 16px 12px;
    box-sizing: border-box;
    height: 95px;
    position: relative;

    @include breakpoint(black-bear) {
        padding: 4px 10px 0;
        height: 93px;
    }

    &--dark {
        background: rgba($white, 0.02);
        color: $fog;

        @include breakpoint(black-bear) {
            background: $brand-black;
        }
    }

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 38px;
        border-bottom: 1px solid $gray-divider;
        padding-left: 8px;

        &--dark {
            border-color: $pewter;
        }
    }

    &__title {
        display: flex;
        align-items: center;

        svg {
            height: 22px;
            margin-right: 8px;
            color: $slate;
        }

        &--dark svg {
            color: $pewter;
        }

        h2 {
            margin: 0;
            font-size: 16px;
            line-height: 22px;
            font-weight: 600;
        }
    }

    &__progress-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 19px;
        margin-left: 2px;
        padding: 0 9px;
        box-sizing: border-box;

        @include breakpoint(black-bear) {
            margin-top: 18px;
        }
    }

    &__progress-left {
        font-size: 16px;
        letter-spacing: -0.3px;
        line-height: 15px;
        font-weight: 400;
        color: $slate-03;
        width: 100px;

        &--dark {
            color: $white;
        }

        span {
            font-size: 16px;
            line-height: 15px;
            letter-spacing: 0.43px;
            margin: 0 2px;
            color: $pewter;
        }
    }

    &__progress-right {
        display: flex;
        align-items: center;
        flex: 1;
    }

    &__progress-percent {
        font-weight: 600;
        letter-spacing: -0.3px;
        line-height: 15px;
        font-size: 18px;
        margin-right: 10px;

        &--dark {
            color: $white;
        }

        span {
            letter-spacing: 0.48px;
            font-weight: 400;
            margin-left: 2px;
        }
    }

    &__progress-bar {
        width: calc(100% - 2px);
        border-radius: 2px;
        background: $pearl;
        height: 12px;
        position: relative;
        border: 1px solid rgba($pewter, 0.85);

        &--dark {
            background: $moonlit-ocean;
            border-color: $slate;
        }
    }

    &__progress-bar-fill {
        background: $brand-black;
        height: 14px;
        position: absolute;
        left: -1px;
        top: -1px;
        border-radius: 2px;

        &--dark {
            background: $pewter;
        }

        &--100 {
            background: url(@/assets/stats/progress-easter-egg.svg) no-repeat center center;
            background-size: cover;
            width: 100% !important;
        }
    }
}
</style>