<template>
    <Portal to="modal">
        <ModalContainer
            key="exitExamModal"
            :show-close-button="true"
            :is-dark-mode="isDarkMode"
            @close="emitClose"
        >
            <template #modal>
                <Modal
                    :show-close-button="true"
                    class="exit-exam__modal"
                    :is-dark-mode="isDarkMode"
                    @close="emitClose"
                >
                    <div class="exit-exam">
                        <h1
                            ref="exit-exam__title"
                            class="exit-exam__title"
                            tabindex="-1"
                        >
                            Exit Exam?
                        </h1>
                        <div
                            v-if="numAnswers === quizLength"
                            v-dark
                            class="exit-exam__text"
                        >
                            You've answered {{ numAnswers }} of {{ quizLength }} questions.
                            You will lose your progress if you quit now. Are you sure you want to exit?
                        </div>
                        <div
                            v-else
                            v-dark
                            class="exit-exam__text"
                        >
                            You've answered {{ numAnswers }} of {{ quizLength }} questions.
                            You will lose your progress if you quit now.
                            Submit now for partial credit.
                        </div>
                        <img
                            v-if="!isDarkMode"
                            src="@/assets/review/brain.png"
                            alt=""
                            class="exit-exam__brain"
                        >
                        <img
                            v-else-if="isDarkMode"
                            src="@/assets/review/brain-dm.png"
                            alt=""
                            class="exit-exam__brain"
                        >
                        <div class="exit-exam__actions">
                            <PocketButton
                                v-if="numAnswers === quizLength"
                                type="secondary"
                                class="exit-exam__submit-btn"
                                :is-dark-mode="isDarkMode"
                                @click="emitSubmit"
                            >
                                Submit Exam
                            </PocketButton>
                            <PocketButton
                                v-else-if="numAnswers"
                                type="secondary"
                                class="exit-exam__submit-btn"
                                :is-dark-mode="isDarkMode"
                                @click="emitSubmit"
                            >
                                Submit {{ numAnswers }} Question{{ numAnswers !== 1 ? 's' : '' }}
                            </PocketButton>
                            <PocketButton
                                class="exit-exam__end-btn"
                                :is-dark-mode="isDarkMode"
                                @click="emitExit"
                            >
                                Exit Exam
                            </PocketButton>
                        </div>
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'

@Component({
    components: {
        PocketButton: UIKit.Button,
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
    },
})
export default class ExitExamModal extends Vue {
    @Prop({ default: 0 }) numAnswers!: number
    @Prop({ default: 1 }) quizLength!: number

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    mounted () {
        this.$nextTick(() => {
            const titleEl = this.$refs['exit-exam__title'] as HTMLElement | undefined
            titleEl?.focus()
        })
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('submit')
    emitSubmit () {
        return true
    }

    @Emit('exit')
    emitExit () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.exit-exam {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 31px 26px 32px;
    width: 384px;
    margin: 0 auto;

    @include breakpoint(black-bear) {
        width: 340px;
        padding: 35px 0 26px 0;
        margin: 0 auto;
    }

    &__modal {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 94px;
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin: 0 0 12px 0;
        outline: none;
    }

    &__text {
        color: $slate-03;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 29px;

        &--dark {
            color: $white;
        }

        @include breakpoint(black-bear) {
            width: 287px;
        }
    }

    &__brain {
        position: relative;
        left: 10px;
        height: 121px;
        margin-bottom: 39px;
    }

    &__actions {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__submit-btn {
        margin-right: 13px;
        padding: 9px 15px 8px;

        @include breakpoint(black-bear) {
            margin-right: 11px;
        }
    }

    &__end-btn {
        padding: 9px 19px 8px;
    }
}

</style>