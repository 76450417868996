import { fetchLoadable } from '@/store/utils'
import { progressModule } from '@/store/progress/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import type { Study } from '@pocketprep/types'
import { runCloudFunction } from '@/store/parseUtils'
import { subscriptionModule } from '@/store/subscription/module'
import { Parse } from '@/store/parseUtils'
import { userModule } from '@/store/user/module'
import * as Sentry from '@sentry/browser'

const fetchProgress = async () => {
    await Promise.all([
        userExamMetadataModule.actions.fetchUserExamMetadata(),
        examMetadataModule.actions.fetchExamMetadata(),
        userModule.actions.fetchUserData(),
    ])
    
    await fetchLoadable(progressModule.state.progressByExamGuid, async () => {
        const uems = Object.values(userExamMetadataModule.getters.getUserExamMetadataByGuid())
        const currentUEM = userExamMetadataModule.getters.getCurrentUserExamMetadata()
        // NOTE: actively studying exam is calculated on the fly becvause we already have that data
        const quizzes = await new Parse.Query<Study.Class.Quiz>('Quiz')
            .notEqualTo('examGuid', currentUEM?.examGuid || '')
            .findAll()
        const examMetadata = examMetadataModule.getters.getExamMetadata()
        const examMetadataCompositeKeyLib = examMetadata.reduce((acc, e) => {
            acc[e.compositeKey.split('.')[0]] = e
            acc[e.examGuid] = e
            return acc
        }, {} as { [compositeKey: string ]: Study.Class.ExamMetadataJSON})

        // create array of examGuids with premium access
        const premiumExamGuids = uems.map(uem => {
            const compositeKey = `${uem.examGuid.toLowerCase()}/${uem.examVersion}`
            const foundExamMetadata = examMetadataCompositeKeyLib[compositeKey.split('.')[0]]
                || examMetadataCompositeKeyLib[uem.examGuid]
            if (!foundExamMetadata) {
                Sentry.captureException(new Error(`ExamMetadata not found for ${compositeKey}`))
                return false
            }
            const examId = uem.examVersion && foundExamMetadata.objectId
            const subscription = examId && subscriptionModule.getters.getSubscriptionForExamId(examId)
            
            // we also want to check is subscription return a boolean true. If the exam is free
            // during the call subscriptionModule.getters.getSubscriptionForExamId(examId) an
            // object is not return but a boolean of true is returned
            return typeof subscription === 'object' && uem.examGuid || subscription && uem.examGuid
        })

        // fetch all serial question info so we can filter out premium questions if user is free and verify questions
        // aren't archived
        const serialQuestionInfoLibs = await uems.reduce(async (acc, uem) => {
            // for performance, we don't get question info libs for the current exam
            if (uem.examGuid === currentUEM?.examGuid) {
                return acc
            }

            const compositeKey = `${uem.examGuid.toLowerCase()}/${uem.examVersion}`
            const lib = uem.examVersion && await runCloudFunction<Study.Cloud.fetchSerialQuestionInfoLibV2>(
                'fetchSerialQuestionInfoLib-v2', 
                { compositeKey }
            )

            lib && acc.then(obj => obj[uem.examGuid] = lib)

            return acc
        }, Promise.resolve(<Record<string, ReturnType<Study.Cloud.fetchSerialQuestionInfoLibV2>>>{}))

        // reduce quizzes into sets of serials to calculate total answered questions
        const answeredSerialsByExamGuid = quizzes.reduce((acc, q) => {
            if (!(q.get('examGuid') in acc)) {
                // use set for performance and free uniqueness
                acc[q.get('examGuid')] = new Set()
            }

            // using forEach because we're mutating the Set
            q.get('answers')?.forEach(a => {
                // checking if question serial is in lib verifies that question is not archived
                if (
                    serialQuestionInfoLibs[q.get('examGuid')]
                    && serialQuestionInfoLibs[q.get('examGuid')][a.questionSerial]
                    && (
                        premiumExamGuids.includes(q.get('examGuid'))
                        || serialQuestionInfoLibs[q.get('examGuid')][a.questionSerial].isFree
                    )
                    && !serialQuestionInfoLibs[q.get('examGuid')][a.questionSerial].isMockQuestion
                ) {
                    acc[q.get('examGuid')]?.add(a.questionSerial)
                }
            })

            return acc
        }, {} as { [examGuid: string]: Set<string> })

        // reduce userExamMetadata records down to answered and total question counts
        return uems.reduce((acc, uem) => {
            const compositeKey = `${uem.examGuid.toLowerCase()}/${uem.examVersion}`
            const exam = uem.examVersion && examMetadataCompositeKeyLib[compositeKey.split('.')[0]]

            acc[uem.examGuid] = {
                answered: answeredSerialsByExamGuid[uem.examGuid]?.size || 0,
                total: exam 
                    && (premiumExamGuids.includes(uem.examGuid) 
                        ? exam.itemCount - exam.archivedCount
                        : exam.specialQuestions) 
                    || 0,
            }

            return acc
        }, {} as { [examGuid: string]: { answered: number; total: number } })
    })
}

export default {
    fetchProgress,
}