<!-- eslint-disable -->
<template>
    <svg width="120px" height="189px" viewBox="0 0 120 189" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-939.000000, -426.000000)">
                <g transform="translate(398.101989, 125.000000)">
                    <g transform="translate(540.898011, 301.000000)">
                        <g transform="translate(0.000000, -0.000597)">
                            <path d="M22.7429701,189.001225 C7.58099002,164.102356 0,140.363826 0,117.785633 C0,101.512219 6.28128096,86.5082853 17.3003805,74.4931119 C26.3826234,64.5898778 48.2396248,54.3742695 64.3554264,52.378143 C86.4364129,49.64316 104.982534,49.2900285 119.99379,51.3187485 L120,179.648054 C119.54014,185.883501 115.709399,189.001225 108.507776,189.001225 C101.306153,189.001225 72.7178844,189.001225 22.7429701,189.001225 Z" fill="#FFF2D9"></path>
                            <path d="M119.99379,0.00122468506 L119.992555,170.442128 C32.4879841,114.473582 -32.325075,48.0577235 73.4944799,12.6438639 C90.8567854,6.83334762 106.16595,2.69092487 119.695075,0.000596648296 L119.99379,0.00122468506 Z" fill="url(#halftone-blue)" opacity="0.8"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
<!-- eslint-enable -->

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class Blob extends Vue {

}
</script>