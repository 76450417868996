import { toastModule } from '@/store/toast/module'

const displayToast = (toast: { title: string; subtext?: string }) => {
    toastModule.state.toast = toast
}

const clearToast = () => {
    toastModule.state.toast = null
}

export default {
    displayToast,
    clearToast,
}
