import { createApp, type Directive } from 'vue'
import '@/elk'
import Study from '@/Study.vue'
import { router } from '@/router'
import { SentryInit } from '@/sentry'
import { userModule } from '@/store/user/module'
import { analyticsModule } from '@/store/analytics/module'
import '@pocketprep/ui-kit/normalize'
import '@pocketprep/ui-kit/fonts'
import '@pocketprep/ui-kit/styles'
import './study.scss'

const app = createApp(Study)

analyticsModule.actions.initAmplitude()
SentryInit(app)

app.use(router)

import PortalVue from 'portal-vue'
app.use(PortalVue)

app.config.errorHandler = async (e) => {
    const error = e as Error | string
    if (error.toString().includes('Invalid session token')) {
        await userModule.actions.signOut()
        router.push({
            name: 'sign-in',
            query: {
                error: 'Your session has expired. Please sign in again to continue studying.',
            },
        }).catch(() => {
            // noop
        })
    } else {
        throw error
    }
}
export const dark: Directive<HTMLElement> = (el, { value }: { value?: boolean }) => {
    const isDarkMode = value === undefined
        ? userModule.state.settings.isDarkMode
        : value
    const classes = (typeof el.className === 'string' 
        ? el.className 
        : (el.className as unknown as { baseVal: string }).baseVal).split(' ')
    if (isDarkMode) {
        if (classes.find(c => c.endsWith('--dark'))) {
            return
        }
        classes.forEach(className => {
            el.classList.add(`${className}--dark`)
        })
    } else {
        classes.forEach(className => {
            if (className.includes('--dark')) {
                el.classList.remove(className)
            }
        })
    }
}
app.directive('dark', dark)

app.mount('#study')
