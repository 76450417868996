<!-- eslint-disable -->
<template>
    <svg width="32" height="37" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_260_8011)">
            <path d="M17.8079 5.29848L17.8099 5.29161L17.8123 5.28486C17.8862 5.07741 18.0711 4.92684 18.26 4.83058C18.4566 4.73038 18.6977 4.66747 18.9458 4.65879C19.2029 4.64979 19.4468 4.7 19.6282 4.81312C19.815 4.92959 19.9617 5.13845 19.9011 5.4028L19.9013 5.40286L19.8981 5.41434L18.6929 9.71527L18.6929 9.71527L18.6921 9.71802L18.6819 9.75292L18.6799 9.7598L18.6775 9.76654C18.5546 10.1119 18.2111 10.3754 17.7635 10.3935C17.5425 10.4025 17.3317 10.3439 17.2051 10.166C17.0887 10.0025 17.093 9.80484 17.1274 9.64788L17.7906 5.36533L17.7931 5.34912L17.7977 5.33338L17.8079 5.29848Z"
                  fill="currentColor"
                  :stroke="borderColor || 'white'"
                  stroke-width="0.5"/>
            <path d="M6.14846 13.6825L6.1485 13.6823L6.15711 13.6845L10.4897 14.8034L10.4897 14.8034L10.4913 14.8038L10.5386 14.8164L10.5386 14.8161L10.5489 14.8193C10.7461 14.8807 10.9088 15.0022 11.0084 15.156C11.1075 15.309 11.1517 15.5107 11.0681 15.7026C11.0124 15.8307 10.9205 15.9795 10.7354 16.0894C10.5571 16.1952 10.319 16.249 10.0007 16.249C9.82899 16.249 9.44885 16.195 9.00472 16.1225C8.54967 16.0481 7.99931 15.9492 7.46867 15.8505C6.93774 15.7518 6.4253 15.6531 6.04567 15.5791C5.85583 15.5421 5.69914 15.5113 5.58988 15.4897L5.46334 15.4646L5.43009 15.458L5.42155 15.4563L5.41938 15.4559L5.41883 15.4558L5.41868 15.4557L5.46756 15.2116L5.41863 15.4557L5.41091 15.4542L5.4033 15.4522L5.35608 15.4396L5.35602 15.4398L5.34575 15.4367C5.0605 15.3479 4.9637 15.0925 4.94566 14.8876C4.92756 14.6819 4.97617 14.459 5.04621 14.2826C5.21502 13.8575 5.69655 13.5825 6.14846 13.6825Z"
                  fill="currentColor"
                  :stroke="borderColor || 'white'"
                  stroke-width="0.5"/>
            <path d="M10.397 4.32274L10.3971 4.32265L10.4016 4.33083L13.9693 10.8147L13.9694 10.8147L13.9713 10.8184L13.9919 10.8573L13.9922 10.8572L13.9978 10.8692C14.1662 11.2339 14.1382 11.6873 13.7737 12.0313C13.5905 12.2042 13.3695 12.2857 13.1431 12.231C12.9335 12.1804 12.778 12.0263 12.6755 11.8577L8.66018 5.81362L8.6533 5.80326L8.64749 5.79227L8.62691 5.75331L8.62659 5.75348L8.62104 5.74146C8.51925 5.52102 8.54288 5.26191 8.62184 5.0353C8.70273 4.80315 8.85234 4.57121 9.05377 4.38293C9.26316 4.18722 9.50842 4.05807 9.74796 4.02965C9.99466 4.00039 10.252 4.08129 10.397 4.32274Z"
                  fill="currentColor"
                  :stroke="borderColor || 'white'"
                  stroke-width="0.5"/>
        </g>
        <g filter="url(#filter1_d_260_8011)">
            <path d="M17.4254 25.3257L16.0704 15.6846C16.0416 15.4795 16.2603 15.3303 16.4407 15.432L24.6657 20.0675C24.9773 20.2431 25.0091 20.6795 24.7263 20.8985L22.4983 22.6232C22.2926 22.7825 22.2434 23.0729 22.3852 23.291L26.3137 29.3365C26.4849 29.6001 26.3734 29.9545 26.082 30.0724L25.1278 30.4586C24.9003 30.5507 24.6396 30.4641 24.5125 30.2542L21.0471 24.5317C20.904 24.2955 20.5966 24.22 20.3604 24.363L18.1795 25.6838C17.8725 25.8697 17.4753 25.6811 17.4254 25.3257Z" fill="currentColor"/>
            <path d="M22.7279 22.9197L22.4983 22.6232L22.7279 22.9197L24.9558 21.195C25.4507 20.8119 25.3951 20.0481 24.8498 19.7408L16.6248 15.1053C16.1738 14.8511 15.627 15.2241 15.6991 15.7368L17.054 25.3778C17.1414 25.9999 17.8364 26.3299 18.3738 26.0045L20.5546 24.6838C20.6137 24.648 20.6906 24.6669 20.7263 24.726L24.1918 30.4484C24.4142 30.8158 24.8704 30.9674 25.2685 30.8062L26.2227 30.42C26.7326 30.2136 26.9279 29.5934 26.6281 29.1322L22.6996 23.0867C22.6642 23.0321 22.6765 22.9595 22.7279 22.9197Z"
                  :stroke="borderColor || 'white'"
                  stroke-width="0.75"/>
        </g>
        <defs>
            <filter id="filter0_d_260_8011"
                    x="0.690918"
                    y="0.775391"
                    width="23.4746"
                    height="22.2246"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha"
                               type="matrix"
                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                               result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.0318944 0 0 0 0 0.306877 0 0 0 0 0.520752 0 0 0 0.3 0"/>
                <feBlend mode="normal"
                         in2="BackgroundImageFix"
                         result="effect1_dropShadow_260_8011"/>
                <feBlend mode="normal"
                         in="SourceGraphic"
                         in2="effect1_dropShadow_260_8011"
                         result="shape"/>
            </filter>
            <filter id="filter1_d_260_8011"
                    x="11.3174"
                    y="11.6484"
                    width="19.8276"
                    height="24.5977"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha"
                               type="matrix"
                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                               result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.0313726 0 0 0 0 0.305882 0 0 0 0 0.521569 0 0 0 0.3 0"/>
                <feBlend mode="normal"
                         in2="BackgroundImageFix"
                         result="effect1_dropShadow_260_8011"/>
                <feBlend mode="normal"
                         in="SourceGraphic"
                         in2="effect1_dropShadow_260_8011"
                         result="shape"/>
            </filter>
        </defs>
    </svg>
</template>
<!-- eslint-disable -->

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator'

@Component
export default class ClickIcon extends Vue {
    @Prop() borderColor?: string
}
</script>