<template>
    <div v-dark class="subject-card">
        <div class="subject-card__percent">
            <template v-if="subject.answeredCount">
                {{ Math.round((subject.correctCount / subject.answeredCount) * 100) }}%
            </template>
            <template v-else>
                - -%
            </template>
        </div>
        <div
            class="subject-card__name"
            :class="{
                'subject-card__name--force-break': !stringHasSpaces(subject.name)
            }"
        >
            {{ subject.name }}
        </div>
        <div v-dark class="subject-card__correct-answered">
            {{ subject.correctCount }} of {{ subject.answeredCount }} Correct
        </div>
        <div v-dark class="subject-card__bar">
            <div
                v-dark
                class="subject-card__bar-fill"
                :style="{ width: `${Math.round((subject.correctCount / subject.answeredCount) * 100)}%` }"
                :class="{
                    [`subject-card__bar-fill--${
                        getScoreRange(Math.round((subject.correctCount / subject.answeredCount) * 100))
                    }`]: true
                }"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator'
import { getScoreRange } from '@/utils'

export type TSubject = {
    name: string
    correctCount: number
    answeredCount: number
}

@Component
export default class SubjectCard extends Vue {
    @Prop() subject!: TSubject

    getScoreRange = getScoreRange

    stringHasSpaces (val: string) {
        return val.includes(' ')
    }
}
</script>

<style lang="scss" scoped>
.subject-card {
    position: relative;
    background: $white;
    border-radius: 5px;
    padding: 12px 10px;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid rgba($pewter, 0.85);
    box-shadow: 0 1px 4px 0 rgba($ash, 0.3);

    &--dark {
        background-color: $brand-black;
        box-shadow: none;
        color: $barely-background;
    }

    &__percent {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.2px;
        font-weight: 600;
        top: 14px;
        right: 10px;
        position: absolute;
    }

    &__name {
        font-size: 15px;
        line-height: 20px;
        max-width: 277px;
        margin-bottom: 9px;

        &--force-break {
            word-break: break-all;
        }
    }

    &__correct-answered {
        color: $slate-01;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 3px;

        &--dark {
            color: $fog;
        }
    }

    &__bar {
        width: 100%;
        height: 6px;
        border-radius: 2px;
        background: $gray-progress-background;
        position: relative;

        &--dark {
            background-color: $charcoal;
        }
    }

    &__bar-fill {
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 2px;
        height: 6px;

        &--low {
            background: $red-pegasus;

            &--dark {
                background-color: $rosa;
            }
        }

        &--medium {
            background: #dd910a;

            &--dark {
                background-color: $banana-bread;
            }
        }

        &--high {
            background: $spectral-green;

            &--dark {
                background-color: $jungle-green;
            }
        }
    }
}
</style>