<template>
    <div class="bonus-question-banner" v-dark="isDarkMode">
        <template v-if="variant === 'explanation-answer-question'">
            We're testing a new question type. Explaining
            answers deepens understanding.
        </template>
        <template v-else>
            We're testing a new question type. Short
            answer questions increase learning retention.
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component
export default class BonusQuestionBanner extends Vue {
    @Prop variant!: 'short-answer-question' | 'explanation-answer-question'
    @Prop({ default: false }) isDarkMode!: boolean
}
</script>

<style lang="scss" scoped>
.bonus-question-banner {
    width: 341px;
    background-color: $barely-blue;
    padding: 12px 15px 12px 15px;
    box-sizing: border-box;
    border: 1px solid rgba($brand-blue, 0.4);
    border-radius: 6px;
    margin: 0 auto 24px auto;
    line-height: 20px;
    letter-spacing: -0.1px;
    font-size: 15px;

    &--dark {
        background-color: rgba($brand-blue, 0.2);
        border-color: rgba($baby-blue, 0.4);
    }
}
</style>
