<template>
    <Portal to="modal">
        <ModalContainer key="welcomeSeriesModal" @close="closeModal">
            <template #modal>
                <Modal
                    class="wsm__modal"
                    :show-close-button="true"
                    @close="closeModal"
                >
                    <div class="wsm">
                        <div class="wsm__top">
                            <img src="@/assets/welcomeSeries/celebrate-icon.png" alt="">
                            <div
                                class="wsm__title"
                                tabindex="-1"
                            >
                                Welcome to Pocket Prep
                            </div>
                            <div class="wsm__subtitle">
                                Your journey to study greatness begins! Here are some basics to get you started:
                            </div>
                        </div>
                        <div class="wsm__middle">
                            <Slide
                                v-if="currentSlide"
                                :title="currentSlide.title"
                                :description="currentSlide.description"
                                :image-name="currentSlide.imageName"
                                :image-alt="currentSlide.imageAlt || ''"
                            />
                        </div>
                        <Foot
                            :current-dot="currentSlideIndex"
                            @close="closeModal"
                            @change="changeSlide"
                        />
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { Vue, Component, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import Slide from '@/components/WelcomeSeries/Slide.vue'
import Foot from '@/components/WelcomeSeries/Foot.vue'
import { userModule } from '@/store/user/module'
import { bundleModule } from '@/store/bundle/module'
import { examMetadataModule } from '@/store/examMetadata/module'

@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
        Slide,
        Foot,
    },
})
export default class WelcomeSeriesModal extends Vue {
    currentSlideIndex = 0
    slides = [
        {
            title: 'Study Anywhere, Anytime',
            description: 'Take your Pocket Prep content with you on any device using our app or here on web.',
            imageName: 'welcome images 01.png',
        },
        {
            title: 'Our explanations are here to help!',
            description: 'Thorough explanations help you learn along the way.',
            imageName: 'welcome images 02.png',
        },
        {
            title: 'Subject Insights',
            description: 'Track performance by subject to know where you need to focus.',
            imageName: 'welcome images 03.png',
        },
        {
            title: 'Our Pass Guarantee*',
            description: 'If you fail your exam, we’ll give you three months free as long as you’ve been subscribed ' +
            'for three months, attempted all practice questions, and maintained an 80% average.',
            imageName: 'welcome images 04.png',
            imageAlt: 'Pass Guarantee, Only with premium',
        },
    ]

    get currentSlide () {
        return this.slides[this.currentSlideIndex] || this.slides[0]
    }

    async mounted () {
        await Promise.all([
            bundleModule.actions.fetchBundles(),
            examMetadataModule.actions.fetchExamMetadata(),
        ])
        this.$nextTick(() => {
            const wsmTitleEl = document.querySelector('.wsm__title') as HTMLElement | null
            wsmTitleEl?.focus()
        })
    }

    closeModal () {
        userModule.actions.updateWebConfig({
            hasSeenWelcomeModal: true,
        })

        this.emitClose()
    }

    changeSlide (newSlideIndex: number) {
        this.currentSlideIndex = newSlideIndex
    }

    @Emit('close')
    emitClose () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.wsm {
    width: 100%;
    position: relative;
    border-radius: 12px;
    overflow: hidden;

    &::before {
        content: '';
        top: 0;
        left: 38.5px;
        width: 578px;
        height: 184px;
        position: absolute;
        background: url('@/assets/welcomeSeries/Welcome-Confetti.svg') no-repeat center center;
    }

    &__modal {
        width: 655px;
        height: 570px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 80px;

        @include breakpoint(black-bear) {
            width: 356px;
            height: auto;
            max-height: 638px;
            top: 20px;
        }
    }

    &__top {
        padding-top: 57px;
        text-align: center;
        width: 334px;
        margin: 0 auto;
        position: relative;
        z-index: 1;

        @include breakpoint(black-bear) {
            padding-top: 38px;
            width: 100%;
        }

        img {
            display: block;
            height: 26px;
            margin: 0 auto 12px;

            @include breakpoint(black-bear) {
                margin: 0 auto 8px;
            }
        }
    }

    &__title {
        font-size: 26px;
        line-height: 36px;
        font-weight: 700;
        margin-bottom: 5px;
        outline: none;

        @include breakpoint(black-bear) {
            font-size: 26px;
            line-height: 36px;
            width: 228px;
            margin: 0 auto 9px;
        }
    }

    &__subtitle {
        color: $slate-03;
        font-size: 14px;
        line-height: 19px;

        @include breakpoint(black-bear) {
            max-width: 302px;
            margin: 0 auto;
        }
    }

    &__middle {
        height: 321px;
        margin-bottom: 8px;

        @include breakpoint(black-bear) {
            height: 408px;
            margin-bottom: 0;
        }
    }
}
</style>