import { userModule } from '@/store/user/module'
import * as amplitude from '@amplitude/analytics-browser'
import { Experiment, type ExperimentConfig } from '@amplitude/experiment-js-client'
import { analyticsModule } from '@/store/analytics/module'
import { isSupportWindow } from '@/utils'
import type { RouteLocationNormalized } from 'vue-router'


const initAmplitude = async () => {
    const amplitudeApiKey = import.meta.env.VUE_APP_AMPLITUDE_API_KEY
    if (amplitudeApiKey && !isSupportWindow()) {
        const ampliInitConfig: amplitude.Types.BrowserOptions = {
            defaultTracking: {
                pageViews: false,
                sessions: true,
                formInteractions: false,
                fileDownloads: false,
                attribution: {
                    /**
                     * See ampli docs here
                     * https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2#exclude-referrers
                     *  */
                    excludeReferrers: [],
                },
            },
        }
        const customAmpliServerUrl = import.meta.env.VUE_APP_AMPLITUDE_ANALYTICS_URL
        if (customAmpliServerUrl) {
            ampliInitConfig.serverUrl = customAmpliServerUrl
        }
        amplitude.init(amplitudeApiKey, ampliInitConfig)
        const ampliExperimentConfig: ExperimentConfig = {}
        const customAmpliExperimentUrl = import.meta.env.VUE_APP_AMPLITUDE_EXPERIMENT_URL
        if (customAmpliExperimentUrl) {
            ampliExperimentConfig.serverUrl = customAmpliExperimentUrl
        }
        const customAmpliFlagUrl = import.meta.env.VUE_APP_AMPLITUDE_FLAG_URL
        if (customAmpliFlagUrl) {
            ampliExperimentConfig.flagsServerUrl = customAmpliFlagUrl
        }
        const experiment = Experiment.initializeWithAmplitudeAnalytics(amplitudeApiKey, ampliExperimentConfig)
        analyticsModule.state.experiment = experiment
        await experiment.start()
    }
}

const amplitudeFetchVariant = async (flagKey: string) => {
    const amplitudeApiKey = import.meta.env.VUE_APP_AMPLITUDE_API_KEY
    const experiment = analyticsModule.state.experiment
    if (amplitudeApiKey && !isSupportWindow() && experiment) {
        const user = userModule.state.user
        if (user) {
            await experiment.fetch({
                user_id: user.objectId,
                user_properties: {
                    email: user.username,
                    createdAt: user.createdAt,
                },
            }, {
                flagKeys: [ flagKey ],
            })
        } else {
            await experiment.fetch(undefined, { flagKeys: [ flagKey ] })
        }
        const variant = experiment.variant(flagKey)
        return variant
    }
}

const amplitudeTrack = (eventName: string, eventProperties?: Record<string, string | number | boolean | undefined>) => {
    const amplitudeApiKey = import.meta.env.VUE_APP_AMPLITUDE_API_KEY
    if (amplitudeApiKey && !isSupportWindow()) {
        amplitude.track(eventName, eventProperties)
    }
}

const amplitudeTrackScreen = (to: RouteLocationNormalized) => {
    const screenName = analyticsModule.getters.getScreenName(to)
    if (screenName) {
        amplitudeTrack('Screen View', {
            screenName,
            platform: 'Web',
        })
    }

    return screenName
}

const amplitudeReset = () => {
    const amplitudeApiKey = import.meta.env.VUE_APP_AMPLITUDE_API_KEY
    if (amplitudeApiKey && !isSupportWindow()) {
        amplitude.reset()
    }
}

const setAmplitudeUser = ({ id, email, createdAt }: {
    id: string
    email: string
    createdAt: string   // ISO date string
}) => {
    const amplitudeApiKey = import.meta.env.VUE_APP_AMPLITUDE_API_KEY
    if (amplitudeApiKey && !isSupportWindow()) {
        amplitude.setUserId(id)

        const identifyEvent = new amplitude.Identify()
        identifyEvent.set('email', email)
        identifyEvent.set('createdAt', createdAt)

        // clear out the Stripe Coupon Id if no referral
        const queryParams = Object.fromEntries(new URLSearchParams(window.location.search))
        if (!('referral' in queryParams)) {
            identifyEvent.unset('stripeCouponId')
        }
        amplitude.identify(identifyEvent)
    }
}

const bootIntercom = () => {
    if (import.meta.env.VUE_APP_INTERCOM_APP_ID && !isSupportWindow()) {
        const intercomSettings = analyticsModule.getters.intercomSettings()
        window.Intercom('boot', intercomSettings)
    }
}

const updateIntercom = () => {
    if (import.meta.env.VUE_APP_INTERCOM_APP_ID && !isSupportWindow()) {
        const intercomSettings = analyticsModule.getters.intercomSettings()
        window.Intercom('update', intercomSettings)
    }
}

const googleAnalyticsTrack = async (eventName: string, eventProperties?: Record<string, string | number | boolean>) => {
    if (import.meta.env.VUE_APP_GOOGLE_ANALYTICS_ID && !isSupportWindow()) {
        gtag('event', eventName, {
            ...eventProperties,
        })
    }
}

const trackEvent = async (eventName: string, eventProperties?: Record<string, string | number | boolean>) => {
    // Can send an event to multiple destinations, if needed
    await Promise.all([
        googleAnalyticsTrack(eventName, eventProperties),
    ])
}

const setSprigUser = ({ id, email }: {
    id: string
    email: string
}) => {
    analyticsModule.state.sprig?.setUserId(id)
    analyticsModule.state.sprig?.setEmail(email)
}

const updateSprigUser = ({ examName }: {
    examName: string
}) => {
    analyticsModule.state.sprig?.setAttribute('Exam', examName)
}

const sprigLogoutUser = () => {
    analyticsModule.state.sprig?.logoutUser()
}

const sprigTrack = async (payload: {
    anonymousId?: string
    metadata?: Record<string, unknown>
    eventName?: string
    properties?: Record<string, unknown>
    userId?: string
    showSurveyCallback?: (surveyId?: number) => Promise<boolean>
}) => {
    const currentUser = userModule.state.user
    const trackPayload = {
        ...payload,
        ...(currentUser && { userId: currentUser.objectId }),
    }
    analyticsModule.state.sprig?.identifyAndTrack(trackPayload)
}

export default {
    initAmplitude,
    amplitudeFetchVariant,
    amplitudeTrack,
    amplitudeTrackScreen,
    setAmplitudeUser,
    amplitudeReset,
    bootIntercom,
    updateIntercom,
    googleAnalyticsTrack,
    trackEvent,
    setSprigUser,
    updateSprigUser,
    sprigLogoutUser,
    sprigTrack,
}
