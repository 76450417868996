<template>
    <KeywordDefinition
        v-if="showKeywordDefinition && visibleKeywordDefinition && question"
        :keywordDOMElement="keywordDOMElement"
        :keywordClickLocation="keywordClickLocation"
        :keyword="visibleKeywordDefinition.keyword"
        :definition="visibleKeywordDefinition.definition"
        :questionContainer="questionContainer"
        :serial="question.serial"
        :keywordLocation="visibleKeywordDefinition.location"
        @close="showKeywordDefinition = false"
    />
    <QuizContainer
        ref="quizContainer"
        class="shared-qotd"
        :class="{ 'shared-qotd--show-banner': !!answer }"
    >
        <template #header>
            <div class="shared-qotd__header">
                <div class="shared-qotd__header-top" :class="{ 'shared-qotd__header-top--show-banner': !!answer }">
                    <div class="shared-qotd__logo">
                        <img src="@/assets/logos/logo-white.svg" alt="Pocket Prep Logo.">
                    </div>
                    <PocketButton
                        class="shared-qotd__register-btn"
                        type="secondary-yellow-dark"
                        @click="$router.push({ name: 'register', query: { exam: examGuid } })"
                    >
                        Create an Account
                    </PocketButton>
                </div>
                <div v-if="!!answer" class="shared-qotd__header-banner">
                    <div class="shared-qotd__header-banner-questions">
                        <span>{{ examQuestionCount }}<Highlight /></span>
                        more questions
                    </div>
                    <div class="shared-qotd__header-banner-text">
                        In-depth explanations for every answer
                    </div>
                </div>
            </div>
        </template>
        <template #question>
            <Question
                v-if="question && examMetadata"
                :key="`${question.objectId}-${allowKeyboardShortcuts}-${JSON.stringify(answer)}`"
                class="shared-qotd__question"
                ref="qotdContainer"
                :container-el="quizContainerEl"
                :question="question"
                :quiz-mode="'qotd'"
                :global-metrics="!!answer && showGlobalMetrics && globalMetrics"
                :show-next-question="false"
                :show-check-answer="true"
                :previous-choices="previousChoices"
                :previous-matrix-choices="previousChoices"
                :initial-show-answers="!!answer"
                :allow-keyboard-shortcuts="allowKeyboardShortcuts"
                :image-url-prefix="`${s3Url || ''}/${question.compositeKey.toUpperCase()}/`"
                :hide-references="examMetadata.hideReferences"
                :keyword-definitions="keywordDefinitions"
                @keywordClick="keywordClicked"
                @checkAnswer="submitQotD"
                @update:showExplanation="explanationViewToggled"
            >
                <template #context>
                    <div 
                        class="shared-qotd__question-context" 
                        :class="{ 'shared-qotd__question-context--show-banner': !!answer }"
                    >
                        <div class="shared-qotd__question-context-left">
                            <div class="shared-qotd__question-context-qotd">
                                Question of the Day
                            </div>
                            <div class="shared-qotd__question-context-exam">
                                {{ examMetadata.nativeAppName }}
                            </div>
                        </div>
                        <div class="shared-qotd__question-context-right">
                            <BundleIcon v-if="bundle" :bundle-id="bundle.objectId" />
                        </div>
                    </div>
                </template>
                <template #afterQuestion>
                    <div
                        v-if="!!answer"
                        :style="{ marginTop: questionBannerMarginTop }"
                        class="shared-qotd__footer-banner"
                    >
                        <div class="shared-qotd__footer-banner-questions">
                            <span>{{ examQuestionCount }}<Highlight /></span>
                            more questions
                        </div>
                        <div class="shared-qotd__footer-banner-text">
                            In-depth explanations for every answer
                        </div>
                        <PocketButton
                            class="shared-qotd__footer-banner-btn"
                            type="primary-yellow"
                            @click="$router.push({ name: 'register', query: { exam: examGuid } })"
                        >
                            Create an Account
                        </PocketButton>
                        <div class="shared-qotd__footer-banner-blob">
                            <Blob />
                        </div>
                    </div>
                </template>
            </Question>
        </template>
        <template #footer>
            <div class="shared-qotd__footer">
                <div
                    class="shared-qotd__global-metrics-toggle"
                    :class="{ 
                        'shared-qotd__global-metrics-toggle--active': showGlobalMetrics,
                        'shared-qotd__global-metrics-toggle--disabled': !answer,
                        'shared-qotd__global-metrics-toggle--enabled': !!answer
                    }"
                >
                    <GlobalMetricsToggle 
                        :show-global-metrics="showGlobalMetrics" 
                        :disabled="!answer"
                        :tooltip-theme="
                            (
                                breakpoint === 'polar-bear' 
                                || breakpoint === 'grizzly-bear' 
                                || breakpoint === 'brown-bear'
                            )
                                && 'rightalign'
                        "
                        @toggleGlobalMetrics="toggleGlobalMetrics"
                    />
                </div>
                <div class="shared-qotd__share">
                    <div class="shared-qotd__share-text">
                        Share with your people
                    </div>
                    <div
                        class="shared-qotd__share-link shared-qotd__share-link--copy"
                        tabindex="0"
                        @click="copyShareLink"
                        @keypress.enter="copyShareLink"
                        @mousedown.prevent
                    >
                        <Tooltip
                            v-if="showLinkCopiedTooltip"
                            class="shared-qotd__share-link-tooltip"
                            :styles="{
                                backgroundColor: brandColors.green,
                                color: brandColors.brandBlack,
                            }"
                        >
                            Link Copied!
                        </Tooltip>
                        <LinkIcon class="shared-qotd__share-link-icon--desktop" />
                        <Icon type="share" class="shared-qotd__share-link-icon--mobile" />
                    </div>
                    <ShareNetwork
                        v-if="qotdSharingInfo"
                        network="facebook"
                        :url="qotdSharingInfo.url"
                        :title="qotdSharingInfo.title"
                        :hashtags="qotdSharingInfo.hashtags"
                        class="shared-qotd__share-link"
                    >
                        <FacebookIcon />
                    </ShareNetwork>
                    <ShareNetwork
                        v-if="qotdSharingInfo"
                        network="twitter"
                        :url="qotdSharingInfo.url"
                        :title="qotdSharingInfo.title"
                        :hashtags="qotdSharingInfo.hashtags"
                        class="shared-qotd__share-link"
                    >
                        <TwitterIcon />
                    </ShareNetwork>
                </div>
                <KeyboardShortcutsButton
                    class="shared-qotd__keyboard-shortcuts-btn"
                    :tooltip-theme="
                        (breakpoint === 'polar-bear' || breakpoint === 'grizzly-bear' || breakpoint === 'brown-bear') 
                            && 'leftalign'
                    "
                >
                    <template
                        #keyboardShortcutsModal="{ 
                            showKeyboardShortcutsModal,
                            closeModalFunc
                        }"
                    >
                        <Portal to="modal">
                            <KeyboardShortcutsModal
                                v-if="showKeyboardShortcutsModal"
                                key="sharedQotdKeyboardShortcutsModal"
                                :allow-keyboard-shortcuts="allowKeyboardShortcuts"
                                aria-live="polite"
                                @toggleKeyboardShortcuts="toggleKeyboardShortcuts"
                                @close="closeModalFunc"
                            />
                        </Portal>
                    </template>
                </KeyboardShortcutsButton>
            </div>
        </template>
    </QuizContainer>
</template>

<script lang="ts">
import { bundleModule } from '@/store/bundle/module'
import { qotdModule } from '@/store/qotd/module'
import { userModule } from '@/store/user/module'
import type { Study } from '@pocketprep/types'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Watch } from 'vue-facing-decorator'
import FacebookIcon from '@/assets/shared-qotd/Facebook.vue'
import TwitterIcon from '@/assets/shared-qotd/Twitter.vue'
import LinkIcon from '@/assets/shared-qotd/Link.vue'
import { ShareNetwork } from 'vue-social-sharing'
import { copyText } from '@/utils'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'
import Blob from '@/assets/shared-qotd/Blob.vue'
import Highlight from '@/assets/shared-qotd/Highlight.vue'
import { screenModule } from '@/store/screen/module'
import { ResizeObserver as ResizeObserverPonyFill } from 'resize-observer'
import { analyticsModule } from '@/store/analytics/module'
import KeywordDefinition from '@/components/KeywordDefinitions/KeywordDefinition.vue'
import { keywordsModule } from '@/store/keywords/module'

interface IBundleBrandInfo {
    appClipBundleId: string
    appStoreId: string
    appStoreURL: string
    color: string
    universalLinkName: string
    playStoreURL: string
}

@Component({
    components: {
        FlagToggle: UIKit.FlagToggle,
        KeyboardShortcutsButton: UIKit.KeyboardShortcutsButton,
        KeyboardShortcutsModal: UIKit.KeyboardShortcutsModal,
        Icon: UIKit.Icon,
        ExamMenuCard: UIKit.ExamMenuCard,
        PocketButton: UIKit.Button,
        QuizContainer: UIKit.QuizContainer,
        Question: UIKit.Question,
        GlobalMetricsToggle: UIKit.GlobalMetricsToggle,
        FacebookIcon,
        TwitterIcon,
        LinkIcon,
        BundleIcon: UIKit.BundleIcon,
        ShareNetwork,
        Tooltip: UIKit.Tooltip,
        Blob,
        Highlight,
        KeywordDefinition,
    },
})
export default class SharedQotD extends Vue {
    allowKeyboardShortcuts = false
    isLoading = true
    question: Study.Class.QuestionJSON | null = null
    globalMetrics: Study.Class.GlobalQuestionMetricJSON | null = null
    showGlobalMetrics = false
    brandColors = BrandColors
    showLinkCopiedTooltip = false
    questionBannerMarginTop = '0'
    bundle: Study.Class.BundleJSON | null = null
    examMetadata: Study.Class.ExamMetadataJSON | null = null
    resizeObserver: ResizeObserver | null = null
    clientVersion = import.meta.env.VUE_APP_VERSION
    keywordDOMElement: HTMLElement | null = null
    showKeywordDefinition = false
    visibleKeywordDefinition: {
        keyword: string
        definition: string
        location: string
    } | null = null
    keywordClickLocation: { x: number; y: number} | null = null

    get s3Url () {
        return import.meta.env.VUE_APP_S3_URL
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get questionContainer () {
        const questionContainerRef = this.$refs['qotdContainer'] as typeof UIKit.Question
        return questionContainerRef && '$el' in questionContainerRef ? questionContainerRef.$el as HTMLElement : null
    }

    get quizContainerEl () {
        const quizContainerRef = this.$refs['quizContainer'] as typeof UIKit.QuizContainer | undefined
        return quizContainerRef && '$el' in quizContainerRef ? quizContainerRef.$el : null
    }

    get answer () {
        return qotdModule.state.sharedQotDQuizDraft?.answers[0] || null
    }

    get examGuid () {
        return this.$route.query.examGuid as string
    }

    get userId () {
        return this.$route.query.userId as string
    }

    get version () {
        return this.$route.query.version as string
    }

    get majorVersion () {
        return this.version.split('.')[0] + '.'
    }

    get examQuestionCount () {
        return (this.examMetadata?.itemCount || 0) - (this.examMetadata?.archivedCount || 0) - 1
    }

    get previousChoices () {
        return this.answer?.selectedChoices || []
    }

    get bundleBrandLib () {
        return bundleModule.getters.getBundleBrandLib()
    }

    get keywordDefinitions () {
        const questionSerial = this.question?.serial
        const questionKeywords = questionSerial && keywordsModule.getters.getKeywordDefinitions().get(questionSerial)

        if (
            !this.answer
            || !questionKeywords
        ) {
            return []
        }

        return Array.from(questionKeywords, ([ keyword, definition ]) => ({
            keyword,
            definition,
        }))
    }

    // We need to handle the case where we don't actually have a bundle defined for the exam
    get bundleBrandInfo (): IBundleBrandInfo {
        const defaultBundleBrandInfo: IBundleBrandInfo = {
            appClipBundleId: 'PL8WGSD3BU.com.pocketprep.app.ems.Clip',
            appStoreId: '1503584128',
            appStoreURL: 'https://apple.co/36GCG4s',
            color: '#1F3545',
            playStoreURL: 'https://bit.ly/3dYDh2X',
            universalLinkName: 'web',
        }
        const id = this.bundle?.objectId
        if (id) {
            const bundleBrandLibId = this.bundleBrandLib[id]
            if (bundleBrandLibId) {
                return bundleBrandLibId
            }
        }
        return defaultBundleBrandInfo
    }

    get qotdSharingInfo () {
        if (!this.bundle || !this.examMetadata) {
            return undefined
        }

        return qotdModule.getters.getQotDSharingInfo({
            bundleId: this.bundle.objectId,
            examGuid: this.examGuid,
            version: this.version,
            userId: this.userId || '',
            examName: this.examMetadata.nativeAppName,
        })
    }

    async mounted () {
        if (!this.examGuid || !this.majorVersion) {
            this.$router.push({ name: 'sign-in' }).catch(() => {
                // vue-router may report an error on additional redirects, but we're fine with them
            })
            return
        }

        if (userModule.state.user) {
            this.$router.push({ name: 'qotd' })
            return
        }

        // update Google Analytics with event data
        /* istanbul ignore next */
        await analyticsModule.actions.googleAnalyticsTrack('shared_qotd_viewed', {
            'event_category': 'Share',
            examGuid: this.examGuid,
            userId: this.userId,
            version: this.version,
        })


        const sharedQotDInfo = await qotdModule.actions.fetchSharedQotD({
            examGuid: this.examGuid,
            version: this.version,
        })
        this.question = sharedQotDInfo.question

        await keywordsModule.actions.fetchKeywordDefinitions([ this.question.serial ], false, this.examGuid)

        // check for locally stored answer and clear if for a different question
        if (this.answer && this.answer.questionSerial !== this.question?.serial) {
            qotdModule.actions.clearSharedQotDDraft()
        }

        this.bundle = sharedQotDInfo.bundle || null
        this.examMetadata = sharedQotDInfo.examMetadata || null
        this.globalMetrics = sharedQotDInfo.globalMetric || null

        // Dynamically add a meta tag for Apple App Clips
        const id = this.bundleBrandInfo.appStoreId
        const clipId = this.bundleBrandInfo.appClipBundleId
        const content = `app-id=${id}, app-clip-bundle-id=${clipId}, app-argument=${this.qotdSharingInfo?.url}`
        const appClipMeta = document.createElement('meta')
        appClipMeta.setAttribute('name', 'apple-itunes-app')
        appClipMeta.setAttribute('content', content)
        document.getElementsByTagName('head')[0]?.appendChild(appClipMeta)

        this.$nextTick(() => {
            const questionContainer = document.querySelector('.uikit-question__main') as HTMLElement
            this.resizeObserver = window.ResizeObserver
                ? new ResizeObserver(this.updateQuestionBannerMarginTop)
                : new ResizeObserverPonyFill(this.updateQuestionBannerMarginTop)
            this.resizeObserver.observe(questionContainer)
        })

        this.isLoading = false
    }

    beforeUnmount () {
        this.resizeObserver?.disconnect()
    }

    @Watch('answer')
    updateQuestionBannerMarginTop () {
        const questionContainer = document.querySelector('.uikit-question__main') as HTMLElement
        const questionBanner = document.querySelector('.shared-qotd__footer-banner') as HTMLElement

        if (!this.answer || !questionContainer || !questionBanner) {
            return
        }

        const existingTopMargin = Number(questionBanner.style.marginTop?.split('px')[0]) || 0
        const topMargin = (questionContainer.getBoundingClientRect().height 
        + questionContainer.getBoundingClientRect().top) 
        - (questionBanner.getBoundingClientRect().top + questionBanner.getBoundingClientRect().height)
        + existingTopMargin
        if (topMargin === 0) {
            return
        }

        this.questionBannerMarginTop = topMargin > 0 ? `${topMargin}px` : '0'
    }

    keywordClicked (params?: { 
        keyword: string
        location: string
        clickLocation: { x: number; y: number}
        target: HTMLElement
    }) {
        this.showKeywordDefinition = false
        if (!params?.keyword) {
            return
        }

        const questionKeywords = this.question
        && keywordsModule.getters.getKeywordDefinitions().get(this.question.serial)
        const definition = questionKeywords?.get(params.keyword.toLowerCase())

        if (!definition) {
            return
        }

        analyticsModule.actions.amplitudeTrack('Keyword Definition Viewed', {
            examGuid: this.examMetadata?.examGuid,
            definitionKeyword: params.keyword,
            serial: this.question?.serial,
            location: params.location,
            quizMode: -1,
            studyMode: 'Shared QotD',
        })

        this.visibleKeywordDefinition = {
            keyword: params.keyword, 
            definition,
            location: params.location,
        }
        this.keywordDOMElement = params.target
        this.keywordClickLocation = params.clickLocation

        this.$nextTick(() => {
            this.showKeywordDefinition = true
        })
    }

    toggleGlobalMetrics (newVal: boolean) {
        this.showGlobalMetrics = newVal
    }

    toggleKeyboardShortcuts (newVal: boolean) {
        this.allowKeyboardShortcuts = newVal
    }

    async copyShareLink () {
        if (!this.qotdSharingInfo) {
            return
        }

        if (navigator.share as unknown && this.qotdSharingInfo && window.innerWidth < 768) {
            try {
                await navigator.share({
                    title: this.qotdSharingInfo.title,
                    url: this.qotdSharingInfo.url,
                })
            } catch (err) {
                // noop
            }
            return
        }

        copyText(this.qotdSharingInfo?.url)
        this.showLinkCopiedTooltip = true
        setTimeout(() => {
            this.showLinkCopiedTooltip = false
        }, 2000)

        analyticsModule.actions.amplitudeTrack('QotD Shared', {
            bundleId: this.bundle?.objectId,
            client: 'Web Study',
            clientAppId: 'study.pocketprep.com',
            clientVersion: this.clientVersion,
            examGuid: this.examMetadata?.examGuid,
            organizationId: userModule.getters.getCurrentOrganizationId() || undefined,
            userDomain: userModule.getters.getUserDomain() || undefined,
        })
    }

    async submitQotD (answer: Study.Cloud.IQuizAnswer) {
        qotdModule.actions.saveSharedQotDDraft({
            answers: [ answer ],
            correctCount: answer.isCorrect ? 1 : 0,
            durationSeconds: 0,
            examGuid: this.examGuid,
            incorrectCount: answer.isCorrect ? 0 : 1,
            mode: -1,
            platform: 'Web',
            startedAt: new Date(),
        })
    }

    explanationViewToggled (showExplanation: boolean) {
        if (showExplanation) {
            analyticsModule.actions.amplitudeTrack(
                'Explanation Opened',
                {
                    examGuid: this.examGuid,
                    serial: this.question?.serial,
                    quizMode: 'qotd',
                    isSharedQotd: true,
                    platform: 'Web',
                }
            )
        }
    }
}
</script>

<style lang="scss" scoped>
.shared-qotd {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;

    :deep(.uikit-quiz-container__main) {
        @include breakpoint(black-bear) {
            height: calc(100% - 108px) !important;
        }
    }

    :deep(.uikit-question__close-icon) {
        display: none;
    }

    :deep(.uikit-quiz-container__header) {
        @include breakpoint(brown-bear) {
            height: 62px !important;
        }

        @include breakpoint(black-bear) {
            height: 54px !important;
        }
    }

    &--show-banner {
        :deep(.uikit-quiz-container__header) {
            height: 145px;
        }

        :deep(.uikit-quiz-container__main) {
            height: calc(100% - 211px);
        }
    }

    &__header {
        padding: 16px 0;
        width: 100%;

        @include breakpoint(brown-bear) {
            padding: 9px 0 13px;
        }

        @include breakpoint(black-bear) {
            padding: 6px 10px 8px;
        }
    }

    &__header-top {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &--show-banner {
            margin-bottom: 13px;
        }

        @include breakpoint(brown-bear) {
            margin-bottom: 0;
        }
    }

    &__logo {
        width: 134px;
        padding: 5px 0;

        img {
            display: block;
            width: 100%;
        }
    }

    &__footer-banner {
        width: calc(100% + 84px);
        height: 203px;
        background: $white;
        border-radius: 0 0 6px 6px;
        margin: 0 -42px;
        color: $brand-black;
        padding: 27px 81px;
        box-sizing: border-box;
        display: none;
        position: relative;
        z-index: 1;

        @include breakpoint(brown-bear) {
            display: block;
        }

        @include breakpoint(black-bear) {
            border-radius: 0 0 12px 12px;
            padding: 33px 26px;
            height: 274px;
            margin: 0 -17px;
            width: calc(100% + 34px);
        }
    }

    &__footer-banner-questions {
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 0.32px;
        font-weight: 700;
        margin-bottom: 6px;

        span {
            z-index: 1;
            position: relative;

            svg {
                color: $beach-sand;
                position: absolute;
                left: -14px;
                top: 24px;
                width: calc(100% + 19px);
                z-index: -1;
                height: 18px;
            }
        }
    }

    &__footer-banner-blob {
        position: absolute;
        right: 0;
        bottom: 0;
        overflow: hidden;
        border-radius: 6px;
        z-index: -1;

        @include breakpoint(black-bear) {
            border-radius: 12px;
        }

        svg {
            display: block;
        }
    }

    &__footer-banner-text {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 23px;

        @include breakpoint(black-bear) {
            width: 225px;
            margin-bottom: 28px;
            line-height: 25px;
        }
    }

    &__footer-banner-btn {
        margin-left: -16px;

        @include breakpoint(black-bear) {
            margin-left: -8px;
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @include breakpoint(black-bear) {
            max-width: 375px;
            width: 100%;
            justify-content: space-around;
            margin: 0 auto;
        }
    }

    &__header-banner {
        background: #ffedcb;
        border-radius: 6px;
        height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        color: $brand-black;
        padding: 8px 30px;
        box-sizing: border-box;

        @include breakpoint(brown-bear) {
            display: none;
        }
    }

    &__header-banner-questions {
        font-size: 32px;
        letter-spacing: 0.32px;
        font-weight: 700;
        line-height: 44px;
        margin-right: 28px;

        span {
            z-index: 1;
            position: relative;

            svg {
                color: $banana-bread;
                position: absolute;
                left: -8px;
                top: 26px;
                width: calc(100% + 10px);
                z-index: -1;
            }
        }
    }

    &__header-banner-text {
        font-size: 18px;
        line-height: 25px;
    }

    &__question {
        position: relative;
        height: 100%;
    }

    &__global-metrics-toggle {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;

        &--active {
            background: $black-feather;
        }
    }

    &__keyboard-shortcuts-btn {
        position: relative;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__share {
        display: flex;
        align-items: center;
    }

    &__share-text {
        margin-right: 18px;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__share-link-tooltip {
        position: absolute;
        top: calc(-100% - 8px);
        left: 50%;
    }

    &__share-link {
        height: 28px;
        margin-right: 12px;
        color: $gray-divider;
        cursor: pointer;
        user-select: none;
        position: relative;
        outline: none;

        @include breakpoint(black-bear) {
            display: none;
        }

        &--copy {
            @include breakpoint(black-bear) {
                display: block;
                margin-right: 0;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        @media (hover: hover) {
            &:hover,
            &:focus {
                color: $banana-bread;
            }
        }

        &:focus::before {
            content: "";
            position: absolute;
            width: 34px;
            height: 34px;
            border: 1px solid $banana-bread;
            border-radius: 34px;
            left: -4px;
            top: -4px;

            @include breakpoint(black-bear) {
                left: -9px;
            }
        }
    }

    &__share-link-icon--desktop {
        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__share-link-icon--mobile {
        display: none;

        @include breakpoint(black-bear) {
            display: block;
        }
    }

    &__question-context {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 56px 0 37px 16px;
        box-sizing: border-box;
        max-width: 492px;

        @include breakpoint(black-bear) {
            padding-bottom: 28px;
        }
    }

    &__question-context-qotd {
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
    }

    &__question-context-exam {
        font-size: 14px;
        line-height: 17px;
        opacity: 0.8;
    }

    &__question-context-right {
        height: 52px;
        width: 52px;
        margin-right: -7px;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}
</style>