import type { Study } from '@pocketprep/types'
import { initLoadable } from '@/store/utils'
import type { Loadable } from '@/store/utils'

type TUserExamMetadataState = {
    userExamMetadataByGuid: Loadable<{ [examGuid: string]: Study.Class.UserExamMetadataJSON }>
}

export const getInitialUserExamMetadataState = (): TUserExamMetadataState => {
    return {
        userExamMetadataByGuid: initLoadable({}),
    }
}

const moduleState = getInitialUserExamMetadataState()

export default moduleState
