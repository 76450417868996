<template>
    <div
        class="share-link"
        tabindex="0"
        @click="copyShareLink"
        @keydown.enter="copyShareLink"
        @mousedown.prevent
        @mouseover="breakpoint !== 'black-bear' && mouseOverShareIcon"
        @mouseleave="showShareTooltip = false"
        @focus="mouseOverShareIcon"
        @blur="showShareTooltip = false"
    >
        <Tooltip
            v-if="!showLinkCopiedTooltip && showShareTooltip"
            :styles="{
                backgroundColor: brandColors.grayDivider,
                color: brandColors.brandBlack,
            }"
            class="share-link__tooltip"
        >
            Share Question Link
        </Tooltip>
        <Tooltip
            v-if="!showShareTooltip && showLinkCopiedTooltip"
            class="share-link__tooltip"
            :theme="breakpoint === 'black-bear' && 'leftalign'"
            :styles="{
                backgroundColor: brandColors.green,
                color: brandColors.brandBlack,
            }"
        >
            Link Copied!
        </Tooltip>
        <Icon type="share" />
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'
import { qotdModule } from '@/store/qotd/module'
import type { Study } from '@pocketprep/types'
import { userModule } from '@/store/user/module'
import { copyText } from '@/utils'
import UIKit from '@pocketprep/ui-kit'
import { screenModule } from '@/store/screen/module'
import { analyticsModule } from '@/store/analytics/module'

@Component({
    components: {
        Tooltip: UIKit.Tooltip,
        Icon: UIKit.Icon,
    },
})
export default class QotDShareLink extends Vue {
    @Prop() bundle!: Study.Class.BundleJSON
    @Prop() examMetadata!: Study.Class.ExamMetadataJSON

    brandColors = BrandColors
    showLinkCopiedTooltip = false
    showShareTooltip = false
    clientVersion = import.meta.env.VUE_APP_VERSION

    get qotdSharingInfo () {
        if (!this.bundle || !this.examMetadata) {
            return undefined
        }

        return qotdModule.getters.getQotDSharingInfo({
            bundleId: this.bundle.objectId,
            examGuid: this.examMetadata.examGuid,
            version: this.examMetadata.version,
            userId: this.currentUser?.objectId || '',
            examName: this.examMetadata.nativeAppName,
        })
    }

    get currentUser () {
        return userModule.state.user
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    mouseOverShareIcon () {
        if (!this.showLinkCopiedTooltip && this.breakpoint !== 'black-bear') {
            this.showShareTooltip = true
        }
    }

    async copyShareLink () {
        if (!this.qotdSharingInfo) {
            return
        }

        if (navigator.share as unknown && this.qotdSharingInfo && window.innerWidth < 768) {
            try {
                await navigator.share({
                    title: this.qotdSharingInfo.title,
                    url: this.qotdSharingInfo.url,
                })
            } catch (err) {
                // noop
            }
            return
        }

        copyText(this.qotdSharingInfo?.url)
        this.showShareTooltip = false
        this.$nextTick(() => {
            this.showLinkCopiedTooltip = true

            setTimeout(() => {
                this.showLinkCopiedTooltip = false
            }, 2000)
        })

        analyticsModule.actions.amplitudeTrack('QotD Shared', {
            bundleId: this.bundle?.objectId,
            client: 'Web Study',
            clientAppId: 'study.pocketprep.com',
            clientVersion: this.clientVersion,
            examGuid: this.examMetadata?.examGuid,
            organizationId: userModule.getters.getCurrentOrganizationId() || undefined,
            userDomain: userModule.getters.getUserDomain() || undefined,
        })
    }
}
</script>

<style lang="scss" scoped>
.share-link {
    height: 28px;
    color: $gray-divider;
    cursor: pointer;
    user-select: none;
    position: relative;
    outline: none;

    &:last-child {
        margin-right: 0;
    }

    @media (hover: hover) {
        &:hover,
        &:focus {
            color: $banana-bread;
        }
    }

    &:focus::before {
        content: "";
        border: 1px solid $banana-bread;
        width: 32px;
        height: 32px;
        top: -4px;
        left: -7px;
        border-radius: 4px;
        box-sizing: border-box;
        position: absolute;

        @include breakpoint(black-bear) {
            left: -9px;
        }
    }

    &__tooltip {
        position: absolute;
        top: calc(-100% - 8px);
        left: 50%;
    }
}
</style>