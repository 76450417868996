<template>
    <div class="payment" :class="{ 'payment--dropdown-open': isStateDropdownOpen }">
        <PurchaseBlob class="payment__blob" />
        <div class="payment__plan payment__field">
            <PocketSelect
                v-model="plan"
                label="Payment Plan"
                :subtext="validReferral"
                :data="mappedPlanOptions"
            />
            <div
                v-if="!validReferral"
                class="payment__plan-tip"
            >
                Renews automatically.<br>Cancel anytime.<br>Taxes not included.
            </div>
            <div v-if="renewDate" class="payment__renew-date">
                Renews {{renewDate}}
            </div>
            <AppleGooglePay
                v-if="plan && planAmount"
                class="payment__apple-google-pay"
                :plan-amount="planAmount"
                :plan-subscription-name="planSubscriptionName"
                :plan="plan"
                :referral-id="referralId"
                :discount-plan-amount="discountPlanAmount"
                :impact-click-id="impactClickId"
                :errors="errors"
                :error-fields="errorFields"
                @update="isAppleGooglePayAvailable"
                @submitAppleGooglePayment="submitAppleGooglePayment"
            />
        </div>
        <PaymentFields
            v-if="planAmount"
            :errors="combinedErrors"
            :error-fields="errorFields"
            :plan-amount="planAmount"
            @openDropdown="openDropdown"
            @submit="submitPayment"
            @update="updatePaymentParams"
        />
        <ReferralTerms
            v-if="validReferral && 'objectId' in validReferral" 
            :plan="plan"
            utm-content="onboarding_referrals_terms"
            @agreedChanged="agreedChanged"
        />
        <PocketButton
            class="payment__submit"
            :disabled="!plan || !cardElCompleted || !addressElCompleted || errors.length
                || isPaymentBeingProcessed || (validReferral && !agreeDisabled && 'objectId' in validReferral)"
            :is-loading="isPaymentBeingProcessed"
            @click="submitPayment"
        >
            Start Studying <Icon type="arrow" />
        </PocketButton>
    </div>
</template>
   
<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import type { Study } from '@pocketprep/types'
import PurchaseBlob from '@/assets/onboarding/PurchaseBlob.vue'
import PaymentFields from '@/components/PaymentFields.vue'
import AppleGooglePay from '@/components/AppleGooglePay.vue'
import ReferralTerms from '@/components/ReferralTerms.vue'
import { isPromise } from '@/store/utils'
import { stripeModule } from '@/store/stripe/module'
import { referralModule } from '@/store/referral/module'
import { subscriptionModule } from '@/store/subscription/module'
import { stripeEnabledAppearance, stripeDisabledAppearance, formatDate } from '@/utils'
import { type StripeElement } from '@stripe/stripe-js'
import { isProxy, toRaw } from 'vue'

@Component({
    components: {
        PocketInput: UIKit.Input,
        PocketSelect: UIKit.Select,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
        Errors: UIKit.Errors,
        PurchaseBlob,
        PaymentFields,
        AppleGooglePay,
        ReferralTerms,
    },
})
export default class Payment extends Vue {
    @Prop() savedPlanId!: string
    @Prop() bundle!: Study.Class.BundleJSON
    @Prop() isLoading!: boolean
    @Prop() paymentError!: string

    name: null | string = null
    plan: null | { value: string; label: string } = null
    stripe: stripe.Stripe | null = null
    errorFields: string[]  = []
    errors: string[] = []
    isStateDropdownOpen = false
    agreeDisabled = false 
    impactClickId = ''
    cardElCompleted = false
    addressEl: StripeElement | null | void = null
    addressElCompleted = false
    planAmount: null | number = null
    planSubscriptionName = ''
    appleGooglePayAvailable = false
    isPaymentBeingProcessed = false

    get mappedPlanOptions () {
        return stripeModule.getters.getStripePlanOptions(this.bundle.objectId, true)
    }

    get combinedErrors () {
        return [ ...this.errors, ...(this.paymentError ? [ this.paymentError ] : []) ]
    }

    get validReferral () {
        return referralModule.getters.getValidReferral()
    }

    get referralError () {
        return referralModule.getters.getReferralError()
    }

    get referralId () {
        return !this.validReferral
            ? ''
            : 'objectId' in this.validReferral 
                ? this.validReferral.objectId 
                : this.validReferral.id
    }

    get discountPlanAmount () {
        return (this.validReferral && this.planAmount)
            ? Math.round(referralModule.getters.calculateDiscountedPrice(this.planAmount))
            : ''
    }

    get planInfo () {
        const statePlans = stripeModule.state.plans.value
        let intervalCount = 1
        let interval = 'month'
        let amount = null
        if (this.bundle) {
            if (isPromise(statePlans) || !statePlans || !statePlans[this.bundle.objectId]) {
                return null
            }
            const bundlePlans = statePlans[this.bundle.objectId]
            const bundlePlan = bundlePlans.find(plan => plan.id === this.plan?.value)
            intervalCount = bundlePlan ? bundlePlan.interval_count : 1
            interval = bundlePlan ? bundlePlan.interval : 'month'
            amount = bundlePlan?.amount
        }
        return {
            intervalCount,
            interval,
            amount,
        }
    }

    get subscriptionName () {
        if (this.planInfo) {
            if (this.planInfo.interval === 'month') {
                return this.planInfo.intervalCount === 1 ? 'Premium Prep Monthly' : 'Premium Prep Quarterly'
            }

            if (this.planInfo.interval === 'year' && this.planInfo.intervalCount === 1) {
                return 'Premium Prep Yearly'
            }
        }
        return ''
    }

    get renewDate () {
        if (this.planInfo?.interval && this.planInfo.intervalCount) {
            return formatDate(new Date(), this.planInfo.interval, this.planInfo.intervalCount)
        } else {
            return null
        }
    }

    async mounted () {
        // check to see if there is a valid referral in the URL
        await referralModule.actions.validateReferral()
        
        await stripeModule.actions.fetchStripePlans()

        this.plan = this.mappedPlanOptions.find(p => p.value === this.savedPlanId) || null
        this.planAmount = this.planInfo?.amount ? this.planInfo.amount : null

        if (import.meta.env.VUE_APP_IMPACT_UTT) {
            window.ire('generateClickId', (clickId: string) => {
                this.impactClickId = clickId
            })
        }

        this.planSubscriptionName = this.subscriptionName
    }

    updatePaymentParams (params: {
        addressEl: StripeElement | null | void
        cardElCompleted: boolean
        addressElCompleted: boolean
    }) {
        this.addressEl = params.addressEl
        this.cardElCompleted = params.cardElCompleted
        this.addressElCompleted = params.addressElCompleted
    }

    isAppleGooglePayAvailable (params: {
        available: boolean
    }) {
        this.appleGooglePayAvailable = params.available
    }


    openDropdown (isOpen: boolean) {
        this.isStateDropdownOpen = isOpen

        this.$nextTick(() => {
            const modalContainer = document.querySelector('.register') as HTMLElement
            modalContainer.scrollTop = modalContainer.scrollHeight
        })
    }

    async submitPayment () {
        if (this.isPaymentBeingProcessed) {
            return
        }

        this.isPaymentBeingProcessed = true

        if (import.meta.env.VUE_APP_STRIPE_PUBLISHABLE_KEY && this.plan && this.planAmount) {
            const { stripe, elements } = await stripeModule.actions.loadStripeAndElements({
                planAmount: this.planAmount,
                creatingPaymentField: false,
            })
            if (stripe && elements) {
                const disabledAppearance = stripeDisabledAppearance(false)
                const enabledAppearance = stripeEnabledAppearance(false)

                await elements.submit()
                // disable card fields while processing
                const paymentStripeEl = elements.getElement('payment')
                paymentStripeEl?.update({ readOnly: true })
                stripeModule.getters.getElements()?.update({ appearance: disabledAppearance })
                // retrieves clientSecret and subscriptionId
                const { clientSecret, subscriptionId } = await subscriptionModule.actions
                    .createIncompleteSubscription({
                        plan: this.plan?.value as string,
                        referralId: this.validReferral 
                            ? 'id' in this.validReferral 
                                ? this.validReferral.id
                                : this.validReferral.objectId
                            : '',
                        impactClickId: this.impactClickId,
                    })
                if (clientSecret && subscriptionId) {
                    // redirect we will 'keep it here'
                    // Register will redirect us on a success
                    const { error } = await stripe.confirmPayment({
                        elements: isProxy(elements) ? toRaw(elements) : elements,
                        clientSecret: clientSecret,
                        confirmParams: {
                            return_url: `${window.location.href}`,
                        },
                        redirect: 'if_required',
                    })

                    if (error && error.message) {
                        // show error message
                        this.errors.push(error.message)
                        this.isPaymentBeingProcessed = false
                        paymentStripeEl?.update({ readOnly: false })
                        stripeModule.getters.getElements()?.update({ appearance: enabledAppearance })
                        this.emitSubmitPayment({ successfulTransaction: false })
                        throw new Error(error.message)
                    } else {
                        try {
                            await subscriptionModule.actions.activateSubscription({
                                subscriptionId: subscriptionId,
                                plan: this.planSubscriptionName,
                            })
                            this.emitSubmitPayment({ 
                                successfulTransaction: true, subscriptionNumOfDays: this.numOfSubscriptionDays(),
                            })
                        } catch {
                            this.errors.push('Unable to process payment. Please try again.')
                            this.isPaymentBeingProcessed = false
                            paymentStripeEl?.update({ readOnly: false })
                            stripeModule.getters.getElements()?.update({ appearance: enabledAppearance })
                            this.emitSubmitPayment({ successfulTransaction: false })
                        }

                    }
                } else {
                    this.errors.push('Register Payment processing error')
                    this.isPaymentBeingProcessed = false
                    paymentStripeEl?.update({ readOnly: false })
                    stripeModule.getters.getElements()?.update({ appearance: enabledAppearance })
                    this.emitSubmitPayment({ successfulTransaction: false })
                    throw new Error('RegisterPayment - missing clientSecret or subscriptionID')
                }
            }
        }

        this.isPaymentBeingProcessed = false
    }

    async submitAppleGooglePayment (successfulTransaction: boolean) {
        if (this.isPaymentBeingProcessed) {
            return
        }

        this.isPaymentBeingProcessed = true

        // make sure confirm and activate payment was successful
        if (successfulTransaction) {
            this.emitSubmitPayment({ successfulTransaction: true, subscriptionNumOfDays: this.numOfSubscriptionDays() })
        } else {
            // if it was not a successful payment

            this.errors.push('Unable to process payment. Please try another payment method.')
            this.isPaymentBeingProcessed = false
            this.emitSubmitPayment({ successfulTransaction: false })
        }

        this.isPaymentBeingProcessed = false
    }

    agreedChanged (agreed: boolean) {
        this.agreeDisabled = agreed
    }

    numOfSubscriptionDays () {
        if (this.planInfo?.interval === 'month') {
            return this.planInfo.intervalCount * 30
        }

        if (this.planInfo?.interval === 'year') {
            return 365
        }

        return 0
    }

    @Watch('plan')
    updatePlanInfo () {
        if (this.planInfo) {
            if (this.planInfo.amount) {
                this.planAmount = this.planInfo.amount
            }
            this.planSubscriptionName = this.subscriptionName
        }
    }

    @Watch('cardElCompleted')
    updateErrorsWithCardChange (newVal: boolean, oldVal: boolean) {
        if (!oldVal && newVal && this.errors.length) {
            this.errors = []
        }
    }

    @Emit('error')
    emitError () {
        return true
    }

    @Emit('submitPayment')
    emitSubmitPayment (paymentParams: {successfulTransaction: boolean; subscriptionNumOfDays?: number}) {
        return paymentParams
    }
}
</script>
<style lang="scss" scoped>
.payment {
    width: 348px;
    margin: 33px auto 0;

    @include breakpoint(black-bear) {
        margin-top: 66px;
    }

    &--dropdown-open {
        margin-bottom: 140px;
    }

    &__blob {
        position: absolute;
        top: 0;
        right: 0;

        @include breakpoint(brown-bear) {
            display: none;
        }
    }

    &__field {
        margin-bottom: 17px;
    }

    &__plan {
        position: relative;
    }

    &__plan-tip {
        position: absolute;
        width: 199px;
        right: -233px;
        top: 5px;
        line-height: 18px;
        font-size: 13px;
        color: $slate-03;

        @include breakpoint(black-bear) {
            right: -2px;
            top: -43px;
            width: 135px;
        }

        &::before {
            content: '';
            position: absolute;
            left: -70px;
            top: -7px;
            background: url(@/assets/onboarding/tip-arrow.svg) no-repeat center center;
            width: 65px;
            height: 14px;
            transform: rotate(-10deg);

            @include breakpoint(black-bear) {
                transform: rotate(-50deg);
                top: 18px;
            }
        }
    }

    &__renew-date {
        font-size: 12px;
        margin: 6px 0 6px 12px;
        color: $slate;
    }

    &__apple-google-pay {
        margin: 16px 0 16px 0;
    }

    &__submit {
        margin: 32px auto 36px;
        display: block;

        svg {
            margin-left: 10px;
        }
    }
}
</style>