import type { Study } from '@pocketprep/types'
import type { utils } from '@pocketprep/ui-kit'
import { initLoadable } from '@/store/utils'
import type { Loadable } from '@/store/utils'

export type TQuizMode = (typeof utils.studyModes)[keyof typeof utils.studyModes]['shortName']

export type TActiveQuiz = {
    examGuid: string
    questions: Study.Class.QuestionJSON[]
    answers: (Study.Cloud.IQuizAnswer & { checked?: boolean })[]
    mode: TQuizMode
    startedAt: string
    currentQuestionIndex: number
    durationSeconds: number
    breakDurationSeconds: number
    timerType: string
    timeLimit?: number  // minutes
    showAnswersAtEnd?: boolean
    showCheckAnswerButton?: boolean
    answerSeeds?: number[][]
    numCorrectToLevelUp?: number
    prebuiltQuiz?: Study.Class.PrebuiltQuizJSON
}

type TQuizState = {
    quizzes: Study.Class.QuizJSON[]
    prebuiltQuizzes: Study.Class.PrebuiltQuizJSON[]
    activeQuiz: TActiveQuiz | null
    answeredQuestions: Loadable<Study.Class.QuestionJSON[]>
}

export const getInitialQuizState = (): TQuizState => {
    const activeQuizString = localStorage.getItem('activeQuiz')
    const activeQuiz = activeQuizString && JSON.parse(activeQuizString)

    return {
        quizzes: [],
        prebuiltQuizzes: [],
        activeQuiz: activeQuiz || null,
        answeredQuestions: initLoadable([]),
    }
}

const moduleState = getInitialQuizState()

export default moduleState
