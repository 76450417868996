<template>
    <div class="confidence-level">
        <div class="confidence-level__section">
            <div class="confidence-level__rating-section">
                <div class="confidence-level__rating-info">
                    <img
                        src="@/assets/register-onboard/confidence-rating.svg"
                        class="confidence-level__rating-stars"
                        alt=""
                    >
                    <div>
                        4.8 average app store rating
                    </div>
                </div>
                <div class="confidence-level__rating-message">
                    Show up confident on test day
                </div>
                <div
                    v-if="breakpoint !== 'black-bear'"
                    class="confidence-level__compare-btn"
                    tabindex="0"
                    @keydown.enter="toggleCompare"
                    @click="toggleCompare"
                    @mousedown.prevent
                >
                    {{ showCompare ? 'Hide Premium Details' : 'Premium Details' }}
                    <Icon
                        type="accordionArrow"
                        class="confidence-level__compare-arrow"
                        :class="{ 'confidence-level__compare-arrow--open': showCompare }"
                    />
                </div>
            </div>
            <div class="confidence-level__app-info">
                <div 
                    v-if="exam" 
                    class="confidence-level__info"
                >
                    <img
                        src="@/assets/register-onboard/question.svg"
                        class="confidence-level__question-svg"
                        alt=""
                    >
                    <div class="confidence-level__info-message">
                        <b>{{ exam.itemCount - exam.archivedCount }} questions</b> 
                        and explanations written by subject matter experts.
                    </div>
                </div>
                <div class="confidence-level__info">
                    <img
                        src="@/assets/register-onboard/subject-insights.svg"
                        class="confidence-level__subject-insights-svg"
                        alt=""
                    >
                    <div class="confidence-level__info-message">
                        <b>Multiple quiz modes</b> 
                        {{ exam.mockExams && exam.mockExams.length > 1 ?  
                            'and mock exams to gauge your exam readiness.' :  
                            exam.mockExams && exam.mockExams.length === 1 ?
                                'and a mock exam to gauge your exam readiness.' :
                                'to gauge your exam readiness.'
                        }}
                    </div>
                </div>
                <div class="confidence-level__info">
                    <img
                        src="@/assets/register-onboard/pass-guarantee.svg"
                        class="confidence-level__pass-guarantee-svg"
                        alt=""
                    >
                    <div class="confidence-level__pass-guarantee">
                        <b>Pass Guarantee:</b> If you fail your exam, we’ll give you three months free.*
                    </div>
                </div>
                <div class="confidence-level__asterisk-message">
                    *If you’ve been subscribed for three months, attempted all practice questions, 
                    and maintained an 80% average.
                </div>
            </div>
            <div
                v-if="breakpoint === 'black-bear'"
                class="confidence-level__compare-btn"
                tabindex="0"
                @keydown.enter="toggleCompare"
                @click="toggleCompare"
                @mousedown.prevent
            >
                {{ showCompare ? 'Hide Premium Details' : 'Premium Details' }}
                <Icon
                    type="accordionArrow"
                    class="confidence-level__compare-arrow"
                    :class="{ 'confidence-level__compare-arrow--open': showCompare }"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import type { Study } from '@pocketprep/types'
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { screenModule } from '@/store/screen/module'

@Component({
    components: {
        Icon: UIKit.Icon,
    },
})
export default class ConfidenceLevel extends Vue {
    @Prop() exam!: Study.Class.ExamMetadataJSON
    @Prop({ default: false }) showCompareView!: boolean

    showCompare = false

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    async toggleCompare () {
        this.showCompare = !this.showCompare
        this.emitToggleCompareView()
    }

    @Emit('toggleCompareView')
    emitToggleCompareView () {
        return this.showCompare
    }
}
</script>
<style lang="scss" scoped>
.confidence-level {

    &__section {
        display: flex;
        justify-content: space-evenly;
        border-top: 1px solid $banana-bread;
        background-color: $manilla;
        margin-top: 32px;
        width: 946px;
        min-height: 310px;

        @include breakpoint(brown-bear) {
            display: flex;
            justify-content: center;
            width: auto;
        }

        @include breakpoint(black-bear) {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: auto;
            min-height: 456px;
            justify-content: flex-start;
            margin-top: 26px;
        }
    }

    &__rating-section{
        width: 320px;
        height: 237px;
        margin-top: 31px;
        margin-left: 5px;

        @include breakpoint(brown-bear) {
            margin-left: 12px;
            margin-right: 12px;
        }

        @include breakpoint(black-bear) {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            height: fit-content;
            margin-left: 0;
        }
    }

    &__rating-info {
        display: flex;
        color: $pickled-bluewood;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; 

        @include breakpoint(black-bear) {
            display: flex;
        }
    }

    &__rating-stars {
        width: 103px;
        height: 17px;
        margin-right: 14px;
    }

    &__rating-message {
        margin-top: 8px;
        color: $brand-black;
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        width: 230px;
    }

    &__compare-btn {
        color: $brand-blue;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        cursor: pointer;
        user-select: none;
        position: relative;
        margin-top: 23px;

        @include breakpoint(black-bear) {
            margin-top: 32px;
        }
    }

    &__compare-arrow {
        margin-bottom: 1px;
        margin-left: 5px;

        &--open {
            transform: rotate(180deg);
            margin-bottom: 2px;
            margin-left: 5px;
        }
    }

    &__app-info{
        width: 340px;
        height: 237px;
        margin-top: 31px;
        margin-right: 40px;

        @include breakpoint(brown-bear) {
            width: 316px;
            margin-right: 25px;
        }

        @include breakpoint(black-bear) {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: fit-content;
            margin-left: 0;
            margin-right: 0;
            margin-top: 17px;
        }
    }

    &__info {
        display: flex;
        align-items: start;
    }

    &__info-message {
        color: $brand-black;
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
        margin-top: 5px;
        margin-left: 15px;
        margin-bottom: 20px;

        @include breakpoint(black-bear) {
            width: 277px;
        }
    }

    &__pass-guarantee {
        color: $brand-black;
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
        margin-top: 5px;
        margin-left: 15px;
        margin-bottom: 9px;

        @include breakpoint(black-bear) {
            width: 277px;
        }
    }

    &__asterisk-message {
        color: $slate;
        font-size: 12px;
        font-weight: 500;
        width: 250px;
        margin-left: 45px;

        @include breakpoint(black-bear) {
            width: 242px;
            margin-left: 18px;
        }
    }
}
</style>