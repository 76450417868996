import { fetchLoadable } from '@/store/utils'
import { runCloudFunction } from '@/store/parseUtils'
import type { Study } from '@pocketprep/types'
import { stripeModule } from '@/store/stripe/module'
import { type Stripe as StripeNode } from 'stripe'
import { userModule } from '@/store/user/module'
import { analyticsModule } from '@/store/analytics/module'
import { loadStripe, type Stripe, type StripeElements } from '@stripe/stripe-js'
import { stripeEnabledAppearance } from '@/utils'
import { resetLoadable } from '@/store/utils'

const validatePayment = async ({ name, city, state, country, cardEl, stripe, plan }: {
    name?: string | null
    city?: string | null
    state?: { label: string; value: string } | null
    country?: { label: string; value: string } | null
    plan?: { label: string; value: string } | null
    cardEl?: stripe.elements.Element | null | void
    stripe?: stripe.Stripe | null
}) => {
    const errors: string[] = []
    const errorFields: string[] = []
    const errorCodes = {
        invalidCard: 'Invalid credit card information.',
        processingError: 'Unable to process credit card information. Please try again or contact support.',
    }

    try {
        if (!name) {
            errors.push('Please enter the cardholder name.')
            errorFields.push('name')
        }

        if (!country) {
            errors.push('Please enter a country.')
            errorFields.push('country')
        }

        if (!city) {
            errors.push('Please enter a city.')
            errorFields.push('city')
        }

        if (!state && country?.value === 'US') {
            errors.push('Please enter a state.')
            errorFields.push('state')
        }

        if (!plan) {
            errorFields.push('plan')
            throw new Error('No plan or bundle selected.')
        }

        if (!stripe || !cardEl) {
            errorFields.push('card')
            throw new Error(errorCodes.invalidCard)
        }

        const tokenResponse = await stripe.createToken(cardEl)

        if (tokenResponse.error && tokenResponse.error.message) {
            errorFields.push('card')
            throw new Error(tokenResponse.error.message)
        }

        if (!tokenResponse.token) {
            errors.push(errorCodes.processingError)
            errorFields.push('card')
        }

        if (!errors.length) {
            return {
                planId: plan?.value as string,
                stripeToken: tokenResponse.token as StripeNode.Token,
                name: name as string,
                country: country?.value as string,
                city: city as string,
                state: country?.value === 'US' ? state?.value as string : '',
            }
        }
    } catch (err) {
        const error = (err as { error?: string }).error || (err as { message?: string }).message
        if (!err) {
            errors.push('Unable to process payment.')
        } else if (error) {
            errors.push(error)
        } else if (err instanceof Error || typeof err === 'string') {
            errors.push(err.toString())
        }
    }

    return { errors, errorFields }
}

const deleteStripePM = async (paymentMethodId: string) => {
    await runCloudFunction<Study.Cloud.deletePaymentMethod>('deletePaymentMethod', { paymentMethodId });
    (await stripeModule.state.payment.value).paymentMethods = (await stripeModule.state.payment.value)
        .paymentMethods.filter(pm => pm.id !== paymentMethodId)
}

const updateStripeSubscription = async (params: {
    paymentMethodId?: string
    planId: string
    subscriptionId: string
    planChanged: boolean
}) => {
    await runCloudFunction<Study.Cloud.updateSubscription>('updateSubscription', params)
    await Promise.all([
        fetchStripeSubscriptions(true),
        userModule.actions.fetchUserData({ stripeSubs: true }),
    ])
    analyticsModule.actions.updateIntercom()
}

const fetchStripeSubscriptions = async (forceFetch?: boolean) => {
    const user = userModule.state.user
    const customerId = user?.stripeCustomerId

    if (!user) {
        throw new Error('Unable to find user.')
    }

    if (!customerId) {
        return
    }
    
    await fetchLoadable(
        stripeModule.state.subscriptions, 
        () => runCloudFunction<Study.Cloud.fetchStripeSubscriptions>('fetchStripeSubscriptions', { customerId }),
        forceFetch
    )
}

const fetchPaymentMethods = async (forceFetch?: boolean) => {
    await fetchLoadable(
        stripeModule.state.payment, 
        () => runCloudFunction<Study.Cloud.fetchPaymentMethods>('fetchPaymentMethods'),
        forceFetch
    )
}

const fetchStripePlans = async () => {
    await fetchLoadable(stripeModule.state.plans, async () => {
        const plans = await runCloudFunction<Study.Cloud.fetchStripePlans>('fetchStripePlans')

        return plans.reduce((acc, p) => {
            const bundleId = p.id.split('_')[0]
            if (!bundleId) {
                return acc
            }
            if (bundleId && !(bundleId in acc)) {
                acc[bundleId] = []
            }
            acc[bundleId]?.push(p)
            return acc
        }, {} as { [bundleId: string]: StripeNode.Plan[] })
    })
}

const loadStripeAndElements = async ({
    planAmount,
    creatingPaymentField,
    oneTimePurchase,
}: { 
    planAmount: number 
    creatingPaymentField: boolean
    oneTimePurchase?: boolean
}): Promise<{ stripe: Stripe | null; elements: StripeElements | null }> => {
    const isDarkMode = userModule.state.settings.isDarkMode
    const appearance = stripeEnabledAppearance(isDarkMode)

    if (creatingPaymentField && stripeModule.getters.getStripe() && stripeModule.getters.getElements()) {
        // when an element is mounting/creating stripe elements and we already have a created elements
        // go ahead and destroy them so that new elements can be created with any updated info
        // example upgrade/add payment side panels get opened multiple times helps with error states
        const stripeEls = stripeModule.getters.getElements()
        stripeEls?.getElement('payment')?.destroy()
        stripeEls?.getElement('address')?.destroy()
        stripeEls?.getElement('paymentRequestButton')?.destroy()
        stripeModule.state.elements = null
        stripeModule.state.stripe = null
    }

    if (!creatingPaymentField && stripeModule.getters.getStripe() && stripeModule.getters.getElements()) {
        // payment or adding payment workflows need the existing element that was updated
        return {
            stripe: stripeModule.getters.getStripe(),
            elements: stripeModule.getters.getElements(),
        }
    }

    if (import.meta.env.VUE_APP_STRIPE_PUBLISHABLE_KEY) {
        await fetchStripePlans()
        const stripe = await loadStripe(import.meta.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
        if (stripe) {
            stripeModule.state.stripe = stripe

            const elements = stripe.elements({
                mode: oneTimePurchase ? 'payment' : 'subscription',
                amount: planAmount,
                currency: 'usd',
                appearance,
                ...(oneTimePurchase && { paymentMethodTypes: [ 'card' ] }), // Conditionally adds 'paymentMethodTypes'
            })

            stripeModule.state.elements = elements

            return {
                stripe,
                elements,
            }
        }
    }
    return {
        stripe: null,
        elements: null,
    }
}

const createSetupIntent = async () => {
    const stripeClientSecret = 
        await runCloudFunction<Study.Cloud.createSetupIntent>('createSetupIntent')
    return stripeClientSecret
}

const loadNewPaymentMethods = async () => {
    resetLoadable(stripeModule.state.payment)
    await Promise.all([
        stripeModule.actions.fetchPaymentMethods(),
    ])
}

export default {
    fetchStripePlans,
    fetchPaymentMethods,
    fetchStripeSubscriptions,
    updateStripeSubscription,
    validatePayment,
    deleteStripePM,
    loadStripeAndElements,
    createSetupIntent,
    loadNewPaymentMethods,
}
