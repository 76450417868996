<template>
    <div v-dark class="no-results-blob">
        <!-- eslint-disable -->
        <svg width="38px" height="33px" viewBox="0 0 38 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title></title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-701.000000, -284.000000)" fill="currentColor">
                    <g transform="translate(701.000000, 284.000000)">
                        <path d="M0,17.758932 C0,24.340517 8.08009787,33.6455166 15.8908591,32.9646629 C23.7016204,32.2838093 32.6905422,28.65259 35.6589341,23.205761 C38.6273261,17.758932 37.1429285,10.7234446 30.7043719,4.59576196 C24.2658153,-1.53192065 14.5441762,-1.53192065 8.61877106,4.59576196 C2.69336596,10.7234446 0,11.177347 0,17.758932 Z"></path>
                    </g>
                </g>
            </g>
        </svg>
        <!-- eslint-enable -->
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class NoResultsBlob extends Vue {

}
</script>

<style lang="scss" scoped>
// @import '@pocketprep/ui-kit/styles/_colors';

.no-results-blob {
    color: $white;

    &--dark {
        color: $moonlit-ocean;
    }
}
</style>