<template>
    <Portal to="sidepanel">
        <SidePanel
            :key="`addExamSidePanel-${String($.vnode.key)}`"
            name="Add Exam"
            class="add-exam"
            width="medium"
            :is-dark-mode="isDarkMode"
            :class="{ 'add-exam--full-height': showFullHeight }"
            @close="emitClose"
        >
            <div v-if="selectedExamVersions.length > 1" class="add-exam__versions">
                <div class="add-exam__title">
                    Choose Exam Version
                </div>
                <div class="add-exam__description">
                    What exam is right for you? You can change your exam version at any time in settings.
                </div>
                <PocketButton
                    type="icon"
                    icon="arrow"
                    class="add-exam__back"
                    @click="selectedExam = null"
                >
                    Back to Browse
                </PocketButton>
                <ExamVersionCard
                    v-for="(exam, index) in selectedExamVersions"
                    :key="exam.objectId"
                    :exam="exam"
                    :adding-exam="true"
                    :is-selected-version="selectedVersion === exam.version"
                    :is-latest-version="index === 0"
                    @clickExamVersion="selectVersion"
                />
            </div>
            <BundleSearch
                v-else
                :exam-metadata-by-id="examMetadata"
                :bundles="bundles"
                :premium-bundle-ids="premiumBundleIds"
                :premium-exam-ids="premiumExamIds"
                class="add-exam__bundle-search"
                :is-dark-mode="isDarkMode"
                @select="selectExam"
            />
            <template #action>
                <PocketButton
                    :is-dark-mode="isDarkMode"
                    :disabled="!selectedExam || (selectedExamVersions.length > 1 && !selectedVersion)"
                    :is-loading="isLoading"
                    @click="addExam"
                >
                    Add Exam
                </PocketButton>
            </template>
        </SidePanel>
    </Portal>
</template>

<script lang="ts">
import { bundleModule } from '@/store/bundle/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import type { TBundleExam } from '@/components/Register/ChooseExam.vue'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { userModule } from '@/store/user/module'
import { subscriptionModule } from '@/store/subscription/module'
import { toastModule } from '@/store/toast/module'
import ExamVersionCard from '@/components/Settings/EditExam/ExamVersionCard.vue'
import type { Study } from '@pocketprep/types'

@Component({
    components: {
        SidePanel: UIKit.SidePanel,
        PocketButton: UIKit.Button,
        BundleSearch: UIKit.BundleSearch,
        ExamVersionCard,
        Icon: UIKit.Icon,
    },
})
export default class AddExamSidePanel extends Vue {
    @Prop({ default: false }) showFullHeight!: boolean
    
    isLoading = true
    selectedExam: TBundleExam | null = null
    selectedVersion: null | string = null

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get bundles () {
        return [ ...bundleModule.getters.getBundles() ]
            .filter(bundle => bundle.name !== 'Finance')
            .sort((a, b) => a.name.localeCompare(b.name))
    }
    
    get examMetadata () {
        return examMetadataModule.getters.getMostRecentExamMetadataById()
    }

    get userExamMetadata () {
        return userExamMetadataModule.getters.getUserExamMetadataByGuid()
    }

    get premiumBundleIds () {
        return subscriptionModule.getters.premiumBundleIds()
    }

    get premiumExamIds () {
        return subscriptionModule.getters.premiumExamIds()
    }

    get selectedExamBundle () {
        return this.bundles.find(b => b.exams.find(e => e.objectId === this.selectedExam?.objectId))
    }

    get selectedExamVersions () {
        return ([ ...this.selectedExamBundle?.exams
            .map(e => examMetadataModule.getters.getExamMetadataById()[e.objectId]) || [] ])
            .filter(e => e && e.examGuid === this.selectedExam?.examGuid)
            .sort((a, b) => -a.version.localeCompare(b.version, undefined, { numeric: true })) || []
    }

    async mounted () {
        await Promise.all([
            bundleModule.actions.fetchBundles(),
            examMetadataModule.actions.fetchExamMetadata(),
            userExamMetadataModule.actions.fetchUserExamMetadata(),
            userModule.actions.fetchUserData(),
        ])
        
        this.isLoading = false
    }

    selectVersion (exam: Study.Class.ExamMetadataJSON) {
        if (!this.selectedExamBundle) {
            return
        }

        this.selectedVersion = exam.version
        this.selectedExam = {
            ...exam,
            bundle: this.selectedExamBundle,
        }
    }

    selectExam (exam: TBundleExam) {
        this.selectedExam = exam
    }

    async addExam () {
        this.isLoading = true
        const exam = this.selectedExam
        if (!exam) {
            this.isLoading = false
            return
        }
        if (!this.userExamMetadata[exam.examGuid]) {
            const upgradesSeen = []
            if (this.selectedExamVersions.length > 1) {
                upgradesSeen.push(
                    ...this.selectedExamVersions
                        .slice(0, this.selectedExamVersions.length - 1)
                        .map(e => e?.version.split('.')[0])
                )
            }
            await userExamMetadataModule.actions.upsertUserExamMetadata({
                examGuid: exam.examGuid,
                examVersion: exam.version,
                flaggedQuestions: [],
                upgradesSeen,
                disabledSubjects: [],
            })
        }
        await userModule.actions.updateCurrentExamGuid(exam.examGuid)
        const premiumMessage = subscriptionModule.getters.getSubscriptionForExamId(exam.objectId)
            ? ''
            : ' Upgrade to Premium to get the full experience!'
        toastModule.actions.displayToast({ 
            title: `${exam.nativeAppName} added successfully`,
            subtext: `You are now studying the ${exam.nativeAppName} exam.${premiumMessage}`,
        })
        this.emitClose()
        this.isLoading = false
    }

    @Emit('close')
    emitClose () {
        return true
    }
}
</script>
<style lang="scss" scoped>
.add-exam {
    &--full-height {
        top: 0;
        height: 100%;
        z-index: 1003;

        @include breakpoint(black-bear) {
            height: calc(100% - 10px);
        }
    }

    &__title {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 8px;
        font-weight: 600;
    }

    &__description {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 16px;
        color: $ash;
    }

    &__back {
        margin-bottom: 14px;
        margin-left: -14px;

        :deep(svg) {
            transform: rotate(180deg);
        }
    }

    &__bundle-search {
        height: auto;
        padding-bottom: 50px;
        margin: 0 -20px;

        @include breakpoint(black-bear) {
            width: calc(100% + 18px);
            margin: 0 -9px;
        }
    }
}
</style>