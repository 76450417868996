<template>
    <Portal to="modal">
        <ModalContainer
            key="examDateModal"
            class="exam-date"
            :is-dark-mode="isDarkMode"
            @close="emitClose"
        >
            <template #modal>
                <Modal
                    :is-dark-mode="isDarkMode"
                    class="exam-date__modal"
                    :show-close-button="true"
                    @close="emitClose"
                >
                    <div tabindex="-1" class="exam-date__title">
                        Set Your Exam Date
                    </div>
                    <Calendar
                        v-if="hasExamDateLoaded"
                        v-model="examDate"
                        class="exam-date__calendar"
                        label="Scheduled Exam Date"
                        :is-dark-mode="isDarkMode"
                        @keydown.enter="!isLoading && examDate && saveExamDate()"
                    />
                    <div class="exam-date__buttons">
                        <PocketButton
                            :is-dark-mode="isDarkMode"
                            type="secondary"
                            @click="emitClose"
                        >
                            Cancel
                        </PocketButton>
                        <PocketButton
                            :is-dark-mode="isDarkMode"
                            :is-loading="isLoading"
                            :disabled="!hasExamDateChanged"
                            @click="saveExamDate"
                        >
                            Save Date
                        </PocketButton>
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Emit } from 'vue-facing-decorator'
import * as Sentry from '@sentry/browser'
import { bundleModule } from '@/store/bundle/module'
import { subscriptionModule } from '@/store/subscription/module'
import { examMetadataModule } from '@/store/examMetadata/module'

@Component({
    components: {
        Calendar: UIKit.Calendar,
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
        PocketButton: UIKit.Button,
    },
})
export default class ExamDateModal extends Vue {
    examDate: Date | null = null
    isLoading = true
    hasExamDateLoaded = false

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get userExamMetadata () {
        return userExamMetadataModule.getters.getCurrentUserExamMetadata()
    }

    get bundle () {
        return bundleModule.getters.getCurrentBundle()
    }

    get currentUserExamMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get hasExamDateChanged () {
        const uem = this.userExamMetadata
        const existingExamDate = uem?.scheduledExamDate
        if (existingExamDate && this.examDate) {
            return new Date(existingExamDate.iso).getTime() !== this.examDate.getTime()
        } else if (!existingExamDate && !this.examDate) {
            return false
        } else {
            return true
        }
    }

    get hasSubscription () {
        return !!subscriptionModule.getters.getSubscriptionForExamId(this.userExamMetadata?.objectId)
    }

    async mounted () {
        await userModule.actions.fetchUserData()
        const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
        if (uem?.scheduledExamDate) {
            this.examDate = new Date(uem.scheduledExamDate.iso)
        }
        this.hasExamDateLoaded = true
        this.isLoading = false

        setTimeout(() => {
            const titleEl = document.querySelector('.exam-date__title') as HTMLElement | null
            titleEl?.focus()
        }, 0)
    }

    async saveExamDate () {
        if (!this.hasExamDateChanged) {
            return
        }

        this.isLoading = true
        const date = this.examDate || undefined

        // if date is somehow not valid, capture date and send to Sentry
        if (date && isNaN(date.getTime())) {
            Sentry.captureException(new Error(`Invalid date: ${this.examDate}`))
        } else {
            if (this.userExamMetadata && this.bundle && this.currentUserExamMetadata) {
                await userExamMetadataModule.actions.setScheduledExamDate({
                    scheduledExamDate: this.examDate || undefined,
                    examNativeName: this.currentUserExamMetadata?.nativeAppName,
                    uemCreatedAt: this.userExamMetadata?.createdAt,
                    uemObjectId: this.userExamMetadata?.objectId,
                    bundle: this.bundle,
                    hasSubscription: this.hasSubscription,
                })
            }
        }

        this.isLoading = false
        this.emitClose()
    }

    @Emit('close')
    emitClose () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.exam-date {
    color: $brand-black;
    z-index: 9998;

    &__modal {
        padding: 35px 25px 22px;
        width: 424px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 80px;
        max-width: 95%;
        box-sizing: border-box;

        @include breakpoint(black-bear) {
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__title {
        margin-bottom: 38px;
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        text-align: center;
        outline: none;
    }

    &__calendar {
        margin-bottom: 41px;
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 220px;
        margin: 0 auto;
    }
}
</style>