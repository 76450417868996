import { initLoadable } from '@/store/utils'
import type { Loadable } from '@/store/utils'
import type { Study } from '@pocketprep/types'

// This is a Mock of Quiz without the 'user' property
export type TQuizDraft = Omit<Study.Class.QuizPayload, 'user'>

type TQotDState = {
    question: Loadable<Study.Class.QuestionJSON | null>
    globalMetric: Loadable<Study.Class.GlobalQuestionMetricJSON | null>
    sharedQotDQuizDraft: null | TQuizDraft
}

export const getInitialQotDState = (): TQotDState => {
    const sharedQotDQuizDraft = JSON.parse(localStorage.getItem('sharedQotDQuizDraft') || '{}')
    return {
        question: initLoadable(null),
        globalMetric: initLoadable(null),
        sharedQotDQuizDraft: Object.keys(sharedQotDQuizDraft).length ? sharedQotDQuizDraft : null,
    }
}

const moduleState = getInitialQotDState()

export default moduleState
