import type { Study } from '@pocketprep/types'
import { objPointer, Parse, runCloudFunction } from '@/store/parseUtils'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { fetchLoadable, resetLoadable } from '@/store/utils'
import { userModule } from '@/store/user/module'
import { progressModule } from '@/store/progress/module'
import { analyticsModule } from '@/store/analytics/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { questionModule } from '@/store/question/module'
import { globalQuestionMetricModule } from '@/store/globalQuestionMetric/module'
import { mockExamModule } from '@/store/mockExam/module'
import { qotdModule } from '@/store/qotd/module'
import { quizModule } from '@/store/quiz/module'
import { readinessModule } from '@/store/readiness/module'

const deleteUserExamMetadata = async (userExamMetadata: Study.Class.UserExamMetadataJSON) => {
    const uem = await new Parse.Query<Study.Class.UserExamMetadata>('UserExamMetadata').get(userExamMetadata.objectId)
    await uem.destroy()
    delete (await userExamMetadataModule.state.userExamMetadataByGuid.value)[userExamMetadata.examGuid]
    userExamMetadataModule.state.userExamMetadataByGuid.value = { 
        ...userExamMetadataModule.state.userExamMetadataByGuid.value, 
    }
}

const resetExamProgress = async (examMetadata: Study.Class.ExamMetadataJSON) => {
    const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    if (!uem) {
        throw new Error('No UserExamMetadata found.')
    }
    await runCloudFunction<Study.Cloud.resetQuizProgress>('resetQuizProgress', { examGuid: examMetadata.examGuid })
    resetLoadable(userModule.state.userData)
    resetLoadable(readinessModule.state.readinessScores)
    resetLoadable(userExamMetadataModule.state.userExamMetadataByGuid)
    await userModule.actions.fetchUserData()
    
    // if this is an exam you aren't actively studying, reset and refetch all progress
    if (uem.examGuid !== examMetadata.examGuid) {
        resetLoadable(progressModule.state.progressByExamGuid)
        await progressModule.actions.fetchProgress()
    }
    
    analyticsModule.actions.updateIntercom()
}

export type TUpsertUserExamMetadata = 
    (Partial<Study.Class.UserExamMetadataPayload> & { objectId: string }) 
    | (Omit<Study.Class.UserExamMetadataPayload, 'user'> & { user?: Study.Class.User | Parse.Pointer })
const upsertUserExamMetadata = async (
    params: TUpsertUserExamMetadata, 
    options?: { forceInsert?: boolean; isChangingExamVersion?: boolean }
) => {
    const currentUser = Parse.User.current()

    if (!currentUser) {
        throw new Error('No user found.')
    }

    // if no objectId is passed, check that user does not already have a UEM record for that examGuid
    let uem
    if (!options?.forceInsert && !params.objectId && params.examGuid) {
        uem = await new Parse.Query<Study.Class.UserExamMetadata>('UserExamMetadata')
            .equalTo('examGuid', params.examGuid)
            .first()
        params.objectId = uem?.id
    }
    
    const updateUserExamMetadata = new Parse.Object<TUpsertUserExamMetadata>('UserExamMetadata', {
            ...params,
            user: currentUser as Study.Class.User,
        }),
        userACL = new Parse.ACL(currentUser)

    updateUserExamMetadata.setACL(userACL)

    await updateUserExamMetadata.save()

    const updatedUserExamMetadata = uem || await new Parse.Query<Study.Class.UserExamMetadata>('UserExamMetadata')
        .get(updateUserExamMetadata.id)
    if (updatedUserExamMetadata) {
        const userExamMetadataByGuid = await userExamMetadataModule.state.userExamMetadataByGuid.value
        userExamMetadataModule.state.userExamMetadataByGuid.value = {
            ...userExamMetadataByGuid,
            [updatedUserExamMetadata.get('examGuid')]: updatedUserExamMetadata.toJSON(),
        }
    }

    if (options?.isChangingExamVersion) {
        // fetch all data similar to switching an exam (updateCurrentExamGuid)
        resetLoadable(userModule.state.userData)
        resetLoadable(readinessModule.state.readinessScores)
        resetLoadable(questionModule.state.serialQuestionInfoLib)
        resetLoadable(qotdModule.state.question)
        resetLoadable(qotdModule.state.globalMetric)
        resetLoadable(progressModule.state.progressByExamGuid)
        resetLoadable(quizModule.state.answeredQuestions)
        resetLoadable(globalQuestionMetricModule.state.globalQuestionMetricsBySerial)
        resetLoadable(mockExamModule.state.mockExams)
        await userModule.actions.fetchUserData({ userData: true })
        await Promise.all([
            questionModule.actions.fetchSerialQuestionInfoLib(),
            qotdModule.actions.fetchCurrentQotDQuestion(),
            qotdModule.actions.fetchCurrentQotDMetric(),
            progressModule.actions.fetchProgress(),
            globalQuestionMetricModule.actions.fetchGlobalQuestionMetrics(),
            quizModule.actions.fetchAnsweredQuestions(),
            mockExamModule.actions.fetchMockExams(),
        ])
    }

    analyticsModule.actions.updateIntercom()
}

const setScheduledExamDate = async (params: {
    scheduledExamDate: Date | undefined
    examNativeName: string
    uemCreatedAt: string
    uemObjectId: string
    bundle: Study.Class.BundleJSON
    hasSubscription: boolean

}) => {
    return upsertUserExamMetadata({
        objectId: params.uemObjectId,
        scheduledExamDate: params.scheduledExamDate,
    })
}

const fetchUserExamMetadata = async (forceFetch?: boolean) => {
    const currentUser = userModule.state.user

    if (currentUser) {
        await fetchLoadable(userExamMetadataModule.state.userExamMetadataByGuid, async () => {
            const userExamMetadata = await new Parse.Query<Study.Class.UserExamMetadata>('UserExamMetadata')
                .equalTo('user', objPointer(currentUser.objectId, '_User'))
                .findAll()
    
            // we need to sort the UEM in case user has multiple records. We only care about the most recently updated.
            const sortedUEM = userExamMetadata.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1)
    
            return sortedUEM.reduce((acc, uem) => {
                if (!(uem.get('examGuid') in acc)) {
                    acc[uem.get('examGuid')] = uem.toJSON()
                }
                return acc
            }, {} as { [examGuid: string]: Study.Class.UserExamMetadataJSON })
        }, forceFetch)
    }
}

const toggleQuestionFlag = async (serial?: string) => {
    if (!serial) {
        throw new Error('Unable to flag question without a serial')
    }

    const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    if (!uem) {
        throw new Error('No UserExamMetadata found.')
    }

    const parseUEM = new Parse.Object('UserExamMetadata')
    parseUEM.set('objectId', uem.objectId)

    const currentFlaggedQs = uem.flaggedQuestions || []
    if (currentFlaggedQs.includes(serial)) {
        parseUEM.remove('flaggedQuestions', serial)
    } else {
        parseUEM.addUnique('flaggedQuestions', serial)
    }

    await parseUEM.save()
    const updatedUEM: Study.Class.UserExamMetadataJSON = {
        ...uem,
        flaggedQuestions: parseUEM.get('flaggedQuestions'),
    };
    (await userExamMetadataModule.state.userExamMetadataByGuid.value)[uem.examGuid] = updatedUEM
    userExamMetadataModule.state.userExamMetadataByGuid.value = {
        ...userExamMetadataModule.state.userExamMetadataByGuid.value,
    }
}

const startNewLevels = async ({ subjectNames }: {
    subjectNames: string[]
}) => {
    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()

    if (currentExam) {
        const levelUpProgress = await runCloudFunction<Study.Cloud.startNewLevels>('startNewLevels', {
            examGuid: currentExam.examGuid,
            majorVersion: currentExam.version.split('.')[0],
            subjectNames,
        })

        resetLoadable(userExamMetadataModule.state.userExamMetadataByGuid)
        await fetchUserExamMetadata()
    
        return levelUpProgress
    }
}

const resetLevelUpProgress = async () => {
    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()
    const examGuid = currentExam?.examGuid
    const majorVersion = currentExam?.version.split('.')[0]
    if (examGuid && majorVersion) {
        await runCloudFunction<Study.Cloud.resetLevelUpProgress>('resetLevelUpProgress', {
            examGuid,
            majorVersion, 
        })

        resetLoadable(userExamMetadataModule.state.userExamMetadataByGuid)
        await fetchUserExamMetadata()
    }
}

export default {
    upsertUserExamMetadata,
    setScheduledExamDate,
    fetchUserExamMetadata,
    resetExamProgress,
    deleteUserExamMetadata,
    toggleQuestionFlag,
    startNewLevels,
    resetLevelUpProgress,
}
