import { subscriptionModule } from '@/store/subscription/module'
import { bundleModule } from '@/store/bundle/module'
import { examMetadataModule } from '@/store/examMetadata/module'

const getSubscriptionForExamId = (examId?: string) => {
    const subscriptions = subscriptionModule.state.subscriptions
    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()
    const examMetadataById = examMetadataModule.getters.getExamMetadataById()
    const bundles = bundleModule.getters.getBundles()

    const exam = examId 
        ? examMetadataById[examId]
        : currentExam && examMetadataById[currentExam.objectId]
    if (!exam) {
        return false
    }

    if (exam.isFree) {
        return true
    }

    return subscriptions
        .find(s => {
            if (new Date(s.expiresAt.iso).getTime() < new Date().getTime()) {
                return false
            }

            if ('bundle' in s) {
                const bundle = bundles.find(b => b.objectId === s.bundle.objectId)
                return !!bundle?.exams.find(be => {
                    const bundleExam = examMetadataById[be.objectId]
                    return bundleExam?.examGuid === exam.examGuid
                })
            } else {
                return s.examGuid === exam.examGuid
            }
        }) || false
}

const premiumExamIds = () => {
    const subscriptions = subscriptionModule.state.subscriptions
    const examMetadata = examMetadataModule.getters.getExamMetadata()
    const premiumIndivExamIds = subscriptions
        .map(s => 'examGuid' in s && examMetadata.filter(e => e.examGuid === s.examGuid).map(e => e.objectId))
        .filter(s => s)
    const bundles = bundleModule.getters.getBundles()
    const premiumBundles = bundles.filter(b => premiumBundleIds().includes(b.objectId))

    return [
        ...premiumBundles.map(b => b.exams.map(e => e.objectId)).flat(),
        ...premiumIndivExamIds.flat(),
    ]
}

const premiumBundleIds = () => {
    const subscriptions = subscriptionModule.state.subscriptions

    return subscriptions
        .filter(s => new Date(s.expiresAt.iso).getTime() > new Date().getTime())
        .map(s => 'bundle' in s && s.bundle.objectId)
        .filter(id => !!id)
}

export default {
    getSubscriptionForExamId,
    premiumBundleIds,
    premiumExamIds,
}