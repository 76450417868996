import { userModule } from '@/store/user/module'

const userInitial = () => {
    const user = userModule.state.user
    if (!user) {
        return ''
    }
    
    return user.firstName
        ? user.firstName.substring(0, 1)
        : user.email.substring(0, 1)
}

const userFullName = () => {
    const user = userModule.state.user
    if (!user) {
        return ''
    }

    return user.firstName
        ? `${user.firstName} ${user.lastName}`
        : user.email.split('@')[0]
}

const getReferralInfo = () => {
    return userModule.state.referralInfo
}

const getUserDomain = () => {
    const user = userModule.state.user
    if (!user || !user.email) {
        return ''
    }
    return user.email.split('@')[1]
}

const getCurrentOrganizationId = () => {
    const user = userModule.state.user
    if (!user || !user.currentOrganization) {
        return null
    }
    return user.currentOrganization.objectId
}

export default {
    userInitial,
    userFullName,
    getReferralInfo,
    getUserDomain,
    getCurrentOrganizationId,
}
