<script setup lang="ts">
import { useReadinessMessage } from '@/store/readiness/composables'
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { computed, ref } from 'vue'
const {
    ModalContainer,
    Modal,
    Button,
} = UIKit

const props = defineProps<{
    isUpdatingExamDate?: boolean
    isEligibleForSurvey?: boolean
    readinessScore: number
    percentQuestionBankComplete: number
    isActiveLast10Days: boolean
    daysUntilExam: number
}>()

const progress = ref(Math.round((props.readinessScore * 100) / 2))

const { summary, suggestion } = useReadinessMessage({
    readinessScore: props.readinessScore,
    percentQuestionBankCompleted: props.percentQuestionBankComplete,
    isActiveLast10Days: props.isActiveLast10Days,
    daysUntilExam: props.daysUntilExam,
})

const isDarkMode = computed(() => {
    return userModule.state.settings.isDarkMode
})

const scoreTheme = computed(() => {
    return progress.value >= 40 ? 'ready'
        : progress.value >= 25 ? 'improving'
            : 'foundations'
})

const emit = defineEmits<{
    close: []
    previous: []
    next: []
}>()

</script>

<template>
    <Portal to="modal">
        <ModalContainer
            :is-dark-mode="isDarkMode"
            @close="emit('close')"
        >
            <template #modal>
                <Modal
                    class="readiness-modal__modal"
                    :show-close-button="true"
                    :is-dark-mode="isDarkMode"
                    @close="emit('close')"
                >
                    <div class="readiness-modal" v-dark="isDarkMode">
                        <h1
                            ref="readiness-modal__title"
                            class="readiness-modal__title"
                            tabindex="-1"
                        >
                            Exam Readiness
                        </h1>
                        <div class="readiness-modal__score">{{ progress }}</div>
                        <div class="readiness-modal__progress-container">
                            <div class="readiness-modal__progress-labels-container">
                                <div
                                    class="readiness-modal__progress-label-foundations"
                                    :class="{'readiness-modal__progress-label-foundations--active':
                                        scoreTheme=== 'foundations'}"
                                    v-dark
                                >
                                    Foundations
                                </div>
                                <div
                                    class="readiness-modal__progress-label-improving"
                                    :class="{'readiness-modal__progress-label-improving--active':
                                        scoreTheme=== 'improving'}"
                                    v-dark
                                >
                                    Improving
                                </div>
                                <div
                                    class="readiness-modal__progress-label-ready"
                                    :class="{'readiness-modal__progress-label-ready--active':
                                        scoreTheme=== 'ready'}"
                                    v-dark
                                >
                                    Ready
                                </div>
                            </div>
                            <div class="readiness-modal__progress-bar" v-dark>
                                <div
                                    class="readiness-modal__progress-bar-filled"
                                    :class="`readiness-modal__progress-bar-filled--${scoreTheme}`"
                                    :style="{
                                        width: `${progress * 2}%`
                                    }"
                                    v-dark
                                >
                                </div>
                                <div
                                    class="readiness-modal__progress-bar-divider-improving"
                                    :class="{
                                        'readiness-modal__progress-bar-divider-improving--filled':
                                            scoreTheme !== 'foundations'
                                    }"
                                />
                                <div
                                    class="readiness-modal__progress-bar-divider-ready"
                                    :class="{
                                        'readiness-modal__progress-bar-divider-improving--filled':
                                            scoreTheme === 'ready'
                                    }"
                                />
                            </div>
                            <div class="readiness-modal__progress-numbered-labels-container">
                                <div class="readiness-modal__progress-numbered-label-foundation" v-dark>0</div>
                                <div class="readiness-modal__progress-numbered-label-improving" v-dark>25</div>
                                <div class="readiness-modal__progress-numbered-label-ready" v-dark>40</div>
                                <div class="readiness-modal__progress-numbered-label-max" v-dark>50</div>
                            </div>
                        </div>
                        <div class="readiness-modal__score-explanation" v-dark>
                            {{ summary }}
                        </div>
                        <div class="readiness-modal__suggestion" v-dark>
                            {{ suggestion }}
                        </div>
                        <div class=readiness-modal__nav-btns-container>
                            <Button
                                v-if="isUpdatingExamDate"
                                type="secondary"
                                :is-dark-mode="isDarkMode"
                                @click="emit('previous')"
                            >
                                Previous
                            </Button>
                            <Button
                                v-if="isEligibleForSurvey"
                                :is-dark-mode="isDarkMode"
                                @click="emit('next')"
                            >
                                Next
                            </Button>
                            <Button
                                v-else
                                :is-dark-mode="isDarkMode"
                                @click="emit('close')"
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<style lang="scss" scoped>
.readiness-modal {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px 40px 24px;
    width: 492px;
    margin: 0 auto;

    @include breakpoint(black-bear) {
            top: 132px;
            max-width: 340px;
        }

    &__modal {
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        margin: 0 0 16px 0;
        outline: none;
        letter-spacing: 0.3px;
    }

    &__score {
        font-size: 26px;
        line-height: 36px;
        letter-spacing: 0.3px;
        font-weight: 600;
        margin-bottom: 40px;
    }

    &__progress-container {
        width: 100%;
    }

    &__progress-labels-container {
        display: flex;
        margin-bottom: 6px;
    }

    &__progress-label-foundations {
        width: 50%;
    }

    &__progress-label-improving {
        width: 30%;
    }

    &__progress-label-ready {
        width: 20%;
    }

    &__progress-label-foundations,
    &__progress-label-improving,
    &__progress-label-ready {
        text-align: center;
        font-size: 11px;
        line-height: 16px;
        color: $slate;

        &--dark {
            color: $cloudy;
        }

        &--active {
            color: $brand-black;
            font-weight: 600;

            &--dark {
                color: $white;
            }
        }
    }

    &__progress-bar {
        width: 100%;
        height: 14px;
        background-color: $pearl;
        border: 1px solid rgba($pewter, 0.85);
        border-radius: 2px;
        position: relative;

        &--dark {
            background-color: $jet;
            border-color: $slate;
        }
    }

    &__progress-bar-filled {
        height: 100%;
        background-color: $spectral-green;
        border: 1px solid $slate;
        border-radius: 2px;
        position: relative;
        top: -1px;
        left: -1px;

        &--dark {
            background-color: $jungle-green;
            border-color: $jungle-green;
        }

        &--foundations {
            background-color: $steel;

            &--dark {
                background-color: $steel;
                border-color: rgba($pewter, 0.85);
            }
        }

        &--improving {
            background-color: rgba($field, 0.4);
            border-color: rgba($spectral-green, 0.85);

            &--dark {
                background-color: rgba($jungle-green, 0.7);
                border-color: rgba($brand-green, 0.85);
            }
        }

        &--ready {
            background-color: $field;
            border-color: $spectral-green;

            &--dark {
                background-color: $jungle-green;
                border-color: $jungle-green;
            }
        }
    }

    &__progress-bar-divider-improving,
    &__progress-bar-divider-ready {
        width: 1px;
        height: 16.5px;
        position: absolute;
        top: -0.5px;
        background-color: rgba($pewter, 0.85);
        border-radius: 0.5px;
        transform: translate(-50%);
        box-sizing: content-box;

        &--filled {
            background-color: $fog;
        }
    }

    &__progress-bar-divider-improving {
        left: 50%;
    }

    &__progress-bar-divider-ready {
        left: 80%;
    }

    &__progress-numbered-labels-container {
        width: calc(100% + 2px);
        position: relative;
        margin-bottom: 62px;
    }

    &__progress-numbered-label-foundation,
    &__progress-numbered-label-improving,
    &__progress-numbered-label-ready,
    &__progress-numbered-label-max {
        font-size: 11px;
        line-height: 16px;
        font-weight: 600;
        color: $ash;
        position: absolute;
        top: 6px;

        &--dark {
            color: $fog;
        }
    }

    &__progress-numbered-label-foundation {
        left: 0;
    }

    &__progress-numbered-label-improving {
        left: 50%;
        transform: translate(-50%);
    }

    &__progress-numbered-label-ready {
        left: 80%;
        transform: translate(-50%);
    }

    &__progress-numbered-label-max {
        left: 100%;
        transform: translate(-100%);
    }

    &__score-explanation {
        padding: 0 16px;
        color: $ash;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 12px;

        &--dark {
            color: $fog;
        }

        @include breakpoint(black-bear) {
            padding: 0;
        }
    }

    &__suggestion {
        padding: 0 16px;
        color: $ash;
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 65px;
        align-self: flex-start;

        &--dark {
            color: $fog;
        }

        @include breakpoint(black-bear) {
            padding: 0;
            margin-bottom: 32px;
        }
    }

    &__nav-btns-container {
        display: flex;
        gap: 8px;
    }
}
</style>
