<template>
    <div v-dark class="banner__container">
        <ExamReadinessSurvey
            v-if="showExamReadinessSurvey"
            :examMetadata="examMetadata"
            :stats="{
                answeredCount: answers.length,
                unansweredCount,
                studyScore,
                totalQuizSeconds,
                daysUntilExam,
            }"
            @close="showExamReadinessSurvey = false"
        />
        <ExamDateModal v-if="showExamDateModal" @close="showExamDateModal = false" />
        <LeftBlob class="banner__blob-left" />
        <RightBlob class="banner__blob-right" />
        <div class="banner">
            <Stat
                class="banner__correct"
                label="Correct of Answered"
                theme="dark"
            >
                <template #value>
                    {{ correctAnswers.length }}
                    <span>/</span>
                    {{ answers.length }}
                </template>
            </Stat>
            <Stat
                class="banner__unanswered"
                :value="unansweredCount"
                label="Unanswered"
                theme="dark"
            />
            <Gauge
                :key="`${studyScore}-${communityScore}-${gaugeSize}`"
                class="banner__score"
                :score="studyScore"
                :community-score="communityScore"
                :size="gaugeSize"
                theme="dark"
            />
            <Stat
                class="banner__time"
                :value="timeSpentStudying"
                label="Time Spent Studying"
                theme="dark"
            />
            <Stat
                class="banner__days"
                label="Days Until Your Exam"
                theme="dark"
            >
                <template #value>
                    <div
                        class="banner__days-edit"
                        tabindex="0"
                        @focus="hoverDaysEdit = true"
                        @blur="hoverDaysEdit = false"
                        @mouseover="hoverDaysEdit = true"
                        @mouseleave="hoverDaysEdit = false"
                        @keydown.enter="showExamDateModal = true"
                        @click="showExamDateModal = true"
                        @mousedown.prevent
                    >
                        <Tooltip v-if="hoverDaysEdit">
                            {{ daysUntilExam !== undefined ? 'Edit' : 'Add' }} Exam Date
                        </Tooltip>
                        <template v-if="daysUntilExam !== undefined">
                            {{ daysUntilExam > 0 ? daysUntilExam : 0 }}
                        </template>
                        <template v-else>
                            <Icon
                                type="pencil"
                                class="banner__days-pencil"
                                title="Edit exam date."
                            />
                        </template>
                    </div>
                </template>
            </Stat>
            <div class="banner__answer-stats">
                <div class="banner__answer-stat">
                    <div class="banner__answer-stat-number">
                        {{ correctAnswers.length }} / {{ answers.length }}
                    </div>
                    <div class="banner__answer-stat-label">
                        Answered Correctly
                    </div>
                </div>
                <div class="banner__community-stat">
                    <div class="banner__community-stat-number">
                        {{ communityScore || 0 }}%
                    </div>
                    <div class="banner__community-stat-label">
                        <span class="banner__community-score-legend" />
                        Community
                    </div>
                </div>
            </div>
            <div class="banner__mobile-bottom">
                <div class="banner__mobile-bottom-stat">
                    <div class="banner__mobile-bottom-stat-number">
                        {{ timeSpentStudying }}
                    </div>
                    <div class="banner__mobile-bottom-stat-label">
                        Time Spent Studying
                    </div>
                </div>
                <div class="banner__mobile-bottom-stat">
                    <div
                        class="banner__mobile-bottom-stat-number"
                        tabindex="0"
                        @keydown.enter="showExamDateModal = true"
                        @click="showExamDateModal = true"
                    >
                        <template v-if="daysUntilExam !== undefined">
                            {{ daysUntilExam > 0 ? daysUntilExam : 0 }}
                        </template>
                        <template v-else>
                            <Icon type="pencil" class="banner__days-pencil" />
                        </template>
                    </div>
                    <div class="banner__mobile-bottom-stat-label">
                        Days Until Your Exam
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'
import LeftBlob from '@/assets/stats/LeftBlob.vue'
import RightBlob from '@/assets/stats/RightBlob.vue'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import UIKit from '@pocketprep/ui-kit'
import { examMetadataModule } from '@/store/examMetadata/module'
import { quizModule } from '@/store/quiz/module'
import { subscriptionModule } from '@/store/subscription/module'
import ExamDateModal from '@/components/Stats/ExamDateModal.vue'
import ExamReadinessSurvey from '@/components/ExamReadiness/ExamReadinessSurvey.vue'
import Gauge from '@/components/Gauge.vue'
import { userModule } from '@/store/user/module'
import { questionModule } from '@/store/question/module'
import { globalQuestionMetricModule } from '@/store/globalQuestionMetric/module'
import { screenModule } from '@/store/screen/module'
import Stat from '@/components/Stat.vue'
import { getHoursMinutesSeconds } from '@/utils'

@Component({
    components: {
        LeftBlob,
        RightBlob,
        Icon: UIKit.Icon,
        ExamDateModal,
        Tooltip: UIKit.Tooltip,
        Gauge,
        Stat,
        ExamReadinessSurvey,
    },
})
export default class StatsBanner extends Vue {
    showExamReadinessSurvey = false
    showExamDateModal = false
    hoverDaysEdit = false

    get gaugeSize () {
        const breakpoint = screenModule.getters.getBreakpoint()
        return breakpoint === 'black-bear' ? 'large' : 'medium'
    }

    get userExamMetadata () {
        return userExamMetadataModule.getters.getCurrentUserExamMetadata()
    }

    get daysUntilExam () {
        if (!this.userExamMetadata?.scheduledExamDate) {
            return undefined
        }

        const scheduledTime = new Date(this.userExamMetadata.scheduledExamDate.iso).getTime()
        const daysUntil = Math.ceil((scheduledTime - new Date().getTime()) / 86400000)

        return daysUntil
    }

    get examMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get answers () {
        return Object.values(
            quizModule.getters.getLatestAnswers({ questionFilter: 'all' })
        )
    }

    get correctAnswers () {
        return this.answers.filter(a => a.isCorrect)
    }

    get hasSubscription () {
        return subscriptionModule.getters.getSubscriptionForExamId()
    }

    get unansweredCount () {
        if (this.hasSubscription) {
            return ((this.examMetadata?.itemCount || 0) - (this.examMetadata?.archivedCount || 0)) - this.answers.length
        }

        const availableAnswers = Object.values(
            quizModule.getters.getLatestAnswers({ questionFilter: this.hasSubscription ? 'all' : 'free' })
        )
        return (this.examMetadata?.specialQuestions || 0) - availableAnswers.length
    }

    get studyScore () {
        if (!this.answers.length) {
            return null
        }
        return Math.round((this.correctAnswers.length / this.answers.length) * 100)
    }

    get communityScore () {
        return globalQuestionMetricModule.getters.getExamCommunityAverage()
    }

    get totalQuizSeconds () {
        const durationTime = quizModule.state.quizzes.reduce((acc, q) => {
            return q.mode === 5
                ? acc
                : acc + q.durationSeconds
        }, 0)
        return durationTime
    }

    get timeSpentStudying () {
        const { hours, minutes, seconds } = getHoursMinutesSeconds(this.totalQuizSeconds)

        return hours ?
            `${hours}h ${minutes}m`
            : minutes
                ? `${minutes}m ${seconds}s`
                : `${seconds}s`
    }

    async mounted () {
        await Promise.all([
            userModule.actions.fetchUserData(),
            questionModule.actions.fetchSerialQuestionInfoLib(),
            globalQuestionMetricModule.actions.fetchGlobalQuestionMetrics(),
        ])

        if (
            !userModule.state.user?.webConfig?.hasSeenExamReadinessSurveyModal
            && this.hasSubscription
            && this.answers.length >= 50
        ) {
            this.showExamReadinessSurvey = true
        } 
    }
}
</script>

<style lang="scss" scoped>
.banner {
    width: 943px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;

    @include breakpoint(brown-bear) {
        width: 580px;
        margin-bottom: 35px;
        padding-top: 0;
    }

    @include breakpoint(black-bear) {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        justify-content: flex-start;
        flex-direction: column;
    }

    &__blob-left {
        position: absolute;
        left: 0;
        bottom: 0;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__blob-right {
        position: absolute;
        right: 0;
        top: 0;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__container {
        background: $moonlit-ocean;
        height: 298px;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 0 0 6px 6px;
        overflow: hidden;

        @include breakpoint(brown-bear) {
            height: 343px;
            align-items: flex-end;
        }

        @include breakpoint(black-bear) {
            height: 420px;
            display: block;
        }

        &--dark {
            background: $brand-black;
        }
    }

    &__correct {
        width: 130px;

        span {
            color: $pewter;
            margin: 0 2px;
        }

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__unanswered {
        width: 106px;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__community-score-legend {
        width: 7px;
        height: 7px;
        background: $fog;
        border-radius: 8px;
        display: inline-block;
        border: 1px solid $jet;
        box-sizing: border-box;
    }

    &__score {
        width: 226px;
        position: relative;

        @include breakpoint(brown-bear) {
            position: absolute;
            left: 50%;
            margin-left: -114px;
            top: 135px;
        }

        @include breakpoint(black-bear) {
            display: block;
            position: relative;
            left: auto;
            top: auto;
            margin: 110px auto 56px;
            width: 328px;
        }
    }

    &__time {
        width: 140px;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__days {
        width: 140px;

        .uikit-tooltip {
            position: absolute;
            left: 50%;
            top: calc(-100% - 14px);
        }

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__days-edit {
        height: 27px;
        border-radius: 6px;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0 3px;
        box-sizing: border-box;
        display: inline-block;
        position: relative;
        min-width: 29px;

        &:hover,
        &:focus {
            outline: none;
            background: rgba($white, 0.15);
            color: $banana-bread;
        }

        &:focus {
            &::before {
                content: '';
                position: absolute;
                left: -3px;
                top: -3px;
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                border: 1px solid $butterscotch;
                border-radius: 9px;
            }
        }
    }

    &__days-pencil {
        color: $banana-bread;
        width: 20px;
        height: 20px;
        margin-top: 2px;
        top: 2px;
        position: relative;
    }

    &__answer-stats {
        display: none;
        justify-content: space-between;
        width: 333px;
        color: $white;
        margin-bottom: 26px;

        @include breakpoint(black-bear) {
            display: flex;
            margin-top: 8px;
        }
    }

    &__answer-stat {
        text-align: center;
        width: 203px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $black-feather;
        border-radius: 6px;
        flex-direction: column;
    }

    &__answer-stat-number {
        font-size: 26px;
        letter-spacing: 0.26px;
        margin-top: 3px;
        font-weight: 600;
        line-height: 30px;
    }

    &__answer-stat-label {
        font-size: 13px;
        font-weight: 500;
        line-height: 19px;
        color: $fog;
    }

    &__community-stat {
        text-align: center;
        width: 120px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $black-feather;
        border-radius: 6px;
        flex-direction: column;
    }

    &__community-stat-number {
        font-size: 26px;
        letter-spacing: 0.26px;
        margin-top: 3px;
        font-weight: 600;
        line-height: 30px;
    }

    &__community-stat-label {
        font-size: 13px;
        font-weight: 500;
        line-height: 19px;
        color: $fog;
    }

    &__mobile-bottom {
        display: none;
        justify-content: space-around;
        width: 336px;
        color: $white;

        @include breakpoint(black-bear) {
            display: flex;
        }
    }

    &__mobile-bottom-stat {
        text-align: center;
    }

    &__mobile-bottom-stat-number {
        font-size: 24px;
        font-weight: 600;
        line-height: 33px;
        letter-spacing: 0.24px;
        margin-bottom: 2px;
    }

    &__mobile-bottom-stat-label {
        font-size: 12px;
        line-height: 16px;
        color: rgba($white, 0.8);
    }
}
</style>