<template>
    <div v-dark="isDarkMode" class="calendar-container">
        <div v-dark="isDarkMode" class="calendar-container__top">
            <div class="calendar-container__tabs" role="tablist">
                <Tab 
                    :is-dark-mode="isDarkMode"
                    class="calendar-container__tab"
                    :active="activeTab === 'all'"
                    :aria-selected="activeTab === 'all' ? 'true' : 'false'"
                    role="tab"
                    @click="clickTab('all')"
                >
                    All Studying
                </Tab>
                <Tab 
                    :is-dark-mode="isDarkMode"
                    class="calendar-container__tab"
                    role="tab"
                    :aria-selected="activeTab === 'qotd' ? 'true' : 'false'"
                    :active="activeTab === 'qotd'"
                    @click="clickTab('qotd')"
                >
                    <span class="calendar-container__tab-mobile">QotD</span>
                    <span class="calendar-container__tab-desktop">Question of the Day</span>
                </Tab>
            </div>
            <div v-dark="isDarkMode" class="calendar-container__streak">
                <Icon
                    v-dark="isDarkMode"
                    class="calendar-container__streak-icon"
                    type="lightning"
                />
                <span>{{ streakCount }} Day Streak</span>
            </div>
        </div>
        <template v-if="!isShowingTextDescription">
            <div
                tabindex="-1"
                role="region"
                aria-label="Calendar summary text description below"
                class="calendar-container__calendar-region"
            >
                <Calendar
                    :dates="dates"
                    :quiz-date-lib="quizDateLib"
                    :calendar-view="activeTab"
                    :is-dark-mode="isDarkMode"
                />
            </div>
        </template>
        <template v-else-if="isShowingTextDescription && activeTab === 'all'">
            <div
                ref="calendar-container__text-description"
                class="calendar-container__text-description"
                tabindex="-1"
            >
                {{ calendarTextDescription }}
            </div>
        </template>
        <template v-else-if="isShowingTextDescription && activeTab === 'qotd'">
            <div
                ref="calendar-container__text-description"
                class="calendar-container__text-description"
                tabindex="-1"
            >
                Today is {{ currentMonthAndDay }}.
                You have taken {{ numQOTDs }} {{
                    numQOTDs === 1
                        ? numCorrectQOTDs === 1
                            ? 'correct '
                            : 'incorrect '
                        : ''
                }}Question of the Day quiz{{ numQOTDs !== 1 ? 'zes' : '' }}
                since {{ startMonthAndDay }}{{
                    numQOTDs > 1
                        ? `: ${numCorrectQOTDs} correct and ${numIncorrectQOTDs} incorrect.`
                        : '.'
                }} You have a {{ streakCount }} day Question of the Day streak.
            </div>
        </template>
        <div
            v-dark="isDarkMode"
            class="calendar-container__text-toggle-container"
        >
            <div
                v-dark="isDarkMode"
                tabindex="0"
                class="calendar-container__text-toggle"
                :class="{
                    'calendar-container__text-toggle--active': isShowingTextDescription,
                }"
                role="switch"
                aria-label="Text Description"
                :aria-checked="isShowingTextDescription ? 'true' : 'false'"
                @focus="isShowingTextDescriptionTooltip = true"
                @blur="isShowingTextDescriptionTooltip = false"
                @mouseenter="isShowingTextDescriptionTooltip = true"
                @mouseleave="isShowingTextDescriptionTooltip = false"
                @click="toggleTextDescription"
                @keydown.enter="toggleTextDescription"
                @mousedown.prevent
            >
                <Tooltip
                    v-if="isShowingTextDescriptionTooltip"
                    :theme="breakpoint === 'black-bear' ? 'rightalign' : false"
                    :is-dark-mode="isDarkMode"
                >
                    Text Description
                </Tooltip>
                <Icon class="calendar-container__text-toggle-icon" type="text" />
            </div>
            <template v-if="!isShowingTextDescription">
                <div
                    v-dark="isDarkMode"
                    class="calendar-container__text-toggle-key-label"
                >
                    {{ breakpoint === 'brown-bear' ? 'Key:' : 'Calendar Key:' }}
                </div>
                <template v-if="activeTab === 'all'">
                    <div
                        v-dark="isDarkMode"
                        class="calendar-container__text-toggle-key-studied"
                    >
                        Studied
                    </div>
                    <div
                        v-if="showExamDate"
                        v-dark="isDarkMode"
                        class="calendar-container__text-toggle-key-exam-date"
                    >
                        Exam Date
                    </div>
                </template>
                <template v-else-if="activeTab === 'qotd'">
                    <div
                        v-dark="isDarkMode"
                        class="calendar-container__text-toggle-key-correct"
                    >
                        Correct
                    </div>
                    <div
                        v-dark="isDarkMode"
                        class="calendar-container__text-toggle-key-incorrect"
                    >
                        Incorrect
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import Calendar from '@/components/Study/Calendar.vue'
import { quizModule } from '@/store/quiz/module'
import type { Study } from '@pocketprep/types'
import { months, timeOfDay } from '@/utils'
import { userModule } from '@/store/user/module'
import { screenModule } from '@/store/screen/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'

@Component({
    components: {
        Icon: UIKit.Icon,
        Calendar,
        Tab: UIKit.Tab,
        Tooltip: UIKit.Tooltip,
    },
})
export default class CalendarContainer extends Vue {
    activeTab: 'all' | 'qotd' = 'all'
    isShowingTextDescription = false
    isShowingTextDescriptionTooltip = false

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get calendarStart () {
        const date = new Date()
        const prevSunday = new Date()
        prevSunday.setHours(0, 0, 0, 0)
        if (date.getDay() === 0){
            prevSunday.setDate(date.getDate() - 7)
        } else {
            prevSunday.setDate(date.getDate() - date.getDay() - 7)
        }
        return prevSunday
    }
    get userExamMetadata () {
        return userExamMetadataModule.getters.getCurrentUserExamMetadata()
    }

    get examDateMonthDay () {
        if (!this.userExamMetadata?.scheduledExamDate) {
            return undefined
        }
        const date = new Date(this.userExamMetadata?.scheduledExamDate.iso)
        const month = months[date.getMonth()]
        const day = this.formatOrdinalDate(date.getDate())
        return `${month} ${day}`
    }

    get presentOrFutureExamDate () {
        if (!this.userExamMetadata?.scheduledExamDate) {
            return undefined
        }

        const examDay = new Date(this.userExamMetadata?.scheduledExamDate.iso)
        // check to see if the exam day was in the past
        if (examDay.getTime() < new Date().setHours(0,0,0,0)) {
            return false
        }

        const datesOnCalendar = this.dates.slice(0, 14)
        const futureExamDate = datesOnCalendar.find(date => examDay.getTime() <= date)
        // check to see if the exam date is today or in the future on the two week calendar
        if (futureExamDate) {
            return true
        }
        return false
    }

    get isDarkMode () {
        return timeOfDay() === 'midnight' || userModule.state.settings.isDarkMode
    }

    get calendarTextDescription () {
        return `Today is ${this.currentMonthAndDay}.
                You have studied ${this.numActiveDays} day${this.numActiveDays !== 1 ? 's' : ''}
                since ${this.startMonthAndDay}.
                You have a ${this.streakCount} day study streak.
                ${this.presentOrFutureExamDate
        ? `Your exam is on ${this.examDateMonthDay}.` : '' }`
    }

    get dates () {
        const dates: number[] = new Array(14)
        const calendarStart = new Date(this.calendarStart)

        for (let i = 0; i < 15; i++) {
            dates[i] = calendarStart.getTime()
            calendarStart.setDate(calendarStart.getDate() + 1)
        }

        return dates
    }

    get showExamDate () {
        if (!this.userExamMetadata?.scheduledExamDate) {
            return undefined
        }
        const scheduledTime = new Date(this.userExamMetadata.scheduledExamDate.iso)
        if (this.dates.slice(0, 14)?.includes(scheduledTime.getTime())) {
            return true
        }
        return false
    }

    get lastTwoWeekQuizzes () {
        return this.quizzes.filter(q => {
            return new Date(q.startedAt?.iso).getTime() > this.calendarStart.getTime()
        })
    }

    get lastTwoWeekQOTD () {
        return this.lastTwoWeekQuizzes.filter(q => q.mode === -1)
    }

    get streakCount () {
        const filterQuizzes = this.activeTab !== 'all' ? true : false
        const quizModeFilter = this.activeTab !== 'all' ? -1 : undefined

        return quizModule.getters.getStreakCount({ filterQuizMode: filterQuizzes, quizModeNum: quizModeFilter })
    }

    get quizDateLib () {
        return this.lastTwoWeekQuizzes.reduce((acc, q) => {
            const date = new Date(q.startedAt.iso)
            date.setHours(0, 0, 0, 0)

            if (!(date.getTime() in acc)) {
                acc[date.getTime()] = { qotd: undefined, quizzes: [] }
            }

            if (q.mode === -1) {
                const dateObject = acc[date.getTime()]
                if (dateObject) {
                    dateObject.qotd = q
                }
            }

            acc[date.getTime()]?.quizzes.push(q)

            return acc
        }, {} as { [date: number]: { qotd?: Study.Class.QuizJSON; quizzes: Study.Class.QuizJSON[] } })
    }

    get quizzes () {
        return [ ...quizModule.state.quizzes ]
    }

    get numActiveDays () {
        return Object.keys(this.quizDateLib).length
    }

    get numQOTDs () {
        return this.lastTwoWeekQOTD.length
    }

    get numCorrectQOTDs () {
        return this.lastTwoWeekQOTD.filter(q => q.correctCount).length
    }

    get numIncorrectQOTDs () {
        return this.lastTwoWeekQOTD.filter(q => !q.correctCount).length
    }

    get startMonthAndDay () {
        const month = months[this.calendarStart.getMonth()]
        const day = this.formatOrdinalDate(this.calendarStart.getDate())
        const year = this.calendarStart.getFullYear()
        return `${month} ${day}${ year !== new Date().getFullYear() ? `, ${year}` : '' }`
    }

    get currentMonthAndDay () {
        const date = new Date()
        const month = months[date.getMonth()]
        const day = this.formatOrdinalDate(date.getDate())
        return `${month} ${day}`
    }

    mounted () {
        userModule.actions.fetchUserData()
    }

    clickTab (tab: 'all' | 'qotd') {
        this.activeTab = tab

        if (this.isShowingTextDescription) {
            setTimeout(() => {
                const textDescriptionEl = this.$refs['calendar-container__text-description'] as HTMLElement | undefined
                textDescriptionEl?.focus()
            }, 0)
        }
    }

    /*
     * For numbers 1-31, returns '1st', '2nd', '23rd', etc
     */
    formatOrdinalDate (day: number) {
        if (day > 3 && day < 21) {
            return `${day}th`
        }
        return day % 10 === 1 ? `${day}st` : day % 10 === 2 ? `${day}nd` : day % 10 === 3 ? `${day}rd` : `${day}th`
    }

    toggleTextDescription () {
        this.isShowingTextDescription = !this.isShowingTextDescription

        if (this.isShowingTextDescription) {
            setTimeout(() => {
                const textDescriptionEl = this.$refs['calendar-container__text-description'] as HTMLElement | undefined
                if (textDescriptionEl) {
                    textDescriptionEl.focus()
                }
            }, 0)
        }
    }
}
</script>
<style lang="scss" scoped>
.calendar-container {
    &--dark {
        color: $white;
    }

    @include breakpoint(brown-bear) {
        padding-top: 79px;
    }

    @include breakpoint(black-bear) {
        padding-top: 0;
    }

    &__tab-mobile {
        display: none;

        @include breakpoint(black-bear) {
            display: block;
        }
    }

    &__tab-desktop {
        display: block;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__top {
        width: 500px;
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: center;

        @include breakpoint(brown-bear) {
            width: 430px;
        }

        @include breakpoint(black-bear) {
            width: 100%;
            padding: 0 30px;
            box-sizing: border-box;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -3px;
            width: 100%;
            height: 1px;
            background: rgba($brand-black, 0.16);

            @include breakpoint(black-bear) {
                bottom: -2px;
            }
        }

        &--dark::before {
            background: $white;
            opacity: 0.41;
        }
    }

    &__tabs {
        display: flex;

        @include breakpoint(black-bear) {
            margin-left: -3px;
        }
    }

    &__tab {
        margin: 0 4.5px;
        position: relative;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__streak {
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.2px;
        display: flex;
        align-items: center;

        &--dark {
            color: $white;
        }
    }

    &__streak-icon {
        width: 12px;
        height: 15px;
        color: $slate-03;
        margin-right: 4px;

        &--dark {
            color: $banana-bread;
        }
    }

    &__calendar-region {
        outline: none;
    }

    &__text-description {
        box-sizing: border-box;
        padding: 24px 0;
        max-width: 500px;
        height: 193px;
        line-height: 26px;
        outline: none;
        font-size: 16px;
        font-weight: 400;

        @include breakpoint(brown-bear) {
            max-width: 430px;
            height: 177px;
        }

        @include breakpoint(black-bear) {
            max-width: none;
            padding: 21px 30px;
            min-height: 149px;
            height: auto;
        }
    }

    &__text-toggle-container {
        display: flex;
        align-items: center;
        color: $ash;
        font-size: 13px;
        line-height: 14px;

        &--dark {
            color: $fog;
        }

        @include breakpoint(brown-bear) {
            font-size: 14px;
        }

        @include breakpoint(black-bear) {
            padding: 0 22px;
        }
    }

    &__text-toggle {
        display: flex;
        position: relative;
        cursor: pointer;
        color: $brand-blue;
        border-radius: 6px;
        outline: none;

        &:hover {
            color: $brand-black;
        }

        &:focus {
            &::after {
                content: '';
                position: absolute;
                left: -2px;
                top: -2px;
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                box-sizing: border-box;
                border: 1px solid $brand-blue;
                border-radius: 8px;
            }
        }

        &--dark {
            color: $banana-bread;

            &:hover {
                color: $butterscotch;
            }

            &:focus {
                &::after {
                    border-color: $butterscotch;
                }
            }
        }

        &--active {
            background-color: $brand-blue;
            color: $white;

            &:hover {
                background-color: $brand-black;
                color: $white;
            }

            &:focus {
                &::after {
                    left: -3px;
                    top: -3px;
                    width: calc(100% + 6px);
                    height: calc(100% + 6px);
                    border-radius: 9px;
                }
            }

            &--dark {
                background-color: $banana-bread;
                color: $brand-black;

                &:hover {
                    background-color: $butterscotch;
                    color: $brand-black;
                }
            }
        }

        .uikit-tooltip {
            position: absolute;
            left: 50%;
            top: calc(-100% - 4px);

            @include breakpoint(brown-bear) {
                top: calc(-100% + 4px);
            }

            @include breakpoint(black-bear) {
                top: calc(-100% + 8px);
            }
        }
    }

    &__text-toggle-icon {
        @include breakpoint(brown-bear) {
            width: 36px;
            height: 36px;
        }

        @include breakpoint(black-bear) {
            width: 40px;
            height: 40px;
        }
    }

    &__text-toggle-key-label {
        margin-left: 14px;
        padding-left: 12px;
        border-left: 1px solid rgba($pewter, 0.5);

        &--dark {
            border-left: 1px solid rgba($white, 0.5);
        }

        @include breakpoint(brown-bear) {
            margin-left: 8px;
        }
    }

    &__text-toggle-key-studied,
    &__text-toggle-key-correct,
    &__text-toggle-key-incorrect,
    &__text-toggle-key-exam-date {
        position: relative;
        padding-left: 24px;

        &::before {
            content: '';
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 100%;
            width: 10px;
            height: 10px;
            box-sizing: border-box;
        }
    }

    &__text-toggle-key-studied {
        &::before {
            background-color: $brand-black;
        }

        &--dark {
            &::before {
                background-color: $white;
            }
        }
    }

    &__text-toggle-key-exam-date {
        &::before {
            border: 2px solid grey;
        }

        &--dark {
            &::before {
                border: 2px solid grey;
            }
        }
    }

    &__text-toggle-key-correct {
        &::before {
            background-color: $spectral-green;
        }

        &--dark {
            &::before {
                background-color: $jungle-green;
            }
        }
    }

    &__text-toggle-key-incorrect {
        margin-left: 4px;

        &::before {
            border: 1.5px solid $pepper;
        }

        &--dark {
            &::before {
                border-color: $rosa;
            }
        }
    }
}
</style>