<!-- eslint-disable -->
<template>
    <svg width="146px" height="194px" viewBox="0 0 146 194" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-0.000000, -0.000000)">
                <path d="M86.2164378,77.9562509 C49.757046,104.43991 26.1382615,131.502795 0.00317319866,146.019802 L0.0023155527,0.00890425605 L145.234551,0.00890272619 C136.616332,27.558366 117.963927,54.8952607 86.2164378,77.9562509 Z" :fill="fillColor" opacity="0.100372314"></path>
                <path d="M103.040017,-1.66977543e-13 C103.769052,2.89626665 104.390767,5.96299853 104.899376,9.20625178 C122.722338,122.858087 136.385481,138.49378 56.8196852,175.877505 C35.0929307,186.085749 16.1963225,191.995022 -2.06057393e-13,194.409897 L0.0023155527,0.00890425605 Z" fill="url(#halftone-purple)"></path>
            </g>
        </g>
    </svg>
</template>
<!-- eslint-enable -->

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'

@Component
export default class TopLeftBlob extends Vue {
    get fillColor () {
        return BrandColors.brandPurple
    }
}
</script>
