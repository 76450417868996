import { reactive } from 'vue'
import actions from '@/store/readiness/actions'
import state from '@/store/readiness/state'
import getters from '@/store/readiness/getters'

export const readinessModule = {
    state: reactive(state),
    actions,
    getters,
}
