import { subscriptionModule } from '@/store/subscription/module'
import { questionModule } from '@/store/question/module'
import { qotdModule } from '@/store/qotd/module'
import { isPromise } from '@/store/utils'
import type { Study } from '@pocketprep/types'

const getSerialQuestionInfoLib = ({ questionFilter }: { questionFilter: 'all' | 'accessible' | 'free' | 'mock' }) => {
    const todaysQotD = qotdModule.getters.getQotDQuestion()
    const serialQuestionInfoLib = questionModule.state.serialQuestionInfoLib.value
    const isPremium = subscriptionModule.getters.getSubscriptionForExamId()

    if (isPromise(serialQuestionInfoLib)) {
        return {}
    }

    return Object.entries(serialQuestionInfoLib).reduce((acc, [ serial, questionInfo ]) => {
        const { isFree, isMockQuestion } = questionInfo
        const isAccessibleQuestion = isFree || todaysQotD?.serial === serial

        if (
            (questionFilter === 'mock' && isMockQuestion) || (!isMockQuestion && (
                isPremium || questionFilter === 'all'
                || (questionFilter === 'free' && isFree)
                || (questionFilter === 'accessible' && isAccessibleQuestion)
            ))
        ) {
            acc[serial] = questionInfo
        }

        return acc
    }, {} as { [serial: string]: { subject: string; isFree?: boolean; isMockQuestion?: boolean } })
}

const getQuestionScenarioPartLabel = (question: Study.Class.QuestionJSON) => {
    if (question.questionScenario) {
        const questionScenario = question.questionScenario as Study.Class.QuestionScenarioJSON
        const numOfScenarioQuestions = questionScenario.questions.length
        const scenarioQuestionIndex =
            questionScenario.questions.findIndex(q => q.serial === question.serial)
        const scenarioPart = scenarioQuestionIndex + 1
        return `Part ${scenarioPart} of ${numOfScenarioQuestions}`
    }

    return ''
}

export default {
    getSerialQuestionInfoLib,
    getQuestionScenarioPartLabel,
}
