<template>
    <div class="payment-fields">
        <div
            class="payment-fields__field"
            :class="{ 
                'payment-fields__field--error': errorFields.includes('card') || stripeValidationError,
                'payment-fields__field--focus': stripeFieldFocused
            }"
            @mouseover="stripeFieldHover = true"
            @mouseout="stripeFieldHover = false"
        >
            <div
                :id="`card-element_${cardFieldId}`"
                v-dark
                class="payment-fields__card"
            />
            <div
                :id="'card-element_address'"
                v-dark
                class="payment-fields__address"
            />
        </div>
        <Errors
            v-if="errors.length"
            :is-dark-mode="isDarkMode"
            :errors="errors"
        />
    </div>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop, Watch, Emit } from 'vue-facing-decorator'
import { type StripeElement } from '@stripe/stripe-js'
import { stripeModule } from '@/store/stripe/module'

@Component({
    components: {
        PocketInput: UIKit.Input,
        PocketSelect: UIKit.Select,
        Errors: UIKit.Errors,
    },
})
export default class PaymentFields extends Vue {
    @Prop({ default: false }) oneTimePurchase!: boolean
    @Prop() errors!: string[]
    @Prop() errorFields!: string[]
    @Prop() planAmount!: number

    cardFieldId = Math.floor(Math.random() * 1e7)
    name: null | string = null
    cardEl: StripeElement | null | void = null
    addressEl: StripeElement | null | void = null
    cardElCompleted = false
    addressElCompleted = false
    stripeFieldFocused = false
    stripeFieldHover = false

    stripeValidationError: stripe.Error | null = null

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get paymentParams () {
        return {
            cardEl: this.cardEl,
            addressEl: this.addressEl,
            cardElCompleted: this.cardElCompleted,
            addressElCompleted: this.addressElCompleted,
        }
    }

    mounted () {
        this.$nextTick(async () => {
            if (import.meta.env.VUE_APP_STRIPE_PUBLISHABLE_KEY) {
                const { elements } = await stripeModule.actions.loadStripeAndElements({
                    planAmount: this.planAmount,
                    creatingPaymentField: true,
                    oneTimePurchase: this.oneTimePurchase,
                })

                if (elements) {
                    this.cardEl = elements.create('payment', {
                        layout: {
                            type: 'accordion',
                            defaultCollapsed: false,
                            radios: false,
                            spacedAccordionItems: true,
                        },
                        wallets: {
                            applePay: 'never',
                            googlePay: 'never',
                        },
                    })

                    this.addressEl = elements.create('address', {
                        mode: 'billing',
                        autocomplete: {
                            mode: 'disabled',
                        },
                        defaultValues: {
                            address: {
                                country: 'US',
                                state: 'Select',
                            },
                        },
                    })

                    // event.complete will not be true until all fields are valid
                    // add else for when errors.length we bubble uncompleted fields back up
                    this.cardEl.on('change', (event) => {
                        if (event.complete) {
                            this.cardElCompleted = true
                        } else {
                            this.cardElCompleted = false
                        }
                    })

                    this.addressEl.on('change', (event) => {
                        if (event.complete) {
                            this.addressElCompleted = true
                        } else {
                            this.addressElCompleted = false
                        }
                    })

                    this.cardEl.mount(`#card-element_${this.cardFieldId}`)
                    this.addressEl.mount('#card-element_address')
                }
            }
        })
    }

    @Watch('paymentParams')
    paymentParamsChanged () {
        this.emitUpdate(this.paymentParams)
    }

    async submit () {
        this.emitSubmit()
    }

    @Emit('update')
    emitUpdate (paymentParams: PaymentFields['paymentParams']) {
        return paymentParams
    }

    @Emit('submit')
    emitSubmit () {
        return true
    }

}
</script>

<style lang="scss" scoped>
.payment-fields {
    &__field {
        margin-bottom: 17px;

        &--error {
            .payment-fields__field-label {
                color: $red-pegasus;
            }

            .payment-fields__card {
                border: 1px solid $red-pegasus;
            }
        }
    }

    &__address {
        margin-top: 20px;
    }

    &__field-label {
        font-size: 13px;
        line-height: 14px;
        margin: 0 0 6px 11px;
        color: $slate-01;
        display: block;

        &--hover {
            color: $slate-03;

            &--dark {
                color: $banana-bread;
            }
        }

        &--focus {
            color: $brand-blue;

            &--dark {
                color: $banana-bread;
            }
        }
    }
}
</style>