import { runCloudFunction } from '@/store/parseUtils'
import { subscriptionModule } from '@/store/subscription/module'
import type { Study } from '@pocketprep/types'
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { analyticsModule } from '@/store/analytics/module'

const validateLicense = async (licenseKey: string) => {
    return runCloudFunction<Study.Cloud.validateLicense>('validateLicense', {
        licenseKey,
    })
}

const activateLicense = async (licenseKey: string) => {
    // Note that all linkedLicenses will be activated here as well, but we will update the current exam with the
    // passed in licenseKey
    const licensesAndSubscriptions = (await runCloudFunction<Study.Cloud.activateLicenseV2>('activateLicense-v2', {
        licenseKey,
        platform: 'Web',
    }))

    let examGuid = ''

    for (const licensesAndSubscription of licensesAndSubscriptions) {
        /* Now we are getting a structure like this:
            {
                license: Study.Class.License
                subscription: Study.Class.Subscription
            }[]
        */
        const subscription = licensesAndSubscription.subscription as ReturnType<Study.Class.SubscriptionExam['toJSON']>
        subscriptionModule.state.subscriptions.push(subscription)

        // Extract the license from the receipt since we probably won't return the license in the future
        const receipt = licensesAndSubscription.subscription.receipt as Study.Class.ReceiptJSON
        const license = receipt.license as Study.Class.LicenseJSON

        if (license.licenseKey === licenseKey) {
            // We found the exam we care about...
            examGuid = subscription.examGuid
        }

        await Promise.all([
            userExamMetadataModule.actions.fetchUserExamMetadata(),
            examMetadataModule.actions.fetchExamMetadata(),
        ])
    
        // For whichever license key was passed in, we want to update the current exam to be that exam
        const uem = userExamMetadataModule.getters.getUserExamMetadataByGuid()[subscription.examGuid]
        const examMetadata = examMetadataModule.getters.getMostRecentExamForExamGuid(subscription.examGuid)
        if (!uem && examMetadata) {
            await userExamMetadataModule.actions.upsertUserExamMetadata({
                examGuid: examMetadata.examGuid,
                examVersion: examMetadata.version,
                flaggedQuestions: [],
                upgradesSeen: [],
                disabledSubjects: [],
            })
        }
    }
    
    await userModule.actions.updateCurrentExamGuid(examGuid)
    analyticsModule.actions.updateIntercom()
}

const fetchUsersLicenseOrgName = async () => {
    const user = userModule.state.user
    const hasSubscriptions = !!subscriptionModule.state.subscriptions.filter(s => 'examGuid' in s).length
    return hasSubscriptions && user && (!user.firstName || !user.lastName)
        ? runCloudFunction<Study.Cloud.fetchLicenseOrgName>('fetchLicenseOrgName')
        : undefined
}

const fetchOrgNameFromLicenseCode = async (licenseCode: string) => {
    return runCloudFunction<Study.Cloud.fetchOrgNameFromLicenseCode>('fetchOrgNameFromLicenseCode', { licenseCode })
}

export default {
    validateLicense,
    activateLicense,
    fetchUsersLicenseOrgName,
    fetchOrgNameFromLicenseCode,
}
