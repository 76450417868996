<template>
    <div>
        <SkipToContent />
        <PencilsDownModal
            v-if="isShowingPencilsDownModal"
            @submitExam="submitExam(true)"
        />
        <ExitExamModal
            v-if="showExitExamModal && currentExam"
            :num-answers="numCompleteQuestions"
            :quiz-length="quizLength"
            @close="showExitExamModal = false"
            @submit="submitExam(true)"
            @exit="exitExam"
        />
        <SubmitExamModal
            v-if="showSubmitExamModal && currentExam"
            :num-answers="numCompleteQuestions"
            :quiz-length="quizLength"
            @close="showSubmitExamModal = false"
            @continue="showSubmitExamModal = false"
            @submit="submitExam(true)"
        />
        <QuizContainer
            ref="quizContainer"
            class="mock-exam"
            :is-dark-mode="isDarkMode"
            :custom-main-styles="breakpoint === 'black-bear' && { height: 'calc(100% - 99px)' }"
        >
            <template v-if="!isLoadingScoring" #header>
                <div class="mock-exam__exam-info">
                    <ExamMenuCard
                        v-if="currentExam && currentBundle"
                        :exam="currentExam"
                        :bundle-id="currentBundle.objectId"
                        theme="silver"
                        :is-clickable="false"
                        class="mock-exam__exam-info-card"
                    />
                </div>
                <div
                    class="mock-exam__progress"
                >
                    <QuizProgress
                        :num-questions="quizLength"
                        :selected-index="questionIndex + 1"
                        :answered-index-list="answeredIndexList"
                        @barClicked="progressBarClicked"
                        @keydown.left.right.stop.prevent
                    />
                </div>
                <div
                    class="mock-exam__timer"
                    :class="{
                        'mock-exam__timer--low': isLowTime,
                        'mock-exam__timer--ten-minute-break': isTenMinuteBreak
                    }"
                >
                    <Icon
                        class="mock-exam__timer-icon"
                        :class="{
                            'mock-exam__timer-icon--low': isLowTime,
                        }"
                        :type="isLowTime ? 'warning' : 'hourglass'"
                    />
                    {{ timeDisplay }}
                </div>
                <PocketButton
                    class="mock-exam__quit-btn"
                    type="secondary-yellow-dark"
                    :is-dark-mode="isDarkMode"
                    @click="exitExamClicked"
                >
                    Exit Exam
                </PocketButton>
            </template>
            <template
                #question
            >
                <ScoringLoading v-if="isLoadingScoring && !isTenMinuteBreak" />
                <QuestionGrid
                    v-else-if="isShowingQuestionGrid && !isTenMinuteBreak"
                    :questions="activeMockExamQuizQuestions"
                    :answers="answers"
                    :flagged-questions="flaggedQuestions"
                    :is-dark-mode="isDarkMode"
                    @questionClicked="gridQuestionClicked"
                    @close="isShowingQuestionGrid = false"
                />
                <div 
                    v-else-if="isTenMinuteBreak && typeOfTimer === 'break' && halfOfExamQsAnswered"
                    class="mock-exam__break-container"
                    :class="{
                        'mock-exam__break-container--last-minute': lastMinuteOfBreak && !isDarkMode,
                        'mock-exam__break-container--dark': isDarkMode,
                    }"
                >
                    <div class="mock-exam__yoga-woman">
                        <img
                            class="mock-exam__yoga-woman-img"
                            src="@/assets/mock-exams/yoga-woman.png"
                            alt=""
                        >
                    </div>
                    <div
                        class="mock-exam__break-header"
                        :class="{
                            'mock-exam__break-header--dark': isDarkMode,
                        }"
                        tabindex="0"
                    >
                        Take a Break
                    </div>
                    <div
                        class="mock-exam__break-description"
                        :class="{
                            'mock-exam__break-description--dark': isDarkMode,
                        }"
                    >
                        The exam timer has paused. 
                        Stretch, breathe, and resume when you are ready.
                    </div>
                    <div 
                        class="mock-exam__break-timer-container"
                        :class="{
                            'mock-exam__break-timer-container--last-minute': lastMinuteOfBreak,
                            'mock-exam__break-timer-container--dark': isDarkMode,
                        }"
                    >
                        <div 
                            class="mock-exam__break-timer"
                            :class="{
                                'mock-exam__break-timer--last-minute': lastMinuteOfBreak && !isDarkMode,
                                'mock-exam__break-timer--last-minute-dark': lastMinuteOfBreak && isDarkMode,
                                'mock-exam__break-timer--dark': !lastMinuteOfBreak && isDarkMode,
                            }"
                        >
                            {{ breakTimeDisplay }}
                        </div>
                    </div>
                    <div class="mock-exam__resume-exam">
                        <PocketButton
                            class="mock-exam__resume-exam-button"
                            :is-dark-mode="isDarkMode"
                            @click="resumeExam"
                        >
                            Resume Exam
                        </PocketButton>
                    </div>
                </div>
                <template v-else-if="!isTenMinuteBreak && !isLoadingScoring && !isShowingQuestionGrid">
                    <Icon
                        v-if="isLoading || !currentExam"
                        v-dark
                        class="mock-exam__loading"
                        type="loading2"
                        :is-dark-mode="isDarkMode"
                    />
                    <Question
                        v-else-if="mode && currentQuestion && currentExam"
                        :key="`${currentQuestion.objectId}-${allowKeyboardShortcuts}-${isOutOfTime}`"
                        class="mock-exam__question"
                        :container-el="quizContainerEl"
                        :question="currentQuestion"
                        :question-number="questionIndex + 1"
                        :quiz-length="quizLength"
                        :quiz-mode="mode"
                        :image-url-prefix="`${s3Url || ''}/${currentQuestion.compositeKey.toUpperCase()}/`"
                        :hide-answer="true"
                        :show-check-answer="false"
                        :previous-choices="previousChoices"
                        :previous-matrix-choices="previousChoices"
                        :initial-show-answers="false"
                        :allow-keyboard-shortcuts="allowKeyboardShortcuts && !isOutOfTime"
                        :is-dark-mode="isDarkMode"
                        :answer-seed="currentQuestionSeed"
                        :hide-references="currentExam.hideReferences"
                        @close="exitExamClicked"
                        @previousQuestion="clickPrevious"
                        @nextQuestion="clickNext"
                        @checkAnswer="checkAnswer"
                        @selectedChoices="questionAnswered"
                        @submitQuiz="submitExam"
                        @update:showExplanation="explanationViewToggled"
                    >
                        <template
                            #action
                            v-if="(answerSerialSet.size >= questionSerialSet.size) || isNextArrowDisabled"
                        >
                            <PocketButton
                                :is-dark-mode="isDarkMode"
                                :disabled="numCompleteQuestions === 0"
                                @click="submitExam"
                            >
                                Submit Exam
                            </PocketButton>
                        </template>
                    </Question>
                </template>
            </template>
            <template v-if="!isShowingQuestionGrid && !isLoadingScoring" #footer>
                <div
                    v-if="breakpoint !== 'black-bear'"
                    class="mock-exam__grid-toggle"
                    :class="{
                        'mock-exam__grid-toggle--is-break': isTenMinuteBreak,
                    }"
                    tabindex="0"
                    aria-label="All Questions"
                    @click="toggleQuestionGrid"
                    @mousedown.prevent
                >
                    <div class="mock-exam__view-all-questions">
                        <Icon class="mock-exam__list-icon" type="list" />
                        <div class="mock-exam__view-all-questions-label">
                            View All Questions
                        </div>
                    </div>
                </div>
                <div 
                    class="mock-exam__controls"
                    :class="{
                        'mock-exam__controls--is-break': isTenMinuteBreak,
                    }"
                >
                    <Icon
                        class="mock-exam__previous-arrow"
                        :class="{
                            'mock-exam__previous-arrow--disabled': isPreviousArrowDisabled,
                        }"
                        :tabindex="isPreviousArrowDisabled ? -1 : 0"
                        type="paginationArrow"
                        role="button"
                        aria-label="Previous question"
                        :aria-disabled="isPreviousArrowDisabled"
                        @click="clickPrevious"
                        @keydown.enter.stop.prevent="clickPrevious"
                        @mousedown.prevent
                    />
                    <div
                        v-if="breakpoint === 'black-bear'"
                        class="mock-exam__grid-toggle-center"
                        tabindex="0"
                        aria-label="All Questions"
                        @click="toggleQuestionGrid"
                        @mousedown.prevent
                    >
                        <Icon
                            class="mock-exam__list-icon-center"
                            type="list"
                        />
                    </div>
                    <FlagToggle
                        :key="`flagToggle_${allowKeyboardShortcuts}`"
                        class="mock-exam__flag-toggle"
                        :is-flagged="isFlagged"
                        :is-dark-mode="isDarkMode"
                        :enable-flag-keyboard-shortcut="allowKeyboardShortcuts"
                        :enable-flag-tooltip="breakpoint !== 'brown-bear' && breakpoint !== 'black-bear'"
                        @toggleFlag="toggleFlag"
                    />
                    <Icon
                        :tabindex="isNextArrowDisabled ? -1 : 0"
                        role="button"
                        aria-label="Next question"
                        :aria-disabled="isNextArrowDisabled"
                        class="mock-exam__next-arrow"
                        :class="{
                            'mock-exam__next-arrow--disabled': isNextArrowDisabled,
                        }"
                        type="paginationArrow"
                        @click="clickNext"
                        @keydown.enter.stop.prevent="clickNext"
                        @mousedown.prevent
                    />
                </div>
                <KeyboardShortcutsButton
                    class="mock-exam__keyboard-shortcuts-btn"
                    :class="{
                        'mock-exam__keyboard-shortcuts-btn--is-break': isTenMinuteBreak,
                    }"
                    :tooltip-theme="
                        (breakpoint === 'polar-bear' || breakpoint === 'grizzly-bear' || breakpoint === 'brown-bear') 
                            && 'leftalign'
                    "
                    :is-dark-mode="isDarkMode"
                    @keydown.enter.stop
                >
                    <template
                        #keyboardShortcutsModal="{ 
                            showKeyboardShortcutsModal,
                            closeModalFunc
                        }"
                    >
                        <Portal to="modal">
                            <KeyboardShortcutsModal
                                v-if="showKeyboardShortcutsModal"
                                key="mockExamKeyboardShortcutsModal"
                                :allow-keyboard-shortcuts="allowKeyboardShortcuts"
                                :is-dark-mode="isDarkMode"
                                aria-live="polite"
                                @toggleKeyboardShortcuts="toggleKeyboardShortcuts"
                                @close="closeModalFunc"
                            />
                        </Portal>
                    </template>
                </KeyboardShortcutsButton>
            </template>
        </QuizContainer>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { examMetadataModule } from '@/store/examMetadata/module'
import { bundleModule } from '@/store/bundle/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import type { Study } from '@pocketprep/types'
import { userModule } from '@/store/user/module'
import { difference, localISODateString } from '@/utils'
import SubmitExamModal from '@/components/MockExam/SubmitExamModal.vue'
import ExitExamModal from '@/components/MockExam/ExitExamModal.vue'
import PencilsDownModal from '@/components/MockExam/PencilsDownModal.vue'
import QuestionGrid from '@/components/MockExam/QuestionGrid.vue'
import ScoringLoading from '@/components/MockExam/ScoringLoading.vue'
import { screenModule } from '@/store/screen/module'
import SkipToContent from '@/components/SkipToContent.vue'
import { mockExamModule } from '@/store/mockExam/module'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'
import { analyticsModule } from '@/store/analytics/module'
import { quizModule } from '@/store/quiz/module'

@Component({
    components: {
        ExitExamModal,
        SubmitExamModal,
        FlagToggle: UIKit.FlagToggle,
        KeyboardShortcutsButton: UIKit.KeyboardShortcutsButton,
        Icon: UIKit.Icon,
        ExamMenuCard: UIKit.ExamMenuCard,
        PocketButton: UIKit.Button,
        QuizContainer: UIKit.QuizContainer,
        Question: UIKit.Question,
        QuizProgress: UIKit.QuizProgress,
        Modal: UIKit.Modal,
        ModalContainer: UIKit.ModalContainer,
        ToggleSwitch: UIKit.ToggleSwitch,
        SkipToContent,
        KeyboardShortcutsModal: UIKit.KeyboardShortcutsModal,
        PencilsDownModal,
        QuestionGrid,
        ScoringLoading,
    },
    options: {
        beforeRouteEnter (to, from, next) {
            next(vm => {
                (vm as MockExam).fromName = (from.name as string | undefined) || null
            })
        },
    },
})
export default class MockExam extends Vue {
    isLoading = true
    isLoadingScoring = false
    questionIndex = 0
    timerInterval: ReturnType<typeof setInterval> | null = null
    breakTimerInterval: ReturnType<typeof setInterval> | null = null
    showExitExamModal = false
    showSubmitExamModal = false
    currentExam: null | Study.Class.ExamMetadataJSON = null
    currentBundle: null | Study.Class.BundleJSON = null
    fromName: string | null = null
    isFlagged = false
    brandColors = BrandColors
    isShowingQuestionGrid = false
    isShowingPencilsDownModal = false
    numOfQuestionsInitiallySkipped: number | null = null
    isTenMinuteBreak = false
    typeOfTimer = 'exam'
    breakCompleted = false
    halfOfExamQsAnswered = false

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get quizContainerEl () {
        const quizContainerRef = this.$refs['quizContainer'] as typeof UIKit.QuizContainer | undefined
        return quizContainerRef && '$el' in quizContainerRef ? quizContainerRef.$el : null
    }

    get mode () {
        return this.activeMockExamQuiz?.mode || null
    }

    get activeMockExamQuiz () {
        return mockExamModule.getters.getActiveMockExamQuiz()
    }

    get activeMockExamQuizQuestions () {
        return this.activeMockExamQuiz?.questions || []
    }

    get questionSerialSet () {
        return new Set(this.activeMockExamQuizQuestions.map(question => question.serial))
    }

    get answers () {
        return this.activeMockExamQuiz?.answers || []
    }

    get numCompleteQuestions () {
        return this.activeMockExamQuizQuestions?.filter(q => this.isQuestionComplete(q.serial))?.length || 0
    }

    get answerSerialSet () {
        return new Set(this.answers.map(answer => answer.questionSerial))
    }

    get numOfAllQuestionViews () {
        return this.activeMockExamQuiz?.numOfAllQuestionViews || 0
    }

    get secondsInAllQuestionsView () {
        return this.activeMockExamQuiz?.secondsInAllQuestionsView || 0
    }

    get timeLimitSeconds () {
        return (this.activeMockExamQuiz?.timeLimit || 0) * 60
    }

    get durationSeconds () {
        return this.activeMockExamQuiz?.durationSeconds || 0
    }

    get breakDurationSeconds () {
        return this.activeMockExamQuiz?.breakDurationSeconds || 0
    }

    get secondsRemaining () {
        return this.timeLimitSeconds - this.durationSeconds
    }

    get breakSecondsRemaining () {
        return 600 - this.breakDurationSeconds
    }

    get startedAt () {
        return this.activeMockExamQuiz?.startedAt
    }

    get currentQuestion () {
        return this.activeMockExamQuizQuestions[this.questionIndex] || null
    }

    get currentQuestionSeed () {
        return this.activeMockExamQuiz?.answerSeeds && this.activeMockExamQuiz.answerSeeds[this.questionIndex] || null
    }

    get quizLength () {
        return this.activeMockExamQuizQuestions.length
    }

    get timeDisplay () {
        if (!this.secondsRemaining) {
            return '0:00:00'
        }
        const isExtraTime = this.secondsRemaining < 0

        const hourSeconds = Math.abs(this.secondsRemaining)
        const hours = Math.floor(hourSeconds / 3600)
        const minuteSeconds = hourSeconds % 3600
        const minutes = Math.floor(minuteSeconds / 60)
        const paddedMinutes = String(minutes).padStart(2, '0')
        const seconds = minuteSeconds % 60
        const paddedSeconds = String(seconds).padStart(2, '0')

        return `${isExtraTime ? '+ ' : ''}${hours}:${paddedMinutes}:${paddedSeconds}`
    }

    get breakTimeDisplay () {
        if (!this.breakSecondsRemaining) {
            return '0:00:00'
        }

        const hourSeconds = Math.abs(this.breakSecondsRemaining)
        const minuteSeconds = hourSeconds % 3600
        const minutes = Math.floor(minuteSeconds / 60)
        const paddedMinutes = String(minutes).padStart(2, '0')
        const seconds = minuteSeconds % 60
        const paddedSeconds = String(seconds).padStart(2, '0')
        return `${paddedMinutes}:${paddedSeconds}`
    }

    /**
     * The remaining seconds at which point the timer should turn red.
     * (10% of the time limit rounded up to closest 5 minutes)
     */
    get lowTimeThreshold () {
        const timeLimitMinutes = this.activeMockExamQuiz?.timeLimit || 0
        const tenPercentTime = timeLimitMinutes * 0.1
        const roundedUpFiveMins = Math.ceil(tenPercentTime / 5) * 5
        return roundedUpFiveMins * 60   // seconds
    }

    get isLowTime () {
        return (0 <= this.secondsRemaining) && (this.secondsRemaining <= this.lowTimeThreshold)
    }

    get isOutOfTime () {
        return this.activeMockExamQuiz
            && !this.activeMockExamQuiz.getsExtraTime
            && (this.durationSeconds >= this.timeLimitSeconds)
    }

    get lastMinuteOfBreak () {
        return this.breakDurationSeconds >= 540
    }

    get isBreakOver () {
        return this.activeMockExamQuiz
            && (this.breakDurationSeconds >= 600)
    }

    get s3Url () {
        return import.meta.env.VUE_APP_S3_URL
    }

    get flaggedQuestions () {
        return userExamMetadataModule.getters.getFlaggedQuestions()
            .filter(serial => this.questionSerialSet.has(serial))
    }

    get answeredIndexList () {
        return this.activeMockExamQuizQuestions.reduce<number[]>((acc, question, index) => {
            if (this.answerSerialSet.has(question.serial)) {
                acc.push(index + 1)
            }
            return acc
        }, [])
    }

    get currentAnswer () {
        return this.answers.find(ans => this.currentQuestion?.serial === ans.questionSerial) || null
    }

    get previousChoices () {
        return this.currentAnswer?.selectedChoices || []
    }

    get isPreviousArrowDisabled () {
        return this.questionIndex === 0
    }

    get isNextArrowDisabled () {
        return (this.questionIndex >= (this.quizLength - 1))
    }

    get quizSettings () {
        return userModule.state.user?.quizSettings || null
    }

    get allowKeyboardShortcuts () {
        return !!this.quizSettings?.enableKeyboardShortcuts
    }

    get percentQuestionBankCompleted () {
        const totalAnswers = Object.keys(quizModule.getters.getLatestAnswers({ questionFilter: 'all' })).length || 0
        const totalQuestions = (this.currentExam?.itemCount || 0) - (this.currentExam?.archivedCount || 0)

        return Math.round((totalAnswers / totalQuestions) * 100)
    }

    get percentageAnswered () {
        return Math.round((this.answerSerialSet.size / this.questionSerialSet.size) * 100)
    }

    get priorMockExamsCompleted () {
        let priorMockExamsCompleted = 0     // Only for Mock Exams connected to the current ExamMetadata
        quizModule.state.quizzes.forEach(quiz => {
            if (quiz.mode === 5 && quiz.examGuid === this.currentExam?.examGuid) {
                priorMockExamsCompleted++
            }
        })
        return priorMockExamsCompleted
    }

    get mockExamFinishedPayload () {
        return {
            examName: this.currentExam?.nativeAppName || 'Unknown',
            percentageAnswered: this.percentageAnswered,
            minutesInExam: Math.round(this.durationSeconds / 60),
            choseExtraTime: this.activeMockExamQuiz?.getsExtraTime || false,
            numOfAllQuestionViews: this.numOfAllQuestionViews,
            platform: 'Web',
            percentQuestionBankCompleted: this.percentQuestionBankCompleted,
            priorMockExamsCompleted: this.priorMockExamsCompleted,
        }
    }

    async mounted () {
        if (!this.activeMockExamQuiz) {
            this.$router.push({ name: 'study' })
            return
        }

        // Restore place in any previously saved quiz
        this.questionIndex = this.activeMockExamQuiz.currentQuestionIndex

        await Promise.all([
            bundleModule.actions.fetchBundles(),
            examMetadataModule.actions.fetchExamMetadata(),
        ])

        this.currentExam = examMetadataModule.getters.getCurrentExamMetadata()
        this.currentBundle = bundleModule.getters.getCurrentBundle() || null

        const questionSerial = this.currentQuestion?.serial
        this.isFlagged = !!(questionSerial && this.flaggedQuestions.includes(questionSerial))

        if (this.isOutOfTime) {
            this.isShowingPencilsDownModal = true
        }

        const activeMockExamQuiz = this.activeMockExamQuiz
        // check which timer to start if a user has paused and restarted an exam
        if (activeMockExamQuiz) {
            if (activeMockExamQuiz.timerType === 'exam') {
                this.examTimer()
            }
            if (activeMockExamQuiz.timerType === 'break') {
                this.typeOfTimer = 'break'
                this.isTenMinuteBreak = true
                this.halfOfExamQsAnswered = true
                this.breakTimer()
            }
        } else {
            this.examTimer()
        }

        this.isLoading = false
    }

    beforeUnmount () {
        if (this.timerInterval) {
            clearInterval(this.timerInterval)
        }

        if (this.breakTimerInterval) {
            clearInterval(this.breakTimerInterval)
        }

        this.isTenMinuteBreak = false
    }

    examTimer () {
        this.timerInterval = setInterval(() => {
            const activeMockExamQuiz = this.activeMockExamQuiz

            if (activeMockExamQuiz) {
                const durationSeconds = activeMockExamQuiz.durationSeconds || 0
                if (!this.isOutOfTime) {
                    activeMockExamQuiz.durationSeconds = durationSeconds + 1
                    // If user is viewing All Questions, track the time spent in that view
                    if (this.isShowingQuestionGrid) {
                        if (!activeMockExamQuiz.secondsInAllQuestionsView) {
                            activeMockExamQuiz.secondsInAllQuestionsView = 1
                        } else if (this.isShowingQuestionGrid) {
                            activeMockExamQuiz.secondsInAllQuestionsView += 1
                        }
                    }
                    mockExamModule.actions.updateActiveMockExamQuiz(activeMockExamQuiz)
                    // out of time
                    if (this.timerInterval && this.isOutOfTime) {
                        clearInterval(this.timerInterval)
                    }

                    // half way through exam, start break timer
                    if (this.timerInterval 
                        && this.typeOfTimer === 'exam'
                        && !this.breakCompleted 
                        && this.halfOfExamQsAnswered
                    ) {
                        activeMockExamQuiz.timerType = 'break'
                        mockExamModule.actions.updateActiveMockExamQuiz(activeMockExamQuiz)
                        this.typeOfTimer = 'break'
                        this.isTenMinuteBreak = true
                        clearInterval(this.timerInterval)
                        this.breakTimer()
                    }
                }
            }
        }, 1000)
    }

    // 10 Minute Break Timer
    breakTimer () {
        this.breakTimerInterval = setInterval(() => {
            const activeMockExamQuiz = this.activeMockExamQuiz
            if (activeMockExamQuiz) {
                const breakDurationSeconds = activeMockExamQuiz.breakDurationSeconds || 0

                if (!this.isBreakOver) {
                    activeMockExamQuiz.breakDurationSeconds = breakDurationSeconds + 1
                    mockExamModule.actions.updateActiveMockExamQuiz(activeMockExamQuiz)

                    // 10 minute break is over, resume exam timer
                    if (this.breakTimerInterval 
                        && this.typeOfTimer === 'break' 
                        && this.isBreakOver
                    ) {
                        activeMockExamQuiz.timerType = 'exam'
                        mockExamModule.actions.updateActiveMockExamQuiz(activeMockExamQuiz)
                        this.typeOfTimer = 'exam'
                        this.isTenMinuteBreak = false
                        this.breakCompleted = true
                        clearInterval(this.breakTimerInterval)
                        this.examTimer()
                    }
                }
            }
        }, 1000)
    }

    isHalfWay () {
        const activeMockExamQuiz = this.activeMockExamQuiz
        if (activeMockExamQuiz?.questions.length && activeMockExamQuiz?.questions.length > 1) {
            const halfTotalQs = Math.ceil((activeMockExamQuiz?.questions.length || 0) / 2)
            const halfwayThroughQs = halfTotalQs === this.numCompleteQuestions ? true : false
            this.halfOfExamQsAnswered = halfwayThroughQs
        }
    }

    isQuestionComplete (questionSerial: string) {
        const question = this.activeMockExamQuizQuestions.find(q => q.serial === questionSerial)
        const answer = this.answers.find(a => a.questionSerial === questionSerial)
        if (!question || !answer) {
            return false
        }

        // Matrix questions are complete if each row has a selected choice
        if ([ 'Matrix Radio Button', 'Matrix Checkbox' ].includes(question.type)) {
            const answeredRowNums = answer.selectedChoices.map(choice =>
                Number(choice.split('_')[0].slice(1))    // a1_2 -> 1
            )
            const matrixRows = question.matrixChoiceLayout || []
            const isEveryRowAnswered = matrixRows.every((row, index) => {
                const rowNum = index + 1
                const hasSelectedChoiceForRow = answeredRowNums.includes(rowNum)
                return hasSelectedChoiceForRow
            })
            return isEveryRowAnswered
        }

        // Other question types are complete if they have 1 or more selected choice
        return answer.selectedChoices.length > 0
    }

    @Watch('currentQuestion')
    currentQuestionChanged () {
        this.isFlagged = !!this.currentQuestion && this.flaggedQuestions.includes(this.currentQuestion.serial)
    }

    async exitExamClicked () {
        if (this.numCompleteQuestions) { 
            this.showExitExamModal = true
        } else {  
            await this.exitExam()
        }
    }

    async exitExam () {
        if (this.currentExam) {
            await analyticsModule.actions.trackEvent('Mock_Exam_Finished_2', {
                ...this.mockExamFinishedPayload,
                abandoned: true,  // If user exited early
            })
        }

        mockExamModule.actions.updateActiveMockExamQuiz(null)
        if (!this.fromName || this.fromName === 'mock-exam-intro') {
            this.$router.push({
                name: 'study',
            })
        } else {
            this.$router.back()
        }
        this.isTenMinuteBreak = false    
    }

    resumeExam () {
        const activeMockExamQuiz = this.activeMockExamQuiz

        if (this.breakTimerInterval) {
            clearInterval(this.breakTimerInterval)
        }

        if (activeMockExamQuiz) {
            activeMockExamQuiz.timerType = 'exam'
            mockExamModule.actions.updateActiveMockExamQuiz(activeMockExamQuiz)
        }

        this.typeOfTimer = 'exam'
        this.isTenMinuteBreak = false
        this.breakCompleted = true
        this.examTimer()
    }

    clickPrevious () {
        if (this.questionIndex > 0) {
            this.questionIndex--
        }
        // check to see if the user is halfway through their questions
        // do not call again once break has been completed
        if (this.breakCompleted === false && this.halfOfExamQsAnswered === false) {
            this.isHalfWay()
        }
    }

    clickNext () {
        if (this.questionIndex < (this.quizLength - 1)) {
            this.questionIndex++

            // move focus back to top
            this.$nextTick(() => {
                const headEl = document.querySelector('.uikit-quiz-container__header') as HTMLElement
                const focusableEls = Array.from<HTMLElement>(headEl.querySelectorAll(
                    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
                ))
                focusableEls[focusableEls.length - 1]?.focus()
                focusableEls[focusableEls.length - 1]?.blur()
            })
        }

        // check to see if the user is halfway through their questions
        // do not call again once break has been completed
        if (this.breakCompleted === false && this.halfOfExamQsAnswered === false) {
            this.isHalfWay()
        }
    }

    progressBarClicked (questionNumber: number) {
        this.questionIndex = questionNumber - 1
        // check to see if the user is halfway through their questions
        // do not call again once break has been completed
        if (this.breakCompleted === false && this.halfOfExamQsAnswered === false) {
            this.isHalfWay()
        }
    }

    gridQuestionClicked (questionIndex: number) {
        this.questionIndex = questionIndex
    }

    checkAnswer (answer: Study.Cloud.IQuizAnswer) {
        this.questionAnswered(answer, true)
    }

    questionAnswered (answer: Study.Cloud.IQuizAnswer, checked?: boolean) {
        if (!this.activeMockExamQuiz) {
            throw new Error('A quiz is active, but no activeMockExamQuiz was found')
        }

        // Do not allow answering a question if the quiz is over
        if (this.isOutOfTime) {
            return
        }

        const answers = this.answers
        const existingAnswer = answers.find(ans => ans.questionSerial === answer.questionSerial)

        // If question component emits selected choices that we already have or are empty, don't make any updates
        if ((
            existingAnswer
            && !checked
            && !difference(existingAnswer.selectedChoices, answer.selectedChoices).length
            && !difference(answer.selectedChoices, existingAnswer.selectedChoices).length
        ) || (
            !existingAnswer
            && !answer.selectedChoices.length
        )) {
            return
        }
        
        if (existingAnswer && !answer.selectedChoices.length) {
            // If the user has removed their selections, remove the answer from the list
            const answerIndex = answers.indexOf(existingAnswer)
            answers.splice(answerIndex, 1)
        } else if (existingAnswer) {
            // If we have new selected choices for an existing answer, update them on the answer object
            existingAnswer.selectedChoices = answer.selectedChoices
            existingAnswer.isCorrect = answer.isCorrect
            if (checked) {
                existingAnswer.checked = !!checked
            }
        } else {
            // If we have selected choices for a new answer, update the answers list
            answers.push({
                ...answer,
                checked: !!checked,
            })
        }

        mockExamModule.actions.updateActiveMockExamQuiz({
            ...this.activeMockExamQuiz,
            answers,
        })
    }

    toggleQuestionGrid () {
        this.isShowingQuestionGrid = !this.isShowingQuestionGrid

        // Track number of times user views All Questions
        if (this.isShowingQuestionGrid && this.activeMockExamQuiz) {
            mockExamModule.actions.updateActiveMockExamQuiz({
                ...this.activeMockExamQuiz,
                numOfAllQuestionViews: this.numOfAllQuestionViews + 1,
            })
        }
    }

    async toggleFlag () {
        this.isFlagged = !this.isFlagged
        const serial = this.currentQuestion?.serial

        try {
            await userExamMetadataModule.actions.toggleQuestionFlag(serial)
        } catch (e) {
            // noop
        } finally {
            this.isFlagged = !!(serial && this.flaggedQuestions.includes(serial))
        }
    }

    toggleKeyboardShortcuts (newVal: boolean) {
        userModule.actions.updateQuizSettings({
            enableKeyboardShortcuts: newVal,
        })
    }

    async submitExam (skipConfirm?: true) {
        this.showExitExamModal = false
        this.showSubmitExamModal = false
        this.isTenMinuteBreak = false

        if (!this.currentExam) {
            throw new Error('MockExam.submitExam: No current exam found')
        }

        if (!this.activeMockExamQuiz) {
            throw new Error('MockExam.submitExam: No active mock exam quiz found')
        }

        if (!skipConfirm) {
            this.showSubmitExamModal = true
            return
        }

        const loadingStartTime = Date.now()
        this.isLoadingScoring = true

        await analyticsModule.actions.trackEvent('Mock_Exam_Finished_2', {
            ...this.mockExamFinishedPayload,
            abandoned: false,  // If user exited early
        })

        // If the user skipped any questions, we need to mark them as incorrect with no selected choice
        // Also, we want to make sure the answers are in the same order that the questions were presented
        const fullAnswers = this.activeMockExamQuizQuestions.map(question => {
            const answerForQuestion = this.answers.find(answer => answer.questionSerial === question.serial)

            return answerForQuestion || {
                questionSerial: question.serial,
                selectedChoices: [],
                isCorrect: false,
            }
        })

        const recordQuizResponse = await mockExamModule.actions.recordMockExamQuiz({
            answers: fullAnswers,
            durationSeconds: this.durationSeconds,
            examGuid: this.currentExam.examGuid,
            mode: 5,
            mockExamId: this.activeMockExamQuiz.mockExam.objectId,
            platform: 'Web',
            appBundleId: 'study.pocketprep.com',
            startedAt: this.startedAt || localISODateString(),
        })

        // Make sure the loading screen is visible for a minimum time (2 seconds)
        const loadingDuration = Date.now() - loadingStartTime
        const minimumLoadingDuration = 2000
        if (loadingDuration < minimumLoadingDuration) {
            await new Promise(res => setTimeout(res, minimumLoadingDuration - loadingDuration))
        }

        this.isTenMinuteBreak = false

        this.$router.push({
            name: 'quiz-result',
            params: { quizId: recordQuizResponse.quiz.objectId },
            query: {
                from: this.fromName || null,
            },
        })
        this.isLoading = false
    }

    @Watch('questionIndex')
    questionIndexChanged (questionIndex: MockExam['questionIndex']) {
        // When user FIRST reaches the last question, track how many questions they skipped
        if (questionIndex === this.questionSerialSet.size - 1 && this.numOfQuestionsInitiallySkipped === null) {
            // The number of skipped questions should not include the last question (which is always unanswered)
            this.numOfQuestionsInitiallySkipped = this.questionSerialSet.size - this.answerSerialSet.size - 1
        }

        if (this.activeMockExamQuiz) {
            mockExamModule.actions.updateActiveMockExamQuiz({
                ...this.activeMockExamQuiz,
                currentQuestionIndex: questionIndex,
            })
        }
    }

    @Watch('isOutOfTime')
    isOutOfTimeChanged (isOutOfTime: boolean) {
        if (isOutOfTime) {
            this.showExitExamModal = false
            this.showSubmitExamModal = false
            this.isShowingPencilsDownModal = true
        }
    }

    explanationViewToggled (showExplanation: boolean) {
        if (showExplanation) {
            analyticsModule.actions.amplitudeTrack(
                'Explanation Opened',
                {
                    examGuid: this.currentExam?.examGuid,
                    serial: this.currentQuestion.serial,
                    quizMode: this.mode || undefined,
                    platform: 'Web',
                }
            )
        }
    }
}
</script>

<style lang="scss" scoped>
.mock-exam {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &__exam-info {
        position: absolute;
        left: -10px;
        width: 320px;

        @include breakpoint(brown-bear) {
            width: 285px;
        }

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__exam-info-card {
        max-width: 100%;
    }

    &__progress {
        position: absolute;
        left: 50%;
        max-width: 768px;
        width: calc(40% + 130px);
        transform: translateX(-50%);

        @include breakpoint(polar-bear) {
            width: calc(40% + 45px);
        }

        @include breakpoint(brown-bear) {
            max-width: 280px;
            width: calc(36% + 38px);
        }

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__timer {
        display: flex;
        align-items: center;
        position: absolute;
        right: 121px;

        &--low {
            color: $rosa;
        }

        &--ten-minute-break {
            color: rgba(255, 255, 255, 0.6);
        }

        @include breakpoint(black-bear) {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__timer-icon {
        margin-right: 4px;

        &--low {
            width: 21px;
            height: 21px;
            margin-right: 6px;
        }
    }

    &__quit-btn {
        position: absolute !important;
        right: 0;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__break-container {
        position: absolute;
        background-color: $barely-green;
        top: 0;
        left: -65px;
        width: calc(100% + 130px);
        height: calc(100% + 65px);
        color: $brand-black;
        overflow: hidden;
        z-index: 1;

        @include breakpoint(polar-bear) {
            left: -23px;
            width: calc(100% + 45px);
        }

        @include breakpoint (brown-bear) {
            left: -19px;
            width: calc(100% + 38px);
        }

        @include breakpoint (black-bear) {
            left: 0;
            width: 100%;
        }

        &--last-minute {
            background-color: $barely-red;
        }

        &--dark {
            background-color: $charcoal;
        }
    }

    &__yoga-woman {
        margin: auto;
        text-align: center;
        padding-top: 60px;
    }

    &__yoga-woman-img {
        height: 122px;
        width: 199px;
    }

    &__break-header {
        width: 375px;
        height: 25px;
        color: $brand-black;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        font-weight: 600;
        padding-top: 32px;
        padding-bottom: 8px;
        margin: auto;

        &--dark {
            color: $white;
        }

        &:focus {
            outline: none;
        }
    }

    &__break-description {
        width: 375px;
        height: 52px;
        color: $ash;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        padding-bottom: 16px;
        margin: auto;

        &--dark {
            color: $white;
        }

        &:focus {
            outline: none;
        }
    }

    &__break-timer-container {
        background-color: rgba(20, 202, 158, 0.2);
        border-radius: 6px;
        width: 290px;
        height: 138px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;

        &--last-minute {
            background-color: rgba(255, 71, 71, 0.2);
        }
    }

    &__break-timer {
        color: $scifi-takeout;
        font-size: 80px;
        font-weight: 600;
        line-height: 96px;

        &--last-minute {
            color: $pepper;
        }

        &--last-minute-dark {
            color: $rosa;
        }

        &--dark {
            color: $jungle-green;
        }
    }

    &__resume-exam {
        margin: auto;
        text-align: center;
        padding-top: 32px;
    }

    &__loading {
        position: absolute;
        left: 50%;
        top: 25%;
        transform: translateX(-50%) scale(1.5);
    }

    &__question {
        position: relative;
        height: 100%;
    }

    &__grid-toggle {
        display: flex;
        position: relative;
        outline: none;
        left: 6px;
        cursor: pointer;

        &--is-break {
            display: none;
        }

        @media (hover: hover) {
            &:hover {
                color: $banana-bread;
            }
        }

        &:focus::before {
            content: '';
            position: absolute;
            border: 1px solid $butterscotch;
            width: 34px;
            height: 32px;
            top: -4px;
            left: -4px;
            border-radius: 4px;
            box-sizing: border-box;
        }
    }

    &__list-icon {
        width: 28px;
        height: 28px;
    }

    &__view-all-questions {
        display: flex;
        position: relative;

        &:hover {
            color: $banana-bread;
        }
    }

    &__view-all-questions-label {
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        padding-left: 15px;
        margin: auto;
    }

    &__controls {
        display: flex;
        align-items: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        &--is-break {
            display: none;
        }

        @include breakpoint(black-bear) {
            width: 304px;
        }
    }

    &__previous-arrow,
    &__next-arrow {
        &--disabled {
            opacity: 0.35;
        }

        &:not(&--disabled) {
            cursor: pointer;

            @media (hover: hover) {
                &:hover {
                    color: $banana-bread;
                }
            }
        }

        &:focus {
            outline: none;
            color: $banana-bread;
        }
    }

    &__previous-arrow {
        transform: rotate(180deg);
    }

    &__grid-toggle-center {
        position: relative;
        display: inline-flex;
        outline: none;
        margin-left: 40px;
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                color: $banana-bread;
            }
        }

        &:focus::before {
            content: '';
            position: absolute;
            border: 1px solid $butterscotch;
            width: 34px;
            height: 32px;
            top: -4px;
            left: -4px;
            border-radius: 4px;
            box-sizing: border-box;
        }
    }

    &__list-icon-center {
        width: 28px;
        height: 28px;
    }

    &__flag-toggle {
        margin: 0 80px;

        @include breakpoint(black-bear) {
            margin: 0 40px 0 60px;
        }
    }

    &__keyboard-shortcuts-btn {
        position: absolute;
        right: 8px;

        &--is-break {
            display: none;
        }

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    :deep(.uikit-quiz-container__footer) {
        z-index: 0;
    }
}
</style>
