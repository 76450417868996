<template>
    <div class="selected-exam">
        <ExamCard
            :exam="exam"
            :bundle="bundle"
            :show-cancel-button="true"
            @cancel="emitCancel"
        />
        <PocketButton class="selected-exam__submit" @click="emitNext">
            Create Account <Icon type="arrow" />
        </PocketButton>
        <PhonePerson 
            class="selected-exam__phone-person" 
            :bundle-id="bundle.objectId"
            :class="{ 'selected-exam__phone-person--referral': referral }" 
        />
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import type { Study } from '@pocketprep/types'

@Component({
    components: {
        ExamCard: UIKit.ExamCard,
        PocketButton: UIKit.Button,
        PhonePerson: UIKit.PhonePerson,
        Icon: UIKit.Icon,
    },
})
export default class SelectedExam extends Vue {
    @Prop() exam!: Study.Class.ExamMetadataJSON
    @Prop() bundle!: Study.Class.BundleJSON
    @Prop({ default: false }) referral!: boolean

    @Emit('cancel')
    emitCancel () {
        return true
    }

    @Emit('next')
    emitNext () {
        return true
    }
}
</script>
<style lang="scss" scoped>
.selected-exam {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 28px;

    &__submit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 1;
        margin: 48px 0 6px;

        @include breakpoint(black-bear) {
            margin-top: 40px;
        }

        svg {
            margin-left: 10px;
        }
    }

    &__phone-person {
        height: 312px;
        position: absolute;
        bottom: 0;
        right: 0;

        @include breakpoint(black-bear) {
            bottom: auto;
            top: 360px;
        }

        &--referral {
            @include breakpoint(black-bear) {
                margin-top: 26px;
                position: static;
            }
        }
    }
}
</style>