import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import Study from '@/components/Study.vue'
import Stats from '@/components/Stats.vue'
import SignIn from '@/components/SignIn.vue'
import Register from '@/components/Register.vue'
import UtiDiscountPage from '@/components/UtiDiscountPage.vue'
import EmailAuth from '@/components/EmailAuth.vue'
import Invite from '@/components/Invite.vue'
import ForgotPassword from '@/components/ForgotPassword.vue'
import Settings from '@/components/Settings.vue'
import Account from '@/components/Settings/Account.vue'
import Exams from '@/components/Settings/Exams.vue'
import Purchases from '@/components/Settings/Purchases.vue'
import Share from '@/components/Settings/Share.vue'
import Quiz from '@/components/Quiz.vue'
import MockExam from '@/components/MockExam.vue'
import BuildYourOwnQuiz from '@/components/Study/BuildYourOwnQuiz.vue'
import LevelUpQuizIntro from '@/components/LevelUpQuizIntro.vue'
import MockExamIntro from '@/components/MockExamIntro.vue'
import QuizResult from '@/components/QuizResult.vue'
import QotD from '@/components/QotD.vue'
import SharedQotD from '@/components/SharedQotD.vue'
import Review from '@/components/Review.vue'
import QuestionReview from '@/components/QuestionReview.vue'
import SSOError from '@/components/SSOError.vue'
import { userModule } from '@/store/user/module'
import { referralModule } from '@/store/referral/module'
import { apm } from '@/elk'
import { isSupportWindow } from '@/utils'
import { analyticsModule } from '@/store/analytics/module'

const routes: RouteRecordRaw[] = [
    {
        path: '/study',
        name: 'study',
        component: Study,
        meta: { 
            requiresAuth: true,
            title: 'Study',
        },
    },
    {
        path: '/stats',
        name: 'stats',
        component: Stats,
        meta: { 
            requiresAuth: true,
            title: 'Stats',
        },
    },
    {
        path: '/review',
        name: 'review',
        component: Review,
        meta: { 
            requiresAuth: true,
            title: 'Review',
        },
    },
    {
        path: '/question-review',
        name: 'question-review',
        component: QuestionReview,
        meta: { 
            requiresAuth: true,
            title: 'Review',
        },
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: { 
            requiresAuth: true,
            title: 'Settings',
        },
        children: [
            {
                path: 'account',
                name: 'account',
                component: Account,
                alias: [ '' ],
                meta: {
                    title: 'Account Settings',
                },
            },
            {
                path: 'exams',
                name: 'exams',
                component: Exams,
                meta: {
                    title: 'Exam Settings',
                },
            },
            {
                path: 'purchases',
                name: 'purchases',
                component: Purchases,
                meta: {
                    title: 'Purchase Settings',
                },
            },
            {
                path: 'share',
                name: 'share',
                component: Share,
                meta: {
                    title: 'Share Settings',
                },
            },
        ],
    },
    {
        path: '/referrals',
        name: 'referrals',
        redirect: { name: 'share' },
    },
    {
        path: '/quiz',
        name: 'quiz',
        component: Quiz,
        meta: { 
            requiresAuth: true,
            title: 'Quiz',
        },
    },
    {
        path: '/mock-exam',
        name: 'mock-exam',
        component: MockExam,
        meta: { 
            requiresAuth: true,
            title: 'Mock Exam',
        },
    },
    {
        path: '/build-your-own-quiz',
        name: 'byoq',
        component: BuildYourOwnQuiz,
        meta: { 
            requiresAuth: true,
            title: 'Build Your Own Quiz',
        },
    },
    {
        path: '/level-up-intro',
        name: 'level-up-intro',
        component: LevelUpQuizIntro,
        meta: { 
            requiresAuth: true,
            title: 'Level Up',
        },
    },
    {
        path: '/mock-exam-intro',
        name: 'mock-exam-intro',
        component: MockExamIntro,
        meta: { 
            requiresAuth: true,
            title: 'Mock Exam Intro',
        },
    },
    {
        path: '/quiz/create',
        name: 'quiz-create',
        redirect: { name: 'byoq' },
    },
    {
        path: '/quiz/:quizId',
        name: 'quiz-result',
        component: QuizResult,
        meta: { 
            requiresAuth: true,
            title: 'Quiz Result',
        },
    },
    {
        path: '/sign-in',
        name: 'sign-in',
        component: SignIn,
        alias: [ '/login' ],
        meta: { 
            title: 'Sign In',
        },
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        alias: [ '/sign-up', '/activateLicense' ],
        meta: { 
            ignoreAuth: true,
            title: 'Register',
        },
    },
    {
        path: '/uti-discount',
        name: 'uti-discount',
        component: UtiDiscountPage,
        meta: { 
            ignoreAuth: true,
            title: 'Uti Discount Page',
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
            title: 'Forgot Password',
        },
    },
    {
        path: '/emailAuth',
        name: 'email-auth',
        component: EmailAuth,
        meta: { 
            ignoreAuth: true,
            title: 'Magic Code', 
        },
    },
    {
        path: '/invite',
        name: 'invite',
        component: Invite,
        meta: { 
            ignoreAuth: true,
            title: 'Invite',
        },
    },
    {
        path: '/qotd/:quizId?',
        name: 'qotd',
        component: QotD,
        meta: { 
            requiresAuth: true,
            title: 'Question of the Day',
        },
    },
    {
        path: '/shared-qotd',
        name: 'shared-qotd',
        component: SharedQotD,
        alias: [ '/sharedqotd' ],
        meta: { 
            ignoreAuth: true,
            title: 'Shared Question of the Day',
        },
    },
    {
        path: '/sso-error',
        name: 'ssoError',
        component: SSOError,
        meta: {
            ignoreAuth: true,
            title: 'SSO Error',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: { name: 'study', params: {} },    // Empty params: see https://github.com/vuejs/router/issues/1617
    },
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                left: 0,
                top: 70,
            }
        }
        if (savedPosition) {
            return savedPosition
        }

        return { x: 0, y: 0 }
    },
    routes,
})

router.beforeEach(async (to, from, next) => {
    if (import.meta.env.VUE_APP_GOOGLE_ANALYTICS_ID && !isSupportWindow()) {
        gtag(
            'config', 
            import.meta.env.VUE_APP_GOOGLE_ANALYTICS_ID, 
            { page_path: `/${to.fullPath}` }
        )
    }

    // update page title
    document.title = `${to.meta?.title || 'Study'} | Pocket Prep`

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const ignoreAuth = to.matched.some(record => record.meta.ignoreAuth)
    const currentUser = userModule.state.user
    // If user is not signed in and route requires auth, completely sign out and redirect to sign-in
    // If user is signed in and route does not require auth, redirect to study
    // (NOTE: /register and /invite allow both signed in and not signed in users)
    // Otherwise, continue as usual
    if (ignoreAuth) {
        next()
    } else if (requiresAuth && !currentUser) {
        next({ name: 'sign-in', query: { redirect: to.fullPath } })
    } else if (to.name === 'sign-in' && typeof to.query.license === 'string') {
        next()
    } else if (to.name !== 'exams' && currentUser && typeof to.query.license === 'string') {
        next({ name: 'exams', query: { ...to.query } })
    } else if (!requiresAuth && currentUser) {
        next({ name: 'study', query: { ...to.query } })
    } else {
        next()
    }
})

router.afterEach((to) => {
    // Amplitude screens
    analyticsModule.actions.amplitudeTrackScreen(to)

    // log apm route change
    // this is a sort of hacky way to long route changes in a SPA
    if (apm) {
        const tr = apm.getCurrentTransaction()

        if (tr) {
            // transactions need at least one span
            const span = tr.startSpan(`Routed to ${to.fullPath}`)

            // transaction needs duration otherwise it will be discarded
            setTimeout(() => {
                span?.end()
                tr.end()
            }, 1)
        }
    }

    // Keep the referral query param in the url if we haven't consumed it yet
    const validReferral = referralModule.getters.getValidReferral()
    if (to.name && !to.query?.referral && validReferral) {
        router.replace({
            name: to.name,
            query: {
                ...to.query,
                referral: 'id' in validReferral ? validReferral.id : validReferral.objectId,
            },
        })
    }
})

export const isHeadVisible = () => {
    const routeName = router.currentRoute.value.name
    return routeName && typeof routeName === 'string' && ![
        'byoq',
        'mock-exam-intro',
        'level-up-intro',
        'mock-exam',
        'quiz',
        'qotd',
        'shared-qotd',
        'quiz-result',
        'question-review',
    ].includes(routeName)
}

export {
    router,
    routes,
}
