import type { ReadinessScoreResponse } from '@/store/readiness/actions'
import { type Loadable, initLoadable } from '@/store/utils'

export type TReadinessState = {
    readinessScores: Loadable<ReadinessScoreResponse[] | null>
}

export const getInitialReadinessState = (): TReadinessState => {
    return {
        readinessScores: initLoadable(null),
    }
}

const moduleState = getInitialReadinessState()

export default moduleState
