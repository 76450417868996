<template>
    <header
        v-dark
        class="head"
        :class="{
            'head--center': !user && !showMinimalHeader
        }"
    >
        <SkipToContent />
        <UpgradeSidePanel
            v-if="!isLoading && showUpgradeSidePanel && currentExamMetadata && !hasActiveSubscription && user"
            key="head"
            :has-active-referral-link="validReferral"
            :referral-link-errors="[ referralError ]"
            @close="showUpgradeSidePanel = false"
        />
        <MobileMenu
            v-if="showMobileMenu && !showUpgradeSidePanel && breakpoint === 'black-bear'"
            :exam-metadata="sortedExams"
            :current-exam-metadata="currentExamMetadata"
            @openUpgradeSidePanel="showUpgradeSidePanel = true"
            @close="closeMobileMenu()"
        />
        <ExamMenu
            v-if="user"
            class="head__exam-menu"
            :exam-metadata="sortedExams"
            :current-exam-metadata="currentExamMetadata"
        />
        <div class="head__logo" v-if="showMinimalHeader">
            <RouterLink
                :to="{ name: 'sign-in' }"
            >
                <img
                    src="@/assets/logos/logo-alt.svg"
                    alt="Pocket Prep Logo"
                >
            </RouterLink>
        </div>
        <div class="head__exam-mobile">
            <BundleIcon
                v-if="bundle"
                theme="silver"
                :bundle-id="bundle.objectId"
            />
            <div class="head__exam-mobile-name">
                {{ currentExamMetadata && currentExamMetadata.nativeAppName }}
            </div>
        </div>
        <div
            ref="mobileMenuIcon"
            class="head__burger-menu"
            tabindex="0"
            role="button"
            aria-label="Open Menu"
            :aria-hidden="showMobileMenu"
            v-if="!showMinimalHeader"
            @click="openMobileMenu"
            @keydown.enter="openMobileMenu"
            @mousedown.prevent
        >
            <img src="@/assets/mobile/burger.svg" alt="menu">
        </div>
        <nav class="head__navigation"
             role="tablist"
             v-if="!showMinimalHeader"
        >
            <RouterLink
                v-slot="{ isActive, navigate, href }"
                custom
                :to="{ name: 'study' }"
            >
                <a
                    v-dark
                    :href="href"
                    role="tab"
                    :aria-selected="isActive ? 'true' : 'false'"
                    class="head__navigation-link"
                    :class="{ 'head__navigation-link--active': isActive }"
                    :tabindex="user ? '0' : '-1'"
                    @click="navigate"
                    @mousedown.prevent
                >
                    Study
                </a>
            </RouterLink>
            <RouterLink
                v-slot="{ isActive, navigate, href }"
                custom
                :to="{ name: 'stats' }"
            >
                <a
                    v-dark
                    :href="href"
                    role="tab"
                    :aria-selected="isActive ? 'true' : 'false'"
                    class="head__navigation-link"
                    :class="{ 'head__navigation-link--active': isActive }"
                    :tabindex="user ? '0' : '-1'"
                    @click="navigate"
                    @mousedown.prevent
                >
                    Stats
                </a>
            </RouterLink>               
            <RouterLink
                v-slot="{ isActive, navigate, href }"
                custom
                :to="{ name: 'review' }"
            >
                <a
                    v-dark
                    :href="href"
                    role="tab"
                    :aria-selected="isActive ? 'true' : 'false'"
                    class="head__navigation-link"
                    :class="{ 'head__navigation-link--active': isActive }"
                    :tabindex="user ? '0' : '-1'"
                    @click="navigate"
                    @mousedown.prevent
                >
                    Review
                </a>
            </RouterLink>
        </nav>
        <PocketButton
            v-if="!isLoading && !hasActiveSubscription && user && currentExamMetadata && bundle?.name !== 'Finance'"
            class="head__upgrade"
            type="primary-yellow"
            :is-dark-mode="isDarkMode"
            @click="showUpgradeSidePanel = true"
        >
            Upgrade to Premium
        </PocketButton>
        <div v-if="user" class="head__right">
            <UserMenu />
            <div
                v-dark
                class="head__right-help"
                :tabindex="user ? '0' : '-1'"
                role="button"
                @click="showIntercom"
                @keydown.enter="showIntercom"
                @mousedown.prevent
            >
                Help
            </div>
        </div>
    </header>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import UserMenu from '@/components/Head/UserMenu.vue'
import ExamMenu from '@/components/Head/ExamMenu.vue'
import MobileMenu from '@/components/Head/MobileMenu.vue'
import UpgradeSidePanel from '@/components/Settings/UpgradeSidePanel.vue'
import SkipToContent from '@/components/SkipToContent.vue'
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { bundleModule } from '@/store/bundle/module'
import { screenModule } from '@/store/screen/module'
import { referralModule } from '@/store/referral/module'
import { subscriptionModule } from '@/store/subscription/module'
import type { Study } from '@pocketprep/types'
import { isSupportWindow } from '@/utils'

@Component({
    components: {
        UserMenu,
        ExamMenu,
        BundleIcon: UIKit.BundleIcon,
        PocketButton: UIKit.Button,
        MobileMenu,
        UpgradeSidePanel,
        SkipToContent,
    },
})
export default class Head extends Vue {
    isLoading = true
    showMobileMenu = false
    showUpgradeSidePanel = false

    get showMinimalHeader () {
        if (this.$route.name === 'ssoError') {
            return true
        }

        return false
    }

    get user () {
        return userModule.state.user
    }

    get userExamMetadata () {
        return userExamMetadataModule.getters.getUserExamMetadataByGuid()
    }

    get currentExamMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get examMetadataById () {
        return examMetadataModule.getters.getExamMetadataById()
    }

    get examMetadata () {
        const stateExamMetadata = examMetadataModule.getters.getExamMetadata()

        return Object.values(this.userExamMetadata).map(uem => {
            return stateExamMetadata.find(
                e => e.version.startsWith(`${uem.examVersion?.split('.')[0]}.`) && e.examGuid === uem.examGuid
            ) || examMetadataModule.getters.getMostRecentExamForExamGuid(uem.examGuid)
        }).filter(e => e)
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get sortedExams () {
        return [
            this.currentExamMetadata,
            ...[ ...this.examMetadata ]
                .filter(e => e?.examGuid !== this.currentExamMetadata?.examGuid)
                .sort((a, b) => a.nativeAppName.localeCompare(b.nativeAppName)),
        ]
    }

    get bundle () {
        return bundleModule.getters.getBundles()
            .find(b => b.exams.find(e => {
                const bundleExam = this.examMetadataById[e.objectId]
                return this.currentExamMetadata && bundleExam?.examGuid === this.currentExamMetadata.examGuid
            }))
    }

    get hasActiveSubscription () {
        return subscriptionModule.getters.getSubscriptionForExamId()
    }

    get validReferral () {
        return referralModule.getters.getValidReferral()
    }

    get referralError () {
        return referralModule.getters.getReferralError()
    }

    get hasSeenReferralUpgradePanel () {
        return referralModule.state.hasSeenReferralUpgradePanel
    }

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    async mounted () {
        if (this.showMinimalHeader) {
            this.isLoading = false
            return
        }

        await Promise.all([
            examMetadataModule.actions.fetchExamMetadata(),
            bundleModule.actions.fetchBundles(),
            userModule.actions.fetchUserData(),
            referralModule.actions.validateReferral(),
        ])

        if ((this.validReferral || this.referralError) && !this.hasSeenReferralUpgradePanel) {
            this.showUpgradeSidePanel = this.hasActiveSubscription ? false : true
        }

        this.isLoading = false
    }

    @Watch('validReferral')
    @Watch('referralError')
    referralAdded (
        newVal: ReturnType<Study.Cloud.validateReferral> | null, 
        oldVal: ReturnType<Study.Cloud.validateReferral> | null
    ) {
        if (!oldVal && newVal && !this.hasSeenReferralUpgradePanel && (this.validReferral || this.referralError)) {
            this.showUpgradeSidePanel = this.hasActiveSubscription ? false : true
        }
    }

    @Watch('hasActiveSubscription')
    checkActiveSubAfterMount () {
        if (this.hasActiveSubscription) {
            this.showUpgradeSidePanel = false
        }
    }

    showIntercom () {
        if (import.meta.env.VUE_APP_INTERCOM_APP_ID && !isSupportWindow()) {
            window.Intercom('show')
        }
    }

    openMobileMenu () {
        this.showMobileMenu = true
    }

    closeMobileMenu () {
        this.showMobileMenu = false

        this.$nextTick(() => {
            const mobileMenuIconEl = this.$refs['mobileMenuIcon'] as HTMLElement | undefined
            mobileMenuIconEl?.focus()
        })
    }
}
</script>

<style lang="scss" scoped>
.head {
    height: 54px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 0 46px 0 34px;
    align-items: center;
    box-sizing: border-box;
    background: $tangaroa;
    color: $white;

    @include breakpoint(grizzly-bear) {
        padding: 0 13px 0 6px;
    }

    @include breakpoint(black-bear) {
        padding: 0;
        position: initial;
    }

    &--dark {
        background: $charcoal;
    }

    &--center {
        justify-content: center;
    }

    &__exam-menu {
        width: 320px;
        position: relative;
        z-index: 1001; // just above side panel

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__logo {
        margin-left: 44px;

        img {
            width: 140px;
            display: block;
        }
    }

    &__exam-mobile {
        display: none;
        align-items: center;
        margin-left: 4px;
        width: calc(100% - 48px);

        @include breakpoint(black-bear) {
            display: flex;
        }

        .uikit-bundle-icon {
            width: 47px;
            height: 47px;
            margin-right: 8px;
        }
    }

    &__exam-mobile-name {
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 240px;
        width: 100%;
    }

    &__burger-menu {
        display: none;
        width: 44px;
        height: 44px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        margin-right: 4px;

        @include breakpoint(black-bear) {
            display: flex;
        }
    }

    &__upgrade {
        position: absolute !important;
        right: 322px;
        top: 9px;
        padding: 9px 18px 8px;

        @include breakpoint(grizzly-bear) {
            right: 225px;
        }

        @include breakpoint(brown-bear) {
            display: none;
        }
    }

    &__navigation {
        display: flex;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__navigation-link {
        color: $white;
        opacity: 0.8;
        text-decoration: none;
        padding: 18px 2px;
        position: relative;
        font-size: 15px;
        letter-spacing: 0.3px;
        line-height: 18px;
        font-weight: 600;
        display: block;
        margin: 0 7px;
        outline: none;

        &:hover {
            color: $banana-bread;
            opacity: 1;
        }

        &:focus {
            color: $banana-bread;
            opacity: 1;

            &::after {
                content: '';
                left: -6px;
                top: 4px;
                border: 1px solid $banana-bread;
                width: calc(100% + 9px);
                height: 44px;
                border-radius: 6px;
                position: absolute;
            }
        }

        &--active {
            opacity: 1;
            color: $banana-bread;

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 4px;
                background: $banana-bread;
            }
        }
    }

    &__left {
        width: 320px;
    }

    &__right {
        display: flex;
        align-items: center;
        width: 306px;
        justify-content: flex-end;

        @include breakpoint(brown-bear) {
            width: 268px;
        }

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__right-help {
        color: $white;
        text-decoration: none;
        padding: 10px 8px 11px;
        margin-left: 10px;
        display: block;
        font-size: 15px;
        position: relative;
        outline: none;
        border-radius: 6px;
        cursor: pointer;

        &:hover {
            background: $charcoal;
        }

        &:focus {
            background: $charcoal;

            &::before {
                content: '';
                left: 0;
                top: 0;
                position: absolute;
                border: 1px solid $banana-bread;
                width: calc(100% - 2px);
                border-radius: 6px;
                height: 36px;
            }
        }

        &--dark {
            &:hover,
            &:focus {
                background: $brand-black;
            }
        }
    }
}
</style>