<template>
    <div
        class="gauge__container"
        :class="{
            'gauge__container--large': size === 'large',
            'gauge__container--dark': theme === 'dark',
        }"
    >
        <template v-if="score !== null">
            <canvas
                ref="gauge"
                class="gauge"
                aria-hidden="true"
                :class="{
                    'gauge--large': size === 'large',
                }"
            />
        </template>
        <template v-else-if="size === 'large'">
            <img
                src="@/assets/stats/gauge-mobile.png"
                class="gauge gauge--large"
                alt=""
            >
        </template>
        <template v-else>
            <img
                src="@/assets/stats/gauge-desktop.png"
                class="gauge"
                alt=""
            >
        </template>
        <div 
            class="gauge__score-percent" 
            :class="{
                'gauge__score-percent--score': score !== null,
                'gauge__score-percent--no-score': score === null,
                'gauge__score-percent--large': size === 'large',
            }"
        >
            <template v-if="score !== null">
                {{ score }}<span>%</span>
            </template>
            <template v-else>
                --
            </template>
        </div>
        <div
            v-if="communityScore !== null && breakpoint !== 'black-bear'"
            class="gauge__community-score"
            :class="{
                'gauge__community-score--large': size === 'large',
            }"
        >
            <div 
                class="gauge__community-score-legend"
                :class="{ 
                    'gauge__community-score-legend--alt': communityScore - (score || 0) > 0,
                    'gauge__community-score-legend--dark': theme === 'dark',
                }"
            />
            <span class="gauge__community-score-span">Community Average is {{ communityScore || 0 }}%</span>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'
import { getScoreRange } from '@/utils'
import { userModule } from '@/store/user/module'
import { screenModule } from '@/store/screen/module'

@Component
export default class Gauge extends Vue {
    @Prop({ default: 'Overall Score' }) label!: string
    @Prop({ default: null }) score!: number | null
    @Prop({ default: null }) communityScore!: number | null
    @Prop({ default: 'medium' }) size!: 'medium' | 'large'
    @Prop({ default: 'light' }) theme!: 'light' | 'dark'

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    mounted () {
        this.updateGauge()
    }

    @Watch('isDarkMode')
    updateGauge () {
        const gaugeEl = this.$refs['gauge'] as HTMLCanvasElement
        const gauge = gaugeEl?.getContext('2d')
        if (gauge && this.score !== null) {
            gauge.clearRect(0, 0, gaugeEl.width, gaugeEl.height)

            // The large gauge is typically used for mobile views
            if (this.size === 'large') {
                this.drawGauge(gauge, 309 * 2, 18 * 2, this.score, this.communityScore)
            } else {
                this.drawGauge(gauge, 214 * 2, 12 * 2, this.score, this.communityScore)
            }
        }
    }

    drawGauge (
        gauge: CanvasRenderingContext2D,
        circleDiameter: number,
        lineWidth: number,
        score: number,
        communityScore: number | null
    ) {
        const circleCenter = (circleDiameter / 2) + (lineWidth / 2)

        // gauge background
        gauge.canvas.width = circleDiameter + lineWidth
        gauge.canvas.height = (circleDiameter / 2) + lineWidth
        gauge.beginPath()
        gauge.lineWidth = lineWidth
        gauge.strokeStyle = this.theme === 'light'
            ? BrandColors.grayDivider
            : this.isDarkMode
                ? BrandColors.jet
                : BrandColors.charcoal
        gauge.lineCap = 'round'
        gauge.arc(
            circleCenter, 
            circleCenter,
            circleDiameter / 2,
            Math.PI,
            Math.PI * 2
        )
        gauge.stroke()
        gauge.closePath()

        // gauge fill
        gauge.beginPath()
        const scoreRange = getScoreRange(score)
        const gradient = gauge.createLinearGradient(0, circleDiameter / 2, circleDiameter, 0)
        gradient.addColorStop(
            0,
            !this.isDarkMode
                ? scoreRange === 'low' 
                    ? BrandColors.tomato
                    : scoreRange === 'medium'
                        ? BrandColors.butterscotch
                        : BrandColors.cadaverous
                : scoreRange === 'low' 
                    ? BrandColors.brandRed
                    : scoreRange === 'medium'
                        ? BrandColors.bananaBread
                        : BrandColors.jungleGreen
        )
        gradient.addColorStop(
            1, 
            !this.isDarkMode
                ? scoreRange === 'low' 
                    ? '#ff6767'
                    : scoreRange === 'medium'
                        ? BrandColors.bananaBread
                        : BrandColors.brandGreen
                : scoreRange === 'low' 
                    ? '#ff6767'
                    : scoreRange === 'medium'
                        ? '#ffd688'
                        : '#2ae3b7'
        )
        gauge.strokeStyle = gradient
        gauge.arc(
            circleCenter, 
            circleCenter,
            circleDiameter / 2,
            Math.PI,
            Math.PI + (Math.PI * (score / 100))
        )
        gauge.stroke()
        gauge.closePath()

        // gauge dot
        if (communityScore !== null) {
            gauge.beginPath()
            gauge.fillStyle = ((communityScore - score) < 1) 
                ? BrandColors.jet 
                : this.theme === 'dark'
                    ? BrandColors.fog
                    : BrandColors.charcoal
            gauge.strokeStyle = ((communityScore - score) < 1) ? BrandColors.jet : BrandColors.charcoal
            gauge.lineWidth = 2
            gauge.arc(
                (circleDiameter / 2) * Math.cos(Math.PI * (1 + (communityScore / 100))) + circleCenter, 
                (circleDiameter / 2) * Math.sin(Math.PI * (1 + (communityScore / 100))) + circleCenter,
                this.size === 'medium' ? 8 : 12,
                0,
                2 * Math.PI
            )
            gauge.fill()
            gauge.stroke()
        }
    }
}
</script>

<style lang="scss" scoped>
.gauge {
    position: absolute;
    width: 226px;
    height: 114px;
    top: -70px;
    left: 0;

    &--large {
        width: 328px;
        height: 159px;
        top: -50px;
    }

    &__container {
        position: relative;
        width: 226px;
        height: 49px;
        text-align: center;
        line-height: 29px;
        color: $brand-black;

        &--large {
            width: 328px;
            height: 63px;
        }

        &--dark {
            color: $white;
        }
    }

    &__score-percent {
        position: relative;

        &--score {
            letter-spacing: 0.5px;
            font-size: 60px;
            top: 4px;
            font-weight: 700;

            @include breakpoint(black-bear) {
                top: 6px;
            }

            span {
                font-weight: 500;
            }
        }

        &--score#{&}--large {
            margin-top: 50px;
            font-size: 78px;
            letter-spacing: 0.64px;
        }

        &--no-score {
            letter-spacing: 0.64px;
            top: -10px;
            font-size: 64px;
            font-weight: 500;

            @include breakpoint(black-bear) {
                margin-top: 50px;
            }
        }
    }

    &__label {
        margin-top: 2px;
        font-size: 13px;
        letter-spacing: 0.2px;
        line-height: 18px;
        font-weight: 500;
        color: $slate-01;

        @include breakpoint(black-bear) {
            font-size: 14px;
        }

        &--large {
            margin-top: 16px;

            @include breakpoint(black-bear) {
                margin-top: 30px;
            }
        }

        &--dark {
            color: rgba($white, 0.8);
        }
    }

    &__community-score {
        position: absolute;
        bottom: -40px;
        width: 100%;
        text-align: center;

        &--large {
            bottom: -34px;

            @include breakpoint(black-bear) {
                bottom: -54px;
            }
        }

        span {
            font-size: 12px;
            line-height: 16px;
            margin-left: 7px;
        }
    }

    &__community-score-legend {
        width: 8px;
        height: 8px;
        background: $jet;
        border-radius: 8px;
        display: inline-block;
        border: 1px solid $jet;
        box-sizing: border-box;

        &--alt {
            background: $charcoal;
            border-color: $charcoal;
        }

        &--dark {
            background: $fog;
        }
    }
}
</style>
