<template>
    <Portal to="modal">
        <ModalContainer
            key="bonusQuestionSurveyModal"
            :is-dark-mode="isDarkMode"
            :show-close-button="true"
            @close="emitClose"
        >
            <div class="bonus-question-survey">
                <div class="bonus-question-survey__title-section" v-dark="isDarkMode">
                    <img
                        v-if="variant === 'shortAnswerQuestion'"
                        class="bonus-question-survey__title-emoji"
                        src="@/assets/surveys/emoji-star.png"
                    >
                    <img
                        v-else
                        class="bonus-question-survey__title-emoji"
                        src="@/assets/surveys/emoji-writing.png"
                    >
                    <div
                        v-if="variant === 'shortAnswerQuestion'"
                        class="bonus-question-survey__title"
                    >
                        Thanks for trying out the bonus question
                    </div>
                    <div
                        v-else
                        class="bonus-question-survey__title"
                        :class="{
                            'bonus-question-survey__title--explanation': variant === 'explanationAnswerQuestion'
                        }"
                    >
                        Thanks for trying out a new question type
                    </div>
                    <div
                        v-if="variant === 'shortAnswerQuestion'"
                        class="bonus-question-survey__text"
                        v-dark
                    >
                        We're testing a new question type.
                    </div>
                </div>
                <div class="bonus-question-survey__form">
                    <Radio
                        v-model="likeToSeeMore"
                        :data="yesNoOptions"
                        :is-dark-mode="isDarkMode"
                        class="bonus-question-survey__field"
                        :label="variant === 'shortAnswerQuestion'
                            ? 'Would you like to see more questions where you write your answer?'
                            : 'Would you like to see more questions where you write the explanation?'
                        "
                    />
                    <PocketTextarea
                        v-model="whyOrWhyNot"
                        label="Why or why not?"
                        class="bonus-question-survey__field bonus-question-survey__field-input"
                        :is-dark-mode="isDarkMode"
                        height="108"
                        @keydown.enter="submitForm"
                    />
                </div>
                <div class="bonus-question-survey__actions">
                    <PocketButton
                        class="bonus-question-survey__dismiss-btn"
                        :is-dark-mode="isDarkMode"
                        @click="emitClose"
                        type="secondary"
                    >
                        Cancel
                    </PocketButton>
                    <PocketButton
                        class="bonus-question-survey__submit-btn"
                        :disabled="isSubmitDisabled"
                        :is-dark-mode="isDarkMode"
                        @click="submitForm"
                    >
                        Submit
                    </PocketButton>
                </div>
            </div>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import { analyticsModule } from '@/store/analytics/module'

@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        PocketButton: UIKit.Button,
        Modal: UIKit.Modal,
        PocketTextarea: UIKit.Textarea,
        Radio: UIKit.Radio,
    },
})
export default class BonusQuestionSurvey extends Vue {
    @Prop variant!: 'shortAnswerQuestion' | 'explanationAnswerQuestion'
    @Prop({ default: '' }) bonusQText!: string

    likeToSeeMore: typeof this['yesNoOptions'][number] | null = null
    whyOrWhyNot = ''
    yesNoOptions = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
        { value: 'notsure', label: 'I\'m not sure' },
    ]

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get isSubmitDisabled () {
        return !this.likeToSeeMore
    }

    submitForm () {
        analyticsModule.actions.amplitudeTrack('Bonus Question Survey Answered', {
            bonusQuestionText: this.bonusQText,
            bonusQuestionVariation: this.variant,
            bonusQuestionSurveyMultipleChoiceAnswer: this.likeToSeeMore?.label || '',
            bonusQuestionSurveyShortAnswer: this.whyOrWhyNot,
        })
        this.emitSubmit()
    }

    @Emit('submit')
    emitSubmit () {
        return true
    }

    @Emit('close')
    emitClose () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.bonus-question-survey {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 520px;
    box-sizing: border-box;

    @include breakpoint(black-bear) {
        margin: 0 auto;
        width: 100%;
    }

    &__title-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 36px 40px 0;
        box-sizing: border-box;
        border-bottom: 1px solid $fog;

        @include breakpoint(black-bear) {
            padding: 36px 24px 0 32px;
        }

        &--dark {
            border-color: $ash;
        }
    }

    &__title-emoji {
        width: 19px;
        height: 25px;
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 6px;
        letter-spacing: 0.3px;

        &--explanation {
            margin-bottom: 30px;
        }

        @include breakpoint(black-bear) {
            width: 110%;
            text-align: center;
        }
    }

    &__text {
        font-size: 15px;
        line-height: 20px;
        color: $ash;
        margin-bottom: 31px;

        &--dark {
            color: $fog;
        }
    }

    &__form {
        margin-top: 30px;
        padding: 0 40px;
    }

    &__modal {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 94px;
    }

    &__field {
        margin-bottom: 20px;
    }

    &__field :deep(.uikit-radio__list) {
        padding: 0;
        font-weight: 400;
    }

    &__field :deep(.uikit-radio__label),
    &__field :deep(.uikit-textarea__label) {
        color: $brand-black;
        margin-left: 0;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        padding-right: 100px;

        @include breakpoint(black-bear) {
            padding-right: 0;
        }

        &.uikit-radio__label--dark,
        &.uikit-textarea__label--dark {
            color: $white;
        }
    }

    &__field :deep(.uikit-radio__label) {
        margin-bottom: 12px;
    }

    &__field :deep(.uikit-radio__item--dark::before) {
        background-color: $pickled-bluewood;
    }

    &__field-input :deep(.uikit-textarea__textarea) {
        margin-left: -9px;
        margin-right: -9px;
        width: calc(100% + 18px);
        height: 108px;
        @include breakpoint(black-bear) {
            height: 72px;
        }
    }

    &__actions {
        margin-top: 4px;
        align-self: center;
        padding: 0 40px 32px;
    }

    &__submit-btn {
        margin-left: 8px;
    }
}
</style>