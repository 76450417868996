<template>
    <Portal to="modal">
        <ModalContainer
            key="quitQuizModal"
            :show-close-button="true"
            :is-dark-mode="isDarkMode"
            @close="emitClose"
        >
            <div class="quit-modal">
                <h1
                    ref="quit-modal__title"
                    class="quit-modal__title"
                    tabindex="-1"
                >
                    Quit Quiz?
                </h1>
                <div v-dark class="quit-modal__text">
                    You've answered {{ numAnswers }} {{
                        quizMode === 'timed'
                            ? ''
                            : `of ${quizLength}`
                    }} question{{
                        (quizMode === 'timed' && numAnswers !== 1) || (quizMode !== 'timed' && quizLength !== 1)
                            ? 's'
                            : ''
                    }}. You will lose your progress if you quit now.
                </div>
                <div class="quit-modal__actions">
                    <PocketButton
                        v-if="numAnswers"
                        type="secondary"
                        class="quit-modal__submit-btn"
                        :is-dark-mode="isDarkMode"
                        @click="emitSubmit"
                    >
                        Submit {{ numAnswers }} Question{{ numAnswers !== 1 ? 's' : '' }}
                    </PocketButton>
                    <PocketButton
                        class="quit-modal__end-btn"
                        :is-dark-mode="isDarkMode"
                        @click="emitQuit"
                    >
                        End Quiz
                    </PocketButton>
                </div>
            </div>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import type { TQuizMode } from '@/store/quiz/state'
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'

@Component({
    components: {
        PocketButton: UIKit.Button,
        ModalContainer: UIKit.ModalContainer,
    },
})
export default class QuitQuizModal extends Vue {
    @Prop({ default: 'quick10' }) quizMode!: TQuizMode
    @Prop({ default: 0 }) numAnswers!: number
    @Prop({ default: 1 }) quizLength!: number

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    mounted () {
        this.$nextTick(() => {
            const titleEl = this.$refs['quit-modal__title'] as HTMLElement | undefined
            titleEl?.focus()
        })
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('submit')
    emitSubmit () {
        return true
    }

    @Emit('quit')
    emitQuit () {
        return true
    }
}
</script>

<style lang="scss" scoped>
// @import '@pocketprep/ui-kit/styles/_colors';

.quit-modal {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 41px 26px 38px;
    width: 384px;
    margin: 0 auto;

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin: 0 0 12px 0;
        outline: none;
    }

    &__text {
        color: $slate-03;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 29px;

        &--dark {
            color: $white;
        }
    }

    &__submit-btn {
        margin-right: 4px;
    }
}

</style>