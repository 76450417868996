<template>
    <div
        v-if="examGuids && stripePrice"
        class="uti-payment-details"
    >
        <div class="uti-payment-details__price-info">
            <div class="uti-payment-details__price-text">
                Lifetime ASE Exam Access - ${{ priceDollarAmount }}
            </div>
            <div class="uti-payment-details__price-subtext">
                Exam Prep for all {{ examGuids.length }} ASE exams
            </div>
        </div>
        <PocketSelect
            v-if="paymentMethods.length > 1"
            v-model="paymentMethod"
            label="Wallet"
            class="uti-payment-details__payment-methods"
            :data="paymentMethods"
        />
        <PaymentFields
            v-if="paymentMethod && paymentMethod.value === 'add'"
            :one-time-purchase="true"
            :errors="errors"
            class="uti-payment-details__fields"
            :error-fields="errorFields"
            :plan-amount="priceAmountDecimal"
            @update="updatePaymentParams"
        />
        <Errors
            v-if="errors.length"
            class="uti-payment-details__errors"
            :errors="errors"
        />
        <PocketButton
            class="uti-payment-details__submit"
            :disabled="!stripePrice
                || !((paymentMethod && paymentMethod.value !== 'add') || (cardElCompleted && addressElCompleted))
                || errors.length || isPaymentBeingProcessed"
            :is-loading="isPaymentBeingProcessed"
            @click="submitPayment"
            @keydown.enter="submitPayment"
        >
            Start Studying <Icon type="arrow" />
        </PocketButton>
    </div>
</template>
   
<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import PaymentFields from '@/components/PaymentFields.vue'
import AppleGooglePay from '@/components/AppleGooglePay.vue'
import { type StripeElement, type StripeError } from '@stripe/stripe-js'
import { stripeModule } from '@/store/stripe/module'
import { stripeDisabledAppearance, stripeEnabledAppearance } from '@/utils'
import { subscriptionModule } from '@/store/subscription/module'
import { isProxy, toRaw } from 'vue'

@Component({
    components: {
        PocketInput: UIKit.Input,
        PocketSelect: UIKit.Select,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
        Errors: UIKit.Errors,
        PaymentFields,
        AppleGooglePay,
    },
})
export default class UtiPaymentDetails extends Vue {
    @Prop() examGuids!: string[] | null
    @Prop() stripePrice!: {
        id: string
        object: 'price'
        created: number  
        nickname: string | null
        unit_amount: number | null
        unit_amount_decimal: string | null
    } | null

    stripe: stripe.Stripe | null = null
    errorFields: string[]  = []
    errors: string[] = []
    paymentMethod: null | { value: string; label: string } = null
    cardElCompleted = false
    addressEl: StripeElement | null | void = null
    addressElCompleted = false
    renewDate: null | string = null
    appleGooglePayAvailable = false
    isPaymentBeingProcessed = false

    get priceAmountDecimal () {
        return Number(this.stripePrice?.unit_amount_decimal) || 0
    }

    get priceDollarAmount () {
        return this.priceAmountDecimal / 100
    }

    get paymentMethods () {
        const options = stripeModule.getters.getStripePaymentMethods()?.reduce((acc, pm) => {
            if (new Date(pm.card?.exp_year || 0, (pm.card?.exp_month || 0) + 1, 0).getTime() < new Date().getTime()) {
                return acc
            }
            const label = `Card ending in ${pm.card?.last4} (Exp ${pm.card?.exp_month}/${pm.card?.exp_year})`
            acc.push({
                value: pm.id,
                label,
            })

            return acc
        }, [] as { value: string; label: string}[])

        return [
            { value: 'add', label: '+ New Payment Method' },
            ...options,
        ]
    }

    get orgId () {
        return typeof this.$route.query.orgId === 'string' ? this.$route.query.orgId : null
    }

    async mounted () {
        await stripeModule.actions.fetchPaymentMethods()

        this.paymentMethod = this.paymentMethods[0]
    }

    updatePaymentParams (params: {
        addressEl: StripeElement | null | void
        cardElCompleted: boolean
        addressElCompleted: boolean
    }) {
        this.addressEl = params.addressEl
        this.cardElCompleted = params.cardElCompleted
        this.addressElCompleted = params.addressElCompleted
    }

    async submitPayment () {
        if (!this.orgId) {
            this.errors.push('Must select a campus')
            return
        }
        if (this.isPaymentBeingProcessed) {
            return
        }

        this.isPaymentBeingProcessed = true

        if (import.meta.env.VUE_APP_STRIPE_PUBLISHABLE_KEY && this.stripePrice && this.priceAmountDecimal) {
            const { stripe, elements } = await stripeModule.actions.loadStripeAndElements({
                planAmount: this.priceAmountDecimal,
                creatingPaymentField: false,
                oneTimePurchase: true,
            })
            if (stripe && elements) {
                const disabledAppearance = stripeDisabledAppearance(false)
                const enabledAppearance = stripeEnabledAppearance(false)

                await elements.submit()
                // disable card fields while processing
                const paymentStripeEl = elements.getElement('payment')
                paymentStripeEl?.update({ readOnly: true })
                stripeModule.getters.getElements()?.update({ appearance: disabledAppearance })
                // retrieves clientSecret and subscriptionId
                const { clientSecret, invoiceId } = await subscriptionModule.actions
                    .createIncompleteDiscountPurchase({
                        priceId: this.stripePrice.id,
                    })
                if (clientSecret && invoiceId) {
                    // redirect we will 'keep it here'
                    // Register will redirect us on a success
                    let stripeError: StripeError | undefined
                    if (this.paymentMethod?.value && this.paymentMethod.value !== 'add') {
                        const { error } = await stripe.confirmPayment({
                            clientSecret: clientSecret,
                            confirmParams: {
                                return_url: `${window.location.href}`,
                                ['payment_method' as 'receipt_email']: this.paymentMethod.value,
                            },
                            redirect: 'if_required',
                        })
                        stripeError = error
                    } else {
                        const { error } = await stripe.confirmPayment({
                            elements: isProxy(elements) ? toRaw(elements) : elements,
                            clientSecret: clientSecret,
                            confirmParams: {
                                return_url: `${window.location.href}`,
                            },
                            redirect: 'if_required',
                        })
                        stripeError = error
                    }

                    if (stripeError && stripeError.message) {
                        // show error message
                        this.errors.push(stripeError.message)
                        this.isPaymentBeingProcessed = false
                        paymentStripeEl?.update({ readOnly: false })
                        stripeModule.getters.getElements()?.update({ appearance: enabledAppearance })
                        this.emitSubmitPayment(false)
                        throw new Error(stripeError.message)
                    } else {
                        try {
                            await subscriptionModule.actions.activateDiscountPurchase({
                                organizationId: this.orgId,
                                invoiceId,
                            })
                            this.emitSubmitPayment(true)
                        } catch {
                            this.errors.push('Unable to process payment. Please try again.')
                            this.isPaymentBeingProcessed = false
                            paymentStripeEl?.update({ readOnly: false })
                            stripeModule.getters.getElements()?.update({ appearance: enabledAppearance })
                            this.emitSubmitPayment(false)
                        }

                    }
                } else {
                    this.errors.push('Unable to process payment. Please try again.')
                    this.isPaymentBeingProcessed = false
                    paymentStripeEl?.update({ readOnly: false })
                    stripeModule.getters.getElements()?.update({ appearance: enabledAppearance })
                    throw new Error('UTI Payment - missing clientSecret or subscriptionID')
                }
            }
        }

        this.isPaymentBeingProcessed = false
    }

    @Watch('cardElCompleted')
    updateErrorsWithCardChange (newVal: boolean, oldVal: boolean) {
        if (!oldVal && newVal && this.errors.length) {
            this.errors = []
        }
    }

    @Emit('error')
    emitError () {
        return true
    }

    @Emit('submit')
    emitSubmitPayment (successfulTransaction: boolean) {
        return successfulTransaction
    }
}
</script>
<style lang="scss" scoped>
.uti-payment-details {
    width: 348px;
    margin: 33px auto 0;

    @include breakpoint(black-bear) {
        width: auto;
        margin-top: 66px;
    }

    &__price-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px 20px;
        background-color: white;
        border: 1px solid rgba(136, 163, 182, 0.85);
        border-radius: 5px;
        margin-bottom: 17px;
    }

    &__price-text {
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 2px;
    }

    &__price-subtext {
        font-size: 13px;
        line-height: 18px;
        color: $slate;
    }

    &__payment-methods {
        margin-bottom: 17px;
    }

    &__submit {
        margin: 32px auto 6px;
        display: block;

        svg {
            margin-left: 10px;
        }
    }
}
</style>