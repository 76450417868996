<template>
    <Portal to="modal">
        <ModalContainer
            v-if="showLearnMoreModal"
            key="badgeBannerLearnMoreModal"
            :is-dark-mode="isDarkMode"
            @close="showLearnMoreModal = false"
        >
            <template #modal>
                <Modal
                    class="badge-banner__learn-more-modal"
                    :is-dark-mode="isDarkMode"
                    :show-close-button="true"
                    @close="showLearnMoreModal = false"
                >
                    <div class="badge-banner__learn-more-modal-title">
                        Did You Know?
                    </div>
                    <div class="badge-banner__learn-more-modal-text">
                        Answering 100 questions each week improves your chances of passing your exam. Give it a try!
                    </div>
                    <PocketButton
                        class="badge-banner__learn-more-modal-close-btn"
                        :is-dark-mode="isDarkMode"
                        @click="showLearnMoreModal = false"
                    >
                        Close
                    </PocketButton>
                </Modal>
            </template>
        </ModalContainer>
        <ModalContainer
            v-else-if="showViewBadgeModal"
            key="badgeBannerViewBadgeModal"
            :is-dark-mode="isDarkMode"
            :show-close-button="true"
            @close="closeViewBadgeModal"
        >
            <template #modal>
                <div v-if="showConfetti" class="badge-banner__confetti-container">
                    <img src="@/assets/celebrations/badge-confetti.gif" alt="">
                </div>
                <Modal
                    class="badge-banner__view-badge-modal"
                    :is-dark-mode="isDarkMode"
                    :show-close-button="true"
                    @close="closeViewBadgeModal"
                >
                    <IconBadge
                        class="badge-banner__view-badge-modal-icon"
                        v-dark="isDarkMode"
                    />
                    <div class="badge-banner__view-badge-modal-title">
                        Weekly 100
                    </div>
                    <div class="badge-banner__view-badge-modal-text">
                        Answer 100 questions in a week
                    </div>
                    <div class="badge-banner__view-badge-modal-earned-count">
                        Earned {{ badgeEarnedTotalCount }} time{{ badgeEarnedTotalCount === 1 ? '' : 's' }}
                    </div>
                    <PocketButton
                        class="badge-banner__view-badge-modal-close-btn"
                        :is-dark-mode="isDarkMode"
                        @click="closeViewBadgeModal"
                    >
                        Close
                    </PocketButton>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
    <div
        class="badge-banner"
        :class="{
            'badge-banner--earned': badgeEarnedCurrentWeek,
        }"
        v-dark="isDarkMode"
    >
        <Tag
            class="badge-banner__beta-tag"
            :is-beta-tag="true"
            :is-dark-mode="isDarkMode"
            size="small"
        >
            <template #tagMessage>
                Beta
            </template>
        </Tag>
        <template v-if="!badgeEarnedCurrentWeek">
            <div class="badge-banner__info-container">
                <div class="badge-banner__title">
                    Earn a {{ badgeName }} badge!
                </div>
                <div class="badge-banner__description">
                    {{ badgeDescription }}
                </div>
                <PocketButton
                    v-if="!hasEarnedBadgeEver"
                    class="badge-banner__btn"
                    type="tertiary"
                    :is-dark-mode="isDarkMode"
                    @click="learnMoreClicked"
                >
                    Learn More
                </PocketButton>
                <PocketButton
                    v-else
                    class="badge-banner__btn"
                    type="tertiary"
                    :is-dark-mode="isDarkMode"
                    @click="viewBadgeClicked"
                >
                    View Badge
                </PocketButton>
            </div>
        </template>
        <template v-else>
            <div class="badge-banner__icon-info-container">
                <IconBadge class="badge-banner__icon" />
                <div class="badge-banner__info-container">
                    <div class="badge-banner__title">
                        You earned a {{ badgeName }} badge!
                    </div>
                    <div class="badge-banner__description">
                        Try for another one next week
                    </div>
                    <PocketButton
                        class="badge-banner__btn"
                        type="tertiary"
                        :is-dark-mode="isDarkMode"
                        @click="viewBadgeClicked"
                    >
                        View Badge
                    </PocketButton>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import IconBadge from '@/components/IconBadge.vue'
import { analyticsModule } from '@/store/analytics/module'
import { examMetadataModule } from '@/store/examMetadata/module'

@Component({
    components: {
        IconBadge,
        Tag: UIKit.Tag,
        PocketButton: UIKit.Button,
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
    },
})
export default class BadgeBanner extends Vue {
    @Prop badgeName!: string
    @Prop badgeDescription!: string
    @Prop badgeEarnedDate?: Date
    @Prop({ default: false }) badgeEarnedCurrentWeek!: boolean
    @Prop({ default: 0 }) badgeEarnedTotalCount!: number
    @Prop({ default: false }) isDarkMode!: boolean

    showLearnMoreModal = false
    showViewBadgeModal = false
    showConfetti = false

    get currentExam () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get hasEarnedBadgeEver () {
        return this.badgeEarnedTotalCount > 0
    }

    learnMoreClicked () {
        this.showLearnMoreModal = true
        analyticsModule.actions.amplitudeTrack('Button Clicked', {
            callToAction: 'Learn More',
            containerName: 'Badge Banner',
            screenName: analyticsModule.getters.getScreenName(this.$route),
            ...(this.currentExam?.examGuid && { examGuid: this.currentExam.examGuid }),
        })
    }

    viewBadgeClicked () {
        this.showViewBadgeModal = true
        analyticsModule.actions.amplitudeTrack('Button Clicked', {
            callToAction: 'View Badge',
            containerName: 'Badge Banner',
            screenName: analyticsModule.getters.getScreenName(this.$route),
            ...(this.currentExam?.examGuid && { examGuid: this.currentExam.examGuid }),
        })
        analyticsModule.actions.amplitudeTrack('Badge Viewed', {
            badgeName: 'weekly100',
            badgeEarnedCount: this.badgeEarnedTotalCount,
            ...(this.badgeEarnedDate && { badgeEarnedDate: this.badgeEarnedDate.toISOString() }),
            ...(this.currentExam?.examGuid && { examGuid: this.currentExam.examGuid }),
        })

        if (this.badgeEarnedCurrentWeek && !this.showConfetti) {
            this.showConfetti = true
            setTimeout(() => {
                this.showConfetti = false
            }, 4000)
        }
    }

    closeViewBadgeModal () {
        this.showViewBadgeModal = false
    }
}
</script>

<style lang="scss" scoped>
.badge-banner {
    position: relative;
    margin-top: 8px;
    margin-bottom: 8px;
    min-height: 83px;
    box-sizing: border-box;
    border: 1px solid rgba($brand-blue, 0.4);
    border-radius: 6px;
    padding: 12px 52px 12px 15px;
    background-color: $barely-blue;

    &--dark {
        border-color: rgba($baby-blue, 0.4);
        background-color: rgba($brand-blue, 0.2);
    }

    &--earned {
        border-color: rgba($brand-green, 0.4);
        background-color: $barely-green;

        &--dark {
            background-color: rgba($jungle-green, 0.2);
        }
    }

    &__confetti-container {
        width: 100%;
        max-height: 100vh;
        position: absolute;
        display: flex;
        justify-content: center;

        @media (prefers-reduced-motion: reduce) {
            display: none
        }
    }

    &__learn-more-modal,
    &__view-badge-modal {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 80px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 384px;
        padding: 35px 0 26px;
        box-sizing: border-box;

        @include breakpoint(black-bear) {
            width: 356px;
            height: auto;
            top: 10px;
        }
    }

    &__view-badge-modal-icon {
        width: 72px;
        height: 72px;
        margin-top: 12px;
        margin-bottom: 32px;
        color: $cheddar;

        &--dark {
            color: $banana-bread;
        }
    }

    &__learn-more-modal-title,
    &__view-badge-modal-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.3px;
        text-align: center;
        margin-bottom: 12px;
    }

    &__learn-more-modal-text {
        margin: 0 auto;
        text-align: center;
        width: 304px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
    }

    &__view-badge-modal-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 12px;
    }

    &__view-badge-modal-earned-count {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
    }

    &__learn-more-modal-close-btn,
    &__view-badge-modal-close-btn {
        margin-top: 32px;
    }

    &__beta-tag {
        position: absolute;
        top: 12px;
        right: 10px;
    }

    &__icon-info-container {
        display: flex;
    }

    &__icon {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        color: $banana-bread;
    }

    &__title {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
    }

    &__description {
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 12px;
    }
}
</style>
