<template>
    <div class="select-version">
        <div class="select-version__info">
            What exam is right for you? You can change your exam version at any time in settings.
        </div>
        <div class="select-version__versions">
            <ExamVersionCard
                v-for="(exam, index) in examVersions"
                :key="exam.objectId"
                :exam="exam"
                :adding-exam="true"
                :is-selected-version="selectedVersion === exam.version"
                :is-latest-version="index === 0"
                @clickExamVersion="selectVersion"
            />
        </div>
        <div class="select-version__buttons">
            <PocketButton
                class="select-version__back"
                type="secondary"
                @click="emitCancel"
            >
                Back to Browse
            </PocketButton>
            <PocketButton
                class="select-version__submit"
                :disabled="!selectedVersion"
                @click="emitNext"
            >
                Create Account <Icon type="arrow" />
            </PocketButton>
        </div>
        <PhonePerson class="select-version__phone-person" :bundle-id="bundle.objectId" />
    </div>
</template>

<script lang="ts">
import type { Study } from '@pocketprep/types'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import ExamVersionCard from '@/components/Settings/EditExam/ExamVersionCard.vue'

@Component({
    components: {
        PocketButton: UIKit.Button,
        PhonePerson: UIKit.PhonePerson,
        ExamVersionCard,
        Icon: UIKit.Icon,
    },
})
export default class SelectVersion extends Vue {
    @Prop() examVersions!: Study.Class.ExamMetadataJSON[]
    @Prop() bundle!: Study.Class.BundleJSON

    selectedVersion: string | null = null

    selectVersion (exam: Study.Class.ExamMetadataJSON) {
        this.selectedVersion = exam.version
        this.emitSelectExamVersion(exam)
    }

    @Emit('cancel')
    emitCancel () {
        return true
    }

    @Emit('next')
    emitNext () {
        return true
    }

    @Emit('selectExamVersion')
    emitSelectExamVersion (exam: Study.Class.ExamMetadataJSON) {
        return exam
    }
}
</script>

<style lang="scss" scoped>
.select-version {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 20px;

    @include breakpoint(black-bear) {
        padding-bottom: 308px;
        margin-bottom: 0px;
    }

    &__info {
        font-size: 14px;
        line-height: 17px;
        color: $ash;
        width: 303px;
        margin-bottom: 29px;
        text-align: center;
    }

    &__versions {
        width: 376px;
        max-width: calc(100% - 60px);
    }

    &__buttons {
        margin-top: 14px;
        display: flex;
        justify-content: space-around;
    }

    &__back {
        margin-right: 8px;
    }

    &__phone-person {
        height: 312px;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;

        @include breakpoint(black-bear) {
            height: 286px;
            bottom: -190px;
        }
    }
}
</style>