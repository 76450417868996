import { initLoadable, type Loadable } from '@/store/utils'

export interface IKeywordsState {
    keywordDefinitions: Loadable<Map<string, Map<string, string>>>
}

export const getInitialAnalyticsState = (): IKeywordsState => {
    return {
        keywordDefinitions: initLoadable(new Map()),
    }
}

const moduleState = getInitialAnalyticsState()

export default moduleState
