import { examMetadataModule } from '@/store/examMetadata/module'
import { Parse, runCloudFunction } from '@/store/parseUtils'
import { quizModule } from '@/store/quiz/module'
import type { TActiveQuiz, TQuizMode } from '@/store/quiz/state'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import type { Study } from '@pocketprep/types'
import { userModule } from '@/store/user/module'
import { localISODateString } from '@/utils'
import { fetchLoadable, resetLoadable } from '@/store/utils'
import { globalQuestionMetricModule } from '@/store/globalQuestionMetric/module'
import { utils } from '@pocketprep/ui-kit'
import { mockExamModule } from '@/store/mockExam/module'
import { analyticsModule } from '@/store/analytics/module'
import { readinessModule } from '@/store/readiness/module'

const fetchAnsweredQuestions = async (quizId?: string) => {
    await Promise.all([
        userModule.actions.fetchUserData(),
        examMetadataModule.actions.fetchExamMetadata(),
        userExamMetadataModule.actions.fetchUserExamMetadata(),
    ])

    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()
    
    if (!currentExam) {
        return
    }

    // if we're fetching a specific quiz's answers we just want to tack them onto the state
    if (quizId) {
        const answeredQuestions = await runCloudFunction<Study.Cloud.fetchQuestionsByQuizIdsV3>(
            'fetchQuestionsByQuizIds-v3', 
            { quizIds: [ quizId ], compositeKey: currentExam?.compositeKey }
        )

        // use a Set to dedupe just in case
        const questionsSerialObj =
        ([ ...await quizModule.state.answeredQuestions.value, ...answeredQuestions ])
            .reduce((acc, question) => {
                acc[question.serial] = question
                return acc
            }, {} as { [serial: string]: Study.Class.QuestionJSON })

        quizModule.state.answeredQuestions.value = Object.values(questionsSerialObj)
    } else {
        const quizIds = quizModule.state.quizzes.map(q => q.objectId)
        await fetchLoadable(
            quizModule.state.answeredQuestions, 
            () => runCloudFunction<Study.Cloud.fetchQuestionsByQuizIdsV3>(
                'fetchQuestionsByQuizIds-v3', 
                { quizIds, compositeKey: currentExam?.compositeKey }
            )
        )
    }
}

export type TCreateActiveQuizParams = {
    prebuiltQuizId?: string
    mode: TQuizMode
    questionCount?: number
    timeLimit?: number  // minutes
    showAnswersAtEnd?: boolean
    showCheckAnswerButton?: boolean
    level?: number
    numCorrectToLevelUp?: number
    subjects?: string[]
    include?: {
        new?: boolean
        answered?: boolean
        flagged?: boolean
        incorrect?: boolean
    }
}

const createActiveQuiz = async (params: TCreateActiveQuizParams) => {
    await Promise.all([
        examMetadataModule.actions.fetchExamMetadata(),
        userExamMetadataModule.actions.fetchUserExamMetadata(),
    ])
    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()
    const currentUserExamMetadata = userExamMetadataModule.getters.getCurrentUserExamMetadata()

    if (!currentExam) {
        throw new Error('No current exam found')
    }
    const examGuid = currentExam.examGuid
    const currentExamVersion = currentExam.version.split('.')[0]
    if (!currentExamVersion) {
        throw new Error('quiz/action - CreateActiveExam: no current exam version')        
    }

    let quizQuestions: Study.Class.QuestionJSON[]
    let prebuiltQuiz: Study.Class.PrebuiltQuizJSON | undefined = undefined

    if (params.prebuiltQuizId) {
        const prebuiltQuizData = await runCloudFunction<Study.Cloud.fetchPrebuiltQuizQuestions>(
            'fetchPrebuiltQuizQuestions', 
            { prebuiltQuizId: params.prebuiltQuizId }
        )
        
        if ('questions' in prebuiltQuizData) {
            // if prebuilt quiz is in exam you aren't actively studying, update currently studying
            const currentExamGuid = userModule.state.user?.currentExamGuid
            const prebuiltQuizExam = prebuiltQuizData.prebuiltQuiz.examMetadata as Study.Class.ExamMetadataJSON
            if (currentExamGuid !== prebuiltQuizExam.examGuid) {
                await userModule.actions.updateCurrentExamGuid(prebuiltQuizExam.examGuid)
            }

            quizQuestions = prebuiltQuizData.questions
            prebuiltQuiz = prebuiltQuizData.prebuiltQuiz
    
            // check if active quiz is already the prebuilt quiz and, if so, just return
            const activeQuiz = quizModule.getters.getActiveQuiz()
            const activeQuestions = activeQuiz?.questions
            const notSameQuiz = activeQuestions?.length !== quizQuestions.length
                    || !activeQuestions.find(aq => !quizQuestions?.find(qq => qq.serial === aq.serial))
            if (activeQuestions && notSameQuiz) {
                return
            }
        } else {
            // if fetch quiz returns a quiz object, then just return it and dont start a quiz
            return prebuiltQuizData
        }
    } else if (params.mode === 'levelUp' && params.subjects && params.level) {
        const fetchQuizParams: Parameters<Study.Cloud.fetchLevelUpQuizQuestionsV2>[0] = {
            examGuid,
            majorVersion: currentExamVersion,
            questionCount: params.questionCount,
            subjectName: params.subjects[0],
            level: params.level,
            includeMatrixQuestions: true,
        }

        // If Level Up progress has been reset, include the most recent reset date in fetch params
        if (currentUserExamMetadata?.levelUpResetDates?.length) {
            const lastResetDateISO = currentUserExamMetadata.levelUpResetDates
                .sort((a, b) => new Date(b.iso).getTime() - new Date(a.iso).getTime())[0].iso
            fetchQuizParams.lastResetDateISO = lastResetDateISO
        }
    
        quizQuestions = await runCloudFunction<Study.Cloud.fetchLevelUpQuizQuestionsV3>(
            'fetchLevelUpQuizQuestions-v3',
            fetchQuizParams
        )
    } else {
        const fetchQuizParams: Parameters<Study.Cloud.fetchQuizQuestionsV4>[0] = {
            examGuid,
            majorVersion: currentExamVersion,
            questionCount: params.questionCount || 10,
            includeMatrixQuestions: true,
        }
        if (params.subjects?.length) {
            fetchQuizParams.subjects = params.subjects
        } else if (currentUserExamMetadata?.disabledSubjects?.length) {
            // If the user has any disabled subjects, pass the enabled subjects to fetchQuizQuestions
            const allSubjects = Object.values(currentExam.knowledgeAreas).map(ka => ka.name)
            const disabledSubjectsSet = new Set(currentUserExamMetadata.disabledSubjects)
            const enabledSubjects = allSubjects.filter(subject => !disabledSubjectsSet.has(subject))
            fetchQuizParams.subjects = enabledSubjects
        }
        const include = params.include
        if (include && Object.keys(include).length) {
            fetchQuizParams.include = include
        }
    
        quizQuestions = await runCloudFunction<Study.Cloud.fetchQuizQuestionsV5>(
            'fetchQuizQuestions-v5',
            fetchQuizParams
        )
    }
    // generate random answer seed
    const answerSeeds = new Array(quizQuestions.length).fill(undefined).map(() => {
        return [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 ].sort(() => Math.random() - 0.5)
    })

    // Store the active quiz in our store state and in local storage
    const activeQuiz: TActiveQuiz = {
        questions: quizQuestions,
        answers: [],
        examGuid,
        mode: params.mode,
        startedAt: localISODateString(),  // includes timezone info
        currentQuestionIndex: 0,
        durationSeconds: 0,
        breakDurationSeconds: 0,
        timerType: 'exam',
        answerSeeds,
    }
    if (prebuiltQuiz) {
        activeQuiz.prebuiltQuiz = prebuiltQuiz
    }
    if (params.timeLimit) {
        activeQuiz.timeLimit = params.timeLimit // minutes
    }
    if ('showAnswersAtEnd' in params && params.mode !== 'levelUp') {
        activeQuiz.showAnswersAtEnd = params.showAnswersAtEnd
    }
    if ('showCheckAnswerButton' in params && params.mode !== 'levelUp') {
        activeQuiz.showCheckAnswerButton = params.showCheckAnswerButton
    }
    if (params.numCorrectToLevelUp) {
        activeQuiz.numCorrectToLevelUp = params.numCorrectToLevelUp
    }

    // Clear out any entries for user explanations or previous AI feedback
    localStorage.removeItem('levelUpQuestionExperiment_explanationAnswer')
    localStorage.removeItem('levelUpQuestionExperiment_aiFeedback')

    updateActiveQuiz(activeQuiz)
}

const updateActiveQuiz = (activeQuiz: TActiveQuiz | null) => {
    if (activeQuiz) {
        // Reset activeMockExamQuiz when setting activeQuiz
        mockExamModule.actions.updateActiveMockExamQuiz(null)
    }
    quizModule.state.activeQuiz = activeQuiz
    localStorage.setItem('activeQuiz', JSON.stringify(activeQuiz))
}

const recordQuiz = async (params: Parameters<Study.Cloud.recordQuiz>[0]) => {
    // Remove checked property from answers (only needed for active quiz state)
    params.answers.forEach(ans => {
        const ansWithChecked = ans as typeof ans & { checked?: boolean }
        delete ansWithChecked.checked
    })
    const recordQuizResponse = await runCloudFunction<Study.Cloud.recordQuiz>('recordQuiz', params)

    // add answered questions to store
    const quizQuestions = quizModule.state.activeQuiz?.questions || []
    const answeredSerials = params.answers.map(a => a.questionSerial)
    const answeredQuestions = quizQuestions.filter(q => answeredSerials.includes(q.serial))
    const questionsSerialObj =
    ([ ...await quizModule.state.answeredQuestions.value, ...answeredQuestions ])
        .reduce((acc, question) => {
            acc[question.serial] = question
            return acc
        }, {} as { [serial: string]: Study.Class.QuestionJSON })

    quizModule.state.answeredQuestions.value = Object.values(questionsSerialObj)

    // update quiz and global metrics in store
    quizModule.state.quizzes.push(recordQuizResponse.quiz)
    quizModule.actions.updateActiveQuiz(null)

    resetLoadable(userExamMetadataModule.state.userExamMetadataByGuid)
    await Promise.all([
        globalQuestionMetricModule.actions.fetchGlobalQuestionMetrics(true),
        userExamMetadataModule.actions.fetchUserExamMetadata(),
    ])

    const userId = userModule.state.user?.objectId
    const examGuid = examMetadataModule.getters.getCurrentExamMetadata()?.examGuid
    if (userId && examGuid) {
        resetLoadable(readinessModule.state.readinessScores)
        await readinessModule.actions.fetchReadinessScore(userId, examGuid)
    }

    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()
    if (currentExam) {
        await analyticsModule.actions.googleAnalyticsTrack('Quiz Submission', {
            'event_category': utils.studyModes[params.mode as -1 | 0 | 2 | 3 | 4 | 5 | 6 | 10].name,
            'event_label': currentExam.nativeAppName,
        })
    }

    if (params.mode === 6) {
        await analyticsModule.actions.trackEvent('Level Up Quiz Completed')
    }

    analyticsModule.actions.updateIntercom()

    return recordQuizResponse
}

const fetchPrebuiltQuizzes = async () => {
    await userModule.actions.fetchUserData()

    const prebuiltQuizIds = quizModule.getters.getQuizzes().reduce<string[]>((acc, q) => {
        if (q.prebuiltQuiz?.objectId) {
            acc.push(q.prebuiltQuiz.objectId)
        }
        return acc
    }, [])

    if (!prebuiltQuizIds.length) {
        return []
    }

    const prebuiltQuizzes = await new Parse.Query<Study.Class.PrebuiltQuiz>('PrebuiltQuiz')
        .containedIn('objectId', prebuiltQuizIds)
        .findAll()
        
    const prebuiltQuizJSON = prebuiltQuizzes.map(pq => pq.toJSON())
    quizModule.state.prebuiltQuizzes = prebuiltQuizJSON

    return prebuiltQuizJSON
}

export type generateExplainYourAnswerFeedback = (params: {
    userExplanation: string
    userSelectedAnswer: string
    serial: string
    examMetadataId: string
}) => {
    success: boolean
    feedback: string
}
const generateExplainYourAnswerFeedback = async (params: Parameters<generateExplainYourAnswerFeedback>[0]) => {
    return runCloudFunction<generateExplainYourAnswerFeedback>('generateExplainYourAnswerFeedback', params)
}

type TIsLevelUpEnabled = (params: { bundleId?: string; examGuid?: string }) => boolean
const isLevelUpEnabled = (params: { examGuid?: string; bundleId?: string }) =>
    runCloudFunction<TIsLevelUpEnabled>('isLevelUpEnabled', params)

export default {
    createActiveQuiz,
    updateActiveQuiz,
    fetchAnsweredQuestions,
    recordQuiz,
    isLevelUpEnabled,
    fetchPrebuiltQuizzes,
    generateExplainYourAnswerFeedback,
}
