<script setup lang="ts">
import { userModule } from '@/store/user/module'
import { localISODateString } from '@/utils'
import UIKit from '@pocketprep/ui-kit'
import { computed, onMounted, ref } from 'vue'

const {
    ModalContainer,
    Modal,
    Button,
    Radio,
    Textarea,
} = UIKit

type TSurveyFeedback = {
    isHelpful: string | undefined
    context: string
}

const emit = defineEmits<{
    (event: 'close'): void
    (event: 'previous'): void
    (event: 'submit', data: TSurveyFeedback): void
}>()

const surveyRadioOptions = [
    {
        label: 'Yes',
        value: 'yes',
    },
    {
        label: 'No',
        value: 'no',
    },
    {
        label: 'Unsure',
        value: 'unsure',
    },
]

const readinessFeedback = ref('')

const surveyChoice = ref<{label: string; value: string} | null>(null)

const isDarkMode = computed(() => {
    return userModule.state.settings.isDarkMode
})

const sawReadinessSurveyTimestamps = computed(() => {
    return userModule.state.user?.webConfig?.sawReadinessSurveyTimestamps || []
})

const surveyFeedback = computed(() => ({
    isHelpful: surveyChoice.value?.value,
    context: readinessFeedback.value,
}))

onMounted(() => {
    const updatedSawReadinessSurveyTimestamps = sawReadinessSurveyTimestamps.value
    updatedSawReadinessSurveyTimestamps.push(localISODateString())
    userModule.actions.updateWebConfig({
        sawReadinessSurveyTimestamps: updatedSawReadinessSurveyTimestamps,
    })
})

const previousClicked = async () => {
    await userModule.actions.updateWebConfig({
        sawReadinessSurveyTimestamps: sawReadinessSurveyTimestamps.value.slice(0, -1),
    })
    emit('previous')
}

</script>

<template>
    <Portal to="modal">
        <ModalContainer
            :is-dark-mode="isDarkMode"
            @close="emit('close')"
        >
            <template #modal>
                <Modal
                    class="readiness-survey__modal"
                    :show-close-button="true"
                    :is-dark-mode="isDarkMode"
                    @close="emit('close')"
                >
                    <div class="readiness-survey" v-dark="isDarkMode">
                        <h1
                            ref="readiness-survey__title"
                            class="readiness-survey__title"
                            tabindex="-1"
                        >
                            Exam Readiness
                        </h1>
                        <div class="readiness-survey__subtitle" v-dark>
                            We’re testing this feature and would love your feedback!
                        </div>
                        <div class="readiness-survey__prompt" v-dark>
                            Did the exam readiness calculation help you understand how prepared you are for your exam?
                        </div>
                        <div class="readiness-survey__radio-container">
                            <Radio
                                v-model="surveyChoice"
                                :data="surveyRadioOptions"
                                :is-dark-mode="isDarkMode"
                            />
                        </div>
                        <Textarea
                            v-model="readinessFeedback"
                            label="Why or why not?"
                            height="64px"
                            width="100%"
                            :is-dark-mode="isDarkMode"
                        />
                        <div class=readiness-survey__nav-btns-container>
                            <Button
                                type="secondary"
                                :is-dark-mode="isDarkMode"
                                @click="previousClicked"
                            >
                                Previous
                            </Button>
                            <Button
                                :is-dark-mode="isDarkMode"
                                :disabled="!surveyChoice"
                                @click="emit('submit', surveyFeedback)"
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<style lang="scss" scoped>
.readiness-survey {
    box-sizing: border-box;
    padding: 36px 40px 24px;
    width: 492px;
    margin: 0 auto;

    @include breakpoint(black-bear) {
            top: 132px;
            max-width: 340px;
        }

    &__modal {
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        margin: 0 0 12px 0;
        outline: none;
        letter-spacing: 0.3px;
        text-align: center;
    }

    &__subtitle {
        font-size: 15px;
        color: $ash;
        line-height: 20px;
        margin-bottom: 16px;
        text-align: left;

        &--dark {
            color: $fog;
        }
    }

    &__prompt {
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 8px;
    }

    &__radio-container {
        margin-bottom: 24px;
    }

    :deep(.uikit-textarea__textarea) {
        resize: none;
        margin-bottom: 72px;
    }

    :deep(.uikit-textarea__label) {
        font-size: 15px;
    }

    &__nav-btns-container {
        display: flex;
        gap: 8px;
        justify-content: center;
    }
}
</style>
