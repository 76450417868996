<template>
    <div class="uti-header">
        <div class="register-head__left">
            <img
                v-if="currentStep === 1"
                class="uti-header__logo uti-header__logo--desktop"
                src="@/assets/logos/logo.svg"
                alt="Pocket Prep Logo"
            >
            <img
                v-if="currentStep === 1"
                class="uti-header__logo uti-header__logo--mobile"
                src="@/assets/logos/logo-square.svg"
                alt="Pocket Prep Logo"
            >
            <div
                v-else
                class="uti-header__back"
                tabindex="0"
                role="button"
                aria-label="Back"
                @keydown.enter="emitBack"
                @click="emitBack"
                @mousedown.prevent
            >
                <Icon type="arrow" />
            </div>
        </div>
        <div class="uti-header__steps">
            <div 
                class="uti-header__step" 
                :class="{
                    'uti-header__step--active': currentStep === 1,
                    'uti-header__step--complete': currentStep > 1,

                }"
                tabindex="-1"
                :aria-label="`${currentStep === 1
                    ? 'Current '
                    : currentStep > 1
                        ? 'Completed '
                        : ''
                }Step 1 Account Details`"
            >
                <span v-if="currentStep <= 1" aria-hidden="true">1</span>
                <span v-else-if="currentStep > 1" aria-hidden="true"><Icon type="check" /></span>
                <div aria-hidden="true">
                    <div class="uti-header__step--desktop">
                        Account
                    </div> Details
                </div>
            </div>
            <div 
                class="uti-header__step" 
                :class="{
                    'uti-header__step--active': currentStep === 2,
                    'uti-header__step--complete': currentStep > 2,
                }"
                tabindex="-1"
                :aria-label="`${currentStep === 2
                    ? 'Current '
                    : currentStep > 2
                        ? 'Completed '
                        : ''
                }Step 2 Payment Details`"
            >
                <span v-if="currentStep <= 2" aria-hidden="true">2</span>
                <span v-else aria-hidden="true"><Icon type="check" /></span>
                <div aria-hidden="true">
                    Payment <div class="uti-header__step--desktop">
                        Details
                    </div>
                </div>
            </div>
        </div>
        <div class="uti-header__links">
            <template v-if="currentStep === 1 && !currentUser">
                <RouterLink
                    v-slot="{ href, navigate }"
                    custom
                    :to="{ name: 'sign-in', query: { redirect: '/uti-discount' } }"
                >
                    <PocketLink
                        class="uti-header__link"
                        type="tertiary-small"
                        :href="href"
                        @click="navigate"
                        @mousedown.prevent
                    >
                        I have an account
                    </PocketLink>
                </RouterLink>
                <span class="uti-header__middot">•</span>
            </template>
            <PocketButton
                class="uti-header__link uti-header__link-help"
                type="tertiary-small"
                @click="showIntercom"
                @keypress.enter="showIntercom"
                @mousedown.prevent
            >
                Help
            </PocketButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import { isSupportWindow } from '@/utils'

@Component({
    components: {
        Icon: UIKit.Icon,
        PocketLink: UIKit.Link,
        PocketButton: UIKit.Button,
    },
})
export default class UtiHeader extends Vue {
    @Prop() currentStep!: number

    get currentUser () {
        return userModule.state.user
    }

    showIntercom () {
        if (import.meta.env.VUE_APP_INTERCOM_APP_ID && !isSupportWindow()) {
            window.Intercom('show')
        }
    }

    @Emit('back')
    emitBack () {
        return true
    }
}
</script>
<style lang="scss" scoped>
.uti-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__logo {
        height: 27px;

        &--desktop {
            @include breakpoint(black-bear) {
                display: none;
            }
        }

        &--mobile {
            display: none;

            @include breakpoint(black-bear) {
                display: block;
            }
        }
    }

    &__steps {
        display: flex;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    &__step {
        font-size: 13px;
        line-height: 21px;
        color: $slate-01;
        margin-right: 32px;
        position: relative;

        @include breakpoint(black-bear) {
            margin-right: 24px;
        }

        &::after {
            content: '';
            right: -19px;
            top: 6px;
            width: 5px;
            height: 8px;
            position: absolute;
            background: url(@/assets/onboarding/stepper.svg) no-repeat center center;

            @include breakpoint(black-bear) {
                right: -15px;
            }
        }

        &:last-child {
            margin-right: 0;

            &::after {
                display: none;
            }
        }

        &--desktop {
            display: inline;

            @include breakpoint(black-bear) {
                display: none !important;
            }
        }

        span {
            font-weight: 700;
            width: 16px;
            margin: 0 0 0 2px;
            display: inline-block;
        }

        div {
            display: inline-block;
        }

        &--active {
            color: $brand-black;
            font-weight: 600;

            span {
                color: $brand-blue;
            }
        }

        &--complete {
            span {
                margin: 0 2px 0 0;

                svg {
                    width: 16px;
                    height: 13px;
                }
            }

            div {
                text-decoration: line-through;
            }
        }
    }

    &__left {
        display: flex;
        width: 190px;

        @include breakpoint(brown-bear) {
            width: 136px;
        }

        @include breakpoint(black-bear) {
            width: 60px;
        }
    }

    &__back {
        color: $brand-blue;
        cursor: pointer;
        outline: none;
        position: relative;

        &:hover,
        &:focus {
            color: $brand-black;
        }

        &:focus::before {
            content: "";
            border: 1px solid $brand-black;
            width: 27px;
            height: 26px;
            top: -6px;
            left: -4px;
            border-radius: 4px;
            box-sizing: border-box;
            position: absolute;
        }

        svg {
            transform: rotate(180deg);
            width: 19px;
            height: 14px;
        }
    }

    &__links {
        font-size: 14px;
        line-height: 19px;
        width: 190px;
        text-align: right;

        @include breakpoint(brown-bear) {
            width: 136px;
        }

        @include breakpoint(black-bear) {
            width: 60px;
        }

        span {
            @include breakpoint(brown-bear) {
                display: none;
            }
        }
    }

    &__middot {
        color: $pewter;
    }

    &__link {
        margin: 0 8px;
        display: inline-block;

        @include breakpoint(brown-bear) {
            display: none;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &-help {
            display: inline-block;
        }
    }
}
</style>