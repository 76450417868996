<template>
    <Portal to="sidepanel">
        <SidePanel
            key="activeLicenseSidePanel"
            name="Activate License Code"
            class="activate-license"
            width="medium"
            :is-dark-mode="isDarkMode"
            @close="emitClose"
        >
            <PocketInput
                v-model="firstName"
                :is-dark-mode="isDarkMode"
                label="First Name"
                :error="errorFields.includes('firstName')"
                class="activate-license__input"
                @keydown.enter="activateLicense"
            />
            <PocketInput
                v-model="lastName"
                :is-dark-mode="isDarkMode"
                label="Last Name"
                :error="errorFields.includes('lastName')"
                class="activate-license__input"
                @keydown.enter="activateLicense"
            />
            <LicenseField v-model="licenseKey" />
            <div class="activate-license__license-info">
                License codes are purchased by your school or workplace. If you haven't received a license code,
                contact your organization.
            </div>

            <template #action>
                <PocketButton
                    :is-dark-mode="isDarkMode"
                    :disabled="!licenseKey || !firstName || !lastName"
                    :is-loading="isLoading"
                    @click="activateLicense"
                >
                    Activate
                </PocketButton>
            </template>
        </SidePanel>
    </Portal>
</template>

<script lang="ts">
import { licenseModule } from '@/store/license/module'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Emit } from 'vue-facing-decorator'
import LicenseField from '@/components/Settings/ActivateLicense/LicenseField.vue'
import { toastModule } from '@/store/toast/module'
import { userModule } from '@/store/user/module'

@Component({
    components: {
        SidePanel: UIKit.SidePanel,
        PocketInput: UIKit.Input,
        PocketButton: UIKit.Button,
        PocketLink: UIKit.Link,
        Errors: UIKit.Errors,
        Icon: UIKit.Icon,
        LicenseField,
    },
})
export default class ActivateLicenseSidePanel extends Vue {
    licenseKey = ''
    errors: string[] = []
    errorFields: string[] = []
    firstName = ''
    lastName = ''
    isLoading = false

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    async mounted () {
        await userModule.actions.fetchUserData()

        this.firstName = (typeof this.$route.query.firstName === 'string' && this.$route.query.firstName)
            ? this.$route.query.firstName
            : userModule.state.user?.firstName || ''
        this.lastName = (typeof this.$route.query.lastName === 'string' && this.$route.query.lastName)
            ? this.$route.query.lastName
            : userModule.state.user?.lastName || ''
    }

    async activateLicense () {
        this.errorFields = []
        this.isLoading = true
        
        if (!this.firstName) {
            this.errorFields.push('firstName')
        }
        if (!this.lastName) {
            this.errorFields.push('lastName')
        }

        if (!this.errorFields.length) {
            try {
                // Validate license to get the exam
                const license = await licenseModule.actions.validateLicense(this.licenseKey)
                await Promise.all([
                    userModule.actions.updateUser({
                        firstName: this.firstName,
                        lastName: this.lastName,
                    }),
                    licenseModule.actions.activateLicense(this.licenseKey),
                ])
                toastModule.actions.displayToast({ 
                    title: 'License code activated.',
                    subtext: `You are now studying the ${license.examName} exam. Switch
                    exams via the upper left menu dropdown`,
                })
            } catch (err) {
                // noop
            }
            this.emitClose()
        }
        this.isLoading = false
    }

    @Emit('close')
    emitClose () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.activate-license {
    &__input {
        margin: 0 -12px 23px;
    }

    &__license-info {
        font-size: 14px;
        line-height: 18px;
        margin-top: -14px;
        color: $ash;
        max-width: 372px;
    }
}
</style>