<template>
    <Portal to="modal">
        <ModalContainer
            key="pencilsDownModal"
            :is-dark-mode="isDarkMode"
        >
            <template #modal>
                <Modal
                    class="pencils-down__modal"
                    :is-dark-mode="isDarkMode"
                >
                    <div class="pencils-down">
                        <div
                            ref="pencils-down__title"
                            class="pencils-down__title"
                            tabindex="-1"
                        >
                            Pencils down!
                        </div>
                        <div v-dark class="pencils-down__text">
                            It's time to submit and see your results.
                        </div>
                        <PocketButton
                            :is-dark-mode="isDarkMode"
                            @click="emitSubmitExam"
                        >
                            Submit Exam
                        </PocketButton>
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { Component, Emit, Vue } from 'vue-facing-decorator'

@Component({
    components: {
        PocketButton: UIKit.Button,
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
    },
})
export default class PencilsDownModal extends Vue {
    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    mounted () {
        setTimeout(() => {
            const titleEl = this.$refs['pencils-down__title'] as HTMLElement | undefined
            titleEl?.focus()
        }, 500)
    }

    @Emit('submitExam')
    emitSubmitExam () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.pencils-down {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 26px 26px 27px;
    width: 384px;
    margin: 0 auto;

    .uikit-btn--primary {
        padding: 9px 18px 8px 17px;
    }

    @include breakpoint(black-bear) {
        max-width: 340px;
        max-height: 183px;
        margin: 0 auto;
        width: 100;
    }

    &__title {
        outline: none;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 12px;
    }

    &__text {
        color: $ash;
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 27px;
        text-align: center;

        &--dark {
            color: $fog;
        }
    }

    &__modal {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 94px;
    }
}
</style>
