<template>
    <Portal to="modal">
        <ModalContainer
            key="newExamVersionModal"
            :is-dark-mode="isDarkMode"
            :show-close-button="true"
            @close="emitClose"
        >
            <template #modal>
                <Modal
                    :show-close-button="true"
                    class="new-exam-version-modal__modal"
                    :is-dark-mode="isDarkMode"
                    @close="emitClose"
                >
                    <div class="new-exam-version-modal">
                        <div class="new-exam-version-modal__title">
                            New Exam Version Supported
                        </div>
                        <div 
                            v-if="latestExamVersion" 
                            v-dark 
                            class="new-exam-version-modal__text"
                        >
                            {{ latestExamVersion.releaseInfo.message }}
                        </div>
                        <div class="new-exam-version-modal__actions">
                            <PocketButton
                                class="new-exam-version-modal__review-btn"
                                :is-dark-mode="isDarkMode"
                                @click="emitReviewExamVersions"
                            >
                                Review Exam Versions
                            </PocketButton>
                        </div>
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'
import type { Study } from '@pocketprep/types'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'


@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        PocketButton: UIKit.Button,
        Modal: UIKit.Modal,
    },
})
export default class NewExamVersionModal extends Vue {
    @Prop() latestExamVersion!: Study.Class.ExamMetadataJSON
 
    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('reviewExamVersions')
    emitReviewExamVersions () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.new-exam-version-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 35px 16px 29px 16px;
    border-radius: 12px;
    box-shadow: 0 -2px 8px 0 rgba(31, 53, 69, 0.15);
    width: 392px;

    @include breakpoint(black-bear) {
        max-width: 340px;
        margin: 0 auto;
        width: 100;
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 12px;
        width: 309px;
        text-align: center;
    }

    &__text {
        max-width: 320px;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: $ash;
        margin-bottom: 29px;
        white-space: pre-wrap;

        &--dark {
            color: $fog;
        }
    }

    &__modal {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 94px;
    }

    &__actions {
        display: flex;
        justify-content: center;
        align-items: center;

        .uikit-btn--primary {
            padding: 9px 15px 8px 15px;
        }

        .uikit-btn--secondary {
            margin: 0 4px;
            padding: 9px 20px 8px 21px;
        }
    }

    &__review-btn {
        margin-right: 12px;
    }
}
</style>
