<template>
    <div class="blob">
        <!-- eslint-disable -->
        <svg width="244px" height="46px" viewBox="0 0 244 46" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(1.712705, -0.003821)">
                    <path d="M223.674842,18.1484062 C188.650987,50.3393017 124.55665,60.1954116 69.4249734,6.94458167 C66.9381398,4.54258772 64.463151,2.23157977 62.0068204,6.26165786e-14 L241.377952,0.00269172283 C236.485513,5.89174899 230.543799,11.8350563 223.674842,18.1484062 Z" :fill="blobColor"></path>
                    <path d="M157.217148,0.00949384309 C152.242332,3.75330238 145.978327,6.99828279 138.268346,9.63989017 C51.4364681,39.3903824 38.5603673,52.8397952 1.44328993e-13,0.00869768832 Z" :fill="`url(#halftone-${halftoneColor})`"></path>
                </g>
            </g>
        </svg>
        <!-- eslint-enable -->
    </div>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import { timeOfDay } from '@/utils'
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class Blob extends Vue {
    timeOfDay = timeOfDay()

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get halftoneColor () {
        if (this.isDarkMode) {
            if (this.timeOfDay === 'early' || this.timeOfDay === 'morning') {
                return 'yellow'
            }
            if (this.timeOfDay === 'afternoon') {
                return 'red'
            }
            if (this.timeOfDay === 'evening') {
                return 'purple'
            }
        }

        return 'blue'
    }

    get blobColor () {
        if (this.timeOfDay === 'early') {
            if (this.isDarkMode) {
                return '#78725B'
            }
            return '#FF907B'
        } else if (this.timeOfDay === 'morning') {
            if (this.isDarkMode) {
                return '#B77B5F'
            }
            return '#FFDC9D'
        } else if (this.timeOfDay === 'afternoon') {
            if (this.isDarkMode) {
                return '#B36459'
            }
            return '#FFBA7E'
        } else if (this.timeOfDay === 'evening') {
            if (this.isDarkMode) {
                return '#65308D'
            }
            return '#9DC2FF'
        } else {
            if (this.isDarkMode) {
                return '#174FA1'
            }
            return '#0A274B'
        }
    }
}
</script>