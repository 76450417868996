<template>
    <Portal to="modal">
        <ModalContainer
            key="submitExamModal"
            :show-close-button="true"
            :is-dark-mode="isDarkMode"
            @close="emitClose"
        >
            <template #modal>
                <Modal
                    :show-close-button="true"
                    class="submit-exam__modal"
                    :is-dark-mode="isDarkMode"
                    @close="emitClose"
                >
                    <div class="submit-exam">
                        <template v-if="numAnswers >= quizLength">
                            <h1
                                ref="submit-exam__title"
                                class="submit-exam__title"
                                tabindex="-1"
                            >
                                Submit Exam
                            </h1>
                            <div v-dark class="submit-exam__text">
                                Just to confirm, you're ready to submit?
                            </div>
                            <div class="submit-exam__actions">
                                <PocketButton
                                    type="secondary"
                                    class="submit-exam__continue-btn"
                                    :is-dark-mode="isDarkMode"
                                    @click="emitContinue"
                                >
                                    Never Mind
                                </PocketButton>
                                <PocketButton
                                    class="submit-exam__submit-btn"
                                    :is-dark-mode="isDarkMode"
                                    @click="emitSubmit"
                                >
                                    Let's do it
                                </PocketButton>
                            </div>
                        </template>
                        <template v-else>
                            <h1
                                ref="submit-exam__title"
                                class="submit-exam__title"
                                tabindex="-1"
                            >
                                Submit Exam?
                            </h1>
                            <div v-dark class="submit-exam__text">
                                You've answered {{ numAnswers }} of {{ quizLength }} questions.
                                If you submit now, incomplete answers will be marked as incorrect.
                            </div>
                            <div class="submit-exam__actions">
                                <PocketButton
                                    type="secondary"
                                    class="submit-exam__continue-btn"
                                    :is-dark-mode="isDarkMode"
                                    @click="emitContinue"
                                >
                                    Never Mind
                                </PocketButton>
                                <PocketButton
                                    class="submit-exam__submit-btn"
                                    :is-dark-mode="isDarkMode"
                                    @click="emitSubmit"
                                >
                                    Submit Exam
                                </PocketButton>
                            </div>
                        </template>
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'

@Component({
    components: {
        PocketButton: UIKit.Button,
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
    },
})
export default class SubmitExamModal extends Vue {
    @Prop({ default: 0 }) numAnswers!: number
    @Prop({ default: 1 }) quizLength!: number

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    mounted () {
        this.$nextTick(() => {
            const titleEl = this.$refs['submit-exam__title'] as HTMLElement | undefined
            titleEl?.focus()
        })
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('continue')
    emitContinue () {
        return true
    }

    @Emit('submit')
    emitSubmit () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.submit-exam {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 26px 26px 27px;
    width: 384px;
    margin: 0 auto;

    @include breakpoint(black-bear) {
        max-width: 340px;
        max-height: 226px;
        margin: 0 auto;
        width: 100;
    }

    &__title {
        outline: none;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin: 0 0 12px 0;
    }

    &__text {
        color: $slate-03;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 29px;

        &--dark {
            color: $white;
        }
    }

    &__continue-btn {
        margin-right: 12px;
    }

    &__modal {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 94px;
    }

    &__actions {
        display: flex;
        justify-content: center;
        align-items: center;

        .uikit-btn--primary {
            padding: 9px 15px 8px 15px;
        }

        .uikit-btn--secondary {
            margin: 0 4px;
            padding: 9px 20px 8px 21px;
        }
    }
}

</style>