import { reactive } from 'vue'
import state from '@/store/instructor/state'
import actions from '@/store/instructor/actions'
import getters from '@/store/instructor/getters'

export const instructorModule = {
    state: reactive(state),
    actions,
    getters,
}
