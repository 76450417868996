<template>
    <div v-dark class="upgrade-compare">
        <div v-dark class="upgrade-compare__purchase-paths">
            <img
                v-if="!isDarkMode"
                src="@/assets/settings/purchase-paths.png"
                alt=""
                class="upgrade-compare__purchase-paths-img"
            />
            <img
                v-if="isDarkMode"
                src="@/assets/settings/purchase-paths-dark.png"
                alt=""
                class="upgrade-compare__purchase-paths-img"
            >
        </div>
        <table
            v-dark
            class="upgrade-compare__table"
            aria-label="Feature comparison table for Free versus Premium plans"
        >
            <tr v-dark class="upgrade-compare__row upgrade-compare__row-head">
                <th v-dark class="upgrade-compare__field upgrade-compare__field-head">
                    App Features
                </th>
                <th v-dark class="upgrade-compare__field upgrade-compare__field-head">
                    Free
                </th>
                <th v-dark class="upgrade-compare__field upgrade-compare__field-head">
                    Premium
                </th>
            </tr>
            <tr v-dark class="upgrade-compare__row">
                <td class="upgrade-compare__field">
                    Questions &amp; Explanations
                </td>
                <td v-dark class="upgrade-compare__field upgrade-compare__field-number">
                    {{ exam.specialQuestions }}
                </td>
                <td v-dark class="upgrade-compare__field upgrade-compare__field-number">
                    {{ exam.itemCount - exam.archivedCount }}
                </td>
            </tr>
            <tr v-dark class="upgrade-compare__row">
                <td class="upgrade-compare__field">
                    Subject Scores
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
            </tr>
            <tr v-dark class="upgrade-compare__row">
                <td class="upgrade-compare__field">
                    Mobile & Web Access
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
            </tr>
            <tr 
                v-if="bundle && bundle.name && uniqueExams.length" 
                v-dark 
                class="upgrade-compare__row"
            >
                <td class="upgrade-compare__field">
                    Prep for {{ uniqueExams.length }} {{ bundle.name }} Exams
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
            </tr>
            <tr v-dark class="upgrade-compare__row">
                <td class="upgrade-compare__field">
                    Pass Guarantee*
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <div
                        role="img"
                        aria-label="No"
                    >
                        &nbsp;
                    </div>
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
            </tr>

            <tr v-dark class="upgrade-compare__row upgrade-compare__row-head-two">
                <th v-dark class="upgrade-compare__field upgrade-compare__field-head">
                    Quiz Mode
                </th>
                <th v-dark class="upgrade-compare__field upgrade-compare__field-head">
                    Free
                </th>
                <th v-dark class="upgrade-compare__field upgrade-compare__field-head">
                    Premium
                </th>
            </tr>
            <tr v-dark class="upgrade-compare__row">
                <td class="upgrade-compare__field">
                    Question of the Day
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
            </tr>
            <tr v-dark class="upgrade-compare__row">
                <td class="upgrade-compare__field">
                    Quick 10 Quiz
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
            </tr>
            <tr v-dark class="upgrade-compare__row">
                <td class="upgrade-compare__field">
                    Timed Quiz
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
            </tr>
            <tr v-dark class="upgrade-compare__row">
                <td class="upgrade-compare__field">
                    Missed Questions Quiz
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <div
                        role="img"
                        aria-label="No"
                    >
                        &nbsp;
                    </div>
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
            </tr>
            <tr v-dark class="upgrade-compare__row">
                <td class="upgrade-compare__field">
                    Weakest Subject Quiz
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <div
                        role="img"
                        aria-label="No"
                    >
                        &nbsp;
                    </div>
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
            </tr>
            <tr v-dark class="upgrade-compare__row">
                <td class="upgrade-compare__field">
                    Build Your Own Quiz
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <div
                        role="img"
                        aria-label="No"
                    >
                        &nbsp;
                    </div>
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
            </tr>
            <tr     
                v-if="exam && exam.mockExams && exam.mockExams.length" 
                v-dark 
                class="upgrade-compare__row"
            >
                <td class="upgrade-compare__field">
                    <div>
                        {{ exam.mockExams.length }} Mock Exam{{ exam.mockExams.length > 1 ? 's' : '' }}
                    </div>
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <div
                        role="img"
                        aria-label="No"
                    >
                        &nbsp;
                    </div>
                </td>
                <td class="upgrade-compare__field" tabindex="-1">
                    <Icon aria-label="Yes" type="check" />
                </td>
            </tr>
            <div v-dark class="upgrade-compare__pass-guarantee">
                *Pass Guarantee: If you fail your exam, 
                we’ll give you three months free if you’ve been subscribed for 3 months, 
                attempted all practice questions, and maintained an 80% average.
            </div>
        </table>
    </div>

</template>

<script lang="ts">
import type { Study } from '@pocketprep/types'
import { Vue, Component, Prop } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { screenModule } from '@/store/screen/module'
import { examMetadataModule } from '@/store/examMetadata/module'

@Component({
    components: {
        Icon: UIKit.Icon,
    },
})
export default class UpgradeCompare extends Vue {
    @Prop() exam!: Study.Class.ExamMetadataJSON
    @Prop({ default: false }) isDarkMode!: boolean
    @Prop() bundle!: Study.Class.BundleJSON

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get examMetadataById () {
        return examMetadataModule.getters.getExamMetadataById()
    }

    get uniqueExams () {
        return this.bundle.exams.reduce<string[]>((acc, exam) => {
            const examNativeAppName = this.examMetadataById[exam.objectId].nativeAppName
            if (!acc.includes(examNativeAppName)) {
                acc.push(examNativeAppName)
            }
            return acc
        }, [])
    }

    async mounted () {
        await examMetadataModule.actions.fetchExamMetadata()
    }

}
</script>
<style lang="scss" scoped>
.upgrade-compare {
    padding: 0 19px 19px 10px;
    box-sizing: border-box;

    @include breakpoint (brown-bear) {
        margin-left: 0;
    }

    @include breakpoint (black-bear) {
        width: 360px;
        margin: 0 auto;
        padding: 14px 9px 16px;
    }

    &__purchase-paths-img {
        width: 455px;
        height: 340px;
        flex-shrink: 0;
        margin-left: -25px;
        margin-top: -20px;

        @include breakpoint (black-bear) {
            width: 370px;
            height: 300px;
            margin-left: -5px;
            margin-top: -20px;
            margin-bottom: 2px;
        }
    }

    &__table {
        position: relative;
        background: $white;
        border-radius: 6px;
        border: 1px solid $gray-divider;
        display: block;
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 100%;
        margin-top: -40px;
        padding: 23px 10px 7px 10px;

        &--dark {
            background: $moonlit-ocean;
            border-color: $moonlit-ocean;
        }

        @include breakpoint(black-bear) {
            margin-left: 10px;
            margin-right: -8px;
        }
    }

    &__row {
        display: flex;
        border-bottom: 0.5px solid $pewter;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        padding: 10px 10px 10px 11px;
        color: $brand-black;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;

        &--dark {
            border-bottom-color: $slate;
            color: rgba($white, 0.8);
        }

        @include breakpoint(black-bear) {
            padding: 10px 15px 10px 6px;
        }
    }

    &__row-head {
        padding: 0 5px 5px 11px;
        border-bottom: 2px solid $steel;

        &--dark {
            color: $pewter;
        }

        @include breakpoint(black-bear) {
            padding-left: 6px;
        }
    }

    &__row-head-two {
        padding: 0 5px 5px 11px;
        margin-top: 42px;
        border-bottom: 2px solid $steel;

        &--dark {
            color: $pewter;
        }

        @include breakpoint(black-bear) {
            margin-top: 32px;
            padding-left: 6px;
        }
    }

    &__field {
        text-align: center;
        width: 62px;
        padding: 0;

        &--desktop {
            display: inline;

            @include breakpoint(black-bear) {
                display: none;
            }
        }

        svg {
            width: 12px;
            height: 10px;
        }

        &:first-child {
            text-align: left;
            width: 287px;
        }

        &:last-child {
            margin-left: 10px;
        }
    }

    &__field-head {
        color: $brand-black;
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.26px;

        &--dark {
            color: $pewter;
        }
    }

    &__field-number {
        color: $brand-black;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;

        &--dark {
            color: rgba($fog, 0.8);
        }
    }

    &__pass-guarantee {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        color: $ash;
        padding: 14px 0 0 11px;
        margin-bottom: 15px;

        &--dark {
            color: rgba($white, 0.66);
        }

        @include breakpoint (black-bear) {
            font-size: 13px;
            line-height: 19px;
            width: 302px;
        }
    }
}
</style>