<template>
    <div class="plans" role="radiogroup">
        <div
            class="plans__plans-row"
        >
            <div
                v-for="plan in plans"
                :key="plan.id"
                class="plans__plan"
                :class="{
                    'plans__plan--selected': plan.id === selectedPlanId,
                    'plans__plan--another-plan': plan.id !== selectedPlanId
                }"
                tabindex="0"
                role="radio"
                :aria-checked="plan.id === selectedPlanId"
                :aria-label="`${plan.name} ${plan.regularPrice}`"
                @keydown.enter="selectPlan(plan.id)"
                @click="selectPlan(plan.id)"
                @mousedown.prevent
            >
                <div 
                    v-if="plan.tag && !validReferral" 
                    class="plans__tag"
                    :class="{'plans__tag--not-selected': plan.id !== selectedPlanId }"
                >
                    Save {{ plan.tag }}
                </div>
                <div class="plans__plan-top-info">
                    <div class="plans__plan-duration">
                        {{ plan.name === 'Monthly' ? '1 Month' :
                            plan.name === 'Quarterly' ? '3 Months' : '12 Months'
                        }}
                    </div>
                    <div class="plans__plan-price-label">
                        ${{plan.regularPrice}}
                    </div>
                </div>
                <span class="plans__plan-divider"/>
                <div v-if="validReferral" class="plans__plan-details">
                    <div 
                        class="plans__plan-discount-duration"
                    >
                        Your first
                        {{ plan.name === 'Monthly' ? 'month' :
                            plan.name === 'Quarterly' ? '3 months' : 'year'
                        }} will be
                    </div>
                    <div class="plans__plan-discount-price">
                        <div class="plans__discount-price">${{plan.discountPrice}}</div>
                        <DiscountHighlight />
                    </div>
                </div>
                <div v-if="!validReferral" class="plans__plan-details">
                    <div class="plans__plan-price">
                        ${{plan.byMonthPrice}}/month
                    </div>
                    <div class="plans__plan-billing-label">
                        {{ breakpoint === 'black-bear' ? 'billed' : 'Billed' }}
                        {{ plan.name === 'Monthly' ? 'monthly' :
                            plan.name === 'Quarterly' ? 'every 3 months' : 'annually'
                        }}
                    </div>
                </div>
            </div>
        </div>
        <div class="plans__actions">
            <PocketButton
                type="secondary"
                class="plans__try-free-btn"
                @click="tryFreeClicked"
            >
                Try {{ exam.specialQuestions }} Questions Free
            </PocketButton>
            <PocketButton
                class="plans__purchase-btn"
                @click="confirmPlan"
            >
                <span class="plans__purchase-premium">Purchase Premium</span>
                <Icon class="plans__purchase-btn-arrow" type="arrow" />
            </PocketButton>
        </div>
    </div>

</template>

<script lang="ts">
import type { Study } from '@pocketprep/types'
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { stripeModule } from '@/store/stripe/module'
import { isPromise } from '@/store/utils'
import { screenModule } from '@/store/screen/module'
import { referralModule } from '@/store/referral/module'
import DiscountHighlight from '@/assets/register-onboard/DiscountHighlight.vue'

@Component({
    components: {
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
        DiscountHighlight,
    },
})

export default class Plans extends Vue {
    @Prop() exam!: Study.Class.ExamMetadataJSON
    @Prop() bundle!: Study.Class.BundleJSON
    @Prop() savedSelectedPlanId!: string
    @Prop({ default: false }) validReferral!: boolean
    @Prop({ default: null }) referralError!: string | null

    selectedPlanId: null | string = null

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get plans () {
        const statePlans = stripeModule.state.plans.value
        if (isPromise(statePlans) || !statePlans || !statePlans[this.bundle.objectId]) {
            return []
        }

        const monthly = statePlans[this.bundle.objectId]?.find(p => p.interval_count === 1 && p.interval === 'month')
        return [ ...statePlans[this.bundle.objectId] ]
            .sort((a, b) => (a.amount || 0) - (b.amount || 0))
            .map(p => {
                const amount = p.amount || 0
                const monthsInPeriod = p.interval === 'year' ? p.interval_count * 12 : p.interval_count
                const regularPrice = amount / 100
                let bestValue
                let mostPopular
                let tag: string | null = 
                    `${100 - Math.round((amount / ((monthly?.amount || 0) * monthsInPeriod)) * 100)}%`
                let byMonthPrice
                let discountPrice

                if (this.validReferral && amount) {
                    discountPrice = (referralModule.getters.calculateDiscountedPrice(amount) / 100).toFixed(2)
                }

                if (p.interval === 'month' && p.interval_count === 1) {
                    tag = null
                    byMonthPrice = regularPrice
                }
                
                if (p.interval === 'month' && p.interval_count === 3) {

                    byMonthPrice = (Math.ceil((regularPrice / 3) * 100) / 100).toFixed(2)
                }

                if (p.interval === 'year' && p.interval_count === 1) {
                    bestValue = true

                    byMonthPrice = (Math.ceil((regularPrice / 12) * 100) / 100).toFixed(2)
                }

                return {
                    id: p.id,
                    price: stripeModule.getters.getPlanLabel({ p }),
                    orderPrice: amount,
                    name: p.nickname,
                    tag,
                    mostPopular,
                    interval: p.interval,
                    bestValue,
                    regularPrice,
                    byMonthPrice,
                    discountPrice,
                }
            })
    }

    async mounted () {
        await Promise.all([
            stripeModule.actions.fetchStripePlans(),
            referralModule.actions.validateReferral(),
        ])

        const quarterlyPlan = this.plans.find(p => p.name === 'Quarterly')
        this.selectedPlanId = quarterlyPlan?.id || this.savedSelectedPlanId
    }

    selectPlan (id: string) {
        this.selectedPlanId = id
    }
    
    confirmPlan () {
        this.emitConfirmPlan(this.selectedPlanId)
    }

    tryFreeClicked () {
        this.selectedPlanId = 'free'
        this.confirmPlan()
    }

    @Emit('confirmPlan')
    emitConfirmPlan (planId: string | null) {
        return planId
    }
}
</script>
<style lang="scss" scoped>
.plans {
    &__plans-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        justify-content: center;

        @include breakpoint(black-bear) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__plan {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        border-radius: 6px;
        border: 1px solid rgba($pewter, 0.85);
        box-shadow: 0px 1px 4px 0px rgba(71, 89, 103, 0.30);
        background: $white;
        width: 256px;
        user-select: none;
        margin-bottom: 42px;
        outline: none;

        &:not(:first-child) {
            margin-left: 20px;

            @include breakpoint(black-bear) {
                margin-left: 0;
            }
        }

        &::before {
            content: '';
            display: none;
            left: -2px;
            top: -2px;
            width: 100%;
            height: 100%;
            border-radius: 6px;
            position: absolute;
        }

    &--another-plan {
        cursor: pointer;
        &:hover::before {
            display: block;
            border: 2px solid $slate;
            border-radius: 6px;
        }

        &:focus::before {
            display: block;
            left: -1px;
            top: -1px;
            border: 1px solid $brand-blue;
            border-radius: 6px;
        }
    }

    &--selected {
        background-color: $barely-blue;;
        &::before {
            display: block;
            left: -2px !important;
            top: -2px !important;
            border: 2px solid $brand-blue !important;
            border-radius: 6px;
        }
    }

        @include breakpoint(brown-bear) {
            width: 210px;
        }

        @include breakpoint(black-bear) {
            width: 319px;
            min-height: 58px;
            align-items: flex-start;
            margin-bottom: 26px;


            &:last-child {
                margin-bottom: 0;
            }
        }

    }

    &__tag {
        position: absolute;
        top: -10px;
        right: 15px;
        width: 60px;
        height: 18px;
        font-size: 12px;
        border-radius: 3px;
        background: $brand-blue;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;

        &--not-selected {
            background: rgba(71, 89, 103, 1);
        }

        @include breakpoint(black-bear) {
            width: 54px;
            height: 16px;
        }
    }

    &__plan-top-info {
        @include breakpoint(black-bear) {
            display: flex;
            margin-top: 10px;
            width: 100%;
            justify-content: space-between;
        }
    }

    &__plan-duration {
        color: $ash;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        margin-top: 18px;
        margin-bottom: 6px;

        @include breakpoint(black-bear) {
            margin-top: 5px;
            margin-bottom: 0;
            margin-left: 12px;
        }
    }

    &__plan-price-label {
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: $brand-black;
        font-size: 23px;
        font-weight: 600;
        line-height: 24px;

        @include breakpoint(black-bear) {
            margin-top: 5px;
            margin-right: 13px;
        }
    }

    &__cadence {
        color: $brand-black;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    &__dot {
        color: $ash;
        margin-left: 3px;
        font-size: 15px;
    }

    &__plan-divider {
        width: 222px;
        height: 0.945px;
        border-bottom: 1px solid $fog;
        margin-top: 13px;
        margin-bottom: 11px;

        @include breakpoint(brown-bear) {
            width: 173.438px;
            height: 0.945px;
        }

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__plan-price-length {
        color: $brand-black;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
    }

    &__plan-details {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint(black-bear) {
            flex-direction: row;
            margin-top: 10px;
            margin-left: 12px;
            margin-bottom: 11px;
        }
    }

    &__plan-price {
        display: flex;
        flex-direction: row;
        color: $ash;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.4px;
        margin-top: 8px;

        @include breakpoint(black-bear) {
            margin-top: 0;
        }
    }

    &__plan-billing-label {
        color: $ash;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.4px;
        margin-bottom: 8px;
        margin-top: 4px;

        @include breakpoint(black-bear) {
            margin: 0 auto 0 3px;
        }
    }

    &__plan-discount-price {
        display: flex;
        flex-direction: row;
        text-align: center;
        font-size: 15px;
        opacity: 0.8;
        color: $brand-black;
        font-weight: 600;
        line-height: 21px;
        margin-top: 4px;
        margin-bottom: 11px;
        z-index: 1;

        svg {
            position: absolute;
            left: -4px;
            bottom: 11px;
            width: calc(100% + 5px);
            z-index: -2;
        }

        @include breakpoint(black-bear) {
            color: $ash;
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
            margin-top: 0;
            margin-bottom: 0;

            svg {
                left: auto;
                bottom: 11px;
                width: 55px;
                z-index: -2;
            }
        }
    }

    &__discount-price {
        margin-left: 4px;
    }

    &__plan-discount-duration {
        color: $ash;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.4px;
        line-height: 21px;
        margin-top: 2px;

        @include breakpoint(black-bear) {
            line-height: 20px;
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    &__basic-option-bottom {
        margin-top: 36px;
    }

    &__description {
        font-size: 14px;
        line-height: 19px;
        color: $ash;
        margin-bottom: 16px;
        margin-left: 14px;
        width: 349px;

        @include breakpoint(brown-bear) {
            width: 304px;
        }
    }

    &__plan-right {
        text-align: right;
        display: flex;
        justify-content: right;
    }

    &__plan-tag {
        border-radius: 3px;
        height: 18px;
        color: $white;
        padding: 0 5px;
        line-height: 18px;
        font-size: 12px;
        letter-spacing: -0.28px;
        margin-right: 12px;
        background: $slate;

        &--popular {
            background-image: linear-gradient(201deg, $cosmos 0%, $barney-purple 100%);
        }

        &--best-value {
            float: right;
            background-image: linear-gradient(201deg, $cosmos 0%, $barney-purple 100%);
            margin-right: 0;
        }
    }

    &__confirm {
        margin: 32px auto 6px;
        display: block;

        svg {
            margin-left: 10px;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;


        @include breakpoint(black-bear) {
            display: flex;
            flex-direction: column-reverse;
        }

        @include breakpoint(black-bear) {
            margin-top: 31px
        }
    }

    &__try-free-btn {
        margin-right: 8px;

        @include breakpoint(black-bear) {
            margin-top: 16px
        }
    }

    &__purchase-btn-arrow {
        width: 12px;
        margin-top: 4px;
        margin-left: 14px;
    }
}
</style>