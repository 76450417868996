<!-- eslint-disable -->
<template>
    <svg width="210px" height="96px" viewBox="0 0 210 96" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-22.000000, 0.000000)">
                <g transform="translate(22.481164, -0.000000)">
                    <path d="M193.094234,67.6692141 C198.478888,71.3020024 203.783603,74.6799724 208.987171,77.8597867 L208.987161,95.5652061 L9.41469125e-14,95.5661968 C2.05661425,92.6963756 4.23575277,89.7686587 6.52952838,86.7699858 C41.5766513,40.9525762 116.042841,15.6860414 193.094234,67.6692141 Z" :fill="fillColor" opacity="0.100372314"></path>
                    <path d="M87.2726236,87.8222833 C149.108595,16.9069615 162.507961,-9.73284795 208.984925,3.11474966 L208.987161,95.5652061 L81.1269386,95.56549 C82.9504941,93.0147137 84.9957813,90.4334333 87.2726236,87.8222833 Z" fill="url(#halftone-blue)"></path>
                </g>
            </g>
        </g>
    </svg>
</template>
<!-- eslint-enable -->

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'

@Component
export default class BottomRightBlob extends Vue {
    get fillColor () {
        return BrandColors.brandBlue
    }
}
</script>
