import { environment } from '@/utils'
import { ApmBase, init } from '@elastic/apm-rum'
import { router } from '@/router'
import { analyticsModule } from '@/store/analytics/module'
import axios from 'axios'

let apm: ApmBase | undefined
const initApm = () => {
    if (import.meta.env.VUE_APP_ELK_SERVER_URL && !apm) {
        apm = init({
            serviceName: 'study-web',
            serverUrl: import.meta.env.VUE_APP_ELK_SERVER_URL,
            serviceVersion: import.meta.env.VUE_APP_VERSION,
            environment,
            distributedTracingOrigins: [ environment === 'localhost'
                ? /https?:\/\/localhost:.*/
                : /https?:\/\/api\..*pocketprep\.com/ ],
            propagateTracestate: true,
            pageLoadTransactionName: 'initial',
        })

        apm.observe('transaction:end', tr => {
            if (tr.type === 'route-change' || tr.type === 'page-load') {
                const currentRouteValue = router.currentRoute.value
                const matchedPath = currentRouteValue.matched[currentRouteValue.matched.length - 1]?.path
                tr.name = `[${tr.type}] ${matchedPath || currentRouteValue.fullPath}`
            }

            tr.addContext(analyticsModule.getters.apmCustomContext())
        })
    }

    return apm
}
apm = initApm()

type TLabels = Record<string, string | number | boolean | undefined>
const emitLog = (
    message: string, 
    labels: TLabels, 
    logLevel: 'info' | 'warn' | 'error', 
    tag?: string
) => {
    const transportUrl = import.meta.env.VUE_APP_TRANSPORT_URL
    if (!transportUrl) {
        // eslint-disable-next-line no-console
        console.log(`[${logLevel.toUpperCase()}]`, message, labels)
        return
    }

    axios.post(
        `${transportUrl}/log-v2`, 
        {
            message,
            source: 'Web',
            logLevel,
            environment,
            tag,
            userAgent: {
                rawUserAgent: window.navigator.userAgent,
            },
            labels,
        }
    )
}

const logger = {
    info: (message: string, labels: TLabels, tag?: string) => 
        emitLog(message, labels, 'info', tag),
    warn: (message: string, labels: TLabels, tag?: string) => 
        emitLog(message, labels, 'warn', tag),
    error: (message: string, labels: TLabels, tag?: string) => 
        emitLog(message, labels, 'error', tag),
}

export {
    apm,
    logger,
}