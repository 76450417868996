<template>
    <div class="slide">
        <img
            class="slide__image"
            aria-live="polite"
            :src="slideImage"
            :alt="imageAlt"
        >
        <div
            class="slide__title"
            aria-live="polite"
            v-html="title"
        />
        <div
            class="slide__description"
            aria-live="polite"
            v-html="description"
        />
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator'

@Component
export default class Slide extends Vue {
    @Prop() title!: string
    @Prop() description!: string
    @Prop() imageName!: string
    @Prop({ default: '' }) imageAlt!: string

    get slideImage () {
        return new URL(`../../assets/welcomeSeries/${this.imageName}`, import.meta.url).href
    }
}
</script>

<style lang="scss" scoped>
.slide {
    text-align: center;

    &__image {
        padding: 23px 0 21px;
        box-sizing: border-box;
        width: 436px;
        display: block;
        margin: 0 auto;

        @include breakpoint(black-bear) {
            margin-left: -40.5px;
            padding: 17px 0 29px;
        }
    }

    &__title {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        width: 340px;
        margin: 0 auto 8px;

        @include breakpoint(black-bear) {
            width: 239px;
        }
    }

    &__description {
        font-size: 14px;
        line-height: 19px;
        width: 340px;
        margin: 0 auto;
        color: $slate-03;

        @include breakpoint(black-bear) {
            width: 310px;
        }
    }
}
</style>