import { reactive } from 'vue'
import actions from '@/store/bundle/actions'
import state from '@/store/bundle/state'
import getters from '@/store/bundle/getters'

export const bundleModule = {
    state: reactive(state),
    actions,
    getters,
}
