<template>
    <Portal to="modal">
        <ModalContainer
            key="newVersionModal"
            :is-dark-mode="isDarkMode"
            @close="emitClose"
        >
            <template #modal>
                <Modal
                    :show-close-button="true"
                    class="new-version"
                    :is-dark-mode="isDarkMode"
                    @close="emitClose"
                >
                    <div class="new-version__title">
                        New Version Available
                    </div>
                    <div v-dark class="new-version__text">
                        {{ examMetadata.releaseInfo && examMetadata.releaseInfo.description }}
                    </div>
                    <div v-dark class="new-version__warning">
                        Switching to a new version will reset your progress. 
                    </div>
                    <div class="new-version__buttons">
                        <PocketButton
                            :is-dark-mode="isDarkMode"
                            class="new-version__submit"
                            :is-loading="isLoading"
                            type="secondary"
                            @click="switchExams"
                        >
                            Switch Exams
                        </PocketButton>
                        <PocketButton
                            :is-dark-mode="isDarkMode"
                            class="new-version__cancel"
                            @click="emitClose"
                        >
                            Maybe Later
                        </PocketButton>
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { toastModule } from '@/store/toast/module'
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import type { Study } from '@pocketprep/types'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'

@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
        PocketButton: UIKit.Button,
    },
})
export default class NewVersionModal extends Vue {
    @Prop() examMetadata!: Study.Class.ExamMetadataJSON

    isLoading = false

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get uem () {
        return userExamMetadataModule.getters.getCurrentUserExamMetadata()
    }

    mounted () {
        if (!this.uem) {
            throw new Error('No current userExamMetadata.')
        }

        userExamMetadataModule.actions.upsertUserExamMetadata({
            objectId: this.uem?.objectId,
            upgradesSeen: [
                ...this.uem.upgradesSeen || [],
                this.examMetadata.version.split('.')[0],
            ],
        })
    }
    
    async switchExams () {
        if (!this.uem) {
            throw new Error('No user exam metadata found.')
        }

        this.isLoading = true
        await userExamMetadataModule.actions.upsertUserExamMetadata({
            examVersion: this.examMetadata.version,
            objectId: this.uem.objectId,
            examGuid: this.uem.examGuid,
            flaggedQuestions: [],
            progressResetAt: new Date(),
            disabledSubjects: [],
        }),
        await userExamMetadataModule.actions.resetExamProgress(this.examMetadata)

        toastModule.actions.displayToast({
            title: `${this.examMetadata.nativeAppName} version updated successfully`,
            subtext: 'Your progress has been reset. Happy studying!',
        })
        this.isLoading = false
        this.emitClose()
    }

    @Emit('close')
    emitClose () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.new-version {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    top: 80px;
    width: 392px;
    padding: 35px 0 26px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakpoint(black-bear) {
        width: 356px;
        height: auto;
        max-height: 638px;
        top: 10px;
    }

    &__title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        width: 309px;
        margin: 0 auto 12px;
    }

    &__text {
        font-size: 15px;
        line-height: 22px;
        color: $ash;
        margin-bottom: 12px;
        width: 309px;

        &--dark {
            color: $white;
        }
    }

    &__warning {
        color: $pepper;
        max-width: 257px;
        margin-bottom: 22px;
        font-size: 15px;
        line-height: 18px;

        &--dark {
            color: $rosa;
        }
    }

    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__submit {
        margin-right: 12px;
    }
}
</style>