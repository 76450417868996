<script setup lang="ts">
// Imports
import ThumbUpDown from '@/components/ThumbUpDown.vue'
import { screenModule } from '@/store/screen/module'
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { computed } from 'vue'
const {
    Button: PocketButton,
    Link: PocketLink,
} = UIKit

// Props
defineProps<{
    isHelpfulVote: string | null
    aiFeedback: string
    isLoading: boolean
}>()

// Emitted events
const emit = defineEmits<{
    'giveFeedbackClicked': []
    'generatedByAIClicked': []
    'submitVote': [ vote: string ]
}>()

// Computed
const isDarkMode = computed(() => {
    return userModule.state.settings.isDarkMode
})

const breakpoint = computed(() => {
    return screenModule.getters.getBreakpoint()
})

// Methods
const submitVote = (vote: string) => {
    emit('submitVote', vote)
}
</script>

<template>
    <div class="ai-feedback">
        <img
            v-if="isDarkMode"
            class="ai-feedback__brain-pencil-paper"
            src="@/assets/brain/brain-pencil-paper-dm.png"
            alt="Brain with pencil and paper"
        >
        <img
            v-else
            class="ai-feedback__brain-pencil-paper"
            src="@/assets/brain/brain-pencil-paper.png"
            alt="Brain with pencil and paper"
        >
        <div class="ai-feedback__title" v-dark="isDarkMode">
            Feedback on your explanation
        </div>
        <div
            v-if="isLoading"
            class="ai-feedback__loading"
            v-dark="isDarkMode"
        >
            <span>•</span>
            <span>•</span>
            <span>•</span>
        </div>
        <div
            v-else-if="aiFeedback"
            class="ai-feedback__content"
            v-dark="isDarkMode"
        >
            {{ aiFeedback }}
        </div>
        <div class="ai-feedback__footer">
            <PocketButton
                v-if="!aiFeedback && !isLoading"
                :isDarkMode="isDarkMode"
                class="ai-feedback__give-feedback-btn"
                type="outline"
                @click="emit('giveFeedbackClicked')"
            >
                Give me feedback
            </PocketButton>
            <div class="ai-feedback__by-ai">
                Feedback <PocketLink
                    :isDarkMode="isDarkMode"
                    class="ai-feedback__by-ai-link"
                    @click="emit('generatedByAIClicked')"
                >generated by AI</PocketLink>
            </div>
            <template v-if="aiFeedback">
                <div class="ai-feedback__feedback-group-label" v-dark="isDarkMode">
                    {{ [ 'grizzly-bear', 'polar-bear' ].includes(breakpoint) ? 'Helpful?' : 'Was it helpful?' }}
                </div>
                <ThumbUpDown
                    class="ai-feedback__thumb-up-down"
                    :vote="isHelpfulVote"
                    :isDarkMode="!isDarkMode"
                    :upDownValues="{ up: 'Helpful', down: 'Unhelpful' }"
                    @submitVote="submitVote"
                />
            </template>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.ai-feedback {
    position: relative;
    margin-top: 24px;
    padding-top: 16px;
    padding-left: 76px;
    border-top: 1px solid $fog;

    @include breakpoint(black-bear) {
        padding-left: 0;
    }

    &__brain-pencil-paper {
        position: absolute;
        top: 16px;
        left: 0;
        width: 57px;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $ash;

        &--dark {
            color: $fog;
        }
    }

    @keyframes ai-feedback-loading {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 0.2;
        }
    }

    &__loading {
        margin-top: 14px;
        margin-bottom: 26px;
        border-radius: 6px;
        background-color: $pearl;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        color: $pickled-bluewood;

        &--dark {
            background-color: $pickled-bluewood;
            color: $pewter;
        }

        span:first-child {
            opacity: 0.2;
            animation-name: ai-feedback-loading;
            animation-duration: 1.2s;
            animation-iteration-count: infinite;
            animation-delay: 0s;
            animation-timing-function: linear;
        }

        span:nth-child(2) {
            opacity: 0.2;
            animation-name: ai-feedback-loading;
            animation-duration: 1.2s;
            animation-iteration-count: infinite;
            animation-delay: 0.3s;
            animation-timing-function: linear;
        }

        span:nth-child(3) {
            opacity: 0.2;
            animation-name: ai-feedback-loading;
            animation-duration: 1.2s;
            animation-iteration-count: infinite;
            animation-delay: 0.6s;
            animation-timing-function: linear;
        }
    }

    &__content {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $ash;
        margin-top: 12px;
        margin-bottom: 32px;

        &--dark {
            color: $fog;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 14px;
        margin-top: 14px;
    }

    &__give-feedback-btn {
        min-width: 165px;
    }

    &__by-ai {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        min-width: 146px;
    }

    &__by-ai-link {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }

    &__feedback-group-label {
        position: relative;
        margin-left: 14px;
        min-width: 81px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: $ash;
        flex: 1;

        @include breakpoint(polar-bear) {
            min-width: auto;
        }

        @include breakpoint(grizzly-bear) {
            min-width: auto;
        }

        @include breakpoint(black-bear) {
            display: none;
        }

        &::before {
            content: '|';
            position: absolute;
            left: -14px;
            font-weight: 500;
            line-height: 16px;
            height: 16px;
            color: $pewter;
        }

        &--dark {
            color: $fog;
        }
    }

    &__thumb-up-down {
        @include breakpoint(black-bear) {
            margin-left: auto;
        }
    }
}
</style>
