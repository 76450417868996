<template>
    <div class="choose-exam">
        <BundleSearch
            :bundles="bundles"
            :exam-metadata-by-id="examMetadata"
            @select="handleSelectExam"
            @selectBundle="handleSelectBundle"
            @search="handleSearch"
        />
    </div>
</template>

<script lang="ts">
import { Vue, Component, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { bundleModule } from '@/store/bundle/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import type { Study } from '@pocketprep/types'

export type TBundleExam = Study.Class.ExamMetadataJSON & { bundle: Study.Class.BundleJSON }

@Component({
    components: {
        BundleSearch: UIKit.BundleSearch,
    },
})
export default class ChooseExam extends Vue {
    get bundles () {
        return [ ...bundleModule.getters.getBundles() ]
            .filter(bundle => bundle.name !== 'Finance')
            .sort((a, b) => a.name.localeCompare(b.name))
    }

    get examMetadata () {
        return examMetadataModule.getters.getMostRecentExamMetadataById()
    }

    async mounted () {
        await Promise.all([
            bundleModule.actions.fetchBundles(),
            examMetadataModule.actions.fetchExamMetadata(),
        ])
    }

    handleSelectExam (exam: TBundleExam) {
        this.emitSelect(exam)
    }

    handleSelectBundle (bundle: Study.Class.BundleJSON) {
        this.emitSelectBundle(bundle)
    }

    handleSearch (search: string) {
        this.emitSearch(search)
    }

    @Emit('select')
    emitSelect (exam: TBundleExam) {
        return exam
    }

    @Emit ('selectBundle')
    emitSelectBundle (bundle: Study.Class.BundleJSON) {
        return bundle
    }

    @Emit('search')
    emitSearch (searchString: string) {
        return searchString
    }
}
</script>

<style lang="scss" scoped>
.choose-exam {
    padding-top: 23px;
    max-width: 885px;
    width: 100%;
    height: calc(100% - 80px);
    margin: 0 auto -30px;
    box-sizing: border-box;

    @include breakpoint(brown-bear) {
        max-width: 657px;
    }

    @include breakpoint(black-bear) {
        max-width: 356px;
        height: auto;
    }
}
</style>