import { Parse, runCloudFunction } from '@/store/parseUtils'
import type { Study } from '@pocketprep/types'
import { fetchLoadable } from '@/store/utils'
import { examMetadataModule } from '@/store/examMetadata/module'

const fetchExamMetadata = async () => {
    const examLoadable = examMetadataModule.state.examMetadata

    await fetchLoadable(examLoadable, async () => {
        const examMetadata = await new Parse.Query<Study.Class.ExamMetadata>('ExamMetadata')
            .findAll({ batchSize: 500 })
        return examMetadata.map(e => e.toJSON())
    })
}

const fetchSubjectsWithLevels = async ({ examGuid, majorVersion }: {
    examGuid: string
    majorVersion: string
}) => {
    return fetchLoadable(examMetadataModule.state.subjectsWithLevels, 
        async () => await runCloudFunction<Study.Cloud.fetchSubjectsWithLevels>('fetchSubjectsWithLevels', {
            examGuid,
            majorVersion,
        }))
}

export default {
    fetchExamMetadata,
    fetchSubjectsWithLevels,
}
