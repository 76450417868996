import { reactive } from 'vue'
import actions from '@/store/subscription/actions'
import state from '@/store/subscription/state'
import getters from '@/store/subscription/getters'

export const subscriptionModule = {
    state: reactive(state) as typeof state, // TS needs an extra hint due to subscription state's complexity
    actions,
    getters,
}
