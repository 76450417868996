import { reactive } from 'vue'
import actions from '@/store/question/actions'
import state from '@/store/question/state'
import getters from '@/store/question/getters'

export const questionModule = {
    state: reactive(state),
    actions,
    getters,
}
