<template>
    <div class="calendar" :class="{ [`calendar--${calendarView}`]: true }">        
        <div class="calendar__row calendar__head">
            <div
                v-dark="isDarkMode"
                class="calendar__item"
            >
                <span aria-label="Sunday" role="img">S</span>
            </div>
            <div
                v-dark="isDarkMode"
                class="calendar__item"
            >
                <span aria-label="Monday" role="img">M</span>
            </div>
            <div
                v-dark="isDarkMode"
                class="calendar__item"
            >
                <span aria-label="Tuesday" role="img">T</span>
            </div>
            <div
                v-dark="isDarkMode"
                class="calendar__item"
            >
                <span aria-label="Wednesday" role="img">W</span>
            </div>
            <div
                v-dark="isDarkMode"
                class="calendar__item"
            >
                <span aria-label="Thursday" role="img">T</span>
            </div>
            <div
                v-dark="isDarkMode"
                class="calendar__item"
            >
                <span aria-label="Friday" role="img">F</span>
            </div>
            <div
                v-dark="isDarkMode"
                class="calendar__item"
            >
                <span aria-label="Saturday" role="img">S</span>
            </div>
        </div>
        <div
            v-for="(week, index) in slicedWeeks"
            :key="index"
            class="calendar__row calendar__week"
        >
            <div
                v-for="date in week"
                :key="date"
                v-dark="isDarkMode"
                class="calendar__item"
                :aria-label="getDateAriaLabel(date)"
                :class="{
                    'calendar__item--before-registered': dateIsBeforeRegistration(date),
                    'calendar__item--active': calendarView === 'all' 
                        && dateHasActivity(date),
                    'calendar__item--qotd': calendarView === 'qotd' 
                        && date in quizDateLib 
                        && quizDateLib[date].qotd,
                    'calendar__item--correct': calendarView === 'qotd' 
                        && date in quizDateLib
                        && quizDateLib[date].qotd
                        && isQotdCorrect(date),
                    'calendar__item--incorrect': calendarView === 'qotd' 
                        && date in quizDateLib
                        && quizDateLib[date].qotd
                        && !isQotdCorrect(date),
                    'calendar__item--active-previous': calendarView === 'all' 
                        && dateHasPreviousActivity(date)
                        && dateHasActivity(date),
                    'calendar__item--active-next': calendarView === 'all' 
                        && dateHasNextActivity(date)
                        && dateHasActivity(date),
                    'calendar__item--exam-date': isExamDate(date)
                }"
            >
                <span
                    class="calendar__date"
                >
                    {{ new Date(date).getDate() }} 
                    <LightExamDateOutline v-if="!isDarkMode && isExamDate(date)" />
                    <DarkExamDateOutline v-if="isDarkMode && isExamDate(date)" />
                </span>
                <div v-dark="isDarkMode" class="calendar__item--background" />
                <div
                    v-if="new Date(date).getDate() === todaysDate"
                    v-dark="isDarkMode"
                    class="calendar__item--today"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import type { Study } from '@pocketprep/types'
import { Vue, Component, Prop } from 'vue-facing-decorator'
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import LightExamDateOutline from '@/assets/celebrations/LightExamDateOutline.vue'
import DarkExamDateOutline from '@/assets/celebrations/DarkExamDateOutline.vue'

@Component({
    components: {
        LightExamDateOutline,
        DarkExamDateOutline,
    },
})
export default class Calendar extends Vue {
    @Prop() calendarView!: 'qotd' | 'all'
    @Prop() quizDateLib!: { [date: number]: { qotd?: Study.Class.QuizJSON; quizzes: Study.Class.QuizJSON[] } }
    @Prop() dates!: number[]
    @Prop() isDarkMode!: boolean

    todaysDate = new Date().getDate()

    get slicedWeeks () {
        return [
            this.dates.slice(0, 7),
            this.dates.slice(7, 14),
        ]
    }
    get userExamMetadata () {
        return userExamMetadataModule.getters.getCurrentUserExamMetadata()
    }

    isExamDate (date: number) {
        if (!this.userExamMetadata?.scheduledExamDate) {
            return undefined
        }
        const scheduledTime = new Date(this.userExamMetadata.scheduledExamDate.iso).getTime()

        if (new Date(date).getTime() === scheduledTime) {
            return true
        }
        return false
    }

    dateHasNextActivity (date: number) {
        const dateIndex = this.dates.indexOf(date)
        const nextDate = this.dates[dateIndex + 1]
        if (nextDate) {
            return !!this.quizDateLib[nextDate]?.quizzes
        }
        return
    }

    dateHasPreviousActivity (date: number) {
        const dateIndex = this.dates.indexOf(date)
        const previousDate = this.dates[dateIndex - 1]
        if (previousDate) {
            return !!this.quizDateLib[previousDate]?.quizzes
        }
        return
    }

    isQotdCorrect (date: number) {
        return (this.quizDateLib[date]?.qotd?.correctCount || 0) > 0
    }

    dateHasActivity (date: number) {
        return date in this.quizDateLib
            && !!this.quizDateLib[date]?.quizzes.length
    }

    dateIsBeforeRegistration (date: number) {
        const userCreatedAtTime = new Date(userModule.state.user?.createdAt || 0).setHours(0, 0, 0, 0)
        return date < userCreatedAtTime
    }

    calendarDateInactive (date: number) {
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        return ((this.calendarView === 'all' && !this.dateHasActivity(date)) 
                || (this.calendarView === 'qotd' && (!(date in this.quizDateLib) || !this.quizDateLib[date]?.qotd)))
            && date < today.getTime()
            && !this.dateIsBeforeRegistration(date)
    }

    getDateAriaLabel (date: number) {
        const fullDate = date
        if (this.calendarView === 'qotd') {
            if (this.calendarDateInactive(date)) {
                return `${fullDate}, no question of the day answered`
            }

            return `${fullDate}, question of the day answered ${this.isQotdCorrect(date) ? 'correctly' : 'incorrectly'}`
        }

        if (this.calendarView === 'all') {
            if (this.calendarDateInactive(date)) {
                return `${fullDate}, no activity`
            }

            return `${fullDate}, study activity logged`
        }
    }
}
</script>
<style lang="scss" scoped>
.calendar {
    max-width: 410px;
    margin: 0 auto 20px;

    @include breakpoint(brown-bear) {
        margin-bottom: 16px;
    }

    @include breakpoint(black-bear) {
        max-width: 325px;
    }

    &__row {
        display: flex;
        justify-content: space-between;
    }

    &__head {
        margin-bottom: 5px;
        margin-top: 24px;

        @include breakpoint(brown-bear) {
            margin-top: 12px;
        }

        @include breakpoint(black-bear) {
            margin-bottom: 0;
            margin-top: 12px;
        }

        .calendar__item {
            font-size: 11px;
            letter-spacing: 0.44px;
            color: rgba($black-feather, 0.8);

            &--dark {
                color: $white;
                opacity: 0.8;
            }

            @include breakpoint(black-bear) {
                font-size: 10px;
            }
        }
    }

    &__item {
        width: 42px;
        height: 42px;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        letter-spacing: -0.57px;
        font-weight: 600;
        position: relative;
        border-radius: 100%;
        box-sizing: border-box;

        @include breakpoint(black-bear) {
            width: 36px;
            height: 36px;
            font-size: 14px;
            letter-spacing: -0.5px;
            line-height: 17px;
        }

        span {
            z-index: 2;
            position: relative;
        }

        &--dark {
            color: $white;
        }

        &--background {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 42px;
            z-index: 1;
        }

        &--today {
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            width: 14px;
            height: 2px;
            background: $brand-black;
            z-index: 1;

            &--dark {
                background: $white;
            }

            @include breakpoint(black-bear) {
                width: 12px;
                bottom: 8px;
            }
        }

        &--active {
            color: $white;

            &--dark {
                color: $brand-black;
            }

            .calendar__item--background {
                background: $brand-black;

                &--dark {
                    background: $white;
                }
            }

            .calendar__item--today {
                background: $white;

                &--dark {
                    background: $brand-black;
                }
            }
        }

        &--before-registered {
            color: rgba($brand-black, 0.7);

            &--dark {
                color: rgba($white, 0.7);
            }
        }

        &--correct {
            color: $white;

            &--dark {
                color: $brand-black;
            }

            .calendar__item--background {
                background: $spectral-green;

                &--dark {
                    background-color: $jungle-green;
                }
            }

            .calendar__item--today {
                background: $white;

                &--dark {
                    background: $brand-black;
                }
            }
        }

        &--incorrect {
            color: $pepper;

            &::before {
                content: '';
                left: 0;
                top: 0;
                border: 1px solid $pepper;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                border-radius: 42px;
                position: absolute;
            }

            &--dark {
                color: $rosa;

                &::before {
                    border-color: $rosa;
                }
            }

            .calendar__item--today {
                background: $pepper;

                &--dark {
                    background: $rosa;
                }
            }
        }

        &--active-previous,
        &--active-next {
            &::after,
            &::before {
                position: absolute;
                background: $brand-black;
                height: 3.5px;
                width: 20px;
                border-radius: 3.5px;

                @include breakpoint(black-bear) {
                    width: 18px;
                }
            }

            &--dark {
                &::after,
                &::before {
                    background: $white;
                }
            }
        }

        &--active-previous {
            &::before {
                content: '';
                left: -16px;
            }
        }

        &--active-next {
            &::after {
                content: '';
                right: -16px;
            }
        }

        &--exam-date {
            z-index: 1;
            position: relative;

            svg {
                position: absolute;
                top: -14px;
                left: 50%;
                transform: translateX(-50%);
                z-index: -2;
            }
        }
    }

    &__week {
        &:last-child {
            margin-top: 18px;

            @include breakpoint(black-bear) {
                margin-top: 13px;
            }
        }
    }
}
</style>