<template>
    <div
        id="stats" 
        v-dark 
        class="stats"
    >
        <UpgradeSidePanel
            v-if="showUpgradeSidePanel && user"
            key="head"
            :has-active-referral-link="validReferral"
            :referral-link-errors="[ referralError ]"
            @close="showUpgradeSidePanel = false"
        />
        <StatsBanner v-if="examMetadata" :key="answerKey" />
        <PocketButton
            v-if="!isLoading && breakpoint === 'black-bear' && additionalQuestionCount && !hasPremiumAccess"
            :is-dark-mode="isDarkMode"
            class="stats__mobile-cta"
            :is-loading="isLoading"
            @click="showUpgradeSidePanel = true"
        >
            Get {{ additionalQuestionCount }} more questions with Premium
        </PocketButton>
        <ReadinessBanner
            v-if="percentQuestionBankComplete >= 30
                && currentReadinessScore >= 0.3
                && (daysUntilExam === null || daysUntilExam >= 0)
            "
            :readinessScore="currentReadinessScore"
            :percentQuestionBankComplete="percentQuestionBankComplete"
            :daysUntilExam="daysUntilExam"
        />
        <SubjectInsights />
        <div class="stats__2-column">
            <div class="stats__2-column-left">
                <QuizScores class="stats__quiz-scores" />
                <StudyProgress class="stats__study-progress" />
            </div>
            <div class="stats__2-column-right">
                <StudyActivity class="stats__study-activity" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import StatsBanner from '@/components/Stats/Banner.vue'
import SubjectInsights from '@/components/SubjectInsights.vue'
import UpgradeSidePanel from '@/components/Settings/UpgradeSidePanel.vue'
import { examMetadataModule } from '@/store/examMetadata/module'
import { quizModule } from '@/store/quiz/module'
import { userModule } from '@/store/user/module'
import QuizScores from '@/components/Stats/QuizScores.vue'
import StudyActivity from '@/components/Stats/StudyActivity.vue'
import StudyProgress from '@/components/Stats/StudyProgress.vue'
import ReadinessBanner from '@/components/Stats/ReadinessBanner.vue'
import { screenModule } from '@/store/screen/module'
import { subscriptionModule } from '@/store/subscription/module'
import { referralModule } from '@/store/referral/module'
import { readinessModule } from '@/store/readiness/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'

@Component({
    components: {
        StatsBanner,
        SubjectInsights,
        QuizScores,
        StudyActivity,
        StudyProgress,
        ReadinessBanner,
        PocketButton: UIKit.Button,
        UpgradeSidePanel,
        PocketLink: UIKit.Link,
        Icon: UIKit.Icon,
    },
})
export default class Stats extends Vue {
    isLoading = true
    showUpgradeSidePanel = false

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get examMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get answerKey () {
        // keep track of most recent answer serial because if it changes then we reload the Stats Banner
        const answers = Object.values(quizModule.getters.getLatestAnswers({ questionFilter: 'accessible' }))
        const answer = answers[0]
        return `${answer?.questionSerial}_${answers.length}`
    }
    get additionalQuestionCount () {
        if (this.examMetadata) {
            // subtract archived and free questions
            return (this.examMetadata?.itemCount || 0) 
                - (this.examMetadata?.archivedCount || 0) - (this.examMetadata?.specialQuestions || 0)
        }
        return 0 
    } 

    get user () {
        return userModule.state.user
    }

    get validReferral () {
        return referralModule.getters.getValidReferral()
    }

    get referralError () {
        return referralModule.getters.getReferralError()
    }

    get hasPremiumAccess () {
        const currentExam = examMetadataModule.getters.getCurrentExamMetadata()
        return subscriptionModule.getters.getSubscriptionForExamId(currentExam?.objectId)
    }

    get bestAnswers () {
        return Object.values(
            quizModule.getters.getLatestAnswers({ questionFilter: this.hasPremiumAccess ? 'all' : 'free' })
        )
    }

    get premiumQuestionCount () {
        return ((this.examMetadata?.itemCount || 0) - (this.examMetadata?.archivedCount || 0))
    }

    get percentQuestionBankComplete () {
        return Math.round((this.bestAnswers.length / this.premiumQuestionCount) * 100)
    }

    get readinessScoreResponses () {
        return readinessModule.getters.getReadinessScores()
    }

    get currentReadinessScoreResponse () {
        return this.readinessScoreResponses?.find(readinessScoreResponse =>
            readinessScoreResponse.examGuid === this.examMetadata?.examGuid
        ) || null
    }

    get currentReadinessScore () {
        return this.currentReadinessScoreResponse?.readinessScore || 0
    }

    get scheduledExamDate () {
        return userExamMetadataModule.getters.getCurrentUserExamMetadata()?.scheduledExamDate?.iso
    }

    get daysUntilExam () {
        if (!this.scheduledExamDate) {
            return null
        }

        const scheduledTime = new Date(this.scheduledExamDate).getTime()
        const daysUntil = Math.ceil((scheduledTime - new Date().getTime()) / 86400000)

        return daysUntil
    }

    async mounted () {
        await Promise.all([
            examMetadataModule.actions.fetchExamMetadata(),
            userModule.actions.fetchUserData(),
            referralModule.actions.validateReferral(),
        ])

        const userId = this.user?.objectId
        const examGuid = this.examMetadata?.examGuid
        if (userId && examGuid) {
            await readinessModule.actions.fetchReadinessScore(userId, examGuid)
        }

        const statsEl = document.getElementById('stats')
        if (statsEl) {
            statsEl.scrollIntoView()
        }

        this.isLoading = false
    }
}
</script>

<style lang="scss" scoped>
.stats {
    background: $gray-background;
    padding: 0 0 50px;

    &--dark {
        background: $charcoal;
    }

    &__mobile-cta {
        width: calc(100% - 36px);
        margin-left: 18px;
        margin-top: 30px;
    }

    &__2-column {
        display: flex;
        justify-content: space-between;
        max-width: 974px;
        margin: 0 auto;

        @include breakpoint(brown-bear) {
            display: block;
            margin: 0 auto;
            max-width: 725px;
        }

        @include breakpoint(brown-bear) {
            box-sizing: border-box;
        }

        @include breakpoint(black-bear) {
            padding: 0 18px;
        }
    }

    &__2-column-left {
        width: 542px;

        @include breakpoint(brown-bear) {
            width: 100%;
        }
    }

    &__2-column-right {
        width: 420px;

        @include breakpoint(brown-bear) {
            width: 100%;
        }
    }

    &__quiz-scores {
        margin-bottom: 12px;

        @include breakpoint(brown-bear) {
            margin-bottom: 16px;
        }

        @include breakpoint(black-bear) {
            margin-bottom: 20px;
        }
    }

    &__study-progress {
        @include breakpoint(brown-bear) {
            margin-bottom: 16px;
        }

        @include breakpoint(black-bear) {
            margin-bottom: 20px;
        }
    }
}
</style>
