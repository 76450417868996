<template>
    <PocketTextarea
        class="bonus-question-textarea"
        :class="{
            'bonus-question-textarea--disabled': disabled,
        }"
        :modelValue="modelValue"
        :label="label"
        :height="height"
        :width="width"
        :disabled="disabled"
        :is-dark-mode="isDarkMode"
        :show-icon-and-tooltip="showIconAndTooltip"
        :tooltip-text="tooltipText"
        @update:modelValue="updateModelValue"
        @keydown.stop
    />
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        PocketTextarea: UIKit.Textarea,
    },
})
export default class BonusQuestionTextarea extends Vue {
    @Prop label!: string
    @Prop modelValue!: string
    @Prop height!: string
    @Prop({ default: '100%' }) width!: string
    @Prop disabled!: boolean
    @Prop isDarkMode!: boolean
    @Prop showIconAndTooltip!: boolean

    @Emit('update:modelValue')
    updateModelValue (newVal: string) {
        return newVal
    }

    get tooltipText () {
        return `Explaining your answer helps you think critically as you prepare for your exam.
        Compare your explanation to Pocket Prep's rationale.`
    }
}
</script>

<style lang="scss" scoped>
.bonus-question-textarea {
    margin: 0 auto;
    max-width: 492px;

    :deep(.uikit-textarea__label) {
        margin-left: 15px;
    }

    :deep(.uikit-textarea__textarea) {
        width: 341px;
        resize: none;
        padding: 11px 15px;

        &:disabled {
            background-color: rgba($white, 0.4);
            border: 1px solid rgba($pewter, 0.5);
        }

        @include breakpoint(brown-bear) {
            width: 100%;
        }
    }

    :deep(.uikit-textarea__textarea--dark) {
        &:disabled {
            background-color: rgba($moonlit-ocean, 0.5);
            border: 1px solid rgba($pewter, 0.25);
            color: $pewter;
        }
    }

    &--disabled {
        :deep(.uikit-textarea__label) {
            color: $steel;
        }

        :deep(.uikit-textarea__label--dark) {
            color: rgba($pewter, 0.85);
        }
    }
}
</style>
