<template>
    <div class="bonus-question-experiment">
        <div class="bonus-question-experiment__star-icon" v-dark="isDarkMode">
            <IconStar />
        </div>
        <div class="bonus-question-experiment__context" v-dark="isDarkMode">Bonus Question</div>
        <BonusQuestionBanner
            variant="short-answer-question"
            :is-dark-mode="isDarkMode"
        />
        <div
            class="bonus-question-experiment__prompt"
            :class="{
                'bonus-question-experiment__prompt--mobile': breakpoint === 'black-bear'
            }"
            v-html="question.prompt"
        />
        <BonusQuestionTextarea
            class="bonus-question-experiment__textarea"
            v-model="userAnswer"
            height="87px"
            :disabled="showAnswer"
            :is-dark-mode="isDarkMode"
        />
        <div v-if="!showAnswer">
            <PocketButton
                class="bonus-question-experiment__view-answer-btn"
                :disabled="!userAnswer"
                :is-dark-mode="isDarkMode"
                @click="submitUserAnswer"
            >
                View Answer
            </PocketButton>
        </div>
        <div v-if="!showAnswer">
            <PocketButton
                class="bonus-question-experiment__skip-btn"
                type="secondary"
                :is-dark-mode="isDarkMode"
                @click="skipBtnClicked"
            >
                Skip to Question of the Day
            </PocketButton>
        </div>
        <div
            v-if="showAnswer"
            class="bonus-question-experiment__answer"
            v-dark="isDarkMode"
        >
            <div class="bonus-question-experiment__answer-text">
                {{ question.answer }}
            </div>
            <PocketButton
                type="tertiary-small"
                class="bonus-question-experiment__toggle-explanation"
                :is-dark-mode="isDarkMode"
                :aria-expanded="showExplanation ? 'true' : 'false'"
                @click.stop="toggleExplanation"
                @mousedown.prevent
            >
                <span
                    ref="bonus-question-experiment__toggle-explanation-text"
                    class="bonus-question-experiment__toggle-explanation-text"
                    tabindex="-1"
                >{{ showExplanation ? 'Hide' : 'Show' }} Explanation</span>
                <Icon
                    class="bonus-question-experiment__toggle-explanation-icon"
                    :class="{
                        'bonus-question-experiment__toggle-explanation-icon--up': showExplanation,
                    }"
                    type="accordionArrow"
                />
            </PocketButton>
            <div
                v-if="showExplanation"
                class="bonus-question-experiment__explanation"
            >
                <div
                    class="bonus-question-experiment__explanation-text"
                    v-html="question.explanation"
                />
                <div
                    v-dark="isDarkMode"
                    class="bonus-question-experiment__reference"
                >
                    <span class="bonus-question-experiment__reference-label">Reference: </span>
                    <div v-html="question.reference" />
                </div>
            </div>
        </div>
        <div v-if="showAnswer">
            <PocketButton
                class="bonus-question-experiment__close-btn"
                :is-dark-mode="isDarkMode"
                @click="closeBtnClicked"
            >
                Close Bonus Question
            </PocketButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import IconStar from '@/components/BonusQuestionExperiment/IconStar.vue'
import BonusQuestionBanner from '@/components/BonusQuestionExperiment/BonusQuestionBanner.vue'
import BonusQuestionTextarea from '@/components/BonusQuestionExperiment/BonusQuestionTextarea.vue'
import { analyticsModule } from '@/store/analytics/module'
import { screenModule } from '@/store/screen/module'

@Component({
    components: {
        PocketButton: UIKit.Button,
        PocketTextarea: UIKit.Textarea,
        Icon: UIKit.Icon,
        IconStar,
        BonusQuestionBanner,
        BonusQuestionTextarea,
    },
})
export default class BonusQuestionExperiment extends Vue {
    @Prop examGuid!: string
    @Prop question!: {
        prompt: string
        answer: string
        explanation: string
        reference: string
    }
    @Prop({ default: false }) isDarkMode!: boolean
    @Prop({ default: null }) breakpointOverride!: 'black-bear' | null // Allows override from QotdContainer
    userAnswer = ''
    showAnswer = false
    showExplanation = false

    get breakpoint () {
        return this.breakpointOverride || screenModule.getters.getBreakpoint()
    }

    mounted () {
        const localStorageAnswer = localStorage.getItem(`bonusQuestionExperiment_${this.examGuid}_shortAnswer`)
        if (localStorageAnswer) {
            this.userAnswer = localStorageAnswer
            this.showAnswer = true
        }
    }

    submitUserAnswer () {
        localStorage.setItem(`bonusQuestionExperiment_${this.examGuid}_shortAnswer`, this.userAnswer)
        this.showAnswer = true
        analyticsModule.actions.amplitudeTrack('Bonus Question Answered', {
            bonusQuestionText: this.userAnswer,
            bonusQuestionVariation: 'shortAnswerQuestion',
        })
        this.emitUserAnswer()
    }

    skipBtnClicked () {
        analyticsModule.actions.amplitudeTrack('Bonus Question Skipped')
        this.emitSkip()
    }

    closeBtnClicked () {
        this.emitShowSurvey()
    }

    toggleExplanation () {
        this.showExplanation = !this.showExplanation
    }

    @Emit('userAnswer')
    emitUserAnswer () {
        return this.userAnswer
    }

    @Emit('showSurvey')
    emitShowSurvey () {
        return true
    }

    @Emit('skip')
    emitSkip () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.bonus-question-experiment {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 26px;
    padding-bottom: 50px;
    overflow: auto;
    box-sizing: border-box;

    &__star-icon {
        color: $ash;
        margin-bottom: 8px;
        width: 22px;
        height: 21px;

        &--dark {
            color: $fog;
        }
    }

    &__context {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: $ash;
        margin-bottom: 28px;

        &--dark {
            color: $fog;
        }
    }

    &__prompt {
        width: 460px;
        font-weight: 600;
        font-size: 17.5px;
        line-height: 26px;
        letter-spacing: -0.1px;
        margin-bottom: 24px;

        :deep(strong),
        :deep(b) {
            font-weight: 700;
        }

        :deep(p) {
            margin: 0 0 12px;

            &:last-child {
                margin: 0;
            }
        }

        &--mobile {
            width: 313px;
        }
    }

    &__textarea {
        display: flex;
    }

    &__view-answer-btn {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    &__skip-btn {
        margin-bottom: 24px;
    }

    &__answer {
        width: 341px;
        background-color: $white;
        padding: 14px 14px 14px 15px;
        box-sizing: border-box;
        margin-top: 13px;
        margin-bottom: 24px;
        border-radius: 5px;
        box-shadow: 0 1px 6px 0 rgba($fog, 0.6);

        &--dark {
            background-color: $brand-black;
            border: 1px solid $slate;
            box-shadow: 0 1px 4px 0 rgba($jet, 0.3);
        }
    }

    &__toggle-explanation {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 0;
    }

    &__toggle-explanation-icon {
        margin-left: 5px;
    }

    &__answer-text {
        font-weight: 600;
        font-size: 16.5px;
        line-height: 23px;
    }

    &__explanation-text {
        font-size: 16px;
        letter-spacing: -0.1px;
        line-height: 24px;
        font-weight: 400;
        padding-bottom: 6px;

        p {
            margin: 10px 0;

            &:first-child {
                margin: 0;
            }
        }
    }

    &__reference {
        padding-top: 24px;
        border-top: 1px solid $fog;
        font-size: 15px;
        letter-spacing: -0.1px;
        line-height: 22px;
        font-weight: 400;
        word-break: break-word;
        margin-bottom: -7px;

        &--dark {
            color: $white;
            border-top-color: rgba($fog, 0.28);
        }

        p {
            margin: 6px 0 8pt 0;
        }
    }

    &__reference-label {
        font-weight: 600;
    }
}
</style>
