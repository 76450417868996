<template>
    <div
        class="level-badge"
        :class="{
            'level-badge--locked': status === 'locked',
            'level-badge--active': status === 'active',
            'level-badge--complete': status === 'complete',
            'level-badge--small': size === 'small',
            'level-badge--large': size === 'large',
            'level-badge--dark': isDarkMode,
        }"
    >
        <template v-if="status === 'active'">
            <div
                class="level-badge__gauge-background-white"
                :class="{
                    'level-badge__gauge-background-white--small': size === 'small',
                    'level-badge__gauge-background-white--large': size === 'large',
                }"
            />
            <div
                class="level-badge__gauge-background"
                :class="{
                    'level-badge__gauge-background--small': size === 'small',
                    'level-badge__gauge-background--large': size === 'large',
                }"
            />
        </template>
        <canvas
            v-if="status === 'active'"
            class="level-badge__gauge"
            :class="{
                'level-badge__gauge--small': size === 'small',
                'level-badge__gauge--large': size === 'large',
            }"
            :id="id"
            width="1200"
            height="1200"
        />
        <div
            class="level-badge__level-num"
            :class="{
                'level-badge__level-num--locked': status === 'locked',
                'level-badge__level-num--active': status === 'active',
                'level-badge__level-num--complete': status === 'complete',
                'level-badge__level-num--small': size === 'small',
                'level-badge__level-num--large': size === 'large',
                'level-badge__level-num--dark': isDarkMode,
            }"
        >
            <template v-if="status === 'complete'">
                <Icon
                    class="level-badge__check"
                    :class="{
                        'level-badge__check--small': size === 'small',
                        'level-badge__check--large': size === 'large',
                        'level-badge__check--dark': isDarkMode,
                    }"
                    type="correct"
                />
            </template>
            <template v-else>
                {{ levelNum }}
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        Icon: UIKit.Icon,
    },
})
export default class LevelBadge extends Vue {
    @Prop({ default: false }) isDarkMode!: boolean
    @Prop({ default: 'large' }) size!: 'small' | 'large'
    @Prop({ default: 'locked' }) status!: 'locked' | 'active' | 'complete'
    @Prop({ default: 1 }) levelNum!: number
    @Prop({ default: 0 }) percentStart!: number
    @Prop({ default: 100 }) percentStop!: number
    @Prop({ default: false }) animate!: boolean

    id = `levelBadge-${Math.round(Math.random() * 1e5)}`
    percentDecimal = 0
    animationRate = 0.02
    animationStarted = false

    mounted () {
        this.percentDecimal = this.percentStart / 100
        this.draw()
    }

    draw () {
        const radiansComplete = this.percentDecimal * 2 * Math.PI
        const canvas = document.getElementById(this.id) as HTMLCanvasElement
        const ctx = canvas?.getContext('2d')
        if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height)

            // Dark fill, according to percent
            ctx.beginPath()
            const radius = this.size === 'large' ? 460 : 490
            ctx.arc(600, 600, radius, -(Math.PI / 2), radiansComplete - (Math.PI / 2))
            ctx.lineWidth = this.size === 'large' ? 280 : 240
            ctx.strokeStyle = '#D10FE5'
            ctx.globalAlpha = 1
            ctx.stroke()
        }

        if (this.animate && this.percentDecimal < (this.percentStop / 100)) {
            const animationRate = this.percentStop >= 100 ? this.animationRate * 2 : this.animationRate
            this.percentDecimal += animationRate
            if (!this.animationStarted) {
                setTimeout(() => {
                    window.requestAnimationFrame(this.draw)
                    this.animationStarted = true
                }, 500)
            } else {
                window.requestAnimationFrame(this.draw)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.level-badge {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    overflow: hidden;

    &--small {
        width: 40px;
        height: 40px;
    }

    &--large {
        width: 120px;
        height: 120px;
    }

    &--locked {
        background-color: $pearl;
        border: 0.5px solid $fog;
    }

    &--active {
        border: 0.5px solid $brand-purple;
    }

    &--active#{&}--large {
        border: 1px solid $brand-purple;
    }

    &--complete {
        background-color: $meadow;
        border: 0.5px solid $jungle-green;
    }

    &--complete#{&}--large {
        border: 1px solid $jungle-green;
    }

    &--locked#{&}--dark {
        background-color: rgba($pewter, 0.3);
        border: 0.5px solid rgba($pewter, 0.3);
    }

    &--complete#{&}--dark {
        background-color: $cadaverous;
        border: none;
    }

    &__gauge-background-white,
    &__gauge-background
     {
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;

        &--small {
            width: 24px;
            height: 24px;
        }

        &--large {
            width: 65px;
            height: 65px;
        }
    }

    &__gauge-background-white {
        &--small {
            border: 15px solid $white;
        }

        &--large {
            border: 30px solid $white;
        }
    }

    &__gauge-background {
        &--small {
            border: 15px solid rgba($brand-purple, 0.3)
        }

        &--large {
            border: 30px solid rgba($brand-purple, 0.3)
        }
    }

    &__gauge {
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &--small {
            width: 40px;
            height: 40px;
        }

        &--large {
            width: 120px;
            height: 120px;
        }
    }

    &__level-num {
        z-index: 3;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;

        &--small {
            width: 25px;
            height: 25px;
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: -1px;
            box-sizing: border-box;
        }

        &--large {
            width: 64px;
            height: 64px;
            font-size: 40px;
            font-weight: 700;
            letter-spacing: -2px;
        }

        &--locked {
            color: $steel;
        }

        &--active {
            border: 0.5px solid $brand-purple;
        }

        &--active#{&}--small {
            padding-right: 0.5px;
        }

        &--active#{&}--large {
            border: 1px solid $brand-purple;
        }

        &--complete {
            width: 21px;
            height: 21px;
        }

        &--complete#{&}--large {
            padding-left: 4px;
            width: 48px;
            height: 48px;
        }

        &--locked#{&}--dark {
            color: $cloudy;
        }

        &--active#{&}--dark {
            color: $white;
        }
    }

    &__check {
        color: $cadaverous;
        width: 100%;
        height: 100%;

        &--small {
            width: 21px;
        }

        &--dark {
            color: $white;
        }
    }
}
</style>
