import { globalQuestionMetricModule } from '@/store/globalQuestionMetric/module'
import { quizModule } from '@/store/quiz/module'
import { isPromise } from '@/store/utils'

const getGlobalQuestionMetricsBySerial = () => {
    const metrics = globalQuestionMetricModule.state.globalQuestionMetricsBySerial.value
    if (isPromise(metrics)) {
        return null
    } else {
        return metrics
    }
}

const getExamCommunityAverage = () => {
    const metrics = globalQuestionMetricModule.state.globalQuestionMetricsBySerial.value
    if (isPromise(metrics)) {
        return 0
    } else {
        const { correctAnswers, totalAnswers } = Object.values(metrics).reduce((acc, metric) => {
            const newAcc = {
                correctAnswers: acc.correctAnswers + (metric.answeredCorrectlyCount || 0),
                totalAnswers: acc.totalAnswers + (metric.answeredCorrectlyCount || 0) 
                    + (metric.answeredIncorrectlyCount || 0),
            }
            return newAcc
        }, { correctAnswers: 0, totalAnswers: 0 })
        return Math.round((correctAnswers / totalAnswers) * 100)
    }
}

const getQuizCommunityAverage = (quizId: string) => {
    const metrics = globalQuestionMetricModule.state.globalQuestionMetricsBySerial.value
    if (isPromise(metrics) || !Object.values(metrics).length) {
        return null
    }

    const quiz = quizModule.getters.getQuizzes().find(q => q.objectId === quizId)
    if (!quiz) {
        return null
    }

    const quizMetrics = quiz.answers.map(ans => metrics[ans.questionSerial]).filter(m => m)
    const { correctAnswers, totalAnswers } = quizMetrics.reduce((acc, metric) => {
        const newAcc = {
            correctAnswers: acc.correctAnswers + (metric?.answeredCorrectlyCount || 0),
            totalAnswers: acc.totalAnswers + (metric?.answeredCorrectlyCount || 0) 
                + (metric?.answeredIncorrectlyCount || 0),
        }
        return newAcc
    }, { correctAnswers: 0, totalAnswers: 0 })
    return Math.round((correctAnswers / totalAnswers) * 100) || 0
}

export default {
    getGlobalQuestionMetricsBySerial,
    getExamCommunityAverage,
    getQuizCommunityAverage,
}
