<script setup lang="ts">
import { useOnOffTrackMessaging } from '@/store/readiness/composables'
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { computed } from 'vue'
const {
    ModalContainer,
    Modal,
    Button,
} = UIKit

const props = defineProps<{
    isUpdatingExamDate?: boolean
    isEligibleForSurvey?: boolean
    readinessScore: number
    percentQuestionBankComplete: number
    isActiveLast10Days: boolean
    daysUntilExam: number
}>()

const { status, summary, suggestion } = useOnOffTrackMessaging({
    readinessScore: props.readinessScore,
    percentQuestionBankCompleted: props.percentQuestionBankComplete,
    isActiveLast10Days: props.isActiveLast10Days,
    daysUntilExam: props.daysUntilExam,
})

const isDarkMode = computed(() => {
    return userModule.state.settings.isDarkMode
})

const emit = defineEmits<{
    close: []
    previous: []
    next: []
}>()
</script>

<template>
    <Portal to="modal">
        <ModalContainer
            :is-dark-mode="isDarkMode"
            @close="emit('close')"
        >
            <template #modal>
                <Modal
                    class="readiness-modal__modal"
                    :show-close-button="true"
                    :is-dark-mode="isDarkMode"
                    @close="emit('close')"
                >
                    <div class="readiness-modal" v-dark="isDarkMode">
                        <h1
                            ref="readiness-modal__title"
                            class="readiness-modal__title"
                            tabindex="-1"
                        >
                            Exam Readiness
                        </h1>
                        <div class="readiness-modal__readiness-status-container">
                            <div
                                class="readiness-modal__readiness-status-off-track"
                                :class="{
                                    'readiness-modal__readiness-status-off-track--active': status === 'off-track'
                                }"
                                v-dark
                            >
                                Off Track
                            </div>
                            <div
                                class="readiness-modal__readiness-status-on-track"
                                :class="{
                                    'readiness-modal__readiness-status-on-track--active': status === 'on-track'
                                }"
                                v-dark
                            >
                                On Track
                            </div>
                        </div>
                        <div class="readiness-modal__score-explanation" v-dark>
                            {{ summary }}
                        </div>
                        <div class="readiness-modal__suggestion" v-dark>
                            {{ suggestion }}
                        </div>
                        <div class=readiness-modal__nav-btns-container>
                            <Button
                                v-if="isUpdatingExamDate"
                                type="secondary"
                                :is-dark-mode="isDarkMode"
                                @click="emit('previous')"
                            >
                                Previous
                            </Button>
                            <Button
                                v-if="isEligibleForSurvey"
                                :is-dark-mode="isDarkMode"
                                @click="emit('next')"
                            >
                                Next
                            </Button>
                            <Button
                                v-else
                                :is-dark-mode="isDarkMode"
                                @click="emit('close')"
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<style lang="scss" scoped>
.readiness-modal {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px 40px 24px;
    width: 492px;
    margin: 0 auto;

    @include breakpoint(black-bear) {
            top: 132px;
            max-width: 340px;
        }

    &__modal {
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        margin: 0 0 24px 0;
        outline: none;
        letter-spacing: 0.3px;
    }

    &__readiness-status-container {
        width: 100%;
        display: flex;
        gap: 8px;
        margin-bottom: 24px;
    }

    &__readiness-status-off-track,
    &__readiness-status-on-track {
        flex-grow: 1;
        height: 47px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $barely-background;
        border: 0.5px solid rgba($pewter, 0.85);
        border-radius: 5px;
        font-size: 16.5px;
        line-height: 23px;
        letter-spacing: -0.1px;
        color: $steel;
        box-sizing: border-box;

        &--dark {
            background-color: $brand-black;
            border-color: $slate;
            color: $pewter;
        }
    }

    &__readiness-status-off-track {
        &--active {
            background-color: $manilla;
            border: 2px solid $banana-bread;
            color: $brand-black;

            &--dark {
                background-color: rgba($cheddar, 0.2);
                border-color: rgba($cheddar, 0.4);
                color: $white;
            }
        }
    }

    &__readiness-status-on-track {
        &--active {
            background-color: $barely-green;
            border: 2px solid $cadaverous;
            color: $brand-black;

            &--dark {
                background-color: rgba($green, 0.2);
                border-color: rgba($brand-green, 0.4);
                color: $white;
            }
        }
    }

    &__score-explanation {
        padding: 0 16px;
        color: $ash;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 12px;

        &--dark {
            color: $fog;
        }

        @include breakpoint(black-bear) {
            padding: 0;
        }
    }

    &__suggestion {
        padding: 0 16px;
        color: $ash;
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 32px;

        &--dark {
            color: $fog;
        }

        @include breakpoint(black-bear) {
            padding: 0;
            margin-bottom: 32px;
        }
    }

    &__nav-btns-container {
        display: flex;
        gap: 8px;
    }
}
</style>
