<!-- eslint-disable -->
<template>
    <svg width="187px" height="201px" viewBox="0 0 187 201" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title></title>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M6.99416856,0.234713641 C22.1154103,-2.94521711 41.6851431,4.14436325 65.8646315,24.3130882 C126.651897,75.0172891 166.061278,126.565669 186.839561,179.999372 L7,180 Z" fill="#FFF2D9" transform="translate(97.000000, 89.829114) scale(-1, -1) translate(-97.000000, -89.829114) "></path>
            <path d="M82.9587454,201.003087 L72,201 L71.9964186,6.02804555 C86.3350767,2.72086416 104.186318,0.641736396 126.368744,0.0620924878 C249.953185,-3.16726427 156.756726,120.294691 82.9587454,201.003087 Z" fill="url(#halftone-blue)" opacity="0.8" transform="translate(129.348101, 100.500000) scale(-1, -1) translate(-129.348101, -100.500000) "></path>
        </g>
    </svg>
</template>
<!-- eslint-enable -->

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class PurchaseBlob extends Vue {

}
</script>