import { reactive } from 'vue'
import actions from '@/store/quiz/actions'
import state from '@/store/quiz/state'
import getters from '@/store/quiz/getters'

export const quizModule = {
    state: reactive(state),
    actions,
    getters,
}
