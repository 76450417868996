<template>
    <div class="choose-plan">
        <PurchaseBlob v-if="!validReferral" class="choose-plan__blob" />
        <div class="choose-plan__info">
            Study on the go, when and how you want
        </div>
        <Plans
            :exam="exam"
            class="choose-plan__plans"
            :bundle="bundle"
            :saved-selected-plan-id="savedSelectedPlanId"
            :valid-referral="validReferral"
            :referral-error="referralError"
            @confirmPlan="handleConfirmPlan"
        />
        <ConfidenceLevel
            class="choose-plan__confidence-level"
            :show-compare-view="showCompareView"
            :exam="exam"
            @toggleCompareView="toggleCompareView"
        />
        <Compare
            class="choose-plan__compare"
            :bundle="bundle"
            :exam="exam"
            :show-compare-view="showCompareView"
        />
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import type { Study } from '@pocketprep/types'
import UIKit from '@pocketprep/ui-kit'
import Plans from '@/components/Register/ChoosePlan/Plans.vue'
import ConfidenceLevel from '@/components/Register/ChoosePlan/ConfidenceLevel.vue'
import Compare from '@/components/Register/ChoosePlan/Compare.vue'
import PurchaseBlob from '@/assets/onboarding/PurchaseBlob.vue'
import { analyticsModule } from '@/store/analytics/module'
import { screenModule } from '@/store/screen/module'
import { userModule } from '@/store/user/module'

@Component({
    components: {
        Icon: UIKit.Icon,
        PurchaseBlob,
        PocketButton: UIKit.Button,
        Plans,
        ConfidenceLevel,
        Compare,
    },
})
export default class ChoosePlan extends Vue {
    @Prop() exam!: Study.Class.ExamMetadataJSON
    @Prop() bundle!: Study.Class.BundleJSON
    @Prop() savedSelectedPlanId!: string
    @Prop({ default: false }) validReferral!: boolean
    @Prop({ default: null }) referralError!: string | null

    showCompareView = false

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get hasExpandedCompareTable () {
        return userModule.state.user?.webConfig?.hasViewedCompareTable
    }

    async mounted () {
        await userModule.actions.fetchUserData()
    }

    async toggleCompareView () {
        if (!this.hasExpandedCompareTable) {
            // check if the user has seen the compare before
            await userModule.actions.updateWebConfig({
                hasViewedCompareTable: true,
            })

            await analyticsModule.actions.trackEvent('Choose_Plan_Table_Expanded')
        }

        this.showCompareView = !this.showCompareView
        if (this.showCompareView) {
            setTimeout(() => {
                const compareEl = document.querySelector('.choose-plan__compare')
                compareEl?.scrollIntoView({ behavior: 'smooth' })
            }, 200)
        }

        if (!this.showCompareView) {
            const blobEl = document.querySelector('.choose-plan__blob')
            blobEl?.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
    }

    handleConfirmPlan (planId: string) {
        this.emitConfirmPlan(planId)
    }

    @Emit('confirmPlan')
    emitConfirmPlan (planId: string) {
        return planId
    }
}
</script>
<style lang="scss" scoped>
.choose-plan {
    @include breakpoint(brown-bear) {
        width: 100%;
    }

    &__blob {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -2;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__info {
        color: $slate-03;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        margin-bottom: 44px;
        margin-top: 6px;

        @include breakpoint(black-bear) {
            margin-bottom: 24px;
        }
    }

    &__compare {
        color: $brand-blue;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        font-weight: 600;
        cursor: pointer;
        user-select: none;
        width: 303px;
        position: relative;
        z-index: 1;

        @include breakpoint (brown-bear) {
           margin: 12px auto 32px;
        }
    }

    &__compare-panel {
        @include breakpoint (brown-bear) {
            margin-bottom: 34px;
        }
    }

    &__compare-arrow {
        width: 9px;
        margin-left: 2px;
        display: none;

        @include breakpoint (brown-bear) {
            display: inline-block;
        }

        &--open {
            transform: rotate(180deg);
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
    }

    &__try-free-btn {
        margin-right: 8px;
    }

    &__purchase-btn-arrow {
        width: 12px;
        margin-top: 4px;
        margin-left: 14px;
    }
}
</style>