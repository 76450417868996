<template>
    <Portal to="modal">
        <ModalContainer
            key="addExamRequiredModal"
            :is-dark-mode="isDarkMode"
        >
            <template #modal>
                <Modal
                    class="delete-requested"
                >
                    <div class="delete-requested__title">
                        Account Pending Deletion
                    </div>
                    <div class="delete-requested__message">
                        Please allow for 2 weeks for your account data to be erased from our system. To cancel this
                        deletion, sign in again within the next 2 weeks.
                    </div>
                    <div class="delete-requested__modal-buttons">
                        <PocketButton
                            class="delete-requested__modal-button"
                            type="secondary"
                            :is-dark-mode="isDarkMode"
                            :disabled="isLoading"
                            @click="cancelDeletion"
                        >
                            Cancel deletion
                        </PocketButton>
                        <PocketButton
                            class="delete-requested__modal-button"
                            :is-dark-mode="isDarkMode"
                            :disabled="isLoading"
                            @click="signOut"
                        >
                            I understand
                        </PocketButton>
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import { Vue, Component } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
        PocketButton: UIKit.Button,
    },
})
export default class DeleteRequestedModal extends Vue {
    isLoading = false

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    async cancelDeletion () {
        this.isLoading = true
        await userModule.actions.cancelDeleteUser()
    }

    async signOut () {
        this.isLoading = true
        await userModule.actions.signOut()
        this.$router.push({ name: 'sign-in' })
    }
}
</script>

<style lang="scss" scoped>
.delete-requested {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    top: 80px;
    width: 392px;
    padding: 35px 0 26px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakpoint(black-bear) {
        width: 356px;
        height: auto;
        max-height: 638px;
        top: 10px;
    }

    &__title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        width: 309px;
        margin: 0 auto 12px;
    }

    &__message {
        font-size: 15px;
        line-height: 22px;
        color: $slate-03;
        width: 309px;
        margin-bottom: 29px;
    }

    &__modal-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__modal-button {
        margin: 0 6px;
    }
}
</style>