import { isPromise } from '@/store/utils'
import { keywordsModule } from '@/store/keywords/module'

const getKeywordDefinitions = () => {
    const keywordDefinitions = keywordsModule.state.keywordDefinitions.value

    if (isPromise(keywordDefinitions)) {
        return new Map<string, Map<string, string>>()
    } else {
        return keywordDefinitions
    }
}

export default {
    getKeywordDefinitions,
}