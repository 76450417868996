<template>
    <div v-if="!isLoading" class="select-mock-exam">
        <div class="select-mock-exam__summary-text">
            We offer multiple mock exams for the {{ currentExamName }} exam.
            Please select which exam you'd like to take.
        </div>
        <div v-dark class="select-mock-exam__summary-subtext">
            You can take each mock exam an unlimited number of times.
            Your progress will reset and questions will reshuffle for every attempt.
        </div>
        <div class="select-mock-exam__mock-exam-choices">
            <div
                v-for="mockExam in mockExams"
                :key="mockExam.objectId"
                v-dark
                tabindex="0"
                role="radio"
                :aria-checked="mockExam.objectId === selectedMockExamId ? 'true' : 'false'"
                class="select-mock-exam__mock-exam-choice"
                :class="{
                    'select-mock-exam__mock-exam-choice--selected': mockExam.objectId === selectedMockExamId,
                }"
                @click="handleMockExamClick(mockExam.objectId)"
                @keydown.enter="handleMockExamClick(mockExam.objectId)"
                @keydown.space="handleMockExamClick(mockExam.objectId)"
            >
                {{ mockExam.name }}
                <div
                    v-if="mockExam.lastTakenAt"
                    v-dark
                    class="select-mock-exam__last-taken-date"
                >
                    Taken {{ formatLastTakenDate(mockExam.lastTakenAt) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import type { TMappedMockExam } from '@/components/MockExamIntro.vue'
import { analyticsModule } from '@/store/analytics/module'
import { months } from '@/utils'
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'

@Component
export default class SelectMockExam extends Vue {
    @Prop() mockExams!: TMappedMockExam[]
    @Prop() currentExamName!: string
    @Prop() selectedMockExamId!: string

    isLoading = true

    async mounted () {
        await analyticsModule.actions.trackEvent('Mock_Exam_Choice_Viewed')

        if (this.mockExams && this.mockExams.length) {
            this.mockExams.sort((a, b) => a.name.localeCompare(b.name))
        }

        this.isLoading = false
    }

    handleMockExamClick (mockExamId: string) {
        this.emitSelectMockExam(mockExamId)
    }

    formatLastTakenDate (date: Date) {
        const month = months[date.getMonth()]
        const day = date.getDate()
        const year = date.getFullYear()
        const currentYear = new Date().getFullYear()
        return `${month} ${day}${ year !== currentYear ? `, ${ year }` : '' }`
    }

    @Emit('selectMockExam')
    emitSelectMockExam (mockExamId: string) {
        return mockExamId
    }
}
</script>

<style lang="scss" scoped>
.select-mock-exam {
    width: 460px;

    @include breakpoint(black-bear) {
        width: 308px;
    }

    &__summary-text {
        font-weight: 600;
        font-size: 16px;
        letter-spacing: -0.1px;
        line-height: 24px;
        margin-bottom: 8px;

        @include breakpoint(black-bear) {
            margin-bottom: 10px;
        }
    }

    &__summary-subtext {
        font-size: 16px;
        letter-spacing: -0.1px;
        line-height: 24px;
        margin-bottom: 36px;

        &--dark {
            color: $fog;
        }

        @include breakpoint(black-bear) {
            margin-bottom: 24px;
        }
    }

    &__mock-exam-choices {
        position: relative;
        left: -15px;
        width: 475px;
        max-width: 100%;

        @include breakpoint(brown-bear) {
            left: -13px;
            width: 474px;
        }

        @include breakpoint(black-bear) {
            left: -15px;
        }
    }

    &__mock-exam-choice {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 15px;
        padding-right: 16px;
        background-color: $white;
        border: 1px solid rgba($pewter, 0.85);
        border-radius: 5px;
        box-shadow: 0 1px 4px 0 rgba($ash, 0.3);
        height: 50px;
        margin-bottom: 9px;
        font-size: 16px;
        letter-spacing: -0.1px;
        line-height: 22px;
        cursor: pointer;
        box-sizing: border-box;

        &--dark {
            background-color: $brand-black;
            color: $white;
            border-color: $slate;
        }

        &:hover:not(&--selected) {
            border: 2px solid $slate;
            padding-left: 14px;

            &#{&}--dark {
                border-color: $fog;
            }

            .mock-exam-intro__last-taken-date {
                right: 15px;
            }
        }

        &--selected {
            border: 2px solid $brand-blue;
            padding-left: 14px;

            &#{&}--dark {
                border-color: $banana-bread;
            }

            .mock-exam-intro__last-taken-date {
                right: 15px;
            }
        }

        @include breakpoint(black-bear) {
            width: 338px;
        }
    }

    &__last-taken-date {
        position: absolute;
        right: 16px;
        font-size: 14px;
        letter-spacing: -0.3px;
        line-height: 17px;
        color: $slate;

        &--dark {
            color: $fog;
        }
    }
}
</style>
