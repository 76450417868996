<template>
    <div class="referral">
        <div class="referral__legal">
            <Checkbox
                v-model="agreed"
                class="referral__legal-checkbox"
                :is-dark-mode="isDarkMode"
                aria-label="I agree to the Referral Terms of service, required"
            />
            <div
                v-dark 
                class="referral__legal-agreement"
            >
                I agree to the
                <a
                    v-dark
                    target="_blank"
                    :href="`https://www.pocketprep.com/referral-program?${buildUTMParamString({
                        utm_campaign: 'app_settings',
                        utm_content: utmContent,
                    })}`"
                    class="referral__legal-text-link"
                >Referral Program Terms and Conditions</a>
            </div>
        </div>
        <div 
            v-dark
            class="referral__subtext"
        >
            Renews automatically at {{ plan && plan.label || '' }}.<br>Taxes not included. Cancel anytime.
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import { buildUTMParamString } from '@/store/utils'

@Component({
    components: {
        Checkbox: UIKit.Checkbox,
    },
})
export default class ReferralTerms extends Vue {
    @Prop() plan!: null | { value: string; label: string }
    @Prop({ default: 'referrals_terms' }) utmContent!: string

    agreed = false

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    buildUTMParamString = buildUTMParamString

    @Watch('agreed')
    agreedValChanged (val: boolean){
        this.emitAgreedChanged(val)
    }

    @Emit('agreedChanged')
    emitAgreedChanged (agreed: boolean) {
        return agreed
    }
}
</script>

<style lang="scss" scoped>
.referral {
    &__legal {
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 30px;
        color: $ash;
        justify-content: center;

        &-agreement {
            font-weight: 500;
            color: $ash;

            &--dark {
                color: $cloudy;
            }
        }
    }

    &__legal-checkbox {
        margin-right: 9px;
        vertical-align: middle;
        margin-bottom: 2px;

        &--dark {
            color: $banana-bread;
        }
    }

    &__legal-text-link {
        color: $brand-blue;
        text-decoration: none;
        text-align: left;

        &--dark {
            color: $banana-bread;
        }
    }

    &__subtext {
        font-size: 13px;
        font-weight: 500;
        color: $ash;
        line-height: 16px;
        text-align: center;

        &--dark {
            color: $cloudy;
        }
    }
}
</style>