<!-- eslint-disable -->
<template>
    <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Twitter icon</title>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-273.000000, 0.000000)" fill="currentColor">
                <g transform="translate(191.000000, 0.000000)">
                    <path d="M96,2.20268248e-13 C103.731986,2.20268248e-13 110,6.2680135 110,14 C110,21.7319865 103.731986,28 96,28 C88.2680135,28 82,21.7319865 82,14 C82,6.2680135 88.2680135,2.20268248e-13 96,2.20268248e-13 Z M98.8882326,8.90909091 C97.3743617,8.90909091 96.1475772,10.2037871 96.1475772,11.8004412 C96.1475772,12.0271165 96.1703245,12.2480007 96.2173878,12.4589576 C93.939522,12.3381745 91.9197202,11.1882526 90.5666491,9.43772406 C90.330548,9.86625609 90.195633,10.3626252 90.195633,10.8920857 C90.195633,11.8947514 90.6796011,12.779943 91.415358,13.2994761 C90.9659031,13.284585 90.5431174,13.1530472 90.1728858,12.9379539 L90.1728858,12.973527 C90.1728858,14.3749425 91.1172902,15.5438919 92.3730971,15.8086221 C92.1424867,15.8764592 91.9008948,15.9103778 91.6498903,15.9103778 C91.4734028,15.9103778 91.3008372,15.8930049 91.1345467,15.8590863 C91.4828155,17.0073536 92.4954617,17.8445629 93.695577,17.8668995 C92.7566633,18.64289 91.574589,19.1045133 90.2905441,19.1045133 C90.0693464,19.1045133 89.8505018,19.0921041 89.6363636,19.0648038 C90.8498135,19.8846401 92.2907362,20.3636364 93.8391202,20.3636364 C98.8827418,20.3636364 101.639085,15.9575329 101.639085,12.1363177 C101.639085,12.0105708 101.637516,11.8856513 101.632025,11.7623862 C102.167763,11.3545363 102.63369,10.8449306 103,10.265006 C102.508188,10.4949903 101.97951,10.6505193 101.425731,10.720011 C101.99206,10.3626252 102.426612,9.79593713 102.630553,9.12087508 C102.100306,9.45261513 101.514367,9.69252689 100.889209,9.82241015 C100.390338,9.25985844 99.677328,8.90909091 98.8882326,8.90909091 Z"></path>
                </g>
            </g>
        </g>
    </svg>
</template>
<!-- eslint-enable -->

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class Twitter extends Vue {

}
</script>