import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'

export const uniqBy = <T>(fn: (item: T) => unknown, list: T[]) => {
    const set = new Set()
    const result = []
    let idx = 0
    let appliedItem, item

    while (idx < list.length) {
        item = list[idx]
        appliedItem = fn(item)
        if (!set.has(appliedItem)) {
            set.add(appliedItem)
            result.push(item)
        }
        idx += 1
    }
    return result
}

export const difference = <T>(first: T[], second: T[]) => {
    const out = []
    let idx = 0
    const firstLen = first.length
    const secondSet = new Set(second)

    while (idx < firstLen) {
        if (!secondSet.has(first[idx])) {
            secondSet.add(first[idx])
            out.push(first[idx])
        }
        idx += 1
    }
    return out
}

export const isValidEmail = (email?: string) => {
    return email && email.match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}/i)
}

export const timeOfDay = (): 'midnight' | 'early' | 'morning' | 'afternoon' | 'evening' | 'midnight' => {
    const hour = new Date().getHours()
    const minute = new Date().getMinutes()

    if (hour <= 4 && minute < 30) {
        return 'midnight'
    } else if (hour < 7) {
        return 'early'
    } else if (hour < 12) {
        return 'morning'
    } else if (hour < 17) {
        return 'afternoon'
    } else if (hour < 23) {
        return 'evening'
    } else {
        return 'midnight'
    }
}

export const localISODateString = (): string => {
    // we still have IE users, so avoiding padStart and repeat fcns
    // NOTE: Array() inserts between array elements, need + 1
    const zeroPadded = (num: number, digits = 2) : string =>
        num.toString().length < digits + 1
            ?  Array(digits + 1 - num.toString().length).join('0') + `${num}`
            : `${num}`
    const now = new Date()
    const tzHours = Math.floor(Math.abs(now.getTimezoneOffset() / 60))
    const tzMins = Math.abs(now.getTimezoneOffset() % 60)

    return `${now.getFullYear()}`
     + '-' + zeroPadded(now.getMonth() + 1) // January = 0
     + '-' + zeroPadded(now.getDate())
     + 'T' + zeroPadded(now.getHours())
     + ':' + zeroPadded(now.getMinutes())
     + ':' + zeroPadded(now.getSeconds())
     + '.' + zeroPadded(now.getMilliseconds(), 3)
     + ((now.getTimezoneOffset() > 0) ? '-' : '+')        // offset is GMT - local time so flip sign
     + zeroPadded(tzHours)
     + ':' + zeroPadded(tzMins)
}

export const getScoreRange = (score: number) => {
    if (score <= 50) {
        return 'low'
    } else if (score < 75) {
        return 'medium'
    } else {
        return 'high'
    }
}

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

export const copyText = (url: string) => {
    const shareInput = document.createElement('input')
    shareInput.setAttribute('class', 'qotd-social-links__copy-input')
    shareInput.setAttribute('style', 'position: absolute; left: -10000%; bottom: -10000%;')
    shareInput.value = url
    document.body.append(shareInput)
    shareInput.select()
    shareInput.setSelectionRange(0, 99999)
    document.execCommand('copy')
    shareInput.remove()
}

export const allowedQuestionsPerMinute = 10

export const getHoursMinutesSeconds = (durationSeconds: number) => {
    const hourSeconds = durationSeconds || 0
    const hours = Math.floor(hourSeconds / 3600)
    const minuteSeconds = hourSeconds % 3600
    const minutes = Math.floor(minuteSeconds / 60)
    const seconds = minuteSeconds % 60

    return {
        hours,
        minutes,
        seconds,
    }
}

export const getCookie = (cookieName: string) => {
    const cookieLib = document.cookie.split('; ').reduce((acc, cookie) => {
        const [ name, value ] = cookie.split('=')
        if (value && name) {
            acc[name] = value
        }
        return acc
    }, {} as Record<string, string>)
    return cookieLib[cookieName]
}

export const setCookie = ({ name, value }: {
    name: string
    value: string
}) => {
    const cookieDomain =  import.meta.env.VUE_APP_COOKIE_DOMAIN
    if (cookieDomain) {
        document.cookie = `${name}=${value}; domain=${cookieDomain}`
    } else {
        document.cookie = `${name}=${value}`
    }
}

export const clearCookie = (cookieName: string) => {
    const cookieDomain =  import.meta.env.VUE_APP_COOKIE_DOMAIN
    if (cookieDomain) {
        document.cookie = `${cookieName}=; domain=${cookieDomain}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
    } else {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
    }
}

export type TFilterOption = {
    id: string
    label: string
    enabled: boolean
}

export type TRow = {
    id: string
    source: string
    bundleId: string
    name: string
    nameSubtext: string
    planName: string
    planCost: string
    renewsExpired: string
    paymentPlatform: string
    paymentSubtext: string
    canEdit: boolean
    expired: boolean
}

export const environment = (() => {
    if (import.meta.env.VUE_APP_PARSE_URL) {
        if (import.meta.env.VUE_APP_PARSE_URL.includes('localhost')) {
            return 'localhost'
        } else if (import.meta.env.VUE_APP_PARSE_URL.includes('test')) {
            return 'test'
        } else if (import.meta.env.VUE_APP_PARSE_URL.includes('development')) {
            return 'development'
        } else if (import.meta.env.VUE_APP_PARSE_URL.includes('staging')) {
            return 'staging'
        }
    }
    return 'production'
})()

export const isSupportWindow = () => {
    return window.location.origin.includes('support')
}

export const stripeDisabledAppearance = (isDarkMode: boolean) => {
    return {
        variables: {
            colorBackground: isDarkMode ? BrandColors.charcoal : BrandColors.grayBackground,
            fontSizeBase: '16px',
            spacingGridRow: '19px',
            colorIcon: isDarkMode ? BrandColors.pewter : BrandColors.brandBlack,
            colorText: isDarkMode ? BrandColors.fog : BrandColors.slate,
            colorIconCardError: isDarkMode ? BrandColors.rosa : BrandColors.pepper,
            colorIconCardCvcError: isDarkMode ? BrandColors.rosa : BrandColors.pepper,
            colorDanger: isDarkMode ? BrandColors.rosa : BrandColors.pepper,
        },
        rules: {
            '.AccordionItem--selected': {
                color: isDarkMode ? BrandColors.bananaBread : BrandColors.brandBlue,

            },

            '.TabIcon': {
                paddingLeft: '10px',
            },

            '.Input': {
                backgroundColor: isDarkMode ? 'rgba(41, 62, 78, 0.50)' : 'rgba(255, 255, 255, .50)',
                border: isDarkMode ? 
                    '1px solid rgba(136, 163, 182, 0.50)' : '1px solid rgba(136, 163, 182, 0.43)',
                color: isDarkMode ? BrandColors.pewter : BrandColors.brandBlack,
            },

            '.Input::placeholder': {
                color: BrandColors.pewter,
                fontSize: '16px',
            },

            '.Input:focus' : {
                border: isDarkMode ? 
                    `1px solid ${BrandColors.bananaBread}` : `1px solid ${BrandColors.brandBlue}`,
                boxShadow: 'none',
            },

            '.Error' : {
                fontSize: '13px',
                margin: '8px 0 0 5px',
            },

            '.Label': {
                fontSize: '13px',
                margin: '0 0 6px 12px',
            },

            '.Label--invalid': {
                color: isDarkMode ? BrandColors.rosa : BrandColors.pepper,
            },

            '.TermsText': {
                color: isDarkMode ? BrandColors.fog : BrandColors.slate,
            },
        },
    }
}

export const stripeEnabledAppearance = (isDarkMode: boolean) => {
    return {
        variables: {
            colorBackground: isDarkMode ? BrandColors.charcoal : BrandColors.grayBackground,
            fontSizeBase: '16px',
            spacingGridRow: '19px',
            colorIcon: isDarkMode ? BrandColors.pewter : BrandColors.brandBlack,
            colorText: isDarkMode ? BrandColors.fog : BrandColors.slate,
            colorIconCardError: isDarkMode ? BrandColors.rosa : BrandColors.pepper,
            colorIconCardCvcError: isDarkMode ? BrandColors.rosa : BrandColors.pepper,
            colorDanger: isDarkMode ? BrandColors.rosa : BrandColors.pepper,
        },
        rules: {
            '.AccordionItem--selected': {
                color: isDarkMode ? BrandColors.bananaBread : BrandColors.brandBlue,

            },

            '.TabIcon': {
                paddingLeft: '10px',
            },

            '.Input': {
                backgroundColor: isDarkMode ? BrandColors.moonlitOcean : BrandColors.white,
                border: isDarkMode ? 
                    `1px solid ${BrandColors.pewter}` : '1px solid rgba(136, 163, 182, 0.85)',
                color: isDarkMode ? BrandColors.white : BrandColors.brandBlack,
            },

            '.Input::placeholder': {
                color: isDarkMode ? BrandColors.pewter : BrandColors.slate,
                fontSize: '16px',
            },

            '.Input:focus' : {
                border: isDarkMode ? 
                    `1px solid ${BrandColors.bananaBread}` : `1px solid ${BrandColors.brandBlue}`,
                boxShadow: 'none',
            },

            '.Error' : {
                fontSize: '13px',
                margin: '8px 0 0 5px',
            },

            '.Label': {
                fontSize: '13px',
                margin: '0 0 6px 12px',
            },

            '.Label--invalid': {
                color: isDarkMode ? BrandColors.rosa : BrandColors.pepper,
            },

            '.TermsText': {
                color: isDarkMode ? BrandColors.fog : BrandColors.slate,
            },
        },
    }
}

export const formatDate = (date: Date, interval: string, intervalCount: number) => {
    const initialDate = date.getDate()
    if (interval === 'year') {
        date.setFullYear(date.getFullYear() + intervalCount)
    } else if (interval === 'month') {
        date.setMonth(date.getMonth() + intervalCount)
    }

    if (date.getDate() !== initialDate) { // If the dates don't match, it means we rolled into the next month
        date.setDate(0) // Setting the date to 0 changes the date to the last day of the previous month
    }

    const newDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear()
    return newDate ? newDate : null
}

export const getStartOfWeek = (date: Date) => {
    const startOfWeek = new Date(date)  // Need to mutate this to the start of the week
    startOfWeek.setDate(date.getDate() - date.getDay())    // Shift to Sunday
    startOfWeek.setHours(0,0,0,0)   // Shift to midnight
    return startOfWeek
}

// Map quiz string types to numeric types
export type TMode = 'qotd' | 'quick10' | 'missed' | 'weakest' | 'timed' | 'mockExam' | 'levelUp' | 'custom' 
| 'assignment'
export const getNumericQuizMode = (mode: TMode) => {
    const quizModeMap = {
        'qotd': '-1',
        'quick10': '0',
        'missed': '2',
        'weakest': '3',
        'timed': '4',
        'mockExam': '5',
        'levelUp': '6',
        'custom': '10',
        'assignment': '11',
    }

    return quizModeMap[mode] || 'unknown'
}

export const stripText = (string: string) => {
    return string.replace(/<[^\s>]+[^>]*>/gi, ' ').trim()
}