<template>
    <Portal to="modal">
        <div
            v-if="showKeywordDefinition && visibleKeywordDefinition"
            :style="{
                position: 'absolute',
                top: `${computedQuestionContainerRect?.top}px`,
                height: `${computedQuestionContainerRect?.height}px`,
                width: '100%',
                overflow: 'hidden'
            }"
        >
            <KeywordDefinition
                :keywordDOMElement="keywordDOMElement"
                :keywordClickLocation="keywordClickLocation"
                :keyword="visibleKeywordDefinition.keyword"
                :definition="visibleKeywordDefinition.definition"
                :questionContainer="questionContainer"
                :serial="question?.serial"
                :keywordLocation="visibleKeywordDefinition.location"
                :adjustBaseTopCoordinate="questionContainerRect?.top"
                :isMobile="true"
                @close="showKeywordDefinition = false"
            />
        </div>
    </Portal>
    <div v-dark class="qotd-container">
        <BonusQuestionSurvey
            v-if="showSurvey"
            :variant="bonusQuestionVariantValue === 'short-answer-question'
                ? 'shortAnswerQuestion'
                : 'explanationAnswerQuestion'
            "
            :bonusQText="bonusQuestionUserText"
            @submit="submitSurvey"
            @close="closeSurvey"
        />
        <Icon
            v-if="!question || isLoading"
            v-dark
            class="qotd-container__loading"
            type="loading2"
            :is-dark-mode="isDarkMode"
        />
        <BonusQuestionExperiment
            v-else-if="examMetadata && showBonusQuestion"
            :examGuid="examMetadata.examGuid"
            :question="bonusQuestion"
            :is-dark-mode="isDarkMode"
            :breakpoint-override="'black-bear'"
            @skip="skipBonusQuestion"
            @userAnswer="updateUserShortAnswer"
            @showSurvey="showSurvey = true"
        />
        <Question
            v-else-if="question && examMetadata && !showBonusQuestion"
            ref="qotdContainer"
            class="qotd-container__question"
            :class="{ 'qotd-container__question--correct': isCorrect }"
            :question="question"
            :previous-choices="previousChoices"
            :previous-matrix-choices="previousChoices"
            :initial-show-answers="showAnswers"
            :show-next-question="false"
            :show-check-answer="true"
            :global-metrics="!isCommunityStatsDisabled && showCommunityStats && globalMetric"
            :quiz-mode="'qotd'"
            :is-dark-mode="isDarkMode"
            :image-url-prefix="`${s3Url || ''}/${question.compositeKey.toUpperCase()}/`"
            :hide-references="examMetadata.hideReferences"
            :keyword-definitions="keywordDefinitions"
            :allow-keyboard-shortcuts="false"
            :auto-focus-prompt="null"
            @keywordClick="keywordClicked"
            @checkAnswer="submitQotD"
            @selectedChoices="selectChoices"
        >
            <template #closeIcon>
                <!-- Hide the close icon -->
                <div />
            </template>
            <template #promptExperiment>
                <BonusQuestionBanner
                    v-if="showUserExplanationField"
                    class="qotd-container__bonus-question-banner"
                    variant="explanation-answer-question"
                    :is-dark-mode="isDarkMode"
                />
            </template>
            <template #actionExperiment>
                <BonusQuestionTextarea
                    v-if="showUserExplanationField"
                    class="qotd-container__bonus-question-explanation-textarea"
                    v-model="userExplanation"
                    label="Why is this the correct answer?"
                    height="134px"
                    :disabled="!selectedChoices.length || showAnswers"
                    :is-dark-mode="isDarkMode"
                />
            </template>
        </Question>
        <div class="qotd-container__toolbar">
            <div
                class="qotd-container__people-icon"
                :class="{
                    'qotd-container__people-icon--active': showCommunityStats,
                    'qotd-container__people-icon--disabled': isCommunityStatsDisabled,
                }"
            >
                <GlobalMetricsToggle 
                    :show-global-metrics="showCommunityStats" 
                    :custom-tooltip-styles="!isDarkMode 
                        ? {
                            backgroundColor: 'rgba(31, 53, 69, 0.95)',
                            color: brandColors.white,
                            marginTop: '-9px'
                        }
                        : {
                            backgroundColor: brandColors.white,
                            color: brandColors.brandBlack,
                            marginTop: '-9px'
                        }"
                    :disabled="isCommunityStatsDisabled"
                    :is-dark-mode="isDarkMode"
                    @toggleGlobalMetrics="toggleCommunityStats"
                />
            </div>
            <div
                class="qotd-container__flag-toggle"
                :class="{
                    'qotd-container__flag-toggle--disabled': isFlagDisabled,
                }"
                :tabindex="isFlagDisabled ? -1 : 0"
                role="checkbox"
                :aria-checked="isFlagged ? 'true' : 'false'"
                aria-label="Flag Question"
                @mouseover="!isFlagDisabled && (showFlagTooltip = true)"
                @mouseleave="!isFlagDisabled && (showFlagTooltip = false)"
                @click="toggleFlagStatus"
                @keydown.enter.stop.prevent="toggleFlagStatus"
                @mousedown.prevent
                @focus="!isFlagDisabled && (showFlagTooltip = true)"
                @blur="!isFlagDisabled && (showFlagTooltip = false)"
            >
                <Tooltip
                    v-if="showFlagTooltip"
                    :key="`flag-tooltip_${isFlagged}`"
                    :is-dark-mode="isDarkMode"
                >
                    {{ isFlagged ? 'Unflag' : 'Flag' }}
                </Tooltip>
                <Icon
                    class="qotd-container__flag-icon"
                    :class="{
                        'qotd-container__flag-icon--active': isFlagged,
                    }"
                    :type="isFlagged ? 'flag-filled' : 'flag'"
                />
            </div>
            <div
                class="qotd-container__share-icon"
                :class="{
                    'qotd-container__share-icon--disabled': isShareDisabled,
                }"
            >
                <Tooltip v-if="!showLinkCopiedTooltip && showShareTooltip" :is-dark-mode="isDarkMode">
                    Share Question Link
                </Tooltip>
                <Tooltip
                    v-if="showLinkCopiedTooltip"
                    :styles="{
                        backgroundColor: brandColors.green,
                        color: brandColors.brandBlack,
                    }"
                >
                    <span role="alert">Link Copied!</span>
                </Tooltip>
                <Icon
                    type="share"
                    :tabindex="isShareDisabled ? -1 : 0"
                    role="button"
                    aria-label="Share Question of the Day"
                    @mouseover="mouseOverShareIcon"
                    @mouseleave="!isShareDisabled && (showShareTooltip = false)"
                    @click="copyShareLink"
                    @keydown.enter="copyShareLink"
                    @mousedown.prevent
                    @focus="mouseOverShareIcon"
                    @blur="!isShareDisabled && (showShareTooltip = false)"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import UIKit from '@pocketprep/ui-kit'
import { Component, Vue, Watch } from 'vue-facing-decorator'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'
import type { Study } from '@pocketprep/types'
import { qotdModule } from '@/store/qotd/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { copyText } from '@/utils'
import { bundleModule } from '@/store/bundle/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { userModule } from '@/store/user/module'
import { analyticsModule } from '@/store/analytics/module'
/********* Start Bonus Question Experiment imports *********/
import BonusQuestionExperiment from '@/components/BonusQuestionExperiment/BonusQuestionExperiment.vue'
import BonusQuestionBanner from '@/components/BonusQuestionExperiment/BonusQuestionBanner.vue'
import BonusQuestionSurvey from '@/components/BonusQuestionExperiment/BonusQuestionSurvey.vue'
import BonusQuestionTextarea from '@/components/BonusQuestionExperiment/BonusQuestionTextarea.vue'
import { toastModule } from '@/store/toast/module'
/********* End Bonus Question Experiment imports *********/
import { keywordsModule } from '@/store/keywords/module'
import KeywordDefinition from '../KeywordDefinitions/KeywordDefinition.vue'

@Component({
    components: {
        Icon: UIKit.Icon,
        Tooltip: UIKit.Tooltip,
        Question: UIKit.Question,
        GlobalMetricsToggle: UIKit.GlobalMetricsToggle,
        /********* Start Bonus Question Experiment components *********/
        BonusQuestionExperiment,
        BonusQuestionBanner,
        BonusQuestionSurvey,
        BonusQuestionTextarea,
        /********* End Bonus Question Experiment components *********/
        KeywordDefinition,
    },
})
export default class QotDContainer extends Vue {
    isLoading = true
    isSubmitLoading = false
    showCommunityStatsTooltip = false
    showFlagTooltip = false
    showShareTooltip = false
    showLinkCopiedTooltip = false
    showCommunityStats = false
    isCommunityStatsDisabled = true
    isFlagged = false
    brandColors = BrandColors
    isCorrect = false
    clientVersion = import.meta.env.VUE_APP_VERSION
    /********* Start Bonus Question Experiment properties *********/
    disableExperiment = false
    showSurvey = false
    selectedChoices: Study.Cloud.TChoiceKey[] = []
    bonusQuestionVariantValue: 'short-answer-question' | 'explanation-answer-question' | null = null
    userShortAnswer = ''
    userExplanation = ''
    /********* End Bonus Question Experiment properties *********/
    showKeywordDefinition = false
    visibleKeywordDefinition: {
        keyword: string
        definition: string
        location: string
    } | null = null
    keywordDOMElement: HTMLElement | null = null
    keywordClickLocation: { x: number; y: number} | null = null
    hasSeenHighlightedKeyword = false
    questionContainerRect: DOMRect | null = null

    get keywordDefinitions () {
        const questionSerial = this.question?.serial
        const questionKeywords = questionSerial && keywordsModule.getters.getKeywordDefinitions().get(questionSerial)

        if (
            !this.showAnswers
            || !questionKeywords
        ) {
            return []
        }

        return Array.from(questionKeywords, ([ keyword, definition ]) => ({
            keyword,
            definition,
        }))
    }

    get questionContainer () {
        const questionContainerRef = this.$refs['qotdContainer'] as typeof UIKit.Question
        return questionContainerRef && '$el' in questionContainerRef ? questionContainerRef.$el as HTMLElement : null
    }

    get computedQuestionContainerRect () {
        return this.questionContainerRect
    }

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get sharing () {
        if (!this.bundle || !this.examMetadata) {
            return undefined
        }

        return qotdModule.getters.getQotDSharingInfo({
            bundleId: this.bundle.objectId,
            examGuid: this.examMetadata.examGuid,
            version: this.examMetadata.version,
            userId: userModule.state.user?.objectId || '',
            examName: this.examMetadata.nativeAppName,
        })
    }

    get bundle () {
        return bundleModule.getters.getCurrentBundle()
    }

    get examMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get showAnswers () {
        return !!this.quiz
    }

    get previousChoices () {
        return this.quiz?.answers[0]?.selectedChoices || []
    }

    get question () {
        return qotdModule.getters.getQotDQuestion()
    }

    get quiz () {
        return qotdModule.getters.getQotDQuiz()
    }

    get globalMetric () {
        return qotdModule.getters.getQotDMetric()
    }

    get flaggedQuestions () {
        return userExamMetadataModule.getters.getFlaggedQuestions()
    }

    get isFlaggedInStore () {
        const questionSerial = this.question?.serial
        return !!(questionSerial && this.flaggedQuestions.includes(questionSerial))
    }

    get s3Url () {
        return import.meta.env.VUE_APP_S3_URL
    }

    /********* Start Bonus Question Experiment Getters *********/
    get bonusQuestion () {
        const examGuid = this.examMetadata?.examGuid
        if (!examGuid) {
            return null
        }

        const examGuidQuestionLibString = import.meta.env.VUE_APP_BONUS_QUESTION_EXPERIMENT_EXAM_GUID_QUESTION_LIB
        const examGuidQuestionLib = JSON.parse(examGuidQuestionLibString || '{}') as {
            [examGuid: string]: {
                prompt: string
                answer: string
                explanation: string
                reference: string
            }
        }
        return examGuidQuestionLib[examGuid]
    }

    get isBonusQuestionExperimentActive () {
        // Check if current date is within experiment date range
        const currentDate = new Date()
        const dateRangeString = import.meta.env.VUE_APP_BONUS_QUESTION_EXPERIMENT_DATE_RANGE
        const dateRange = JSON.parse(dateRangeString || '[]') as [string, string]
        const [ startDate, endDate ] = dateRange.map(dateString => new Date(dateString))
        if (currentDate < startDate || currentDate > endDate) {
            return false
        }

        // Check if we have a current exam
        if (!this.examMetadata?.examGuid) {
            return false
        }

        // Check if we have a bonus experiment question for the current exam
        if (!this.bonusQuestion) {
            return false
        }

        return true
    }

    get showBonusQuestion () {
        if (this.disableExperiment) {
            return false
        }
        if (!this.bonusQuestion) {
            return false
        }
        
        return this.bonusQuestionVariantValue === 'short-answer-question'
    }

    get showUserExplanationField () {
        if (this.disableExperiment) {
            return false
        }

        return this.bonusQuestionVariantValue === 'explanation-answer-question'
    }

    get isFlagDisabled () {
        return this.showBonusQuestion
    }

    get isShareDisabled () {
        return this.showBonusQuestion
    }

    get bonusQuestionUserText () {
        return this.bonusQuestionVariantValue === 'short-answer-question'
            ? this.userShortAnswer
            : this.userExplanation
    }
    /********* End Bonus Question Experiment Getters *********/

    async mounted () {
        await Promise.all([
            qotdModule.actions.fetchCurrentQotDMetric(),
            examMetadataModule.actions.fetchExamMetadata(),
            bundleModule.actions.fetchBundles(),
            userModule.actions.fetchUserData(),
        ])

        // Setup Bonus Question Experiment
        if (this.isBonusQuestionExperimentActive) {
            const variant = await analyticsModule.actions.amplitudeFetchVariant('bonus-question-experiment')
            const variantValue = variant?.value as 'short-answer-question' | 'explanation-answer-question'
            this.bonusQuestionVariantValue = variantValue || null
            this.initializeBonusQuestionExperiment()
        }

        if (this.quiz && !this.showBonusQuestion) {
            this.isCommunityStatsDisabled = false
        }
        const questionSerial = this.question?.serial
        if (questionSerial && this.flaggedQuestions.includes(questionSerial)) {
            this.isFlagged = true
        }

        const studyContainer = document.getElementsByClassName('study__body')[0]
        studyContainer.addEventListener('scroll', this.updateQuestionContainerRect)

        // fetch keyword data
        const serial = this.question?.serial
        serial && await keywordsModule.actions.fetchKeywordDefinitions([ serial ])

        this.isLoading = false
    }

    beforeUnmount () {
        const studyContainer = document.getElementsByClassName('study__body')[0]
        studyContainer.removeEventListener('scroll', this.updateQuestionContainerRect)
    }

    updateQuestionContainerRect () {
        this.questionContainerRect = this.questionContainer?.getBoundingClientRect() || null
    }

    keywordClicked (params?: { 
        keyword: string
        location: string
        clickLocation: { x: number; y: number}
        target: HTMLElement
    }) {
        this.showKeywordDefinition = false
        if (!params?.keyword) {
            return
        }

        this.updateQuestionContainerRect()

        const questionKeywords = this.question
        && keywordsModule.getters.getKeywordDefinitions().get(this.question.serial)
        const definition = questionKeywords?.get(params.keyword.toLowerCase())

        if (!definition) {
            return
        }

        analyticsModule.actions.amplitudeTrack('Keyword Definition Viewed', {
            examGuid: this.examMetadata?.examGuid,
            definitionKeyword: params.keyword,
            serial: this.question?.serial,
            location: params.location,
            quizMode: -1,
            studyMode: 'Active Quiz',
        })

        this.visibleKeywordDefinition = {
            keyword: params.keyword, 
            definition,
            location: params.location,
        }
        this.keywordDOMElement = params.target
        this.keywordClickLocation = params.clickLocation

        this.$nextTick(() => {
            this.showKeywordDefinition = true
        })
    }

    async submitQotD (answer: Study.Cloud.IQuizAnswer) {
        // Prevent duplicate submission
        if (this.isSubmitLoading) {
            return
        }
        this.isSubmitLoading = true
        this.isCommunityStatsDisabled = false
        if (answer.isCorrect) {
            this.isCorrect = answer.isCorrect
        }
        // Store the bonus question experiment answer before submitting the quiz
        if (this.showUserExplanationField) {
            const examGuid = this.examMetadata?.examGuid
            localStorage.setItem(`bonusQuestionExperiment_${examGuid}_explanationAnswer`, this.userExplanation)
            analyticsModule.actions.amplitudeTrack('Bonus Question Answered', {
                bonusQuestionText: this.userExplanation,
                bonusQuestionVariation: 'explanationAnswerQuestion',
            })
        }
        await qotdModule.actions.recordQotD(answer)
        this.isSubmitLoading = false

        // Show the bonus question experiment survey 3 seconds after submitting the quiz
        if (this.showUserExplanationField) {
            setTimeout(() => {
                this.showSurvey = true
            }, 3000)
        }
    }

    toggleCommunityStats () {
        if (!this.isCommunityStatsDisabled) {
            this.showCommunityStats = !this.showCommunityStats
        }
    }

    async toggleFlagStatus () {
        if (this.isFlagDisabled) {
            return
        }

        const serial = this.question?.serial
        if (!serial) {
            throw new Error('Unable to flag question without a serial')
        }
        this.isFlagged = !this.isFlagged

        try {
            await userExamMetadataModule.actions.toggleQuestionFlag(serial)
        } catch (e) {
            // noop
        } finally {
            this.isFlagged = !!(serial && this.flaggedQuestions.includes(serial))
        }
    }

    mouseOverShareIcon () {
        if (this.isShareDisabled) {
            return
        }

        if (!this.showLinkCopiedTooltip) {
            this.showShareTooltip = true
        }
    }

    copyShareLink () {
        if (!this.sharing || this.isShareDisabled) {
            return
        }

        copyText(this.sharing.url)
        this.showShareTooltip = false
        this.$nextTick(() => {
            this.showLinkCopiedTooltip = true
            setTimeout(() => {
                this.showLinkCopiedTooltip = false
            }, 2000)
        })

        analyticsModule.actions.amplitudeTrack('QotD Shared', {
            bundleId: this.bundle?.objectId,
            client: 'Web Study',
            clientAppId: 'study.pocketprep.com',
            clientVersion: this.clientVersion,
            examGuid: this.examMetadata?.examGuid,
            organizationId: userModule.getters.getCurrentOrganizationId() || undefined,
            userDomain: userModule.getters.getUserDomain() || undefined,
        })
    }

    /********* Start Bonus Question Experiment Methods *********/
    selectChoices (answer: Study.Cloud.IQuizAnswer) {
        this.selectedChoices = answer.selectedChoices
    }

    initializeBonusQuestionExperiment () {
        const isBonusQuestionComplete = localStorage.getItem('bonusQuestionExperimentComplete')
        if (this.bonusQuestionVariantValue === 'explanation-answer-question') {
            const examGuid = this.examMetadata?.examGuid
            const localStorageAnswer = localStorage.getItem(`bonusQuestionExperiment_${examGuid}_explanationAnswer`)
            if (localStorageAnswer) {
                this.userExplanation = localStorageAnswer
                this.disableExperiment = false  // Enable to show user their previous answer
            } else if (localStorageAnswer === null && this.quiz) {
                this.disableExperiment = true   // Disable if qotd answered outside of experiment
            } else if (isBonusQuestionComplete) {
                this.disableExperiment = true   // Disable if already participated in experiment
            } else {
                this.disableExperiment = false  // Enable if qotd unanswered and experiment still incomplete
            }
        } else if (this.bonusQuestionVariantValue === 'short-answer-question') {
            const examGuid = this.examMetadata?.examGuid
            const localStorageAnswer = localStorage.getItem(`bonusQuestionExperiment_${examGuid}_shortAnswer`)
            if (localStorageAnswer === null && this.quiz) {
                this.disableExperiment = true   // Disable if qotd answered outside of experiment
            } else if (isBonusQuestionComplete) {
                this.disableExperiment = true   // Disable if already participated in experiment
            } else {
                this.disableExperiment = false  // Enable if qotd unanswered and experiment still incomplete
            }
        }
    }

    skipBonusQuestion () {
        localStorage.setItem('bonusQuestionExperimentComplete', 'true')
        this.disableExperiment = true
        this.isCommunityStatsDisabled = !this.quiz
    }

    updateUserShortAnswer (userAnswer: string) {
        this.userShortAnswer = userAnswer
    }

    submitSurvey () {
        this.showSurvey = false
        toastModule.actions.displayToast({
            title: 'Thank you for your feedback!',
        })
        this.completeBonusQuestionExperiment()
    }

    closeSurvey () {
        this.showSurvey = false
        this.completeBonusQuestionExperiment()
    }

    completeBonusQuestionExperiment () {
        localStorage.setItem('bonusQuestionExperimentComplete', 'true')
        if (this.bonusQuestionVariantValue === 'short-answer-question') {
            this.disableExperiment = true
        }
        this.isCommunityStatsDisabled = !this.quiz
    }
    /********* End Bonus Question Experiment Methods *********/

    // This helps update the component's flagged state when switching exams
    @Watch('isFlaggedInStore')
    isFlaggedInStoreChanged (isFlagged: QotDContainer['isFlaggedInStore']) {
        this.isFlagged = isFlagged
    }

    // This helps enable/disable the community stats button when switching exams
    @Watch('quiz')
    quizChanged (quiz: QotDContainer['quiz']) {
        this.showCommunityStats = false
        this.isCommunityStatsDisabled = !quiz || this.showBonusQuestion
        this.initializeBonusQuestionExperiment()
    }
}
</script>

<style lang="scss" scoped>
.uikit-question {
    &__context {
        margin-bottom: 28px;
    }

    &__context-icon {
        height: 19px;
    }
}

:deep(.uikit-question-choices-container__choice-container--correct p) {
    padding-right: 18px;
}

.qotd-container {
    box-sizing: border-box;
    width: 387px;
    height: 647px;
    border: 6px solid $white;
    border-radius: 10px;
    background-color: $gray-background;
    padding-bottom: 53px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &--dark {
        border-color: $jet;
        background-color: $charcoal;
        color: $gray-background;
    }

    &__loading {
        position: absolute;
        top: 25%;
        transform: translateY(-50%);
    }

    &__question {
        &--correct{
            :deep(.uikit-question-choices-container__choice-container--correct) {
                &::after {
                    @media (prefers-reduced-motion: reduce) {
                        animation: none;
                    }

                    content: '';
                    border: 2px solid $cadaverous;
                    @keyframes fadeIt {
                        0%  { box-shadow: 0px 0px 2px 2px rgba($cadaverous, 0.8); }
                        25%  { box-shadow: 0px 0px 4px 3px rgba($cadaverous, 0.6); }
                        50%  { box-shadow: 0px 0px 6px 5px rgba($cadaverous, 0.5); }
                        75%  { box-shadow: 0px 0px 8px 7px rgba($cadaverous, 0.4); }
                        90%  { box-shadow: 0px 0px 10px 8px rgba($cadaverous, 0.3); }
                        100%  { box-shadow: 0px 0px 11px 10px rgba($cadaverous, 0.2); }
                    }
                    animation: fadeIt .75s ease-in-out;
                }
            }
        }
    }

    &__toolbar {
        position: absolute;
        bottom: 0;
        background-color: $pickled-bluewood;
        border-radius: 0 0 6px 6px;
        width: 100%;
        height: 53px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: $white;
    }

    &__flag-toggle {
        position: relative;
        outline: none;
        display: flex;

        &:focus::before {
            content: '';
            position: absolute;
            border: 1px solid $butterscotch;
            width: 32px;
            height: 32px;
            top: 8px;
            left: 8px;
            border-radius: 4px;
            box-sizing: border-box;
        }

        @media (hover: hover) {
            .qotd-container__flag-icon:hover {
                color: $banana-bread;
            }
        }

        .qotd-container__flag-icon {
            cursor: pointer;

            &--active {
                color: $banana-bread;
            }
        }

        .uikit-tooltip {
            position: absolute;
            left: 50%;
            top: calc(-100% + 13px);
        }

        &--disabled {
            opacity: 0.35;
            
            .qotd-container__flag-icon {
                cursor: default;

                @media (hover: hover) {
                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }

    &__people-icon,
    &__share-icon {
        position: relative;
        cursor: pointer;
        height: 30px;
        display: flex;
        align-items: center;

        &:hover {
            color: $banana-bread;
        }

        &--active {
            color: $banana-bread;
        }

        svg:focus {
            outline: none;
            color: $banana-bread;
        }

        .uikit-tooltip {
            position: absolute;
            left: 50%;
            top: calc(-100% - 14px);
        }
    }

    &__people-icon {
        &--disabled {
            cursor: default;

            &:hover {
                color: $white;
            }
        }
    }

    &__share-icon {
        &--disabled {
            cursor: default;
            opacity: 0.35;

            &:hover {
                color: $white;
            }
        }
    }
}

</style>
