<template>
    <div
        class="study"
        tabindex="-1"
        :key="`${isDarkMode}`"
    >
        <SVGDefinitions />
        <PortalTarget name="modal" multiple />
        <PortalTarget name="sidepanel" multiple />
        <PortalTarget name="quizResultHeader" />
        <DeleteRequestedModal v-if="showDeleteRequestedModal" />
        <NameModal
            v-if="showNameModal"
            :org-name="orgName"
            @close="orgName = null"
        />
        <NewExamVersionModal
            v-if="!isLoading && showNewExamVersionModal && examVersions"
            :latest-exam-version="examVersions[0]"
            @close="showNewExamVersionModal = false"
            @reviewExamVersions="showNewExamVersionModal = false, showReviewNewVersions = true"
        />
        <ReviewNewVersionsSidePanel
            v-if="showReviewNewVersions && currentExamVersion"
            key="study"
            :passed-exam-versions="examVersions"
            :current-exam-version="currentExamVersion"
            :uem="userExamMetadata"
            :examMetadata="examMetadata"
            @close="showReviewNewVersions = false"
        />
        <AddExamSidePanel
            v-if="!currentExamMetadata
                && currentUser
                && !isLoading
                && $route.name !== 'register'
                && $route.name !== 'invite'
            "
            key="missingExamAddExamSidePanel"
            :show-full-height="true"
            @close="closeAddExamSidePanel"
        />
        <AddExamRequiredModal
            v-if="showAddExamRequiredModal"
            @close="showAddExamRequiredModal = false"
        />
        <SSOTransitionModal
            v-if="showSSOTransitionModal && !isLoading"
            :orgName="ssoOrgName"
            @close="showSSOTransitionModal = false"
        />
        <Head v-if="isHeadVisible()" />
        <div
            class="study__body"
            tabindex="-1"
            :style="{ 
                height: isHeadVisible() 
                    ? 'calc(100% - 54px)'
                    : ($route.name === 'quiz-result' && breakpoint !== 'black-bear')
                        ? 'calc(100% - 64px)'
                        : '100%'
            }"
        >
            <RouterView />
        </div>
        <Toast
            v-if="toast"
            :is-dark-mode="isDarkMode"
            @close="clearToast"
        >
            {{ toast.title }}
            <template v-if="toast.subtext" #subtext>
                {{ toast.subtext }}
            </template>
        </Toast>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator'
import Head from '@/components/Head.vue'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import { isHeadVisible } from '@/router'
import { toastModule } from '@/store/toast/module'
import NameModal from '@/components/NameModal.vue'
import NewVersionModal from '@/components/NewVersionModal.vue'
import { licenseModule } from '@/store/license/module'
import { screenModule } from '@/store/screen/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { bundleModule } from '@/store/bundle/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { mockExamModule } from '@/store/mockExam/module'
import type { Study as StudyTypes } from '@pocketprep/types'
import AddExamSidePanel from '@/components/Settings/AddExamSidePanel.vue'
import AddExamRequiredModal from '@/components/AddExamRequiredModal.vue'
import DeleteRequestedModal from '@/components/DeleteRequestedModal.vue'
import { quizModule } from '@/store/quiz/module'
import { getCookie, clearCookie } from '@/utils'
import * as Sentry from '@sentry/browser'
import NewExamVersionModal from '@/components/Study/NewExamVersionModal.vue'
import ReviewNewVersionsSidePanel from '@/components/Study/ReviewNewVersionsSidePanel.vue'
import SSOTransitionModal from '@/components/SSOTransitionModal.vue'

@Component({
    components: {
        Head,
        Toast: UIKit.Toast,
        SVGDefinitions: UIKit.SVGDefinitions,
        NameModal,
        NewVersionModal,
        AddExamSidePanel,
        AddExamRequiredModal,
        DeleteRequestedModal,
        NewExamVersionModal,
        ReviewNewVersionsSidePanel,
        SSOTransitionModal,
    },
})
export default class Study extends Vue {
    isHeadVisible = isHeadVisible
    orgName: string | null = null
    newExamVersion: StudyTypes.Class.ExamMetadataJSON | null = null
    showAddExamRequiredModal = false
    showNewExamVersionModal = false
    showReviewNewVersions = false
    examId = ''
    isLoading = true
    bodyClassObserver: MutationObserver | null = null
    showSSOTransitionModal = false
    ssoOrgName = ''

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get toast () {
        return toastModule.state.toast
    }

    get showNameModal () {
        return !!this.orgName && !userModule.state.user?.firstName && !userModule.state.user?.lastName
    }

    get showDeleteRequestedModal () {
        return !!userModule.state.user?.deleteRequestedDate
    }

    get currentUser () {
        return userModule.state.user
    }
    
    get currentExamMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get examMetadataById () {
        return examMetadataModule.getters.getExamMetadataById()
    }

    get examBundle () {
        return bundleModule.getters.getBundles().find(b => b.exams.find(e => e.objectId === this.examId))
    }

    get userExamMetadata () {
        return userExamMetadataModule.getters.getCurrentUserExamMetadata()
    }

    get examMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get examVersions () {
        if (!this.userExamMetadata || !this.userExamMetadata.examVersion) {
            return undefined
        }

        return examMetadataModule.getters.getExamMetadata()
            .filter(e =>
                e.examGuid === this.userExamMetadata?.examGuid 
                && (
                    this.examBundle?.exams.find(be => e.objectId === be.objectId)
                    || this.userExamMetadata.examVersion?.startsWith(`${e.version.split('.')[0]}.`)
                )
            )
            .sort((a, b) => 
                a.objectId === this.userExamMetadata?.objectId 
                    ? 1 
                    : -a.version.localeCompare(b.version, undefined, { numeric: true })
            )
    }

    get currentExamGuid () {
        return examMetadataModule.getters.getCurrentExamMetadata()?.objectId || ''
    }

    get currentExamVersion () {
        const examMajorVersion = this.userExamMetadata?.examVersion?.split('.')[0]

        if (!examMajorVersion || !this.examVersions) {
            return undefined
        }

        return this.examVersions.find(e => e.version.split('.')[0] === examMajorVersion)
    }

    async mounted () {
        const sessionCookieName = import.meta.env.VUE_APP_SESSION_COOKIE_NAME
        if (sessionCookieName) {           
            const newSessionToken = getCookie(sessionCookieName)

            if (newSessionToken) {
                await userModule.actions.signInWithCookie(newSessionToken)

                const hasSeenSSOTransitionModal = userModule.state.user?.webConfig?.hasSeenSSOTransitionModal
                if (!hasSeenSSOTransitionModal && typeof this.$route.query.ssoOrgName === 'string') {
                    this.showSSOTransitionModal = true
                    this.ssoOrgName = this.$route.query.ssoOrgName
                    userModule.actions.updateWebConfig({
                        hasSeenWelcomeModal: true,
                        hasSeenSSOTransitionModal: true,
                    })
                }

                clearCookie(sessionCookieName)
                if (this.$route.name !== 'study'){
                    if (typeof this.$route.query.redirect === 'string') {
                        const query = Object.fromEntries(new URLSearchParams(this.$route.query.redirect.split('?')[1]))
                        this.$router.push({ path: this.$route.query.redirect, query: query })
                    } else {
                        this.$router.push({ name: 'study', query: this.$route.query })
                    }
                }
            }
        }

        // make sure are calling this only if we have a current user
        if (this.currentUser) {
            await Promise.all([
                bundleModule.actions.fetchBundles(),
                examMetadataModule.actions.fetchExamMetadata(),
                userModule.actions.fetchUserData(),
                userExamMetadataModule.actions.fetchUserExamMetadata(),
            ])
        }

        // Listen for clearLocalStorage postMessage - for admin iframe support
        window.addEventListener('message', this.messageListener)

        // due to a difference in how iOS Safari 15+ handles "vh", we have to apply different height styles with newer
        // browsers. Once we have the majority of users on Safari 15+, we can remove this conditional code and apply the
        // vh styles universally. More here: https://lukechannings.com/blog/2021-06-09-does-safari-15-fix-the-vh-bug/
        const iOSVersion = navigator.userAgent.match(/iPhone OS ([0-9]{2})_/)
        const iOS15 = iOSVersion 
            && Number(iOSVersion[1] || 0) > 14
            && !navigator.userAgent.includes('CriOS')

        if (iOS15) {
            document.body.classList.add('ios-15')
        }

        this.examId = this.currentExamGuid

        await this.fetchUserDataAndUpdateOrgName()
        this.isDarkModeChanged(this.isDarkMode)

        this.checkIfNewExamVersion()
        await this.checkIfHasCurrentExamMetadata()

        // check for new major exam versions
        if (this.examVersions && this.examVersions.length > 1) {
            const examCurrentMajorVersion = this.examVersions[0].version.split('.')[0]
            const userCurrentMajorVersion = this.userExamMetadata?.examVersion?.split('.')[0] || '1'

            const hasSeenLatestVersion = this.userExamMetadata?.upgradesSeen?.includes(examCurrentMajorVersion)
            if ((userCurrentMajorVersion < examCurrentMajorVersion) && !hasSeenLatestVersion) {
                this.showNewExamVersionModal = true
            } 
        }

        this.isLoading = false

        // listen for the body class to change so we can toggle aria-hidden on study__body
        this.bodyClassObserver = new MutationObserver(this.toggleAriaHidden)
        this.bodyClassObserver.observe(document.body, { attributes: true })
        this.toggleAriaHidden()

        if (this.currentUser) {
            await quizModule.actions.fetchAnsweredQuestions()
        }

        await mockExamModule.actions.fetchMockExams()   // Used to need after leanplum, TODO: maybe could move up now

        if (this.$route.query.payment_intent && 
            this.$route.query.payment_intent_client_secret && 
            this.$route.query.redirect_status) {
            const stripeRedirectObj = {
                paymentIntent: this.$route.query.payment_intent,
                paymentIntentCS: this.$route.query.payment_intent_client_secret,
                redirectStatus: this.$route.query.redirect_status,
                user: this.currentUser,
            }
            Sentry.captureException(new Error(`Stripe redirect notice: ${stripeRedirectObj}`))
        }
    }

    beforeUnmount () {
        window.removeEventListener('message', this.messageListener)
        this.bodyClassObserver?.disconnect()
    }

    messageListener (event: MessageEvent) {
        if (event.data === 'clearLocalStorage') {
            localStorage.clear()
        }
    }

    toggleAriaHidden () {
        const bodyClasses = Array.from(document.body.classList)
        const bodyCovered = bodyClasses.find(c => 
            c === 'uikit-sidepanel-open' || 
            c === 'uikit-modal-open' ||
            c === 'mobile-menu-open'
        )
        const headCovered = bodyClasses.find(c => 
            c === 'byoq-modal-open' || 
            c === 'uikit-modal-open'
        )

        const studyBody = document.querySelector('.study__body')
        if (bodyCovered) {
            studyBody?.setAttribute('aria-hidden', 'true')
        } else {
            studyBody?.removeAttribute('aria-hidden')
        }

        const studyHead = document.querySelector('.head')
        if (headCovered) {
            studyHead?.setAttribute('aria-hidden', 'true')
        } else {
            studyHead?.removeAttribute('aria-hidden')
        }
    }

    closeAddExamSidePanel () {
        if (!this.currentExamMetadata) {
            this.showAddExamRequiredModal = true
        }
    }

    @Watch('currentUser')
    async fetchUserDataAndUpdateOrgName () {
        await userModule.actions.fetchUserData()
        this.orgName = await licenseModule.actions.fetchUsersLicenseOrgName() || null
    }

    @Watch('currentUser')
    async checkIfHasCurrentExamMetadata () {
        this.isLoading = true
        if (!userModule.state.user) {
            return
        }

        await Promise.all([
            examMetadataModule.actions.fetchExamMetadata(),
            bundleModule.actions.fetchBundles(),
            userExamMetadataModule.actions.fetchUserExamMetadata(),
        ])
        this.isLoading = false
    }

    @Watch('currentUser')
    async checkIfNewExamVersion () {
        if (!userModule.state.user) {
            return
        }

        await Promise.all([
            examMetadataModule.actions.fetchExamMetadata(),
            bundleModule.actions.fetchBundles(),
            userExamMetadataModule.actions.fetchUserExamMetadata(),
        ])
        const bundleExams = bundleModule.getters.getCurrentBundle()?.exams
        if (bundleExams) {
            this.newExamVersion = bundleExams.reduce<null | StudyTypes.Class.ExamMetadataJSON>(
                (acc, e) => {
                    const exam = this.examMetadataById[e.objectId] as StudyTypes.Class.ExamMetadataJSON

                    if (
                        exam 
                        && exam.examGuid === this.userExamMetadata?.examGuid 
                        && Number(exam.version.split('.')[0]) 
                            > Number(this.userExamMetadata?.examVersion?.split('.')[0] || 0)
                        && (
                            !this.userExamMetadata.upgradesSeen?.includes(exam.version.split('.')[0])
                            || !bundleExams.find(be => be.objectId === this.currentExamMetadata?.objectId)
                        )
                    ) {
                        return exam
                    } else {
                        return acc
                    }
                }, null)
        }
    }

    clearToast () {
        toastModule.actions.clearToast()
    }

    @Watch('isDarkMode')
    isDarkModeChanged (newVal: boolean) {
        const body = document.querySelector('body')
        if ((newVal && !body?.className.includes('dark')) || (!newVal && body?.className.includes('dark'))) {
            if (newVal) {
                body?.classList.add('dark')
            } else {
                body?.classList.remove('dark')
            }
        }
    }
}
</script>

<style lang="scss">
body,
html {
    height: 100%;
    width: 100%;
    overflow: hidden;

    @include breakpoint(black-bear) {
        overflow: scroll;
    }

    // as noted above, once most people are on iOS 15, we can make these styles universal
    &.ios-15 {
        .uikit-page-overlay {
            height: calc(100vh - env(safe-area-inset-bottom, 0px) - 10px) !important;
            max-height: calc(100vh - env(safe-area-inset-bottom, 0px) - 10px) !important;
        }

        .uikit-modal-container,
        .mobile-menu {
            height: calc(100vh - env(safe-area-inset-bottom, 0px)) !important;
            max-height: calc(100vh - env(safe-area-inset-bottom, 0px)) !important;
        }

        .uikit-quiz-container {
            @media screen and (-webkit-min-device-pixel-ratio: 0) {
                /* Safari only override */
                ::i-block-chrome,
                .flex-direction-nav-featured a {
                    height: calc(90vh - env(safe-area-inset-bottom, 0px)) !important;
                    max-height: calc(90vh - env(safe-area-inset-bottom, 0px)) !important;
                }
            }
        }
    }
}

body.dark {
    background: $charcoal;
}

// this disables scrolling on the study__body class when full-screen divs are open which helps prevent double
// scrolling on mobile devices (and probably other devices as well)
body.byoq-modal-open > .study > .study__body,
body.uikit-sidepanel-open > .study > .study__body,
body.uikit-modal-open > .study > .study__body,
body.mobile-menu-open > .study > .study__body {
    overflow: hidden !important;
}
</style>

<style lang="scss" scoped>
// @import '@pocketprep/ui-kit/styles/_breakpoints';

.study {
    height: 100%;

    &__body {
        box-sizing: border-box;
        overflow: auto;
        outline: none;

        @include breakpoint(black-bear) {
            overflow: initial;
            height: auto;
        }
    }
}
</style>
