<template>
    <div
        class="keyword-definition-banner"
        :class="{'keyword-definition-banner--mobile': isMobile}"
        v-dark
    >
        <ClickIcon
            v-if="!isMobile"
            :borderColor="isDarkMode ? brandColors.brandBlack : brandColors.white"
            type="activity"
            class="keyword-definition-banner__icon"
            v-dark
        />
        <div
            class="keyword-definition-banner__highlight-container"
            :class="{'keyword-definition-banner__highlight-container--mobile': isMobile}"
        >
            <div class="keyword-definition-banner__text">
                {{`${isMobile ? 'Tap' : 'Click'}`}}
            </div>
            <div class="keyword-definition-banner__highlight">
                <HighlightBlob class="keyword-definition-banner__highlight-blob" v-if="!isDarkMode"/>
                <span class="keyword-definition-banner__highlight-word" v-dark>highlighted</span>
            </div>
            <div class="keyword-definition-banner__text">
                words to see definitions.
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop } from 'vue-facing-decorator'
import ClickIcon from '@/assets/quiz/ClickIcon.vue'
import HighlightBlob from '@/assets/quiz/HighlightBlob.vue'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'

@Component({
    components: {
        Icon: UIKit.Icon,
        ClickIcon,
        HighlightBlob,
    },
})
export default class KeywordDefinitionBanner extends Vue {
    @Prop() isMobile?: boolean
    @Prop() isDarkMode?: boolean

    brandColors = BrandColors
}
</script>

<style lang="scss" scoped>
.keyword-definition-banner {
    height: 44px;
    width: 100%;
    background-color: $barely-blue;
    margin-top: 13px;
    margin-bottom: 30px;
    border-radius: 6px;
    border: 1px solid rgba($brand-blue, 0.4);
    padding: 12px 20px 12px 16px;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 20px;
    position: relative;

    @include breakpoint(black-bear) {
        width: 100%;
        height: auto;
        padding-top: 11px;
        padding-bottom: 11px;
    }

    &__highlight-container {
        position: relative;
        display: inline-block;
        padding-left: 20px;

        &--mobile {
            padding-left: 0px;
        }
    }

    &__highlight {
        display: inline-block;
    }

    &__highlight-word {
        position: relative;
        z-index: 1;
        display: inline-block;
        height: 20px;
        box-sizing: border-box;
        background-color: $manilla;
        border-bottom: 1px solid $banana-bread;
        border-radius: 2px 2px 0 0;
        padding: 0 2px;
        margin: 0 2.5px;

        &--dark {
            background-color: rgba($banana-bread, 0.3);
            border-color: rgba($banana-bread, 0.6);
        }
    }

    &__highlight-blob {
        position: absolute;
        height: 35px;
        top: -9px;
        left: 50px;
    }

    &__text {
        display: inline-block;
        position: relative;
        z-index: 1;
    }

    &__icon {
        position: absolute;
        top: 3px;
        left: 2px;

        &--dark {
            color: $manilla;
        }
    }

    &--mobile {
        padding-right: 0;
    }

    &--dark {
        color: $white;
        background-color: rgba($brand-blue, 0.2);
        border-color: rgba($baby-blue, 0.2);
    }
}
</style>