<template>
    <div class="quiz-error">
        <EmptyState
            class="quiz-error__empty-state"
            :title="errorMessage"
            :message="errorDescription"
            :is-dark-mode="isDarkMode"
            backgroundColor="white"
        >
            <template #icon>
                <Icon class="quiz-error__icon" type="link" />
            </template>
        </EmptyState>
        <PocketButton
            class="quiz-error__close"
            @click="closeQuiz"
            :is-dark-mode="isDarkMode"
        >
            Close Quiz
        </PocketButton>
    </div>
</template>

<script lang="ts">
import { Emit, Component, Vue, Prop } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'

@Component({
    components: {
        Icon: UIKit.Icon,
        PocketButton: UIKit.Button,
        EmptyState: UIKit.EmptyState,
    },
})
export default class AssignmentQuizError extends Vue {
    @Prop({ default: '' }) errorMessage!: string
    @Prop({ default: '' }) errorDescription!: string

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    @Emit('close')
    closeQuiz () {
        return
    }
}
</script>

<style lang="scss" scoped>
.quiz-error {
    padding: 56px 0 0;
    margin: 0 auto;
    text-align: center;
    width: 300px;

    &__empty-state {
        padding: 0;
    }

    &__close {
        width: 112px;
        margin: 24px auto 0;
    }
}
</style>