<script setup lang="ts">
import { computed, ref } from 'vue'
import { screenModule } from '@/store/screen/module'
import brandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'
import UIKit from '@pocketprep/ui-kit'
const {
    Tooltip,
} = UIKit

defineProps<{
    isDarkMode: boolean
    vote: string | null
    upDownValues: {
        up: string
        down: string
    }
}>()

const emit = defineEmits<{
    submitVote: [ vote: string ]
}>()

const showTooltip = ref<string | null>(null)

const enableReactionTooltip = (reaction: string) => {
    if (breakpoint.value === 'black-bear') {
        return
    }

    showTooltip.value = reaction
}

const breakpoint = computed(() => {
    return screenModule.getters.getBreakpoint()
})
</script>

<template>
    <div class="thumb-up-down">
        <div
            class="thumb-up-down__feedback-thumbs-up"
            :class="{'thumb-up-down__feedback-thumbs-up--active': vote === upDownValues.up}"
            @mouseenter="enableReactionTooltip(upDownValues.up)"
            @mouseleave="showTooltip = null"
            @click="emit('submitVote', upDownValues.up)"
            v-dark="isDarkMode"
        >
            <Tooltip
                v-if="showTooltip === upDownValues.up"
                class="thumb-up-down__reaction-tooltip-helpful"
                :styles="{
                    'backgroundColor': isDarkMode ? brandColors.mariner : brandColors.fog,
                    'color': isDarkMode ? brandColors.white : brandColors.brandBlack,
                }"
            >
                {{ upDownValues.up }}
            </Tooltip>
            👍
        </div>
        <div
            class="thumb-up-down__feedback-thumbs-down"
            :class="{'thumb-up-down__feedback-thumbs-down--active': vote === upDownValues.down}"
            @mouseenter="enableReactionTooltip(upDownValues.down)"
            @mouseleave="showTooltip = null"
            @click="emit('submitVote', upDownValues.down)"
            v-dark="isDarkMode"
        >
            <Tooltip
                v-if="showTooltip === upDownValues.down"
                class="thumb-up-down__reaction-tooltip-unhelpful"
                :styles="{
                    'backgroundColor': isDarkMode ? brandColors.mariner : brandColors.fog,
                    'color': isDarkMode ? brandColors.white : brandColors.brandBlack,
                }"
            >
                {{ upDownValues.down }}
            </Tooltip>
            👎
        </div>
    </div>
</template>

<style lang="scss" scoped>
.thumb-up-down {
    display: flex;
    justify-content: space-between;
    width: 70px;

    @include breakpoint(black-bear) {
        width: 95px;
    }

    &__feedback-thumbs-up, &__feedback-thumbs-down {
        width: 30px;
        height: 28px;
        background-color: $pickled-bluewood;
        border-radius: 6px;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;

        @include breakpoint(black-bear) {
            width: 40px;
            height: 40px;
            font-size: 20px;
        }

        @media (hover: hover) {
            &:hover {
                background-color: $slate;
                border: 1px solid rgba($banana-bread, 0.4)
            }
        }

        &--dark {
            background-color: $gray-background;
            border: 0.5px solid rgba($pewter, 0.85);

            @media (hover: hover) {
                &:hover {
                    background-color: $barely-background;
                    border: 1px solid rgba($brand-blue, 0.4)
                }
            }
        }

        &--active {
            background-color: rgba($banana-bread, 0.2);
            border: 1px solid $banana-bread;

            &--dark {
                background-color: $white !important;
                border: 1px solid $brand-blue !important;
            }
        }
    }

    &__reaction-tooltip-helpful {
        position: absolute;
        top: -34px;
        right: -44px;
    }

    &__reaction-tooltip-unhelpful {
        position: absolute;
        top: -34px;
        right: -58px;
    }

    &__feedback-thumbs-down {
        padding-top: 4px;
        box-sizing: border-box;
    }
}
</style>
