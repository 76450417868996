<template>
    <div class="mock-exam-intro__container">
        <div 
            id="mock-exam-intro"
            v-dark 
            class="mock-exam-intro"
        >
            <Icon
                v-dark
                type="close"
                class="mock-exam-intro__close-icon"
                title="Close"
                tabindex="0"
                @click="backToStudy"
                @keydown.enter="backToStudy"
                @mousedown.prevent
            />
            <UpgradeSidePanel
                v-if="showUpgradeSidePanel"
                key="mockExamIntro"
                :show-full-height="true"
                @close="showUpgradeSidePanel = false"
            />
            <BottomRightBlob class="mock-exam-intro__bottom-right-blob" />
            <TopLeftBlob class="mock-exam-intro__top-left-blob" />
            <div v-dark class="mock-exam-intro__header">
                <div
                    tabindex="-1"
                    class="mock-exam-intro__title"
                    role="header"
                >
                    <Icon
                        v-dark
                        type="exam"
                        class="mock-exam-intro__title-icon"
                    />
                    Pocket Prep Mock Exam
                    <div
                        class="mock-exam-intro__a11y-step"
                        :style="{
                            width: '0',
                            height: '0',
                            overflow: 'hidden',
                            display: 'inline-flex',
                        }"
                    >
                        {{ step }}
                    </div>
                </div>
                <div v-dark class="mock-exam-intro__exam-name">
                    {{ currentExamName }}
                </div>
            </div>
            <div v-dark class="mock-exam-intro__content">
                <template v-if="isLoading">
                    <Icon
                        v-if="isLoading"
                        class="mock-exam-intro__loading"
                        type="loading2"
                        :is-dark-mode="isDarkMode"
                    />
                </template>
                <template v-else-if="step === 'selectExam'">
                    <SelectMockExam
                        :mock-exams="mappedMockExams"
                        :current-exam-name="currentExamName"
                        :selected-mock-exam-id="selectedMockExamId"
                        @selectMockExam="selectMockExam"
                    />
                </template>
                <template v-else-if="selectedMockExam && step === 'examInfo'">
                    <MockExamInfo
                        :mock-exam="selectedMockExam"
                        :current-exam-name="currentExamName"
                    >
                        <template #extraTime>
                            <div
                                class="mock-exam-intro__extra-time"
                                @click="getsExtraTime = !getsExtraTime"
                            >
                                <Checkbox
                                    class="mock-exam-intro__extra-time-checkbox"
                                    aria-label="I will get extra time during my test for accessibility reasons"
                                    :modelValue="getsExtraTime"
                                    :is-dark-mode="isDarkMode"
                                    @keydown.enter.space="getsExtraTime = !getsExtraTime"
                                />
                                <span
                                    v-dark
                                    class="mock-exam-intro__extra-time-label"
                                    :class="{
                                        'mock-exam-intro__extra-time-label--selected': getsExtraTime,
                                    }"
                                >
                                    I will get extra time during my test for accessibility reasons
                                </span>
                                <span
                                    v-dark
                                    class="mock-exam-intro__extra-time-info"
                                    tabindex="0"
                                    aria-label="If selected, timer will count up after reaching 0:00"
                                    @focus="isShowingInfoTooltip = true"
                                    @blur="isShowingInfoTooltip = false"
                                    @mouseenter="isShowingInfoTooltip = true"
                                    @mouseleave="isShowingInfoTooltip = false"
                                >
                                    <Tooltip
                                        v-if="isShowingInfoTooltip"
                                        theme="leftalign"
                                        :is-dark-mode="isDarkMode"
                                    >
                                        If selected, timer will count up after reaching 0:00
                                    </Tooltip>
                                    <Icon
                                        v-dark
                                        type="info"
                                        class="mock-exam-intro__extra-time-info-icon"
                                    />
                                </span>
                            </div>
                        </template>
                    </MockExamInfo>
                </template>
                <template v-else-if="selectedMockExam && step === 'whatToExpect'">
                    <WhatToExpect />
                </template>
            </div>
            <div v-dark class="mock-exam-intro__footer">
                <PocketButton
                    type="secondary"
                    :is-dark-mode="isDarkMode"
                    @click="previousStep"
                >
                    {{
                        (step === 'selectExam' || (step === 'examInfo' && !hasMultipleMockExams))
                            ? 'Cancel'
                            : 'Back'
                    }}
                </PocketButton>
                <PocketButton
                    class="mock-exam-intro__next-btn"
                    :is-dark-mode="isDarkMode"
                    :disabled="!selectedMockExamId"
                    :is-loading="isLoading"
                    @click="nextStep"
                >
                    {{
                        step === 'selectExam'
                            ? 'Exam Information'
                            : step === 'examInfo'
                                ? 'What to Expect'
                                : hasActiveSubscription
                                    ? 'Start Exam'
                                    : 'Upgrade to Premium'
                    }}<Icon
                        type="arrow"
                        class="mock-exam-intro__next-btn-arrow"
                    />
                </PocketButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import UIKit from '@pocketprep/ui-kit'
import BottomRightBlob from '@/components/MockExamIntro/BottomRightBlob.vue'
import TopLeftBlob from '@/components/MockExamIntro/TopLeftBlob.vue'
import SelectMockExam from '@/components/MockExamIntro/SelectMockExam.vue'
import MockExamInfo from '@/components/MockExamIntro/MockExamInfo.vue'
import WhatToExpect from '@/components/MockExamIntro/WhatToExpect.vue'
import { Component, Vue, Watch } from 'vue-facing-decorator'
import { userModule } from '@/store/user/module'
import { mockExamModule } from '@/store/mockExam/module'
import { quizModule } from '@/store/quiz/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import type { Study } from '@pocketprep/types'
import { subscriptionModule } from '@/store/subscription/module'
import UpgradeSidePanel from '@/components/Settings/UpgradeSidePanel.vue'

type TMockExamIntroStep = 'selectExam' | 'examInfo' | 'whatToExpect'
export type TMappedMockExam = Study.Class.MockExamJSON & { lastTakenAt: Date | null }

@Component({
    components: {
        Icon: UIKit.Icon,
        PocketButton: UIKit.Button,
        Checkbox: UIKit.Checkbox,
        Tooltip: UIKit.Tooltip,
        BottomRightBlob,
        TopLeftBlob,
        SelectMockExam,
        MockExamInfo,
        WhatToExpect,
        UpgradeSidePanel,
    },
})
export default class MockExamIntro extends Vue {
    isLoading = true
    step: TMockExamIntroStep = 'selectExam'
    selectedMockExamId: string | null = null
    getsExtraTime = false
    showUpgradeSidePanel = false
    isShowingInfoTooltip = false

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get currentExam () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get currentExamName () {
        return this.currentExam?.nativeAppName || ''
    }

    get hasActiveSubscription () {
        return subscriptionModule.getters.getSubscriptionForExamId()
    }

    get mockExamQuizzes () {
        const quizzes = quizModule.getters.getQuizzes()
        return quizzes.filter(quiz => quiz.mode === 5).sort((a, b) => {
            // Sort the quizzes by started date so that we'll use the most recent one in mappedMockExams
            return new Date(b.startedAt.iso).getTime() - new Date(a.startedAt.iso).getTime()
        })
    }

    get mappedMockExams (): TMappedMockExam[] {
        const mockExams = mockExamModule.getters.getCurrentMockExams()
        return mockExams.map(mockExam => {
            const mockExamQuiz = this.mockExamQuizzes.find(quiz => quiz.mockExam?.objectId === mockExam.objectId)

            return {
                ...mockExam,
                lastTakenAt: mockExamQuiz ? new Date(mockExamQuiz.startedAt.iso) : null,
            }
        })
    }

    get hasMultipleMockExams () {
        return this.mappedMockExams.length > 1
    }

    get selectedMockExam () {
        return this.mappedMockExams.find(mockExam => mockExam.objectId === this.selectedMockExamId) || null
    }

    async mounted () {
        await mockExamModule.actions.fetchMockExams()

        if (this.mappedMockExams.length === 0) {
            this.$router.push({ name: 'study' })
        } else if (!this.hasMultipleMockExams && this.mappedMockExams[0]?.objectId) {
            this.selectedMockExamId = this.mappedMockExams[0].objectId
            this.step = 'examInfo'
        }

        setTimeout(() => {
            const titleEl = document.querySelector('.mock-exam-intro__title') as HTMLElement | null
            titleEl?.focus()
        }, 0)

        this.isLoading = false
    }

    backToStudy () {
        this.$router.push({
            name: 'study',
        })
    }

    selectMockExam (id: string) {
        this.selectedMockExamId = this.selectedMockExamId === id ? null : id
    }

    previousStep () {
        if (this.step === 'selectExam') {
            this.backToStudy()
        } else if (this.step === 'examInfo' && !this.hasMultipleMockExams) {
            this.backToStudy()
        } else if (this.step === 'examInfo' && this.hasMultipleMockExams) {
            this.step = 'selectExam'
        } else if (this.step === 'whatToExpect') {
            this.step = 'examInfo'
        }
    }

    async nextStep () {
        if (this.step === 'selectExam') {
            this.step = 'examInfo'
        } else if (this.step === 'examInfo') {
            this.step = 'whatToExpect'
        } else if (this.step === 'whatToExpect') {
            if (!this.hasActiveSubscription) {
                this.showUpgradeSidePanel = true
            } else if (this.selectedMockExamId) {
                this.isLoading = true
                await mockExamModule.actions.createActiveMockExamQuiz({
                    mockExamId: this.selectedMockExamId,
                    getsExtraTime: this.getsExtraTime,
                })
                this.$router.push({ name: 'mock-exam' })
            }
        }
    }

    @Watch('step')
    stepChanged () {
        setTimeout(() => {
            const titleEl = document.querySelector('.mock-exam-intro__title') as HTMLElement
            titleEl?.focus()
        }, 0)
    }
}
</script>

<style lang="scss" scoped>
.mock-exam-intro {
    position: relative;
    background-color: $gray-background;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;

    &--dark {
        background-color: $charcoal;
    }

    &__container {
        height: calc(100% - 87px);
        overflow: auto; // overflow:auto and position:relative need to be on different elements

        // Including breakpoint for mobile footer height calculation
        @include breakpoint(black-bear) {
            height: calc(100% - 68px);
        }
    }

    &__close-icon {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 29px;
        right: 28px;
        color: $brand-blue;
        cursor: pointer;
        outline: none;

        &:hover {
            color: $brand-black;
        }

        &:focus {
            content: '';
            position: absolute;
            border: 1px solid $brand-blue;
            border-radius: 5px;
            top: 28px;
            right: 27px;
        }

        &--dark {
            color: $banana-bread;

            &:hover {
                color: $butterscotch;
            }
        }

        @include breakpoint(black-bear) {
            top: 14px;
            right: 15px;

            &:focus {
                top: 13px;
                right: 14px;
            }
        }
    }

    &__bottom-right-blob {
        position: absolute;
        bottom: 0;
        right: 0;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__top-left-blob {
        position: absolute;
        top: 0;
        left: 0;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__header {
        margin-top: 103px;
        width: 460px;

        &--dark {
            color: $white;
        }

        @include breakpoint(black-bear) {
            margin-top: 105px;
            width: 308px;
        }
    }

    &__title {
        outline: none;
        position: relative;
        font-weight: 600;
        font-size: 26px;
        line-height: 34px;
        margin-bottom: 2px;
    }

    &__title-icon {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 4px;
        left: -39px;

        &--dark {
            color: $fog;
        }

        @include breakpoint(black-bear) {
            top: -30px;
            left: -3px;
        }
    }

    &__exam-name {
        color: $ash;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 17px;
        margin-bottom: 26px;

        &--dark {
            color: $fog;
        }

        @include breakpoint(black-bear) {
            margin-bottom: 22px;
        }
    }

    &__content {
        z-index: 1;

        &--dark {
            color: $white;
        }
    }

    &__loading {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__extra-time {
        display: flex;
        align-items: center;
        height: 45px;
        padding-left: 21px;

        &:hover {
            cursor: pointer;
        }

        @include breakpoint(black-bear) {
            height: 58px;
            padding-left: 15px;
        }
    }

    &__extra-time-checkbox {
        width: 15px;
        height: 15px;
        min-width: 15px;
        min-height: 15px;
        margin-right: 12px;

        @include breakpoint(black-bear) {
            position: relative;
            top: -10px;
        }
    }

    &__extra-time-label {
        color: $ash;
        font-size: 15px;
        line-height: 18px;
        margin-right: 4px;

        &--dark {
            color: rgba($white, 0.72);
        }

        &--selected {
            color: $brand-black;

            &--dark {
                color: $white;
            }
        }
    }

    &__extra-time-info {
        position: relative;
        top: 2px;
        color: $brand-blue;
        outline: none;

        @media (hover: hover) {
            &:hover {
                color: $brand-black;
            }
        }

        &:focus {
            color: $brand-black;
        }

        &--dark {
            color: $banana-bread;

            @media (hover: hover) {
                &:hover {
                    color: $butterscotch;
                }
            }

            &:focus {
                color: $butterscotch;
            }
        }

        :deep(.uikit-tooltip) {
            position: absolute;
            left: 50%;
            top: calc(-100% - 14px);
        }

        @include breakpoint(black-bear) {
            :deep(.uikit-tooltip) {
                left: calc(50% - 7px);
                top: calc(-100% - 6px);
            }
        }
    }

    &__extra-time-info-icon {
        @include breakpoint(black-bear) {
            width: 24px;
            height: 24px;
            margin-right: 13px;
        }
    }

    &__footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 87px;
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid $fog;
        z-index: 2;

        &--dark {
            background-color: $mariner;
            border-color: $brand-black;
        }

        @include breakpoint(black-bear) {
            height: 68px;
        }
    }

    &__next-btn {
        margin-left: 8px;
        padding-left: 14px;
        padding-right: 12px;
    }

    &__next-btn-arrow {
        margin-left: 10px;
    }
}
</style>
