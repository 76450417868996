import { fetchLoadable } from '@/store/utils'
import { runCloudFunction } from '@/store/parseUtils'
import type { Study } from '@pocketprep/types'
import { referralModule } from '@/store/referral/module'
import { getInitialReferralState } from '@/store/referral/state'
import { router } from '@/router'
import * as Sentry from '@sentry/browser'

const validateReferral = async () => {
    // grab the referral query from the url rather than each component having to pass it
    const referralId = router.currentRoute.value.query?.referral

    // if no referralParams then don't call fetchLoadable just return null
    if (!referralId){
        return null
    } else if (typeof referralId === 'string') {
        return fetchLoadable(referralModule.state.validReferral, async () => {
            try {
                return await runCloudFunction<Study.Cloud.validateReferral>(
                    'validateReferral', 
                    { referralId }
                )
            } catch (err){
                if (err instanceof Error) {
                    referralModule.state.referralError = err.message
                } else {
                    Sentry.captureException(new Error(`Validate Referral error: ${referralId}`))
                    referralModule.state.referralError =
                        'Invalid discount link. Please check that the link was entered correctly.'
                }
                return null
            }
        })
    }
}

const wipeReferralContext = () => {
    referralModule.state = getInitialReferralState()

    // Remove the referral query param from the route, if present
    const currentRoute = router.currentRoute.value
    if (currentRoute.name && currentRoute.query?.referral) {
        router.replace({
            name: currentRoute.name,
            query: {
                ...currentRoute.query,
                referral: undefined,
            },
        })
    }
}

const fetchReferralInfo = async (bundleId: string) => {
    return await runCloudFunction<Study.Cloud.fetchReferralInfo>('fetchReferralInfo', { bundleId })
}

const trackHasSeenReferralUpgradePanel = () => {
    referralModule.state.hasSeenReferralUpgradePanel = true
}

export default {
    validateReferral,
    wipeReferralContext,
    fetchReferralInfo,
    trackHasSeenReferralUpgradePanel,
}
