import { stripeModule } from '@/store/stripe/module'
import { isPromise } from '@/store/utils'
import type Stripe from 'stripe'
import { referralModule } from '../referral/module'

const getStripePlans = () => {
    const stripePlans = stripeModule.state.plans.value
    if (isPromise(stripePlans)) {
        return {}
    } else {
        return stripePlans
    }
}

const getStripePaymentMethods = () => {
    const stripePaymentMethods = stripeModule.state.payment.value
    if (isPromise(stripePaymentMethods)) {
        return []
    } else {
        const paymentMethods = stripePaymentMethods.paymentMethods

        const currentMonth = new Date().getMonth() + 1
        const currentYear = Number(new Date().getFullYear().toString().substr(-2))

        // filter out expired cards before looking for identical fingerPrints
        paymentMethods.filter((pm) => {
            if (pm && pm.card) {
                if (pm.card?.exp_year > currentYear) {
                    return pm
                }
                if (pm.card?.exp_year === currentYear && pm.card?.exp_month >= currentMonth){
                    return pm
                }
            }
            return
        })

        // filter out duplicate cards by fingerPrint
        return paymentMethods.filter((value, index, pm) =>
            index === pm.findIndex((method) => (
                method.card?.fingerprint === value.card?.fingerprint
            ))
        )
    }
}

const getPlanLabel = (params: {
    p: Stripe.Plan
    hidePeriodName?: boolean
    isLongLabel?: boolean
}) => {
    const { p, hidePeriodName, isLongLabel } = params

    const period = p.interval === 'year'
        ? 'year'
        : p.interval_count === 1
            ? 'month'
            : '3 months'
    const periodName = period === 'year'
        ? 'Yearly'
        : period === 'month'
            ? 'Monthly'
            : 'Quarterly'

    return `${
        !hidePeriodName ? `${periodName} - ` : ''
    }$${
        (p.amount || 0) / 100}${isLongLabel ? ' every ' : '/'
    }${period}`
}

const getPlanSubtext = (p: Stripe.Plan) => {
    const price = (referralModule.getters.calculateDiscountedPrice(p.amount || 0) / 100).toFixed(2)
    return `Your first charge will be $${price}`
}

const getStripePlanOptions = (bundleId: string, hidePeriodName?: boolean) => {
    const bundlePlans = getStripePlans()[bundleId]
    if (!bundlePlans) {
        return []
    }

    const hasReferralDiscount = referralModule.getters.getValidReferral()

    return [ ...bundlePlans ]
        .sort((a, b) => (a.amount || 0) - (b.amount || 0))
        .map(p => ({
            label: getPlanLabel({ p, hidePeriodName, isLongLabel: true }),
            value: p.id,
            subtext: hasReferralDiscount ? getPlanSubtext(p) : undefined,
        }))
}

const getStripeSubscriptions = () => {
    const stripeSubscriptions = stripeModule.state.subscriptions.value
    if (isPromise(stripeSubscriptions)) {
        return []
    } else {
        return stripeSubscriptions
    }
}

const getStripe = () => {
    return stripeModule.state.stripe
}

const getElements = () => {
    return stripeModule.state.elements
}

export default {
    getStripePlans,
    getStripeSubscriptions,
    getStripePlanOptions,
    getStripePaymentMethods,
    getPlanLabel,
    getStripe,
    getElements,
}
