<!-- eslint-disable -->
<template>
    <svg width="103px" height="200px" viewBox="0 0 103 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title></title>
        <defs>
            <rect id="path-1-right-blob" x="0" y="0" width="103" height="200"></rect>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <mask id="mask-2-right-blob" fill="white">
                <use xlink:href="#path-1-right-blob"></use>
            </mask>
            <g></g>
            <path d="M3.90114845,97.2001375 C-11.8958339,145.409713 28.0540425,174.450679 50.9421776,188.438 C101.220872,219.164149 169.020988,181.644141 169.89252,169.040893 C171.160796,150.700328 235.067469,156.510753 283.816209,68.0179111 C332.564949,-20.4749305 298.554593,40.5244291 238.456933,-87.1789171 C178.359273,-214.882263 58.9297135,-70.7372376 3.90114845,97.2001375 Z" fill="url(#halftone-blue)" opacity="0.8" mask="url(#mask-2-right-blob)"></path>
        </g>
    </svg>
</template>
<!-- eslint-enable -->

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class RightBlob extends Vue {

}
</script>