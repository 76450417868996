import { runCloudFunction } from '@/store/parseUtils'
import type { Study } from '@pocketprep/types'
import { userModule } from '@/store/user/module'
import { fetchLoadable } from '@/store/utils'
import { globalQuestionMetricModule } from '@/store/globalQuestionMetric/module'

const fetchGlobalQuestionMetrics = async (forceFetch?: boolean) => {
    await userModule.actions.fetchUserData()

    const examGuid = userModule.state.user?.currentExamGuid as string

    await fetchLoadable(globalQuestionMetricModule.state.globalQuestionMetricsBySerial, async () => {
        const metrics = await runCloudFunction<Study.Cloud.fetchGlobalQuestionMetrics>(
            'fetchGlobalQuestionMetrics', 
            { examGuid }
        )

        return metrics.reduce<{ [serial: string]: Study.Class.GlobalQuestionMetricJSON }>((acc, metric) => {
            acc[metric.questionSerial] = metric
            return acc
        }, {})
    }, forceFetch)
}

export default {
    fetchGlobalQuestionMetrics,
}