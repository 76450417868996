<!-- eslint-disable -->
<template>
    <svg width="96.3456405px" height="203.62685px" viewBox="0 0 96.3456405 203.62685" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title></title>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(48.172820, 101.813425) rotate(-90.000000) translate(-48.172820, -101.813425) translate(-53.640605, 53.640605)">
                <path d="M26.8151025,44.9378391 C17.5638541,40.8177955 8.61153244,37.2740164 1.4033219e-13,34.0810607 L0.00122962459,0.161349818 L203.62685,0.162158113 C174.799348,48.0061407 108.125474,81.149414 26.8151025,44.9378391 Z" fill="#FFF2D9" opacity="0.152832031"></path>
                <path d="M121.735231,8.91731133e-13 C117.877951,9.16922399 110.73054,18.3182248 99.624112,27.2840087 C48.7330122,68.3663964 24.6062814,92.443815 0.000681331444,96.3456405 L0.00122962459,2.17241504e-05 Z" fill="url(#halftone-yellow)"></path>
            </g>
        </g>
    </svg>
</template>
<!-- eslint-enable -->

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class LeftBlob extends Vue {

}
</script>