import { examMetadataModule } from '@/store/examMetadata/module'
import { mockExamModule } from '@/store/mockExam/module'
import { isPromise } from '@/store/utils'

const getMockExams = () => {
    const mockExams = mockExamModule.state.mockExams.value
    if (isPromise(mockExams)) {
        return []
    } else {
        return mockExams
    }
}

/**
 * Returns the enabled mock exams for the user's current exam
 */
const getCurrentMockExams = () => {
    const allMockExams = getMockExams()
    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()

    // The mock exam ids for the current exam
    const mockExamIds = currentExam?.mockExams?.map(mockExam => mockExam.objectId) || []
    // Use a set for a minor performance boost
    const mockExamIdSet = new Set(mockExamIds)

    // Filter by the current exam AND whether mock exam is enabled
    const currentMockExams = allMockExams.filter(mockExam => mockExam.enabled && mockExamIdSet.has(mockExam.objectId))
    return currentMockExams
}

const getActiveMockExamQuiz = () => {
    return mockExamModule.state.activeMockExamQuiz
}

export default {
    getMockExams,
    getCurrentMockExams,
    getActiveMockExamQuiz,
}
