import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { userModule } from '@/store/user/module'
import { isPromise } from '@/store/utils'

const getUserExamMetadataByGuid = () => {
    const userExamMetadataByGuid = userExamMetadataModule.state.userExamMetadataByGuid.value
    if (isPromise(userExamMetadataByGuid)) {
        return {}
    } else {
        return userExamMetadataByGuid
    }
}

const getCurrentUserExamMetadata = () => {
    const uemByGuid = userExamMetadataModule.getters.getUserExamMetadataByGuid()
    const examGuid = userModule.state.user?.currentExamGuid
    const uem = examGuid && uemByGuid[examGuid]

    return uem || null
}

const getFlaggedQuestions = () => {
    const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    return uem?.flaggedQuestions || []
}

const getLevelUpProgress = () => {
    const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    return uem?.levelUpProgress || []
}

const getMessageReferralConfig = () => {
    const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    return uem?.messageConfig?.referral
}

export default {
    getUserExamMetadataByGuid,
    getCurrentUserExamMetadata,
    getFlaggedQuestions,
    getLevelUpProgress,
    getMessageReferralConfig,
}
