<!-- eslint-disable -->
<template>
    <svg width="146px" height="194px" viewBox="0 0 146 194" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title></title>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(0.000000, -64.000000)">
                <g transform="translate(0.000000, 64.000000)">
                    <g transform="translate(-0.000000, -0.000000)">
                        <path d="M86.2164378,77.9562509 C49.757046,104.43991 26.1382615,131.502795 0.00317319866,146.019802 L0.0023155527,0.00890425605 L145.234551,0.00890272619 C136.616332,27.558366 117.963927,54.8952607 86.2164378,77.9562509 Z" :fill="fillColor" opacity="0.1"></path>
                        <path d="M103.040017,-5.77315973e-14 C103.769052,2.89626665 104.390767,5.96299853 104.899376,9.20625178 C122.722338,122.858087 136.385481,138.49378 56.8196852,175.877505 C35.0929307,186.085749 16.1963225,191.995022 -1.82964754e-13,194.409897 L0.0023155527,0.00890425605 Z" :fill="`url(#halftone-${color})`"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
<!-- eslint-enable -->

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'

@Component
export default class QuizResultBlob extends Vue {
    @Prop() color!: 'red' | 'yellow' | 'green'

    get fillColor () {
        return this.color === 'red'
            ? BrandColors.brandRed
            : this.color === 'yellow'
                ? BrandColors.butterscotch
                : BrandColors.chromophobiaGreen
    }
}
</script>
