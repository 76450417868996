<!-- eslint-disable -->
<template>
    <svg width="68px" height="29px" viewBox="0 0 68 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title></title>
        <g id="Visuals-R3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M2.27676043,0.0370918451 L10.880382,0.17257941 L13.9441236,0.386183452 L50.4442938,1.54737073 L52.2527475,1.70533965 C58.7757548,2.4006084 62.8274569,2.78250279 64.4078541,2.85102283 C65.9884758,2.91955262 67.2230662,3.10709061 67.0238304,5.58261821 C66.4463751,12.7575674 66.9704606,22.4754511 66.8950778,23.9042718 C66.8196949,25.3330924 67.050062,26.9386423 65.5457048,28.427577 C64.8875674,29.0789673 59.4438969,28.8165406 49.2146931,27.6402971 C31.8635606,28.125564 20.8937716,28.3136708 16.3053264,28.2046175 C11.7168812,28.0955641 7.13535355,27.202364 2.56074341,25.5250172 C0.903774609,25.5674032 0.439452805,27.3966785 0.226597246,20.1874644 C0.013741688,12.9782503 -0.640156518,-0.0152093584 2.27676043,0.0370918451 Z" id="highlight" fill="#FFEDCB" fill-rule="nonzero" transform="translate(33.528653, 14.406300) scale(-1, -1) translate(-33.528653, -14.406300) "></path>
        </g>
    </svg>
</template>
<!-- eslint-enable -->

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class ReferralHighlight extends Vue {

}
</script>