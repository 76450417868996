<script setup lang="ts">
import { userModule } from '@/store/user/module'
import { computed } from 'vue'
import UIKit from '@pocketprep/ui-kit'
const {
    ModalContainer,
    Button: PocketButton,
} = UIKit

const emit = defineEmits<{
    close: []
}>()

const isDarkMode = computed(() => {
    return userModule.state.settings.isDarkMode
})
</script>

<template>
    <Portal to="modal">
        <ModalContainer
            key="aiFeedbackInfoModal"
            :show-close-button="true"
            :is-dark-mode="isDarkMode"
            @close="emit('close')"
        >
            <div class="ai-feedback-info-modal">
                <div class="ai-feedback-info-modal__title">
                    AI-Generated Feedback
                </div>
                <div class="ai-feedback-info-modal__content">
                    Our AI feedback uses our question and
                    explanation to give you specific feedback
                    on your response. Like humans, AI can be
                    wrong sometimes. We recommend
                    comparing your response to our
                    explanation and welcome your feedback.
                </div>
                <PocketButton
                    class="ai-feedback-info-modal__close-btn"
                    :isDarkMode="isDarkMode"
                    @click="emit('close')"
                >Close</PocketButton>
            </div>
        </ModalContainer>
    </Portal>
</template>

<style lang="scss" scoped>
.ai-feedback-info-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.3px;
        text-align: center;
        margin-top: 36px;
        margin-bottom: 12px;
    }

    &__content {
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        width: 298px;
        margin: 0 38px 32px 38px;
    }

    &__close-btn {
        margin-bottom: 24px;
    }
}
</style>
