<!-- eslint-disable -->
<template>
    <svg width="190px" height="180px" viewBox="0 0 190 180" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title></title>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-559.000000, -796.000000)">
                <g transform="translate(19.000000, 62.000000)">
                    <g transform="translate(0.000000, 711.000000)">
                        <g transform="translate(540.519782, 23.701543)">
                            <g transform="translate(0.000000, 0.000000)">
                                <path d="M189.478958,4.92219579 L189.480218,179.463154 L62.5130253,179.461795 C56.6483604,132.678726 59.4842112,88.355086 71.2252164,65.1307593 C104.817296,-1.31614228 134.502057,-6.88567922 189.478958,4.92219579 Z" fill="#FFF2D9"></path>
                                <path d="M29.5984279,122.772174 C119.368311,62.4207101 132.15275,42.0261755 189.480342,86.61956 L189.480218,179.463154 L1.22955847,179.463231 C-3.23006442,159.587938 4.04368938,139.952389 29.5984279,122.772174 Z" fill="url(#halftone-blue)"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
<!-- eslint-enable -->

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class Blob extends Vue {

}
</script>