import { instructorModule } from '@/store/instructor/module'
import { isPromise } from '@/store/utils'

const getCurrentUserOrgInstructors = () => {
    const currentUserOrgInstructors = instructorModule.state.currentUserOrgInstructors.value

    if (!(isPromise(currentUserOrgInstructors))) {
        return currentUserOrgInstructors
    } else {
        return []
    }
}

export default {
    getCurrentUserOrgInstructors,
}
