<template>
    <Portal to="modal">
        <ModalContainer
            key="unfinishedQuizModal"
            :is-dark-mode="isDarkMode"
            :show-close-button="true"
            @close="emitClose"
        >
            <template #modal>
                <Modal
                    :show-close-button="true"
                    class="unfinished-quiz__modal"
                    :is-dark-mode="isDarkMode"
                    @close="emitClose"
                >
                    <div class="unfinished-quiz">
                        <div class="unfinished-quiz__title">
                            Unfinished {{ unfinishedMode === 'mockExam' ? 'Exam' : 'Quiz' }}
                        </div>
                        <div v-dark class="unfinished-quiz__text">
                            It looks like you have an unfinished {{ getModeName(unfinishedMode) }}.
                            Would you like to resume where you left off?
                        </div>
                        <div class="unfinished-quiz__actions">
                            <PocketButton
                                type="secondary"
                                class="unfinished-quiz__start-btn"
                                :is-dark-mode="isDarkMode"
                                @click="emitStartNew(clickedMode)"
                            >
                                No, Start New {{ clickedMode === 'mockExam' ? 'Exam' : 'Quiz' }}
                            </PocketButton>
                            <PocketButton
                                class="unfinished-quiz__resume-btn"
                                :is-dark-mode="isDarkMode"
                                @click="emitResume(unfinishedMode)"
                            >
                                Yes, Resume
                            </PocketButton>
                        </div>
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import type { TQuizMode } from '@/store/quiz/state'

@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        PocketButton: UIKit.Button,
        Modal: UIKit.Modal,
    },
})
export default class UnfinishedQuiz extends Vue {
    @Prop() unfinishedMode!: Exclude<TQuizMode, 'qotd' | 'levelUp'>
    @Prop() clickedMode!: TQuizMode

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    getModeName (mode: Exclude<TQuizMode, 'levelUp'>) {
        const modeNameLib = {
            'mockExam': 'Mock Exam',
            'quick10': 'Quick 10 quiz',
            'missed': 'Missed Questions quiz',
            'weakest': 'Weakest Subject quiz',
            'custom': 'Build Your Own quiz',
            'timed': 'Timed quiz',
            'qotd': 'Question of the Day',
            'levelUp': 'Level Up',
            'assignment': 'Assignment',
        } as const

        return modeNameLib[mode]
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('startNew')
    emitStartNew (clickedMode: TQuizMode) {
        return clickedMode
    }

    @Emit('resume')
    emitResume (unfinishedMode: TQuizMode) {
        return unfinishedMode
    }
}
</script>

<style lang="scss" scoped>
.unfinished-quiz {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 384px;
    box-sizing: border-box;
    padding: 35px 16px 26px 16px;

    @include breakpoint(black-bear) {
        max-width: 340px;
        margin: 0 auto;
        width: 100;
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 12px;
    }

    &__text {
        max-width: 320px;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: $ash;
        margin-bottom: 26px;

        &--dark {
            color: $fog;
        }
    }

    &__modal {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 94px;
    }

    &__actions {
        display: flex;
        justify-content: center;
        align-items: center;

        .uikit-btn--primary {
            padding: 9px 15px 8px 15px;
        }

        .uikit-btn--secondary {
            margin: 0 4px;
            padding: 9px 20px 8px 21px;
        }
    }

    &__start-btn {
        margin-right: 12px;
    }
}
</style>
