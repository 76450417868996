<template>
    <Portal to="modal">
        <ModalContainer key="emailAuthModal">
            <div class="email-auth">
                <img class="email-auth__sparkles" src="@/assets/signIn/Sign-in_Link-Sparkles.png">
                <img class="email-auth__mobile-sparkles" src="@/assets/signIn/Sign-in_mobile-code-sparkles.svg">
                <div class="email-auth__header">
                    <Icon
                        class="email-auth__back-arrow"
                        type="arrow"
                        tabindex="0"
                        role="button"
                        aria-label="Back"
                        @keyup.enter="clickBackArrow"
                        @click="clickBackArrow"
                        @mousedown.prevent
                        @keydown.enter="clickBackArrow"
                    />
                    <div v-if="!isLoading" class="email-auth__link-sent">
                        <Icon class="email-auth__link-sent-check" type="check" />
                        <span role="alert" class="email-auth__link-sent-text">Link Sent</span>
                    </div>
                </div>
                <EmailAuthForm
                    v-model="code"
                    class="email-auth__form"
                    :email="email"
                    :parent-error="errorMessage"
                    :parent-is-loading="isLoading"
                    @submit="submitCode"
                    @resend="resendEmail"
                />
            </div>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'

@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        Icon: UIKit.Icon,
        EmailAuthForm: UIKit.EmailAuth,
    },
})
export default class EmailAuth extends Vue {
    email = ''
    code = ''
    errorMessage = ''
    isLoading = true

    async mounted () {
        // if user logged in, check query email is same as user email and redirect to home
        if (userModule.state.user?.email === this.$route.query?.email && this.$route.query?.email) {
            if (this.$route.query.redirect && typeof this.$route.query.redirect === 'string') {
                const [ path, queryParams ] = this.$route.query.redirect.split('?')
                const query = Object.fromEntries(new URLSearchParams(queryParams))
                this.$router.push({ path, query })
            } else {
                this.$router.push({ name: 'study' })
            }
        } else {
            await userModule.actions.signOut()
            if (typeof this.$route.query.code === 'string') {
                const queryCode = this.$route.query.code
                if (typeof Number(queryCode) === 'number' && queryCode.length === 6) {
                    this.code = queryCode
                } else {
                    this.errorMessage = `Invalid code: ${queryCode}. Code must be six digits`
                }
            }
            if (typeof this.$route.query.email === 'string') {
                this.email = this.$route.query.email

                if (this.code) {
                    this.submitCode()
                }
            } else {
                this.$router.push({
                    name: 'sign-in',
                })
            }
        }

        setTimeout(() => {
            this.isLoading = false
        }, 500)
    }

    clickBackArrow () {
        this.$router.push({
            name: 'sign-in',
        })
    }

    async submitCode () {
        this.errorMessage = ''
        this.isLoading = true
        try {
            const { redirect: magicRedirect } = await userModule.actions.signInWithCode({
                email: this.email,
                code: this.code,
            })
            if (this.$route.query.redirect && typeof this.$route.query.redirect === 'string') {
                const [ path, queryParams ] = this.$route.query.redirect.split('?')
                const query = Object.fromEntries(new URLSearchParams(queryParams))
                this.$router.push({ path, query })
            } else if (magicRedirect) {
                const [ path, queryParams ] = magicRedirect.split('?')
                const query = Object.fromEntries(new URLSearchParams(queryParams))
                this.$router.push({ path, query })
            } else {
                this.$router.push({ name: 'study' })
            }
        } catch (err) {
            this.errorMessage = 'That code wasn\'t right or it expired'
            this.isLoading = false
        }
        this.isLoading = false
    }

    async resendEmail () {
        this.isLoading = true
        try {
            this.code = ''
            await userModule.actions.sendMagicEmail({ 
                email: this.email,
                route: this.$route,
            })
        } catch (err) {
            this.errorMessage = 'We weren\'t able to send the link.'
        }
        setTimeout(() => {
            this.isLoading = false
        }, 500)
    }
}
</script>

<style lang="scss" scoped>
.email-auth {
    position: relative;
    box-sizing: border-box;
    background-color: $floral-white;
    width: 661px;
    height: 503px;

    @include breakpoint(black-bear) {
        width: 100%;
        height: 673px;
    }

    &__sparkles {
        position: absolute;
        top: 86px;
        left: 105px;
        width: 484px;
        height: 291px;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__mobile-sparkles {
        display: none;

        @include breakpoint(black-bear) {
            display: initial;
            position: absolute;
            top: 42px;
            left: 50%;
            transform: translateX(-50%);
            width: 353px;
            height: 380px;
        }
    }

    &__header {
        position: absolute;
        top: 15px;
        display: flex;
        align-items: center;
        width: 100%;
        z-index: 2;
    }

    &__back-arrow {
        width: 18px;
        height: 24px;
        margin-left: 17px;
        padding: 0 4px;
        transform: rotate(180deg);
        color: $brand-blue;
        cursor: pointer;

        &:hover {
            color: $brand-black;
        }
    }

    &__link-sent {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        line-height: 17px;
        height: 19px;
        color: $slate-03;
    }

    &__link-sent-check {
        position: relative;
        top: 1px;
        width: 12px;
        height: 10px;
        margin-right: 7px;
    }

    &__form {
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
    }
}
</style>
