<template>
    <div
        v-dark
        class="exam-card"
        :tabindex="isFocusable ? '0' : undefined"
        :aria-label="
            exam && isFocusable
                ? `${exam.nativeAppName} ${isPremium ? 'Premium' : ''} ${exam.descriptiveName}`
                : undefined
        "
        :class="{ [`exam-card--${theme}`]: true }"
    >
        <div class="exam-card__icon">
            <BundleIcon
                v-if="bundle"
                :theme="isDarkMode && theme === 'grayscale' ? 'silver' : theme"
                :bundle-id="bundle.objectId"
            />
        </div>
        <div :aria-hidden="isFocusable ? 'true' : false" class="exam-card__right">
            <div class="exam-card__top">
                <div class="exam-card__name" :class="{ 'exam-card__name--is-premium': isPremium }">
                    {{ exam.nativeAppName }}
                </div>
                <Tag
                    v-if="isPremium && !exam.isFree"
                    class="exam-card__is-premium"
                    :is-dark-mode="isDarkMode"
                    size="small"
                >
                    <template #tagMessage>
                        Premium
                    </template>
                </Tag>
            </div>
            <div class="exam-card__fullname">
                <span>{{ exam.descriptiveName }}</span>
                <Icon
                    v-if="showMenuArrow"
                    :style="{ transform: `rotate(${menuArrowDirection === 'up' ? '180' : '0'}deg)` }"
                    type="accordionArrow"
                    class="exam-card__arrow"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import type { Study } from '@pocketprep/types'
import { Vue, Component, Prop } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { bundleModule } from '@/store/bundle/module'
import { userModule } from '@/store/user/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { screenModule } from '@/store/screen/module'

@Component({
    components: {
        BundleIcon: UIKit.BundleIcon,
        Icon: UIKit.Icon,
        Tag: UIKit.Tag,
    },
})
export default class ExamCard extends Vue {
    @Prop() exam!: Study.Class.ExamMetadataJSON
    @Prop({ default: 'color' }) theme!: string
    @Prop({ default: false }) isPremium!: boolean
    @Prop({ default: false }) showMenuArrow!: boolean
    @Prop({ default: 'down' }) menuArrowDirection!: 'up' | 'down'
    @Prop({ default: false }) isFocusable!: boolean

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get examMetadataById () {
        return examMetadataModule.getters.getExamMetadataById()
    }

    get bundle () {
        return bundleModule.getters.getBundles().find(b => b.exams.find(e => {
            const bundleExam = this.examMetadataById[e.objectId]
            return bundleExam?.examGuid === this.exam.examGuid
        }))
    }

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    async mounted () {
        await bundleModule.actions.fetchBundles()
    }
}
</script>

<style lang="scss" scoped>
.exam-card {
    display: flex;
    align-items: center;
    color: $white;
    padding: 8px 3px 10px;
    box-sizing: border-box;
    height: 54px;
    cursor: pointer;
    user-select: none;
    width: 320px;
    position: relative;
    outline: none;

    @include breakpoint(black-bear) {
        width: 100%;
    }

    &:focus::before {
        content: '';
        left: 0;
        top: 3px;
        position: absolute;
        width: calc(100% - 2px);
        height: calc(100% - 8px);
        border-radius: 6px;
        border: 1px solid $brand-blue;
    }

    &--grayscale {
        color: $brand-black;

        &:hover {
            background: $gray-background;
        }

        .exam-card__arrow {
            color: $slate-03;
        }

        &--dark {
            color: $white;

            &:hover {
                background: $brand-black;
            }

            .exam-card__arrow {
                color: $white;
            }
        }
    }

    &--silver {
        &:hover {
            background: $black-feather;
        }

        &--dark:hover {
            background: $brand-black;
        }
    }

    &--dark {
        &:focus::before {
            border-color: $banana-bread;
        }
    }

    &__right {
        position: relative;
        max-width: calc(100% - 52px);
        width: 100%;
        margin-top: 5px;
    }

    &__arrow {
        width: 10px;
        color: $white;
        display: inline-block;
        vertical-align: top;
        margin-top: 4px;
        margin-left: 5px;
    }

    &__icon {
        width: 52px;
        height: 52px;
        margin-right: 5px;
        margin-top: 1px;

        @include breakpoint(black-bear) {
            width: 47px;
            height: 47px;
            margin-top: 0;
        }

        svg {
            width: 52px;
            height: 52px;

            @include breakpoint(black-bear) {
                width: 47px;
                height: 47px;
            }
        }
    }

    &__top {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        height: 20px;
    }

    &__name {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &--is-premium {
            max-width: calc(100% - 86px);
        }

        // Safari ellipsis fix (https://stackoverflow.com/a/38744914/16283676)
        &::after {
            content: "\0000a0";
            display: inline-block;
            width: 0;
        }
    }

    &__is-premium {
        vertical-align: top;
        margin-left: 8px;
    }

    &__fullname {
        font-size: 12px;
        line-height: 16px;
        opacity: 0.8;
        max-width: 244px;

        @include breakpoint(black-bear) {
            max-width: none;
            width: 100%;
            display: inline-block;
            position: relative;
        }

        span {
            display: inline-block;
            max-width: calc(100% - 40px);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}
</style>