<template>
    <div class="subscription-row">
        <PocketLink
            v-if="column.propName === 'action' && row.canEdit"
            class="subscription-row__subscription-edit"
            type="tertiary-small"
            :is-dark-mode="isDarkMode"
            @click="emitEditSubscription(row.id)"
        >
            Edit
        </PocketLink>
        <template v-else-if="column.propName === 'purchase'">
            <div class="subscription-row__subscription-purchase">
                <BundleIcon
                    :bundle-id="row.bundleId"
                    :theme="isDarkMode ? 'silver' : 'color'"
                    class="subscription-row__subscription-icon"
                />
                <div class="subscription-row__subscription-right">
                    <div class="subscription-row__subscription-label">
                        {{ row.name }}
                    </div>
                    <div v-dark class="subscription-row__subscription-subtext">
                        {{ row.nameSubtext }}
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="column.propName === 'plan'">
            <div class="subscription-row__subscription-label">
                Premium Prep
            </div>
            <div
                v-dark
                class="subscription-row__subscription-subtext"
                :class="{ 'subscription-row__subscription-subtext--expired': row.expired }"
            >
                <template v-if="row.planCost && !row.expired">
                    {{ row.planCost }} •
                </template>
                {{ row.renewsExpired }}
            </div>
        </template>
        <template v-else-if="column.propName === 'paid'">
            <div class="subscription-row__subscription-label">
                {{ row.paymentPlatform }}
            </div>
            <div v-dark class="subscription-row__subscription-subtext">
                {{ row.paymentSubtext }}
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import UIKit, { type ITableColumn } from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import type { TRow } from '@/utils'

@Component({
    components: {
        PocketLink: UIKit.Link,
        BundleIcon: UIKit.BundleIcon,
    },
})
export default class SubscriptionRow extends Vue {
    @Prop() row!: TRow
    @Prop() column!: ITableColumn
    
    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    @Emit('editSubscription')
    emitEditSubscription (rowId: string) {
        return rowId
    }
}
</script>

<style lang="scss" scoped>
.subscription-row {
    &__subscription-label {
        margin-bottom: 4px;
        font-size: 15px;
        line-height: 15px;
    }

    &__subscription-subtext {
        font-size: 13px;
        line-height: 18px;
        color: $slate-01;

        &--dark {
            color: $fog;
        }

        &--expired {
            color: $brand-red;

            &--dark {
                color: $rosa;
            }
        }
    }

    &__subscription-purchase {
        display: flex;
        align-items: center;
    }

    &__subscription-icon {
        width: 45px;
        height: 45px;
        margin-top: -5px;
        margin-left: 5px;
        margin-right: 12px;
    }
}
</style>