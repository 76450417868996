<!-- eslint-disable -->
<template>
    <svg width="214px" height="243px" viewBox="0 0 214 243" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title></title>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M0.000170194594,30.832471 C17.8122987,27.1489532 40.8453568,35.5249071 69.2881476,59.2497607 C141.031137,119.092409 187.519198,179.932335 212.000364,243.000677 L0.0075893841,243 Z" fill="#FFF2D9"></path>
            <path d="M12.8054313,244 L0.00625305833,243.997901 L0.000110633865,14.0791988 C16.8858181,10.1962395 37.8953988,7.75500789 63.9870269,7.07321353 C209.704137,3.26551307 99.8204343,148.836297 12.8054313,244 Z" fill="url(#halftone-blue)" opacity="0.8"></path>
        </g>
    </svg>
</template>
<!-- eslint-enable -->

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class LeftBlob extends Vue {

}
</script>