<template>
    <Portal to="sidepanel">
        <SidePanel
            key="quizSettingsSidePanel"
            :is-dark-mode="isDarkMode"
            name="Quiz Settings"
            class="quiz-settings"
            width="medium"
            @close="emitClose"
        >
            <label class="quiz-settings__label">
                These controls will be default settings for all of your quiz modes.
            </label>
            <QuizSetting
                v-model="showAnswersAtEnd"
                :options="showAnswersAtEndOptions"
                class="quiz-settings__setting"
            />
            <QuizSetting
                v-if="!showAnswersAtEnd"
                v-model="showCheckAnswerButton"
                :options="showCheckAnswerButtonOptions"
                class="quiz-settings__setting"
            />
            <label class="quiz-settings__label quiz-settings__subjects-label">
                Adjust Subjects
            </label>
            <div v-dark class="quiz-settings__subjects-sublabel">
                Turn off a subject to hide all questions for that subject. It’s helpful if your exam has optional
                subjects that you don’t need to study for.
            </div>
            <div class="quiz-settings__subjects">
                <SubjectsSelector :exam-metadata="currentExamMetadata" @disabledSubjects="updateDisabledSubjects" />
            </div>
            <template #action>
                <PocketButton
                    :is-dark-mode="isDarkMode"
                    :is-loading="isLoading"
                    :disabled="!settingsUnsaved"
                    @click="saveSettings"
                >
                    Save Settings
                </PocketButton>
            </template>
        </SidePanel>
    </Portal>
</template>

<script lang="ts">
import { examMetadataModule } from '@/store/examMetadata/module'
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { difference } from '@/utils'
import UIKit from '@pocketprep/ui-kit'
import SubjectsSelector from '@/components/Study/SubjectsSelector.vue'
import { Vue, Component, Emit } from 'vue-facing-decorator'
import QuizSetting from '@/components/Study/QuizSetting.vue'

@Component({
    components: {
        SidePanel: UIKit.SidePanel,
        Icon: UIKit.Icon,
        PocketButton: UIKit.Button,
        Checkbox: UIKit.Checkbox,
        SubjectsSelector,
        QuizSetting,
    },
})
export default class QuizSettingsSidePanel extends Vue {
    showAnswersAtEnd = false
    showCheckAnswerButton = false
    disabledSubjects: string[] = []
    isLoading = true
    showAnswersAtEndOptions = [
        {
            value: false,
            label: 'Show answers as I go',
        },
        {
            value: true,
            label: 'Show answers at the end',
        },
    ]
    showCheckAnswerButtonOptions = [
        {
            value: true,
            label: 'Manual Submit (Click "Check Answer" Button)',
        },
        {
            value: false,
            label: 'Automatic Submit (Click Answer)',
        },
    ]

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get currentExamMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get settingsUnsaved () {
        const userQuizSettings = userModule.state.user?.quizSettings
        const userDisabledSubjects = userExamMetadataModule.getters.getCurrentUserExamMetadata()?.disabledSubjects
        const disabledSubjects = this.disabledSubjects
        if (!userQuizSettings) {
            return true
        }
        return userQuizSettings.showCheckAnswerButton !== this.showCheckAnswerButton
            || userQuizSettings.showAnswersAtEnd !== this.showAnswersAtEnd
            || difference(disabledSubjects, userDisabledSubjects || []).length
            || difference(userDisabledSubjects || [], disabledSubjects).length
    }

    async mounted () {
        await Promise.all([
            examMetadataModule.actions.fetchExamMetadata(),
            userExamMetadataModule.actions.fetchUserExamMetadata(),
        ])

        this.showAnswersAtEnd = userModule.state.user?.quizSettings?.showAnswersAtEnd || false
        this.showCheckAnswerButton = userModule.state.user?.quizSettings?.showCheckAnswerButton || false
        this.disabledSubjects = userExamMetadataModule.getters.getCurrentUserExamMetadata()?.disabledSubjects || []

        this.isLoading = false
    }

    updateDisabledSubjects (subjects: string[]) {
        this.disabledSubjects = subjects
    }

    async saveSettings () {
        this.isLoading = true
        const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
        if (!uem) {
            throw new Error('No User Exam Metadata found.')
        }

        await Promise.all([
            userModule.actions.updateQuizSettings({
                showCheckAnswerButton: this.showCheckAnswerButton,
                showAnswersAtEnd: this.showAnswersAtEnd,
            }),
            userExamMetadataModule.actions.upsertUserExamMetadata({
                objectId: uem.objectId,
                disabledSubjects: this.disabledSubjects,
            }),
        ])
        this.emitClose()
        this.isLoading = false
    }

    @Emit('close')
    emitClose () {
        return true
    }
}
</script>
<style lang="scss" scoped>
.quiz-settings {
    &__label {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 17px;
        display: block;
    }

    &__subjects {
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-bottom: 36px;
    }

    &__setting {
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-bottom: 12px;
    }

    &__subjects-label {
        margin-top: 32px;
        margin-bottom: 10px;
    }

    &__subjects-sublabel {
        font-size: 14px;
        line-height: 18px;
        color: $slate-03;
        margin-bottom: 17px;

        &--dark {
            color: $pewter;
        }
    }
}
</style>