<template>
    <Portal to="sidepanel">
        <SidePanel
            key="editExamSidePanel"
            :name="sidePanelName"
            class="edit-exam"
            width="medium"
            :tabs="tabs"
            :active-tab-name="activeTab"
            :is-dark-mode="isDarkMode"
            @close="emitClose"
            @selectTab="selectTab"
        >
            <Portal to="modal">
                <ResetProgressModal
                    v-if="showResetProgressModal"
                    :is-loading="isResetLoading"
                    @resetProgress="resetProgress"
                    @close="showResetProgressModal = false"
                />
                <ModalContainer
                    v-if="showCannotDeleteModal"
                    key="cannotDeleteExamModal"
                    :is-dark-mode="isDarkMode"
                    @close="showCannotDeleteModal = false"
                >
                    <template #modal>
                        <Modal
                            :show-close-button="true"
                            class="edit-exam__modal"
                            :is-dark-mode="isDarkMode"
                            @close="showCannotDeleteModal = false"
                        >
                            <div class="edit-exam__modal-title">
                                Cannot Delete Exam
                            </div>
                            <div v-dark class="edit-exam__modal-message">
                                This is your only exam, thus you cannot delete it. If you would like to cancel your
                                subscription, go to Settings > Purchases. If you would like to delete your account, go
                                to Settings > Account.
                            </div>
                            <div class="edit-exam__modal-buttons">
                                <PocketButton
                                    class="edit-exam__modal-button"
                                    :is-dark-mode="isDarkMode"
                                    @click="showCannotDeleteModal = false"
                                >
                                    Got it
                                </PocketButton>
                            </div>
                        </Modal>
                    </template>
                </ModalContainer>
            </Portal>
            <Portal to="modal">
                <ModalContainer
                    v-if="showDeleteConfirm"
                    key="confirmDeleteExamModal"
                    :is-dark-mode="isDarkMode"
                    @close="showDeleteConfirm = false"
                >
                    <template #modal>
                        <Modal
                            :show-close-button="true"
                            class="edit-exam__modal"
                            :is-dark-mode="isDarkMode"
                            @close="showDeleteConfirm = false"
                        >
                            <div class="edit-exam__modal-title">
                                Delete {{ examMetadata && examMetadata.nativeAppName }} Exam?
                            </div>
                            <div v-dark class="edit-exam__modal-message">
                                This will delete all of your progress. It is irreversible. Are you sure you want to
                                delete?
                            </div>
                            <div class="edit-exam__modal-buttons">
                                <PocketButton
                                    class="edit-exam__modal-button"
                                    type="secondary"
                                    :is-dark-mode="isDarkMode"
                                    @click="showDeleteConfirm = false"
                                >
                                    Never mind
                                </PocketButton>
                                <PocketButton
                                    type="primary-red"
                                    class="edit-exam__modal-button"
                                    :is-loading="isLoading"
                                    :is-dark-mode="isDarkMode"
                                    @click="deleteExam"
                                >
                                    Yes, Delete
                                </PocketButton>
                            </div>
                        </Modal>
                    </template>
                </ModalContainer>
            </Portal>
            <template v-if="activeTab === 'General'">
                <div v-dark class="edit-exam__note">
                    To edit your subscription, go to 
                    <RouterLink
                        v-slot="{ href, navigate }"
                        custom
                        :to="{ name: 'purchases' }"
                    >
                        <PocketLink
                            class="edit-exam__link"
                            type="tertiary-small"
                            :href="href"
                            :is-dark-mode="isDarkMode"
                            @click="navigate"
                            @mousedown.prevent
                        >
                            <span>Purchases</span>
                        </PocketLink>
                    </RouterLink>.
                </div>
                <div class="edit-exam__progress-top">
                    <div class="edit-exam__progress-top-title">
                        Study Progress
                    </div>
                    <PocketLink
                        v-if="totalAnswers"
                        type="tertiary-small"
                        :disabled="isLoading"
                        :is-dark-mode="isDarkMode"
                        @click="showResetProgressModal = true"
                    >
                        Reset Progress
                    </PocketLink>
                </div>
                <div v-dark class="edit-exam__progress">
                    <div v-dark class="edit-exam__progress-container">
                        <div
                            v-dark
                            class="edit-exam__progress-bar"
                            :style="{ width: `${progressPercent}%` }"
                        />
                    </div>
                    <div v-dark class="edit-exam__progress-percent">
                        {{ (!progressPercent || isNaN(Number(progressPercent))) ? '0.0' : progressPercent }}%
                    </div>
                </div>
            </template>
            <template v-if="activeTab === 'Prep Content' && !isLoading">
                <div
                    v-if="examVersions.length > 1 && !hasSeenLatest && !isLoading"
                    v-dark
                    class="edit-exam__review-version-card"
                    tabindex="0"
                >
                    <div class="edit-exam__review-version-card-header">
                        <div v-dark class="edit-exam__review-version-card-name">
                            {{ examMetadata.nativeAppName }}
                        </div>
                        <PocketLink
                            v-dark
                            class="edit-exam__review-version-card-link"
                            @click="showReviewNewVersions = true"
                            @mousedown.prevent
                        >
                            Review Versions
                        </PocketLink>
                    </div>
                    <div v-dark class="edit-exam__review-version-card-info">
                        Version {{ examMetadata.version }}
                    </div>
                    <div v-dark class="edit-exam__review-version-card-description">
                        <Icon class="edit-exam__review-version-card-icon" type="warning" />
                        New Exam Version Available
                    </div>
                </div>
                <div v-if="(examVersions.length > 1 && hasSeenLatest && !isLoading) || 
                         (examVersions.length === 1 && !isLoading)" 
                     class="edit-exam__versions"
                >
                    <ExamVersionCard
                        v-if="currentExamVersion"
                        :key="currentExamVersion.objectId"
                        :exam="currentExamVersion"
                        :is-selected-version="
                            selectedMajorVersion && currentExamVersion.version.startsWith(`${selectedMajorVersion}.`)
                        "
                        :is-active-exam="currentExamVersion.version.startsWith(`${originalMajorVersion}.`)"
                        :is-latest-version="selectedMajorVersion === currentMajorVersion"
                        :hasMultipleVersions="examVersions.length > 1"
                        @clickExamVersion="clickExamVersion"
                        @clickReviewExamVersions="showReviewNewVersions = true"
                    />
                </div>
            </template>
            <template v-if="activeTab === 'Exam Date'">
                <div class="edit-exam__label">
                    Set your exam date.
                </div>
                <div v-dark class="edit-exam__p">
                    We'll help you count down and send you encouragement on exam day!
                </div>
                <Calendar
                    v-model="examDate"
                    label="Scheduled Exam Date"
                    class="edit-exam__exam-date"
                    :is-dark-mode="isDarkMode"
                />
            </template>
            <template #details>
                <PocketButton
                    :disabled="isLoading"
                    type="secondary"
                    class="edit-exam__delete"
                    :is-dark-mode="isDarkMode"
                    @click="clickDelete"
                >
                    Delete Exam
                </PocketButton>
            </template>
            <template #action>
                <PocketButton 
                    :disabled="originalExamDate === examDate"
                    :is-loading="isLoading"
                    class="edit-exam__save--desktop"
                    :is-dark-mode="isDarkMode"
                    @click="saveSettings"
                >
                    Save Settings
                </PocketButton>
                <PocketButton 
                    :disabled="originalExamDate === examDate"
                    :is-loading="isLoading"
                    class="edit-exam__save--mobile"
                    :is-dark-mode="isDarkMode"
                    @click="saveSettings"
                >
                    Save
                </PocketButton>
            </template>
        </SidePanel>
    </Portal>
    <ReviewNewVersionsSidePanel
        v-if="showReviewNewVersions && currentExamVersion"
        key="study"
        :passed-exam-versions="examVersions"
        :current-exam-version="currentExamVersion"
        :uem="userExamMetadata"
        :exam-metadata="examMetadata"
        @close="showReviewNewVersions = false"
    />
</template>

<script lang="ts">
import { examMetadataModule } from '@/store/examMetadata/module'
import { progressModule } from '@/store/progress/module'
import { questionModule } from '@/store/question/module'
import { quizModule } from '@/store/quiz/module'
import { subscriptionModule } from '@/store/subscription/module'
import { toastModule } from '@/store/toast/module'
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop, Emit, Watch } from 'vue-facing-decorator'
import ResetProgressModal from '@/components/Stats/ResetProgressModal.vue'
import { bundleModule } from '@/store/bundle/module'
import ExamVersionCard from '@/components/Settings/EditExam/ExamVersionCard.vue'
import type { Study } from '@pocketprep/types'
import ReviewNewVersionsSidePanel from '../Study/ReviewNewVersionsSidePanel.vue'

@Component({
    components: {
        SidePanel: UIKit.SidePanel,
        PocketButton: UIKit.Button,
        PocketLink: UIKit.Link,
        PocketInput: UIKit.Input,
        Calendar: UIKit.Calendar,
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
        Icon: UIKit.Icon,
        ResetProgressModal,
        ExamVersionCard,
        ReviewNewVersionsSidePanel,
    },
})
export default class EditExamSidePanel extends Vue {
    @Prop() examId!: string
    @Prop() isLatestVersion!: boolean
    @Prop({ default: null }) isActiveExam!: boolean

    examDate: Date | null = null
    originalExamDate: Date | null = null
    selectedMajorVersion: string | null = null
    currentMajorVersion: string | null = null
    originalMajorVersion: string | null = null
    isLoading = true
    isResetLoading = false
    activeTab = 'General'
    showDeleteConfirm = false
    showCannotDeleteModal = false
    showResetProgressModal = false
    showReviewNewVersions = false
    uem: Study.Class.UserExamMetadataJSON | undefined = undefined
    tabs = [
        'General',
        'Prep Content',
        'Exam Date',
    ]

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }
    
    get sidePanelName () {
        return this.examMetadata
            ? `${this.examMetadata.nativeAppName} Settings`
            : 'Exam Settings'
    }

    get examMetadata () {
        return examMetadataModule.getters.getExamMetadataById()[this.examId]
    }

    get userExamMetadata () {
        return this.examMetadata 
            && userExamMetadataModule.getters.getUserExamMetadataByGuid()[this.examMetadata.examGuid]
    }

    get currentUserExamMetadata () {
        return userExamMetadataModule.getters.getCurrentUserExamMetadata()
    }

    get examBundle () {
        return bundleModule.getters.getBundles().find(b => b.exams.find(e => e.objectId === this.examId))
    }

    get examVersions () {
        return examMetadataModule.getters.getExamMetadata()
            .filter(e => 
                e.examGuid === this.userExamMetadata?.examGuid 
                && (
                    this.examBundle?.exams.find(be => e.objectId === be.objectId)
                    || this.userExamMetadata.examVersion?.startsWith(`${e.version.split('.')[0]}.`)
                )
            )
            .sort((a, b) => 
                a.objectId === this.userExamMetadata?.objectId 
                    ? 1 
                    : -a.version.localeCompare(b.version, undefined, { numeric: true })
            )
    }

    get currentExamVersion () {
        const examMajorVersion = this.userExamMetadata?.examVersion?.split('.')[0]
        return this.examVersions.find(e => e.version.split('.')[0] === examMajorVersion)
    }

    get progressByExamGuid () {
        return progressModule.getters.getProgressByExamGuid()
    }

    get totalAnswers () {
        const questionFilter = this.hasSubscription ? 'all' : 'free'
        return this.currentUserExamMetadata?.examGuid === this.examMetadata?.examGuid
            ? Object.keys(quizModule.getters.getLatestAnswers({ questionFilter  })).length
            : this.progressByExamGuid[this.examMetadata.examGuid]?.answered || 0
    }

    get hasSubscription () {
        return !!subscriptionModule.getters.getSubscriptionForExamId(this.examMetadata?.objectId)
    }

    get totalQuestions () {
        return this.currentUserExamMetadata?.examGuid === this.examMetadata?.examGuid
            ? this.hasSubscription
                ? (this.examMetadata?.itemCount || 0) - (this.examMetadata?.archivedCount || 0)
                : this.examMetadata?.specialQuestions || 0
            : this.progressByExamGuid[this.examMetadata.examGuid]?.total || 0
    }

    get progressPercent () {
        const totalQuestions = this.totalQuestions
        const totalAnswers = this.totalAnswers
        const percent = (totalAnswers / totalQuestions) * 100

        return Math.round(percent) === 100 ? 100 : percent.toPrecision(2)
    }

    get hasSeenLatest () {
        this.currentMajorVersion = this.examVersions[0].version.split('.')[0]
        return this.userExamMetadata?.upgradesSeen?.includes(this.currentMajorVersion)
    }

    async mounted () {
        await Promise.all([
            examMetadataModule.actions.fetchExamMetadata(),
            userExamMetadataModule.actions.fetchUserExamMetadata(),
            userModule.actions.fetchUserData(),
            questionModule.actions.fetchSerialQuestionInfoLib(),
        ])

        const scheduledExamDate = this.userExamMetadata?.scheduledExamDate
        this.examDate = scheduledExamDate ? new Date(scheduledExamDate.iso) : null
        this.originalExamDate = this.examDate
        const examMajorVersion = this.userExamMetadata?.examVersion?.split('.')[0]
        this.selectedMajorVersion = examMajorVersion ? examMajorVersion : null
        this.originalMajorVersion = this.selectedMajorVersion
        this.uem = this.userExamMetadata

        this.isLoading = false
    }

    clickDelete () {
        if (Object.values(userExamMetadataModule.getters.getUserExamMetadataByGuid()).length <= 1) {
            this.showCannotDeleteModal = true
        } else {
            this.showDeleteConfirm = true
        }
    }

    selectTab (tab: string) {
        this.activeTab = tab
    }

    async saveSettings () {
        const uem = this.userExamMetadata

        if (!uem) {
            throw new Error('No current userExamMetadata.')
        }

        this.isLoading = true
        const updatePromises: Promise<unknown>[] = []
        if (this.examDate?.getTime() !== this.originalExamDate?.getTime() && this.examBundle) {
            updatePromises.push(userExamMetadataModule.actions.setScheduledExamDate({
                scheduledExamDate: this.examDate || undefined,
                examNativeName: this.examMetadata.nativeAppName,
                uemCreatedAt: this.userExamMetadata.createdAt,
                uemObjectId: this.userExamMetadata.objectId,
                bundle: this.examBundle,
                hasSubscription: this.hasSubscription,
            }))
        }
        await Promise.all(updatePromises)

        if (this.examDate?.getTime() !== this.originalExamDate?.getTime()) {
            toastModule.actions.displayToast({
                title: 'Exam date saved successfully',
            })
        }

        this.emitClose()
        this.isLoading = false
    }

    async deleteExam () {
        this.isLoading = true
        const uemToDelete = this.userExamMetadata
        const isDeletingCurrentExam = 
            this.userExamMetadata?.objectId === userExamMetadataModule.getters.getCurrentUserExamMetadata()?.objectId
        // if deleting current exam, switch to any new one
        if (isDeletingCurrentExam) {
            const newUEM = Object.values(userExamMetadataModule.getters.getUserExamMetadataByGuid()).find(
                uem => uem.objectId !== this.userExamMetadata?.objectId
            )

            if (!newUEM) {
                throw new Error('No new exam to switch to.')
            }
            await userModule.actions.updateCurrentExamGuid(newUEM.examGuid)
        }
        if (this.examMetadata) {
            await userExamMetadataModule.actions.resetExamProgress(this.examMetadata)
        }
        if (uemToDelete) {
            await userExamMetadataModule.actions.deleteUserExamMetadata(uemToDelete)
        }

        toastModule.actions.displayToast({
            title: `${this.examMetadata?.nativeAppName} deleted successfully`,
            subtext: 'If you would like to restart  this exam, you can re-add it at any time.',
        })
        this.emitClose()
        this.isLoading = false
    }

    async resetProgress () {
        this.isResetLoading = true
        if (this.examMetadata) {
            await userExamMetadataModule.actions.resetExamProgress(this.examMetadata)
        }
        toastModule.actions.displayToast({
            title: 'Your progress was reset',
        })
        this.showResetProgressModal = false
        this.isResetLoading = false
    }

    async clickExamVersion (exam: Study.Class.ExamMetadataJSON) {
        this.selectedMajorVersion = exam.version.split('.')[0]
    }

    async closeReview () {
        this.showReviewNewVersions = false
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Watch('currentExamVersion')
    currentExamVersionChange () {
        this.selectedMajorVersion = this.currentExamVersion?.version.split('.')[0] || null
    }

    // watching when the review side panel open or closes
    // to get most recent user data
    @Watch('showReviewNewVersions')
    async checkIfHasCurrentExamMetadata () {
        this.isLoading = true
        await Promise.all([
            examMetadataModule.actions.fetchExamMetadata(),
            bundleModule.actions.fetchBundles(),
            userExamMetadataModule.actions.fetchUserExamMetadata(),
        ])
        this.isLoading = false
    }
}
</script>

<style lang="scss" scoped>
.edit-exam {
    &__modal {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 80px;
        width: 392px;
        padding: 35px 0 26px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include breakpoint(black-bear) {
            width: 356px;
            height: auto;
            max-height: 638px;
            top: 10px;
        }
    }

    &__modal-title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        width: 309px;
        margin: 0 auto 12px;
    }

    &__modal-message {
        font-size: 15px;
        line-height: 22px;
        color: $slate-03;
        margin-bottom: 29px;
        width: 309px;

        &--dark {
            color: $white;
        }
    }

    &__modal-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__modal-button {
        margin: 0 6px;
    }

    &__delete {
        margin-left: -20px;
    }

    &__label {
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 12px;
    }

    &__p {
        font-size: 14px;
        line-height: 18px;
        color: $slate-03;
        margin-bottom: 12px;

        &--dark {
            color: $pewter;
        }
    }

    &__warning {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 31px;
        color: $red-pegasus;

        &--dark {
            color: $rosa;
        }
    }

    &__exam-date {
        width: calc(100% + 24px);
        margin-left: -12px;
        margin-right: -12px;
        margin-top: 23px;
    }

    &__note {
        color: $slate-03;
        border-bottom: 1px solid $gray-divider;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 18px;
        padding: 0 12px 16px;
        box-sizing: border-box;
        width: calc(100% + 24px);
        margin-left: -12px;
        margin-right: -12px;

        &--dark {
            color: $pewter;
            border-bottom-color: $slate;
        }
    }

    &__link {
        text-decoration: none;
        font-weight: 500;
    }

    &__progress-top {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 7px;
    }

    &__progress {
        width: calc(100% + 24px);
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $white;
        border-radius: 6px;
        margin-left: -12px;
        margin-right: -12px;
        padding: 0 12px;
        box-sizing: border-box;

        &--dark {
            background: $charcoal;
        }
    }

    &__progress-container {
        width: 324px;
        height: 14px;
        background: $gray-progress-background;
        border-radius: 2px;
        margin-right: 13px;
        position: relative;

        &--dark {
            background: $moonlit-ocean;
        }

        @include breakpoint(black-bear) {
            width: 100%;
        }
    }

    &__progress-bar {
        position: absolute;
        left: 0;
        top: 0;
        height: 14px;
        border-radius: 2px;
        background: $brand-black;

        &--dark {
            background: $pewter;
        }
    }

    &__progress-percent {
        font-size: 15px;
        line-height: 15px;
    }

    &__save {
        &--mobile {
            display: none;

            @include breakpoint(black-bear) {
                display: block;
            }
        }

        &--desktop {
            display: block;

            @include breakpoint(black-bear) {
                display: none;
            }
        }
    }

    &__review-version-card {
        box-shadow: 0 1px 6px 0 rgba($gray-divider, 0.6);
        background: $white;
        border-radius: 5px;
        width: calc(100% + 24px);
        margin-left: -12px;
        margin-right: -12px;
        padding: 14px 15px;
        box-sizing: border-box;
        margin-bottom: 16px;
        position: relative;
        outline: none;

        &--dark {
            background-color: $moonlit-ocean;
            box-shadow: 0 1px 6px 0 rgba($charcoal, 0.3);
        }
    }

    &__review-version-card-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
    }

    &__review-version-card-name {
        font-size: 17px;
        line-height: 20px;
        font-weight: 600;
        color: $brand-black;
        width: 235px;

        &--dark {
            color: $fog;
        }
    }

    &__review-version-card-info {
        letter-spacing: -0.3px;
        color: $ash;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        margin-bottom: 13px;

        &--dark {
            color: $fog;
        }

        span {
            font-weight: 600;
        }
    }

    &__review-version-card-description {
        display: flex;
        color: $brand-black;
        font-size: 14px;
        line-height: 19px;
        text-align: left;

        &--dark {
            color: $white;
        }
    }

    &__review-version-card-icon{
        margin-right: 7px;
        margin-top: 2px;
    }
}
</style>