import { ref, type MaybeRefOrGetter, toValue } from 'vue'

export const useReadinessMessage = (params: {
    readinessScore: MaybeRefOrGetter<number>
    daysUntilExam: MaybeRefOrGetter<number>
    percentQuestionBankCompleted: MaybeRefOrGetter<number>
    isActiveLast10Days: MaybeRefOrGetter<boolean>
}) => {
    const readinessScore = toValue(params.readinessScore)
    const daysUntilExam = toValue(params.daysUntilExam)
    const percentQuestionBankCompleted = toValue(params.percentQuestionBankCompleted)
    const isActiveLast10Days = toValue(params.isActiveLast10Days)

    const displayableReadinessScore = Math.round((readinessScore * 100) / 2)

    // Based on the following linear regression function for our recent readiness pass precision data
    // y = 18.63x + 81.99
    const percentChanceOfPassing = Math.round(18.63 * readinessScore + 81.99)

    const summary = ref('')
    const suggestion = ref('')

    if (!isActiveLast10Days) {
        // Not active in last 10 days
        summary.value = `You're at risk for not being ready for your exam in ${
            daysUntilExam
        } day${ daysUntilExam === 1 ? '' : 's'} because you haven't studied in the last 10 days.`
        suggestion.value = '💡 Jump back in with a Quick 10 quiz!'
    } else if (
        daysUntilExam < 7
        && percentQuestionBankCompleted >= 50
        && readinessScore < 0.5
    ) {
        // Close exam date, high answered %, low score
        summary.value = 'You\'re in the home stretch. We\'re rooting for you!'
    } else if (
        daysUntilExam < 7
        && percentQuestionBankCompleted < 50
        && readinessScore < 0.5
    ) {
        // Close exam date, low answered %, low score
        summary.value = `A score of ${
            displayableReadinessScore
        } means you still might be working towards being ready for exam day. You've answered ${
            percentQuestionBankCompleted
        }% of the question bank.`
        suggestion.value = '💡 To improve the accuracy of your score, aim to answer at least 50% of the question bank.'
    } else if (
        daysUntilExam >= 7
        && percentQuestionBankCompleted >= 50
        && readinessScore < 0.5
    ) {
        // Distant exam date, high answered %, low score
        summary.value = `A score of ${
            displayableReadinessScore
        } means you are working on fundamentals, and are on track to pass your exam in ${
            daysUntilExam
        } day${ daysUntilExam === 1 ? '' : 's'}.`
        suggestion.value = '💡 Focus on your weakest subjects, and check your score again in a few days.'
    } else if (
        daysUntilExam >= 7
        && percentQuestionBankCompleted < 50
        && readinessScore < 0.5
    ) {
        // Distant exam date, low answered %, low score
        summary.value = `A score of ${
            displayableReadinessScore
        } means you are working on fundamentals and progressing well.
        Your score is based on a variety of factors and is exam specific.`
        suggestion.value = '💡 To improve the accuracy of your score, aim to answer at least 50% of the question bank.'
    } else if (
        daysUntilExam < 7
        && percentQuestionBankCompleted >= 50
        && readinessScore >= 0.5
        && readinessScore < 0.8
    ) {
        // Close exam date, high answered %, medium score
        summary.value = `People who take their exam with a score of ${
            displayableReadinessScore
        } pass ${
            percentChanceOfPassing
        }% of the time.`
        suggestion.value = '💡 Keep up the great work. We\'re rooting for you!'
    } else if (
        daysUntilExam < 7
        && percentQuestionBankCompleted < 50
        && readinessScore >= 0.5
        && readinessScore < 0.8
    ) {
        // Close exam date, low answered %, medium score
        summary.value = `A score of ${
            displayableReadinessScore
        } means you are well on your way to being ready for exam day. You've answered ${
            percentQuestionBankCompleted
        }% of the question bank.`
        suggestion.value = '💡 To improve the accuracy of your score, aim to answer at least 50% of the question bank.'
    } else if (
        daysUntilExam >= 7
        && percentQuestionBankCompleted >= 50
        && readinessScore >= 0.5
        && readinessScore < 0.8
    ) {
        // Distant exam date, high answered %, medium score
        summary.value = `A score of ${
            displayableReadinessScore
        } means you are well on your way to being ready for exam day.
        Your score is based on a variety of factors and is exam specific.`
        suggestion.value = '💡 Try taking a Build Your Own quiz with your 2 weakest subjects selected.'
    } else if (
        daysUntilExam >= 7
        && percentQuestionBankCompleted < 50
        && readinessScore >= 0.5
        && readinessScore < 0.8
    ) {
        // Distant exam date, low answered %, medium score
        summary.value = `A score of ${
            displayableReadinessScore
        } means you are on your way to being ready for exam day. You've answered ${
            percentQuestionBankCompleted
        }% of the question bank.`
        suggestion.value = '💡 To improve the accuracy of your score, answer at least 50% of the question bank.'
    } else if (
        daysUntilExam < 7
        && percentQuestionBankCompleted >= 50
        && readinessScore >= 0.8
    ) {
        // Close exam date, high answered %, high score
        summary.value = `A score of ${
            displayableReadinessScore
        } means if you took your exam today, you have a great chance of passing.
        Your score is calculated based on a variety of factors and is exam specific.`
        suggestion.value = `💡 Focus on your weakest subjects until exam day. ${ daysUntilExam } day${
            daysUntilExam === 1 ? '' : 's'
        } to go!`
    } else if (
        daysUntilExam < 7
        && percentQuestionBankCompleted < 50
        && readinessScore >= 0.8
    ) {
        // Close exam date, low answered %, high score
        summary.value = `A score of ${
            displayableReadinessScore
        } means if you took your exam today, you have a great chance of passing. You've answered ${
            percentQuestionBankCompleted
        }% of the question bank.`
        suggestion.value = `💡 To improve the accuracy of your score, answer at least 50% of the question bank. ${
            daysUntilExam
        } day${ daysUntilExam === 1 ? '' : 's'} to go!`
    } else if (
        daysUntilExam >= 7
        && percentQuestionBankCompleted >= 50
        && readinessScore >= 0.8
    ) {
        // Distant exam date, high answered %, high score
        summary.value = `A score of ${
            displayableReadinessScore
        } means you are on track to pass your exam in ${
            daysUntilExam
        } day${ daysUntilExam === 1 ? '' : 's'}. Your score is based on a variety of factors and is exam specific.`
    } else if (
        daysUntilExam >= 7
        && percentQuestionBankCompleted < 50
        && readinessScore >= 0.8
    ) {
        // Distant exam date, low answered %, high score
        summary.value = `A score of ${
            displayableReadinessScore
        } means you are on track to pass your exam in ${
            daysUntilExam
        } day${ daysUntilExam === 1 ? '' : 's'}. Your score is based on a variety of factors and is exam specific.`
        suggestion.value = '💡 To improve the accuracy of your score, answer at least 50% of the question bank.'
    }

    return {
        summary,
        suggestion,
    }
}

export const useOnOffTrackMessaging = (params: {
    readinessScore: MaybeRefOrGetter<number>
    daysUntilExam: MaybeRefOrGetter<number>
    percentQuestionBankCompleted: MaybeRefOrGetter<number>
    isActiveLast10Days: MaybeRefOrGetter<boolean>
}) => {
    const readinessScore = toValue(params.readinessScore)
    const daysUntilExam = toValue(params.daysUntilExam)
    const isActiveLast10Days = toValue(params.isActiveLast10Days)

    const status = ref<'on-track' | 'off-track'>('on-track')
    const summary = ref('')
    const suggestion = ref('')

    if (!isActiveLast10Days) {
        // Not active in last 10 days
        status.value = 'off-track'
        summary.value = `You're at risk for not being ready for your exam in ${
            daysUntilExam
        } day${ daysUntilExam === 1 ? '' : 's'} because you haven't studied in the last 10 days.`
        suggestion.value = '💡 Jump back in with a Quick 10 quiz!'
    } else if (
        daysUntilExam < 7
        && readinessScore < 0.5
    ) {
        // Close exam date, low score
        status.value = 'off-track'
        summary.value = `Based on your study activity and performance,
        you're at risk of not being ready for your exam in ${ daysUntilExam } day${ daysUntilExam === 1 ? '' : 's'}.`
        suggestion.value = '💡 To get back on track, take time to focus on your weakest subjects.'
    } else if (
        daysUntilExam >= 7
        && daysUntilExam < 14
        && readinessScore < 0.48
    ) {
        // More distant exam date, low score
        status.value = 'off-track'
        summary.value = `Based on your study activity and performance,
        you're at risk of not being ready for your exam in ${ daysUntilExam } day${ daysUntilExam === 1 ? '' : 's'}.`
        suggestion.value = '💡 To get back on track, take time to focus on your weakest subjects.'
    } else if (
        daysUntilExam < 7
        && readinessScore >= 0.5
    ) {
        // Close exam date, high score
        status.value = 'on-track'
        summary.value = `Based on your study activity, you're on track to pass your exam in ${
            daysUntilExam
        } day${ daysUntilExam === 1 ? '' : 's'}.`
        suggestion.value = '💡 Focus on your weakest subjects until exam day. We\'re rooting for you!'
    } else if (
        daysUntilExam >= 7
        && daysUntilExam < 14
        && readinessScore >= 0.48
    ) {
        // More distant exam date, high score
        status.value = 'on-track'
        summary.value = `Based on your study activity, you're on track to pass your exam in ${
            daysUntilExam
        } day${ daysUntilExam === 1 ? '' : 's'}.`
    } else if (
        daysUntilExam >= 14
    ) {
        // Very distant exam date
        status.value = 'on-track'
        summary.value = `Based on your study activity, you're on track to pass your exam in ${
            daysUntilExam
        } day${ daysUntilExam === 1 ? '' : 's'}.`
    }

    return {
        status,
        summary,
        suggestion,
    }
}
