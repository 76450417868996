<template>
    <div class="keyword-definition-hint">
        <div class="keyword-definition-hint__container">
            <img
                v-if="isDarkMode"
                class="keyword-definition-hint__image"
                src="@/assets/quiz/glasses-with-blob-dm.png"
            />
            <img
                v-else
                class="keyword-definition-hint__image"
                src="@/assets/quiz/glasses-with-blob.png"
            />
            <div class="keyword-definition-hint__text" v-dark>
                After answering, look for
                <span class="keyword-definition-hint__highlight" v-dark>highlighted</span>
                keywords to view definitions.
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop } from 'vue-facing-decorator'

@Component({
    components: {
        Icon: UIKit.Icon,
    },
})
export default class KeywordDefinitionHint extends Vue {
    @Prop() isDarkMode?: boolean
}
</script>

<style lang="scss" scoped>
.keyword-definition-hint {
    position: absolute;
    top: 111px;
    left: 8%;
    height: 100px;
    width: 100px;
    z-index: 1;
    cursor: default;

    &__container {
        position: relative;
    }

    &__image {
        position: absolute;
        height: 101px;
    }

    &__text {
        position: absolute;
        width: 175px;
        height: 60px;
        font-size: 15px;
        line-height: 20px;
        top: 28px;
        left: 36px;
        font-weight: 600;
        color: $slate;

        &--dark {
            color: $cloudy;
        }
    }

    &__highlight {
        height: 23px;
        box-sizing: border-box;
        background-color: $manilla;
        border-bottom: 1px solid $banana-bread;
        border-radius: 2px 2px 0 0;
        padding: 0 2px;
        margin-left: -2px;
        color: $pickled-bluewood;

        &--dark {
            color: $fog;
            background-color: rgba($banana-bread, 0.3);
            border-color: rgba($banana-bread, 0.6);
        }
    }
}
</style>