<template>
    <div v-dark class="exam-menu-dropdown">
        <ExamCard
            v-for="(exam, index) in examMetadata"
            :key="JSON.stringify(exam)"
            ref="examCards"
            :is-premium="isExamPremium(exam.objectId)"
            :exam="exam"
            :show-menu-arrow="index === 0"
            menu-arrow-direction="up"
            theme="grayscale"
            :role="index === 0 ? 'button' : false"
            :aria-expanded="index === 0 ? 'true' : false"
            :is-focusable="true"
            @click="clickedExamCard(exam)"
            @keydown.enter="clickedExamCard(exam)"
            @mousedown.prevent
        />
        <div
            ref="examCard-add"
            v-dark
            tabindex="0"
            class="exam-menu-dropdown__add"
            @click="emitAddExam"
            @keydown.enter="emitAddExam"
            @mousedown.prevent
        >
            + Add Exam
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import ExamCard from '@/components/Head/ExamCard.vue'
import type { Study } from '@pocketprep/types'
import { subscriptionModule } from '@/store/subscription/module'
import type { ComponentPublicInstance } from 'vue'

@Component({
    components: {
        ExamCard,
    },
})
export default class ExamMenuDropdown extends Vue {
    @Prop() examMetadata!: Study.Class.ExamMetadataJSON[]
    @Prop() currentExamMetadata!: Study.Class.ExamMetadataJSON
    @Prop() focusFirstCard!: boolean

    currentFocusIndex = 0

    getExamCardElFromIndex (index: number) {
        const examCardRefs = (this.$refs['examCards'] || []) as ComponentPublicInstance[]
        const examCardRef = examCardRefs[index] as ComponentPublicInstance || undefined
        return examCardRef?.$el as HTMLElement | undefined
    }

    mounted () {
        // close side panel on `esc` press
        window.addEventListener('keydown', this.keydownListener, true)

        if (this.focusFirstCard) {
            this.$nextTick(() => {
                const firstCardEl = this.getExamCardElFromIndex(0)
                firstCardEl?.focus()
            })
        }
    }

    beforeUnmount () {
        window.removeEventListener('keydown', this.keydownListener, true)
    }

    async clickedExamCard (exam: Study.Class.ExamMetadataJSON) {
        this.emitClickExamCard(exam)
    }

    keydownListener (e: KeyboardEvent) {
        if (e.key === 'Escape') {
            e.stopPropagation()
            this.emitClose()
        }
        if (e.key === 'ArrowDown') {
            e.preventDefault()
            if (this.currentFocusIndex < (this.examMetadata.length - 1)) {
                // If not yet at the end, move one item down the list
                this.getExamCardElFromIndex(this.currentFocusIndex + 1)?.focus()
                this.currentFocusIndex++
            } else if (this.currentFocusIndex === (this.examMetadata.length - 1)) {
                // If on the last exam, move to the Add Exam button
                const addExamBtn = this.$refs['examCard-add'] as HTMLElement | undefined
                addExamBtn?.focus()
                this.currentFocusIndex++
            } else {
                // If on the last exam button, move back to the top
                this.getExamCardElFromIndex(0)?.focus()
                this.currentFocusIndex = 0
            }
        }
        if (e.key === 'ArrowUp') {
            e.preventDefault()
            if (this.currentFocusIndex === this.examMetadata.length) {
                // If on the Add Exam button, move to the last exam
                this.getExamCardElFromIndex(this.examMetadata.length - 1)?.focus()
                this.currentFocusIndex = this.examMetadata.length - 1
            } else if (this.currentFocusIndex > 0) {
                // If not already at the top, move one item up the list
                this.getExamCardElFromIndex(this.currentFocusIndex - 1)?.focus()
                this.currentFocusIndex--
            } else {
                // If at the top, move back to the Add Exam button
                const addExamBtn = this.$refs['examCard-add'] as HTMLElement | undefined
                addExamBtn?.focus()
                this.currentFocusIndex = this.examMetadata.length
            }
        }
    }

    isExamPremium (examId: string) {
        return !!subscriptionModule.getters.getSubscriptionForExamId(examId)
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('addExam')
    emitAddExam () {
        return true
    }

    @Emit('clickExamCard')
    emitClickExamCard (exam: Study.Class.ExamMetadataJSON) {
        return exam
    }
}
</script>

<style lang="scss" scoped>
.exam-menu-dropdown {
    border-radius: 4px;
    position: absolute;
    max-height: calc(100vh - 20px);
    overflow-y: auto;
    z-index: 1;
    left: 0;
    top: 0;
    border: 1px solid $gray-divider;
    background: $white;
    box-shadow: 0 6px 22px 0 rgba($brand-black, 0.2);
    width: 320px;

    @include breakpoint(black-bear) {
        width: calc(100% + 52px);
        top: 1px;
    }

    & > div {
        border-bottom: 1px solid $gray-divider;

        &:last-child {
            border-bottom: 0;
        }
    }

    &--dark {
        background: $charcoal;
        border-color: rgba($fog, 0.25);
        box-shadow: 0 6px 22px 0 rgba($jet, 0.6);

        & > div {
            border-bottom-color: rgba($fog, 0.25);
        }
    }

    &__add {
        color: $brand-blue;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.32px;
        font-weight: 600;
        padding: 9px 8px;
        cursor: pointer;
        user-select: none;
        outline: none;
        position: relative;

        &:hover {
            background: $gray-background;
        }

        &:focus::before {
            content: '';
            left: 0;
            top: 0;
            position: absolute;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            border-radius: 6px;
            border: 1px solid $brand-blue;
        }

        &--dark {
            color: $banana-bread;

            &:focus::before {
                border-color: $banana-bread;
            }

            &:hover {
                background: $brand-black;
            }
        }
    }
}
</style>