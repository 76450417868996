import MediaBreakpoints from '@pocketprep/ui-kit/pocketprep-export.module.scss'

export type TBreakpoint = 'black-bear' | 'brown-bear' | 'grizzly-bear' | 'polar-bear' | 'kodiak-bear'

export type TScreenState = {
    breakpoint: TBreakpoint
}

export const getBreakpoint = () => {
    let breakpoint: TBreakpoint = 'kodiak-bear'
    if (window.matchMedia(`(max-width: ${MediaBreakpoints.polarBear}px)`).matches) {
        breakpoint = 'polar-bear'
    }
    if (window.matchMedia(`(max-width: ${MediaBreakpoints.grizzlyBear}px)`).matches) {
        breakpoint = 'grizzly-bear'
    }
    if (window.matchMedia(`(max-width: ${MediaBreakpoints.brownBear}px)`).matches) {
        breakpoint = 'brown-bear'
    }
    if (window.matchMedia(`(max-width: ${MediaBreakpoints.blackBear}px)`).matches) {
        breakpoint = 'black-bear'
    }
    return breakpoint
}

export const getInitialScreenState = (): TScreenState => {
    const breakpoint = getBreakpoint()

    return {
        breakpoint,
    }
}

const moduleState = getInitialScreenState()

export default moduleState
