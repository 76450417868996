<template>
    <Portal to="modal">
        <ModalContainer
            key="examReadinessSurveyModal"
            :is-dark-mode="isDarkMode"
            :show-close-button="true"
            @close="closeModal"
        >
            <template #modal>
                <Modal
                    class="exam-readiness-survey"
                    :show-close-button="true"
                    :is-dark-mode="isDarkMode"
                    @close="closeModal"
                >
                    <div class="exam-readiness-survey__content">
                        <div class="exam-readiness-survey__title-section" v-dark="isDarkMode">
                            <img
                                class="exam-readiness-survey__title-emoji"
                                src="@/assets/surveys/emoji-writing.png"
                            >
                            <div class="exam-readiness-survey__title">
                                Give us your feedback!
                            </div>
                        </div>
                        <div class="exam-readiness-survey__form">
                            <PocketTextarea
                                v-model="feedback"
                                label="How will you know when you're ready to take your exam?"
                                class="exam-readiness-survey__field"
                                :is-dark-mode="isDarkMode"
                                height="108"
                                @keydown.enter="submitForm"
                            />
                        </div>
                        <div class="exam-readiness-survey__actions">
                            <PocketButton
                                class="exam-readiness-survey__dismiss-btn"
                                :is-dark-mode="isDarkMode"
                                @click="closeModal"
                                type="secondary"
                            >
                                Cancel
                            </PocketButton>
                            <PocketButton
                                class="exam-readiness-survey__submit-btn"
                                :disabled="isSubmitDisabled"
                                :is-dark-mode="isDarkMode"
                                @click="submitForm"
                            >
                                Submit
                            </PocketButton>
                        </div>
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import { analyticsModule } from '@/store/analytics/module'
import type { Study } from '@pocketprep/types'
import { toastModule } from '@/store/toast/module'

@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        PocketButton: UIKit.Button,
        Modal: UIKit.Modal,
        PocketTextarea: UIKit.Textarea,
    },
})
export default class ExamReadinessSurvey extends Vue {
    @Prop() examMetadata!: Study.Class.ExamMetadataJSON
    @Prop() stats!: {
        answeredCount: number
        unansweredCount: number
        studyScore: number
        totalQuizSeconds: number
        daysUntilExam: number
    }

    feedback = ''

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get isSubmitDisabled () {
        return !this.feedback
    }

    submitForm () {
        analyticsModule.actions.amplitudeTrack('Exam Readiness Survey Answered', {
            examReadinessFeedback: this.feedback,
            examGuid: this.examMetadata.examGuid,
            examVersion: this.examMetadata.version,
            answeredCount: this.stats.answeredCount,
            unansweredCount: this.stats.unansweredCount,
            studyScore: this.stats.studyScore,
            totalQuizSeconds: this.stats.totalQuizSeconds,
            daysUntilExam: this.stats.daysUntilExam,
        })
        toastModule.actions.displayToast({
            title: 'Thank you for your feedback!',
        })
        this.closeModal()
    }

    closeModal () {
        userModule.actions.updateWebConfig({
            hasSeenExamReadinessSurveyModal: true,
        })
        this.emitClose()
    }

    @Emit('close')
    emitClose () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.exam-readiness-survey {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint(black-bear) {
        width: 335px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 520px;
        box-sizing: border-box;

        @include breakpoint(black-bear) {
            margin: 0 auto;
            width: 100%;
        }
    }

    &__title-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 36px 40px 30px;
        box-sizing: border-box;
        border-bottom: 1px solid $fog;

        @include breakpoint(black-bear) {
            padding: 24px 24px 30px 32px;
        }

        &--dark {
            border-color: $ash;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 6px;
        letter-spacing: 0.3px;

        &--explanation {
            margin-bottom: 30px;
        }

        @include breakpoint(black-bear) {
            text-align: center;
        }
    }

    &__title-emoji {
        width: 19px;
        height: 25px;
    }

    &__form {
        margin-top: 30px;
        padding: 0 40px;
        box-sizing: border-box;
        width: 100%;

        @include breakpoint(black-bear) {
            padding: 0 30px;
        }
    }

    &__field {
        margin-bottom: 20px;
    }

    &__field :deep(.uikit-textarea__label) {
        color: $brand-black;
        margin-left: 0;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;

        @include breakpoint(black-bear) {
            padding-right: 0;
        }

        &.uikit-textarea__label--dark {
            color: $white;
        }
    }

    &__field :deep(.uikit-textarea__textarea) {
        margin-left: -9px;
        margin-right: -9px;
        width: calc(100% + 18px) !important;
        height: 64px;
        max-width: 458px;
    }

    &__actions {
        margin-top: 4px;
        align-self: center;
        padding: 0 40px 32px;
    }

    &__submit-btn {
        margin-left: 8px;
    }
}
</style>