<template>
    <div class="sso-error">
        <img
            src="@/assets/error/book-airdrop.png"
            alt=""
        >
        <div class="sso-error__message">
            {{ message }}
        </div>
        <div class="sso-error__description" v-if="description">
            {{ description }}
        </div>
    </div>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import { Vue, Component } from 'vue-facing-decorator'

@Component({})
export default class SSOError extends Vue {
    message = 'An error has occurred.'
    description = 'Please try again in a few minutes, or reach out to your admin for help.'

    mounted () {
        userModule.actions.signOut()

        if (typeof this.$route.query.errorDescription === 'string') {
            this.description = this.$route.query.errorDescription
        }

        if (typeof this.$route.query.errorMessage === 'string') {
            this.message = this.$route.query.errorMessage
        }
    }
}
</script>

<style lang="scss" scoped>
.sso-error {
    margin: 66px auto;
    max-width: 232px;
    text-align: center;

    img {
        width: 64px;
        height: 92px;
        margin-bottom: 22px;
    }

    &__message {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 8px;
    }

    &__description {
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
    }
}
</style>