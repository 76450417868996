import Parse from 'parse'
import axios, { AxiosError } from 'axios'
import axiosRetry from 'axios-retry'

axiosRetry(axios, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: axiosRetry.isRetryableError,
})

const parseAppId = import.meta.env.VUE_APP_PARSE_APP_ID
const parseJSKey = import.meta.env.VUE_APP_PARSE_JAVASCRIPT_KEY
const parseUrl = import.meta.env.VUE_APP_PARSE_URL

if (parseAppId && parseUrl && parseJSKey) {
    Parse.initialize(parseAppId, parseJSKey)
    Parse.serverURL = parseUrl
}

type TEnsureJSON<T> = T extends Parse.Object
    ? ReturnType<T['toJSON']>
    : T extends (infer R)[]
        ? TEnsureJSON<R>[]
        : T extends Record<string, unknown>
            ? { [key in keyof T]: TEnsureJSON<T[key]> }
            : T

// cloud function call
const runCloudFunction = async <T extends (
    param: { [P in keyof Parameters<T>[0]]: Parameters<T>[0][P] }
) => unknown>(
    cloudFunc: string, 
    params?: Parameters<T>[0]
): Promise<TEnsureJSON<ReturnType<T>>> => {
    const headers: Record<string, string> = {
        'Content-Type': 'application/json',
    }
    if (parseAppId) {
        headers['X-Parse-Application-Id'] = parseAppId
    }
    if (parseJSKey) {
        headers['X-Parse-Javascript-Key'] = parseJSKey
    }
    const sessionToken = Parse.User.current()?.getSessionToken()
    if (sessionToken) {
        headers['X-Parse-Session-Token'] = sessionToken
    }

    try {
        const request = await axios.post(
            `${parseUrl}/functions/${cloudFunc}`, 
            params,
            {
                headers,
            })
        return request?.data.result
    } catch (err) {
        if ((err as AxiosError<{ error: string | Record<string, unknown> }>)?.response?.data?.error) {
            const errorMessage = (err as AxiosError<{ error: string }>)?.response?.data.error
            if (errorMessage && typeof errorMessage === 'object' && 'errorMessage' in errorMessage) {
                throw errorMessage
            }
            throw new Error(errorMessage)
        } else if (err instanceof Error) {
            throw err
        } else if (typeof err === 'string') {
            throw new Error(err)
        } else {
            throw err
        }
    }
}

/**
 * Create a Parse pointer object
 *
 * @param {string} className - value of the class
 * @param {string} objectId - object's Id value
 *
 * @returns {object} object that conforms to Parse Pointer interface
 */
export const objPointer = (objectId: string, className: string) =>
    ({
        __type: 'Pointer',
        className,
        objectId,
    })

export { Parse, runCloudFunction }

export const getSessionToken = () => {
    return Parse.User.current()?.getSessionToken()
}