import { bundleModule } from '@/store/bundle/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { licenseModule } from '@/store/license/module'
import { stripeModule } from '@/store/stripe/module'
import { subscriptionModule } from '@/store/subscription/module'
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { quizModule } from '@/store/quiz/module'
import { qotdModule } from '@/store/qotd/module'
import { questionModule } from '@/store/question/module'
import { globalQuestionMetricModule } from '@/store/globalQuestionMetric/module'
import { screenModule } from '@/store/screen/module'
import { progressModule } from '@/store/progress/module'
import { toastModule } from '@/store/toast/module'
import { referralModule } from '@/store/referral/module'
import { mockExamModule } from '@/store/mockExam/module'
import { instructorModule } from '@/store/instructor/module'
import { readinessModule } from '@/store/readiness/module'
import { getInitialBundleState } from '@/store/bundle/state'
import { getInitialExamMetadataState } from '@/store/examMetadata/state'
import { getInitialLicenseState } from '@/store/license/state'
import { getInitialMockExamState } from '@/store/mockExam/state'
import { getInitialStripeState } from '@/store/stripe/state'
import { getInitialSubscriptionState } from '@/store/subscription/state'
import { getInitialUserState } from '@/store/user/state'
import { getInitialUserExamMetadataState } from '@/store/userExamMetadata/state'
import { getInitialQuizState } from '@/store/quiz/state'
import { getInitialQuestionState } from '@/store/question/state'
import { getInitialQotDState } from '@/store/qotd/state'
import { getInitialGlobalQuestionMetricState } from '@/store/globalQuestionMetric/state'
import { getInitialScreenState } from '@/store/screen/state'
import { getInitialProgressState } from '@/store/progress/state'
import { getInitialToastState } from '@/store/toast/state'
import { getInitialReferralState } from '@/store/referral/state'
import { getInitialOrgInstructorState } from '@/store/instructor/state'
import { getInitialReadinessState } from '@/store/readiness/state'

const mapStateProperties = <ModuleState>(
    moduleState: ModuleState,
    newState: ModuleState
) => {
    for (const stateKey in newState) {
        moduleState[stateKey] = newState[stateKey]
    }
}

export const resetState = () => {
    mapStateProperties(bundleModule.state, getInitialBundleState())
    mapStateProperties(examMetadataModule.state, getInitialExamMetadataState())
    mapStateProperties(globalQuestionMetricModule.state, getInitialGlobalQuestionMetricState())
    mapStateProperties(licenseModule.state, getInitialLicenseState())
    mapStateProperties(mockExamModule.state, getInitialMockExamState())
    mapStateProperties(progressModule.state, getInitialProgressState())
    mapStateProperties(qotdModule.state, getInitialQotDState())
    mapStateProperties(questionModule.state, getInitialQuestionState())
    mapStateProperties(quizModule.state, getInitialQuizState())
    mapStateProperties(referralModule.state, getInitialReferralState())
    mapStateProperties(screenModule.state, getInitialScreenState())
    mapStateProperties(stripeModule.state, getInitialStripeState())
    mapStateProperties(subscriptionModule.state, getInitialSubscriptionState())
    mapStateProperties(toastModule.state, getInitialToastState())
    mapStateProperties(userExamMetadataModule.state, getInitialUserExamMetadataState())
    mapStateProperties(instructorModule.state, getInitialOrgInstructorState())
    mapStateProperties(userModule.state, getInitialUserState())
    mapStateProperties(readinessModule.state, getInitialReadinessState())
}
