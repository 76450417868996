<template>
    <div
        v-dark
        class="question-card"
        tabindex="0"
        role="button"
        :aria-label="`${
            question.isLocked ? 'Locked ' : ''
        }${
            question.isFlagged ? 'Flagged ' : ''
        }${
            question.isCorrect ? 'Correct ' : 'Incorrect '
        }${ question.subjectName } ${question.prompt}`"
        @click="emitClick"
        @keypress.enter="emitKeypress($event)"
        @mousedown.prevent
    >
        <div
            v-dark
            class="question-card__subject"
            aria-hidden
        >
            {{ question.subjectName }}
        </div>
        <div
            v-dark
            class="question-card__prompt"
            aria-hidden
        >
            {{ question.scenarioPartLabel ? 
                `${question.scenarioPartLabel}: ${question.prompt}` :
                question.prompt
            }}
        </div>
        <div class="question-card__icons" aria-hidden>
            <div v-if="question.isLocked" class="question-card__icon">
                <Icon
                    v-dark
                    type="lock"
                    class="question-card__lock"
                />
            </div>
            <div v-if="question.isFlagged" class="question-card__icon">
                <Icon
                    v-dark
                    type="flagFeedback"
                    :secondary-color="brandColors.bananaBread"
                    class="question-card__flag"
                    title="Flagged"
                />
            </div>
            <div v-if="question.isCorrect" class="question-card__icon">
                <Icon
                    v-dark
                    type="correct"
                    class="question-card__correct"
                    title="Correct"
                />
            </div>
            <div v-if="!question.isCorrect" class="question-card__icon">
                <Icon
                    v-dark
                    type="incorrect"
                    class="question-card__incorrect"
                    title="Incorrect"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import UIKit from '@pocketprep/ui-kit'
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'

export type TQuestionCardInfo = {
    serial: string
    subjectName: string
    prompt: string
    isFlagged: boolean
    isCorrect: boolean
    isLocked: boolean
    scenarioPartLabel: string
}

@Component({
    components: {
        Icon: UIKit.Icon,
    },
})
export default class QuestionCard extends Vue {
    @Prop() question!: TQuestionCardInfo

    brandColors = BrandColors

    @Emit('click')
    emitClick () {
        return true
    }

    @Emit('keypress')
    emitKeypress (keyEvent: KeyboardEvent) {
        return keyEvent
    }
}
</script>

<style lang="scss" scoped>
.question-card {
    padding: 12px 12px 10px;
    position: relative;
    background: $white;
    border-radius: 5px;
    border: 1px solid rgba($pewter, 0.85);
    box-shadow: 0 1px 4px 0 rgba($ash, 0.3);
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 9px;
    user-select: none;
    cursor: pointer;
    outline: none;

    &::before {
        content: '';
        border-radius: 5px;
        box-sizing: border-box;
        display: none;
        position: absolute;
        left: -1px;
        top: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
    }

    &:hover::before {
        display: block;
        border: 2px solid $slate-01;
    }

    &:focus::before {
        display: block;
        border: 1px solid $brand-blue;
    }

    &--dark {
        background: $brand-black;
        border-color: $slate;
        box-shadow: 0 1px 4px 0 $jet;
        color: $white;

        &:hover::before {
            border-color: $pewter;
        }

        &:focus::before {
            border-color: $banana-bread;
        }
    }

    &__subject {
        color: $slate-01;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 7px;
        width: calc(100% - 66px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &--dark {
            color: $pewter;
        }
    }

    &__prompt {
        font-size: 16px;
        line-height: 22px;

        :deep(span) {
            position: relative;
            z-index: 1;

            &::before {
                border-radius: 4px;
                content: '';
                height: 20px;
                width: calc(100% + 2px);
                left: -1px;
                top: 0;
                background: $buttermilk;
                z-index: -1;
                position: absolute;
            }
        }

        &--dark {
            :deep(span::before) {
                background: rgba($buttermilk, 0.3);
            }
        }
    }

    &__icons {
        position: absolute;
        right: 8px;
        top: 9px;
        display: flex;
        align-items: center;
    }

    &__icon {
        width: 22px;
        height: 22px;
        text-align: center;
    }

    &__lock {
        color: $slate;

        &--dark {
            color: $pewter;
        }
    }

    &__flag {
        color: #ce8500;

        &--dark {
            color: $butterscotch;
        }
    }

    &__correct {
        color: $cadaverous;

        &--dark {
            color: $jungle-green;
        }
    }

    &__incorrect {
        color: $pepper;

        &--dark {
            color: $rosa;
        }
    }
}
</style>
