<template>
    <Portal to="modal">
        <ModalContainer key="nameModal" :is-dark-mode="isDarkMode">
            <div class="name-modal">
                <div class="name-modal__title">
                    Complete your profile
                </div>
                <div class="name-modal__text">
                    You’ve been added to {{ orgName }}. Complete your profile so {{ orgName }} knows who you are.
                </div>
                <PocketInput
                    v-model="firstName"
                    :is-dark-mode="isDarkMode"
                    label="First Name"
                    :error="errorFields.includes('firstName')"
                    class="name-modal__input"
                    @keydown.enter="saveName"
                />
                <PocketInput
                    v-model="lastName"
                    :is-dark-mode="isDarkMode"
                    label="Last Name"
                    :error="errorFields.includes('lastName')"
                    class="name-modal__input"
                    @keydown.enter="saveName"
                />
                <PocketButton
                    :is-dark-mode="isDarkMode"
                    :disabled="!firstName || !lastName"
                    class="name-modal__submit"
                    :is-loading="isLoading"
                    @click="saveName"
                >
                    Save
                </PocketButton>
            </div>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        PocketButton: UIKit.Button,
        PocketInput: UIKit.Input,
        ModalContainer: UIKit.ModalContainer,
    },
})
export default class NameModal extends Vue {
    @Prop() orgName!: string

    firstName = ''
    lastName = ''
    errorFields: string[] = []
    isLoading = false

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    async saveName () {
        this.isLoading = true
        this.errorFields = []

        if (!this.firstName) {
            this.errorFields.push('firstName')
        }
        if (!this.lastName) {
            this.errorFields.push('lastName')
        }

        if (!this.errorFields.length) {
            await userModule.actions.updateUser({
                firstName: this.firstName,
                lastName: this.lastName,
            })

            this.emitClose()
        }

        this.isLoading = false
    }

    @Emit('close')
    emitClose () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.name-modal {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 42px 26px 39px;
    width: 374px;
    margin: 0 auto;

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 12px;
    }

    &__text {
        color: $slate-03;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 29px;

        &--dark {
            color: $white;
        }
    }

    &__input {
        margin-bottom: 20px;
        width: 324px;
    }

    &__submit {
        margin-top: 13px;
    }
}
</style>