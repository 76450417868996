<template>
    <div class="onboarding">
        <h1 
            class="onboarding__header"
            :class="{ 'onboarding__header--referral': validReferral }"
        >
            Quiz based test prep for 100+ exams
        </h1>
        <div class="onboarding__main-section">
            <div class="onboarding__testimony">
                <span class="onboarding__yellow-line" />
                <div class="onboarding__quote">
                    I cried tears of joy when I passed because I was finally done. 
                    Everything I worked so hard for was finally achieved! - Alison P.
                    <img
                        src="@/assets/register-onboard/five-stars.png"
                        class="onboarding__five-stars"
                        alt=""
                    >
                </div>    
            </div>
            <div v-if="breakpoint !== 'black-bear'" class="onboarding__page">
                <img
                    v-if="breakpoint !== 'brown-bear'"
                    src="@/assets/register-onboard/home-page.png"
                    class="onboarding__web-home-page"
                    alt=""
                >
                <img
                    v-else
                    src="@/assets/register-onboard/tablet-home-page.png"
                    class="onboarding__tablet-home-page"
                    alt=""
                >
            </div>
        </div>
        <PocketButton
            class="onboarding__find-exam-button"
            @click="emitNext"
        >
            Find Your Exam <Icon class="onboarding__arrow" type="arrow" />
        </PocketButton>
        <div class="onboarding__free-to-try" :class="{ 'onboarding__free-to-try--referral': validReferral }">
            (it's free to try)
        </div>
        <img
            v-if="breakpoint === 'black-bear'"
            src="@/assets/register-onboard/mobile-home-page.png"
            class="onboarding__mobile-home-page"
            alt=""
        >
        <div v-if="breakpoint === 'brown-bear' || breakpoint === 'black-bear'" class="onboarding__already-have-account">
            <RouterLink
                v-slot="{ href, navigate }"
                custom
                :to="{ name: 'sign-in' }"
            >
                <PocketLink
                    class="onboarding__existing-account"
                    type="tertiary-small"
                    :href="href"
                    @click="navigate"
                    @mousedown.prevent
                >
                    I already have an account
                </PocketLink>
            </RouterLink>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Emit, Prop } from 'vue-facing-decorator'
import { screenModule } from '@/store/screen/module'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        Icon: UIKit.Icon,
        PocketButton: UIKit.Button,
        PocketLink: UIKit.Link,
    },
})
export default class Onboarding extends Vue {
    @Prop({ default: false }) validReferral!: boolean

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    @Emit('next')
    emitNext () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.onboarding {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

    &__header {
        margin-top: 66px;
        font-size: 26px;
        line-height: 36px;
        color: $brand-black;
        margin-bottom: 24px;

        &--referral {
            margin-top: 45px;
        }

        @include breakpoint(black-bear) {
            color: $brand-black;
            font-size: 26px;
            line-height: 31px;
            width: 277px;
            text-align: center;
            margin-bottom: 0;
        }
    }

    &__main-section {
        position: relative;
        display: flex;
        width: 100%;

        @include breakpoint(black-bear) {
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: center;
        }
    }

    :deep(.uikit-btn) {
        font-size: 14px;
        line-height: 17px;
        padding: 5px 9px 5px;
    }

    &__testimony {
        position: relative;
        display: flex;
        flex-direction: row;
    }

    &__yellow-line {
        background-color: $banana-bread;
        width: 6px;
        height: 56px;
        margin-left: 65px;
        margin-right: 24px;
        margin-top: 40px;

        @include breakpoint(black-bear) {
            margin-left: 20px;
            height: 48px;
        }
    }

    &__quote {
        display: flex;
        flex-direction: column;
        position: relative;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        width: 215px;
        text-align: left;
        margin-top: 40px;
        margin-right: 24px;
        color: $brand-black;

        @include breakpoint(black-bear) {
            font-size: 16px;
            letter-spacing: 0.17px;
            line-height: 24px;
            width: 255px;
            margin-bottom: 20px;
        }
    }

    &__five-stars {
        width: 200px;
        margin-top: 20px;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__web-home-page {
        width: 600px;
        height: 372px;
    }

    &__tablet-home-page {
        width: 375px;
        height: 390px;
    }

    &__mobile-home-page {
        width: 299px;
        height: 622px;
    }

    &__find-exam-button {
        border-radius: 4px;
        width: 151px;
        height: 36px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    &__arrow {
        margin-left: 5px;
    }

    &__free-to-try {
        color: $ash;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        font-weight: 500;

        &--referral {
            padding-bottom: 15px;
        }

        @include breakpoint(black-bear) {
            margin-bottom: 48px;
        }
    }

    &__already-have-account {
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        margin-top: 112px;

        @include breakpoint(black-bear) {
            margin-top: 48px;
        }
    }
}
</style>