<template>
    <Portal to="modal">
        <ModalContainer
            key="resetProgressModal"
            :is-dark-mode="isDarkMode"
            @close="emitClose"
        >
            <template #modal>
                <Modal
                    class="reset-progress-modal"
                    :show-close-button="true"
                    :is-dark-mode="isDarkMode"
                    @close="emitClose"
                >
                    <div class="reset-progress-modal__title">
                        Reset Progress?
                    </div>
                    <div v-dark class="reset-progress-modal__message">
                        Are you sure you want to delete your account's progress and start fresh? This cannot be
                        undone.
                    </div>
                    <div class="reset-progress-modal__buttons">
                        <PocketButton
                            class="reset-progress-modal__button"
                            type="secondary"
                            :is-dark-mode="isDarkMode"
                            @click="emitClose"
                        >
                            Cancel
                        </PocketButton>
                        <PocketButton
                            type="primary-red"
                            class="reset-progress-modal__button"
                            :disabled="isLoading"
                            :is-dark-mode="isDarkMode"
                            @click="emitResetProgress"
                        >
                            Yes, Reset
                        </PocketButton>
                    </div>
                </Modal>
            </template>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'

@Component({
    components: {
        PocketButton: UIKit.Button,
        Modal: UIKit.Modal,
        ModalContainer: UIKit.ModalContainer,
    },
})
export default class ResetProgressModal extends Vue {
    @Prop({ default: false }) isLoading!: boolean

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('resetProgress')
    emitResetProgress () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.reset-progress-modal {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    top: 80px;
    width: 392px;
    padding: 35px 0 26px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakpoint(black-bear) {
        width: 356px;
        height: auto;
        max-height: 638px;
        top: 10px;
    }

    &__title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        width: 309px;
        margin: 0 auto 12px;
    }

    &__message {
        font-size: 15px;
        line-height: 22px;
        color: $slate-03;
        margin-bottom: 29px;
        width: 309px;

        &--dark {
            color: $white;
        }
    }

    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__button {
        margin: 0 6px;
    }
}
</style>