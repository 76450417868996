<template>
    <div class="apple-google-pay">
        <div
            :id="'payment-request-button'"
            class="apple-google-pay_button"
            v-dark
        />
        <div
            v-if="!isLoading && walletPayAvailable"
            class="apple-google-pay__pay-another-way"
        >
            <div
                class="apple-google-pay__pay-another-way-line"
                :class="{
                    'apple-google-pay__pay-another-way-line--dark': isDarkMode,
                }"
            />
            <div 
                class="apple-google-pay__pay-another-way-text"
                :class="{
                    'apple-google-pay__pay-another-way-text--dark': isDarkMode,
                }"
            >
                or pay another way
            </div>
            <div
                class="apple-google-pay__pay-another-way-line"
                :class="{
                    'apple-google-pay__pay-another-way-line--dark': isDarkMode,
                }"
            />
        </div>
        <Errors
            v-if="errors.length"
            :is-dark-mode="isDarkMode"
            :errors="errors"
        />
    </div>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop, Watch, Emit } from 'vue-facing-decorator'
import { subscriptionModule } from '@/store/subscription/module'
import { type Stripe, type StripeElement } from '@stripe/stripe-js'
import { stripeModule } from '@/store/stripe/module'
import { stripeEnabledAppearance, stripeDisabledAppearance } from '@/utils'

@Component({
    components: {
        PocketInput: UIKit.Input,
        PocketSelect: UIKit.Select,
        Errors: UIKit.Errors,
    },
})
export default class AppleGooglePay extends Vue {
    @Prop() errors!: string[]
    @Prop() errorFields!: string[]
    @Prop() plan!: {value: string; label: string}
    @Prop() planAmount!: number
    @Prop() planSubscriptionName!: string
    @Prop() referralId!: string
    @Prop() impactClickId!: string
    @Prop() discountPlanAmount!: number | ''

    cardFieldId = Math.floor(Math.random() * 1e7)
    name: null | string = null
    stripe: Stripe | null = null
    paymentRequestButton: StripeElement | null | void = null
    stripeFieldFocused = false
    stripeFieldHover = false
    walletPayAvailable = false
    stripeValidationError: stripe.Error | null = null
    isLoading = true

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    mounted () {
        this.$nextTick(async () => {
            if (import.meta.env.VUE_APP_STRIPE_PUBLISHABLE_KEY) {
                if (this.planAmount) {
                    const { stripe, elements } = await stripeModule.actions.loadStripeAndElements({
                        planAmount: this.referralId && this.discountPlanAmount ? 
                            this.discountPlanAmount : this.planAmount,
                        creatingPaymentField: true,
                    })

                    if (stripe && elements) {
                        const paymentRequest = stripe.paymentRequest({
                            country: 'US',
                            currency: 'usd',
                            total: {
                                label: this.plan ? this.plan.label : '',
                                amount: this.referralId && this.discountPlanAmount ? 
                                    this.discountPlanAmount : this.planAmount,
                            },
                            requestPayerName: true,
                            requestPayerEmail: true,
                        })

                        // Check the availability of a wallet
                        const availablePRbutton = await paymentRequest.canMakePayment()

                        this.paymentRequestButton = elements.create('paymentRequestButton', {
                            paymentRequest,
                            style: {
                                paymentRequestButton: {
                                    theme: this.isDarkMode ? 'light' : 'dark',
                                },
                            },
                        })

                        if (availablePRbutton && this.paymentRequestButton) {
                            this.paymentRequestButton.mount('#payment-request-button')
                            this.walletPayAvailable = true

                            this.paymentRequestButton?.on('click', () => {
                                // updates payment info if user switches plan
                                paymentRequest.update({
                                    total: {
                                        label: this.plan ? this.plan.label : '',
                                        amount: this.referralId && this.discountPlanAmount ? 
                                            this.discountPlanAmount : this.planAmount,
                                    },
                                })
                            })
                        }

                        paymentRequest.on('paymentmethod', async (event) => {    
                            const { clientSecret, subscriptionId } = await subscriptionModule.actions
                                .createIncompleteSubscription({
                                    plan: this.plan?.value as string,
                                    referralId: this.referralId ? this.referralId : '',
                                    impactClickId: this.impactClickId,
                                })

                            const disabledAppearance = stripeDisabledAppearance(this.isDarkMode)
                            const enabledAppearance = stripeEnabledAppearance(this.isDarkMode)
                            // disable card fields while processing
                            const paymentStripeEl = elements?.getElement('payment')
                            paymentStripeEl?.update({ readOnly: true })
                            stripeModule.getters.getElements()?.update({ appearance: disabledAppearance })

                            if (clientSecret && subscriptionId) {
                                // Confirm the PaymentIntent without handling potential next actions (yet).
                                const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
                                    clientSecret,
                                    { payment_method: event.paymentMethod.id },
                                    { handleActions: false }
                                )
        
                                if (confirmError) {
                                    // show the user the error and ask for a different payment method
                                    event.complete('fail')
                                    paymentStripeEl?.update({ readOnly: false })
                                    stripeModule.getters.getElements()?.update({ appearance: enabledAppearance })
                                    this.errors.push(
                                        'Unable to process payment. Please try a different payment method.')
                                    this.emitSubmitAppleGooglePayment(false)
                                } else {
                                    // Report to the browser that the confirmation was successful, prompting
                                    // it to close the browser payment method collection interface.
                                    event.complete('success')

                                    if (paymentIntent.status === 'requires_action') {
                                        // Let Stripe.js handle the rest of the payment flow.
                                        const { error } = await stripe.confirmCardPayment(clientSecret)
                                        if (error) {
                                            // The payment failed -- ask your customer for a new payment method.
                                            paymentStripeEl?.update({ readOnly: false })
                                            // eslint-disable-next-line max-len
                                            this.errors.push('Unable to process payment. Please try a different payment method.')
                                            // eslint-disable-next-line max-len
                                            stripeModule.getters.getElements()?.update({ appearance: enabledAppearance })
                                            this.emitSubmitAppleGooglePayment(false)
                                        } else {
                                            await subscriptionModule.actions.activateSubscription({
                                                subscriptionId: subscriptionId,
                                                plan: this.planSubscriptionName,
                                            })
                                            this.emitSubmitAppleGooglePayment(true)
                                        }
                                    } else {
                                        await subscriptionModule.actions.activateSubscription({
                                            subscriptionId: subscriptionId,
                                            plan: this.planSubscriptionName,
                                        })
                                        paymentStripeEl?.update({ readOnly: false })
                                        stripeModule.getters.getElements()?.update({ appearance: enabledAppearance })
                                        this.emitSubmitAppleGooglePayment(true)
                                    }
                                }
                            } else {
                                paymentStripeEl?.update({ readOnly: false })
                                stripeModule.getters.getElements()?.update({ appearance: enabledAppearance })
                                this.emitSubmitAppleGooglePayment(false)
                            }
        
                        })

                    }
                }
            }
            this.isLoading = false
        })
    }

    @Watch('walletPayAvailable')
    walletAvailabilityChange () {
        this.emitUpdate(this.walletPayAvailable)
    }

    @Emit('update')
    emitUpdate  (available: boolean) {
        return available
    }

    @Emit('submitAppleGooglePayment')
    emitSubmitAppleGooglePayment (successfulTransaction: boolean) {
        return successfulTransaction
    }
}
</script>

<style lang="scss" scoped>
.apple-google-pay {
    &__pay-another-way {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
    }

    &__pay-another-way-line {
        height: 1px;
        width: 100px;
        background: $brand-black;

        @include breakpoint(black-bear) {
            width: 90px;
        }

        &--dark {
            background: $fog;
        }
    }

    &__pay-another-way-text {
        font-size: 13px;

        &--dark {
            color: $fog;
        }
    }
}
</style>