<template>
    <div
        class="stat"
        :class="{
            'stat--dark': theme === 'dark',
        }"
    >
        <div class="stat__value">
            <slot name="value">
                {{ value }}
            </slot>
        </div>
        <div
            class="stat__label"
            :class="{
                'stat__label--dark': theme === 'dark',
            }"
        >
            <slot name="label">
                {{ label }}
            </slot>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component
export default class Stat extends Vue {
    @Prop({ default: 'light' }) theme!: 'dark' | 'light'
    @Prop({ default: 0 }) value!: string | number
    @Prop({ default: 'Label' }) label!: string
}
</script>

<style lang="scss" scoped>
.stat {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.24px;
    line-height: 29px;
    text-align: center;
    color: $brand-black;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--dark {
        color: $white;
    }

    &__value {
        display: inline;
        margin-left: -100%;
        margin-right: -100%;
        text-align: center;
    }

    &__label {
        margin-top: 2px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: normal;
        color: $slate-01;

        &--dark {
            color: rgba($white, 0.8);
        }
    }
}
</style>
