import { progressModule } from '@/store/progress/module'
import { isPromise } from '@/store/utils'

const getProgressByExamGuid = () => {
    const progress = progressModule.state.progressByExamGuid.value

    if (isPromise(progress)) {
        return {}
    } else {
        return progress
    }
}

export default {
    getProgressByExamGuid,
}