<!-- eslint-disable -->
<template>
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_27_8689)">
            <path d="M10.7503 1.76835C10.8289 1.52651 11.1711 1.52651 11.2497 1.76835L13.2985 8.07394C13.3336 8.18209 13.4344 8.25532 13.5481 8.25532H20.1782C20.4325 8.25532 20.5382 8.58072 20.3325 8.73019L14.9686 12.6273C14.8766 12.6941 14.8381 12.8126 14.8733 12.9207L16.9221 19.2263C17.0007 19.4682 16.7239 19.6693 16.5181 19.5198L11.1543 15.6227C11.0623 15.5559 10.9377 15.5559 10.8457 15.6227L5.48185 19.5198C5.27613 19.6693 4.99933 19.4682 5.07791 19.2263L7.12672 12.9207C7.16186 12.8126 7.12336 12.6941 7.03136 12.6273L1.6675 8.73019C1.46178 8.58072 1.56751 8.25532 1.8218 8.25532H8.45189C8.56561 8.25532 8.6664 8.18209 8.70154 8.07394L10.7503 1.76835Z" stroke="currentColor" stroke-width="2.1"/>
        </g>
        <defs>
            <clipPath id="clip0_27_8689">
                <rect width="22" height="21" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
<!-- eslint-enable -->

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

@Component
export default class IconStar extends Vue {
    
}
</script>
