import type { Teach } from '@pocketprep/types'
import { runCloudFunction } from '../parseUtils'
import { fetchLoadable } from '../utils'
import { instructorModule } from './module'

export type TFetchCurrentUserOrgInstructors = () => Promise<Teach.Class.OrgInstructorJSON[]>

const fetchCurrentUserOrgInstructors = async () => {
    return fetchLoadable(instructorModule.state.currentUserOrgInstructors,
        async () => runCloudFunction<TFetchCurrentUserOrgInstructors>('fetchCurrentUserOrgInstructors'))
}

export default {
    fetchCurrentUserOrgInstructors,
}
