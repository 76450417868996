import { initLoadable } from '@/store/utils'
import type { Loadable } from '@/store/utils'
import type { Study } from '@pocketprep/types'
import { type Stripe } from 'stripe'

type TReferralState = {
    validReferral: Loadable<Study.Class.ReferralJSON | Stripe.Coupon | null>
    referralError: string | null
    hasSeenReferralUpgradePanel: boolean
}

export const getInitialReferralState = (): TReferralState => {
    return {
        validReferral: initLoadable(null),
        referralError: null,
        hasSeenReferralUpgradePanel: false,
    }
}

const moduleState = getInitialReferralState()

export default moduleState
