import { reactive } from 'vue'
import actions from '@/store/stripe/actions'
import state from '@/store/stripe/state'
import getters from '@/store/stripe/getters'

export const stripeModule = {
    state: reactive(state) as typeof state, // TS needs an extra hint due to stripe state's complexity
    actions,
    getters,
}
